import React from "react";
import { Card, Typography } from "@mui/material";

import { useStyles } from "./SelectedQuote.styles";
import { useAction, wizardActions } from "../../../../../state";
import { ContactMethods } from "../../../../../utils";

export default function ContactCards({ title }) {
  const classes = useStyles({});
  const showContactModal = useAction(wizardActions.showContactModal);

  return (
    <div className={classes.contactWrapper}>
      <Typography className={classes.title}>{title}</Typography>
      {Object.keys(ContactMethods).map((method) => {
        const Icon = ContactMethods[method].icon;
        return (
          <Card
            key={method}
            className={classes.contactCard}
            onClick={() => showContactModal(method)}
          >
            <Icon color="primary" />
            <Typography className={classes.contactTitle}>
              {ContactMethods[method].button}
            </Typography>
            <Typography className={classes.status}>
              Within 1 business day
            </Typography>
          </Card>
        );
      })}
    </div>
  );
}
