import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";

import {
  Card,
  Typography,
  Box,
  Button,
  TextField,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Chip,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authClient,
  authSelectors,
  uiActions,
  useDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, yup } from "../../../../lib";

import { useStyles } from "../AgencySettingsPage.styles";
import { DeleteIcon, EditIcon } from "../../../../components";
import { Agency } from "../../../../state/admin/state";
import { REACT_APP_ASSETS_URL } from "../../../../config";
import { DropzoneArea } from "react-mui-dropzone";
import { ensureHttps } from "../../../../utils";

const validationSchema = yup.object({
  name: yup.string().required("Company name is required"),
  domain: yup
    .string()
    .required("Domain is required")
    .test(
      "domain",
      "An agency with this domain already exists",
      function (domain) {
        const { agencies, isNewAgency, activeAgencyId } = this.parent;
        const matchingAgencies = agencies.filter(
          (agency) => agency.domain === domain,
        );
        if (isNewAgency) {
          return Boolean(!matchingAgencies.length);
        } else {
          const newMatchingAgencies = matchingAgencies.filter(
            (agency) => agency.id !== activeAgencyId,
          );
          return Boolean(!newMatchingAgencies.length);
        }
      },
    ),
});

const defaultAgency = {
  name: "",
  domain: "",
  logo_path: "",
  theme: {
    palette: {
      info: {
        main: "#FF4343",
      },
      type: "light",
      primary: {
        main: "#0033ff",
        light: "#0066ff",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#8D98C2",
        light: "#cdd2e9",
        contrastText: "#0033ff3d",
      },
    },
  },
  group_id: 2,
  email_address: "no-reply@reli-exchange.com",
  phone: "8777078555",
  email_to: "info@reli-exchange.com",
  preview_title: "Personalized Insurance Quotes in 5 Minutes for Free",
  preview_description: "Get the best deal fast, whatever your budget",
  preview_image_path: "images/6dbe56ba-2de9-4fd2-858e-fdecb7d0484e.jpg",
  homepage_title:
    "Get personalized insurance quotes and advice - no obligation",
  homepage_subtitle: "Get the best offer fast, whatever your circumstances.",
  street: "1051 Perimeter Dr",
  city: "Schaumburg",
  state: "IL",
  zip: "60173",
  country: "USA",
  show_in_directory: true,
  restrict_agent_viewing: false,
};

const BrandingCard = ({ isNewAgency = false }) => {
  const [inEditMode, setInEditMode] = useState(isNewAgency);
  const [fileDropped, setFileDropped] = useState(false);
  const fileUploadRef = useRef<FormData>();

  const classes = useStyles();
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const activeAgencyId = params.id
    ? Number(params.id)
    : useSelector(authSelectors.activeAgencyId);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const agencies = useSelector(adminSelectors.agencies);
  const directors = useSelector(adminSelectors.directors);
  const fullAgencyData: Agency = useSelector(adminSelectors.activeAgencyData);

  const [currentAgencyDirectorIds, setCurrentAgencyDirectorIds] = useState<
    Array<any>
  >([]);
  const [addedAgencyDirectorIds, setAddedAgencyDirectorIds] = useState<
    Array<any>
  >([]);
  const agencyData = isNewAgency
    ? {
        name: "",
        domain: "",
        logo_path: "",
        logo_height: 80,
        id: -1,
        google_doc_url: "",
      }
    : useSelector(adminSelectors.activeAgencyData);
  const activeRole = useSelector(authSelectors.activeRole);

  const isAdmin =
    activeRole === "sales_admin" || activeRole === "sales_director";

  const handleDelete = (directorGroupId) => {
    const directorUserId = directors.find(
      (d) => d.agency_groups_id === directorGroupId,
    )?.user_id;
    if (activeAgencyId) {
      dispatch(
        adminActions.deleteAgencyDirector(
          activeAgencyId,
          directorGroupId,
          directorUserId,
        ),
      );
    }
  };
  useEffect(() => {
    dispatch(adminActions.getAgency(activeAgencyId));
    if (isSuperAdmin) {
      dispatch(adminActions.getAgencies());
      dispatch(adminActions.getDirectors());
    }
  }, []);
  useEffect(() => {
    if (isSuperAdmin) {
      const currentDirectorIds = directors
        .filter((d) => d.agencyIds.includes(agencyData?.id))
        .map((d) => d.agency_groups_id);
      setCurrentAgencyDirectorIds(currentDirectorIds);
    }
  }, [directors]);
  const handleAddDirectors = (e) => {
    setAddedAgencyDirectorIds(e.target.value);
  };

  const addAgencyDirector = () => {
    dispatch(
      adminActions.addAgencyDirector(activeAgencyId, addedAgencyDirectorIds),
    );
    setAddedAgencyDirectorIds([]);
  };
  async function uploadFile() {
    if (fileDropped) {
      const { status, data } = await authClient.post(
        "/file-upload",
        fileUploadRef.current,
      );
      if (status === 200) {
        return data.path;
      } else {
        dispatch(uiActions.showError("Logo upload failed"));
      }
    }
    return "";
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...agencyData,
      logo_path: "",
      agencies,
      isNewAgency,
      activeAgencyId,
    },
    validationSchema,
    async onSubmit(values) {
      const new_path = await uploadFile();

      if (isNewAgency) {
        const updatedAgencyData = {
          ...defaultAgency,
          name: values.name,
          domain: values.domain,
          logo_path: new_path || agencyData.logo_path,
          logo_height: values.logo_height,
          google_doc_url: ensureHttps(values.google_doc_url),
        };
        dispatch(adminActions.createAgency(updatedAgencyData));
      } else {
        const updatedAgencyData = {
          ...fullAgencyData,
          name: values.name,
          domain: values.domain,
          logo_path: new_path || agencyData.logo_path,
          logo_height: values.logo_height,
          google_doc_url: ensureHttps(values.google_doc_url),
        };
        dispatch(
          adminActions.updateAgency(
            activeAgencyId,
            updatedAgencyData,
            isSuperAdmin,
          ),
        );
      }
      if (isNewAgency) {
        dispatch(adminActions.setAgencyModalOpen(false));
      } else {
        setInEditMode(false);
      }
      form.resetForm();
    },
  });

  const handleCancel = () => {
    if (isNewAgency) {
      dispatch(adminActions.setAgencyModalOpen(false));
    } else {
      setInEditMode(false);
    }
    form.resetForm();
  };

  const showEditButton = Boolean((!inEditMode && isSuperAdmin) || isAdmin);

  if (!activeAgencyId) return null;

  const SectionTitle = ({ title }) => {
    return (
      <Box className={classes.sectionTitleWrapper}>
        <Typography className={classes.sectionTitle}>{title}</Typography>
        {showEditButton && (
          <Button
            className={classes.editBtnWrapper}
            onClick={() => setInEditMode(!inEditMode)}
          >
            <EditIcon style={{ color: "white" }} />
          </Button>
        )}
      </Box>
    );
  };

  const Field = ({ fieldName, fieldValue }) => {
    return (
      <Box className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle}>{fieldName}</Typography>
        <Typography className={classes.field}>{fieldValue}</Typography>
      </Box>
    );
  };

  async function handleFileDrop(file) {
    fileUploadRef.current = new FormData();
    fileUploadRef.current.append("file", file[0]);
    setFileDropped(true);
  }

  async function handleFileDelete() {
    fileUploadRef.current = new FormData();
    setFileDropped(false);
  }
  return (
    <>
      {inEditMode ? (
        <Card variant="outlined" className={classes.section}>
          <SectionTitle title={"Branding"} />

          <form onSubmit={form.handleSubmit}>
            <>
              {isSuperAdmin && (
                <Box>
                  <Box className={classes.textFieldWrapper}>
                    <TextField
                      id={"name"}
                      name={"name"}
                      label={"Company name"}
                      variant="outlined"
                      required={true}
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.asterisk,
                        },
                      }}
                      value={form.values.name}
                      onChange={form.handleChange}
                      error={Boolean(form.errors.name)}
                      helperText={form.errors.name}
                    />
                  </Box>

                  <Box className={classes.textFieldWrapper}>
                    <TextField
                      id={"domain"}
                      name={"domain"}
                      label={"Domain"}
                      variant="outlined"
                      required={true}
                      InputLabelProps={{
                        classes: {
                          asterisk: classes.asterisk,
                        },
                      }}
                      value={form.values.domain}
                      onChange={form.handleChange}
                      error={Boolean(form.errors.domain)}
                      helperText={form.errors.domain}
                    />
                  </Box>
                  {agencyData.logo_path && (
                    <Box className={classes.textFieldWrapper}>
                      <Typography className={classes.fieldTitle}>
                        Current logo
                      </Typography>

                      <img
                        src={`${REACT_APP_ASSETS_URL}/${agencyData.logo_path}`}
                        style={{
                          width: "auto",
                          height: agencyData.logo_height,
                        }}
                      ></img>
                    </Box>
                  )}

                  <Box className={classes.textFieldWrapper}>
                    <Typography className={classes.fieldTitle}>
                      {isNewAgency
                        ? "Upload logo (required)"
                        : "Upload new logo"}
                    </Typography>

                    <DropzoneArea
                      showPreviews={true}
                      filesLimit={1}
                      acceptedFiles={[".png", ".jpg", ".jpeg", ".svg"]}
                      maxFileSize={3000000}
                      showPreviewsInDropzone={false}
                      dropzoneText="Drop an image or click to browse"
                      dropzoneClass={classes.dropzone}
                      dropzoneParagraphClass={classes.dropzoneText}
                      previewGridClasses={{
                        item: classes.dropzone,
                        image: classes.dropzoneImage,
                      }}
                      onDrop={(file) => handleFileDrop(file)}
                      onDelete={() => handleFileDelete()}
                    />
                  </Box>
                  <Box className={classes.textFieldWrapper}>
                    <TextField
                      id={"logo_height"}
                      name={"logo_height"}
                      label={"Logo height"}
                      variant="outlined"
                      type="number"
                      value={form.values.logo_height}
                      onChange={form.handleChange}
                      error={Boolean(form.errors.logo_height)}
                      helperText={form.errors.logo_height}
                    />
                  </Box>
                </Box>
              )}
              <Box className={classes.textFieldWrapper}>
                <TextField
                  variant="outlined"
                  name="google_doc_url"
                  label="Google doc link"
                  value={form.values.google_doc_url}
                  onChange={form.handleChange}
                  error={
                    form.touched.google_doc_url &&
                    Boolean(form.errors.google_doc_url)
                  }
                  helperText={
                    form.touched.google_doc_url && form.errors.google_doc_url
                  }
                />
              </Box>
            </>
            <Box className={classes.submit}>
              <Button
                variant="contained"
                onClick={() => handleCancel()}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitBtn}
                disabled={
                  !form.isValid ||
                  (isNewAgency &&
                    (!form.values.name || !form.values.domain || !fileDropped))
                }
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      ) : (
        <Card
          variant="outlined"
          className={classes.section}
          style={{ minHeight: 438 }}
        >
          <SectionTitle title={"Branding"} />
          <Field fieldName={"Company Name"} fieldValue={agencyData.name} />

          <Field fieldName={"Domain"} fieldValue={agencyData.domain} />
          <Box className={classes.fieldWrapper}>
            <Typography className={classes.fieldTitle}>Logo</Typography>
            <img
              src={`${REACT_APP_ASSETS_URL}/${agencyData.logo_path}`}
              style={{
                width: "auto",
                height: agencyData.logo_height,
              }}
            ></img>
          </Box>
          {!isNewAgency && isSuperAdmin && (
            <Box className={classes.textFieldWrapper}>
              <Grid item>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Directors</InputLabel>

                  <Select
                    multiple
                    fullWidth
                    id="directorAgencies"
                    labelId="test-select-label"
                    label="Tag"
                    value={addedAgencyDirectorIds}
                    onChange={handleAddDirectors}
                    renderValue={() => ""}
                  >
                    <MenuItem disabled value="">
                      <em>Directors</em>
                    </MenuItem>
                    {directors.map((director) => {
                      return (
                        <MenuItem
                          key={director.agency_groups_id}
                          value={director.agency_groups_id}
                          disabled={currentAgencyDirectorIds.includes(
                            director.agency_groups_id,
                          )}
                        >
                          <Checkbox
                            checked={
                              addedAgencyDirectorIds.includes(
                                director.agency_groups_id,
                              ) ||
                              currentAgencyDirectorIds.includes(
                                director.agency_groups_id,
                              )
                            }
                          />
                          <ListItemText
                            primary={`${
                              director.last_name + " " + director.first_name
                            }`}
                          />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Box className={classes.addBtnWrapper}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.submitBtn}
                  disabled={!addedAgencyDirectorIds.length}
                  onClick={addAgencyDirector}
                >
                  Add
                </Button>
              </Box>
              {(!!currentAgencyDirectorIds?.length ||
                !!addedAgencyDirectorIds?.length) && (
                <Grid className={classes.selectedAgenciesWrapper}>
                  {!!currentAgencyDirectorIds?.length && (
                    <div>
                      {" "}
                      <Typography>Current directors</Typography>
                    </div>
                  )}
                  <div>
                    {" "}
                    <div className={classes.selectedAgencies}>
                      {directors.map((d) => {
                        if (
                          currentAgencyDirectorIds.includes(d.agency_groups_id)
                        ) {
                          const name = d.first_name + " " + d.last_name;
                          return (
                            <Chip
                              key={d.user_id}
                              label={name}
                              className={classes.chip}
                              onDelete={() => handleDelete(d.agency_groups_id)}
                              deleteIcon={<DeleteIcon />}
                            />
                          );
                        }
                      })}
                    </div>{" "}
                    <div>
                      {!!addedAgencyDirectorIds?.length && (
                        <div>
                          {" "}
                          <Typography>Selected directors</Typography>
                        </div>
                      )}
                      {addedAgencyDirectorIds.map((id) => {
                        const name = directors?.find(
                          (a) => a.agency_groups_id === id,
                        );
                        return (
                          <Chip
                            key={id}
                            label={name?.last_name + " " + name?.first_name}
                            className={classes.chip}
                          />
                        );
                      })}
                    </div>
                  </div>
                </Grid>
              )}
            </Box>
          )}
          {(isSuperAdmin || isAdmin) && (
            <Field
              fieldName={"Google doc link"}
              fieldValue={agencyData.google_doc_url}
            />
          )}
        </Card>
      )}
    </>
  );
};

export default BrandingCard;
