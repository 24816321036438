import React, { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useStyles } from "./AgencySettingsPage.styles";
import GeneralSettingsPage from "./GeneralSettingsPage";
import DesignSettingsPage from "./DesignSettingsPage";
import SocialMediaSettingsPage from "./SocialMediaSettingsPage";

const AgencySettingsPage = () => {
  const classes = useStyles();
  const [tab, setTab] = useState("1");

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  return (
    <TabContext value={tab}>
      <Box className={classes.tabsWrapper}>
        <TabList onChange={handleChange}>
          <Tab label="General" value={"1"} />
          <Tab label="Design" value={"2"} />
          <Tab label="Social Media" value={"3"} />
        </TabList>
      </Box>
      <hr className={classes.divider} />

      <TabPanel value={"1"}>
        <GeneralSettingsPage />
      </TabPanel>
      <TabPanel value={"2"}>
        <DesignSettingsPage />
      </TabPanel>
      <TabPanel value={"3"}>
        <SocialMediaSettingsPage />
      </TabPanel>
    </TabContext>
  );
};

export default AgencySettingsPage;
