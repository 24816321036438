import { sub } from "date-fns";
import { AddressShape } from "../auto/types";

export interface CustomQuoteFormShape {
  dwelling: number;
  lossUse: any;
  medPay: any;
  otherStruct: any;
  personalProp: any;
  extendedRep: any;
  personalLiab: any;
  windDed: any;
  generalDed: any;
  lossUseRequired: boolean;
  medpayRequired: boolean;
  otherStructRequired: boolean;
  personalPropRequired: boolean;
  extendedRepRequired: boolean;
  personalLiabRequired: boolean;
  windDedRequired: boolean;
  generalDedRequired: boolean;
}

interface Coverage {
  Limit: number[];
  Premium: number;
  Deductible: number;
  CoverageName: string;
}

interface Coverage2 {
  VIN: string;
  Make: string;
  Year: number;
  Model: string;
  Coverages: Coverage[];
}

export interface QuoteData {
  insurance_level: number;
  premium: string;
  PayPlanDescription: string;
  PayPlanDownPayment: number;
  PayPlanNumOfPayments: number;
  PayPlanPaymentAmount: number;
  TotalPremium: number;
  carrier: string;
  logo_path?: any;
  turbo_quote_id: string;
  coverage: Coverage2[];
}

export interface HomeState {
  activeStep: number;
  start: { address: AddressShape; apt: string; skipLookup?: boolean };
  details: {
    Address1: string;
    Address2: string;
    City: string;
    Construction: string;
    DwellingAmt: number;
    FoundationType: string;
    NumberOfFamilies: number;
    NumberOfFullBaths: number;
    RoofComposition: string;
    SquareFootage: number;
    State: string;
    StoryType: string;
    SwimmingPool: string;
    YearOfConstruction: number;
    ZipCode: string;
    constructionType: string;
    exteriorWalls: string;
    propertyType: string;
    foundation: string;
    roofType: string;
    roofBuilt: number;
    numberOfHalfBaths: number;
    fencedInPool: boolean;
    poolType: string;
    trampoline: string;
    usageType: string;
    manualNumberOfFamilies: string;
    yearPurchased?: number;
    previousAddress?: AddressShape;
  };
  discounts: any;
  insured: Insured;
  contact: {
    email: string;
    phone: string;
  };
  quotes: QuoteData[];
  selectedQuote: any;
  bundleQuotes?: QuoteData[];
  bundleCustomQuotes?: any[];
  bundleQuotesFailed: boolean;
  customQuotes: any[];
  customData: CustomData;
  quotesLoading: boolean;
  coverageInfo: HomeCoverageData;
  rentersCoverageInfo: RentersCoverageData;
}

export interface Insured {
  firstName: string;
  middleName: string;
  lastName: string;
  gender: "M" | "F" | "";
  maritalStatus: "S" | "M" | "D" | "W" | "";
  dob: string | Date;
  addCoapplicant: boolean | "";
  coapplicant?: {
    arrayIndex: number;
    firstName: string;
    middleName: string;
    lastName: string;
    dob: string | Date;
    gender: string;
  };
  effectiveDate: string | Date;
}

export interface HomeCoverageData {
  moreCoverages: string[];
  displayCoverages: string[];
  coverageData: HomeCustomCoverages;
}
export interface RentersCoverageData {
  moreCoverages: string[];
  displayCoverages: string[];
  coverageData: rentersCustomCoverages;
}

interface HomeCustomCoverages {
  other: HomeCustomCoverage | undefined;
  wind_hail: HomeCustomCoverage | undefined;
  loss_of_use: HomeCustomCoverage | undefined;
  medical_payments: HomeCustomCoverage | undefined;
  other_structures: HomeCustomCoverage | undefined;
  personal_property: HomeCustomCoverage | undefined;
  personal_liability: HomeCustomCoverage | undefined;
  extended_replacement: HomeCustomCoverage | undefined;
  water_backup: HomeCustomCoverage | undefined;
}

export interface CustomData {
  dwelling: number | undefined;
  lossUse: string | undefined;
  medPay: string | undefined;
  otherStruct: string | undefined;
  personalProp: string | undefined;
  extendedRep: string | undefined;
  personalLiab: string | undefined;
  windDed: string | undefined;
  generalDed: string | undefined;
  extendedRepEnabled: boolean | undefined;
  waterBackup: string | undefined;
  waterBackupEnabled: boolean | undefined;
  p_p_replacement_cost: boolean | undefined;
}

interface rentersCustomCoverages {
  other: HomeCustomCoverage | undefined;
  wind_hail: HomeCustomCoverage | undefined;
  loss_of_use: HomeCustomCoverage | undefined;
  medical_payments: HomeCustomCoverage | undefined;
  personal_property: HomeCustomCoverage | undefined;
  personal_liability: HomeCustomCoverage | undefined;
  water_backup: HomeCustomCoverage | undefined;
  extended_replacement: undefined;
  other_structures: undefined;
}

interface HomeCustomCoverage {
  default: CustomValue;
  isRequired: boolean;
  Deductibles?: CustomValue[];
  percentage?: CustomValue[];
  Limits?: CustomValue[];
}

interface CustomValue {
  Val1: string;
}

export interface SaveHomeQuoteShape {
  activeStep: number;
  req_uid: string;
  start: any;
  details: any;
  discounts: any;
  insured: any;
  contact: any;
  quoteType: string;
  agentQuoted?: boolean;
  homeCustomData?: any;
}

export const CLAIM_AMOUNTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const DOB_MAX_DATE = sub(new Date(), { years: 18 });
export const NUMBERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const FAMILY_UNITS = [1, 2, 3, 4];

export const CLAIM_DESCRIPTIONS = [
  { label: "Damage to Property of Others", value: "DamageToPropertyOfOthers" },
  { label: "Dog Bite (liability)", value: "DogBiteLiability" },
  { label: "Fire", value: "Fire" },
  { label: "Freezing Water", value: "FreezingWater" },
  {
    label: "Liability (other than dog bite)",
    value: "OtherThanDogBiteLiability",
  },
  { label: "Lightning", value: "Lightning" },
  { label: "Mold - remediated", value: "RemediatedMold" },
  { label: "Mold - not remediated", value: "UnremediatedMold" },
  { label: "Smoke", value: "Smoke" },
  { label: "Theft/Burglary", value: "TheftOrBurglary" },
  { label: "Vandalism", value: "Vandalism" },
  { label: "Water-related", value: "WaterRelated" },
  { label: "Wind", value: "Wind" },
  { label: "Hail", value: "Hail" },
];

export const CONSTRUCTION_TYPE_OPTIONS = [
  { label: "Bi-Level", value: "BL" },
  { label: "Split Level", value: "SL" },
  { label: "Colonial", value: "CO" },
  { label: "Co-op", value: "CP" },
  { label: "Cottage", value: "CG" },
  { label: "Ranch", value: "RA" },
];

export const EXTERIOR_WALLS_OPTIONS = [
  { label: "Brick", value: "B" },
  { label: "Brick Veneer", value: "V" },
  { label: "Frame", value: "F" },
  { label: "Stucco", value: "S" },
];

export const EXTERIOR_WALLS_OPTIONS_INTERNAL = [
  { label: "Brick", value: "B" },
  { label: "Brick Veneer", value: "V" },
  { label: "Stucco", value: "S" },
  { label: "Fire Resistant", value: "R" },
  { label: "Frame", value: "F" },
  { label: "Semi Wind-Resistive", value: "M" },
  { label: "Wind-Resistive", value: "T" },
  { label: "Superior", value: "P" },
  { label: "Stucco", value: "S" },
];

export const STORY_TYPE = [
  { label: "1 Story", value: "OneStory", number: 1 },
  { label: "2 Stories", value: "TwoStories", number: 2 },
  { label: "3 Stories", value: "ThreeStories", number: 3 },
  { label: "4 or More Stories", value: "FourOrMoreStories", number: 4 },
  { label: "Bi-Level", value: "BiLevel", number: 2 },
  { label: "Split-Level", value: "SplitLevel", number: 2 },
];
export const STORY_TYPE_INTERNAL = [
  { label: "1-1/2 Stories", value: "OneAndOneHalfStories", number: 2 },
  { label: "2-1/2 Stories", value: "TwoAndOneHalfStories", number: 2 },
  { label: "Tri-Level", value: "TriLevel", number: 3 },
];

export const FOUNDATION_TYPE = [
  { label: "Basement - Finished", value: "FinishedBasement" },
  { label: "Basement - Unfinished", value: "UnfinishedBasement" },
  { label: "Basement - Walkout", value: "WalkoutBasement" },
  {
    label: "Basement - Partially Finished",
    value: "PartiallyFinishedBasement",
  },
  { label: "Crawlspace", value: "Crawlspace" },
  { label: "Slab", value: "Slab" },
  { label: "Pier And Beam", value: "PierAndBeam" },
  { label: "Other", value: "Other" },
];

export const ROOF_TYPE = [
  { label: "Clay or Concrete Tile", value: "TI" },
  { label: "Slate", value: "SL" },
  { label: "Asphalt", value: "AS" },
  { label: "Metal", value: "MT" },
  { label: "Wood", value: "WS" },
  { label: "Asbestos", value: "AB" },
  { label: "Other", value: "Other" },
];

export const ROOF_TYPE_INTERNAL = [
  { label: "Composite Shingle", value: "CS" },
  { label: "Wood Shingle", value: "WO" },
  { label: "Aluminum", value: "AL" },
  { label: "Steel", value: "ST" },
  { label: "Copper", value: "CO" },
  { label: "Roll Roofing", value: "RO" },
  { label: "Tar and Gravel", value: "TA" },
  { label: "Clay Tile", value: "TI" },
  { label: "Clay or Concrete Tile", value: "TI" },
  { label: "Slate", value: "SL" },
  { label: "Concrete", value: "CN" },
  { label: "Plastic", value: "PL" },
  { label: "Recycled", value: "RE" },
  { label: "Single Ply Membrane Systems", value: "SP" },
  { label: "Asphalt", value: "AS" },
  { label: "Concrete Tile", value: "CT" },
  { label: "Fiberglass", value: "FG" },
  { label: "Gravel", value: "GR" },
  { label: "Asphalt - Fiberglass", value: "AS" },
  { label: "Metal", value: "MT" },
  { label: "Plywood", value: "PY" },
  { label: "Reinforced Concrete", value: "RC" },
  { label: "Spanish Tile", value: "SA" },
  { label: "Tin", value: "TN" },
  { label: "Wood Shake", value: "WS" },
  { label: "Wood", value: "WS" },
  { label: "Asbestos", value: "AB" },
  { label: "Cedar Shakes", value: "CR" },
  { label: "Cedar Shingles", value: "CL" },
  { label: "Rubber", value: "RB" },
  { label: "Rock", value: "RK" },
  { label: "Steel/Porcelain Shingles", value: "PS" },
  { label: "Other", value: "Other" },
];
