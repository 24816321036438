import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";

import CoverageDetails from "./CoverageDetails";
import { useStyles } from "./LifeQuotes.styles";
import { useSelector } from "react-redux";
import {
  lifeActions,
  lifeSelectors,
  uiSelectors,
  useAction,
  wizardSelectors,
} from "../../../../state";
import { Api, Navigation } from "../../../../lib";
import { LifeQuote } from "../../../../state/life/types";
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { ArrowForwardIcon } from "../../../../components";
import { SnagImg } from "../../../../assets/SnagImg";

export default function LifeQuotes() {
  const classes = useStyles();
  const agencyData = useSelector(uiSelectors.agencyData);
  const getQuotes = useAction(lifeActions.getQuote);
  const setSelectedQuote = useAction(lifeActions.setSelectedQuote);

  const quotes = useSelector(lifeSelectors.quotes);
  const loading = useSelector(uiSelectors.loading);
  const hasQuotes = quotes && !!quotes[0]?.name;

  const selectedQuote = useSelector(lifeSelectors.selectedQuote);
  const req_uid = useSelector(wizardSelectors.req_uid);
  const handleSelectedQuote = (q: LifeQuote) => {
    setSelectedQuote(q);

    Api.put(
      `/selected-quote/quote-type/life/${req_uid}/txn/${req_uid}+${q.companyCode}`,
      { total_premium: +q.premium.replace(",", "") },
    );
  };
  const [showMonthly, setShowMonthly] = useState(true);

  useEffect(() => {
    if (selectedQuote) {
      Navigation.go(`/life/wizard/quotes/${req_uid}`);
    }
  }, [selectedQuote]);
  useEffect(() => {
    getQuotes();
  }, []);

  return (
    <>
      <CoverageDetails />
      {hasQuotes ? (
        <>
          <Box className={classes.switcherWrapper}>
            <ToggleButtonGroup
              className={classes.switcherGroup}
              exclusive
              size="large"
              value={!!showMonthly}
              onChange={() => {
                setShowMonthly(!showMonthly);
              }}
            >
              <ToggleButton value={true}>Billed monthly</ToggleButton>
              <ToggleButton value={false}>Billed Annually</ToggleButton>
            </ToggleButtonGroup>
          </Box>
          {!selectedQuote && (
            <Grid className={classes.quotesContainer}>
              {quotes?.map((q) => {
                return (
                  <Box
                    className={classes.quote}
                    key={q.productName + q.premium}
                  >
                    <Box className={classes.quoteProduct}>
                      <div className={classes.logoContainer}>
                        <img
                          src={`https://www.compulifeapi.com/images/logosapi/${q.compImgUrl}-small.png`}
                          className={classes.logo}
                        />
                        <span className={classes.mobileRatingWrapper}>
                          <span className={classes.mobileRating}>
                            {q.amRating}
                          </span>
                        </span>
                      </div>
                      <Box>
                        <Typography className={classes.prodDesc}>
                          <span className={classes.prodName}>
                            {q.productName}
                          </span>
                        </Typography>
                        <Typography className={classes.prodDesc}>
                          <span className={classes.mobileNone}>
                            {" "}
                            A.M. Best Rating{" "}
                          </span>
                          <span className={classes.rating}>{q.amRating}</span>
                        </Typography>
                      </Box>
                    </Box>
                    <Box className={classes.quoteRight}>
                      <div className={classes.price}>
                        ${showMonthly ? q.monthlyPremium : q.premium}{" "}
                        <span className={classes.priceInterval}>
                          {showMonthly ? "/mo" : "/yr"}
                        </span>
                        <span className={classes.mobileInterval}>
                          {showMonthly ? "/month" : "/year"}
                        </span>
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                          handleSelectedQuote(q);
                        }}
                      >
                        Apply now
                      </Button>
                      <div
                        className={classes.mobileButton}
                        onClick={() => {
                          handleSelectedQuote(q);
                        }}
                      >
                        <ArrowForwardIcon />
                      </div>
                    </Box>
                  </Box>
                );
              })}
            </Grid>
          )}
        </>
      ) : !loading ? (
        <Box className={classes.NoQuotesContainer}>
          <Box className={classes.image} mb={2}>
            <SnagImg fillColor={agencyData.theme.palette?.primary.main} />
          </Box>
          <Typography>
            There are no companies offering the selection you requested. Please
            change the length of the term and try again.
          </Typography>
        </Box>
      ) : null}
    </>
  );
}
