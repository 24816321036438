import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  totalAmounts: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0 20px 0",
    width: "65%",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
      gap: 8,
      flexWrap: "wrap",
      padding: 0,
    },
  },
  totalItem: {
    width: 224,
    padding: 16,
    display: "flex",
    backgroundColor: "#DCF8E7",
    borderRadius: 3,
    [theme.breakpoints.down('sm')]: {
      width: "47%",
      padding: 0,
      height: 70,
    },
  },
  total: {
    backgroundColor: "#2eb662",
    color: "white",
    fontWeight: 600,
    fontSize: 16,
    height: 47,
    width: 47,
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      height: 32,
      width: 32,
      margin: "20px 15px 20px 20px ",
    },
  },
  totalText: {
    display: "flex",
    alignItems: "center",
    padding: 12,
    fontWeight: 600,
    fontSize: 17,
    color: "#2eb662",
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      padding: 0,
    },
  },
  mobileColumns: {
    border: "1px solid #DADFF6",
    width: "65%",
    paddingRight: 20,
    [theme.breakpoints.down('lg')]: {
      textAlign: "center",
      width: "100%",
      padding: 0,
    },
  },
  mobileTitle: { fontWeight: 600, padding: 20, display: "flex" },
}));
