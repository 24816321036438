export interface AddressShape {
  zipcode: string;
  state: string;
  full_state?: string;
  city: string;
  street_line: string;
  label?: string;
}

export interface StartWizardShape {
  first_name: string;
  middle_name: string;
  last_name: string;
  address: AddressShape;
  apt?: string;
  skipLookup: boolean;
}

export interface VehiclesWizardShape {
  selectedVehicles?: any[];
  notListedVehicles?: boolean;
}

export interface StartFormShape {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  address?: AddressShape;
  addressError?: string;
  apt?: string;
  skipLookup?: boolean;
}

export interface CustomQuoteFormShape {
  bi?: any;
  pd?: any;
  um?: any;
  undum?: any;
  umpd?: any;
  umpdded?: any;
  undpd?: any;
  uimpdded?: any;
  medex?: any;
  pip?: any;
  pipded?: any;
  attendantcare?: any;
  medicalexpenseonly?: any;
  extendedmedical?: any;
  obel?: any;
  tort?: any;

  umpdEnabled?: boolean;
  pipEnabled?: boolean;
  medEnabled?: boolean;

  vehicles?: any;
  biRequired?: boolean;
  pdRequired?: boolean;
  umRequired?: boolean;
  umpdRequired?: boolean;
  umpdDeductibleRequired?: any;
  medpmRequired?: boolean;
  collRequired?: boolean;
  compRequired?: boolean;
  tlRequired?: boolean;
  rreimRequired?: boolean;
  pipRequired?: boolean;
  pipDeductibleRequired?: boolean;
  attendantCareRequired?: boolean;
  medicalExpenseOnlyRequired?: boolean;
  extendedMedicalRequired?: boolean;
  tortRequired?: boolean;
}

export interface VehiclesFormShape {
  selectedVehicles?: SelectedVehiclesShape[];
  submittedVehicles?: any[];
  notListedVehicles?: boolean;
  vinError?: string;
}

export interface DriversFormShape {
  selectedDrivers: DriverShape[];
  applicantIndex: number | undefined;
}

export interface DiscountsFormShape {
  months_coverage?: number;
  bodily_injury?: {
    perAcc: number;
    perPerson: number;
  };
  residence_owned_rented?: "O" | "R" | "";
  employment_military?: boolean;
  paperless_discount?: boolean;
  autopay?: boolean;
  whoDrives?: string;
  effectiveDate?: string;
}

export interface UDDInfoResponse {
  responseData: {
    vehicles?: UDDVehiclesShape[];
    drivers?: UDDDriversShape[];
  };
}

export interface UDDVehiclesShape {
  ModelYear?: number;
  Maker?: string;
  Model?: string;
  LicensePlate?: string;
  RegistrationNumber?: string;
  OwnerName?: string;
  VIN?: string;
  Error?: string;
}
export interface SelectedVehiclesShape extends UDDVehiclesShape {
  Error?: string;
  makes?: string[];
  models?: string[];
  usage?: string;
  Type?: string;
}

export interface UDDDriversShape {
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  Age?: number;
  Address?: string;
  City?: string;
  State?: string;
  Zip?: string;
  DLNumber?: string;
  isChecked: boolean;
}
export interface DriverShape extends UDDDriversShape {
  isUserEntered?: boolean;
  udd_id?: number;
  spouse: boolean;
  tickets: any[];
  continuous_insurance: string;
  no_insurance_reason: string;
  accidents: boolean;
  collision: boolean;
  relationship_to_applicant: string;
  gender: "M" | "F" | "";
  marital_status: "S" | "M" | "D" | "W" | "";
  dob: string;
  education_level: string;
  industry: string;
  occupation_class: string;
  isSubmitted: boolean;
  moreDrivers: boolean;
  manualLicense?: string;
}

export interface VehicleInitialValuesShape {
  VIN?: string;
  ModelYear?: string;
  Maker?: string;
  Model?: string;
  Type?: string;
  miles?: number;
  usage?: string;
  moreVehicles?: boolean | undefined;
}

export interface SaveQuoteDataShape {
  activeVehicleIndex?: number;
  activeDriverIndex?: number;
  startForm?: any;
  vehiclesForm?: any;
  driversForm?: any;
  discountsForm?: any;
  req_uid?: string;
  quoteType?: string;
  contact?: any;
  shopperApprovedShown?: boolean;
  agentQuoted?: boolean;
  autoCustomData?: any;
}

interface Coverage {
  Limit: number[];
  Premium: number;
  Deductible: number;
  CoverageName: string;
}

interface Coverage2 {
  VIN: string;
  Make: string;
  Year: number;
  Model: string;
  Coverages: Coverage[];
}

export interface QuoteData {
  insurance_level: number;
  premium: string;
  PayPlanDescription: string;
  PayPlanDownPayment: number;
  PayPlanNumOfPayments: number;
  PayPlanPaymentAmount: number;
  TotalPremium: number;
  carrier: string;
  logo_path?: any;
  turbo_quote_id: string;
  coverage: Coverage2[];
}

export const REASON_FOR_NO_INS = [
  { label: "Driving a company car", value: "CompanyCar" },
  { label: "Did not own or operate a vehicle", value: "DidNotOwnVehicle" },
  { label: "Car in storage", value: "CarInStorage" },
  { label: "Deployed military", value: "DeployedMilitary" },
  { label: "Driving a car owned by a relative", value: "UnderOthersPolicy" },
  { label: "Other", value: "Other" },
];

export const QUOTE_STATUSES = [
  { status: "New", sub_status: "New lead" },
  { status: "New", sub_status: "Need to present" },
  { status: "In progress", sub_status: "Agent reviewed" },
  { status: "Pending", sub_status: "Waiting on client" },
  { status: "Pending", sub_status: "Need payment info" },
  { status: "Pending", sub_status: "Need app signature" },
  { status: "Closed", sub_status: "Won" },
  { status: "Closed", sub_status: "Price too high" },
  { status: "Closed", sub_status: "No response" },
];

export const QUOTE_TYPES = [
  { label: "Auto", value: "auto" },
  { label: "Homeowners", value: "home" },
  { label: "Renters", value: "renters" },
  { label: "Life", value: "life" },
  { label: "Auto + Home", value: "bundle" },
  { label: "Auto + Renters", value: "autoRenters" },
  { label: "Workers Comp", value: "WC" },
  { label: "Business owners", value: "BOP" },
  { label: "All", value: "" },
];

function createDateVal(number) {
  const d = new Date();
  return new Date(d.setDate(d.getDate() - number)).toISOString().split("T")[0];
}

const yearToDate = new Date(new Date().getFullYear(), 0, 1)
  .toISOString()
  .split("T")[0];

export const DATE_INTERVALS = [
  {
    label: "Last 7 days",
    value: createDateVal(7),
  },
  {
    label: "Last 30 days",
    value: createDateVal(30),
  },
  {
    label: "Last 60 days",
    value: createDateVal(60),
  },
  {
    label: "Last 90 days",
    value: createDateVal(90),
  },
  {
    label: "Last year",
    value: createDateVal(365),
  },
  {
    label: "Year to date",
    value: yearToDate,
  },
  // { label: "Custom", value: "" },
];
