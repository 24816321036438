import React, { useCallback, useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { RestartAltIcon as RestartIcon } from "../../components";
import { useLocation } from "react-router-dom";
import { Navigation } from "../../lib";
import AgentIcon from "../../assets/img/5minute-avatar.png";
import {
  autoActions,
  autoSelectors,
  homeActions,
  lifeActions,
  uiSelectors,
  useAction,
  useSelector,
  wizardActions,
  wizardNoPersistSelectors,
} from "../../state";
import { useMobile } from "../../themes";
import { useStyles } from "./WizardLayout.styles";
import AutoCustomQuoteForm from "../../pages/wizard/auto/custom/CustomQuoteForm";
import HomeCustomQuoteForm from "../../pages/wizard/home/custom/CustomQuoteForm";
import { Add } from "@mui/icons-material";
import { useAgentTitle } from "../../hooks";
import BackNavigation from "./stepper/WizardStepper/WizardTitle/BackNavigation";
import { REACT_APP_ASSETS_URL } from "../../config";

export default function WizardTitle() {
  const classes = useStyles();
  const isMobile = useMobile();
  const isTablet = useMobile("lg");
  const location = useLocation();
  const refreshAutoState = useAction(autoActions.resetState);
  const refreshHomeState = useAction(homeActions.refreshHomeState);
  const refreshWizardState = useAction(wizardActions.refreshWizardState);
  const getWizardSettings = useAction(wizardActions.getWizardSettings);
  const refreshLifeState = useAction(lifeActions.resetState);
  const loading = useSelector(uiSelectors.loading);

  const [scrolled, setScrolled] = useState<Boolean>(false);
  const [scrollTop, setScrollTop] = useState(0);

  const title: string | string[] = useAgentTitle();
  const [customQuoteModal, setCustomQuoteModal] = useState<boolean>(false);
  const activeDriverIndex = useSelector(autoSelectors.activeDriverIndex);
  const selectedDrivers = useSelector(autoSelectors.selectedDrivers);
  const { default_agent_image_path } = useSelector(uiSelectors.agencyData);
  const driver = selectedDrivers && selectedDrivers[activeDriverIndex];
  const showSpouseSubTitle =
    location.pathname.includes("/wizard/drivers") && driver?.spouse;

  const mode = useSelector(wizardNoPersistSelectors.mode);

  const showCustomQuoteButton = () => {
    return (
      !isMobile &&
      !isTablet &&
      (location.pathname === "/home/wizard/quotes" ||
        location.pathname === "/renters/wizard/quotes" ||
        location.pathname === "/auto/wizard/quotes")
    );
  };

  const handleStartOver = () => {
    refreshAutoState();
    refreshHomeState();
    refreshWizardState();
    refreshLifeState();
    if (location.pathname === `/${mode}/wizard/start`) {
      Navigation.reload();
    } else {
      Navigation.go(`/${mode}/wizard/start`);
    }
    getWizardSettings();
  };

  const handleCustomQuoteModal = useCallback(() => {
    setCustomQuoteModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setCustomQuoteModal(false);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const onScroll = (e) => {
        setScrollTop(e.target.documentElement.scrollTop);
      };
      window.addEventListener("scroll", onScroll);
      if (scrollTop > 60) {
        setScrolled(true);
      } else if (scrollTop < 10) {
        setScrolled(false);
      }
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, [scrollTop]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      direction="column"
      className={`${classes.wizardTitleContainer} ${
        scrolled ? "scrolled" : ""
      }`}
    >
      <BackNavigation />
      <img
        src={
          default_agent_image_path
            ? `${REACT_APP_ASSETS_URL}/${default_agent_image_path}`
            : AgentIcon
        }
        className={classes.agentIcon}
      />
      <Typography
        align="center"
        className={classes.title}
        id="wizard-agent-quote"
        variant="h1"
      >
        {title}
      </Typography>
      {location.pathname === `/life/wizard/coverage` && (
        <Typography>Don't worry you'll be able to change this later</Typography>
      )}
      <Tooltip title="Start Over">
        <IconButton
          onClick={handleStartOver}
          className={classes.startOverIcon}
          disabled={loading}
          size="large"
        >
          <RestartIcon />
        </IconButton>
      </Tooltip>
      {showSpouseSubTitle && (
        <Typography align="center" style={{ fontWeight: 300 }}>
          Most insurances require spouses to be included in coverage
        </Typography>
      )}
      {showCustomQuoteButton() && (
        <Button
          className={classes.customQuoteBtn}
          onClick={handleCustomQuoteModal}
          // disabled={loading}
        >
          <Add />
          Customize Coverages
        </Button>
      )}
      <Modal
        className={classes.modal}
        open={customQuoteModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ className: classes.backdrop_custom }}
      >
        <Fade in={customQuoteModal}>
          <Paper square variant="outlined" className={classes.paper}>
            {mode === "auto" ? (
              <AutoCustomQuoteForm
                handleCloseModal={handleCloseModal}
                bundled={false}
                setFormValue={null}
              />
            ) : (
              <HomeCustomQuoteForm
                handleCloseModal={handleCloseModal}
                bundled={false}
                setFormValue={null}
              />
            )}
          </Paper>
        </Fade>
      </Modal>
    </Grid>
  );
}
