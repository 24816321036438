import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  formSection: {
    display: "block",
    marginBottom: 40,
    "& .datepicker": {
      width: "304px",
      maxWidth: "100%",
    },
  },
  formLabel: {
    color: "#000",
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 16,
    lineHeight: "1.2",
    "&.Mui-focused": {
      color: "inherit",
    },
  },
  inputHalf: {
    width: "calc(50% - 20px)",
  },
  inputThird: {
    width: "calc(40% - 20px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 20,
    },
  },
  inputSmall: {
    width: "calc(20% - 20px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 20,
    },
  },
  emailInfo: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    marginTop: 8,
    "& svg": {
      fontSize: 18,
      marginRight: 4,
    },
  },
  toggle: {
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  nextButton: {
    width: 220,
    height: 48,
    display: "block",
    margin: "0 auto 40px",
  },
  error: {
    "& .MuiRadio-root": {
      color: "#f44336",
    },
    "& .MuiToggleButton-root": {
      borderColor: "#f44336",
    },
  },
  checkboxWrap: {
    alignSelf: "flex-start",
  },
  content: {
    paddingTop: 9,
    paddingBottom: 9,
    fontWeight: 500,
    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  list: {
    width: "100%",
    marginBottom: 24,
  },
  item: {
    marginBottom: 16,
    padding: "12px 24px 12px 24px",
    borderRadius: 3,
    boxShadow: `0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC`,
    cursor: "pointer",

    "&:hover": {
      boxShadow: `0 2px 40px 2px ${theme.palette.secondary.contrastText}, 0 2px 24px 0 #DADFF6, 0 2px 40px 0 #DADFF6`,
    },
  },
  info: {
    display: "flex",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
      color: "#AFB6D4",
      margin: "2px 0 0 5px",
      "&:hover": { cursor: "pointer" },
    },
  },
}));
