import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Checkbox,
  Box,
  Slider,
  Modal,
  Paper,
} from "@mui/material";

import Cropper from "react-easy-crop";

import {
  useAction,
  adminActions,
  useSelector,
  authSelectors,
  authClient,
  uiActions,
  useDispatch,
} from "../../../state";
import { useFormik, yup } from "../../../lib";

import { useStyles } from "./ProfilePage.styles";
import { AccountCircleIcon, DeleteIcon } from "../../../components";
import CloudUpload from "@mui/icons-material/CloudUpload";
import { REACT_APP_ASSETS_URL } from "../../../config";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  phone: yup.string().required("Phone Number is required"),
});

const ProfilePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useSelector(authSelectors.user);
  const role = useSelector(authSelectors.activeRole);

  const updateUser = useAction(adminActions.updateUserProfile);

  const initialPixels = { height: 0, width: 0, x: 0, y: 0 };

  const [initialValues, setInitialValues] = useState(user);
  const [openCropper, setOpenCropper] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [pixels, setPixels] = useState(initialPixels);
  const [tempFilePath, setTempFilePath] = useState<string>("");

  const fileUploadRef = useRef<FormData>();
  const fileRef = useRef(null);
  const _fileRef: any = fileRef.current;

  useEffect(() => {
    setInitialValues({ ...user, role });
  }, [user]);

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values) {
      updateUser({
        id: values.id,
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        profile_image_path: values.profile_image_path || "",
        use_profile_image: values.use_profile_image,
        disable_notifications: values.disable_notifications,
      });
    },
  });

  async function saveFile(file) {
    fileUploadRef.current = new FormData();
    fileUploadRef.current.append("file", file);

    const { status, data } = await authClient.post(
      "/file-upload",
      fileUploadRef.current,
    );
    if (status === 200) {
      setTempFilePath(data.path);
      setOpenCropper(true);
    } else {
      dispatch(uiActions.showError("Error uploading image"));
    }
  }

  async function cropFile() {
    const { status, data } = await authClient.post("/crop-and-save", {
      original_filepath: tempFilePath,
      pixels,
    });
    if (status === 200) {
      form.setFieldValue("profile_image_path", data.path);
    } else {
      dispatch(uiActions.showError("Error cropping image"));
    }
    setOpenCropper(false);
  }

  async function handleFileDrop(event) {
    const file = event.target.files && event.target.files[0];
    setPixels(initialPixels);
    saveFile(file);
  }

  async function handleCropComplete(area, pixels) {
    setPixels(pixels);
  }

  async function handleCropCancel() {
    setOpenCropper(false);
  }

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid item xs={12}>
            <Typography className={classes.title}>Profile</Typography>
            {form.values.profile_image_path ? (
              <img
                src={`${REACT_APP_ASSETS_URL}/${form.values.profile_image_path}`}
                className={classes.profileImage}
              ></img>
            ) : (
              <AccountCircleIcon className={classes.profileImage} />
            )}
            <input
              id="fileInput"
              className={classes.uploader}
              type="file"
              ref={fileRef}
              onChange={(e) => handleFileDrop(e)}
            ></input>
            {form.values.profile_image_path ? (
              <Box className={classes.imageButtonsWrapper}>
                <Box
                  className={classes.uploadWrapper}
                  onClick={() => _fileRef.click()}
                >
                  <CloudUpload className={classes.icon} />
                  <Typography className={classes.mediumText}>
                    Replace photo
                  </Typography>
                </Box>
                <Box
                  className={classes.uploadWrapper}
                  onClick={() => form.setFieldValue("profile_image_path", "")}
                >
                  <DeleteIcon className={classes.icon} />
                  <Typography className={classes.mediumText}>
                    Remove photo
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                className={classes.uploadWrapper}
                onClick={() => _fileRef.click()}
              >
                <CloudUpload className={classes.icon} />
                <Typography className={classes.mediumText}>
                  Upload photo
                </Typography>
              </Box>
            )}

            <Modal
              open={openCropper}
              onClose={() => {
                setOpenCropper(false);
              }}
            >
              <Paper className={classes.paper}>
                <Typography className={classes.title}>Crop photo</Typography>
                <Box>
                  <Cropper
                    image={`${REACT_APP_ASSETS_URL}/${tempFilePath}`}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    cropShape="round"
                    showGrid={false}
                    onCropChange={(crop) => setCrop(crop)}
                    onCropComplete={(area, pixels) => {
                      handleCropComplete(area, pixels);
                    }}
                    onZoomChange={(zoom) => setZoom(zoom)}
                  />
                </Box>
                <Box className={classes.slider}>
                  <Slider
                    value={zoom}
                    min={1}
                    max={10}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, z) => {
                      if (Array.isArray(z)) {
                        setZoom(z[0]);
                      } else {
                        setZoom(z);
                      }
                    }}
                  />
                </Box>
                <Box className={classes.btnWrapper}>
                  <Button
                    variant="contained"
                    onClick={() => handleCropCancel()}
                    className={classes.cancelBtn}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.submitBtn}
                    onClick={() => cropFile()}
                  >
                    Save
                  </Button>
                </Box>
              </Paper>
            </Modal>
          </Grid>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="first_name"
                name="first_name"
                label="First Name"
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                value={form.values.first_name}
                onChange={form.handleChange}
                error={
                  form.touched.first_name && Boolean(form.errors.first_name)
                }
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="last_name"
                name="last_name"
                label="Last Name"
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                value={form.values.last_name}
                onChange={form.handleChange}
                error={form.touched.last_name && Boolean(form.errors.last_name)}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControlFull}>
              <TextField
                disabled
                fullWidth
                variant="outlined"
                type="email"
                id="email"
                name="email"
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                label="Email Address"
                value={form.values.email}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.formControlFull}>
            <TextField
              fullWidth
              variant="outlined"
              id="phone"
              name="phone"
              label="Phone Number"
              required={true}
              InputLabelProps={{
                classes: {
                  asterisk: classes.asterisk,
                },
              }}
              value={form.values.phone}
              onChange={form.handleChange}
              error={form.touched.phone && Boolean(form.errors.phone)}
            />
          </Grid>
          {role !== "affiliate_agent" && (
            <Grid item xs={12} className={classes.formControlFull}>
              <Box className={classes.flexWrapper}>
                <Checkbox
                  edge="start"
                  checked={form.values.use_profile_image}
                  disableRipple
                  color="primary"
                  onClick={() =>
                    form.setFieldValue(
                      "use_profile_image",
                      !form.values.use_profile_image,
                    )
                  }
                />
                <Typography className={classes.bigText}>
                  Use my profile details in my personal links and emails.
                </Typography>
              </Box>
              <Typography className={classes.smallText}>
                When checked, your first name and photo will appear in your
                personal links and emails. Uncheck to use the default agent
                avatar.
              </Typography>

              <Box className={classes.flexWrapper}>
                <Checkbox
                  edge="start"
                  checked={form.values.disable_notifications}
                  disableRipple
                  color="primary"
                  onClick={() =>
                    form.setFieldValue(
                      "disable_notifications",
                      !form.values.disable_notifications,
                    )
                  }
                />
                <Typography className={classes.bigText}>
                  Disable quote notifications
                </Typography>
              </Box>
              <Typography className={classes.smallText}>
                When checked, you will not receive notifications about your
                customer's quotes.
              </Typography>
            </Grid>
          )}
          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.saveBtn}
                disabled={
                  form.isSubmitting ||
                  !form.values.first_name ||
                  !form.values.last_name
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default ProfilePage;
