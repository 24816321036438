import React, { useEffect } from "react";
import { Button, Card, CardContent, Grid, TextField } from "@mui/material";

import { useFormik, yup } from "../../../../lib";
import { useAction, adminActions } from "../../../../state";

import { useStyles } from "./CreateFAQCategoryPage.styles";

const initialValues = {
  category_name: "",
  order: 0,
};

const validationSchema = yup.object({
  category_name: yup.string().required("Category Name is required"),
  order: yup.number().required("Order is required"),
});

const CreateFAQCategoryPage = () => {
  const classes = useStyles();

  const setPageTitle = useAction(adminActions.setPageTitle);
  const createFAQCategory = useAction(adminActions.createFAQCategory);

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values) {
      createFAQCategory(values);
    },
  });

  useEffect(() => {
    setPageTitle("Create FAQ Category");
  }, [setPageTitle]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="category_name"
                name="category_name"
                label="Category Name"
                value={form.values.category_name}
                onChange={form.handleChange}
                error={
                  form.touched.category_name &&
                  Boolean(form.errors.category_name)
                }
                helperText={
                  form.touched.category_name && form.errors.category_name
                }
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                id="order"
                name="order"
                label="Order"
                value={form.values.order}
                onChange={form.handleChange}
                error={form.touched.order && Boolean(form.errors.order)}
                helperText={form.touched.order && form.errors.order}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={form.isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateFAQCategoryPage;
