import { useEffect } from "react";
import { useAction, useSelector, autoActions, autoSelectors } from "../state";

const useIndustriesOccupations = () => {
  const getIndustriesOccupations = useAction(
    autoActions.getIndustriesOccupations,
  );

  const industriesOccupations = useSelector(
    autoSelectors.industriesOccupations,
  );
  const industries = useSelector(autoSelectors.industries);
  const industriesOccupationsLoading = useSelector(
    autoSelectors.industriesOccupationsLoading,
  );

  useEffect(() => {
    if (!industriesOccupations) {
      getIndustriesOccupations();
    }
  }, [industriesOccupations, getIndustriesOccupations]);

  return {
    industriesOccupations,
    industries,
    industriesOccupationsLoading,
  };
};

export default useIndustriesOccupations;
