import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import { AddIcon } from "../../components/icons";

import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
} from "../../state";
import AdminPages from ".";
import { PRODUCTS_COLUMNS as columns } from "../../constants/tables";

import { useStyles } from "./ProductsPage.styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function CarriersPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const referralProducts = useSelector(adminSelectors.referralProducts);
  const [pageSize, setPageSize] = React.useState(25);

  useEffect(() => {
    dispatch(adminActions.getReferralProducts());
    dispatch(adminActions.setPageTitle("Products"));
  }, [dispatch]);

  return (
    <div className={classes.page}>
      <div className={classes.topArea}>
        <Link className={classes.link} to={AdminPages.addProducts.path}>
          <Button variant="outlined" color="primary">
            <AddIcon /> Add Product
          </Button>
        </Link>
      </div>
      {/* <div className={classes.dataTable}> */}
      <DataGridPro
        rows={referralProducts}
        columns={columns}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
    // </div>
  );
}
