import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
    transition: "all 300ms linear",
    maxWidth: ({ quoteCount }: any) =>
      quoteCount === 3 ? 960 : quoteCount === 4 ? 1270 : 1530,
    [theme.breakpoints.down(1850)]: {
      maxWidth: ({ quoteCount }: any) => (quoteCount === 3 ? 960 : 1226),
    },
    [theme.breakpoints.down(1625)]: {
      maxWidth: ({ quoteCount }: any) => (quoteCount === 3 ? 922 : 922),
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  coverageLevelSection: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    gap: 24,
  },
  card: {
    width: "100%",
    padding: "18px 32px",
    borderRadius: 3,
    boxShadow: "0 2px 12px 0 #F5F6FE",
    maxHeight: 140,
  },
  premium: {
    color: theme.palette.primary.main,
    fontSize: 40,
    fontWeight: 400,
  },
  mo: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 12,
    paddingRight: 0,
  },
  coverageLevelTitle: {
    color: "#000000",
    fontSize: 20,
    fontWeight: 600,
    margin: "0 auto",
  },
  carrierImg: {
    height: 20,
  },
  iconButtonContainer: {
    marginRight: 0,
    padding: 0,
  },
  noQuotes: {
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: 14,
    textAlign: "center",
  },
  selectText: {
    textTransform: "uppercase",
    fontSize: "13px",
    alignItems: "center",
    fontWeight: 500,
    letterSpacing: 0.5,
    color: "#8D98C2",
    cursor: "pointer",
  },
}));
