import { Api, history } from "../../lib";
import { v4 as uuidv4 } from "uuid";
import { mapHomeQuoteValues } from "../../utils/mapping_Home";
import { uiActions } from "../ui/actions";
import { wizardActions } from "../wizard/actions";
import { genericErrorMsg } from "../auto/actions";
import { home } from "./state";
import { SaveHomeQuoteShape } from "./types";
import { adminActions } from "../admin/actions";
import { getReferralToken } from "../../utils";
const { actions } = home;

export const homeActions = {
  ...actions,
  getPropertyLookup(obj, grecaptchaToken, forLookup, propertyType) {
    obj.grecaptchaToken = grecaptchaToken;
    obj.manualforLookup = forLookup;
    obj.propertyType = propertyType;
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await Api.post(`/property-lookup`, obj);
      dispatch(actions.setHomeDetails(data.data));
      dispatch(uiActions.setLoading(false));
    };
  },
  submitQuote(is_linked = false, isCustom = false) {
    return async (dispatch, getState) => {
      const { start, insured, details, discounts, customData } =
        getState().home;
      const { contactInfo: contact, req_uid } = getState().wizard;
      const { mode } = getState().wizardNoPersist;

      const quotePayload = mapHomeQuoteValues({
        start,
        insured,
        details,
        discounts,
        contact,
        custom_quote: isCustom,
        customData,
        is_linked,
        mode,
      });

      if (isCustom) {
        dispatch(wizardActions.setCustomQuotesLoading(true));
        dispatch(homeActions.setPlaceHolderCustomQuote(is_linked));
      } else {
        if (mode === "home" || mode === "renters") {
          dispatch(uiActions.setLoading(true));
          dispatch(uiActions.setLoadingMessage("Loading quotes..."));
        } else if (mode === "bundle" || mode === "auto-renters") {
          dispatch(homeActions.setQuotesLoading(true));
        }
      }

      const quoteParams =
        isCustom || mode === "bundle" || mode === "auto-renters"
          ? {
              ...quotePayload,
              req_uid,
            }
          : {
              ...quotePayload,
            };

      try {
        const url = mode === "home" || mode === "bundle" ? "home" : "renters";
        const {
          data: { req_uid: id },
          status,
        } = await Api.post(`/${url}-quote`, quoteParams);
        if (status === 200) {
          dispatch(wizardActions.setReqUid(id));
          await dispatch(wizardActions.getQuotes(id, `${url}`, is_linked));
          dispatch(homeActions.saveWizardInfo());
        } else {
          history.push("/hit-snag");
        }
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.setLoadingMessage(null));
        dispatch(homeActions.setQuotesLoading(false));
        dispatch(wizardActions.setCustomQuotesLoading(false));
        return id;
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.setLoadingMessage(null));
        dispatch(wizardActions.setCustomQuotesLoading(false));
        dispatch(uiActions.showError(genericErrorMsg));
        throw e;
      }
    };
  },
  setAgentCustomForm() {
    return async (dispatch, getState) => {
      const req_uid = uuidv4();
      const { start, insured, details, discounts, customData } =
        getState().home;
      const { contactInfo: contact } = getState().wizard;
      const { mode } = getState().wizardNoPersist;
      const { user } = getState().auth;
      const referrer = getReferralToken(user);
      const quotePayload = mapHomeQuoteValues({
        start,
        insured,
        details,
        discounts,
        contact,
        custom_quote: true,
        customData,
        is_linked: false,
        mode,
      });
      dispatch(uiActions.setLoading(true));
      dispatch(uiActions.setLoadingMessage("Loading quotes..."));
      dispatch(wizardActions.setReqUid(req_uid));
      try {
        const url = mode === "home" || mode === "bundle" ? "home" : "renters";
        const { status } = await Api.post(`/${url}-quote`, {
          ...quotePayload,
          req_uid,
          agentQuoted: true,
          referrer,
        });
        if (status === 200) {
          dispatch(homeActions.saveWizardInfo(true));
          dispatch(adminActions.getNewAgentQuotes(req_uid));
        } else {
          history.push("/something-went-wrong");
        }
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.setLoadingMessage(null));
        dispatch(uiActions.showError(genericErrorMsg));
        throw e;
      }
    };
  },
  saveSelectedQuote(req_uid, req_txn_id, quoteType) {
    return async () => {
      await Api.put(
        `/selected-quote/quote-type/${quoteType}/${req_uid}/txn/${req_txn_id}`,
      );
    };
  },
  saveWizardInfo(agentQuoted = false) {
    return async (dispatch, getState) => {
      const { home, wizard } = getState();
      const { mode } = getState().wizardNoPersist;
      const data: SaveHomeQuoteShape = {
        activeStep: home.activeStep,
        req_uid: wizard.req_uid,
        start: home.start,
        details: home.details,
        discounts: home.discounts,
        insured: home.insured,
        homeCustomData: home.customData,
        contact: wizard.contactInfo,
        quoteType: mode,
        agentQuoted,
      };
      Api.put(`/quote-data/${wizard.req_uid}`, data);
    };
  },
};
