import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  statItem: {
    display: "flex",
    boxSizing: "border-box",
    // height: 115,
    width: 224,
    border: "1px solid #DADFF6",
    borderRadius: 3,
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: 15,
    },

    // lineHeight: "normal",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    height: 47,
    width: 47,
    borderRadius: 3,
    backgroundColor: "rgb(233 236 246)",
  },
  statNum: {
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1,
    // lineHeight: "80%",
  },
  statText: {
    fontSize: 16,
    // fontWeight: 500,
    // lineHeight: "80%",
  },
  statWrapper: {
    marginLeft: 17,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // lineHeight: "inherit",
  },

  text: { fontSize: 16, fontWeight: 500 },

  totalAmounts: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0 20px 0",
    width: "65%",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
      gap: 8,
      flexWrap: "wrap",
      padding: 0,
    },
  },

  mobileColumns: {
    border: "1px solid #DADFF6",
    width: "65%",
    paddingRight: 20,
    [theme.breakpoints.down('lg')]: {
      textAlign: "center",
      width: "100%",
      padding: 0,
    },
  },
  mobileTitle: { fontWeight: 600, padding: 20, display: "flex" },

  mobileInfo: { display: "flex", alignItems: "center" },
}));
