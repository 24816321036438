import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import {
  autoActions,
  autoSelectors,
  homeActions,
  homeSelectors,
  useAction,
  useSelector,
  wizardActions,
  wizardSelectors,
  wizardNoPersistSelectors,
} from "../../../state";
import ChatWidget from "../../../components/chat";
import { defaultTheme, useMobile } from "../../../themes";
import { DirectionsCar, HomeIcon } from "../../../components/icons";
import { Home2Image } from "../../../assets/img/home/two";
import { KeyboardArrowDown, KeyboardArrowUp } from "../../../components/icons";
import { Navigation, useOnMount } from "../../../lib";
import { WizardSide2 } from "../../../assets/img/auto/wizard-side-2";
import PayForm from "../payment/PayForm";
import WizardCompleteCard from "../quotes/cards/WizardCompleteCard";
import ContactCards from "./ContactCards";
import QuoteCard from "../quotes/cards/QuoteCard";
import { useStyles } from "./SelectedQuote.styles";

export default function Quotes() {
  const classes = useStyles({});
  const isMobile = useMobile();
  const params: { id: string } = useParams();
  const [bundleBoxOpen, setBundleBoxOpen] = useState<boolean>(true);
  const contactType = useSelector(wizardSelectors.contactType);
  const clearSelectedAutoQuote = useAction(autoActions.clearSelectedAutoQuote);
  const clearSelectedHomeQuote = useAction(homeActions.clearSelectedHomeQuote);
  const getSelectedQuote = useAction(wizardActions.getSelectedQuote);
  const selectedAutoQuote = useSelector(autoSelectors.selectedQuote);
  const selectedHomeQuote = useSelector(homeSelectors.selectedQuote);
  const upgradeToBundle = useAction(wizardActions.upgradeToBundle);
  const showChat = useSelector(wizardSelectors.showChat);
  const showPmtPopup = useSelector(wizardSelectors.showPmtPopup);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const payment_type =
    selectedAutoQuote?.payment_type || selectedHomeQuote?.payment_type;

  useOnMount(() => {
    window.scrollTo(0, 0);
    getSelectedQuote(params.id);

    return () => {
      if (mode === "auto") {
        clearSelectedAutoQuote();
      } else if (mode === "home") {
        clearSelectedHomeQuote();
      } else if (mode === "bundle" || mode === "auto-renters") {
        clearSelectedAutoQuote();
        clearSelectedHomeQuote();
      }
    };
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      spacing={5}
      className={classes.selectedQuotePageWrapper}
    >
      {isMobile ? (
        <>
          {(mode === "bundle" || mode === "auto-renters") &&
            selectedAutoQuote && <QuoteCard quote={selectedAutoQuote} />}
          {mode !== "bundle" ||
          ((mode === "bundle" || mode === "auto-renters") &&
            selectedHomeQuote) ? (
            <QuoteCard
              quote={mode === "auto" ? selectedAutoQuote : selectedHomeQuote}
            />
          ) : null}
          {showPmtPopup && !!payment_type ? (
            <Grid item md={7} sm={7} xs={12}>
              <PayForm />
            </Grid>
          ) : contactType ? (
            <WizardCompleteCard />
          ) : showChat ? (
            <ChatWidget agentChat={true} />
          ) : (
            <>
              <ContactCards
                title="How would you like to check out?"
                // snag={false}
              />
              {(mode === "auto" || mode === "home") && (
                <div className={classes.bundleBoxWrapper}>
                  <div
                    className={`bundleBoxCollapse
                      ${bundleBoxOpen ? "open" : ""}`}
                    onClick={() => setBundleBoxOpen(!bundleBoxOpen)}
                  >
                    {bundleBoxOpen ? (
                      <KeyboardArrowDown />
                    ) : (
                      <KeyboardArrowUp />
                    )}
                  </div>
                  {bundleBoxOpen && (
                    <div className={classes.bundleBox}>
                      <p>Bundle Auto + Home Insurance & Save!</p>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          upgradeToBundle(mode);
                          if (mode === "auto") {
                            Navigation.go("/bundle/wizard/details/form");
                          } else if (mode === "home") {
                            Navigation.go("/bundle/wizard/start");
                          }
                        }}
                      >
                        Bundle + Save
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <Grid>
            <Box className={classes.selectedQuoteContainer}>
              {(mode === "bundle" || mode === "auto-renters") &&
                selectedAutoQuote &&
                Object.keys(selectedAutoQuote).length > 0 && (
                  <>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.cardTitle}
                    >
                      <Box>
                        <DirectionsCar />
                      </Box>
                      <Typography>Auto Insurance</Typography>
                    </Grid>
                    <QuoteCard quote={selectedAutoQuote} />
                  </>
                )}
              {(mode === "bundle" || mode === "auto-renters") &&
                selectedHomeQuote &&
                Object.keys(selectedHomeQuote).length > 0 && (
                  <Grid
                    container
                    alignItems="center"
                    className={classes.cardTitle}
                    style={{ marginTop: 20 }}
                  >
                    <Box>
                      <HomeIcon />
                    </Box>
                    <Typography>
                      {mode === "bundle" ? "Home" : "Renters"} Insurance
                    </Typography>
                  </Grid>
                )}
              {/* //TODO: auto-renters */}
              {mode !== "bundle" || (mode === "bundle" && selectedHomeQuote) ? (
                <QuoteCard
                  quote={
                    mode === "auto" ? selectedAutoQuote : selectedHomeQuote
                  }
                />
              ) : null}
            </Box>
          </Grid>
          {showPmtPopup && !!payment_type ? (
            <Grid className={classes.contactCardsContainer}>
              <PayForm />
            </Grid>
          ) : (
            <Grid item className={classes.contactCardsContainer}>
              {contactType ? (
                <WizardCompleteCard />
              ) : showChat ? (
                <ChatWidget agentChat={true} />
              ) : (
                <div>
                  <ContactCards
                    title="How would you like to check out?"
                    // snag={false}
                  />
                  {mode !== "bundle" &&
                    mode !== "auto-renters" &&
                    mode !== "renters" && (
                      <div className={classes.bundleBoxContainer}>
                        {mode === "auto" ? (
                          <Home2Image
                            fillColor={defaultTheme.palette.primary.main}
                            fillContrast={defaultTheme.palette.info.main}
                          />
                        ) : (
                          <WizardSide2
                            fillColor={defaultTheme.palette.primary.main}
                            fillContrast={defaultTheme.palette.info.main}
                          />
                        )}

                        {mode !== "renters" && (
                          <div className={classes.bundleBox}>
                            <p>
                              See what you can save by bundling <br /> your Auto
                              & Home insurance.
                            </p>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                upgradeToBundle(mode);
                                if (mode === "auto") {
                                  Navigation.go("/bundle/wizard/details/form");
                                } else if (mode === "home") {
                                  Navigation.go("/bundle/wizard/start");
                                }
                              }}
                            >
                              Bundle + Save
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                </div>
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
