import React from "react";
import { Backdrop, Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
// import { initReqClientSocket, reqSocket } from "../../utils/socket";

import { MainSnackbar } from "../../components/snackbars";
import {
  // useDispatch,
  useSelector,
  uiSelectors,
  // wizardSelectors,
  // wizardActions,
} from "../../state";

import { useStyles } from "./MainLayout.styles";
import relianceLoader from "../../assets/reliance-loader.json";

function _MainLayout({ children }) {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const loading = useSelector(uiSelectors.loading);
  const loadingMessage = useSelector(uiSelectors.loadingMessage);
  // const req_uid = useSelector(wizardSelectors.req_uid);

  // useEffect(() => {
  //   if (req_uid) {
  //     initReqClientSocket(req_uid);
  //     if (reqSocket) {
  //       reqSocket.on("new_message", (e) => {
  //         switch (e.action) {
  //           case "getSelectedQuote":
  //             dispatch(wizardActions.getQuoteData(e.req_txn_id));
  //             break;
  //           case "showPmtPopup":
  //             dispatch(wizardActions.showPmtPopup(e.showPaymentPopup));
  //             break;
  //           default:
  //             console.error("not handled");
  //         }
  //       });
  //     }
  //   }
  // }, [dispatch, req_uid, reqSocket]);

  return (
    <>
      <Box>{children}</Box>
      <MainSnackbar />
      {loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <Box
            style={{ pointerEvents: "none", height: "300px", width: "300px" }}
          >
            <Lottie animationData={relianceLoader} loop />
          </Box>
          {loadingMessage && <Typography>{loadingMessage}</Typography>}
        </Backdrop>
      )}
    </>
  );
}

export const MainLayout = React.memo(_MainLayout);
