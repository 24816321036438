import React from "react";

export const GeneralDedImage = ({ fillColor }) => {
  return (
    <svg
      width="84px"
      height="84px"
      viewBox="0 0 84 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 2 Copy 5</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="84" height="84"></rect>
      </defs>
      <g
        id="↳-home-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Home---Create-a-custom-quote-side-bar"
          transform="translate(-564.000000, -503.000000)"
        >
          <g id="Group-2-Copy-5" transform="translate(564.000000, 503.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use id="Rectangle" fill="#FFFFFF" xlinkHref="#path-1"></use>
            <g id="Group-3-Copy" mask="url(#mask-2)">
              <g transform="translate(-62.400000, 14.400000)">
                <g id="Group-14" strokeWidth="1" fill="none">
                  <polygon
                    id="Path"
                    fill="#002679"
                    fillRule="nonzero"
                    points="13.8180274 28.2580208 10.9873366 23.5345913 37.4084854 0.860759494 105.349169 0.860759494 105.349169 7.47356082 80.8151471 33.9268213 52.5061869 39.5949367"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#DADFF6"
                    fillRule="nonzero"
                    points="117.000935 0 108.139032 0 108.139032 4.62883799 105.349578 1.46539051 82.7871728 23.9823335 79.0263176 27.7349305 74.3261006 46.4992764 82.7871728 67.1392405 126.031214 67.1392405 126.031214 24.9204827 117.000935 14.6790766"
                  ></polygon>
                  <rect
                    id="Rectangle"
                    fill="#E7EBFD"
                    fillRule="nonzero"
                    x="10.9873366"
                    y="23.8860759"
                    width="71.7408449"
                    height="43.2531646"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fill={fillColor}
                    fillRule="nonzero"
                    x="57.3065007"
                    y="37.443038"
                    width="15.2960961"
                    height="13.556962"
                  ></rect>
                  <polygon
                    id="Path"
                    fill={fillColor}
                    fillRule="nonzero"
                    points="103.194789 42.1772152 103.194789 37.443038 97.5934016 37.443038 97.5934016 42.1772152 102.727725 42.1772152"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill={fillColor}
                    fillRule="nonzero"
                    points="102.727725 46.2658228 97.5934016 46.2658228 97.5934016 51 103.194789 51 103.194789 46.2658228"
                  ></polygon>
                  <rect
                    id="Rectangle"
                    fill={fillColor}
                    fillRule="nonzero"
                    x="107.28811"
                    y="37.443038"
                    width="5.60138729"
                    height="4.73417722"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fill={fillColor}
                    fillRule="nonzero"
                    x="107.28811"
                    y="46.2658228"
                    width="5.60138729"
                    height="4.73417722"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    x="140.034682"
                    y="14.2025316"
                    width="1"
                    height="39.5949367"
                    rx="0.5"
                  ></rect>
                </g>
                <rect
                  id="Rectangle"
                  fill="#000000"
                  x="49.6190024"
                  y="67.8176944"
                  width="103.980998"
                  height="1"
                ></rect>
              </g>
            </g>
            <g id="Group-2" mask="url(#mask-2)">
              <g transform="translate(40.920000, 39.200000)" id="Group-Copy">
                <path
                  d="M21.14,0 C9.71913291,0.00441651734 0.364721044,9.07537335 0.00899356,20.4907 C0.00295356,20.70512 0,20.92256 0,21.14 C0,32.8152996 9.46470039,42.28 21.14,42.28 C32.8152996,42.28 42.28,32.8152996 42.28,21.14 C42.28,9.46470039 32.8152996,0 21.14,0 Z M21.14,41.676 C9.80317936,41.6641815 0.615818474,32.4768206 0.604,21.14 C0.604,20.85914 0.61004604,20.5813 0.62213812,20.30648 C1.07840966,9.08274833 10.468071,0.308411695 21.6969497,0.612746133 C32.9258284,0.917080571 41.8264356,10.1871353 41.674094,21.4191044 C41.5217524,32.6510734 32.3730021,41.676 21.14,41.676 L21.14,41.676 Z"
                  id="Shape"
                  fill="#3F3D56"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M38.958,21.14 C38.958,30.9806097 30.9806097,38.958 21.14,38.958 C11.2993903,38.958 3.322,30.9806097 3.322,21.14 C3.322,20.57526 3.34913168,20.01958 3.40044752,19.46994 C4.29237791,9.99627376 12.4708684,2.895278 21.9759507,3.3417108 C31.481033,3.7881436 38.958,11.6244395 38.958,21.14 L38.958,21.14 Z"
                  id="Path"
                  fill="#E6E6E6"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M26.4460977,22.32384 C26.0141934,21.755991 25.4460562,21.306236 24.7942423,21.01618 C23.9902805,20.6773511 23.1600269,20.4046473 22.3117358,20.20078 L22.3117358,15.35066 C23.6156752,15.5472567 24.8385767,16.1048357 25.8420977,16.96032 L26.8266962,14.86142 C26.2604244,14.3569582 25.6103777,13.9553005 24.9058705,13.67456 C24.1250391,13.3583749 23.3053986,13.1481281 22.4687819,13.04942 L22.4687819,10.59114 L20.1887423,10.59114 L20.1887423,13.07358 C19.3082919,13.1724768 18.4589749,13.4576414 17.6972423,13.91012 C17.0091311,14.3173919 16.4298663,14.8852235 16.0089596,15.56508 C15.6072923,16.228553 15.399155,16.9911255 15.4080581,17.76666 C15.3688218,18.6070871 15.6123076,19.4364275 16.0996381,20.12226 C16.5481941,20.7160492 17.1405793,21.185825 17.8209475,21.4873 C18.6377374,21.8361174 19.4822964,22.1159413 20.3457762,22.32384 L20.3457762,26.97162 C18.8070603,26.8555548 17.3409077,26.2705745 16.14492,25.29552 L15.1604695,27.39744 C15.8291881,27.9502999 16.5896542,28.3816424 17.4073344,28.67188 C18.3032954,28.9925124 19.2393095,29.1876424 20.1887544,29.25172 L20.1887544,31.68886 L22.468794,31.68886 L22.468794,29.20642 C23.735252,29.068032 24.9229066,28.5234696 25.854214,27.65414 C26.6834021,26.8589231 27.1413053,25.752554 27.1166283,24.60394 C27.1544801,23.7902966 26.9183831,22.9874625 26.4460977,22.32384 L26.4460977,22.32384 Z M20.3457883,19.68738 C19.7903694,19.5189504 19.2739784,19.2417778 18.8266438,18.87198 C18.4678002,18.5526848 18.2706232,18.0895938 18.2891502,17.60962 C18.2726759,17.0717267 18.4703387,16.5492807 18.8387358,16.157 C19.2471862,15.7454378 19.7741395,15.4719421 20.3457883,15.37482 L20.3457883,19.68738 Z M23.7432883,26.11394 C23.357991,26.5090601 22.8563261,26.770463 22.3117358,26.85988 L22.3117358,22.8614 C22.8322817,23.0010595 23.3192197,23.2445162 23.7432883,23.57714 C24.0688936,23.8552664 24.2500747,24.2665668 24.2355121,24.69454 C24.2564825,25.212843 24.0806443,25.7198985 23.7432883,26.11394 L23.7432883,26.11394 Z"
                  id="Shape"
                  fill={fillColor}
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
            <path
              d="M66.2,18.8 C66.375,17.575 66.8125,16.6125 67.5125,15.9125 C68.2125,15.2125 69.175,14.775 70.4,14.6 C69.175,14.425 68.2125,13.9875 67.5125,13.2875 C66.8125,12.5875 66.375,11.625 66.2,10.4 C66.025,11.625 65.5875,12.5875 64.8875,13.2875 C64.1875,13.9875 63.225,14.425 62,14.6 C63.225,14.775 64.1875,15.2125 64.8875,15.9125 C65.5875,16.6125 66.025,17.575 66.2,18.8 Z"
              id="Path"
              fill="#FFCC00"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M62.8875,25.9125 C61.4541667,24.4791667 60.5583333,22.5083333 60.2,20 C59.8416667,22.5083333 58.9458333,24.4791667 57.5125,25.9125 C56.0791667,27.3458333 54.1083333,28.2416667 51.6,28.6 C54.1083333,28.9583333 56.0791667,29.8541667 57.5125,31.2875 C58.9458333,32.7208333 59.8416667,34.6916667 60.2,37.2 C60.5583333,34.6916667 61.4541667,32.7208333 62.8875,31.2875 C64.3208333,29.8541667 66.2916667,28.9583333 68.8,28.6 C66.2916667,28.2416667 64.3208333,27.3458333 62.8875,25.9125 Z"
              id="Path"
              fill="#FFCC00"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M75.8,28.4 C75.625,29.625 75.1875,30.5875 74.4875,31.2875 C73.7875,31.9875 72.825,32.425 71.6,32.6 C72.825,32.775 73.7875,33.2125 74.4875,33.9125 C75.1875,34.6125 75.625,35.575 75.8,36.8 C75.975,35.575 76.4125,34.6125 77.1125,33.9125 C77.8125,33.2125 78.775,32.775 80,32.6 C78.775,32.425 77.8125,31.9875 77.1125,31.2875 C76.4125,30.5875 75.975,29.625 75.8,28.4 Z"
              id="Path"
              fill="#FFCC00"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
