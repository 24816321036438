import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  tabsWrapper: {
    borderBottom: 1,
    borderColor: "divider",
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));
