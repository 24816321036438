import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useOnMount } from "../../../../lib";
import {
  useAction,
  useDispatch,
  autoActions,
  useSelector,
  autoSelectors,
} from "../../../../state";
import VehiclesList from "./VehiclesList";
import VehicleFields from "./VehicleFields";

const VehiclesWizard = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  const setSelectedVehicles = useAction(autoActions.setSelectedVehicles);

  const activeVehicleIndex = useSelector(autoSelectors.activeVehicleIndex);
  const selectedVehicles = useSelector(autoSelectors.selectedVehicles);

  const showSelectVehicleView = useSelector(
    autoSelectors.showSelectVehicleView,
  );
  const vehicles = useSelector(autoSelectors.uddVehicles);

  useOnMount(() => {
    if (!vehicles?.length) {
      (async () => {
        await dispatch(autoActions.setShowSelectVehicleView(false));
        if (!selectedVehicles?.length) {
          await setSelectedVehicles([{}]);
        }
        setLoading(false);
      })();
    } else {
      setLoading(false);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeVehicleIndex]);

  return (
    <Box className="maxWidth">
      {!loading &&
        (showSelectVehicleView ? (
          <VehiclesList />
        ) : (
          selectedVehicles?.map((selectedVehicle, index) => (
            <Box
              key={`selectedVehicle-${selectedVehicle.VIN || index}`}
              style={{
                display: activeVehicleIndex === index ? "block" : "none",
              }}
            >
              <VehicleFields index={index} />
            </Box>
          ))
        ))}
    </Box>
  );
};

export default React.memo(VehiclesWizard);
