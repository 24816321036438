import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  formCard: {
    borderRadius: 0,
    marginBottom: "2em",
  },
  formWrap: {
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formControl: {
    width: "50%",
    minWidth: 330,
    padding: "0 2em 2em",
  },
  agencySelect: {
    maxWidth: 330,
    padding: "0 2em 2em",
  },
  formAction: {
    paddingRight: "2em",
  },
  selectLoader: {
    position: "absolute",
    right: 35,
  },
  formField: {
    width: "50%",
    padding: "0 2em 2em",
  },
  jsonInput: {
    paddingBlock: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  switcherGroup: {
    width: "100%",
  },
  switcher: {
    width: "50%",

    "&.MuiToggleButton-root": {
      borderRadius: 0,
      borderColor: "#ccd2e9",
      textTransform: "initial",
      color: theme.palette.secondary.main,
    },
    "&.MuiToggleButton-root.Mui-selected, &.MuiToggleButton-root.Mui-selected:hover":
      {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: "#fff",
      },
  },
}));
