import makeStyles from '@mui/styles/makeStyles';

//import levelUpBG from "../../assets/img/levelup-background-image.jpg";

export const useStyles = makeStyles(
  (theme) => ({
    page: {
      paddingTop: 80,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    root: {
      [theme.breakpoints.down('md')]: {
        "& .MuiTabs-flexContainer": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0px 32px",
        },
      },
    },
    header: {
      height: 80,
      padding: "16px 24px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #DADFF6",
      position: "fixed",
      top: 0,
      zIndex: 100,
      backgroundColor: "white",
      [theme.breakpoints.down('md')]: {
        padding: 8,
        alignItems: "center",
      },
      [theme.breakpoints.down('sm')]: {
        position: "inherit",
      },
    },
    topLeft: {
      display: "flex",
      alignItems: "center",
      gap: 16,
      fontSize: 20,
      fontWeight: 600,
      [theme.breakpoints.down('md')]: {
        gap: 8,
        fontSize: 16,
      },
    },
    topCenter: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      margin: "0px 40px",
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    logo: {
      position: "relative",
      zIndex: 3,
      height: 30,
    },
    content: {
      position: "relative",
      minHeight: "calc(100vh - 80px)",
      width: "calc(100vw - 88px)",
      marginLeft: 85,
      // padding: "12px 0px",
      [theme.breakpoints.down('md')]: {
        width: "100vw",
        marginLeft: 0,
        overflowX: "hidden",
        padding: "0 16px 80px 16px",
      },
    },
    mobileSubheader: {
      marginTop: 16,
      padding: "0 16px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: 16,
      borderBottom: "1px solid #DADFF6",
      paddingBottom: 16,
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 75,
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: "100%",
        paddingLeft: 16,
        gridGap: 8,
        marginBottom: 0,
      },
    },
    agencyWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      maxWidth: 500,
      width: "75%",
      [theme.breakpoints.down('md')]: {
        flexDirection: "column",
        maxWidth: 700,
      },
      "& .MuiOutlinedInput-root": {
        maxHeight: 32,
      },
    },
    copyLinksWrapper: {
      [theme.breakpoints.down('md')]: {
        textAlign: "center",
        border: "1px solid #AFB6D4",
        borderRadius: "3px",
        height: 40,
        "& .MuiButton-label": {
          fontWeight: 300,
          textTransform: "capitalize",
        },
      },
    },
    linkText: {
      marginLeft: 12,
      fontWeight: "bold",
    },
  }),
  { classNamePrefix: "AdminLayout" },
);
