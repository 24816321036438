import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  box: {
    width: 550,
    height: 360,
    borderRadius: 3,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 32px 0px rgba(0,0,0,0.08)",
    padding: 40,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.down('md')]: {
      width: "100%",
      maxWidth: 550,
    },
  },
  selectBtn: {
    fontWeight: 600,
    letterSpacing: "1px",
    transition: "all 300ms linear",
    height: 48,
    width: 120,
    color: "#fff",
    marginBottom: 16,
  },
  cancelBtn: {
    fontWeight: 600,
    letterSpacing: "1px",
    transition: "all 300ms linear",
    height: 48,
    width: 120,
    marginBottom: 16,
  },
  transferWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  transferTitle: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 5,
  },
  transferText: {
    fontSize: 16,
    marginBottom: 25,
  },
  transferInput: {
    width: "100%",
    "& .MuiInputBase-root .MuiOutlinedInput-input ": {
      height: 100,
    },
    marginTop: 20,
  },
  transferDropdown: {
    height: 24,
    borderRadius: 3,
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  transferButtons: {
    alignSelf: "end",
    position: "absolute",
    bottom: 20,
    right: 40,
  },
}));
