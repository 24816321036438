import { Box, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./AppStatsItems.styles";
import { ApplicationsStats } from "./ApplicationsStats";
import { useMobile } from "../../../themes";

const TotalItem = (props) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.totalItem}
      style={{ backgroundColor: props.secondaryColor }}
    >
      <Box
        className={classes.total}
        style={{ backgroundColor: props.primaryColor }}
      >
        {props.amount}
      </Box>{" "}
      <div className={classes.totalText} style={{ color: props.primaryColor }}>
        {props.type}
      </div>
    </Box>
  );
};

export const AppStatsItems = ({ amounts }) => {
  const classes = useStyles();
  const isMobile = useMobile();
  return (
    <Box>
      <Box className={classes.totalAmounts}>
        <TotalItem
          primaryColor={"#2eb662"}
          secondaryColor={"#DCF8E7"}
          type={"New"}
          amount={!!amounts && amounts["New"]}
        />
        <TotalItem
          primaryColor={"#204FFF"}
          secondaryColor={"#D2DCFF"}
          type={"In progress"}
          amount={!!amounts && amounts["In progress"]}
        />
        <TotalItem
          primaryColor={"#FFAE00"}
          secondaryColor={"#FFEFCC"}
          type={"Pending"}
          amount={!!amounts && amounts["Pending"]}
        />
        <TotalItem
          primaryColor={"#8D98C2"}
          secondaryColor={"#EEEFF5"}
          type={"Closed"}
          amount={!!amounts && amounts["Closed"]}
        />
      </Box>
      <div className={classes.mobileColumns}>
        {isMobile && (
          <Typography variant="h6" className={classes.mobileTitle}>
            Applications statuses
          </Typography>
        )}
        <ApplicationsStats />
      </div>
    </Box>
  );
};
