import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import { AddIcon } from "../../components/icons";

import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
} from "../../state";
import AdminPages from ".";
import { COVERAGE_UI_COLUMNS as columns } from "../../constants/tables";

import { useStyles } from "./CoveragePage.style";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function CoverageUIPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(50);
  const coverage_ui = useSelector(adminSelectors.coverage_ui);

  useEffect(() => {
    dispatch(adminActions.getCoverageUI());
    dispatch(adminActions.setPageTitle("Coverage UI Details"));
  }, [dispatch]);

  return (
    <div className={classes.page}>
      <div className={classes.topArea}>
        <Link className={classes.link} to={AdminPages.createCoverageUI.path}>
          <Button variant="outlined" color="primary">
            <AddIcon /> Add Coverage UI
          </Button>
        </Link>
      </div>

      <DataGridPro
        autoHeight
        // onRowClick={(e) =>
        //   history.push(AdminPages.editCoverage.path.replace(":id", "" + e.id))
        // }
        rows={coverage_ui}
        columns={columns}
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
