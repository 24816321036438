import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import { GridColDef, DataGridPro } from "@mui/x-data-grid-pro";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authSelectors, adminActions } from "../../state";
import { customFormatDate } from "../../utils";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  minHeight: 400,
  minWidth: 800,
};
export const UserNotesModal = ({ notes, open, setOpen, agentId }) => {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [note, setNote] = useState("");
  const [showInput, setShowInput] = useState(false);
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const dispatch = useDispatch();
  const handlePostNote = () => {
    if (!activeRole || !agencyId) {
      console.warn("active role and active agency id is not defined");
      return;
    }
    dispatch(adminActions.postUserNote(agentId, note, agencyId, activeRole));
    setShowInput(false);
  };
  useEffect(() => {
    if (!showInput) {
      setNote("");
    }
  }, [showInput]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "note",
        headerName: "Note",
        sortable: false,
        disableColumnMenu: true,
        width: 400,
        type: "string",
      },
      {
        field: "created_by",
        headerName: "Created By",
        sortable: false,
        alignHeader: "left",
        align: "left",
        disableColumnMenu: true,
        width: 175,
        editable: false,
        type: "string",
        renderCell: ({ row }) => {
          return row.creator.first_name + " " + row.creator.last_name;
        },
      },
      {
        field: "created_at",
        headerName: "Created At",
        sortable: false,
        alignHeader: "left",
        align: "left",
        disableColumnMenu: true,
        width: 175,
        editable: false,
        type: "string",
        renderCell: ({ row }) => {
          return customFormatDate(row.created_at);
        },
      },
    ],
    [],
  );
  return (
    <div>
      <Button onClick={handleOpen}>View</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            mb={2}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              User details
            </Typography>
            <Button
              onClick={() => {
                setShowInput(true);
              }}
            >
              Add note
            </Button>
          </Box>
          {showInput ? (
            <Box>
              <TextField
                variant="outlined"
                id="standard-textarea"
                label="Add a note"
                multiline
                minRows={3}
                onChange={(e) => setNote(e.target.value)}
                value={note}
              />
              <Box
                sx={{
                  margin: "20px 0",
                  display: "flex",
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={() => {
                    setShowInput(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handlePostNote}
                  disabled={!note.length}
                >
                  Save
                </Button>
              </Box>
            </Box>
          ) : null}
          <DataGridPro
            rows={notes || []}
            columns={columns}
            onRowClick={(_p, event) => event.stopPropagation()}
            getRowHeight={() => "auto"}
            disableSelectionOnClick
            autoHeight
            hideFooter
            sx={{
              "& .MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus": {
                outline: "1px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-cell": {
                padding: 1,
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};
