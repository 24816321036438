import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: "scroll",
  },
  box: {
    width: 650,
    borderRadius: 3,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 32px 0px rgba(0,0,0,0.08)",
    padding: 40,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.down('md')]: {
      width: "100%",
      maxWidth: 650,
    },
  },
  titleWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 28,
    maxWidth: "80%",
  },
  heading: {
    fontSize: 16,
    fontWeight: 600,
  },
  explanation: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 28,
    marginRight: 36,
  },
  accordionWrapper: {
    boxShadow: "none",
  },
  closeBtn: {
    marginRight: 0,
    paddingRight: 0,
    color: "#a3a8bf",
    width: 24,
    height: 24,
    "& .MuiSvgIcon-root": {
      width: 24,
      height: 24,
    },
  },
}));
