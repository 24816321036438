import React, { useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";

import { BlockIcon, LockIcon } from "../../../../components/icons";
import { AddressAsyncAutocomplete } from "../../../../components/forms/inputs";

import {
  useAction,
  useSelector,
  uiActions,
  wizardActions,
  lifeActions,
  lifeSelectors,
} from "../../../../state";
import { Form, Navigation, useFormik, useLocation } from "../../../../lib";
import { startValidationSchema } from "../../../../utils";
import { StartWizardInitialValues } from "../../../../constants";

import { useStyles } from "./StartWizard.styles";

const StartWizard = () => {
  const classes = useStyles();
  const location = useLocation();
  const setStartForm = useAction(lifeActions.setStartForm);
  const setFbUser = useAction(uiActions.setFbUser);
  const startForm = useSelector(lifeSelectors.startForm);
  // const addressError = useSelector(wizardSelectors.addressError);
  const setAddressError = useAction(wizardActions.setAddressError);

  const form = useFormik({
    initialValues: startForm || StartWizardInitialValues,
    validationSchema: startValidationSchema,
    async onSubmit(values, { setSubmitting }) {
      setSubmitting(false);
      await setStartForm(values);
      Navigation.go(`/life/wizard/insured`);
    },
  });
  useEffect(() => {
    const { utm_medium } = location.query;
    if (utm_medium === "manychat") {
      setFbUser(true);
    } else {
      setFbUser(false);
    }
  }, []);

  useEffect(() => {
    setAddressError("");
  }, []);

  useEffect(() => {
    form.setFieldTouched("address.label", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form form={form} className="maxWidth">
      <Grid container spacing={2} className={classes.fields}>
        <Grid item md={5} xs={12}>
          <TextField
            id="first_name"
            name="first_name"
            label="First name"
            value={form.values.first_name}
            onChange={form.handleChange}
            error={form.touched.first_name && Boolean(form.errors.first_name)}
            helperText={form.touched.first_name && form.errors.first_name}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <TextField
            id="middle_name"
            name="middle_name"
            label="Middle"
            value={form.values.middle_name}
            onChange={form.handleChange}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <TextField
            id="last_name"
            name="last_name"
            label="Last name"
            value={form.values.last_name}
            onChange={form.handleChange}
            error={form.touched.last_name && Boolean(form.errors.last_name)}
            helperText={form.touched.last_name && form.errors.last_name}
          />
        </Grid>
        <Grid item md={9} xs={12} style={{ marginTop: 24 }}>
          <AddressAsyncAutocomplete form={form} />
          {/* {Boolean(addressError?.length) && (
            <FormHelperText error variant="outlined">
              {addressError}
            </FormHelperText>
          )} */}
        </Grid>
        <Grid item md={3} xs={12} className={classes.aptField}>
          <TextField
            id="apt"
            name="apt"
            label="Apt/Unit#"
            value={form.values.apt}
            onChange={form.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={0}
            wrap="nowrap"
          >
            <Grid item xs={6} style={{ marginTop: 24 }}>
              <Typography
                className={`${classes.feature} ${classes.firstFeature}`}
              >
                <LockIcon color="primary" />
                Cutting edge encryption
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 24 }}>
              <Typography className={classes.feature}>
                <BlockIcon color="primary" />
                We never sell your info
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 24 }}
      >
        <Button
          type="submit"
          id="submit"
          variant="contained"
          color="primary"
          disabled={!form.isValid}
          className={classes.submitButton}
        >
          Let's go
        </Button>
      </Grid>
    </Form>
  );
};

export default React.memo(StartWizard);
