import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    maxWidth: 925,
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 36,
  },
  bindWrapper: {
    marginRight: 16,
  },
  bindBtn: {
    width: 315,
    marginBottom: 8,
  },
  wrapper: {
    padding: 16,
    paddingTop: 0,
    display: "flex",
    gap: 72,
    [theme.breakpoints.down('md')]: {
      flexWrap: "wrap",
      padding: 8,
      marginTop: 0,
      gap: 16,
    },
  },
  leftPanel: {
    display: "flex",
    flexDirection: "column",
  },
  quoteType: {
    margin: 8,
    fontSize: 18,
  },
  quoteTypeFiller: {
    height: 42,
  },
  form: {
    width: 400,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 16,
    marginBottom: 16,
  },
  editQuoteWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 16,
    width: "100%",
    boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
  },
  buttonWrapper: {
    display: "flex",
    width: "100%",
    gap: 16,
    "& .MuiButtonBase-root": {
      padding: 11,
      width: 180,
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      fontFamily: "Lato",
      fontSize: 14,
      fontWeight: 600,
    },
  },
  card: {
    padding: 24,
    height: "auto",
    width: 420,
    maxWidth: "calc(100vw - 16px)",
    boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
    marginBottom: 24,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 10,
  },
  cardsWrapper: {
    marginTop: 42,
    [theme.breakpoints.down('lg')]: {
      flexWrap: "wrap",
    },
  },
  cardInfoSection: {
    marginTop: 24,
    display: "grid",
    gridTemplateColumns: "150px 1fr",
  },
  field: {
    marginBottom: 12,
  },
  pmtInfoTitle: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 600,
  },
  pmtInfoValue: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: 400,
  },
  offlineLink: {
    fontSize: 10,
  },
}));
