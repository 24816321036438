import { arrayToEntityList } from "../../lib/utils";
import { AppState } from "../states";
import { createSelector } from "@reduxjs/toolkit";

const _state = (state: AppState) => state.commercial;

const _applicationQuestionsByKey = createSelector(
  _state,
  (s) => arrayToEntityList(s.applicationQuestions, "questionId").entities,
);

export const commercialSelectors = {
  startForm(state: AppState) {
    return state.life.startForm || {};
  },
  insuredDetails(state: AppState) {
    return state.life.insuredDetails;
  },
  coverageDetails(state: AppState) {
    return state.life.coverageDetails;
  },
  quotes(state: AppState) {
    return state.life.quotes || [];
  },
  baseInfo(state: AppState) {
    return state.life.baseInfo;
  },
  entityTypes(state: AppState) {
    return state.commercial.entityTypes;
  },
  industryCodes(state: AppState) {
    return state.commercial.industryCodes;
  },
  carrierIndustryCodes(state: AppState) {
    return state.commercial.carrierIndustryCodes;
  },
  jobCodes(state: AppState) {
    return state.commercial.jobCodes;
  },
  applicationQuestions(state: AppState) {
    return state.commercial.applicationQuestions;
  },
  applicationFields(state: AppState) {
    return state.commercial.applicationFields;
  },
  applicationData(state: AppState) {
    return state.commercial.applicationData;
  },
  uwStatements(state: AppState) {
    return state.commercial.uwStatements;
  },
  applicationQuestionsByKey: _applicationQuestionsByKey,
  fieldsConfig(state: AppState) {
    return state.commercial.fieldsConfig;
  },
  paymentPlans(state: AppState) {
    return state.commercial.paymentPlans;
  },
  liabilityLimits(state: AppState) {
    return state.commercial.liabilityLimits;
  },
};
