import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, Switch, Typography } from "@mui/material";
import { DirectionsCar, HomeIcon } from "../../../components/icons";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import exitIntent from "exit-intent";
import clsx from "clsx";
import {
  autoActions,
  autoSelectors,
  homeActions,
  homeSelectors,
  uiActions,
  useAction,
  useDispatch,
  useSelector,
  wizardSelectors,
  wizardActions,
  wizardNoPersistSelectors,
} from "../../../state";
import { useMobile } from "../../../themes";
import QuotesCarousel from "./QuotesCarousel";
import { useStyles } from "./Quotes.styles";

const Quotes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMobile();
  // refs
  const firstUpdate = useRef(true);
  // actions
  const getAllQuotes = useAction(wizardActions.getAllQuotes);
  const getCoverageDetails = useAction(wizardActions.getCoverageDetails);
  const getQuotes = useAction(wizardActions.getQuotes);
  const setCarouselMode = useAction(wizardActions.setCarouselMode);
  const setShopperApprovedShown = useAction(
    wizardActions.setShopperApprovedShown,
  );
  const setShowExitIntent = useAction(uiActions.setShowExitIntent);
  // selectors
  const carouselMode = useSelector(wizardSelectors.carouselMode);
  const chatMode = useSelector(wizardSelectors.chatMode);
  const contactInfo = useSelector(wizardSelectors.contactInfo);
  const req_uid = useSelector(wizardSelectors.req_uid);
  const fullName = useSelector(autoSelectors.fullName);
  const isQuoteSelected = useSelector(wizardSelectors.isQuoteSelected) || null;
  const offlineChatSubmitted = useSelector(
    wizardSelectors.offlineChatSubmitted,
  );
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const quoteWasHydrated = useSelector(wizardSelectors.getQuoteWasHydrated);
  const selectedQuote = useSelector(wizardSelectors.selectedQuote) || null;
  const selectedAutoQuote = useSelector(autoSelectors.selectedQuote);
  const selectedHomeQuote = useSelector(homeSelectors.selectedQuote);
  const shopperApprovedOn = useSelector(wizardSelectors.settings)
    .show_shopper_approved?.value;
  const shopperApprovedShown = useSelector(
    wizardSelectors.shopperApprovedShown,
  );
  //state
  const [customQuoteModal, setCustomQuoteModal] = useState<boolean>(false);
  const [poppedUp, setPoppedUp] = useState(false);
  const [showingBundled, setShowingBundled] = useState(false);
  const [showingMore, setShowingMore] = useState(false);

  const showShopperApproved = shopperApprovedOn && !shopperApprovedShown;

  const handleCustomQuoteModal = useCallback(() => {
    setCustomQuoteModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setCustomQuoteModal(false);
    window.scrollTo(0, 0);
  }, []);

  function saLoadScript(src) {
    const js = window.document.createElement("script");
    js.src = src;
    js.type = "text/javascript";
    document.getElementsByTagName("head")[0].appendChild(js);
  }

  useEffect(() => {
    if (mode === "bundle" || mode === "auto-renters") {
      setShowingBundled(true);
    }
    if (isQuoteSelected) {
      dispatch(autoActions.clearSelectedAutoQuote());
      dispatch(homeActions.clearSelectedHomeQuote());
    }
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, [showingMore]);

  useEffect(() => {
    if (!mode) return;
    if (quoteWasHydrated) {
      if (mode === "bundle") {
        getQuotes(req_uid, "auto", true);
        getQuotes(req_uid, "auto", false);
        getQuotes(req_uid, "home", true);
        getQuotes(req_uid, "home", false);
      } else if (mode === "auto-renters") {
        getQuotes(req_uid, "auto", true);
        getQuotes(req_uid, "auto", false);
        getQuotes(req_uid, "renters", true);
        getQuotes(req_uid, "renters", false);
      } else {
        getQuotes(req_uid, mode, false);
      }
      getAllQuotes();
    }
    getCoverageDetails();
    dispatch(wizardActions.setShowChat(false));
    const removeExitIntent = exitIntent({
      threshold: 5,
      maxDisplays: 2,
      eventThrottle: 100,
      onExitIntent: () => {
        setShowExitIntent(true);
        setPoppedUp(true);
      },
    });
    if (quoteWasHydrated || poppedUp) {
      removeExitIntent();
    }
    return () => {
      removeExitIntent();
      dispatch(wizardActions.setCarouselMode("auto"));
    };
  }, [quoteWasHydrated, mode]);

  useEffect(() => {
    if (showShopperApproved && selectedQuote) {
      if (chatMode === "online" || offlineChatSubmitted || contactInfo) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.sa_values.orderid = selectedQuote?.turbo_quote_id;
        window.sa_values.name = fullName;
        window.sa_values.email = contactInfo.email;

        const d = new Date();

        if (d.getTime() - 172800000 > 1477399567000) {
          saLoadScript(
            "https://www.shopperapproved.com/thankyou/rate/35144.js?d=" +
              d.getTime(),
          );
        } else {
          saLoadScript(
            "//direct.shopperapproved.com/thankyou/rate/35144.js?d=" +
              d.getTime(),
          );
        }
        setShopperApprovedShown(true);
      }
    }
  }, [
    selectedQuote,
    fullName,
    contactInfo,
    chatMode,
    offlineChatSubmitted,
    shopperApprovedOn,
    quoteWasHydrated,
    contactInfo,
  ]);

  return (
    <Box className={classes.root}>
      {/* TODO: test this for tablet and mobile - CG */}
      {(mode === "bundle" || mode === "auto-renters") && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          className={clsx(
            classes.bundleActionsContainer,
            isMobile ? classes.mobileBundleActionsContainer : null,
          )}
        >
          {/* empty box here for justifyContent: space-between to work properly */}
          <Box style={{ flex: 1 }} />
          <ToggleButtonGroup
            className={classes.switcherGroup}
            exclusive
            value={carouselMode}
            onChange={(e, value) => {
              return carouselMode !== value && value !== null
                ? setCarouselMode(value)
                : null;
            }}
          >
            <ToggleButton value={"auto"} className={classes.switcher}>
              <DirectionsCar className={classes.backgroundIcon} />
              Auto
            </ToggleButton>
            <ToggleButton value={"home"} className={classes.switcher}>
              <HomeIcon className={classes.backgroundIcon} />
              {mode === "bundle" ? "Home" : "Renters"}
            </ToggleButton>
          </ToggleButtonGroup>
          <Grid
            container
            alignItems="center"
            justifyContent={isMobile ? "center" : "flex-end"}
            style={{ flex: 1 }}
          >
            <Typography style={{ fontSize: 14, fontWeight: 500 }}>
              Multi-Policy
            </Typography>
            <Switch
              checked={showingBundled}
              onChange={(e, value) => setShowingBundled(value)}
              color="primary"
              name="showBundled"
              inputProps={{ "aria-label": "secondary checkbox" }}
              disabled={selectedAutoQuote || selectedHomeQuote}
            />
          </Grid>
        </Grid>
      )}
      <QuotesCarousel
        customQuoteModal={customQuoteModal}
        handleCloseModal={handleCloseModal}
        handleCustomQuoteModal={handleCustomQuoteModal}
        showingBundled={showingBundled}
        showingMore={showingMore}
        setShowingMore={setShowingMore}
      />
    </Box>
  );
};

export default React.memo(Quotes);
