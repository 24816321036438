import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 620,
    width: 440,
    maxWidth: "100%",
    borderRadius: 3,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
    "& .MuiButtonBase-root": {
      height: 48,
    },
  },
  header: {
    padding: 16,
    height: 120,
    width: "100%",
    borderRadius: "3px 3px 0 0",
    backgroundColor: theme.palette.primary.light,
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    gap: 12,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    marginLeft: 8,
    fontSize: 20,
    fontWeight: 600,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 400,
  },
  bottomSection: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  subSection: {
    marginBottom: 32,
    // display: "flex",
    // flexDirection: "column",
    // gap: 16,
  },
  subSectionTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
}));
