import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Form, useFormik } from "../../../../lib";
import {
  authSelectors,
  lifeActions,
  lifeSelectors,
  useAction,
  useDispatch,
  useSelector,
  wizardActions,
} from "../../../../state";
import {
  formatCurrency,
  lifeCoverageSchema as validationSchema,
} from "../../../../utils";
import ScrollToOnError from "../../../../components/forms/ScrollToOnError";
import { useMobile } from "../../../../themes";
import { useStyles } from "./CoverageDetails.styles";
import {
  InfoIcon,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "../../../../components/icons";
import { LIFE_AMOUNTS } from "../../../../state/life/types";

export default function InsuredContainer() {
  const classes = useStyles();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const clearSelectedQuote = useAction(lifeActions.clearSelectedLifeQuote);
  const clearQuotes = useAction(lifeActions.clearLifeQuotes);

  //STATE
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [coverageShowing, setCoverageShowing] = useState(false);

  //SELECTORS
  const coverages = useSelector(lifeSelectors.coverageDetails);
  const insuredDetails = useSelector(lifeSelectors.insuredDetails);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  //ACTIONS
  const setCoverages = useAction(lifeActions.setCoverages);
  const setInsured = useAction(lifeActions.setInsured);
  const terms = [
    { label: 5, value: "2" },
    { label: 10, value: "3" },
    { label: 15, value: "4" },
    { label: 20, value: "5" },
    { label: 25, value: "6" },
    { label: 30, value: "7" },
    { label: 35, value: "9" },
  ];

  const healthOptions = [
    { label: "Preferred plus", value: "PP" },
    { label: "Preferred", value: "P" },
    { label: "Standard plus", value: "RP" },
    { label: "Standard", value: "R" },
  ];
  const initialValues: any = {
    yearTerm: "",
    amount: "",
    healthClass: "",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik: any = useFormik({
    initialValues: { ...coverages, ...insuredDetails } || initialValues,
    validationSchema: validationSchema,
    validateOnChange: formSubmitted,
    async onSubmit(values) {
      await setCoverages({ yearTerm: values.yearTerm, amount: values.amount });
      await setInsured({ ...insuredDetails, healthClass: values.healthClass });
      clearQuotes();
      clearSelectedQuote();
      dispatch(lifeActions.submitQuote(true, isLoggedIn));
    },
  });

  return (
    <>
      <Box className={isMobile ? classes.mobileWrapper : classes.notMobile}>
        <Box className={isMobile ? classes.mobileContainer : classes.hide}>
          <div className={classes.bold}>Coverage</div>
          {!coverageShowing && (
            <div onClick={() => setCoverageShowing(!coverageShowing)}>
              <KeyboardArrowDown />
            </div>
          )}
          {coverageShowing && (
            <div onClick={() => setCoverageShowing(!coverageShowing)}>
              <KeyboardArrowUp />
            </div>
          )}
        </Box>
        <Form
          form={formik}
          onSubmit={formik.handleSubmit}
          className={classes.formContainer}
        >
          {(!!coverageShowing || !isMobile) && (
            <Grid container className={classes.formFlex}>
              <Box className={classes.formSection}>
                <ScrollToOnError name="healthClass">
                  <FormControl variant="outlined" className={classes.formItem}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Health class
                    </InputLabel>
                    <Select
                      name="healthClass"
                      value={formik.values.healthClass}
                      onChange={formik.handleChange}
                      label="Health class"
                      error={!!formik.errors.healthClass}
                      fullWidth
                    >
                      {healthOptions.map((option) => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(formik.errors.yearTerm) && (
                      <FormHelperText error variant="outlined">
                        {formik.errors.yearTerm}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <Box className={classes.healthTitle}>
                    <span> What's my health class?</span>
                    <span
                      onClick={() =>
                        dispatch(wizardActions.setExplainerModalOpen(true))
                      }
                    >
                      <InfoIcon className={classes.infoIcon}></InfoIcon>
                    </span>
                  </Box>
                </ScrollToOnError>
              </Box>
              <Box className={classes.formSection}>
                <ScrollToOnError name="yearTerm">
                  <FormControl variant="outlined" className={classes.formItem}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Insurance type
                    </InputLabel>
                    <Select
                      name="yearTerm"
                      value={formik.values.yearTerm}
                      onChange={formik.handleChange}
                      label="Insurance type"
                      error={!!formik.errors.yearTerm}
                      fullWidth
                    >
                      {terms.map((term) => (
                        <MenuItem key={term.label} value={term.value}>
                          {term.label} year term
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(formik.errors.yearTerm) && (
                      <FormHelperText error variant="outlined">
                        {formik.errors.yearTerm}
                      </FormHelperText>
                    )}
                  </FormControl>
                </ScrollToOnError>
              </Box>
              <Box className={classes.formSection}>
                <ScrollToOnError name="amount">
                  <FormControl variant="outlined" className={classes.formItem}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Insurance amount
                    </InputLabel>
                    <Select
                      name="amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      error={!!formik.errors.amount}
                      fullWidth
                      label="Insurance amount"
                    >
                      {LIFE_AMOUNTS.map((amount) => (
                        <MenuItem key={amount} value={amount * 1000}>
                          {formatCurrency(amount * 1000)}
                        </MenuItem>
                      ))}
                    </Select>
                    {Boolean(formik.errors.amount) && (
                      <FormHelperText error variant="outlined">
                        {formik.errors.amount}
                      </FormHelperText>
                    )}
                  </FormControl>
                </ScrollToOnError>
              </Box>
              <Box className={classes.formSection}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.formItem}
                  type="submit"
                  disabled={!formik.isValid}
                  onClick={() => setFormSubmitted(true)}
                >
                  Recalculate
                </Button>
              </Box>
            </Grid>
          )}
        </Form>
      </Box>
    </>
  );
}
