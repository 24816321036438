import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  RadioGroup,
  Radio,
  Select,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { getLastHundredYears, getLastTwoHundredYears } from "../../../../utils";
import {
  Form,
  getRecaptchaToken,
  Navigation,
  useFormik,
} from "../../../../lib";
import {
  homeActions,
  homeSelectors,
  useAction,
  useSelector,
  wizardNoPersistSelectors,
  wizardSelectors,
} from "../../../../state";
import { useStyles } from "./Details.styles";
import { initialDetailsSchema as validationSchema } from "../../../../utils";
import {
  EXTERIOR_WALLS_OPTIONS,
  FAMILY_UNITS,
  NUMBERS,
  STORY_TYPE,
} from "../../../../state/home/types";
import ScrollToOnError from "../../../../components/forms/ScrollToOnError";
import { AddressAsyncAutocomplete } from "../../../../components";

export default function DetailsForm() {
  const classes = useStyles();

  const { address, apt } = useSelector(homeSelectors.start);
  const details = useSelector(homeSelectors.details);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const getPropertyLookup = useAction(homeActions.getPropertyLookup);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const propertyLookupOn = useSelector(wizardSelectors.settings)
    .home_property_lookup?.value;
  const yearPurchasedOptions = getLastHundredYears();
  const yearBuiltOptions = getLastTwoHundredYears();
  const setHomeDetails = useAction(homeActions.setHomeDetails);
  const isRenters = mode === "renters" || mode === "auto-renters";
  const isHome = mode === "home" || mode === "bundle";
  useEffect(() => {
    if (isRenters) {
      setHomeDetails({ ...details, propertyType: "A" });
    } else {
      setHomeDetails({ ...details, propertyType: "" });
    }
  }, [isRenters]);
  const formik: any = useFormik({
    initialValues: {
      own: "",
      vacantUnoccupied: "",
      underConstruction: false,
      yearPurchased: "",
      propertyLookupOn,
      manualYearOfConstruction: details.YearOfConstruction || "",
      manualSquareFootage: details.SquareFootage || "",
      manualStoryType:
        // STORY_TYPE.filter((x) => x.label === details.StoryType)[0]?.value ||
        details.StoryType || "",
      manualExteriorWalls: details.exteriorWalls || "",
      ...details,
      mode,
      isRenters,
      isHome,
    },
    validateOnChange: formSubmitted,
    validationSchema: validationSchema,
    async onSubmit(values) {
      if (isRenters) {
        const manualDetails = {
          SquareFootage: values.manualSquareFootage,
          StoryType: values.manualStoryType,
          YearOfConstruction: values.manualYearOfConstruction,
          NumberOfFamilies: values.manualNumberOfFamilies,
          usageType: values.usageType,
          exteriorWalls: values.manualExteriorWalls,
          NumberOfFullBaths: values.NumberOfFullBaths,
          numberOfHalfBaths: values.numberOfHalfBaths,
        };
        const url =
          mode === "renters"
            ? `/${mode}/wizard/discounts`
            : `/${mode}/wizard/home-discounts`;
        await setHomeDetails(manualDetails);
        return Navigation.go(url);
      }
      await setHomeDetails(values);
      const grecaptchaToken = await getRecaptchaToken();
      let lookupAddress = {
        address1: `${address.street_line} ${apt}`,
        city: address.city,
        state: address.state,
        zipCode: address.zipcode,
      };
      if (!propertyLookupOn) {
        lookupAddress = {
          ...lookupAddress,
          ...{
            yearOfConstruction: values.manualYearOfConstruction,
            squareFootage: values.manualSquareFootage,
            numberOfFamilies: Number(values.manualNumberOfFamilies),
            storyType:
              values.manualStoryType === "SplitLevel"
                ? "TwoStories"
                : values.manualStoryType,
          },
        };
      }
      await getPropertyLookup(
        lookupAddress,
        grecaptchaToken,
        true,
        values.propertyType,
      );
      Navigation.go(`/${mode}/wizard/details/confirm`);
    },
  });

  const handleToggleChange =
    (fieldName) => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) {
        formik.setFieldValue(fieldName, value);
      }
    };
  return (
    <Form form={formik} onSubmit={formik.handleSubmit} className="maxWidth">
      {!isRenters && (
        <>
          <ScrollToOnError name="propertyType">
            <FormControl
              className={`${classes.formSection} ${
                formik.errors.propertyType ? classes.error : ""
              }`}
              component="fieldset"
            >
              <FormLabel className={classes.formLabel} component="legend">
                Describe your property
              </FormLabel>
              <RadioGroup
                name="propertyType"
                value={formik.values.propertyType}
                onChange={formik.handleChange}
              >
                <>
                  <FormControlLabel
                    value="D"
                    control={<Radio color="primary" />}
                    label="House"
                  />
                  <FormControlLabel
                    value="T"
                    control={<Radio color="primary" />}
                    label="Townhouse"
                  />
                  <FormControlLabel
                    value="X"
                    control={<Radio color="primary" />}
                    label="Duplex"
                  />
                </>
                <FormControlLabel
                  value="C"
                  control={<Radio color="primary" />}
                  label="Condo"
                />
                {/* <FormControlLabel
            value="A"
            control={<Radio color="primary" />}
            label="Apartment"
          /> */}
              </RadioGroup>
              {Boolean(formik.errors.propertyType) && (
                <FormHelperText error variant="outlined">
                  {formik.errors.propertyType}
                </FormHelperText>
              )}
            </FormControl>
          </ScrollToOnError>
          <ScrollToOnError name="own">
            <FormControl
              className={`${classes.formSection} ${
                formik.errors.own ? classes.error : ""
              }`}
              component="fieldset"
            >
              <FormLabel className={classes.formLabel} component="legend">
                Do you currently own this property?
              </FormLabel>
              <RadioGroup
                name="own"
                value={formik.values.own}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value="Y"
                  control={<Radio color="primary" />}
                  label="I currently own this property"
                />
                <FormControlLabel
                  value="N"
                  control={<Radio color="primary" />}
                  label="I’m in the process of buying it"
                />
              </RadioGroup>
              {Boolean(formik.errors.own) && (
                <FormHelperText error variant="outlined">
                  {formik.errors.own}
                </FormHelperText>
              )}
            </FormControl>
          </ScrollToOnError>
          {formik.values.own === "N" ? (
            <ScrollToOnError name="previousAddress">
              <FormControl
                className={`${classes.formSection} ${
                  formik.errors.previousAddress ? classes.error : ""
                }`}
              >
                <FormLabel className={classes.formLabel} component="legend">
                  What is your previous address?
                </FormLabel>
                <AddressAsyncAutocomplete
                  form={formik}
                  name="previousAddress"
                />
                {Boolean(formik.errors.previousAddress?.state) && (
                  <FormHelperText error variant="outlined">
                    {formik.errors.previousAddress?.state}
                  </FormHelperText>
                )}
              </FormControl>
            </ScrollToOnError>
          ) : null}
        </>
      )}
      <ScrollToOnError name="usageType">
        <FormControl className={classes.formSection} component="fieldset">
          <FormLabel className={classes.formLabel} component="legend">
            Is this your primary residence?
          </FormLabel>
          <ToggleButtonGroup
            className={`${classes.toggle} ${
              formik.errors.usageType ? classes.error : ""
            }`}
            value={formik.values.usageType}
            onChange={handleToggleChange("usageType")}
            exclusive
            size="large"
          >
            <ToggleButton
              value="Primary"
              aria-label="yes"
              style={{ width: "50%", textTransform: "capitalize" }}
              color="primary"
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value="Secondary"
              aria-label="no"
              style={{ width: "50%", textTransform: "capitalize" }}
              color="primary"
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
          {Boolean(formik.errors.usageType) && (
            <FormHelperText error variant="outlined">
              {formik.errors.usageType}
            </FormHelperText>
          )}
        </FormControl>
      </ScrollToOnError>
      {formik.values.usageType === "Secondary" && !isRenters && (
        <ScrollToOnError name="vacantUnoccupied">
          <FormControl
            className={`${classes.formSection} ${
              formik.errors.vacantUnoccupied ? classes.error : ""
            }`}
            component="fieldset"
          >
            <FormLabel className={classes.formLabel} component="legend">
              This property is
            </FormLabel>
            <RadioGroup
              name="vacantUnoccupied"
              value={formik.values.vacantUnoccupied}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="NotVacantOrUnoccupied"
                control={<Radio color="primary" />}
                label="A secondary home"
              />
              <FormControlLabel
                value="Unoccupied"
                control={<Radio color="primary" />}
                label="Unoccupied"
              />
            </RadioGroup>
            {Boolean(formik.errors.vacantUnoccupied) && (
              <FormHelperText error variant="outlined">
                {formik.errors.vacantUnoccupied}
              </FormHelperText>
            )}
          </FormControl>
        </ScrollToOnError>
      )}
      {!isRenters && (
        <FormControl className={classes.formSection} component="fieldset">
          <FormLabel className={classes.formLabel} component="legend">
            Is this property under construction?
          </FormLabel>
          <ToggleButtonGroup
            className={classes.toggle}
            value={formik.values.underConstruction}
            onChange={handleToggleChange("underConstruction")}
            exclusive
            size="large"
          >
            <ToggleButton
              value={true}
              aria-label="yes"
              style={{ width: "50%", textTransform: "capitalize" }}
              color="primary"
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value={false}
              aria-label="no"
              style={{ width: "50%", textTransform: "capitalize" }}
              color="primary"
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </FormControl>
      )}
      {formik.values.own === "Y" && (
        <ScrollToOnError name="yearPurchased">
          <FormControl className={classes.formSection}>
            <FormLabel className={classes.formLabel} component="legend">
              When did you buy this home?
            </FormLabel>
            <FormControl className={classes.formSelect} variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Purchase year
              </InputLabel>
              <Select
                name="yearPurchased"
                value={formik.values.yearPurchased}
                onChange={formik.handleChange}
                label="Purchase year"
                className={classes.selectDropdown}
                error={!!formik.errors.yearPurchased}
              >
                {yearPurchasedOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {Boolean(formik.errors.yearPurchased) && (
              <FormHelperText error variant="outlined">
                {formik.errors.yearPurchased}
              </FormHelperText>
            )}
          </FormControl>
        </ScrollToOnError>
      )}
      {(!propertyLookupOn || isRenters) && (
        <>
          <ScrollToOnError name="manualYearOfConstruction">
            <FormControl variant="outlined" className={classes.formSection}>
              <FormLabel className={classes.formLabel} component="legend">
                In what year was this home built?
              </FormLabel>

              <FormControl className={classes.formSelect} variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Year built
                </InputLabel>
                <Select
                  name="manualYearOfConstruction"
                  value={formik.values.manualYearOfConstruction || ""}
                  onChange={formik.handleChange}
                  label="Year built"
                  className={classes.selectDropdown}
                  error={!!formik.errors.manualYearOfConstruction}
                >
                  {yearBuiltOptions.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {Boolean(formik.errors.manualYearOfConstruction) && (
                <FormHelperText error variant="outlined">
                  {formik.errors.manualYearOfConstruction}
                </FormHelperText>
              )}
            </FormControl>
          </ScrollToOnError>
          <ScrollToOnError name="manualSquareFootage">
            <FormControl variant="outlined" className={classes.formSection}>
              <FormLabel className={classes.formLabel} component="legend">
                What is the square footage on this home?
              </FormLabel>
              <OutlinedInput
                className={classes.formSelect}
                name="manualSquareFootage"
                type="number"
                value={formik.values.manualSquareFootage || ""}
                onChange={formik.handleChange}
                placeholder="Square footage"
                error={!!formik.errors.manualSquareFootage}
              />
              {Boolean(formik.errors.manualSquareFootage) && (
                <FormHelperText error variant="outlined">
                  {formik.errors.manualSquareFootage}
                </FormHelperText>
              )}
            </FormControl>
          </ScrollToOnError>
          <ScrollToOnError name="manualStoryType">
            <FormControl className={classes.formSection}>
              <FormLabel className={classes.formLabel} component="legend">
                How many stories does this home have?
              </FormLabel>
              <FormControl className={classes.formSelect} variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Number of stories
                </InputLabel>
                <Select
                  name="manualStoryType"
                  value={formik.values.manualStoryType}
                  onChange={formik.handleChange}
                  label="Number of stories"
                  className={classes.selectDropdown}
                  error={!!formik.errors.manualStoryType}
                >
                  {STORY_TYPE.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {Boolean(formik.errors.manualStoryType) && (
                <FormHelperText error variant="outlined">
                  {formik.errors.manualStoryType}
                </FormHelperText>
              )}
            </FormControl>
          </ScrollToOnError>
          {isRenters && (
            <>
              <ScrollToOnError name="manualExteriorWalls">
                <FormControl className={classes.formSection}>
                  <FormLabel className={classes.formLabel} component="legend">
                    What are the exterior walls made of?
                  </FormLabel>
                  <FormControl
                    className={classes.formSelect}
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Exterior walls
                    </InputLabel>
                    <Select
                      name="manualExteriorWalls"
                      value={formik.values.manualExteriorWalls}
                      onChange={formik.handleChange}
                      label="Exterior walls"
                      className={classes.selectDropdown}
                      error={!!formik.errors.manualExteriorWalls}
                    >
                      {EXTERIOR_WALLS_OPTIONS.map((type) => (
                        <MenuItem key={type.value} value={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {Boolean(formik.errors.manualExteriorWalls) && (
                    <FormHelperText error variant="outlined">
                      {formik.errors.manualExteriorWalls}
                    </FormHelperText>
                  )}
                </FormControl>
              </ScrollToOnError>
              <ScrollToOnError name="NumberOfFullBaths">
                <FormControl className={classes.formSection}>
                  <FormLabel className={classes.formLabel} component="legend">
                    How many full baths?
                  </FormLabel>
                  <FormControl
                    className={classes.formSelect}
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Full baths
                    </InputLabel>
                    <Select
                      name="NumberOfFullBaths"
                      value={formik.values.NumberOfFullBaths}
                      onChange={formik.handleChange}
                      label="Full baths"
                      className={classes.selectDropdown}
                      error={!!formik.errors.NumberOfFullBaths}
                    >
                      {NUMBERS.map((number) => (
                        <MenuItem key={number} value={number}>
                          {number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {Boolean(formik.errors.NumberOfFullBaths) && (
                    <FormHelperText error variant="outlined">
                      {formik.errors.NumberOfFullBaths}
                    </FormHelperText>
                  )}
                </FormControl>
              </ScrollToOnError>
              <ScrollToOnError name="numberOfHalfBaths">
                <FormControl className={classes.formSection}>
                  <FormLabel className={classes.formLabel} component="legend">
                    How many half baths?
                  </FormLabel>
                  <FormControl
                    className={classes.formSelect}
                    variant="outlined"
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Half baths
                    </InputLabel>
                    <Select
                      name="numberOfHalfBaths"
                      value={formik.values.numberOfHalfBaths}
                      onChange={formik.handleChange}
                      label="Full baths"
                      className={classes.selectDropdown}
                      error={!!formik.errors.numberOfHalfBaths}
                    >
                      {NUMBERS.map((number) => (
                        <MenuItem key={number} value={number}>
                          {number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {Boolean(formik.errors.numberOfHalfBaths) && (
                    <FormHelperText error variant="outlined">
                      {formik.errors.numberOfHalfBaths}
                    </FormHelperText>
                  )}
                </FormControl>
              </ScrollToOnError>
            </>
          )}
          {!isRenters && (
            <ScrollToOnError name="manualNumberOfFamilies">
              <FormControl
                className={`${classes.formSection} ${
                  formik.errors.manualNumberOfFamilies ? classes.error : ""
                }`}
                component="fieldset"
              >
                <FormLabel className={classes.formLabel} component="legend">
                  How many families live in this home?
                </FormLabel>
                <RadioGroup
                  name="manualNumberOfFamilies"
                  value={formik.values.manualNumberOfFamilies}
                  onChange={formik.handleChange}
                >
                  {FAMILY_UNITS.map((number) => (
                    <FormControlLabel
                      key={number}
                      value={`${number}`}
                      label={number}
                      control={<Radio color="primary" />}
                    />
                  ))}
                </RadioGroup>

                {Boolean(formik.errors.manualNumberOfFamilies) && (
                  <FormHelperText error variant="outlined">
                    {formik.errors.manualNumberOfFamilies}
                  </FormHelperText>
                )}
              </FormControl>
            </ScrollToOnError>
          )}
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        className={classes.nextButton}
        type="submit"
        disabled={!formik.isValid}
        onClick={() => setFormSubmitted(true)}
      >
        Next
      </Button>
    </Form>
  );
}
