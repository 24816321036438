import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  page: {
    width: "96%",
    height: 750,
    [theme.breakpoints.down('md')]: {
      width: "100%",
      paddingBottom: 48,
    },
  },
  topArea: {
    paddingTop: 16,
    paddingBottom: 16,
    display: "flex",
    justifyContent: "space-between",
  },
  header: {
    fontSize: 24,
    fontWeight: 600,
  },
  table: {
    minWidth: 650,
  },
  link: {
    textDecoration: "none",
  },
  agencyField: {
    width: "300px",
  },
}));
