import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LifeBaseInfo, LifeState, StartFormShape } from "./types";
const initialState: LifeState = {
  startForm: {},
  insuredDetails: {
    dob: "",
    gender: "",
    smoke: false,
    healthClass: "",
  },
  coverageDetails: {
    yearTerm: "",
    amount: "",
  },
  quotes: [],
  baseInfo: null,
  selectedQuote: null,
};
export const life = {
  ...createSlice({
    name: "life",
    initialState: initialState,
    reducers: {
      resetState: () => initialState,
      setStartForm(state, { payload }: PayloadAction<StartFormShape>) {
        state.startForm = payload;
      },
      setInsured(state, { payload }: PayloadAction<Object>) {
        state.insuredDetails = { ...state.insuredDetails, ...payload };
      },
      setCoverages(state, { payload }: PayloadAction<Object>) {
        state.coverageDetails = { ...state.coverageDetails, ...payload };
      },
      setQuotes(state, { payload }: PayloadAction<any>) {
        state.quotes = payload;
      },
      setBaseInfo(state, { payload }: PayloadAction<LifeBaseInfo>) {
        state.baseInfo = payload;
      },
      setWizard(state, { payload }: PayloadAction<any>) {
        state.startForm = payload.startForm;
        state.insuredDetails = payload.insuredDetails;
        state.coverageDetails = payload.coverageDetails;
        state.baseInfo = payload.baseInfo;
      },
      setSelectedQuote(state, { payload }: PayloadAction<any>) {
        state.selectedQuote = payload;
      },
      clearSelectedLifeQuote(state) {
        state.selectedQuote = null;
      },
      clearLifeQuotes(state) {
        state.quotes = [];
      },
    },
  }),
  persist: true,
};
