import * as React from "react";
const SVGComponent = (props) => (
  <svg
    id="Icons"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    width={20}
    height={20}
    {...props}
  >
    <style type="text/css">
      {
        ".st0{fill:#FFFFFF;}\n\t.st1{fill:#3A559F;}\n\t.st2{fill:#F4F4F4;}\n\t.st3{fill:#FF0084;}\n\t.st4{fill:#0063DB;}\n\t.st5{fill:#00ACED;}\n\t.st6{fill:#FFEC06;}\n\t.st7{fill:#FF0000;}\n\t.st8{fill:#25D366;}\n\t.st9{fill:#0088FF;}\n\t.st10{fill:#314358;}\n\t.st11{fill:#EE6996;}\n\t.st12{fill:#01AEF3;}\n\t.st13{fill:#FFFEFF;}\n\t.st14{fill:#F06A35;}\n\t.st15{fill:#00ADEF;}\n\t.st16{fill:#1769FF;}\n\t.st17{fill:#1AB7EA;}\n\t.st18{fill:#6001D1;}\n\t.st19{fill:#E41214;}\n\t.st20{fill:#05CE78;}\n\t.st21{fill:#7B519C;}\n\t.st22{fill:#FF4500;}\n\t.st23{fill:#00F076;}\n\t.st24{fill:#FFC900;}\n\t.st25{fill:#00D6FF;}\n\t.st26{fill:#FF3A44;}\n\t.st27{fill:#FF6A36;}\n\t.st28{fill:#0061FE;}\n\t.st29{fill:#F7981C;}\n\t.st30{fill:#EE1B22;}\n\t.st31{fill:#EF3561;}\n\t.st32{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-miterlimit:10;}\n\t.st33{fill:#0097D3;}\n\t.st34{fill:#01308A;}\n\t.st35{fill:#019CDE;}\n\t.st36{fill:#FFD049;}\n\t.st37{fill:#16A05D;}\n\t.st38{fill:#4486F4;}\n\t.st39{fill:none;}\n\t.st40{fill:#34A853;}\n\t.st41{fill:#4285F4;}\n\t.st42{fill:#FBBC05;}\n\t.st43{fill:#EA4335;}"
      }
    </style>
    <g>
      <path
        className="st39"
        d="M5.561 10c0 0.376 0.061 0.751 0.127 1.125L6.876 10 5.752 8.875A3.516 3.516 0 0 0 5.563 10z"
      />
      <path
        className="st40"
        d="M10 14.439a4.369 4.369 0 0 1 -4.251 -3.251l-4.188 4.188A10.013 10.013 0 0 0 10 20a9.796 9.796 0 0 0 5.312 -1.561l-4.188 -4.188a3.516 3.516 0 0 1 -1.125 0.188z"
      />
      <path
        className="st41"
        d="M20 8.627a0.608 0.608 0 0 0 -0.627 -0.5H10c-0.376 0 -0.627 0.251 -0.627 0.627v3.125c0 0.376 0.251 0.627 0.627 0.627h3.312a4.491 4.491 0 0 1 -2.188 1.751l4.188 4.188A9.936 9.936 0 0 0 20 10v-0.439a2.364 2.364 0 0 0 0 -0.939z"
      />
      <path
        className="st42"
        d="M5.561 10c0 -0.376 0.061 -0.751 0.127 -1.125L1.561 4.688a9.815 9.815 0 0 0 0 10.624l4.188 -4.188a3.516 3.516 0 0 1 -0.188 -1.125z"
      />
      <path
        className="st43"
        d="M17.812 3.751a10.7 10.7 0 0 0 -2.5 -2.251A10.292 10.292 0 0 0 10 0a9.928 9.928 0 0 0 -8.439 4.688l4.188 4.188A4.424 4.424 0 0 1 10 5.627c0.376 0 0.751 0.061 1.125 0.188 0.564 0.188 1.061 0.5 1.627 0.939 0.188 0.188 0.439 0.188 0.688 0.061l4.188 -2.061a0.468 0.468 0 0 0 0.312 -0.439c0.061 -0.188 0 -0.376 -0.127 -0.564z"
      />
    </g>
  </svg>
);
export default SVGComponent;
