import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WizardState } from "./types";

const contactModalDefault = {
  isOpen: false,
  type: undefined,
};

const coverageModalDefault = {
  isOpen: false,
  internal_name: "",
  title: "",
  description: "",
};

const initialState: WizardState = {
  activeStep: 0,
  addressError: "",
  activeQuoteCard: 1,
  allQuotes: undefined,
  carouselMode: "auto",
  chatMode: undefined,
  contactInfo: {
    email: "",
    phone: "",
  },
  addressModalOpen: false,
  contactModal: {},
  explainerModalOpen: false,
  contactType: "",
  coverageDetails: undefined,
  coverageModal: coverageModalDefault,
  customQuotes: [],
  customQuotesLoading: false,
  offlineChatSubmitted: false,
  prefContactMethod: undefined,
  quoteWasHydrated: false,
  req_uid: "",
  settings: {},
  shopperApprovedShown: false,
  showChat: false,
  showPmtPopup: false,
  selectedAdminQuote: "",
  upgradedBundle: undefined,
};

export const wizard = {
  ...createSlice({
    name: "wizard",
    initialState,
    reducers: {
      // General Wizard Info
      refreshWizardState: () => initialState,
      setActiveWizardStep(state, { payload }: PayloadAction<number>) {
        state.activeStep = payload;
      },
      setAddressError(state, { payload }: PayloadAction<string>) {
        state.addressError = payload;
      },
      setReqUid(state, { payload }: PayloadAction<string>) {
        state.req_uid = payload;
      },
      setCoverageDetails(state, { payload }: PayloadAction<any[]>) {
        state.coverageDetails = payload;
      },
      setShopperApprovedShown(state, { payload }: PayloadAction<boolean>) {
        state.shopperApprovedShown = payload;
      },
      // Modals
      resetContactModal(state) {
        state.contactModal = contactModalDefault;
      },
      setContactModal(state, { payload }: PayloadAction<any>) {
        state.contactModal = { isOpen: true, type: payload };
      },
      setExplainerModalOpen(state, { payload }: PayloadAction<any>) {
        state.explainerModalOpen = payload;
      },
      showContactModal(state, { payload }: PayloadAction<any>) {
        state.contactModal = { isOpen: true, type: payload };
      },
      closeContactModal(state) {
        state.contactModal = { isOpen: false, type: undefined };
      },
      setAddressModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.addressModalOpen = payload;
      },
      resetCoverageModal(state) {
        state.coverageModal = coverageModalDefault;
      },
      setCoverageModal(state, { payload }: PayloadAction<string>) {
        const cd = state.coverageDetails?.find(
          (x) => x.internal_name.toLowerCase() === payload.toLowerCase(),
        );
        state.coverageModal = { ...cd, isOpen: true };
      },
      // Contact
      setContactInfo(state, { payload }: PayloadAction<Object>) {
        state.contactInfo = { ...state.contactInfo, ...payload };
      },
      setContactType(state, { payload }: PayloadAction<string>) {
        state.contactType = payload;
      },
      // Quotes
      setActiveQuoteCard(state, { payload }: PayloadAction<any>) {
        state.activeQuoteCard = payload;
        //TODO: unset activeQuoteCard when leaving quotes page
      },
      setAllQuotes(state, { payload }: PayloadAction<any>) {
        state.allQuotes = payload;
      },
      setCustomQuotesLoading(state, { payload }: PayloadAction<boolean>) {
        state.customQuotesLoading = payload;
      },
      clearQuotes(state) {
        state.allQuotes = [];
      },
      setCarouselMode(state, { payload }: PayloadAction<any>) {
        state.carouselMode = payload;
      },
      // Hydrated Quote
      setQuoteWasHydrated(state, { payload }: PayloadAction<boolean>) {
        state.quoteWasHydrated = payload;
      },
      setWizard(state, { payload }: PayloadAction<any>) {
        state.contactInfo = payload.contact;
        state.req_uid = payload.req_uid;
        state.shopperApprovedShown = payload.shopperApprovedShown;
      },
      // Chat
      setShowChat(state, { payload }: PayloadAction<boolean>) {
        state.showChat = payload;
      },
      setChatMode(state, { payload }: PayloadAction<any>) {
        state.chatMode = payload;
      },
      setOfflineChatSubmitted(state, { payload }: PayloadAction<boolean>) {
        state.offlineChatSubmitted = payload;
      },
      setPrefContactMethod(state, { payload }: PayloadAction<any>) {
        state.prefContactMethod = payload;
      },
      // Payment
      showPmtPopup(state, { payload }: PayloadAction<boolean>) {
        state.showPmtPopup = payload;
      },
      // Settings
      setSettings(state, { payload }: PayloadAction<Record<string, any>>) {
        state.settings = payload;
      },
      setSelectedAdminQuote(
        state,
        { payload }: PayloadAction<Record<string, any>>,
      ) {
        state.selectedAdminQuote = payload;
      },
      // bundle and save
      setBundleAndSave(
        state,
        { payload }: PayloadAction<"auto" | "home" | undefined>,
      ) {
        state.upgradedBundle = payload;
      },
    },
  }),
  persist: true,
};
