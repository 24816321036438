import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  feature: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    marginLeft: 40,
    fontSize: 14,
    fontWeight: 500,
    "& .MuiSvgIcon-root": {
      marginRight: 8,
    },
    [theme.breakpoints.down('sm')]: {
      display: "block",
      fontSize: 12,
      width: 100,
      marginLeft: 8,
      "& .MuiSvgIcon-root": {
        display: "block",
        margin: "0 auto 8px",
      },
    },
  },
  firstFeature: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      display: "block",
      marginLeft: "auto",
      marginRight: 8,
    },
  },
  fields: {
    marginBottom: theme.spacing(2),
  },
  aptField: {
    marginTop: 24,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  autocompleteError: {
    marginTop: 16,
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  text: {
    textAlign: "center",
    marginTop: theme.spacing(1.2),
    fontSize: "0.9rem",
    marginBottom: theme.spacing(3),
  },
  linkWrapper: {
    textAlign: "center",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    textTransform: "none",
    fontSize: 14,
    fontWeight: 500,
    transition: "all 300ms linear",
    cursor: "pointer",

    "&:hover": {
      opacity: 0.9,
      backgroundColor: "transparent",
    },
  },
}));
