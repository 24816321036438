import React from "react";

export const LossUseImage = () => {
  return (
    <svg
      width="84px"
      height="84px"
      viewBox="0 0 84 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 2 2</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="84" height="84"></rect>
      </defs>
      <g
        id="↳-home-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Home---Create-a-custom-quote-side-bar"
          transform="translate(-564.000000, -964.000000)"
        >
          <g id="Group-2" transform="translate(564.000000, 964.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use id="Rectangle" fill="#FFFFFF" xlinkHref="#path-1"></use>
            <path
              d="M58.2667725,40.7821231 C58.4005543,40.8527453 58.5416764,40.699812 58.4597692,40.5732343 C58.0151876,39.8861455 57.5941936,38.7299679 58.2649375,37.2409682 C59.3834854,34.7579241 60.0641208,33.4718799 60.0641208,33.4718799 C60.0641208,33.4718799 60.4261462,34.9726797 61.4075108,36.3071017 C62.3520172,37.5913903 62.8689949,39.2066123 62.0355555,40.5521898 C61.9573409,40.6784787 62.0952627,40.8274787 62.2281717,40.7599454 C63.2598444,40.235901 64.4169738,39.1847234 64.547533,37.0929015 C64.5956702,36.4537016 64.5234755,35.5588129 64.1624796,34.4295464 C63.6983164,32.9983022 63.1276963,32.3304579 62.7975387,32.0436579 C62.6987801,31.9578579 62.5451929,32.0339024 62.5532941,32.1639468 C62.6495015,33.7082576 62.0644245,34.1001465 61.7314023,33.2169688 C61.5984038,32.8642133 61.5208382,32.2540801 61.5208382,31.511058 C61.5208382,30.2740582 61.159417,29.0006807 60.3626122,27.9659253 C60.1554049,27.6968364 59.9129506,27.445392 59.6347794,27.2309698 C59.5340738,27.1533254 59.3880955,27.2313698 59.3973157,27.3576365 C59.4584999,28.1962141 59.4030671,30.5995248 57.2797236,33.4707244 C55.3544792,36.1340795 56.1005061,38.1795236 56.3652275,38.7335235 C56.8713513,39.7948344 57.5773198,40.418101 58.2667725,40.7821231 Z"
              id="Path-Copy-12"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M10.3667725,27.1821231 C10.5005543,27.2527453 10.6416764,27.099812 10.5597692,26.9732343 C10.1151876,26.2861455 9.69419357,25.1299679 10.3649375,23.6409682 C11.4834854,21.1579241 12.1641208,19.8718799 12.1641208,19.8718799 C12.1641208,19.8718799 12.5261462,21.3726797 13.5075108,22.7071017 C14.4520172,23.9913903 14.9689949,25.6066123 14.1355555,26.9521898 C14.0573409,27.0784787 14.1952627,27.2274787 14.3281717,27.1599454 C15.3598444,26.635901 16.5169738,25.5847234 16.647533,23.4929015 C16.6956702,22.8537016 16.6234755,21.9588129 16.2624796,20.8295464 C15.7983164,19.3983022 15.2276963,18.7304579 14.8975387,18.4436579 C14.7987801,18.3578579 14.6451929,18.4339024 14.6532941,18.5639468 C14.7495015,20.1082576 14.1644245,20.5001465 13.8314023,19.6169688 C13.6984038,19.2642133 13.6208382,18.6540801 13.6208382,17.911058 C13.6208382,16.6740582 13.259417,15.4006807 12.4626122,14.3659253 C12.2554049,14.0968364 12.0129506,13.845392 11.7347794,13.6309698 C11.6340738,13.5533254 11.4880955,13.6313698 11.4973157,13.7576365 C11.5584999,14.5962141 11.5030671,16.9995248 9.37972364,19.8707244 C7.45447924,22.5340795 8.20050613,24.5795236 8.46522751,25.1335235 C8.97135135,26.1948344 9.6773198,26.818101 10.3667725,27.1821231 Z"
              id="Path-Copy-13"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M26.6667725,23.8821231 C26.8005543,23.9527453 26.9416764,23.799812 26.8597692,23.6732343 C26.4151876,22.9861455 25.9941936,21.8299679 26.6649375,20.3409682 C27.7834854,17.8579241 28.4641208,16.5718799 28.4641208,16.5718799 C28.4641208,16.5718799 28.8261462,18.0726797 29.8075108,19.4071017 C30.7520172,20.6913903 31.2689949,22.3066123 30.4355555,23.6521898 C30.3573409,23.7784787 30.4952627,23.9274787 30.6281717,23.8599454 C31.6598444,23.335901 32.8169738,22.2847234 32.947533,20.1929015 C32.9956702,19.5537016 32.9234755,18.6588129 32.5624796,17.5295464 C32.0983164,16.0983022 31.5276963,15.4304579 31.1975387,15.1436579 C31.0987801,15.0578579 30.9451929,15.1339024 30.9532941,15.2639468 C31.0495015,16.8082576 30.4644245,17.2001465 30.1314023,16.3169688 C29.9984038,15.9642133 29.9208382,15.3540801 29.9208382,14.611058 C29.9208382,13.3740582 29.559417,12.1006807 28.7626122,11.0659253 C28.5554049,10.7968364 28.3129506,10.545392 28.0347794,10.3309698 C27.9340738,10.2533254 27.7880955,10.3313698 27.7973157,10.4576365 C27.8584999,11.2962141 27.8030671,13.6995248 25.6797236,16.5707244 C23.7544792,19.2340795 24.5005061,21.2795236 24.7652275,21.8335235 C25.2713513,22.8948344 25.9773198,23.518101 26.6667725,23.8821231 Z"
              id="Path-Copy-14"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <g id="Group-3" mask="url(#mask-2)">
              <g transform="translate(-62.400000, 14.400000)">
                <g id="Group-14" strokeWidth="1" fill="none">
                  <polygon
                    id="Path"
                    fill="#002679"
                    fillRule="nonzero"
                    points="13.8180274 28.2580208 10.9873366 23.5345913 37.4084854 0.860759494 105.349169 0.860759494 105.349169 7.47356082 80.8151471 33.9268213 52.5061869 39.5949367"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#DADFF6"
                    fillRule="nonzero"
                    points="117.000935 0 108.139032 0 108.139032 4.62883799 105.349578 1.46539051 82.7871728 23.9823335 79.0263176 27.7349305 74.3261006 46.4992764 82.7871728 67.1392405 126.031214 67.1392405 126.031214 24.9204827 117.000935 14.6790766"
                  ></polygon>
                  <rect
                    id="Rectangle"
                    fill="#E7EBFD"
                    fillRule="nonzero"
                    x="10.9873366"
                    y="23.8860759"
                    width="71.7408449"
                    height="43.2531646"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fillOpacity="0.546282575"
                    fill="#FF4343"
                    fillRule="nonzero"
                    x="57.3065007"
                    y="37.443038"
                    width="15.2960961"
                    height="13.556962"
                  ></rect>
                  <polygon
                    id="Path"
                    fillOpacity="0.546282575"
                    fill="#FF4343"
                    fillRule="nonzero"
                    points="103.194789 42.1772152 103.194789 37.443038 97.5934016 37.443038 97.5934016 42.1772152 102.727725 42.1772152"
                  ></polygon>
                  <polygon
                    id="Path"
                    fillOpacity="0.546282575"
                    fill="#FF4343"
                    fillRule="nonzero"
                    points="102.727725 46.2658228 97.5934016 46.2658228 97.5934016 51 103.194789 51 103.194789 46.2658228"
                  ></polygon>
                  <rect
                    id="Rectangle"
                    fillOpacity="0.546282575"
                    fill="#FF4343"
                    fillRule="nonzero"
                    x="107.28811"
                    y="37.443038"
                    width="5.60138729"
                    height="4.73417722"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fillOpacity="0.546282575"
                    fill="#FF4343"
                    fillRule="nonzero"
                    x="107.28811"
                    y="46.2658228"
                    width="5.60138729"
                    height="4.73417722"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    x="140.034682"
                    y="14.2025316"
                    width="1"
                    height="39.5949367"
                    rx="0.5"
                  ></rect>
                </g>
                <rect
                  id="Rectangle"
                  fill="#000000"
                  x="49.6190024"
                  y="67.8176944"
                  width="103.980998"
                  height="1"
                ></rect>
              </g>
            </g>
            <path
              d="M5.74527521,54.7687155 C5.97811986,54.8923043 6.2237402,54.6246711 6.08118225,54.40316 C5.30739639,53.2007546 4.57466411,51.1774439 5.74208129,48.5716943 C7.68889316,44.2263672 8.87352635,41.9757898 8.87352635,41.9757898 C8.87352635,41.9757898 9.5036247,44.6021894 11.2116715,46.9374279 C12.8555672,49.1849331 13.7553571,52.0115715 12.3047716,54.3663322 C12.1686404,54.5873377 12.4086909,54.8480877 12.6400164,54.7299044 C14.4356234,53.8128268 16.4495855,51.9732659 16.6768213,48.3125777 C16.7606033,47.1939779 16.6349498,45.6279226 16.0066432,43.6517062 C15.1987758,41.1470289 14.2056221,39.9783013 13.6309889,39.4764014 C13.4591014,39.3262514 13.1917858,39.4593291 13.2058858,39.6869069 C13.373333,42.3894509 12.3550174,43.0752563 11.7753986,41.5296955 C11.5439172,40.9123733 11.4089156,39.8446402 11.4089156,38.5443515 C11.4089156,36.3796019 10.7798689,34.1511911 9.39304491,32.3403692 C9.03240446,31.8694637 8.61041735,31.429436 8.12626562,31.0541972 C7.95098945,30.9183195 7.69691691,31.0548972 7.71296442,31.2758638 C7.81945443,32.7433747 7.72297463,36.9491685 4.02733506,41.9737676 C0.67648319,46.6346391 1.97492904,50.2141662 2.43567166,51.1836661 C3.31657073,53.0409602 4.54529561,54.1316767 5.74527521,54.7687155 Z"
              id="Path"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M-0.552164289,57.7804143 C-0.395892711,57.8577872 -0.231046848,57.6902352 -0.326723324,57.5515581 C-0.84604269,56.7987918 -1.33780932,55.5320972 -0.554307861,53.9007666 C0.752277287,51.1803728 1.54733312,49.7713978 1.54733312,49.7713978 C1.54733312,49.7713978 1.97021792,51.4156564 3.11655804,52.8776334 C4.21984374,54.284685 4.82372961,56.0543032 3.85018225,57.5285021 C3.75881906,57.6668627 3.91992675,57.8301053 4.07517882,57.7561166 C5.28028416,57.1819798 6.63193659,56.030322 6.78444385,53.7385465 C6.84067339,53.0382466 6.75634215,52.0578171 6.33465985,50.820606 C5.79246701,49.2525517 5.12592089,48.5208693 4.74026104,48.2066546 C4.6249003,48.1126532 4.44549384,48.1959666 4.45495692,48.3384417 C4.56733757,50.0303705 3.88390429,50.4597193 3.49489838,49.4921203 C3.33954174,49.1056455 3.24893664,48.4371907 3.24893664,47.6231444 C3.24893664,46.267902 2.82675765,44.8728045 1.8960033,43.7391387 C1.65396272,43.4443281 1.3707499,43.1688486 1.04581585,42.9339302 C0.928180841,42.8488639 0.757662356,42.9343684 0.768432495,43.0727047 C0.8399023,43.9914405 0.775150761,46.6244794 -1.70514425,49.7701318 C-3.95403813,52.6880724 -3.08259796,54.9290368 -2.77337472,55.5359926 C-2.1821673,56.6987524 -1.35751972,57.3815959 -0.552164289,57.7804143 Z"
              id="Path-Copy"
              fill="#BC1427"
              fillRule="nonzero"
              mask="url(#mask-2)"
              transform="translate(1.800000, 50.350000) scale(-1, 1) translate(-1.800000, -50.350000) "
            ></path>
            <path
              d="M46.6529941,53.1936905 C46.7033366,53.218616 46.7564413,53.1646395 46.7256193,53.119965 C46.5583218,52.8774631 46.3999004,52.4694004 46.6523035,51.9438711 C47.0732168,51.0675026 47.3293422,50.6136047 47.3293422,50.6136047 C47.3293422,50.6136047 47.4655736,51.1432987 47.8348643,51.6142712 C48.1902852,52.0675495 48.3848256,52.6376279 48.0711997,53.1125376 C48.0417672,53.1571101 48.0936677,53.2096984 48.1436818,53.1858631 C48.5319036,53.0009062 48.9673353,52.6299024 49.0164651,51.8916123 C49.0345793,51.6660123 49.0074122,51.3501693 48.8715683,50.9516046 C48.6969021,50.4464596 48.4821759,50.2107498 48.3579364,50.1095263 C48.3207733,50.079244 48.2629779,50.1060832 48.2660264,50.1519812 C48.3022296,50.6970321 48.0820631,50.8353458 47.9567458,50.5236361 C47.906698,50.3991341 47.8775098,50.183793 47.8775098,49.9215499 C47.8775098,49.4849617 47.7415058,49.0355343 47.4416655,48.6703266 C47.3636927,48.575354 47.2724563,48.4866089 47.1677796,48.4109305 C47.1298838,48.3835266 47.0749516,48.4110717 47.0784212,48.4556364 C47.101445,48.751605 47.0805855,49.5998323 46.2815643,50.6131968 C45.5570884,51.5532045 45.8378208,52.275126 45.9374363,52.4706553 C46.1278924,52.8452357 46.3935507,53.0652121 46.6529941,53.1936905 Z"
              id="Path-Copy-2"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M48.2529941,61.5936905 C48.3033366,61.618616 48.3564413,61.5646395 48.3256193,61.519965 C48.1583218,61.2774631 47.9999004,60.8694004 48.2523035,60.3438711 C48.6732168,59.4675026 48.9293422,59.0136047 48.9293422,59.0136047 C48.9293422,59.0136047 49.0655736,59.5432987 49.4348643,60.0142712 C49.7902852,60.4675495 49.9848256,61.0376279 49.6711997,61.5125376 C49.6417672,61.5571101 49.6936677,61.6096984 49.7436818,61.5858631 C50.1319036,61.4009062 50.5673353,61.0299024 50.6164651,60.2916123 C50.6345793,60.0660123 50.6074122,59.7501693 50.4715683,59.3516046 C50.2969021,58.8464596 50.0821759,58.6107498 49.9579364,58.5095263 C49.9207733,58.479244 49.8629779,58.5060832 49.8660264,58.5519812 C49.9022296,59.0970321 49.6820631,59.2353458 49.5567458,58.9236361 C49.506698,58.7991341 49.4775098,58.583793 49.4775098,58.3215499 C49.4775098,57.8849617 49.3415058,57.4355343 49.0416655,57.0703266 C48.9636927,56.975354 48.8724563,56.8866089 48.7677796,56.8109305 C48.7298838,56.7835266 48.6749516,56.8110717 48.6784212,56.8556364 C48.701445,57.151605 48.6805855,57.9998323 47.8815643,59.0131968 C47.1570884,59.9532045 47.4378208,60.675126 47.5374363,60.8706553 C47.7278924,61.2452357 47.9935507,61.4652121 48.2529941,61.5936905 Z"
              id="Path-Copy-3"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M36.426497,64.6968453 C36.4516683,64.709308 36.4782206,64.6823198 36.4628097,64.6599825 C36.3791609,64.5387316 36.2999502,64.3347002 36.4261518,64.0719356 C36.6366084,63.6337513 36.7646711,63.4068023 36.7646711,63.4068023 C36.7646711,63.4068023 36.8327868,63.6716494 37.0174322,63.9071356 C37.1951426,64.1337748 37.2924128,64.4188139 37.1355998,64.6562688 C37.1208836,64.6785551 37.1468338,64.7048492 37.1718409,64.6929315 C37.3659518,64.6004531 37.5836676,64.4149512 37.6082326,64.0458062 C37.6172897,63.9330062 37.6037061,63.7750846 37.5357841,63.5758023 C37.4484511,63.3232298 37.3410879,63.2053749 37.2789682,63.1547632 C37.2603866,63.139622 37.2314889,63.1530416 37.2330132,63.1759906 C37.2511148,63.4485161 37.1410316,63.5176729 37.0783729,63.361818 C37.053349,63.2995671 37.0387549,63.1918965 37.0387549,63.0607749 C37.0387549,62.8424809 36.9707529,62.6177672 36.8208327,62.4351633 C36.7818463,62.387677 36.7362282,62.3433045 36.6838898,62.3054653 C36.6649419,62.2917633 36.6374758,62.3055359 36.6392106,62.3278182 C36.6507225,62.4758025 36.6402927,62.8999161 36.2407821,63.4065984 C35.8785442,63.8766023 36.0189104,64.237563 36.0687182,64.3353277 C36.1639462,64.5226178 36.2967753,64.6326061 36.426497,64.6968453 Z"
              id="Path-Copy-4"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M35.0333629,81.995005 C35.0707431,82.0147377 35.1101743,81.9720063 35.0872884,81.936639 C34.9630671,81.7446583 34.8454364,81.4216087 35.0328502,81.0055646 C35.3453856,80.3117729 35.5355632,79.952437 35.5355632,79.952437 C35.5355632,79.952437 35.6367173,80.3717781 35.9109221,80.7446313 C36.1748283,81.1034767 36.319278,81.5547887 36.0864052,81.9307589 C36.0645511,81.9660455 36.1030881,82.0076779 36.1402245,81.9888083 C36.4284859,81.8423841 36.7518015,81.5486727 36.7882813,80.9641931 C36.8017314,80.7855931 36.7815593,80.5355507 36.6806928,80.2200203 C36.5510002,79.8201139 36.3915622,79.6335103 36.2993123,79.553375 C36.271718,79.5294015 36.2288039,79.5506492 36.2310675,79.5869851 C36.2579489,80.0184838 36.0944715,80.1279821 36.0014212,79.8812119 C35.9642599,79.7826478 35.9425871,79.6121694 35.9425871,79.4045603 C35.9425871,79.058928 35.8416018,78.7031314 35.6189652,78.4140085 C35.561069,78.3388219 35.4933244,78.2685654 35.4156001,78.2086533 C35.3874618,78.1869586 35.3466737,78.2087651 35.34925,78.2440455 C35.3663456,78.4783539 35.350857,79.1498672 34.7575698,79.9521142 C34.2196339,80.6962869 34.4280826,81.2678081 34.5020489,81.4226021 C34.6434658,81.7191449 34.8407217,81.8932929 35.0333629,81.995005 Z"
              id="Path-Copy-6"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M2.43336291,81.995005 C2.47074311,82.0147377 2.51017428,81.9720063 2.48728844,81.936639 C2.36306713,81.7446583 2.24543644,81.4216087 2.43285017,81.0055646 C2.74538564,80.3117729 2.93556318,79.952437 2.93556318,79.952437 C2.93556318,79.952437 3.03671732,80.3717781 3.31092214,80.7446313 C3.57482834,81.1034767 3.71927798,81.5547887 3.48640522,81.9307589 C3.46455113,81.9660455 3.50308812,82.0076779 3.54022445,81.9888083 C3.82848593,81.8423841 4.1518015,81.5486727 4.18828127,80.9641931 C4.20173139,80.7855931 4.18155934,80.5355507 4.08069284,80.2200203 C3.95100018,79.8201139 3.7915622,79.6335103 3.69931227,79.553375 C3.67171796,79.5294015 3.62880389,79.5506492 3.63106746,79.5869851 C3.65794894,80.0184838 3.49447154,80.1279821 3.40142124,79.8812119 C3.36425989,79.7826478 3.34258713,79.6121694 3.34258713,79.4045603 C3.34258713,79.058928 3.24160182,78.7031314 3.01896516,78.4140085 C2.961069,78.3388219 2.89332443,78.2685654 2.81560013,78.2086533 C2.78746181,78.1869586 2.74667375,78.2087651 2.74924997,78.2440455 C2.76634556,78.4783539 2.75085698,79.1498672 2.15756984,79.9521142 C1.61963391,80.6962869 1.8280826,81.2678081 1.90204886,81.4226021 C2.04346582,81.7191449 2.24072171,81.8932929 2.43336291,81.995005 Z"
              id="Path-Copy-7"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M37.9333629,16.295005 C37.9707431,16.3147377 38.0101743,16.2720063 37.9872884,16.236639 C37.8630671,16.0446583 37.7454364,15.7216087 37.9328502,15.3055646 C38.2453856,14.6117729 38.4355632,14.252437 38.4355632,14.252437 C38.4355632,14.252437 38.5367173,14.6717781 38.8109221,15.0446313 C39.0748283,15.4034767 39.219278,15.8547887 38.9864052,16.2307589 C38.9645511,16.2660455 39.0030881,16.3076779 39.0402245,16.2888083 C39.3284859,16.1423841 39.6518015,15.8486727 39.6882813,15.2641931 C39.7017314,15.0855931 39.6815593,14.8355507 39.5806928,14.5200203 C39.4510002,14.1201139 39.2915622,13.9335103 39.1993123,13.853375 C39.171718,13.8294015 39.1288039,13.8506492 39.1310675,13.8869851 C39.1579489,14.3184838 38.9944715,14.4279821 38.9014212,14.1812119 C38.8642599,14.0826478 38.8425871,13.9121694 38.8425871,13.7045603 C38.8425871,13.358928 38.7416018,13.0031314 38.5189652,12.7140085 C38.461069,12.6388219 38.3933244,12.5685654 38.3156001,12.5086533 C38.2874618,12.4869586 38.2466737,12.5087651 38.24925,12.5440455 C38.2663456,12.7783539 38.250857,13.4498672 37.6575698,14.2521142 C37.1196339,14.9962869 37.3280826,15.5678081 37.4020489,15.7226021 C37.5434658,16.0191449 37.7407217,16.1932929 37.9333629,16.295005 Z"
              id="Path-Copy-8"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M2.43336291,30.995005 C2.47074311,31.0147377 2.51017428,30.9720063 2.48728844,30.936639 C2.36306713,30.7446583 2.24543644,30.4216087 2.43285017,30.0055646 C2.74538564,29.3117729 2.93556318,28.952437 2.93556318,28.952437 C2.93556318,28.952437 3.03671732,29.3717781 3.31092214,29.7446313 C3.57482834,30.1034767 3.71927798,30.5547887 3.48640522,30.9307589 C3.46455113,30.9660455 3.50308812,31.0076779 3.54022445,30.9888083 C3.82848593,30.8423841 4.1518015,30.5486727 4.18828127,29.9641931 C4.20173139,29.7855931 4.18155934,29.5355507 4.08069284,29.2200203 C3.95100018,28.8201139 3.7915622,28.6335103 3.69931227,28.553375 C3.67171796,28.5294015 3.62880389,28.5506492 3.63106746,28.5869851 C3.65794894,29.0184838 3.49447154,29.1279821 3.40142124,28.8812119 C3.36425989,28.7826478 3.34258713,28.6121694 3.34258713,28.4045603 C3.34258713,28.058928 3.24160182,27.7031314 3.01896516,27.4140085 C2.961069,27.3388219 2.89332443,27.2685654 2.81560013,27.2086533 C2.78746181,27.1869586 2.74667375,27.2087651 2.74924997,27.2440455 C2.76634556,27.4783539 2.75085698,28.1498672 2.15756984,28.9521142 C1.61963391,29.6962869 1.8280826,30.2678081 1.90204886,30.4226021 C2.04346582,30.7191449 2.24072171,30.8932929 2.43336291,30.995005 Z"
              id="Path-Copy-9"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M48.7667305,14.3892213 C48.847393,14.4318023 48.9324813,14.3395926 48.8830961,14.2632736 C48.6150396,13.8489995 48.3612049,13.1518924 48.7656241,12.2541132 C49.4400427,10.7569837 49.8504258,9.98157465 49.8504258,9.98157465 C49.8504258,9.98157465 50.0687058,10.8864686 50.6604109,11.6910466 C51.2298927,12.4653971 51.5415998,13.4392809 51.039085,14.250585 C50.9919261,14.3267298 51.0750849,14.416568 51.1552212,14.3758494 C51.7772591,14.0598815 52.4749401,13.4260832 52.5536596,12.1648377 C52.5826835,11.7794377 52.5391544,11.2398725 52.3214951,10.5589912 C52.041632,9.69603515 51.6975816,9.29336431 51.498516,9.12044081 C51.4389703,9.06870846 51.3463663,9.11455878 51.3512508,9.19296792 C51.4092582,10.1240965 51.0564912,10.3603824 50.8556985,9.82787827 C50.7755082,9.61518746 50.7287407,9.24731301 50.7287407,8.79931439 C50.7287407,8.05347628 50.510825,7.28570451 50.0303985,6.66180788 C49.9054647,6.49956314 49.759279,6.34795696 49.5915582,6.21867299 C49.5308386,6.17185796 49.4428223,6.21891417 49.4483815,6.29504553 C49.485272,6.80065851 49.4518493,8.2497135 48.1715981,9.98087792 C47.010789,11.5867244 47.4605993,12.8200069 47.6202107,13.1540362 C47.9253736,13.7939443 48.3510311,14.1697373 48.7667305,14.3892213 Z"
              id="Path-Copy-10"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M62.5667725,81.9821231 C62.7005543,82.0527453 62.8416764,81.899812 62.7597692,81.7732343 C62.3151876,81.0861455 61.8941936,79.9299679 62.5649375,78.4409682 C63.6834854,75.9579241 64.3641208,74.6718799 64.3641208,74.6718799 C64.3641208,74.6718799 64.7261462,76.1726797 65.7075108,77.5071017 C66.6520172,78.7913903 67.1689949,80.4066123 66.3355555,81.7521898 C66.2573409,81.8784787 66.3952627,82.0274787 66.5281717,81.9599454 C67.5598444,81.435901 68.7169738,80.3847234 68.847533,78.2929015 C68.8956702,77.6537016 68.8234755,76.7588129 68.4624796,75.6295464 C67.9983164,74.1983022 67.4276963,73.5304579 67.0975387,73.2436579 C66.9987801,73.1578579 66.8451929,73.2339024 66.8532941,73.3639468 C66.9495015,74.9082576 66.3644245,75.3001465 66.0314023,74.4169688 C65.8984038,74.0642133 65.8208382,73.4540801 65.8208382,72.711058 C65.8208382,71.4740582 65.459417,70.2006807 64.6626122,69.1659253 C64.4554049,68.8968364 64.2129506,68.645392 63.9347794,68.4309698 C63.8340738,68.3533254 63.6880955,68.4313698 63.6973157,68.5576365 C63.7584999,69.3962141 63.7030671,71.7995248 61.5797236,74.6707244 C59.6544792,77.3340795 60.4005061,79.3795236 60.6652275,79.9335235 C61.1713513,80.9948344 61.8773198,81.618101 62.5667725,81.9821231 Z"
              id="Path-Copy-11"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M39.326497,65.0968453 C39.3516683,65.109308 39.3782206,65.0823198 39.3628097,65.0599825 C39.2791609,64.9387316 39.1999502,64.7347002 39.3261518,64.4719356 C39.5366084,64.0337513 39.6646711,63.8068023 39.6646711,63.8068023 C39.6646711,63.8068023 39.7327868,64.0716494 39.9174322,64.3071356 C40.0951426,64.5337748 40.1924128,64.8188139 40.0355998,65.0562688 C40.0208836,65.0785551 40.0468338,65.1048492 40.0718409,65.0929315 C40.2659518,65.0004531 40.4836676,64.8149512 40.5082326,64.4458062 C40.5172897,64.3330062 40.5037061,64.1750846 40.4357841,63.9758023 C40.3484511,63.7232298 40.2410879,63.6053749 40.1789682,63.5547632 C40.1603866,63.539622 40.1314889,63.5530416 40.1330132,63.5759906 C40.1511148,63.8485161 40.0410316,63.9176729 39.9783729,63.761818 C39.953349,63.6995671 39.9387549,63.5918965 39.9387549,63.4607749 C39.9387549,63.2424809 39.8707529,63.0177672 39.7208327,62.8351633 C39.6818463,62.787677 39.6362282,62.7433045 39.5838898,62.7054653 C39.5649419,62.6917633 39.5374758,62.7055359 39.5392106,62.7278182 C39.5507225,62.8758025 39.5402927,63.2999161 39.1407821,63.8065984 C38.7785442,64.2766023 38.9189104,64.637563 38.9687182,64.7353277 C39.0639462,64.9226178 39.1967753,65.0326061 39.326497,65.0968453 Z"
              id="Path-Copy-5"
              fill="#EA0D26"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
