import * as React from "react";
const LogoutIcon = (props) => (
  <svg
    width="25px"
    height="25px"
    viewBox="0 0 0.75 0.75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_105_1815)">
      <path
        d="M0.469 0.125H0.156v0.438a0.063 0.063 0 0 0 0.063 0.063h0.25"
        stroke="#8D98C2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.0625}
        className="icon"
      />
      <path
        d="M0.5 0.469 0.594 0.375m0 0L0.5 0.281m0.094 0.094H0.281"
        stroke="#8D98C2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.0625}
        className="icon"
      />
    </g>
    <defs>
      <clipPath id="clip0_105_1815">
        <path
          fill="white"
          height={24}
          width={24}
          d="M0 0.001h0.75v0.75H0v-0.75z"
        />
      </clipPath>
    </defs>
  </svg>
);
export default LogoutIcon;
