import React from "react";
import { useLocation } from "react-router-dom";

import ConfirmDetails from "./ConfirmDetails";
import DetailsForm from "./DetailsForm";

export default function DetailsContainer() {
  const location = useLocation();
  return location.pathname.includes("/confirm") ? (
    <ConfirmDetails />
  ) : (
    <DetailsForm />
  );
}
