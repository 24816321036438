import React from "react";
import { Box } from "@mui/material";

import { useStyles } from "./AgencySettingsPage.styles";
import BrandingCard from "./cards/BrandingCard";
import ContactInfoCard from "./cards/ContactInfoCard";
import AgentAvatarCard from "./cards/AgentAvatarCard";
import GeneralCard from "./cards/GeneralCard";

const GeneralSettingsPage = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.sectionWrapper}>
        <Box>
          <BrandingCard />
          <Box style={{ marginTop: 32 }}></Box>
          <GeneralCard />
        </Box>
        <Box>
          <ContactInfoCard />
          <Box style={{ marginTop: 32 }}></Box>
          <AgentAvatarCard />
        </Box>
      </Box>
    </>
  );
};

export default GeneralSettingsPage;
