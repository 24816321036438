import React from "react";
import Chart from "react-google-charts";
import { useSelector } from "react-redux";
import { adminSelectors } from "../../../state";
import { mobileTimeOptions, options } from "../../../state/admin/types";
import { useMobile } from "../../../themes";

export const TimelineStats = () => {
  const { appStats, daysOpen } = useSelector(adminSelectors.agentStats);

  const isMobile = useMobile();

  const minCloseTime = appStats.min_days_to_close || 0;
  const maxCloseTime = appStats.max_days_to_close || 0;
  const avgCloseTime = appStats.avg_days_to_close || 0;
  const minOpenTime = daysOpen.min_days_in_status || 0;
  const maxOpenTime = daysOpen.max_days_in_status || 0;
  const avgOpenTime = daysOpen.avg_days_in_status || 0;

  const data = [
    [
      "",
      "Minimum",
      {
        role: "annotation",
      },
      "Maximum",
      {
        role: "annotation",
      },
      "Average",
      {
        role: "annotation",
      },
    ],
    [
      "Time in status",
      +minOpenTime,
      minOpenTime,
      +maxOpenTime,
      maxOpenTime,
      +avgOpenTime,
      Math.round(+avgOpenTime * 10) / 10,
    ],
    [
      "Time to close",
      +minCloseTime,
      minCloseTime,
      +maxCloseTime,
      maxCloseTime,
      +avgCloseTime,
      Math.round(+avgCloseTime * 10) / 10,
    ],
  ];
  return !isMobile ? (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="500px"
      options={options(85, null)}
      data={data}
    />
  ) : (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="500px"
      options={mobileTimeOptions}
      data={data}
    />
  );
};
