import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { DATE_INTERVALS } from "../../../state/auto/types";
import { useStyles } from "../AgentStatsPage.styles";

export const DateSelectField = ({ startValue, setStartValue }) => {
  const classes = useStyles();
  return (
    <TextField
      className={classes.selectDateField}
      select
      fullWidth={false}
      variant="standard"
      value={startValue}
      onChange={(e) => setStartValue(e.target.value)}
    >
      {DATE_INTERVALS.map((type) => (
        <MenuItem key={type.value} value={type.value}>
          {type.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
