import { add } from "date-fns";
import { yup } from "../lib";
import sub from "date-fns/sub";

export const initialDetailsSchema = yup.object({
  propertyType: yup.string().when("isHome", {
    is: true,
    then: yup.string().required("This field is required"),
  }),
  own: yup.string().when("isHome", {
    is: true,
    then: yup.string().required("This field is required"),
  }),
  usageType: yup.string().required("This field is required"),
  yearPurchased: yup.number().when("own", {
    is: "Y",
    then: yup
      .number()
      .test(
        "yearPurchased",
        "Year purchased must be after year built",
        function () {
          const { manualYearOfConstruction, yearPurchased } = this.parent;
          if (manualYearOfConstruction > yearPurchased) {
            return false;
          }
          return true;
        },
      )
      .required("This field is required"),
  }),
  vacantUnoccupied: yup.string().when("usageType", {
    is: "Secondary",
    then: yup.string().when("isHome", {
      is: true,
      then: yup.string().required("This field is required"),
    }),
  }),
  manualYearOfConstruction: yup
    .number()
    .when("propertyLookupOn", {
      is: false,
      then: yup.number().required("This field is required"),
    })
    .when("isRenters", {
      is: true,
      then: yup.number().required("This field is required"),
    }),
  manualSquareFootage: yup
    .number()
    .max(10000, "This entry is invalid")
    .min(100, "This entry is invalid")
    .when("propertyLookupOn", {
      is: false,
      then: yup.number().required("This field is required"),
    })
    .when("isRenters", {
      is: true,
      then: yup.number().required("This field is required"),
    }),
  manualStoryType: yup
    .string()
    .when("propertyLookupOn", {
      is: false,
      then: yup.string().required("This field is required"),
    })
    .when("isRenters", {
      is: true,
      then: yup.string().required("This field is required"),
    }),
  manualExteriorWalls: yup.string().when("isRenters", {
    is: true,
    then: yup.string().required("This field is required"),
  }),
  NumberOfFullBaths: yup.number().when("isRenters", {
    is: true,
    then: yup.number().required("This field is required"),
  }),
  numberOfHalfBaths: yup.number().when("isRenters", {
    is: true,
    then: yup.number().required("This field is required"),
  }),
});

export const moreDetailsValidation = yup.object().shape({
  YearOfConstruction: yup
    .number()
    .max(new Date().getFullYear(), "This entry is invalid")
    .min(new Date().getFullYear() - 200, "This entry is invalid")
    .test(
      "YearOfConstruction",
      "Year built cannot be after purchase date",
      function () {
        const { yearPurchased, YearOfConstruction } = this.parent;
        if (YearOfConstruction > yearPurchased) {
          return false;
        }
        return true;
      },
    )
    .required("This field is required"),
  SquareFootage: yup
    .number()
    .max(10000, "This entry is invalid")
    .min(100, "This entry is invalid")
    .required("This field is required"),
  StoryType: yup.string().required("This field is required"),
  exteriorWalls: yup.string().required("This field is required"),
  foundation: yup.string().required("This field is required"),
  roofType: yup.string().required("This field is required"),
  roofBuilt: yup
    .string()
    .test("roofBuilt", "Roof built cannot be before Year built", function () {
      const { roofBuilt, YearOfConstruction } = this.parent;
      if (YearOfConstruction > roofBuilt) {
        return false;
      }
      return true;
    })
    .required("This field is required"),
  NumberOfFamilies: yup
    .number()
    .moreThan(0, "This field is required")
    .required("This field is required"),
  NumberOfFullBaths: yup.string().required("This field is required"),
  numberOfHalfBaths: yup.string().required("This field is required"),
  SwimmingPool: yup.string().required("This field is required"),
  fencedInPool: yup.string().required("This field is required"),
  poolType: yup.string().required("This field is required"),
  trampoline: yup.string().required("This field is required"),
});
const today = new Date();
today.setHours(0, 0, 0, 0);
export const insuredSchema = yup.object({
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
  gender: yup.string().required("This field is required"),
  maritalStatus: yup.string().required("This field is required"),
  dob: yup
    .date()
    .max(
      sub(new Date(), { years: 18 }),
      "Applicant must be at least 18 years of age",
    )
    .required("This field is required")
    .typeError("Invalid date of birth"),
  effectiveDate: yup
    .date()
    .min(today, "Date cannot be in the past")
    .max(add(new Date(), { days: 30 }), "Cannot be 30 days in advance")
    .required("This field is required")
    .typeError("Invalid date"),
  addCoapplicant: yup.boolean().required("This field is required"),
  coapplicant: yup.object().when("addCoapplicant", {
    is: true,
    then: yup.object().shape({
      firstName: yup.string().required("This field is required"),
      lastName: yup.string().required("This field is required"),
      gender: yup.string().required("This field is required"),
      dob: yup
        .date()
        .required("This field is required")
        .max(
          sub(new Date(), { years: 18 }),
          "Co-Applicant must be at least 18 years of age",
        )
        .required("This field is required")
        .typeError("Invalid date of birth"),
    }),
  }),
});

export const homeDiscountsSchema = yup.object().shape({
  constructionLevel: yup.string().when("mode", {
    is: "home",
    then: yup.string().required("This field is required"),
  }),
  mortgageOrLoan: yup.boolean().when("mode", {
    is: "home",
    then: yup.boolean().required("This field is required"),
  }),
  updatedDwellingAmount: yup.number().when("mode", {
    is: "home",
    then: yup
      .number()
      .moreThan(0, "This field is required")
      .required("This field is required")
      .typeError("Invalid dwelling amount"),
  }),
  hasClaims: yup.boolean().required("This field is required"),
  claims: yup.array().when("hasClaims", {
    is: true,
    then: yup.array().of(
      yup.object().shape({
        amountOfLoss: yup.number().required("This field is required"),
        description: yup.string().required("This field is required"),
        dateOfLoss: yup.date().required("This field is required"),
        // validate date to be within 5 years
      }),
    ),
  }),
  policyCanceledLastFive: yup.boolean().required("This field is required"),
});
