import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  info: {
    marginLeft: "auto",
    color: "#AFB6D4",

    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
  },
  box: {
    width: 950,
    height: 475,
    borderRadius: 3,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 32px 0px rgba(0,0,0,0.08)",
    padding: 30,
    paddingBottom: 12,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      height: "auto",
    },
  },
  titleWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 22,
    fontWeight: 600,
    marginBottom: 28,
  },
  fieldName: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 6,
  },
  // explanation: {
  //   fontSize: 16,
  //   fontWeight: 400,
  //   marginBottom: 28,
  //   marginRight: 36,
  // },
  closeBtn: {
    marginRight: 0,
    paddingRight: 0,
    color: "#a3a8bf",
    width: 24,
    height: 24,
    "& .MuiSvgIcon-root": {
      width: 24,
      height: 24,
    },
  },
  fieldNamesWrapper: {
    display: "flex",
    "& p:first-child": {
      flex: 1.7,
    },
    "& p:nth-child(2)": {
      flex: 1.7,
    },
    "& p:nth-child(3)": {
      flex: 1,
    },
    "& p:nth-child(4)": {
      flex: 1,
    },
    paddingBottom: 7,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  activityRow: {
    display: "flex",
    "& p:first-child": {
      flex: 1.7,
    },
    "& p:nth-child(2)": {
      flex: 1.7,
    },
    "& p:nth-child(3)": {
      flex: 1,
    },
    "& p:nth-child(4)": {
      flex: 1,
    },
    padding: "15px 0",
    borderBottom: "1px solid #DADFF6",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  historyWrapper: {
    height: 350,
    overflow: "auto",
    paddingBottom: 20,
    "& div:first-child": {
      borderTop: "1px solid #DADFF6",
      [theme.breakpoints.down('sm')]: {
        borderTop: "none",
      },
    },
  },
}));
