import * as React from "react";
const PersonalLink = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="22.9166667px"
    height="16.6666667px"
    viewBox="0 0 22.9166667 16.6666667"
    {...props}
  >
    <g
      id="\u21B3-settings"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Agency-settings---profile-dropdown"
        transform="translate(-1123, -116)"
        fill="#8D98C2"
        className="personalIcon"
      >
        <g id="Personal-link-icon" transform="translate(1123, 116)">
          <path
            d="M8.33333333,8.33333333 C10.6344792,8.33333333 12.5,6.4678125 12.5,4.16666667 C12.5,1.86547917 10.6344792,0 8.33333333,0 C6.03214583,0 4.16666667,1.86547917 4.16666667,4.16666667 C4.16666667,6.4678125 6.03214583,8.33333333 8.33333333,8.33333333 Z"
            id="PersonSearchFilled-Copy-2"
          />
          <path
            d="M8.75262055,10.0057164 C5.89098532,9.89451524 0,11.4068512 0,14.4426432 L0,16.6666667 L10,16.6666667 C7.41090147,13.5975143 8.71069182,10.1169176 8.75262055,10.0057164 Z"
            id="Path-Copy-4"
          />
          <g id="instagram-copy-4" transform="translate(10.4167, 9.375)">
            <path
              d="M1.1875,3.12500001 C1.1875,2.05625001 2.05625001,1.1875 3.12500001,1.1875 L5.62500002,1.1875 L5.62500002,-3.55271368e-15 L3.12500001,-3.55271368e-15 C1.40000001,-3.55271368e-15 -1.77635684e-15,1.40000001 -1.77635684e-15,3.12500001 C-1.77635684e-15,4.85000002 1.40000001,6.25000003 3.12500001,6.25000003 L5.62500002,6.25000003 L5.62500002,5.06250002 L3.12500001,5.06250002 C2.05625001,5.06250002 1.1875,4.19375002 1.1875,3.12500001 Z M3.75000002,3.75000002 L8.75000002,3.75000002 L8.75000002,2.50000001 L3.75000002,2.50000001 L3.75000002,3.75000002 Z M9.37500003,-3.55271368e-15 L6.87500003,-3.55271368e-15 L6.87500003,1.1875 L9.37500003,1.1875 C10.44375,1.1875 11.3125,2.05625001 11.3125,3.12500001 C11.3125,4.19375002 10.44375,5.06250002 9.37500003,5.06250002 L6.87500003,5.06250002 L6.87500003,6.25000003 L9.37500003,6.25000003 C11.1,6.25000003 12.5000001,4.85000002 12.5000001,3.12500001 C12.5000001,1.40000001 11.1,-3.55271368e-15 9.37500003,-3.55271368e-15 Z"
              id="InsertLinkFilled"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default PersonalLink;
