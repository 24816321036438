import React from "react";
import { Box } from "@mui/material";

import { useStyles } from "./AgencySettingsPage.styles";
import HomepageCard from "./cards/HomepageCard";
import WebsiteColorsCard from "./cards/WebsiteColorsCard";
import AboutCard from "./cards/AboutCard";

const DesignSettingsPage = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.sectionWrapper}>
        <Box>
          <HomepageCard />
          <Box style={{ marginTop: 32 }}></Box>
          <AboutCard />
        </Box>

        <WebsiteColorsCard />
      </Box>
    </>
  );
};

export default DesignSettingsPage;
