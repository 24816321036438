import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  Typography,
  Paper,
  Box,
} from "@mui/material";

import {
  adminActions,
  useDispatch,
  useSelector,
  adminSelectors,
} from "../../state";
import { useFormik } from "../../lib";
import { useStyles } from "./EditCoverageUIPage.styles";
import { useParams } from "react-router-dom";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

const initialValues = {
  id: -1,
  internal_name: "",
  title: "",
  description: "",
  faqs: {},
};

const EditCoverageUIPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const coverages_ui: any[] = useSelector(adminSelectors.coverage_ui);
  const [activeCoverageUI, setActiveCoverageUI] = useState(initialValues);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: activeCoverageUI,
    async onSubmit(values) {
      await dispatch(adminActions.editCoverageUI(values));
    },
  });

  useEffect(() => {
    dispatch(adminActions.setPageTitle("Add Coverage UI"));
  }, [dispatch]);

  useEffect(() => {
    if (!coverages_ui.length) {
      dispatch(adminActions.getCoverageUI());
    } else {
      const matchingCvgUI = coverages_ui.find(
        (r) => r.id === parseInt(params.id),
      );
      if (matchingCvgUI) {
        setActiveCoverageUI({
          id: matchingCvgUI.id,
          internal_name: matchingCvgUI.internal_name,
          title: matchingCvgUI.title,
          description: matchingCvgUI.description,
          faqs: matchingCvgUI.faqs,
        });
      }
    }
  }, [dispatch, coverages_ui, params]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item xs={4} className={classes.formControl}>
              <TextField
                id="internal_name"
                name="internal_name"
                label="Internal Name"
                variant="outlined"
                value={form.values.internal_name}
                onChange={form.handleChange}
                error={
                  form.touched.internal_name &&
                  Boolean(form.errors.internal_name)
                }
                helperText={
                  form.touched.internal_name && form.errors.internal_name
                }
              ></TextField>
            </Grid>
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                id="title"
                name="title"
                label="Coverage Title"
                variant="outlined"
                value={form.values.title}
                onChange={form.handleChange}
                error={form.touched.title && Boolean(form.errors.title)}
                helperText={form.touched.title && form.errors.title}
              ></TextField>
            </Grid>
            <Grid item xs={9} className={classes.formControl}>
              <TextField
                id="description"
                name="description"
                label="Description"
                multiline
                rows={3}
                variant="outlined"
                value={form.values.description}
                onChange={form.handleChange}
                error={
                  form.touched.description && Boolean(form.errors.description)
                }
                helperText={form.touched.description && form.errors.description}
              ></TextField>
            </Grid>
            <Grid item xs={10} className={classes.formControl}>
              <Typography style={{ color: "#b4b4d4" }}>FAQs</Typography>
              <Paper>
                <Box className={classes.jsonInput}>
                  <JSONInput
                    key={activeCoverageUI.id}
                    id="faqs"
                    name="faqs"
                    label="State Requirements"
                    placeholder={form.values.faqs}
                    onChange={(e) => {
                      form.setFieldValue("faqs", e.jsObject);
                    }}
                    colors={{
                      default: "#484c4f",
                      background: "#eff1f6",
                      background_warning: "#FEECEB",
                      string: "#FA7921",
                      number: "#70CE35",
                      colon: "#49B8F7",
                      keys: "#1277ab",
                    }}
                    height={"100%"}
                    width={"100%"}
                    locale={locale}
                    error={form.touched.faqs && Boolean(form.errors.faqs)}
                    helperText={form.touched.faqs && form.errors.faqs}
                  ></JSONInput>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  !(
                    form.values.internal_name &&
                    form.values.title &&
                    form.values.description
                  )
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default EditCoverageUIPage;
