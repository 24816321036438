import React, { useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
} from "../../state";
import { REPORT_COLUMNS as columns } from "../../constants/tables";

export const useStyles = makeStyles((theme) => ({
  page: {
    width: "96%",
    height: "100%",
    [theme.breakpoints.down('md')]: {
      width: "100%",
      paddingBottom: 48,
    },
  },
}));

export default function UsersPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reports = useSelector(adminSelectors.reports);
  const [pageSize, setPageSize] = React.useState(25);
  useEffect(() => {
    dispatch(adminActions.getReports());
    dispatch(adminActions.setPageTitle("Reports"));
  }, [dispatch]);

  return (
    <div className={classes.page}>
      <DataGridPro
        autoHeight
        rows={reports}
        columns={columns}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
}
