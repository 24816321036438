import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import {
  Card,
  Typography,
  Box,
  Button,
  TextField,
  MenuItem,
  Switch,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, yup } from "../../../../lib";

import { useStyles } from "../AgencySettingsPage.styles";
import { EditIcon } from "../../../../components";
import { Agency } from "../../../../state/admin/state";

const validationSchema = yup.object({});

const GeneralCard = () => {
  const [inEditMode, setInEditMode] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const activeAgencyId = params.id
    ? Number(params.id)
    : useSelector(authSelectors.activeAgencyId);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const agencyData: Agency = useSelector(adminSelectors.activeAgencyData);
  const agency_groups = useSelector(adminSelectors.agency_groups);

  useEffect(() => {
    dispatch(adminActions.getAgency(activeAgencyId));
    if (isSuperAdmin) {
      dispatch(adminActions.getAgencyGroups());
    }
  }, []);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      group_id: agencyData.group_id,
      show_in_directory: agencyData.show_in_directory,
      restrict_agent_viewing: agencyData.restrict_agent_viewing,
      google_doc_url: agencyData.google_doc_url || "",
    },
    validationSchema,
    async onSubmit(values) {
      const updatedAgencyData = {
        ...agencyData,
        group_id: values.group_id,
        show_in_directory: values.show_in_directory,
        restrict_agent_viewing: values.restrict_agent_viewing,
      };
      dispatch(
        adminActions.updateAgency(
          activeAgencyId,
          updatedAgencyData,
          isSuperAdmin,
        ),
      );
      setInEditMode(false);
      form.resetForm();
    },
  });

  const handleCancel = () => {
    setInEditMode(false);
    form.resetForm();
  };

  const showEditButton = Boolean(!inEditMode && isSuperAdmin);

  if (!activeAgencyId) return null;

  const SectionTitle = ({ title }) => {
    return (
      <Box className={classes.sectionTitleWrapper}>
        <Typography className={classes.sectionTitle}>{title}</Typography>
        {showEditButton && (
          <Button
            className={classes.editBtnWrapper}
            onClick={() => setInEditMode(!inEditMode)}
          >
            <EditIcon style={{ color: "white" }} />
          </Button>
        )}
      </Box>
    );
  };

  const Field = ({ fieldName, fieldValue }) => {
    return (
      <Box className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle}>{fieldName}</Typography>
        <Typography className={classes.field}>{fieldValue}</Typography>
      </Box>
    );
  };

  return (
    <>
      {inEditMode ? (
        <Card variant="outlined" className={classes.section}>
          <form onSubmit={form.handleSubmit}>
            <SectionTitle title={"General settings"} />

            {isSuperAdmin && (
              <Box className={classes.textFieldWrapper}>
                <TextField
                  select
                  id="group_id"
                  name="group_id"
                  label="Agency Group"
                  variant="outlined"
                  value={form.values.group_id}
                  onChange={form.handleChange}
                >
                  {agency_groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}

            {isSuperAdmin && (
              <Box className={classes.textFieldWrapper}>
                <Typography className={classes.medText}>
                  Display agency in directory?
                </Typography>
                <Switch
                  checked={form.values.show_in_directory}
                  onChange={form.handleChange}
                  color="primary"
                  name="show_in_directory"
                />
              </Box>
            )}

            {isSuperAdmin && (
              <Box className={classes.textFieldWrapper}>
                <Typography className={classes.medText}>
                  Restrict agents on this agency?
                </Typography>
                <Typography className={classes.smallText}>
                  When enabled, agents will only be able to view their own
                  applications and quote data.
                </Typography>
                <Switch
                  checked={form.values.restrict_agent_viewing}
                  onChange={form.handleChange}
                  color="primary"
                  name="restrict_agent_viewing"
                />
              </Box>
            )}

            <Box className={classes.submit}>
              <Button
                variant="contained"
                onClick={() => handleCancel()}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitBtn}
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      ) : (
        <Card variant="outlined" className={classes.section}>
          <SectionTitle title={"General settings"} />
          {isSuperAdmin && (
            <>
              <Field
                fieldName={"Agency Group"}
                fieldValue={
                  agency_groups.find((a) => a.id === agencyData.group_id)
                    ?.name || ""
                }
              />
              <Field
                fieldName={"Display agency in directory"}
                fieldValue={
                  agencyData.show_in_directory ? "Enabled" : "Disabled"
                }
              />
              <Field
                fieldName={"Restrict agents on this agency"}
                fieldValue={
                  agencyData.restrict_agent_viewing ? "Enabled" : "Disabled"
                }
              />
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default GeneralCard;
