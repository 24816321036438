import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import { AddIcon } from "../../components/icons";

import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
  authSelectors,
} from "../../state";
import AdminPages from ".";
import { USERS_COLUMNS as columns } from "../../constants/tables";

import { useStyles } from "./UsersPage.styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function UsersPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const users = useSelector(adminSelectors.users);
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const [pageSize, setPageSize] = React.useState(25);

  useEffect(() => {
    if (!activeRole || !agencyId) {
      console.warn("active role and active agency id is not defined");
      return;
    }
    dispatch(adminActions.getUsers(activeRole, agencyId));
    dispatch(adminActions.setPageTitle("Users"));
  }, [dispatch, agencyId, activeRole]);

  return (
    <div className={classes.page}>
      <div className={classes.topArea}>
        <Link className={classes.link} to={AdminPages.createUsers.path}>
          <Button variant="outlined" color="primary">
            <AddIcon /> Add User
          </Button>
        </Link>
      </div>

      <DataGridPro
        rows={users}
        columns={columns}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        getRowId={(row: any) => row.email + row.rolesAsString}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
