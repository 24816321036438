import React from "react";

export const Med_PipImage = ({ fillColor }) => {
  return (
    <svg
      width="80px"
      height="83px"
      viewBox="0 0 80 83"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 6</title>
      <g
        id="↳-auto-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Create-a-custom-quote-side-bar"
          transform="translate(-562.000000, -680.000000)"
        >
          <g id="Group-6" transform="translate(562.000000, 680.000000)">
            <g
              id="Group-5"
              transform="translate(19.500000, 41.500000) scale(-1, 1) translate(-19.500000, -41.500000) translate(5.000000, 0.000000)"
              fillRule="nonzero"
            >
              <path
                d="M28.2049822,74.4298372 C27.1778681,76.5302256 25.2850093,78.0926705 23.3007591,79.3628595 C21.1320428,80.7498938 18.7954571,81.8638932 16.3455736,82.6788397 C16.0097037,82.7928516 15.6705698,82.8988345 15.3298413,83 L1.55138665,83 C1.45521816,82.8972287 1.3639066,82.78964 1.27753185,82.6788397 C0.354705611,81.5146336 -0.144203195,80.022844 0.0367487851,78.5615646 C0.269922124,76.6843826 1.71768959,74.9597517 3.5926369,74.5615129 C5.46925536,74.1616684 7.60826535,75.264854 8.17890149,77.0713807 C8.49200302,73.590003 8.85390372,69.9641031 10.7321151,66.9997935 C12.4342411,64.3164991 15.3803964,62.3959605 18.5726311,62.052319 C20.4502751,61.8597171 22.3446224,62.2013308 24.0311734,63.0366753 C24.1860913,63.1137538 24.3393397,63.1940439 24.4893225,63.2775456 C25.5014848,63.8427757 26.3946589,64.5932981 27.1207915,65.4887343 C29.1114866,67.9712986 29.599012,71.5795395 28.2049822,74.4298372 Z"
                id="Path"
                fill="#F2F2F2"
              ></path>
              <path
                d="M24.8953003,63.4881702 C18.3473202,68.6302565 13.1731397,75.2109705 9.80648308,82.6786854 C9.75816424,82.7847192 9.70983207,82.8923596 9.66313441,83 L9,83 C9.04669766,82.8923596 9.09331534,82.7847192 9.14164085,82.6786854 C9.78313009,81.2327696 10.491251,79.8125591 11.2693359,78.4292997 C12.9250851,75.487795 14.8730945,72.708238 17.0859511,70.1297432 C19.2973611,67.5480619 21.7674562,65.1829843 24.4603674,63.0688547 C24.4817371,63.0520984 24.5052516,63.0380597 24.5303331,63.0270838 C24.6251553,62.9873273 24.7336189,62.9915209 24.8248354,63.0384704 C24.9160519,63.0854199 24.9800249,63.1699801 24.998536,63.2680697 C25.0082961,63.3540952 24.9687409,63.4384275 24.8953003,63.4881702 Z"
                id="Path"
                fill="#FFFFFF"
              ></path>
              <path
                d="M8.99147169,77.5299439 L8.99147169,3.59812822 C8.99147169,2.59978162 9.01066039,1.59930207 8.99147169,0.601077118 C8.99063291,0.557433719 8.99147169,0.513702732 8.99147169,0.470047979 C8.99147169,-0.15616432 8.00852831,-0.157200784 8.00852831,0.470047979 L8.00852831,74.4018718 C8.00852831,75.4002184 7.98933961,76.4006979 8.00852831,77.3989229 C8.00936709,77.4425663 8.00852831,77.4862973 8.00852831,77.529952 C8.00852831,78.1561643 8.99147169,78.1572008 8.99147169,77.529952 L8.99147169,77.5299439 Z"
                id="Path"
                fill="#F2F2F2"
              ></path>
              <path
                d="M14.9294118,22 L3.07058824,22 C2.479602,21.9993394 2.00068345,21.5364188 2,20.9651741 L2,7.03482587 C2.00068345,6.46358118 2.479602,6.00066062 3.07058824,6 L14.9294118,6 C15.520398,6.00066062 15.9993166,6.46358118 16,7.03482587 L16,20.9651741 C15.9993166,21.5364188 15.520398,21.9993394 14.9294118,22 L14.9294118,22 Z"
                id="Path"
                fill="#F2F2F2"
              ></path>
            </g>
            <path
              d="M80,82 C80,82.013275 79.9832045,82.0260197 79.952924,82.0354065 C79.9226436,82.0447934 79.8815314,82.0500463 79.8387087,82.05 L0.161290321,82.05 C0.0722121362,82.05 0,82.0276141 0,82 C0,81.9723859 0.0722121362,81.95 0.161290321,81.95 L79.8387087,81.95 C79.8815315,81.9499536 79.922644,81.9552064 79.9529245,81.9645933 C79.983205,81.9739802 80,81.986725 80,82 Z"
              id="Path"
              fill="#3F3D56"
              fillRule="nonzero"
            ></path>
            <g id="Group-3" transform="translate(34.000000, 20.000000)">
              <path
                d="M24.7318979,14.0530299 C27.002729,16.5378391 30.1872933,20.4162099 32.5378799,20.9492372 C34.7609833,19.5530238 35.7651479,16.734741 37.4739232,14.6503653 C37.634062,14.4547893 37.8613963,14.3288252 38.1044305,14.2956767 C39.6430191,14.0881673 38.8222292,16.1267981 38.4002163,17.3698656 C33.865462,30.7406318 27.9591659,23.6654227 20.8194869,17.7802437 C18.2403101,14.6013056 22.3624715,11.2420398 24.7318979,14.0530299"
                id="Fill-5"
                fill="#FFB8B8"
              ></path>
              <path
                d="M24.3840537,13.0355506 C26.4531091,15.2146679 29.5943176,17.9941471 31.926406,19.4136944 C32.4075977,19.7068617 30.3965339,25.2826527 29.7372253,24.9887839 C26.7575647,23.6618158 22.9743069,20.4397802 19.9677217,17.2955952 C16.9307598,14.1198491 21.779267,10.0624968 24.3840537,13.0355506"
                id="Fill-6"
                fill={fillColor}
              ></path>
              <path
                d="M42.8221723,13.3258911 L42.8215902,13.3251395 C42.6725709,13.1026484 42.4507882,12.9831346 42.2237666,13.0019261 L40.7981872,13.3567091 C40.540896,13.3785072 40.2905901,13.4679547 40.06124,13.6182864 L38.7113345,14.4097833 C38.4365801,14.57139 38.272426,14.8495038 38,15.1110811 L39.1380187,17 L40.399444,16.6422103 C41.5729713,16.2558577 42.8349787,14.538317 42.8349787,14.538317 C43.0596719,14.1842856 43.0544329,13.6724059 42.8221723,13.3258911"
                id="Fill-7"
                fill="#FFB8B8"
              ></path>
              <path
                d="M40.4197038,14.128909 C40.5262517,14.0156532 40.6183128,13.8933089 40.689812,13.7702656 L40.9370218,13.3452067 C41.0001094,13.2361456 41.0169327,13.1340755 40.9823514,13.0690583 C40.9281428,12.9676873 40.7636479,12.9788731 40.5926105,13.0963236 L40.1388473,13.4053302 C39.8921049,13.5738157 39.6705974,13.8066193 39.5247951,14.0506087 L39,14.9286908 L39.6392873,15 L40.4197038,14.128909"
                id="Fill-8"
                fill="#FFB8B8"
              ></path>
              <path
                d="M43.1525258,49.7306291 C40.8611045,56.6311196 34.4526185,61 27.5893368,61 C25.8709323,61 24.123457,60.7249946 22.4050525,60.1489785 C22.270035,60.1021691 22.1350175,60.0560098 22,60.0033492 C22.9057155,59.6717823 23.777838,59.2608995 24.6105536,58.7739514 C31.7141536,60.2913572 38.9947622,56.2169862 41.3514312,49.1247069 C43.8864009,41.4954195 39.7816105,33.2140507 32.2012502,30.6635868 C29.6727407,29.8119151 27.0725234,29.7059438 24.6338102,30.2227979 C23.8107849,29.7423511 22.9386623,29.3314683 22.0167964,28.997301 C23.7939885,28.3445694 25.6868175,28 27.6190535,28 C29.337458,28 31.0849333,28.2750054 32.8033378,28.8516716 C41.3746878,31.7369531 46.0195481,41.1046908 43.1525258,49.7306291"
                id="Fill-9"
                fill="#002679"
              ></path>
              <path
                d="M40.3205611,48.4630689 C38.5374887,53.5676777 33.5526757,57 27.9149716,57 C27.6093209,57 27.3030101,56.9905307 27,56.9678042 C27.3399786,56.710238 27.6693948,56.4368896 27.985608,56.1509153 C33.2093963,56.121876 37.821223,52.9307063 39.4795266,48.1928769 C40.5087046,45.2466477 40.2763309,42.0908303 38.820034,39.3024235 C37.3670379,36.5165419 34.8710006,34.4389673 31.790068,33.4509989 C30.5456801,33.0558115 29.2603628,32.8531675 27.985608,32.8468546 C27.6726956,32.5634055 27.3498809,32.2932135 27.0105624,32.0331222 C28.7025338,31.9138084 30.4143097,32.1164524 32.0693126,32.6473671 C35.3760174,33.7054087 38.0562374,35.9344927 39.6174985,38.9223871 C41.1747987,41.9140691 41.426977,45.3009386 40.3205611,48.4630689"
                id="Fill-10"
                fill="#002679"
              ></path>
              <path
                d="M26.3863727,58 C26.3841002,58 26.3818276,58 26.3795551,58 C26.1666933,57.9974389 25.9970099,57.8488968 26.00004,57.6689815 L26.228052,45.3214145 C26.2310821,45.1408589 26.4098557,45.0083235 26.6196874,45 C26.8333067,45.0032013 27.0029901,45.1517435 26.99996,45.3316588 L26.771948,57.6792258 C26.7681604,57.8572203 26.5962044,58 26.3863727,58"
                id="Fill-11"
                fill="#CDD2E9"
              ></path>
              <path
                d="M7.76934364,20.6961355 L7.00700325,16.5870137 C6.96915279,16.3816469 7.08856834,16.1786612 7.27248963,16.1363973 L7.826919,16.0078198 C8.01084028,15.965556 8.19262914,16.0988956 8.23047961,16.3042624 L8.99281999,20.4127889 C9.03120357,20.618751 8.91178801,20.8217367 8.72786673,20.8640006 L8.17343736,20.9919828 C7.98951607,21.034842 7.80772721,20.9015023 7.76934364,20.6961355"
                id="Fill-12"
                fill="#8D98C2"
              ></path>
              <path
                d="M20.7693436,20.6961355 L20.0070033,16.5870137 C19.9691528,16.3816469 20.0885683,16.1786612 20.2724896,16.1363973 L20.826919,16.0078198 C21.0108403,15.965556 21.1926291,16.0988956 21.2304796,16.3042624 L21.99282,20.4127889 C22.0312036,20.618751 21.911788,20.8217367 21.7278667,20.8640006 L21.1734374,20.9919828 C20.9895161,21.034842 20.8077272,20.9015023 20.7693436,20.6961355"
                id="Fill-13"
                fill="#525CD8"
              ></path>
              <path
                d="M11.6583874,44 L7,18 L21.5879726,18 C22.6028998,18 23.4720115,18.7281724 23.6512297,19.7284445 C24.6924936,25.5377422 28,44 28,44 L11.6583874,44"
                id="Fill-14"
                fill="#8D98C2"
              ></path>
              <path
                d="M11.3031886,42.9066326 L11.050696,42.9574807 C9.90436681,43.1859835 8.77173875,42.470343 8.53425222,41.36675 L4.04419096,20.5146178 C3.80670443,19.4110248 4.55048092,18.3212424 5.69746257,18.0927397 L5.94930268,18.0425193 C7.09563189,17.8140165 8.22825996,18.529657 8.46574649,19.63325 L12.9558077,40.4853822 C13.1932943,41.5889752 12.4495178,42.6781298 11.3031886,42.9066326"
                id="Fill-15"
                fill="#CDD2E9"
              ></path>
              <path
                d="M35.9680341,45 L19.0319659,45 C18.4644843,45 18,44.5973502 18,44.1054147 L18,41.8945853 C18,41.4026498 18.4644843,41 19.0319659,41 L35.9680341,41 C36.5355157,41 37,41.4026498 37,41.8945853 L37,44.1054147 C37,44.5973502 36.5355157,45 35.9680341,45"
                id="Fill-16"
                fill="#525CD8"
              ></path>
              <path
                d="M26.967998,45 L10.0313374,45 C9.46383604,45 9,44.5973502 9,44.1054147 L9,41.8945853 C9,41.4026498 9.46383604,41 10.0313374,41 L26.967998,41 C27.5354994,41 28,41.4026498 28,41.8945853 L28,44.1054147 C28,44.5973502 27.5354994,45 26.967998,45"
                id="Fill-17"
                fill="#CDD2E9"
              ></path>
              <path
                d="M21.3075279,17 L7.69247207,17 C7.31051062,17 7,16.7759036 7,16.499759 C7,16.2236145 7.31051062,16 7.69247207,16 L21.3075279,16 C21.6901571,16 22,16.2236145 22,16.499759 C22,16.7759036 21.6901571,17 21.3075279,17"
                id="Fill-18"
                fill="#8D98C2"
              ></path>
              <path
                d="M39.3857991,59 L26.6142009,59 C26.2765479,59 26,58.7748718 26,58.5 C26,58.2251282 26.2765479,58 26.6142009,58 L39.3857991,58 C39.7234521,58 40,58.2251282 40,58.5 C40,58.7748718 39.7234521,59 39.3857991,59"
                id="Fill-19"
                fill="#CDD2E9"
              ></path>
              <path
                d="M35.9390783,49.6932938 C35.682969,51.6109488 36.5065508,57.7386514 33.539267,56.9262692 C32.8082729,56.7260484 32.3938686,56.0083652 32.3154678,55.3197681 C32.1810665,54.1407653 32.0862388,52.9522927 32.0175448,51.7577323 C31.6703412,45.6868492 36.5991384,43.5209474 35.9390783,49.6932938"
                id="Fill-20"
                fill="#FFB8B8"
              ></path>
              <path
                d="M38.2173256,56.6480633 C31.1697771,58.2418642 32.6142228,53.8996641 34.7688226,54.0017756 C35.7298808,54.2878109 40.9486874,55.2363408 38.2173256,56.6480633"
                id="Fill-21"
                fill="#FFB8B8"
              ></path>
              <path
                d="M35.6441581,54.1212497 C35.3777041,53.8541329 35.1375288,54.0050798 34.256886,54.80791 C33.3102409,55.672155 32.0323616,54.4934374 32.0293059,54.8937426 C32.0268614,55.1719584 31.9431361,57.9866739 32.0702518,57.9881537 L38.7658259,58 C38.8929416,58.0014726 38.9974454,57.7772721 38.9998899,57.4997962 C39.0053901,56.8160956 38.8043273,56.212308 38.5067055,56.0147452 L35.6441581,54.1212497"
                id="Fill-22"
                fill="#2F2E41"
              ></path>
              <path
                d="M19.2133368,34.268403 C22.3095196,32.6426 33.3602944,32.0721087 36.0786293,35.6936577 C36.50307,36.2589569 36.744331,36.9443253 36.8183687,37.6517605 C37.3908849,43.1139392 36.430947,52.8661607 36.3869073,53.4126382 C36.3249964,54.1674521 32.8509657,54.2200229 32.7692688,53.4230225 C32.1980292,47.8466158 32.4609909,45.4069379 31.6363633,39.9428122 C31.5974297,39.6838519 21.5104223,42.6855839 17.0911335,40.3043181 C14.885957,38.1885027 16.2403375,35.6417359 19.2133368,34.268403"
                id="Fill-23"
                fill="#2F2E41"
              ></path>
              <path
                d="M30.9460633,49.8382365 C30.7070277,51.7787986 30.5913428,57.8742854 28.4358412,56.8940791 C27.7884242,56.5997434 27.3661047,55.9652105 27.2929306,55.2683878 C27.1674892,54.0753015 27.0789833,52.8726322 27.0155657,51.6638023 C26.6908118,45.5204004 31.56212,43.5921593 30.9460633,49.8382365"
                id="Fill-24"
                fill="#FFB8B8"
              ></path>
              <path
                d="M33.2176199,56.6480633 C26.1698222,58.2418642 27.6141888,53.8996641 29.7686706,54.0017756 C30.7303113,54.2878109 35.9481969,55.2363408 33.2176199,56.6480633"
                id="Fill-25"
                fill="#FFB8B8"
              ></path>
              <path
                d="M32.6441579,54.1212497 C32.3777039,53.8541329 32.1375286,54.0050798 31.257497,54.80791 C30.3102408,55.672155 29.0323616,54.4934374 29.0293059,54.8937426 C29.0268614,55.1719584 28.9431361,57.9866739 29.0702518,57.9881537 L35.7658256,58 C35.8929413,58.0014726 35.997445,57.7772721 35.9998896,57.4997962 C36.0053898,56.8160956 35.8049381,56.212308 35.5067052,56.0147452 L32.6441579,54.1212497"
                id="Fill-26"
                fill="#3A376D"
              ></path>
              <path
                d="M14.8303504,34.1958458 C17.9497467,32.5687207 29.3761751,32.1711955 32.1143262,35.7950402 C32.5418609,36.3614486 32.7848806,37.0467248 32.8594581,37.7547353 C33.4355049,43.2220055 32.0738229,46.5139296 31.7767987,51.9824989 C31.7472249,52.3605376 31.732438,52.8652386 31.6874343,53.4121605 C31.625715,54.1675882 28.1257164,54.2202019 28.043424,53.4225533 C27.4680201,47.8416117 28.0209221,47.2953394 27.7586151,42.0177381 C27.745114,41.7559691 18.9096104,42.7004161 15.705993,40.7582588 C13.4024487,39.3623739 13.7714787,34.8934635 14.8303504,34.1958458"
                id="Fill-27"
                fill="#2F2E41"
              ></path>
              <path
                d="M12.6197251,21.2917593 C12.6900662,21.8082809 14.0601041,28.0353788 13.900062,29.0844433 C13.8394009,29.6118593 13.2360163,34.0939343 13.2360163,34.0939343 C12.4900135,37.5705223 24.6493424,35.7120697 25.9245167,33.1172854 C26.3678592,32.2149746 24.6977422,25.4854537 25.065581,24.7843685 C25.4501983,24.0506001 26.3381739,22.908613 25.7012321,20.5567092 C25.3024175,19.0840458 25.5941072,17.0916564 24.6570864,15.5247886 C23.2625258,13.1933919 18.752952,12.7454407 16.6220687,13.1152087 C10.9599335,14.0976252 11.7304588,16.4982333 12.6197251,21.2917593"
                id="Fill-28"
                fill={fillColor}
              ></path>
              <path
                d="M20.1488601,15.9945842 L20.1488601,15.9945842 C18.8752005,16.0936029 17.843816,14.8203309 18.0195842,13.3648157 L18.3969844,10.2399535 L21.4689689,10 L21.9704097,13.3247223 C22.1715138,14.659957 21.323815,15.9022477 20.1488601,15.9945842"
                id="Fill-29"
                fill="#FFB8B8"
              ></path>
              <path
                d="M22.0862195,2.53672032 C22.0862195,2.53672032 18.6933297,11.8585743 23.9004949,9.66607922 C26.1873491,8.7035814 24.4956174,1.72172216 22.5562846,2.00859651 C21.9605336,2.09672131 22.0862195,2.53672032 22.0862195,2.53672032"
                id="Fill-30"
                fill="#000644"
              ></path>
              <path
                d="M24.9151176,7.18620407 C25.1816127,10.2517462 24.9359471,12.727198 22.4792915,12.9793335 C20.0232485,13.2308038 16.2855787,11.1631595 16.0196963,8.09761741 C15.7532012,5.03207532 18.2368127,2.26989417 20.6934683,2.01842393 C23.1495112,1.76695368 24.6492352,4.12066199 24.9151176,7.18620407"
                id="Fill-31"
                fill="#FFB8B8"
              ></path>
              <path
                d="M24,2.72820876 C24,2.72820876 19.868322,2.84038036 19.382827,4.96394908 C18.680137,8.03488712 17.501889,7.48236184 17.5572525,8.87200777 C17.589193,9.67195156 17.6289411,10.3667745 17.4294907,10.7571317 C17.117184,11.3705501 13.8386736,10.8718672 13.0870081,8.68804634 C12.8045125,7.86823216 13.2708432,6.1260469 13.6165099,4.48513657 C14.0885189,2.24683233 15.6528915,0.367477223 18.3493758,0.083522819 C21.5902675,-0.256837878 24.0028366,0.407859001 24,2.72820876"
                id="Fill-32"
                fill="#000644"
              ></path>
              <path
                d="M18.9931636,6.96992303 C19.0269411,7.52701815 18.9515046,7.9729919 18.4014937,7.99878793 C17.8514828,8.02508004 17.0351922,7.62077682 17.0014147,7.0636817 C16.9676372,6.50708266 17.5446701,6.02687956 18.0946811,6.00108353 C18.644692,5.97528749 18.9593861,6.41332399 18.9931636,6.96992303"
                id="Fill-33"
                fill="#FFB8B8"
              ></path>
              <path
                d="M15.103742,43.365914 C15.4539211,42.3186495 16.5868214,41.753563 17.634086,42.103742 C18.6813505,42.4539211 19.246437,43.5868214 18.896258,44.634086 C18.5460789,45.6813505 17.413724,46.246437 16.365914,45.896258 C15.3186495,45.5460789 14.753563,44.4131786 15.103742,43.365914"
                id="Fill-34"
                fill="#002679"
              ></path>
              <path
                d="M2.66452653,39.8738643 C0.114122222,47.5029633 4.24560817,55.7847778 11.8747172,58.3351788 C19.503176,60.8862299 27.7850013,56.7534491 30.3354056,49.1250002 C32.8858099,41.4965512 28.754324,33.2147368 21.125215,30.6643358 C13.4954559,28.1126346 5.21558095,32.2454154 2.66452653,39.8738643 Z M16.4872888,61 C14.7566805,61 12.9981173,60.7256515 11.2688092,60.1476993 C2.64112236,57.2624891 -2.03191006,47.8962829 0.852653778,39.2686073 C3.73721761,30.6402815 13.103436,25.9685554 21.731773,28.8524654 C30.3594599,31.7370255 35.0318422,41.1032317 32.1472783,49.7309073 C29.8413176,56.6292771 23.3902184,61 16.4872888,61 L16.4872888,61 Z"
                id="Fill-35"
                fill="#002679"
              ></path>
              <path
                d="M16.4975693,32.8494619 C14.7170155,32.8494619 12.9459293,33.2635649 11.3029723,34.0829334 C8.51568916,35.4735873 6.43848154,37.8622546 5.45384346,40.8083198 C4.46920539,43.754385 4.69264249,46.9119204 6.08312818,49.6995407 C7.47361387,52.4865297 9.86136119,54.5639885 12.8070701,55.5487457 C14.0006282,55.9483299 15.2402622,56.1509626 16.4906264,56.1509626 C21.5122805,56.1509626 25.9551442,52.9536581 27.5463446,48.1946297 C28.5309827,45.2485646 28.3069144,42.0916604 26.9164287,39.3046714 C25.525943,36.5170511 23.1381957,34.4395922 20.1924868,33.4548351 C18.9831493,33.0502009 17.7384658,32.8494619 16.4975693,32.8494619 Z M16.4906264,57 C15.1481102,57 13.8182176,56.7822172 12.5381882,56.3535953 C9.37787865,55.2968751 6.81529495,53.0685464 5.32382074,50.0782934 C3.83171536,47.0880404 3.5924988,43.7000971 4.64909119,40.5387741 C5.70568359,37.3780824 7.93437399,34.81582 10.9236342,33.3235341 C13.9141567,31.8318795 17.3010592,31.5920028 20.4619999,32.6499855 C23.6223094,33.7060744 26.1848931,35.9350344 27.6763673,38.9252873 C29.1678415,41.9155403 29.4076893,45.3028525 28.3510969,48.4641754 C26.6443909,53.5691313 21.8783639,57 16.4906264,57 L16.4906264,57 Z"
                id="Fill-36"
                fill="#002679"
              ></path>
              <path
                d="M16.0857606,18.6749886 C13.8230848,21.0459073 10.2774291,24.3864612 9.8690652,26.7605259 C11.3452157,29.0238471 14.2327071,29.8997279 16.3047292,31.6672201 C16.984287,33.7228971 14.7964884,32.8916913 13.5059578,32.4889875 C0.639034379,28.4732755 7.10364251,22.282963 12.389092,14.9046743 C15.2960892,12.1977497 18.6529789,16.1926972 16.0857606,18.6749886"
                id="Fill-37"
                fill="#FFB8B8"
              ></path>
              <path
                d="M16.1041884,18.2263519 C14.1245408,20.3532836 11.6202632,23.561048 10.3704488,25.9161327 C10.1121184,26.4024053 4.75690919,24.6439238 5.00859871,23.9826204 C6.14551812,20.9921122 9.04293599,17.1339413 11.8985163,14.0439992 C14.7819883,10.9227287 18.8136692,15.5395938 16.1041884,18.2263519"
                id="Fill-38"
                fill={fillColor}
              ></path>
              <path
                d="M18.9833989,34.3472192 L18.9833989,34.3465662 C19.0343222,34.0958186 18.9666393,33.8352762 18.8003326,33.6432976 L17.5975099,32.5626016 C17.4099314,32.3451564 17.180454,32.1688495 16.9226142,32.0447817 L15.4741991,31.2625014 C15.1789725,31.1031722 14.8792337,31.1044782 14.518258,31 L14,33.0118577 L15.0938854,33.9449783 C16.1478056,34.7847215 18.1673359,34.9995548 18.1673359,34.9995548 C18.559897,35.0126145 18.9041131,34.7370534 18.9833989,34.3472192"
                id="Fill-39"
                fill="#FFB8B8"
              ></path>
              <path
                d="M17.7606684,31.8966192 C17.9311793,31.9400631 18.0959426,31.9657107 18.2447405,31.973562 L18.7588275,31.9992096 C18.8903827,32.0054907 18.9778733,31.9740855 18.9963932,31.9138921 L18.9963932,31.9133687 C19.025131,31.819153 18.8795262,31.6783529 18.652817,31.5794264 L18.0512394,31.3171928 C17.7236286,31.1748224 17.3711117,31.0842707 17.0728774,31.065951 L16,31 L16.4796017,31.6003633 L17.7606684,31.8966192"
                id="Fill-40"
                fill="#FFB8B8"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
