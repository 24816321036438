import React from "react";

export const WizardSide2 = ({ fillColor, fillContrast }) => {
  return (
    <svg
      height="353"
      viewBox="0 0 403 353"
      width="403"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" transform="translate(.957474 1)">
        <g stroke="#dadff6" strokeWidth=".8">
          <g transform="matrix(.94551858 -.32556815 .32556815 .94551858 0 87.095814)">
            <path
              d="m35.0820554 5.92515771c-3.3037454.77781083-7.4069973 2.78715548-9.4156746 8.30205029-3.9644946 10.8029283 5.2067029 18.1921312 2.2002945 29.4109721-.8217136 2.9828122-2.3971602 5.7922513-4.6252437 8.2480357-3.6803725 4.0781054-7.684512 6.0118296-11.5565017 8.8692041-4.92156747 3.6494859-8.44825826 8.3838078-10.15571362 13.6332954-1.87122066 5.7940046-2.02750473 11.8690116-.45591688 17.7222038 1.38096562 4.8937265 3.7332324 8.4532909 5.7815546 12.4233679 2.55031406 5.065818 4.5405112 10.308174 5.9467419 15.664245.7812391 2.603134 1.2241923 5.266405 1.3214982 7.945554.0660749 3.365112-.5748517 7.021904-1.2025634 10.630082l-5.59654484 31.928054c-.74664648 4.250952-1.49990045 8.572123-1.52633041 12.61782-.03964495 4.672267.8787963 8.685554 1.79063005 12.601616 1.98224729 8.44789 4.0768219 17.214466 8.5897383 21.670674 4.5129163 4.456208 12.1643909 2.911389 16.8755319-6.184677 4.4798789-8.642342 4.5723838-20.201475 8.5236634-29.448782 6.2242565-14.583953 18.6066946-16.52848 25.4718777-30.426447 6.6074909-13.330814 4.5723837-29.708053 7.9289891-44.89697 2.3919118-10.894753 7.4862873-20.833447 10.5059107-31.598565 4.3411216-15.5076035 3.9116346-31.6039665.4427019-43.5195963-3.4689328-11.9156299-9.6931893-20.098848-16.5649799-26.30513026-2.9139035-2.63051302-6.0260318-4.96934698-9.6865818-5.20160994-3.8918121-.24306588-7.26824 3.78102488-11.1336222 4.96394552-4.4798789 1.36657042-8.9465428-.09722635-13.4594592.95065769z"
              fillRule="nonzero"
            />
            <path d="m51.4113286 30.2635955s-4.6462675 119.3362945-24.1280672 189.7095545" />
            <path d="m29.8526473 42.4973421s20.805986 19.0775391 16.8798899 55.4281748" />
            <path d="m12.3733889 96.7802101s34.196529 6.9185879 31.6596669 27.4967129" />
            <path d="m72.3892264 56.5916279s-13.8319384 12.1869994-26.1631323 46.7051441" />
          </g>
          <g transform="translate(132.59097)">
            <path
              d="m57.9830786 5.70968119c-6.3959682.72364101-14.0885851 2.98501918-17.2491659 9.96910711-6.2027178 13.6920498 12.661025 24.056303 8.3160056 38.3624954-1.3028825 4.2847166-4.5133344 7.7696194-7.8983351 10.3118845-6.632856 4.9797928-14.1820935 7.1411942-21.3510637 10.5213595-7.1689703 3.3801653-14.61223168 8.6836922-17.97229689 16.8294144-3.23538747 7.8362705-1.83276285 16.4961589 1.52730236 22.9660809s8.44691723 11.292608 12.99142093 16.615178c5.5340013 6.55253 10.1615334 13.526454 13.8080602 20.80944 1.6270446 3.204016 3.1169436 6.584181 3.646824 10.421383.5859854 4.375172-.1558471 9.083599-.8914458 13.69205l-6.5580494 40.942847c-.8727442 5.451111-1.7517223 10.997439-1.2467774 16.248597.5672837 6.060494 2.9112253 11.344977 5.2239975 16.50568 4.9871097 11.130742 10.3233172 22.694716 19.7177852 28.859947 9.3944681 6.165231 24.156313 4.817925 32.0982853-6.588942 7.5617052-10.859376 6.1715483-25.83208 12.6173877-37.496031 10.1612358-18.381434 34.0681938-19.866802 45.5697158-37.31512 11.040214-16.738959 4.862432-38.15302 9.294726-57.605633 3.179282-13.939612 11.775813-26.398615 16.208106-40.1144689 6.358565-19.7620648 3.322662-40.6857639-5.093085-56.44876-8.415748-15.7629961-21.68146-26.9175415-35.944594-35.55838647-6.046871-3.66105224-12.467775-6.96980557-19.624277-7.57918748-7.6302781-.64746828-13.714552 4.25615176-21.064305 5.48919797-8.5404255 1.46156442-17.4548842-.81885694-26.1262214.16186707z"
              fillRule="nonzero"
            />
            <path d="m102.652908 19.2s5.54264 166.765571-34.0944162 263.2" />
            <path d="m49.3812913 33.6s56.4813767 29.2510619 51.6444137 80" />
            <path d="m11.8259403 108s89.7362927 13.666153 86.1952969 42.4" />
            <path d="m161.248294 58.4s-33.969052 15.6863795-60.727801 63.2" />
            <path d="m124.491993 202.958177s-14.743877 9.314411-29.5648505-27.758177" />
          </g>
        </g>
        <g transform="matrix(-1 0 0 1 402.042527 209.519573)">
          <g fillRule="nonzero">
            <path
              d="m83.7554245 31.9042003s46.8281125-30.00096046 79.2024145-30.00096046c0 0 38.976166-6.08749964 81.371084 4.20117574 42.394919 10.28867542 58.054879 23.62911042 69.808835 19.71228502l-49.380195 1.4497861z"
              fill="#000"
            />
            <path
              d="m304.476589 77.8207683h-285.5935769l7.8519463-31.5468649 48.5654457-12.9479516 229.1761849 6.0394405z"
              fill="#000"
            />
            <path
              d="m319.509511 58.1084833v10.068404s-8.478983 6.1675983-9.2498 10.9975487c0 0-12.141361 3.091809-15.60804 3.091809-.315515 0-.658988 0-1.018436.0360444.541546-1.4572588.964938-2.955963 1.266056-4.4815211 2.326124-11.7101287-2.612504-23.6721386-12.512381-30.3066406-9.899876-6.634502-22.81073-6.634502-32.710607 0-9.899876 6.634502-14.838504 18.5965119-12.51238 30.3066406.408678 2.0637893 1.036635 4.0777445 1.873124 6.007401h-16.462728s-.151767.0520641-.399386.1601973c-.882645.4004934-2.963451 1.457796-3.262991 3.1198436h-136.4345555l-2.7957082-.0240296c.5942072-1.0001111 1.1276925-2.0352651 1.5975475-3.0998189.8766265-1.9718197 1.527781-4.0368347 1.9410203-6.1555835 2.2151757-11.036886-2.0183446-22.3768364-10.9167413-29.2416936s-20.9144194-8.0609621-30.9850432-3.0843286c-10.0706239 4.9766336-16.4447722 15.2606702-16.4366584 26.5188679-.0000913 1.9456475.1898586 3.8866266.5671294 5.7951395.6436398 3.2314084 1.8235773 6.331572 3.4906414 9.1712988-1.949008-.4765872-6.2024784-1.8302549-6.2024784-1.8302549s-18.11219542-1.9303781-22.7370956-6.0074009l.69892706-.484597 2.96345071-2.0305016-1.34992768-5.2064141c-.16294497-.2195461-.29716671-.4591227-.39938689-.7128783-.3594482-.8690706-.67496384-2.483059.59508646-4.5095556 1.92504481-3.0918091.96252241-14.1053775.96252241-14.1053775s26.20776763-18.1623756 77.85248633-22.2233786c0 0 1.2341055.9611841 3.7821938 1.1614308 1.7253514.1401727 4.0537769-.0720888 7.0132338-1.1614308 0 0 4.2374949 1.9303781 22.365666-8.5024749 0 0 30.784757-23.19857349 71.25463-21.26018544 0 0 43.620617-1.89233784 79.078185 11.83657584 15.280542 5.9112825 24.962084 10.3807889 33.249362 12.0468414 7.652253 1.5418996 12.456877 1.8262499 14.657499 1.8502795h.047927 1.298007.063902s0 4.6016691.243626 10.0363645v.0280346c.087865 1.8903288.207681 3.880781.375424 5.8151641v.120148c.463288 5.3866362 1.262062 10.2806655 2.647935 11.2498596z"
              fill="#dadff6"
            />
            <path
              d="m7.63627732 51.6605396s11.61017688-9.0831903 26.06398838-7.1488072c0 0-13.1518103 10.436858-19.0787117 9.7600241l-1.0104488-.8690707-5.99080335.3364145s-.89462664-1.4017269.01597547-2.0785607z"
              fill="#f9fafc"
            />
            <path
              d="m18.9069753 67.3759005v5.3626066s-.3993869 5.1223105-5.0123054 5.3145474l-13.17976737.6528042 2.95945685-2.0305015-1.34992768-5.2064142c-.16366943-.2176295-.29795946-.4559814-.39938689-.7088733 1.19816066-1.1053618 4.02182597-2.971661 9.76500939-3.3721544z"
              fill="#fff"
              opacity=".2"
            />
            <path
              d="m80.236826 44.900211 10.1164699-.4004934s-4.0937156 3.2359867-6.9852766 3.5283468c-2.8915611.2923602-3.1311933-3.1278534-3.1311933-3.1278534z"
              fill="#fff"
              opacity=".2"
            />
            <path
              d="m101.532135 75.6741237s91.487555-12.7557147 113.601607-8.6987166c0 0 6.502018.4365378 8.962242 3.0437498 2.460223 2.607212 0 .9171299 0 .9171299z"
              fill="#fff"
              opacity=".2"
            />
            <path
              d="m152.745516 39.8740189s1.158222-2.1746792 3.03534-2.2227384l12.189288-.6287746s-.191706 1.6900821-2.360377 2.3188568z"
              fill="#18218a"
              opacity=".5"
            />
            <path
              d="m223.27724 36.2014944s1.158222-2.1746791 3.035341-2.2227383l12.189288-.6447944s-.191706 1.6900821-2.360377 2.3188568z"
              fill="#18218a"
              opacity=".5"
            />
            <path
              d="m181.657133 6.40478563-9.353641 24.59830447-33.724229.0360444h-8.682671-7.552406-6.529976-7.396645s4.505084-3.3160853 11.761944-7.5412907c2.396321-1.4017269 5.116146-2.8995722 8.067615-4.4054273 2.715831-1.3937171 5.639343-2.8034538 8.710628-4.1411018 2.396321-1.0573025 4.920447-2.0745558 7.500486-3.0237251 11.698042-4.28928431 24.845858-7.16883183 37.198895-5.52280397z"
              fill="#000"
            />
            <path
              d="m234.647785 24.3669145s-14.090369 2.0945805-27.537726 3.6444899c-5.140109.5927303-10.184366 1.1053618-14.314026 1.3897121l-.575117.0400494c-1.597548.1041282-3.079273.1722121-4.337342.1962417-1.996934.0360444-3.490641-.0520641-4.237495-.2923602l3.886035-23.52898706s4.792642.05206414 11.88176.40049339l1.549621.08410362c1.473738.08410361 3.027353.18022203 4.648863.29236018 2.316444.16019736 4.756698.35243419 7.272836.58472036 12.149349 1.11737658 25.912221 3.12384851 34.626843 6.73629891 18.543533 7.6974831-12.864252 10.4528777-12.864252 10.4528777z"
              fill="#000"
            />
            <path
              d="m299.588093 36.2014944v6.2797365s-10.11647.1441776-7.947799-1.882319c1.403531-1.3456806 2.677852-2.8206402 3.806157-4.4054274z"
              fill="#f9fafc"
            />
            <path
              d="m314.744826 41.8404415-8.215389.6447943v-7.5493005s3.806158-5.3626066 7.612315.5807154l.219662.3484293z"
              fill="#f9fafc"
            />
            <ellipse
              cx="54.344574"
              cy="72.021624"
              fill="#000"
              rx="23.511906"
              ry="23.577046"
            />
            <path
              d="m73.2036527 72.0015992c.0176902 10.1037099-7.8629649 18.4493474-17.9247466 18.9823291s-18.7754047-6.9336418-19.81932-16.9829931c-1.0439152-10.0493514 5.9480426-19.1565055 15.903356-20.7144147s19.3820421 4.979881 21.4412939 14.8703421c.2630232 1.2646474.3968434 2.5528808.3994167 3.8447366z"
              fill="#dadff6"
            />
            <ellipse
              cx="54.316617"
              cy="71.997594"
              fill="#000"
              opacity=".5"
              rx="16.211114"
              ry="16.296076"
            />
            <path
              d="m59.7402909 53.8071842v.6888486l-5.3957169 17.9140697-4.3293539-18.8992834c3.2122443-.7508337 6.5642223-.6486844 9.7250708.2963651z"
              fill="#dadff6"
            />
            <path
              d="m59.7003522 90.2040242c-3.0905101.9177594-6.3632494 1.0307826-9.5094018.3284045l4.1496297-18.1223262z"
              fill="#dadff6"
            />
            <path
              d="m73.2036229 72.0015992c.0027261 1.9762992-.3018016 3.9409448-.9026143 5.823174h-.0758836l-17.8645755-5.4106658 18.4756375-4.2532399c.2513159 1.2645445.3744212 2.5513389.3674359 3.8407317z"
              fill="#dadff6"
            />
            <path
              d="m54.344574 72.4101025-17.8645755 5.4106658h-.1397855c-.9949488-3.1278951-1.1723489-6.4595694-.515209-9.6759205z"
              fill="#dadff6"
            />
            <ellipse
              cx="54.316617"
              cy="71.997594"
              fill="#000"
              rx="2.072818"
              ry="2.078561"
            />
            <ellipse
              cx="265.887828"
              cy="72.021624"
              fill="#000"
              rx="23.511906"
              ry="23.577046"
            />
            <path
              d="m284.7509 72.0015992c.01769 10.1037099-7.862965 18.4493474-17.924746 18.9823291-10.061782.5329817-18.775405-6.9336418-19.81932-16.9829931-1.043916-10.0493514 5.948042-19.1565055 15.903356-20.7144147 9.955313-1.5579092 19.382042 4.979881 21.441294 14.8703421.263023 1.2646474.396843 2.5528808.399416 3.8447366z"
              fill="#dadff6"
            />
            <ellipse
              cx="265.863864"
              cy="71.997594"
              fill="#000"
              opacity=".5"
              rx="16.211114"
              ry="16.296076"
            />
            <path
              d="m271.283544 53.8071842v.6888486l-5.395716 17.9140697-4.329354-18.8992834c3.212244-.7508337 6.564222-.6486844 9.72507.2963651z"
              fill="#dadff6"
            />
            <path
              d="m271.2476 90.2040242c-3.09051.9177594-6.36325 1.0307826-9.509402.3284045l4.14963-18.1223262z"
              fill="#dadff6"
            />
            <path
              d="m284.750889 72.0015992c.002707 1.9762992-.30182 3.9409448-.902633 5.823174h-.091859l-17.868569-5.4146707 18.475637-4.2532399c.258289 1.2651704.388087 2.5533269.387424 3.8447366z"
              fill="#dadff6"
            />
            <path
              d="m265.887828 72.4101025-17.864576 5.4106658h-.139785c-.994949-3.1278951-1.172349-6.4595694-.515209-9.6759205z"
              fill="#dadff6"
            />
            <ellipse
              cx="265.863864"
              cy="71.997594"
              fill="#000"
              rx="2.072818"
              ry="2.078561"
            />
            <path
              d="m128.251118 19.1164461-5.906932 11.9387082h-6.529976l4.365299-7.5332809c2.408303-1.4017268 5.12014-2.8955672 8.071609-4.4054273z"
              fill="#fff"
              opacity=".2"
            />
            <path
              d="m144.458238 11.9516192-5.882969 19.0915203h-8.682671l7.069148-16.075805c2.412297-1.0492927 4.920446-2.0585361 7.496492-3.0157153z"
              fill="#fff"
              opacity=".2"
            />
            <path
              d="m205.620346 6.6130422-12.816325 22.7920793-.575117.0400493c-1.597548.1041283-3.079273.1722122-4.337342.1962418l11.5303-23.40082926 1.549621.08410361c1.453768.08009868 3.019365.1762171 4.648863.28835525z"
              fill="#fff"
              opacity=".2"
            />
          </g>
          <path
            d="m108.641222 30.9750556-7.316768 50.5382618 61.110188-1.3416529 10.032598-48.9763376z"
            opacity=".2"
            stroke="#fff"
            strokeWidth=".5"
          />
          <path
            d="m222.178926 83.9723469c-.882645.4004934-2.96345 1.457796-3.26299 3.1198436h-136.4385495l-2.7957082-.0240297c.5942072-1.000111 1.1276925-2.0352651 1.5975475-3.0998188z"
            fill="#afb6d4"
            fillRule="nonzero"
            opacity=".5"
          />
          <path
            d="m104.220009 120.49334c.930571-.869071 1.805228-1.894334 2.076812-3.139868.271583-1.245535-.259602-2.74338-1.449775-3.203948-1.329958-.512631-2.755769.400494-3.834114 1.349663-1.0783446.94917-2.3164439 2.002467-3.7262797 1.80222 1.4770306-1.343352 2.1426354-3.365464 1.7533085-5.326562-.0618926-.398003-.2302893-.771716-.487252-1.081332-.7388658-.800987-2.0808057-.452557-2.9634507.172212-2.7957083 2.002467-3.594482 5.819169-3.6144514 9.271422-.2835647-1.245534-.0439325-2.543133-.0519203-3.820707-.0079877-1.277574-.3554543-2.691315-1.429805-3.380164-.6691218-.360621-1.4214158-.537472-2.1806525-.512631-1.2660564-.04806-2.6758921.080098-3.5385678 1.009243-1.0743507 1.153421-.7987738 3.087804.1397854 4.357368s2.3563827 2.066546 3.6623778 2.943627c1.0484493.626691 1.9425908 1.482244 2.6159841 2.503083.0798332.142676.145386.292926.1956996.448553h7.9318236c1.7785564-.903969 3.4269954-2.045042 4.9004774-3.392179z"
            fill="#eff1fb"
            fillRule="nonzero"
          />
          <path
            d="m296.370163 121.629639c.731984-.598395 1.26542-1.405653 1.529652-2.314852.199694-.921135-.191706-2.022492-1.070357-2.358906-.982492-.376464-2.032879.304375-2.827659.997228-.79478.692854-1.709376 1.477821-2.751776 1.329638 1.089819-.990628 1.581087-2.482198 1.294014-3.92884-.045059-.294773-.169321-.571676-.359448-.800987-.54716-.58472-1.533646-.332409-2.18864.128158-2.076812 1.465806-2.655923 4.29329-2.667905 6.840428-.207681-.91713-.031951-1.87431-.039939-2.803454-.007987-.929145-.263595-2.002467-1.054381-2.491069-.490371-.264212-1.041072-.395366-1.597548-.380469-.934565-.036044-1.972971.060074-2.61199.744918-.798774.849046-.587099 2.278807.103841 3.203947.690939.92514 1.737333 1.521875 2.703849 2.170674.774624.461799 1.435371 1.09289 1.933033 1.846275.058532.104484.10669.21448.143779.328404h5.851018c1.310946-.669866 2.525454-1.514563 3.610457-2.511093z"
            fill="#eff1fb"
            fillRule="nonzero"
          />
          <path
            d="m309.545511 139.969334c.731984-.598395 1.265419-1.405653 1.529652-2.314852.199693-.921135-.191706-2.022492-1.070357-2.358906-.982492-.376464-2.03288.304375-2.82766.997228-.794779.692854-1.709375 1.477821-2.751775 1.329638 1.089818-.990628 1.581086-2.482199 1.294013-3.92884-.045058-.294773-.16932-.571676-.359448-.800987-.54716-.58472-1.533646-.332409-2.18864.128158-2.076812 1.465806-2.655923 4.29329-2.667904 6.840428-.207682-.91713-.031951-1.87431-.039939-2.803454-.007988-.929145-.263595-2.002467-1.054382-2.491069-.49037-.264212-1.041071-.395366-1.597547-.380469-.934565-.036044-1.972971.060074-2.61199.744918-.798774.849046-.587099 2.278807.10384 3.203947.69094.92514 1.737333 1.521875 2.70385 2.170674.774623.461799 1.43537 1.09289 1.933032 1.846275.058533.104484.10669.21448.143779.328404h5.851018c1.310947-.669866 2.525455-1.514563 3.610458-2.511093z"
            fill="#eff1fb"
            fillRule="nonzero"
          />
          <path
            d="m232.922434 116.53246c-3.462685 1.117377-18.922951.248306-19.294381-2.002467-.295546-1.790205-4.485115-13.056085-6.138576-17.461512l-.662983-1.762171 8.039659-1.7381413s.295546 1.4137417.70292 3.4282235c.74286 3.6765298 1.865137 9.3515208 2.264524 12.0748758.61905 4.217196 1.996935 3.472278 1.996935 3.472278s16.542605 2.871538 13.091902 3.988914z"
            fill="#ffbdc6"
            fillRule="nonzero"
          />
          <path
            d="m189.245484 43.3462966s-.61905 3.7205837 4.700783 7.2088812c5.319834 3.4882975.74286 15.9997112.74286 15.9997112l-3.933961.2202714c-5.192195.2918565-9.684646-3.5866727-10.16839-8.7788153s2.224585-3.6044406-1.857149-12.8998923z"
            fill="#ffbdc6"
            fillRule="nonzero"
          />
          <path
            d="m166.256774 38.7686571s-7.656246 7.4852216-11.214784 12.6435766c-.421817.5982597-.80726 1.2214914-1.154228 1.8662992-2.596015 4.9621132-8.634744-3.1038238-6.545951-5.8912579l1.441787-1.3496627 13.882688-12.9759861s9.525377-7.4411674 14.345977-2.1065953-10.755489 7.8136262-10.755489 7.8136262z"
            fill="#ffbdc6"
            fillRule="nonzero"
          />
          <ellipse
            cx="183.262668"
            cy="36.846289"
            fill="#a66477"
            fillRule="nonzero"
            rx="11.130913"
            ry="11.161751"
          />
          <path
            d="m137.93625 109.459747s-8.287278.496612-9.525377 0c-1.2381-.496612-9.149954-1.734137-6.661774 3.972894 2.488181 5.707031 11.873773 8.061932 11.873773 8.061932l6.789577-1.738141z"
            fill={fillContrast}
            fillRule="nonzero"
          />
          <path
            d="m137.93625 109.459747s-8.287278.496612-9.525377 0c-1.2381-.496612-9.149954-1.734137-6.661774 3.972894 2.488181 5.707031 11.873773 8.061932 11.873773 8.061932l6.789577-1.738141z"
            fill="#000"
            fillRule="nonzero"
            opacity=".05"
          />
          <path
            d="m167.494874 85.0296495 16.634464 6.7763482 9.461659 20.1528283s.123626 7.068708-6.058883 8.682696c-6.182509 1.613989-14.841217.869071-17.069796-.744917-2.228579-1.613989-11.378532-11.161751-11.378532-11.161751z"
            fill="#222"
            fillRule="nonzero"
          />
          <path
            d="m166.364609 85.0296495 14.397897 5.4106658 8.191587 16.1278687s.107673 5.658972-5.244112 6.948561c-5.351784 1.289589-12.848276.692853-14.777315-.596735-1.929038-1.289589-9.84888-8.935008-9.84888-8.935008z"
            fill="#000"
            fillRule="nonzero"
            opacity=".2"
          />
          <path
            d="m152.034607 109.459747s-4.329354 1.489835-6.058699 0c-1.729345-1.489836-4.824594.372459-4.824594.372459l1.365904 8.061932s6.182509-1.365683 9.525377-.993224z"
            fill="#ffbdc6"
            fillRule="nonzero"
          />
          <path
            d="m152.034607 109.459747s-4.329354 1.489835-6.058699 0c-1.729345-1.489836-4.824594.372459-4.824594.372459l1.365904 8.061932s6.182509-1.365683 9.525377-.993224z"
            fill="#000"
            fillRule="nonzero"
            opacity=".05"
          />
          <path
            d="m154.498824 100.781055-3.949936-1.3656827s-4.329354 3.8447367-7.188964.6207647c-2.85961-3.2239718 2.596015-16.6204759 2.596015-16.6204759l5.811079-9.4276146 4.205544 13.2723512 3.834114 6.4479437z"
            fill={fillColor}
            fillRule="nonzero"
          />
          <path
            d="m192.228904 100.036137 8.906327 1.237525 1.733339 8.186085-11.007102-.620765z"
            fill={fillColor}
            fillRule="nonzero"
          />
          <path
            d="m179.740076 55.1408272s3.710304 14.2615699 15.576088 3.9688896l3.215065 40.9264202s-3.957924 6.323791-4.576974 12.014802-11.378532-17.6217095-17.808661-17.7338477c-6.430129-.1121381-12.80035-9.1152297-12.80035-9.1152297l7.23689-14.9464136z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <path
            d="m179.863886 54.7683683s3.710304 14.2615699 15.576088 3.9688896l3.215065 40.9264203s-3.957924 6.3237908-4.576974 12.0148018-11.378533-17.6217094-17.808661-17.7338475c-6.430129-.1121382-12.80035-9.1152297-12.80035-9.1152297l7.23689-14.9464136z"
            fill="#f2f1f2"
            fillRule="nonzero"
          />
          <path
            d="m177.882927 45.5890597s-5.19203-.8690707-8.163468-.3724589c-2.971439.4966118-3.338875 5.9513319-3.338875 5.9513319s-8.411088 2.9756659-11.254722 2.002467c0 0-13.978541-.9932237-13.978541 3.2239718s4.457157 6.1756082 4.457157 6.1756082 19.406209.6568092 19.542001.6207648c4.453164-1.2415296-.12381 0-.12381 0l-8.039658 12.1549746 7.420608 10.2926803 7.915848-5.8271789s.99048-5.458725 3.594482-8.1860851c1.853155-1.9504028 4.277434-10.7292181 5.511539-15.587203.524166-2.0667223.2005-4.2578858-.89862-6.0834947z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <path
            d="m155.04199 51.4122337c-.421817.5982597-.80726 1.2214914-1.154228 1.8662992-2.596015 4.9621132-8.634744-3.1038238-6.545951-5.8912579l1.441787-1.3496627c-.527191 6.3998845 4.668833 5.7190457 6.258392 5.3746214z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <path
            d="m165.022669 62.7061475h-.139786s4.59295-1.2415296.139786 0z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <path
            d="m177.511497 45.5890597s-5.19203-.8690707-8.163468-.3724589c-2.971439.4966118-3.338875 5.9513319-3.338875 5.9513319s-8.411088 2.9756659-11.254722 2.002467l.49524-1.3656825s-7.540425 2.3549012-6.677749-5.5948928c0 0-7.792038 5.9513319-7.792038 10.1685274s4.453163 6.1916279 4.453163 6.1916279 19.406209.6568092 19.542001.6207648c4.453164-1.2415296-.12381 0-.12381 0l-8.039658 12.1549746 7.420608 10.2926803 7.915848-5.8271789s.99048-5.458725 3.594482-8.1860851c1.853156-1.9504028 4.277434-10.7292181 5.511539-15.587203.524167-2.0667223.2005-4.2578858-.89862-6.0834947z"
            fill={fillColor}
            fillRule="nonzero"
          />
          <path
            d="m202.99238 85.8987201v.6808388c-.052697-.2238702-.052697-.4569685 0-.6808388z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <path
            d="m215.569073 97.0124119c-2.628828.8118828-5.436777.8369363-8.079596.0720888l-.662983-1.7621709 8.039659-1.7381414s.291552 1.4257565.70292 3.4282235z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <path
            d="m191.490038 48.6888786s6.925369 4.3413484 9.401567 4.9621132c2.476199.6207647 8.906328 2.8515129 8.906328 2.8515129l2.596015 8.8108548s.990479 6.6962496 0 10.4128283c-.99048 3.7165788-10.264243 10.7892922-9.769004 9.6719156l-.24762 9.7960685s0 14.2615701 1.996935 17.2212161c1.996934 2.959646-3.774206-2.915592-3.774206-2.915592s-6.989271-10.3567592-7.851946-16.9288559c-.862676-6.5720966.491245-31.8512399.491245-31.8512399l-1.238099-1.7381413s2.580039-6.6962496-.511215-10.2926803z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <path
            d="m191.861468 48.6888786s6.925368 4.3413484 9.401567 4.9621132c2.476199.6207647 8.906328 2.8515129 8.906328 2.8515129l2.596014 8.8108548s.99048 6.6962496 0 10.4128283c-.990479 3.7165788 3.338875 20.5893656 3.338875 20.5893656s-3.993869 1.8502795-9.525377 0c-.37143-.124153-4.081734-9.7960685-3.594482-10.9134451l-.24762 9.7960685s0 14.2615702 1.996934 17.2212162c1.273273 1.87133 2.815629 3.54347 4.576974 4.962113l-3.957924.496612s-11.390514-18.7110516-12.257184-25.2711335c-.866669-6.5600818.49524-31.8752695.49524-31.8752695l-1.238099-1.7381413s2.600008-6.7082644-.491246-10.3046951z"
            fill={fillColor}
            fillRule="nonzero"
          />
          <path
            d="m158.093306 108.226227s-8.287278-12.0148019-7.915848-17.6217094c0 0-.990479 7.6894732-2.100775 8.186085s-12.245202-2.9796709-12.245202-2.9796709-.49524-3.6044406.74286-4.8379602c1.238099-1.2335197 5.072213-24.1857963 5.072213-24.1857963s1.361909-6.1996378 7.296799-6.5720967c5.934889-.3724588 19.29438 29.8888223 19.29438 29.8888223s1.976965 18.1223262-10.144427 18.1223262z"
            fill="#222"
            fillRule="nonzero"
          />
          <path
            d="m138.5553 91.850052s.247619 17.361389-2.843635 20.216907c-3.091255 2.855518 9.030138 4.341348 9.030138 4.341348s-2.100776-4.217195-.37143-7.56532c1.729345-3.348125 2.843634-16.992935 2.843634-16.992935z"
            fill="#ffbdc6"
            fillRule="nonzero"
          />
          <path
            d="m139.170355 111.830668s-1.238099-3.099819-4.576973-.620765c0 0-2.967445 6.944555-8.163468 7.937779-5.196024.993224-.74286 3.96889-.49524 4.093043.24762.124152 8.658708 1.489835 10.635673.86907s10.016623-2.106595 10.016623-2.106595-.37143-8.186085-1.597548-8.810855c-1.226117-.62477-.623043 3.712574-5.819067-1.361677z"
            fill="#000"
            fillRule="nonzero"
            opacity=".05"
          />
          <path
            d="m139.170355 112.066959s-1.238099-3.099819-4.576973-.620765c0 0-2.967445 6.944555-8.163468 7.937779-5.196024.993224-.74286 3.96889-.49524 4.093043s8.658708 1.489835 10.635673.86907c1.976965-.620764 10.020617-2.10259 10.020617-2.10259s-.37143-8.186085-1.597548-8.810855c-1.226117-.624769-.627037 3.716579-5.823061-1.365682z"
            fill={fillContrast}
            fillRule="nonzero"
          />
          <path
            d="m29.517007 124.080427h239.715006v1h-239.715006z"
            fill="#000"
          />
          <path
            d="m162.458605 34.1349485c1.044103 1.6269343 2.169132 3.20019 3.370825 4.7138073.231454.2714691.342298.6259541.306857.9813418-.03544.3553877-.214091.6808429-.494569.9009771l-.483258.3804688c-.278967.2190866-.634736.3151016-.985679.2660147-.350942-.0490868-.666931-.2390615-.875464-.5263354-1.082346-1.5041715-2.437192-2.7907649-3.993869-3.7926725-.36075-.225338-.589589-.6126127-.613429-1.0381345-.023839-.4255219.160302-.836062.493613-1.1005003l1.377885-1.089342c.286586-.226653.654682-.3227043 1.01509-.2648792.360409.0578252.680242.2642499.881998.5692542z"
            fill="#565388"
            fillRule="nonzero"
          />
          <path
            d="m190.679283 47.751724c-1.597548-2.2547778-1.294014-4.0409784-1.294014-4.0409784l-10.523845 1.7541611c.730965 1.6359934 1.331914 3.3273309 1.797241 5.0582316 3.594071 1.053084 7.473031-.0197249 10.020618-2.7714143z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <ellipse
            cx="183.32657"
            cy="40.626947"
            fill="#ffbdc6"
            fillRule="nonzero"
            rx="9.892813"
            ry="9.920221"
          />
          <path
            d="m178.973253 35.4565767c.934565-.1561924 1.76529-.7048684 2.711837-.7529276 1.19816-.060074 2.280499.7088733 3.123205 1.5018502.842707.792977 1.66145 1.6980921 2.819672 2.0425164.740199.1739572 1.503784.2253647 2.260529.1521875.420789.0213199.838249-.0847296 1.198161-.304375.181112-.1684139.31825-.3788164.399387-.6127549.652991-1.5085135.639935-3.2234758-.035945-4.7218172-.093621-.153438-.133012-.3340103-.111828-.5126315.036117-.1060979.094703-.2031014.171736-.2843503.798774-.9451645 1.64148-1.8903289 1.964984-3.03574.323503-1.1454111-.07189-2.5551479-1.250081-3.0637745-.594003-.2565576-1.267141-.2565576-1.861143 0-.551154.2523109-.978498.7208881-1.597548.8450411-1.002461.2082566-1.861143-.6007401-2.643941-1.2014802-1.219785-.9167063-2.677047-1.4617391-4.197556-1.5699341-.665067-.0764338-1.338666.0129861-1.96099.2603207-.581435.3305343-1.118309.7343056-1.597547 1.2014802-1.78526 1.4457811-4.285422 1.8502795-6.521988 2.6392515-.427344.1521875-.902615.3764638-.982492.8009868s.399387.8530509.878651 1.0372779c.479264.1842269 1.030418.3043749 1.349928.6768338.399387.4805921.271583 1.1614309.343473 1.7661759.147773 1.2014802 1.19816 1.8542844 2.360376 2.2027137.962523.3083799 2.224585 1.093347 3.17912.9331496z"
            fill="#000"
            fillRule="nonzero"
            opacity=".1"
          />
          <path
            d="m178.973253 35.2082708c.934565-.1561924 1.76529-.7048684 2.711837-.7529276 1.19816-.060074 2.280499.7088733 3.123205 1.5018502.842707.792977 1.66145 1.698092 2.819672 2.0425164.740199.1739572 1.503784.2253647 2.260529.1521875.420789.0213199.838249-.0847296 1.198161-.304375.181112-.1684139.31825-.3788164.399387-.6127549.652991-1.5085135.639935-3.2234758-.035945-4.7218172-.093621-.153438-.133012-.3340103-.111828-.5126315.036117-.1060979.094703-.2031014.171736-.2843503.798774-.9451645 1.64148-1.8903289 1.964984-3.03574.323503-1.1454111-.07189-2.5551479-1.250081-3.0637745-.594003-.2565576-1.267141-.2565576-1.861143 0-.551154.2523109-.978498.7208881-1.597548.8450411-1.002461.2082566-1.861143-.6007401-2.643941-1.2014802-1.207618-.8952833-2.643788-1.428569-4.141642-1.5378947-.665067-.0764338-1.338665.0129862-1.96099.2603208-.581435.3305343-1.118309.7343056-1.597547 1.2014801-1.78526 1.4457812-4.285421 1.8502795-6.521988 2.6392515-.427344.1521875-.902614.3764638-.982492.8009868-.079877.424523.399387.853051.878651 1.0372779.479265.184227 1.030419.304375 1.349928.6768339.399387.4805921.271583 1.1614308.343473 1.7661759.147773 1.2014801 1.19816 1.8542844 2.360376 2.2027136.906609.2763405 2.168671 1.0613075 3.123206.9011102z"
            fill="#a66477"
            fillRule="nonzero"
          />
          <path
            d="m170.989509 82.9310641s7.065154 1.2014801 8.830444 5.0261921c1.76529 3.8247119 12.380994 14.1734618 12.380994 14.1734618"
            fill="#000"
            fillRule="nonzero"
            opacity=".05"
          />
          <g
            fill="#000"
            fillRule="nonzero"
            opacity=".05"
            transform="translate(135.732901 66.026238)"
          >
            <path d="m12.5914023 31.8992991c-1.1142895.4966118-12.24520206-2.9756659-12.24520206-2.9756659-.13674398-1.0791896-.12464878-2.1721207.03594482-3.2480015-.63901903 1.5459045-.2835647 4.1170722-.2835647 4.1170722s11.13091264 3.4722777 12.24520204 2.9756659c.3674359-.1642023.7228903-1.1173766 1.034412-2.3228617-.2476198.7729522-.5112152 1.3416529-.7867921 1.453791z" />
            <path d="m6.14529787 0c-.0912128.25186403-.16854435.50856737-.2316444.76894732 0 0-2.6838799 16.07180008-4.28542132 22.02713688 1.48172536-4.8339553 4.27743358-21.36632277 4.51706572-22.7960842z" />
            <path d="m22.6080254 41.3269137s-8.2872779-12.0148019-7.9158481-17.6217095c0 0-.0878651.6968585-.243626 1.6820723.3754237 5.8632233 7.9118543 16.8127128 7.9118543 16.8127128 5.7631528 0 8.3391982-4.0930425 9.4534876-8.3903367-1.2301116 3.9568748-3.8301202 7.5172611-9.2058678 7.5172611z" />
          </g>
          <path
            d="m136.993697 96.1753807s7.5564 4.1731413 10.280218 2.186694c2.723819-1.9864473.99048-5.0862662.99048-5.0862662l-2.104769-3.4722777h-7.049179l-2.348395 4.0930425z"
            fill="#333"
            fillRule="nonzero"
          />
          <path
            d="m188.522593 112.563571s.49524-9.920222-12.121392-9.796069-.49524 0-.49524 0-4.948403-1.489835-11.130912 2.002467-13.235682 2.72736-13.235682 2.72736-5.691263 9.427615-.371429 12.154975c5.319833 2.72736 7.049178-1.013249 7.049178-1.013249s10.883293 2.106596 13.359492-.744917c3.613538 3.590773 9.015604 4.671973 13.726927 2.747384 0 0 6.186503-2.747384 3.219058-8.077951z"
            fill="#222"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
};
