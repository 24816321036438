import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import ScrollToOnError from "../../../../../components/forms/ScrollToOnError";

import {
  autoActions,
  autoSelectors,
  useAction,
  useSelector,
  wizardNoPersistSelectors,
} from "../../../../../state";
import {
  MILEAGE_OPTIONS,
  VehicleInitialValues as initialValues,
} from "../../../../../constants";
import { vehicleValidationSchema } from "../../../../../utils";
import { Form, Navigation, useFormik } from "../../../../../lib";

import { useStyles } from "./VehicleFields.styles";
import { DeleteIcon } from "../../../../../components/icons";

const VehicleFields = ({ index }) => {
  const classes = useStyles();
  // actions
  const getMakeByYear = useAction(autoActions.getMakeByYear);
  const getModel = useAction(autoActions.getModel);
  const removeVehicle = useAction(autoActions.removeVehicle);
  const setVehicle = useAction(autoActions.setVehicle);
  const setVinError = useAction(autoActions.setVehicleLookupError);
  const toNextVehicle = useAction(autoActions.toNextVehicle);
  const toPreviousVehicle = useAction(autoActions.toPreviousVehicle);
  const vehicleLookup = useAction(autoActions.vehicleLookup);
  // selectors
  const activeVehicleIndex = useSelector(autoSelectors.activeVehicleIndex);
  const getVinError = useSelector(autoSelectors.getVinError);
  const makesLoading = useSelector(autoSelectors.makesLoading);
  const modelsLoading = useSelector(autoSelectors.modelsLoading);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const rate_state = useSelector(autoSelectors.state);
  const vehiclesForm = useSelector(autoSelectors.vehiclesForm);
  const selectedVehicles = useSelector(autoSelectors.selectedVehicles);

  const years: number[] = [];
  for (let i = new Date().getFullYear(); i > 1970; i--) {
    years.push(i);
  }
  // consts
  const selectedVehiclesLength = selectedVehicles.length || 0;
  const isFirstVehicle = activeVehicleIndex === 0;
  const isLastVehicle =
    selectedVehiclesLength === 1 ||
    activeVehicleIndex + 1 === selectedVehiclesLength;
  const submittedVehicles = vehiclesForm.submittedVehicles;
  const vehicleFromState = selectedVehicles && selectedVehicles[index];
  // state
  const [formSubmitted, setFormSubmitted] = useState(false);

  const allowRemoveVehicle = useMemo(() => {
    if (!submittedVehicles?.length) return false;
    return !(submittedVehicles?.length === 1 && isFirstVehicle);
  }, [submittedVehicles, activeVehicleIndex]);

  const form: any = useFormik({
    enableReinitialize: false,
    validateOnChange: formSubmitted,
    initialValues: {
      ...initialValues,
      ...vehicleFromState,
      isLastVehicle,
      rate_state,
    },
    validationSchema: vehicleValidationSchema,
    async onSubmit(values) {
      const result = await setVehicle({ vehicle: values, index });
      setVinError("");
      if (activeVehicleIndex + 1 === result?.selectedVehicles?.length) {
        Navigation.go(`/${mode}/wizard/drivers`);
      } else {
        toNextVehicle();
      }
    },
  });

  // Since we need to keep `enableReinitialize: false`
  // (in order to prevent resetting the form values on re-render),
  // we have to manually update form values with values from state
  // TODO: see if below is necessary
  useEffect(() => {
    if (vehicleFromState) {
      form.setFieldValue("ModelYear", vehicleFromState.ModelYear);
      form.setFieldValue("Maker", vehicleFromState.Maker);
      form.setFieldValue("Model", vehicleFromState.Model);
    }
  }, [
    vehicleFromState.ModelYear,
    vehicleFromState.Maker,
    vehicleFromState.Model,
  ]);

  useEffect(() => {
    if (getVinError) {
      form.setFieldValue("VIN", "");
    }
  }, [getVinError]);

  const handleSwitcherChange = useCallback(
    (fieldName) => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) {
        form.setFieldValue(fieldName, value);
      }
    },
    [form],
  );

  const handleRemoveVehicle = useCallback(() => {
    removeVehicle(activeVehicleIndex);
    if (isFirstVehicle) {
      if (!submittedVehicles?.length) {
        // TODO: find out what we need to do here, no longer have backWizardStep action - CG
        // backWizardStep();
      }
    } else {
      toPreviousVehicle();
    }
  }, [
    removeVehicle,
    activeVehicleIndex,
    isFirstVehicle,
    submittedVehicles?.length,
    toPreviousVehicle,
  ]);

  const handleLoadVehicleInfo = useCallback(() => {
    const { VIN } = form.values;
    vehicleLookup({ VIN, index });
  }, [form.values, vehicleLookup, index]);

  useEffect(() => {
    if (activeVehicleIndex === index) {
      const ModelYear = form.values.ModelYear;
      if (ModelYear) {
        getMakeByYear({ ModelYear, index });
      }
    }
  }, [form.values.ModelYear, getMakeByYear, activeVehicleIndex, index]);

  useEffect(() => {
    if (activeVehicleIndex === index) {
      const ModelYear = form.values.ModelYear;
      const Maker = form.values.Maker;
      if (Boolean(ModelYear) && Boolean(Maker)) {
        getModel({ ModelYear, Maker, index });
      }
    }
  }, [
    form.values.ModelYear,
    form.values.Maker,
    getModel,
    activeVehicleIndex,
    index,
  ]);

  const isVINFieldVisible =
    !form.values.ModelYear || !form.values.Maker || !form.values.Model;

  return (
    <Form form={form}>
      <Box className={classes.root}>
        {isVINFieldVisible && (
          <Grid container className={classes.VINField}>
            <Grid item xs={12}>
              <Typography className={classes.VINTitle}>
                Enter your VIN number for a more accurate quote{" "}
                <span>optional</span>
              </Typography>
            </Grid>
            <Grid item className={classes.VINGridItem}>
              <TextField
                id="VIN"
                name="VIN"
                label="VIN number (optional)"
                value={form.values.VIN}
                onChange={form.handleChange}
              />
            </Grid>
            <Grid item className={classes.VINButton}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleLoadVehicleInfo}
              >
                Go
              </Button>
            </Grid>
          </Grid>
        )}
        {isVINFieldVisible && getVinError && (
          <Grid>
            <FormHelperText error variant="outlined">
              {`${getVinError}`}
            </FormHelperText>
          </Grid>
        )}
        <Grid
          container
          spacing={5}
          className={`${classes.field} ${classes.smallMarginField}`}
        >
          <Grid item xs={12}>
            {/* TODO fix year selection failure */}
            <ScrollToOnError name="ModelYear">
              <TextField
                select
                id="year"
                name="ModelYear"
                label="Year"
                value={form.values.ModelYear || ""}
                onChange={form.handleChange}
                error={form.touched.ModelYear && Boolean(form.errors.ModelYear)}
                helperText={form.touched.ModelYear && form.errors.ModelYear}
                disabled={!years?.length}
              >
                {years.map((year) => (
                  <MenuItem key={`year-${year}`} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>
            </ScrollToOnError>
          </Grid>
          <Grid item xs={12}>
            <ScrollToOnError name="Maker">
              <TextField
                select
                id="make"
                name="Maker"
                label="Make"
                value={form.values.Maker || ""}
                onChange={form.handleChange}
                error={form.touched.Maker && Boolean(form.errors.Maker)}
                helperText={form.touched.Maker && form.errors.Maker}
                disabled={!form.values.ModelYear && !makesLoading}
                InputProps={{
                  endAdornment: makesLoading ? (
                    <CircularProgress
                      color="primary"
                      size={22}
                      className={classes.selectLoader}
                    />
                  ) : null,
                }}
              >
                {/* Make based on selected year and may wary between selected vehicles, so should be saved and taken from selected vehicle state level */}
                {(form.values.ModelYear
                  ? vehicleFromState?.makes || []
                  : []
                ).map((make) => (
                  <MenuItem key={`make-${make}`} value={make}>
                    {make}
                  </MenuItem>
                ))}
              </TextField>
            </ScrollToOnError>
          </Grid>
          <Grid item xs={12}>
            <ScrollToOnError name="Model">
              <TextField
                select
                id="model"
                name="Model"
                label="Model"
                value={form.values.Model || ""}
                onChange={form.handleChange}
                error={form.touched.Model && Boolean(form.errors.Model)}
                helperText={form.touched.Model && form.errors.Model}
                disabled={
                  (!form.values.ModelYear || !form.values.Maker) &&
                  !modelsLoading
                }
                InputProps={{
                  endAdornment: modelsLoading ? (
                    <CircularProgress
                      color="primary"
                      size={22}
                      className={classes.selectLoader}
                    />
                  ) : null,
                }}
              >
                {/* Model based on selected year and make and may wary between selected vehicles, so should be saved and taken from selected vehicle state level */}
                {(Boolean(form.values.ModelYear) && Boolean(form.values.Maker)
                  ? vehicleFromState?.models || []
                  : []
                ).map((model) => (
                  <MenuItem key={`model-${model}`} value={model}>
                    {model}
                  </MenuItem>
                ))}
              </TextField>
            </ScrollToOnError>
          </Grid>
        </Grid>
        <Box className={`${classes.field} ${classes.smallMarginField}`}>
          <Typography variant="h6" className={classes.fieldTitle}>
            Do you own, finance or lease this vehicle?
          </Typography>
          <ScrollToOnError name="Type">
            <FormControl
              component="fieldset"
              className={form.errors.Type ? classes.error : ""}
            >
              <RadioGroup
                name="Type"
                value={form.values.Type}
                onChange={form.handleChange}
              >
                <FormControlLabel
                  value="own"
                  label="Own"
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  value="finance"
                  label="Finance"
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  value="lease"
                  label="Lease"
                  control={<Radio color="primary" />}
                />
              </RadioGroup>
            </FormControl>
          </ScrollToOnError>
          {Boolean(form.errors.Type) && (
            <FormHelperText error variant="outlined">
              {form.errors.Type}
            </FormHelperText>
          )}
        </Box>

        <Box className={classes.field}>
          <Typography variant="h6" className={classes.fieldTitle}>
            How many miles do you drive?
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12}>
              <ScrollToOnError name="miles">
                <TextField
                  select
                  id="miles"
                  name="miles"
                  label="Annual Mileage"
                  value={form.values.miles}
                  onChange={form.handleChange}
                  error={form.touched.miles && Boolean(form.errors.miles)}
                >
                  {MILEAGE_OPTIONS.map((mileage) => (
                    <MenuItem
                      key={`mileage-${mileage.value}`}
                      value={mileage.value}
                    >
                      {mileage.label}
                    </MenuItem>
                  ))}
                </TextField>
              </ScrollToOnError>
              {Boolean(form.errors.miles) && (
                <FormHelperText error variant="outlined">
                  {form.errors.miles}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.field}>
          <Typography variant="h6" className={classes.fieldTitle}>
            What do you mostly use this vehicle for?
          </Typography>
          <ScrollToOnError name="usage">
            <FormControl
              component="fieldset"
              className={form.errors.usage ? classes.error : ""}
            >
              <RadioGroup
                name="usage"
                value={form.values.usage || ""}
                onChange={form.handleChange}
              >
                <FormControlLabel
                  value="W"
                  label="Commuting"
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  value="P"
                  label="Pleasure"
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  value="B"
                  label="Business"
                  control={<Radio color="primary" />}
                />
              </RadioGroup>
            </FormControl>
          </ScrollToOnError>
          {Boolean(form.errors.usage) && (
            <FormHelperText error variant="outlined">
              {form.errors.usage}
            </FormHelperText>
          )}
        </Box>
        {isLastVehicle && (
          <Box className={classes.field}>
            <Typography variant="h6" className={classes.fieldTitle}>
              Are there any other vehicles to add?
            </Typography>

            <Grid container spacing={5}>
              <Grid item xs={12}>
                <ScrollToOnError name="moreVehicles">
                  <ToggleButtonGroup
                    className={`${classes.switcherGroup} ${
                      form.errors.moreVehicles ? classes.error : ""
                    }`}
                    exclusive
                    size="large"
                    value={form.values.moreVehicles}
                    onChange={handleSwitcherChange("moreVehicles")}
                  >
                    <ToggleButton value={true} className={classes.switcher}>
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      value={false}
                      className={classes.switcher}
                      id="noMoreVehicles"
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ScrollToOnError>

                {Boolean(form.errors.moreVehicles) && (
                  <FormHelperText error variant="outlined">
                    {form.errors.moreVehicles}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        )}

        {allowRemoveVehicle && (
          <Box>
            <Button
              onClick={handleRemoveVehicle}
              className={classes.removeVehicleBtn}
            >
              <DeleteIcon className={classes.removeIcon} />
              REMOVE THIS VEHICLE
            </Button>
          </Box>
        )}
      </Box>
      <Grid container justifyContent="center" alignItems="center">
        <Button
          onClick={() => setFormSubmitted(true)}
          id="submit"
          type="submit"
          variant="contained"
          color="primary"
        >
          {isLastVehicle ? "Let's keep moving" : "Next Vehicle"}
        </Button>
      </Grid>
    </Form>
  );
};

export default React.memo(VehicleFields);
