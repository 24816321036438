import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  topRight: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: "right",
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  avatar: {
    height: 32,
    width: 32,
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.down('md')]: {
      height: 24,
      width: 24,
    },
  },
  link: {
    textDecoration: "none",
    width: 200,
    color: "#8D98C2",
    fontWeight: 500,
  },
  menu: {
    "& .MuiPaper-root": {
      left: "auto !important",
      right: 30,
      top: "60px !important",
    },
    "& .MuiListItem-button:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "#F7F9FF",
      "& .icon": {
        stroke: theme.palette.primary.main,
      },
      "& .personalIcon": {
        fill: theme.palette.primary.main,
      },
    },
    "& .MuiSvgIcon-root": {
      verticalAlign: "bottom",
    },
    "& svg": {
      verticalAlign: "bottom",
    },
    "& .personalIcon": {
      verticalAlign: "middle",
    },
  },
  iconWrapper: {
    width: 35,
    display: "inline-block",
  },
  linkWrapper: {
    width: 35,
    display: "inline-block",
    paddingLeft: 3,
    verticalAlign: "text-bottom",
  },
  menuBtn: {
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}));
