import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  Chip,
  Typography,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useFormik, yup } from "../../lib";

import { useStyles } from "./CreateUsersPage.styles";
import { formatSplitCase } from "../../utils";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  role: "",
  agency: "",
  phone: "",
  referral_prod_id: "",
  multipleAgencies: [],
};

const restrictedAgents = ["agent", "affiliate_agent"];
const CreateUsersPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const agencies = useSelector(adminSelectors.agencies);
  const isSuperAgent = useSelector(authSelectors.isSuperAgent);
  const activeAgencyId = useSelector(authSelectors.activeAgencyId);
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const products = useSelector(adminSelectors.referralProducts);
  const getAgencies = adminActions.getAgencies;
  const availableRoles = isSuperAgent
    ? [
        "agent",
        "super_agent",
        "agency_admin",
        "affiliate_agent",
        "referral_product_owner",
        "sales_director",
        "sales_admin",
      ]
    : ["agency_admin", "agent", "affiliate_agent"];

  const [multiAgencies, setMultiAgencies] = useState<Array<number>>([]);

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values) {
      if (!activeRole || !agencyId) {
        console.warn("active role and active agency id is not defined");
        return;
      }
      const user = {
        ...values,
        agency: +form.values.agency,
        referral_prod_id: form.values.referral_prod_id
          ? form.values.referral_prod_id
          : null,
        multipleAgencies: multiAgencies,
      };
      dispatch(adminActions.createUser(user, activeRole, agencyId));
    },
  });

  const handleMultiSelect = (event) => {
    setMultiAgencies(event.target.value);
  };
  useEffect(() => {
    if (isSuperAgent) {
      dispatch(getAgencies());
    } else {
      form.setFieldValue("agency", activeAgencyId);
    }
  }, [activeAgencyId]);

  useEffect(() => {
    if (form.values.role === "referral_product_owner") {
      dispatch(adminActions.getReferralProducts());
    }
  }, [form.values.role]);
  useEffect(() => {
    if (activeRole && restrictedAgents.includes(activeRole)) {
      form.setFieldValue("role", "affiliate_agent");
    }
  }, [activeRole]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="first_name"
                name="first_name"
                label="First Name"
                value={form.values.first_name}
                onChange={form.handleChange}
                error={
                  form.touched.first_name && Boolean(form.errors.first_name)
                }
                helperText={form.touched.first_name && form.errors.first_name}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="last_name"
                name="last_name"
                label="Last Name"
                value={form.values.last_name}
                onChange={form.handleChange}
                error={form.touched.last_name && Boolean(form.errors.last_name)}
                helperText={form.touched.last_name && form.errors.last_name}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                id="email"
                name="email"
                label="Email address"
                onChange={form.handleChange}
                error={form.touched.email && Boolean(form.errors.email)}
                value={form.values.email}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                select
                fullWidth
                name="role"
                id="role"
                label="Role"
                disabled={activeRole && restrictedAgents.includes(activeRole)}
                variant="outlined"
                value={form.values.role}
                onChange={form.handleChange}
                error={form.touched.role && Boolean(form.errors.role)}
                helperText={form.touched.role && form.errors.role}
              >
                {availableRoles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {formatSplitCase(role)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                type="phone"
                id="phone"
                name="phone"
                label="Phone number"
                onChange={form.handleChange}
                error={form.touched.phone && Boolean(form.errors.phone)}
                value={form.values.phone}
              />
            </Grid>
            {isSuperAgent &&
              form.values.role !== "referral_product_owner" &&
              form.values.role !== "sales_director" &&
              form.values.role !== "sales_admin" && (
                <Grid item className={classes.formControl}>
                  <TextField
                    select
                    fullWidth
                    name="agency"
                    id="agency"
                    label="Agency"
                    variant="outlined"
                    value={form.values.agency}
                    onChange={form.handleChange}
                    error={form.touched.agency && Boolean(form.errors.agency)}
                    helperText={form.touched.agency && form.errors.agency}
                  >
                    {agencies.map((agency) => (
                      <MenuItem key={agency.id} value={agency.id}>
                        {agency.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
            {isSuperAgent && form.values.role === "referral_product_owner" && (
              <Grid item className={classes.formControl}>
                <TextField
                  select
                  fullWidth
                  name="referral_prod_id"
                  id="referral_prod_id"
                  label="Product"
                  variant="outlined"
                  value={form.values.referral_prod_id}
                  onChange={form.handleChange}
                >
                  {products.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.product_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {isSuperAgent &&
              (form.values.role === "sales_director" ||
                form.values.role === "sales_admin") && (
                <Grid item>
                  <FormControl variant="outlined" className={classes.agencies}>
                    <InputLabel
                      // id="multipleAgencies"
                      // htmlFor="multipleAgencies"
                      className={classes.label}
                    >
                      Agencies
                    </InputLabel>

                    <Select
                      multiple
                      fullWidth
                      name="multipleAgencies"
                      id="multipleAgencies"
                      labelId="test-select-label"
                      // variant="outlined"
                      label="Tag"
                      value={multiAgencies}
                      onChange={handleMultiSelect}
                      // input={<OutlinedInput label={"Agencies"} />}
                      renderValue={() => ""}
                    >
                      <MenuItem disabled value="">
                        <em>Agencies</em>
                      </MenuItem>
                      {agencies.map((agency) => {
                        return (
                          <MenuItem key={agency.id} value={agency.id}>
                            <Checkbox
                              checked={multiAgencies.includes(agency.id)}
                            />
                            <ListItemText primary={agency.name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
          </Grid>
          {!!multiAgencies.length && (
            <Grid className={classes.selectedAgenciesWrapper}>
              <div>
                {" "}
                <Typography>Selected agencies</Typography>
              </div>
              <div>
                {" "}
                <div className={classes.selectedAgencies}>
                  {multiAgencies.map((id) => {
                    const name = agencies?.find((a) => a.id === id)?.name;
                    return (
                      <Chip key={id} label={name} className={classes.chip} />
                    );
                  })}
                </div>
              </div>
            </Grid>
          )}

          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  form.isSubmitting ||
                  !form.values.first_name ||
                  !form.values.last_name ||
                  !form.values.role ||
                  !form.values.email ||
                  !form.values.phone ||
                  (isSuperAgent &&
                    form.values.role !== "referral_product_owner" &&
                    form.values.role !== "sales_director" &&
                    form.values.role !== "sales_admin" &&
                    !form.values.agency) ||
                  (form.values.role === "referral_product_owner" &&
                    !form.values.phone) ||
                  (form.values.role === "referral_product_owner" &&
                    !form.values.referral_prod_id)
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateUsersPage;
