import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  loadingQuoteCard: {
    borderRadius: 3,
    border: "none",
    margin: 12,
    padding: "40px 32px 24px",
    boxShadow: "0 2px 12px 0 #F5F6FE",
    height: "100%",
  },
  circularProgress: {
    height: 28,
    width: 28,
    margin: "12px 0px",
  },
  description: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "24px",
  },
  insuranceLevel: {
    fontSize: 20,
    fontWeight: 600,
    color: "#000",
    marginBottom: 16,
    whiteSpace: "nowrap",
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  selectBtn: {
    background: "#EFF1FB",
    color: "#fff",
    fontSize: 14,
    fontWeight: 600,
    borderRadius: 3,
    height: 32,
    margin: "16px 0",
    textTransform: "uppercase",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  fullWidthDivider: {
    margin: "0 -32px 24px",
    background: "#EFF1FB",
    height: 1,
    width: "100%",
  },
  divider: {
    margin: "11.5px 0",
    background: "#EFF1FB",
    height: 1,
    width: "100%",
  },
}));
