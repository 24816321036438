import React from "react";
import { MenuItem, TextField } from "@mui/material";
import {
  authActions,
  authSelectors,
  useAction,
  useSelector,
} from "../../state";
import { useStyles } from "./AgencySelectionBox.styles";

const AgencySelectionBox = () => {
  const classes = useStyles();

  const user_agency = useSelector(authSelectors.userAgency);
  const activeAgencyId = useSelector(authSelectors.activeAgencyId);
  const setActiveAgencyId = useAction(authActions.setActiveAgencyId);

  const handleAgencySelect = (e) => {
    setActiveAgencyId(e.target.value);
  };

  return (
    <TextField
      className={classes.agencyField}
      select
      value={activeAgencyId}
      onChange={handleAgencySelect}
      variant="outlined"
      size="small"
    >
      {user_agency?.map((a, idx) => (
        <MenuItem key={idx} value={a.agency_id}>
          {a.agency?.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default AgencySelectionBox;
