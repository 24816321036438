import React from "react";
import { FormikProvider } from "formik";

export const Form = React.memo(
  /**
   * Form element that implements FormikProvider for a `useFormik` component.
   *
   * @typedef {object} FormProps
   * @property {React.ReactNode} children
   * @property {ReturnType<typeof import("formik").useFormik>} form
   *
   * @param {FormProps & JSX.IntrinsicElements["form"]} param0
   */
  function Form({ form: frm, children, action, ...rest }) {
    // See:
    // - https://github.com/formium/formik/discussions/2746
    // - https://github.com/formium/formik/blob/formik%402.2.5/packages/formik/src/Form.tsx
    return (
      <FormikProvider value={frm}>
        <form
          onReset={frm.handleReset}
          onSubmit={frm.handleSubmit}
          // iOS needs an "action" attribute for nice input: https://stackoverflow.com/a/39485162/406725
          // We default the action to "#" in case the preventDefault fails (just updates the URL hash)
          action={action || "#"}
          {...rest}
        >
          {children}
        </form>
      </FormikProvider>
    );
  },
);
