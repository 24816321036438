import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Form, Navigation, useFormik } from "../../../../lib";
import {
  lifeActions,
  lifeSelectors,
  useAction,
  useSelector,
} from "../../../../state";
import {
  formatCurrency,
  lifeCoverageSchema as validationSchema,
} from "../../../../utils";
import ScrollToOnError from "../../../../components/forms/ScrollToOnError";
import { useStyles } from "./CoverageDetails.styles";
// import { InfoIcon } from "../../../../components/icons";
import { CoverageDetails, LIFE_AMOUNTS } from "../../../../state/life/types";

export default function InsuredContainer() {
  const classes = useStyles();

  //STATE
  const [formSubmitted, setFormSubmitted] = useState(false);

  //SELECTORS
  const coverages = useSelector(lifeSelectors.coverageDetails);
  //ACTIONS
  const setCoverages = useAction(lifeActions.setCoverages);
  const terms = [
    { label: 5, value: "2" },
    { label: 10, value: "3" },
    { label: 15, value: "4" },
    { label: 20, value: "5" },
    { label: 25, value: "6" },
    { label: 30, value: "7" },
    { label: 35, value: "9" },
  ];
  const initialValues: CoverageDetails = {
    yearTerm: "",
    amount: "",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik: any = useFormik({
    initialValues: coverages || initialValues,
    validationSchema: validationSchema,
    validateOnChange: formSubmitted,
    async onSubmit(values: CoverageDetails) {
      await setCoverages(values);
      Navigation.go(`/life/wizard/contact`);
    },
  });

  return (
    <Form form={formik} onSubmit={formik.handleSubmit} className="maxWidth">
      <ScrollToOnError name="yearTerm">
        <FormControl className={classes.formSection}>
          <FormLabel className={classes.formLabel} component="legend">
            Insurance type
          </FormLabel>
          <FormControl className={classes.formSection} variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Select insurance type
            </InputLabel>
            <Select
              name="yearTerm"
              value={formik.values.yearTerm}
              onChange={formik.handleChange}
              label="Select insurance type"
              error={!!formik.errors.yearTerm}
              fullWidth
            >
              {terms.map((term) => (
                <MenuItem key={term.label} value={term.value}>
                  {term.label} year term
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.errors.yearTerm) && (
              <FormHelperText error variant="outlined">
                {formik.errors.yearTerm}
              </FormHelperText>
            )}
          </FormControl>
        </FormControl>
      </ScrollToOnError>
      <ScrollToOnError name="amount">
        <FormControl className={classes.formSection}>
          <FormLabel className={classes.formLabel} component="legend">
            Insurance amount
          </FormLabel>
          <FormControl className={classes.formSection} variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Select insurance amount
            </InputLabel>
            {/* <OutlinedInput label="Select insurance amount" /> */}
            <Select
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={!!formik.errors.amount}
              fullWidth
              label="Select insurance amount"
            >
              {LIFE_AMOUNTS.map((amount) => (
                <MenuItem key={amount} value={amount * 1000}>
                  {formatCurrency(amount * 1000)}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.errors.amount) && (
              <FormHelperText error variant="outlined">
                {formik.errors.amount}
              </FormHelperText>
            )}
          </FormControl>
        </FormControl>
      </ScrollToOnError>
      <Button
        variant="contained"
        color="primary"
        className={classes.nextButton}
        type="submit"
        disabled={!formik.isValid}
        onClick={() => setFormSubmitted(true)}
      >
        Continue
      </Button>
    </Form>
  );
}
