import React from "react";
import { Box, Button, Grid, Hidden, Typography } from "@mui/material";
import img from "../../../assets/img/crash-car.png";
import { useMobile } from "../../../themes";
import { uiSelectors, useSelector } from "../../../state";
import { useStyles } from "./NotFoundPage.styles";
import { REACT_APP_ASSETS_URL } from "../../../config";
import { formatPhone } from "../../../lib";

const NotFoundPage = () => {
  const { logo_height, logo_path, phone } = useSelector(uiSelectors.agencyData);

  const classes = useStyles({ logo_height });
  const isMobile = useMobile();

  return (
    <Box>
      <Grid item component="header" className={classes.header} id="header">
        <Grid
          container
          alignItems="center"
          justifyContent={isMobile ? "center" : "space-between"}
          className={classes.headerContent}
        >
          <Grid item className={classes.logoWrapper}>
            <a href="/">
              <img src={`${REACT_APP_ASSETS_URL}/${logo_path}`} alt="logo" />
            </a>
          </Grid>
          <Hidden smDown>
            <Grid item>
              <Typography className={classes.helpTextLight}>
                Need help? Call us at{" "}
                <a href={`tel:${phone}`} className={classes.phoneLink}>
                  {formatPhone(phone)}
                </a>
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Typography className={classes.title}>Accidents happen.</Typography>
        <Typography className={classes.subTitle}>Like this 404</Typography>
        <Button color="primary" variant="contained" href="/">
          Return Home
        </Button>
        <img src={img} alt="crash" />
      </div>
    </Box>
  );
};

export default NotFoundPage;
