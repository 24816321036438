import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { AddressAsyncAutocomplete, LockIcon } from "../../../../components";
import { BlockIcon } from "../../../../components";
import { Form, useFormik, useLocation, yup } from "../../../../lib";
import {
  homeActions,
  homeSelectors,
  wizardSelectors,
  uiActions,
  useAction,
  useSelector,
  wizardActions,
} from "../../../../state";
import { useMobile } from "../../../../themes";
import { useStyles } from "./Start.styles";
import { UNSUPPORTED_STATES } from "../../../../state/wizard/types";

const StartForm = () => {
  const classes = useStyles();
  const isMobile = useMobile();
  const location = useLocation();

  const { address, apt } = useSelector(homeSelectors.start);
  const addressError = useSelector(wizardSelectors.addressError);
  const initialValues: any = { address, apt };
  const setStart = useAction(homeActions.setStart);
  const setFbUser = useAction(uiActions.setFbUser);
  // const validateAddress = useAction(wizardActions.validateAddress);
  const setAddressError = useAction(wizardActions.setAddressError);
  const setAddressModalOpen = useAction(wizardActions.setAddressModalOpen);
  //TODO: renters
  useEffect(() => {
    const { utm_medium } = location.query;
    if (utm_medium === "manychat") {
      setFbUser(true);
    } else {
      setFbUser(false);
    }
  }, []);

  useEffect(() => {
    setAddressError("");
  }, []);

  const validationSchema = yup.object({
    address: yup.object({
      zipcode: yup.string(),
      state: yup.string(),
      full_state: yup.string(),
      city: yup.string(),
      street_line: yup.string(),
      label: yup.string().required("Address is required"),
    }),
  });

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values, { setSubmitting }) {
      // const isCoveredArea = await validateAddress(
      //   values.address?.state,
      //   "home",
      // );
      const state = values.address.state;
      if (UNSUPPORTED_STATES.includes(state)) {
        setAddressError(
          `${window.location.hostname} is not yet available in your area. Check back soon!`,
        );
        return;
      }
      setSubmitting(false);
      // if (!isCoveredArea) return;
      setStart(values);
      setAddressModalOpen(true);
    },
  });

  return (
    <Form form={form} className="maxWidth">
      <Grid container className={classes.formContainer}>
        <Grid item sm={9} xs={12}>
          <Box mb={5}>
            <AddressAsyncAutocomplete form={form} />
            {Boolean(addressError?.length) && (
              <FormHelperText error variant="outlined">
                {addressError}
              </FormHelperText>
            )}
          </Box>
        </Grid>
        <Grid item sm={3} xs={12}>
          <Box mb={5} ml={isMobile ? 0 : 2}>
            <TextField
              id="apt"
              name="apt"
              label="Apt/Unit#"
              value={form.values.apt}
              onChange={form.handleChange}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mb={5}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={0}
              wrap="nowrap"
            >
              <Grid item xs={6}>
                <Typography
                  className={`${classes.feature} ${classes.firstFeature}`}
                >
                  <LockIcon color="primary" />
                  Cutting edge encryption
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.feature}>
                  <BlockIcon color="primary" />
                  We never sell your info
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" alignItems="center">
        <Button
          type="submit"
          id="submit"
          variant="contained"
          color="primary"
          disabled={!form.values?.address?.zipcode || !form.isValid}
          className={classes.submitButton}
        >
          I'm ready to roll
        </Button>
      </Grid>
    </Form>
  );
};

export default React.memo(StartForm);
