import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./CoverageSection.styles";
import { CoverageCard } from "./CoverageCard";
import { ExpandLessIcon } from "../../../components";

interface CoverageSectionProps {
  quotes: Array<any>;
  level: number;
  selectedQuote: any; //may or may not be in this coverage section
  hasPurchased: any; //may or may not be in this coverage section
}

export const CoverageSection = ({
  quotes,
  level,
  selectedQuote,
  hasPurchased,
}: CoverageSectionProps) => {
  const classes = useStyles({ level });
  const [expanded, setExpanded] = useState(false);
  // console.log({ hasPurchased, level, selectedQuote });

  useEffect(() => {
    if (hasPurchased) {
      setExpanded(Boolean(quotes.find((q) => q.full_premium_override)));
    } else {
      if (selectedQuote) {
        setExpanded(
          Boolean(
            quotes.find((q) => q.req_txn_id === selectedQuote?.req_txn_id),
          ),
        );
      } else {
        setExpanded(true);
      }
    }
  }, [selectedQuote, hasPurchased]);

  const coverageLevelTitle =
    level === 1
      ? "Low Coverages"
      : level === 2
      ? "Medium Coverages"
      : level === 3
      ? "High Coverages"
      : "Custom Coverages";

  return (
    <Box className={classes.coverageWrapper}>
      <Accordion onChange={() => setExpanded(!expanded)} expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandLessIcon />}>
          <Box className={classes.headingTxtWrapper}>
            <Typography>{coverageLevelTitle}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {quotes.map((quote, index) => (
            <CoverageCard
              key={index}
              quote={quote}
              isLast={index === quotes.length - 1}
              hasPurchased={hasPurchased}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
