import { Box, Button, Link, Typography } from "@mui/material";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { NODE_ENV } from "../../../config";
import { adminSelectors, uiActions } from "../../../state";

export const ApplicantSection = ({ app }) => {
  const dispatch = useDispatch();
  const { agency, quoteType } = useSelector(adminSelectors.activeQuote);

  const hydrateLink = `${
    NODE_ENV !== "development"
      ? `https://${agency?.domain}`
      : `${window.location.host}`
  }/${quoteType.toLowerCase()}/wizard/hydrate-quote/${app.req_uid}`;
  return (
    <div>
      <h2>Applicant Info</h2>

      <h3>{quoteType} Quote</h3>
      <Box
        style={{
          width: 300,
          margin: "0 auto",
        }}
      >
        {Object.keys(app)
          .filter(
            (q) =>
              (typeof app[q] === "string" || typeof app[q] === "number") &&
              q !== "email" &&
              q !== "phone",
          )
          .map((c, index) => (
            <Typography variant="body2" key={index}>
              <b>{c}</b>: {app[c]}
            </Typography>
          ))}
        {Object.keys(app)
          .filter((q) => q === "previous_ins")
          .map((c, index) => (
            <Typography variant="body2" key={index}>
              <b>{c}</b>: {app[c]?.continuous_insurance}
            </Typography>
          ))}
        <br></br>
        {Object.keys(app)
          .filter((q) => q === "email" || q === "phone")
          .map((c, index) => (
            <Typography variant="body2" key={index}>
              <Link href={`${c === "phone" ? "tel" : "mailto"}:${app[c]}`}>
                <b>{c}</b>: {app[c]}
              </Link>
            </Typography>
          ))}
        <br></br>
        <Button
          variant="contained"
          style={{ width: "75%" }}
          href={hydrateLink}
          target="_blank"
        >
          Return to Wizard
        </Button>
        <CopyToClipboard
          onCopy={() => {
            dispatch(uiActions.showSuccess("Link copied to clipboard"));
          }}
          text={hydrateLink}
        >
          <Button>COPY</Button>
        </CopyToClipboard>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "96%", marginTop: 12 }}
          href={`/admin/edit-quote/${app.req_uid}`}
          target="_blank"
        >
          Proceed to Edit Payment
        </Button>
      </Box>
    </div>
  );
};
