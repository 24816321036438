import React from "react";
import { useStyles } from "./ModalActivity.styles";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { CloseIcon } from "../icons";
import { useSelector } from "react-redux";
import { adminActions, adminSelectors, useAction } from "../../state";

export const StatusActivity = ({ statusHistory }) => {
  const classes = useStyles();
  const orderedHistory = statusHistory?.toReversed();
  return (
    <div>
      <div className={classes.fieldNamesWrapper}>
        <Typography className={classes.fieldName}>Status</Typography>
        <Typography className={classes.fieldName}>comments</Typography>
        <Typography className={classes.fieldName}>User</Typography>
        <Typography className={classes.fieldName}>Date</Typography>
      </div>
      <div className={classes.historyWrapper}>
        {!!statusHistory?.length &&
          orderedHistory.map((r, i) => (
            <Box className={classes.activityRow} key={i}>
              <Typography>
                {r.status} - {r.sub_status}
              </Typography>
              <Typography>{r.message}</Typography>
              <Typography>
                {r.user.first_name} {r.user.last_name}
              </Typography>
              <Typography>
                {new Date(r.created_at).toLocaleDateString()} -{" "}
                {new Date(r.created_at)
                  .toLocaleTimeString("en-US", {
                    hour: "numeric",
                    hour12: true,
                    minute: "numeric",
                  })
                  .toLowerCase()}
              </Typography>
            </Box>
          ))}
      </div>
    </div>
  );
};
export default function ModalActivity() {
  const setListModalOpen = useAction(adminActions.setListModalOpen);
  const isOpen = useSelector(adminSelectors.listModalOpen);
  const { quote_activity } = useSelector(adminSelectors.activeQuote);
  const classes = useStyles();

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => setListModalOpen(false)}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <Box className={classes.box}>
          <div className={classes.titleWrapper}>
            <Typography className={classes.title}>
              Application status activity
            </Typography>
            <IconButton
              className={classes.closeBtn}
              onClick={() => setListModalOpen(false)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <StatusActivity statusHistory={quote_activity} />
        </Box>
      </Modal>
    </>
  );
}
