import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
      "& .MuiDialog-paperWidthSm": {
        width: 580,
        height: 500,
        textAlign: "center",
      },
      [theme.breakpoints.down('md')]: {
        "& .MuiDialog-paperWidthSm": {
          margin: 15,
        },
      },
    },
    closeBtn: {
      position: "absolute",
      right: 30,
      top: 30,
      [theme.breakpoints.down('md')]: {
        position: "absolute",
        right: 5,
        top: 5,
      },
    },
    wrapper: {
      margin: 40,
      [theme.breakpoints.down('md')]: {
        margin: 20,
      },
    },
    thanksWrapper: {
      margin: "auto",
    },
    title: {
      fontFamily: "Montserrat",
      fontSize: 24,
      fontWeight: 600,
      marginBottom: 32,
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
    },
    formControl: {
      width: 500,
      maxWidth: "100%",
    },
    radioGroup: {
      width: "100%",
      gap: 16,
    },
    option: {
      boxSizing: "border-box",
      height: 57,
      border: "1px solid #CDD2E9",
      margin: 0,
      "& .MuiTypography-root": {
        fontSize: 18,
        [theme.breakpoints.down('md')]: {
          fontSize: 12,
        },
      },
    },
    emoji: {
      marginRight: 8,
    },
  }),
  {
    classNamePrefix: "ModalExitIntent",
  },
);
