import { useEffect } from "react";
import throttle from "lodash/throttle";
import { subMinutes, addMinutes, getUnixTime } from "date-fns";
import { useDispatch } from "react-redux";
import { logout } from "./state";
import { useInterval } from "./hooks";
import { adminActions } from "./state";

const DEFAULT_ACTIVITY_EVENTS = [
  "click",
  "mousemove",
  "keydown",
  "DOMMouseScroll",
  "mousewheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "focus",
];
function setLastActive(time) {
  return localStorage.setItem("lastActive", time + "");
}

function clearLastActive() {
  return localStorage.removeItem("lastActive");
}
function refreshToken() {
  return async (dispatch) => {
    dispatch(adminActions.refreshToken());
    dispatch(adminActions.getMyProfile());
  };
}

export default function HandleTokenRefresh() {
  const dispatch = useDispatch();

  const handleActions = () => {
    const lastActiveStorage = localStorage.getItem("lastActive");
    const lastActive = lastActiveStorage
      ? parseInt(lastActiveStorage)
      : lastActiveStorage;
    const jwtExpiry = localStorage.getItem("tokenExpiration");

    if (!jwtExpiry) return;
    const now = new Date();
    const halfHourAgo = subMinutes(now, 5);
    const fourMinAhead = addMinutes(now, 4);
    if (
      !lastActive ||
      (lastActive > getUnixTime(halfHourAgo) &&
        parseInt(jwtExpiry) < getUnixTime(fourMinAhead))
    ) {
      dispatch(refreshToken());
    } else if (lastActive < getUnixTime(halfHourAgo)) {
      logout(dispatch);
    }
  };

  const updateActivityTime = throttle(() => {
    setLastActive(getUnixTime(new Date()));
  }, 5000);

  window.updateActivityTime = updateActivityTime;

  useInterval(handleActions, 1000 * 60);

  useEffect(() => {
    DEFAULT_ACTIVITY_EVENTS.forEach((e) => {
      document.addEventListener(e, updateActivityTime);
    });
    // setLastActive and run handleActions on initial load
    setLastActive(getUnixTime(new Date()));
    handleActions();
    return () => {
      DEFAULT_ACTIVITY_EVENTS.forEach((e) => {
        document.removeEventListener(e, updateActivityTime);
      });
      localStorage.removeItem("tokenExpiration");
      clearLastActive();
      updateActivityTime.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
