import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 560,
    padding: 16,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    "& .MuiCard-root": {
      border: "none !important",
      padding: 8,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.down('md')]: {
      width: "100%",
      maxWidth: 650,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "80%",
    margin: "auto",
  },
  title: {
    fontWeight: 600,
    fontSize: 22,
    paddingBottom: 8,
    paddingTop: 8,
  },
  closeIconWrapper: {
    float: "right",
    position: "sticky",
    top: 0,
    right: 0,
    "& .MuiIconButton-root": {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      position: "initial",
    },
  },
  closeIcon: {
    color: "#AFB6D4",
  },
  textWrapper: {
    maxWidth: 530,
    maxHeight: 365,
    fontSize: 17,
    overflow: "scroll",
    marginRight: 20,
    paddingRight: 10,
    "& span": {
      fontWeight: "bold",
    },
  },
}));
