import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Hidden } from "@mui/material";
import { MenuIcon } from "../../../components/icons";
import { useMobile } from "../../../themes";
import QuoteCard from "../../wizard/quotes/cards/QuoteCard";
import PayForm from "../../wizard/payment/PayForm";
import Sidebar from "../../../layouts/sidebar/Sidebar";

import {
  useDispatch,
  useSelector,
  wizardActions,
  wizardSelectors,
  wizardNoPersistActions,
} from "../../../state";

import { useStyles } from "./PayPage.styles";

const ACHPayPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params: any = useParams();
  const isMobile = useMobile();

  const scrollRef = useRef<HTMLDivElement>(null);
  const selectedQuote = useSelector(wizardSelectors.selectedQuote);

  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (params.req_uid) {
      dispatch(
        wizardNoPersistActions.setWizardMode(
          location.pathname.includes("bundle")
            ? "bundle"
            : location.pathname.includes("home")
            ? "home"
            : location.pathname.includes("auto")
            ? "auto"
            : "",
        ),
      );
      dispatch(wizardActions.getSelectedQuote(params.req_uid));
      dispatch(wizardActions.getStateRequirements());
    }
  }, [dispatch, params.req_uid]);

  useEffect(() => {
    scrollRef?.current?.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <Grid container direction={isMobile ? "column" : "row"}>
      <Grid item>
        <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <Hidden smUp>
          <Box className={classes.mobileHeader} id="mobileHeader">
            <MenuIcon
              onClick={() => setMobileOpen(!mobileOpen)}
              className={classes.drawerButton}
            />
          </Box>
        </Hidden>
      </Grid>
      <Grid
        item
        component="section"
        className={classes.payPageContent}
        ref={scrollRef}
      >
        <Box display="flex" justifyContent="center" width="100%">
          <Box className={classes.selectedQuoteWrap}>
            <Grid
              item
              md={5}
              sm={5}
              xs={12}
              className={classes.quoteCardContainer}
            >
              <QuoteCard quote={selectedQuote} />
            </Grid>
            <Grid item md={7} sm={7} xs={12}>
              <PayForm />
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ACHPayPage;
