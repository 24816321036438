import { Box, List, ListItem, Tooltip, Typography } from "@mui/material";
import React from "react";
import { currencyFormat } from "../../../utils";
import { InfoIcon } from "../../../components";
import { formatKey } from "./fieldsUtils";

export const PaymentPlanData = ({ paymentPlan }) => {
  return (
    <Box>
      <Typography>{paymentPlan.carrierPlanDescription}</Typography>
      <Typography>
        Amount down{" "}
        {currencyFormat(
          paymentPlan.downPaymentDetails.downPaymentAmount?.toString(),
        )}
      </Typography>
      <Typography>
        Installment Amount{" "}
        {currencyFormat(
          paymentPlan.invoiceDetails
            .find((i) => i.isDownPayment === false)
            ?.totalAmount?.toString(),
        )}
      </Typography>
    </Box>
  );
};

export const FailuresInfo = ({ failures }) => {
  return (
    <Tooltip
      title={
        <List>
          {failures?.length
            ? failures?.map((f, i) => <ListItem key={i}>{f.reason}</ListItem>)
            : null}
        </List>
      }
    >
      <InfoIcon
        sx={{
          fontSize: 20,
          color: "#8d98c2",
          "&:hover": {
            cursor: "pointer",
          },
        }}
      />
    </Tooltip>
  );
};
export const PurchasedTag = () => {
  return (
    <Box
      sx={{
        height: 22,
        width: 118,
        borderRadius: "3px 3px 0 0",
        backgroundColor: " #2EB662",
        "& .MuiTypography-root": {
          fontSize: 14,
          color: "#FFFFFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "2px",
        },
      }}
    >
      <Typography>Purchased</Typography>
    </Box>
  );
};

export const DisplayCoverageData = ({ coverages }) => {
  return (
    <div>
      {Object.entries(coverages).map(([key, value]) => (
        <div key={key}>
          <Typography component={"span"}>{formatKey(key)}:</Typography>{" "}
          {currencyFormat(value)}
        </div>
      ))}
    </div>
  );
};
