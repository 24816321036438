import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: "64px auto",
    textAlign: "center",
  },
  progressWrapper: {
    "& .MuiTypography-root": {
      color: theme.palette.primary.light,
      fontSize: 24,
      fontWeight: 600,
    },
  },
  mainText: {
    marginTop: 24,
    marginBottom: 24,
    fontSize: 16,
    fontWeight: "bold",
  },
  subText: {
    color: theme.palette.secondary.main,
    fontSize: 14,
  },
  checkIcon: {
    transform: "scale(3)",
  },
}));
