import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  agencyField: {
    maxWidth: "50%",
    [theme.breakpoints.down('lg')]: {
      height: 48,
      maxWidth: "100%",
      "& .MuiOutlinedInput-root": {
        height: 40,
        minHeight: 48,
      },
    },
    [theme.breakpoints.down('md')]: {
      height: 40,
      maxWidth: "100%",
      "& .MuiOutlinedInput-root": {
        height: 40,
        minHeight: 40,
      },
    },
  },
}));
