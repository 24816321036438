import { REACT_APP_SMARTY_STREETS_API_URL } from "../../config";
import {
  Api,
  axios,
  groupBy,
  history,
  Navigation,
  timeoutAsync,
} from "../../lib";
import { v4 as uuidv4 } from "uuid";
import {
  getReferralToken,
  mapCustomQuoteValues,
  mapHomeQuoteValues,
  mapQuoteValues,
  NewDriverShape,
} from "../../utils";
import {
  adminActions,
  authClient,
  homeActions,
  uiActions,
  wizardActions,
} from "../states";
import { auto } from "./state";
import {
  CustomQuoteFormShape,
  DriversFormShape,
  SaveQuoteDataShape,
  StartFormShape,
} from "./types";

const { actions } = auto;
export const genericErrorMsg =
  "Oops Something went wrong! Please try again or contact us at 877-707-8555";

export const autoActions = {
  ...actions,
  setStartForm(values: StartFormShape, isLoggedIn) {
    return async (dispatch, getState) => {
      const { mode } = getState().wizardNoPersist;
      const { startForm } = getState().auto;
      const { settings } = getState().wizard;
      const uddCodeVerifyOn = !!settings.udd_code_verification?.value;

      dispatch(
        actions.setStartDetails({
          ...startForm,
          address: values.address,
          apt: values.apt,
        }),
      );

      if (isLoggedIn || startForm.skipLookup || !uddCodeVerifyOn) {
        dispatch(uiActions.setLoading(true));
        try {
          const UDDPayload = {
            firstName: startForm.first_name,
            middleName: startForm.middle_name || "",
            lastName: startForm.last_name,
            address: `${values.address?.street_line} ${values.apt}`.trim(),
            zip: values.address?.zipcode,
            city: values.address?.city,
            grecaptchaToken: "0",
            code: "",
          };
          const url = isLoggedIn ? "/admin/udd" : "/udd";
          const { data, status } = await authClient.post(url, UDDPayload);
          dispatch(uiActions.setLoading(false));
          if (status !== 200) {
            dispatch(
              actions.setUDDInfo({
                responseData: { drivers: [], vehicles: [] },
              }),
            );
            dispatch(wizardActions.setAddressError(""));
          } else {
            dispatch(actions.setUDDInfo(data));
            Navigation.go(`/${mode}/wizard/vehicles`);
            dispatch(wizardActions.setAddressError(""));
          }
        } catch (e) {
          dispatch(uiActions.setLoading(false));
          throw e;
        }
      } else {
        Navigation.go(`/${mode}/wizard/verify`);
      }
    };
  },
  verifyPhone(code: string, grecaptchaToken) {
    return async (dispatch, getState) => {
      const { mode } = getState().wizardNoPersist;
      const { startForm } = getState().auto;
      dispatch(uiActions.setLoading(true));
      try {
        const UDDPayload = {
          firstName: startForm.first_name,
          middleName: startForm.middle_name,
          lastName: startForm.last_name,
          address: `${startForm.address?.street_line} ${startForm.apt}`.trim(),
          zip: startForm.address?.zipcode,
          city: startForm.address?.city,
          grecaptchaToken,
          code,
        };
        const { data, status } = await Api.post("/udd", UDDPayload);
        if (status === 200) {
          dispatch(actions.setUDDInfo(data));
          dispatch(uiActions.setLoading(false));
          Navigation.go(`/${mode}/wizard/vehicles`);
        } else if (status === 403) {
          if (data.message === "Failed") {
            dispatch(uiActions.setLoading(false));
            dispatch(wizardActions.setAddressError("Invalid code"));
          } else if (data.message === "Denied") {
            dispatch(uiActions.setLoading(false));
            dispatch(uiActions.showError("Too many attempts"));
            dispatch(
              actions.setUDDInfo({
                responseData: { drivers: [], vehicles: [] },
              }),
            );
            Navigation.go(`/${mode}/wizard/vehicles`);
          }
        }
        dispatch(uiActions.setLoading(false));
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        throw e;
      }
      // dispatch(actions.setStartForm(values));
    };
  },
  // Address autocomplete
  setAddressSuggestionsLoading(loading: boolean) {
    return actions.setAddressSuggestionsLoading(loading);
  },
  //TODO: decide if we want to verify if address is legit
  setAddressQuery(query: string) {
    return async (dispatch) => {
      dispatch(actions.setAddressSuggestionsLoading(true));
      try {
        const {
          data: { suggestions },
        } = await axios.get(
          `${REACT_APP_SMARTY_STREETS_API_URL}${query}&prefer_geolocation=none`,
        );

        if (!suggestions) {
          dispatch(
            wizardActions.setAddressError(
              "Oops we can't find this address. Please try again with street name first",
            ),
          );
          await timeoutAsync(5000);
          dispatch(wizardActions.setAddressError(""));
        } else {
          dispatch(wizardActions.setAddressError(""));
          dispatch(actions.setAddressAutocompleteResults(suggestions));
        }

        dispatch(actions.setAddressSuggestionsLoading(false));
      } catch (e) {
        dispatch(actions.setAddressSuggestionsLoading(false));
        dispatch(uiActions.showError(genericErrorMsg));
        throw e;
      }
    };
  },
  // Quotes
  saveSelectedQuote(req_uid, req_txn_id) {
    return async () => {
      await Api.put(
        `/selected-quote/quote-type/auto/${req_uid}/txn/${req_txn_id}`,
      );
    };
  },
  saveWizardInfo(agentQuoted = false) {
    return async (dispatch, getState) => {
      const { auto, wizard } = getState();
      const { mode } = getState().wizardNoPersist;
      const data: SaveQuoteDataShape = {
        activeVehicleIndex: auto.activeVehicleIndex,
        activeDriverIndex: auto.activeDriverIndex,
        startForm: auto.startForm,
        vehiclesForm: auto.vehiclesForm,
        driversForm: auto.driversForm,
        discountsForm: auto.discountsForm,
        autoCustomData: auto.customData,
        req_uid: wizard.req_uid,
        contact: wizard.contactInfo,
        shopperApprovedShown: wizard.shopperApprovedShown,
        quoteType: mode,
        agentQuoted,
      };
      Api.put(`/quote-data/${wizard.req_uid}`, data);
    };
  },
  savePaymentInfo(pmtType, req_uid, values) {
    return async (dispatch) => {
      let url, postBody;
      if (pmtType === "ach") {
        url = "/payment/ach";
        postBody = {
          req_uid,
          name_on_account: values.account_name,
          routing_number: values.aba_number,
          account_number: values.acct_number,
          bill_first_name: values.first_name,
          bill_last_name: values.last_name,
          bill_street: values.addr1,
          bill_city: values.city,
          bill_state: values.state,
          bill_zip: values.zip,
        };
      } else {
        console.error("defaulting to ACH if nothing is selected");
        url = "/payment/cc";
        postBody = {
          req_uid,
          name_on_account: values.account_name,
          creditcard_number: values.cc_number,
          expiration: values.expiry_date_str,
          cvc: values.cvv,
          bill_first_name: values.first_name,
          bill_last_name: values.last_name,
          bill_street: values.addr1,
          bill_city: values.city,
          bill_state: values.state,
          bill_zip: values.zip,
        };
      }

      const { status } = await Api.post(url, postBody);
      //TODO: handle for success and failure
      if (status !== 200) {
        dispatch(
          uiActions.showError("We are having issues with your payment."),
        );
      }
    };
  },
  setQuotesSubmit(is_linked = false, req_uid = "") {
    return async (dispatch, getState) => {
      const { startForm, vehiclesForm, driversForm, discountsForm } =
        getState().auto;
      const { contactInfo: contact } = getState().wizard;
      const { mode } = getState().wizardNoPersist;
      const quotePayload = mapQuoteValues({
        startForm,
        vehiclesForm,
        contact,
        driversForm,
        discountsForm,
        is_linked,
      });
      if (mode === "auto" || is_linked) {
        dispatch(uiActions.setLoading(true));
        dispatch(uiActions.setLoadingMessage("Loading quotes..."));
      } else {
        dispatch(autoActions.setQuotesLoading(true));
      }

      try {
        const {
          data: { req_uid: id },
          status,
        } = await Api.post(`/auto-quote`, {
          ...quotePayload,
          req_uid,
          agentQuoted: false,
        });
        if (status === 200) {
          dispatch(wizardActions.setReqUid(id));
          dispatch(autoActions.saveWizardInfo());
          await dispatch(wizardActions.getQuotes(id, "auto", is_linked));
        } else {
          history.push("/hit-snag");
        }
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.setLoadingMessage(null));
        dispatch(autoActions.setQuotesLoading(false));
        return id;
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.setLoadingMessage(null));
        dispatch(uiActions.showError(genericErrorMsg));
        throw e;
      }
    };
  },
  setCustomQuoteForm(values: CustomQuoteFormShape, is_linked = false) {
    return async (dispatch, getState) => {
      dispatch(wizardActions.setCustomQuotesLoading(true));
      dispatch(autoActions.setPlaceHolderCustomQuote(is_linked));
      const { startForm, vehiclesForm, driversForm, discountsForm } =
        getState().auto;
      const { contactInfo: contact, req_uid } = getState().wizard;
      const quotePayload = mapCustomQuoteValues({
        ...values,
        contact,
        startForm,
        vehiclesForm,
        driversForm,
        discountsForm,
        is_linked,
      });

      try {
        const {
          data: { req_uid: id },
          status,
        } = await Api.post(`/auto-quote`, {
          ...quotePayload,
          req_uid,
        });
        if (status === 200) {
          await dispatch(wizardActions.getQuotes(id, "auto", is_linked));
          dispatch(autoActions.saveWizardInfo());
        } else {
          history.push("/hit-snag");
        }
        dispatch(wizardActions.setCustomQuotesLoading(false));
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.setLoadingMessage(null));

        return id;
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.setLoadingMessage(null));
        dispatch(wizardActions.setCustomQuotesLoading(false));
        dispatch(uiActions.showError(genericErrorMsg));
        throw e;
      }
    };
  },

  setAgentCustomForm(values: CustomQuoteFormShape) {
    const req_uid = uuidv4();

    return async (dispatch, getState) => {
      const { user } = getState().auth;
      const referrer = getReferralToken(user);

      dispatch(uiActions.setLoading(true));
      dispatch(uiActions.setLoadingMessage("Loading quotes..."));
      const { startForm, vehiclesForm, driversForm, discountsForm } =
        getState().auto;
      const { contactInfo: contact } = getState().wizard;
      const quotePayload = mapCustomQuoteValues({
        ...values,
        contact,
        startForm,
        vehiclesForm,
        driversForm,
        discountsForm,
        is_linked: false,
      });
      try {
        const { status } = await Api.post(`/auto-quote`, {
          ...quotePayload,
          req_uid,
          agentQuoted: true,
          referrer,
        });
        if (status === 200) {
          await dispatch(wizardActions.setReqUid(req_uid));
          dispatch(autoActions.saveWizardInfo(true));
          dispatch(adminActions.getNewAgentQuotes(req_uid));
        } else {
          history.push("/something-went-wrong");
          dispatch(uiActions.setLoading(false));
        }
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.setLoadingMessage(null));
        dispatch(uiActions.showError(genericErrorMsg));
        throw e;
      }
    };
  },
  setAgentBundleQuote(values: CustomQuoteFormShape) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      dispatch(uiActions.setLoadingMessage("Loading quotes..."));
      const req_uid = uuidv4();
      const { startForm, vehiclesForm, driversForm, discountsForm } =
        getState().auto;
      const { contactInfo: contact } = getState().wizard;
      const { user } = getState().auth;
      const referrer = getReferralToken(user);
      const autoQuotePayload = mapCustomQuoteValues({
        ...values,
        contact,
        startForm,
        vehiclesForm,
        driversForm,
        discountsForm,
        is_linked: false,
      });

      const { start, insured, details, discounts, customData } =
        getState().home;
      const { mode } = getState().wizardNoPersist;

      const homeQuotePayload = mapHomeQuoteValues({
        start,
        insured,
        details,
        discounts,
        contact,
        custom_quote: true,
        customData,
        is_linked: false,
        mode,
      });

      try {
        const { status } = await Api.post(`/auto-quote`, {
          ...autoQuotePayload,
          req_uid,
          agentQuoted: true,
          referrer,
        });
        dispatch(wizardActions.setReqUid(req_uid));
        dispatch(autoActions.saveWizardInfo(true));
        if (status === 200) {
          autoQuotePayload.is_linked = true;
          await Api.post(`/auto-quote`, {
            ...autoQuotePayload,
            req_uid,
            agentQuoted: true,
          });

          const urlMode = mode === "bundle" ? "home" : "renters";
          dispatch(homeActions.saveWizardInfo(true));
          Api.post(`/${urlMode}-quote`, {
            ...homeQuotePayload,
            req_uid,
            agentQuoted: true,
          });
          homeQuotePayload.is_linked = true;
          Api.post(`/${urlMode}-quote`, {
            ...homeQuotePayload,
            req_uid,
            agentQuoted: true,
          });

          dispatch(adminActions.getNewAgentQuotes(req_uid));
        } else {
          history.push("/something-went-wrong");
          dispatch(uiActions.setLoading(false));
        }
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.setLoadingMessage(null));
        dispatch(uiActions.showError(genericErrorMsg));
        throw e;
      }
    };
  },
  // Vehicle Lookup
  vehicleLookup(values: { VIN: number; index: number }) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { VIN, index } = values;
      let response: any = {};
      if (VIN) {
        const { data: vinData, status } = await Api.get(
          `/vehiclelookup/vin/${VIN}`,
        );
        if (status !== 200) {
          dispatch(
            autoActions.setVehicleLookupError(
              "Looks like we couldn't locate your vehicle. Please tell us about your car.",
            ),
          );
        } else {
          response = {
            ...response,
            ...vinData,
          };
          const lookupData = {
            ModelYear: response.year,
            Maker: response.make,
            Model: response.model,
            Trim: response.bodyType,
          };

          dispatch(actions.setSelectedVehicleData({ lookupData, index }));
          dispatch(autoActions.setVehicleLookupError(""));
        }
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getMakeByYear({ ModelYear, index }: { ModelYear: number; index: number }) {
    return async (dispatch) => {
      dispatch(actions.setMakesLoading(true));

      const { data: makes, status } = await Api.get(
        `/vehiclelookup/${ModelYear}`,
      );
      // Make based on selected year and may wary between selected vehicles, so should be saved and taken from selected vehicle state level
      if (status === 200) {
        dispatch(actions.setMakes({ makes, index }));
      } else {
        dispatch(
          uiActions.showError(
            "Oops Something went wrong! We couldn't load list of vehicle makes, please refresh the page and try again.",
          ),
        );
      }
      dispatch(actions.setMakesLoading(false));
    };
  },
  getModel({
    ModelYear,
    Maker,
    index,
  }: {
    ModelYear: number;
    Maker: string;
    index: number;
  }) {
    return async (dispatch) => {
      dispatch(actions.setModelsLoading(true));

      const { data: models, status } = await Api.get(
        `/vehiclelookup/${ModelYear}/${Maker}`,
      );
      // Model based on selected year and make and may wary between selected vehicles, so should be saved and taken from selected vehicle state level
      if (status === 200) {
        dispatch(actions.setModels({ models, index }));
      } else {
        dispatch(
          uiActions.showError(
            "Oops Something went wrong! We couldn't load list of vehicle make models, please refresh the page and try again.",
          ),
        );
      }
      dispatch(actions.setModelsLoading(false));
    };
  },

  // Other meta
  getIndustriesOccupations() {
    return async (dispatch) => {
      dispatch(actions.setIndustriesOccupationsLoading(true));

      try {
        const { data: industriesOccupations } = await Api.get(
          `/industriesoccupations`,
        );
        const transformedIndustriesOccupations = industriesOccupations.map(
          (industriesOccupation) => {
            const textArray = industriesOccupation.Text.split(" - ");
            return {
              ...industriesOccupation,
              industry: textArray[0],
              occupation: textArray[1],
            };
          },
        );
        const groupedByIndustry = groupBy(
          transformedIndustriesOccupations,
          "industry",
        );
        const industriesArray = Object.keys(groupedByIndustry);

        dispatch(actions.setIndustriesOccupations(groupedByIndustry));
        dispatch(actions.setIndustries(industriesArray));
        dispatch(actions.setIndustriesOccupationsLoading(false));

        return groupedByIndustry;
      } catch (e) {
        dispatch(
          uiActions.showError(
            "Oops Something went wrong! We couldn't load list of industries and occupations.",
          ),
        );
        dispatch(actions.setIndustriesOccupationsLoading(false));
        throw e;
      }
    };
  },
  setVehicle({ vehicle, index }) {
    return async (dispatch, getState) => {
      const state = getState().auto;

      const submittedVehiclesFromState =
        state.vehiclesForm?.submittedVehicles || [];
      const isVehicleAlreadySubmitted = Boolean(
        submittedVehiclesFromState[index],
      );

      const selectedVehicles = state.vehiclesForm?.selectedVehicles?.map(
        (v, i) => (i === index ? { ...v, ...vehicle } : v),
      );
      let submittedVehicles = submittedVehiclesFromState.map((v, i) =>
        i === index ? { ...v, ...vehicle } : v,
      );

      if (!isVehicleAlreadySubmitted) {
        submittedVehicles = [...submittedVehicles, vehicle];
      }

      //only add additional vehicle if on last vehicle page
      if (vehicle.moreVehicles && index + 1 === selectedVehicles?.length) {
        selectedVehicles?.splice(selectedVehicles?.length, 0, {});
        if (selectedVehicles) {
          selectedVehicles[index].moreVehicles = false;
          selectedVehicles[index].isLastVehicle = false;
        }
      }

      const vehiclesForm = {
        ...state.vehiclesForm,
        selectedVehicles,
        submittedVehicles,
      };
      dispatch(actions.setVehicle({ vehiclesForm }));
      return vehiclesForm;
    };
  },
  setDriver({ driver, index }) {
    return async (dispatch, getState) => {
      const driversForm: DriversFormShape = getState().auto.driversForm;

      const selectedDrivers = [...driversForm.selectedDrivers];
      selectedDrivers[index] = { ...driver, isSubmitted: true };

      const applicantIsMarried =
        driversForm.applicantIndex !== undefined &&
        selectedDrivers[driversForm.applicantIndex]?.marital_status === "M";
      const onLastDriver = index + 1 === selectedDrivers?.length;

      //add additional drivers (before spouse)
      if (driver.moreDrivers) {
        const newDriver = {
          ...NewDriverShape(),
          isUserEntered: true,
          isChecked: true,
        };
        selectedDrivers?.splice(selectedDrivers?.length, 0, newDriver);
        //prevent additional drivers being added (if user goes back)
        selectedDrivers[index].moreDrivers = false;
      }
      //add spouse
      else if (onLastDriver) {
        if (applicantIsMarried) {
          //if no spouse,
          const spouseDriver = selectedDrivers.filter(
            (d) => d.relationship_to_applicant === "S" || d.spouse,
          );
          if (!spouseDriver.length) {
            const newSpouseDriver = {
              ...NewDriverShape(),
              spouse: true,
              isUserEntered: true,
              isChecked: true,
            };
            selectedDrivers?.splice(
              selectedDrivers?.length,
              0,
              newSpouseDriver,
            );
          }
        }
      }
      await dispatch(actions.setSelectedDrivers(selectedDrivers));

      if (applicantIsMarried) {
        //if applicant changes a drivers relationship to spouse after we added additional spouse driver,
        //remove additional spouse driver if not filled
        const relationshipSpouseDriver = selectedDrivers.findIndex(
          (d) => d.relationship_to_applicant === "S",
        );
        const addedEmptySpouse = selectedDrivers.findIndex(
          (d) => d.spouse && !d.dob,
        );
        if (relationshipSpouseDriver > -1 && addedEmptySpouse > -1) {
          await dispatch(
            autoActions.removeDriverAndUpdateAppIndex(addedEmptySpouse),
          );
        }
      }
      //if applicant changes selection to not married, remove added spouse
      if (!applicantIsMarried) {
        const spouse = selectedDrivers.findIndex(
          (d) => d.spouse && d.dob === "",
        );
        if (spouse > -1) {
          await dispatch(autoActions.removeDriverAndUpdateAppIndex(spouse));
        }
      }
      const updatedSelectedDrivers =
        getState().auto.driversForm.selectedDrivers;
      return updatedSelectedDrivers;
    };
  },
  submitForm(values) {
    return async () => {
      const { data, status } = await Api.post(`/form`, values);
      if (status !== 204) {
        console.error(data);
      }
    };
  },
};
