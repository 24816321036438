import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors, authSelectors } from "../../state";
import { useStyles } from "./ModalAgentTransfer.styles";

export default function ModalAgentTransfer() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isOpen = useSelector(adminSelectors.agentTransferModalOpen);
  const { applicant } = useSelector(adminSelectors.activeQuote);
  const user = useSelector(authSelectors.user);
  const role = useSelector(authSelectors.activeRole);
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const agents = useSelector(adminSelectors.agents);

  const [selectedAgent, setSelectedAgent] = useState("");
  useEffect(() => {
    if (!role || !agencyId) return;
    if (
      isOpen &&
      (role === "super_admin" ||
        role === "sales_admin" ||
        role === "sales_director")
    ) {
      dispatch(
        adminActions.getAgents(
          role,
          user.sales_director[0]?.agency_groups_id,
          agencyId,
        ),
      );
    }
  }, [isOpen]);

  const handleCloseModal = () => {
    dispatch(adminActions.setEmailModalOpen(false));
    dispatch(adminActions.setAgentTransferModalOpen(false));
    setSelectedAgent("");
  };

  const handleTransferAgent = () => {
    dispatch(
      adminActions.transferQuoteOwnership(
        applicant.req_uid,
        selectedAgent,
        role,
        agencyId,
      ),
    );
    dispatch(adminActions.setAgentTransferModalOpen(false));
    setSelectedAgent("");
  };

  return (
    <Fade in={!!isOpen}>
      <div>
        <Modal open={!!isOpen} onClose={() => handleCloseModal()}>
          <Box className={classes.box}>
            <>
              <Box className={classes.transferWrapper}>
                <Typography className={classes.transferTitle}>
                  Transfer application ownership
                </Typography>
                <Typography className={classes.transferText}>
                  Assign ownership of this application to another agent. The new
                  owner will be notified via email.
                </Typography>
                <div>
                  <TextField
                    select
                    variant="outlined"
                    fullWidth
                    className={classes.transferDropdown}
                    label="Select an agent"
                    id="status"
                    name="sub_status"
                    value={selectedAgent}
                    onChange={(e) => setSelectedAgent(e.target.value)}
                    size="small"
                  >
                    {agents.map((a, i) => (
                      <MenuItem key={i} value={a.referral_token}>
                        {a.user.last_name + " " + a.user.first_name} -{" "}
                        {a.agency.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <Box className={classes.transferButtons}>
                  <Button
                    className={classes.cancelBtn}
                    size="small"
                    onClick={() => {
                      dispatch(adminActions.setAgentTransferModalOpen(false));
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.selectBtn}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!selectedAgent}
                    onClick={() => handleTransferAgent()}
                  >
                    Transfer
                  </Button>
                </Box>
              </Box>
            </>
          </Box>
        </Modal>
      </div>
    </Fade>
  );
}
