import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  searchCard: {
    padding: 32,
    minHeight: 220,
  },
  searchField: {
    marginTop: 16,
    // width: 340,
  },
  editPmtLink: {
    marginRight: 16,
  },
  formCard: {
    borderRadius: 0,
    marginBottom: "2em",
    width: "50%",
    margin: "0 auto",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  formWrap: {
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formControl: {
    width: "50%",
    minWidth: 330,
    padding: "0 2em 2em",
  },
  agencies: {
    width: "50%",
    minWidth: 425,
    padding: "0 2em 0 2em ",
  },
  formAction: {
    paddingRight: "2em",
  },
  chip: {
    margin: 2,
  },
  selectedAgenciesWrapper: {
    padding: "0 2em 2em",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  selectedAgencies: {
    marginTop: 10,
    display: "flex",
    flexWrap: "wrap",
  },
  label: {
    marginLeft: 30,
  },
}));
