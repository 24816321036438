import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Typography,
  Box,
  FormHelperText,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { formatPhone, useFormik, yup } from "../../lib";

import { useStyles } from "./AddApplicationPage.styles";
import { ToggleButtonGroup, ToggleButton, Autocomplete } from "@mui/material";
import { INSURANCE_TYPES } from "../../state/admin/types";
import { QUOTE_STATUSES } from "../../state/auto/types";
import { ArrowBackIcon } from "../../components";

import CurrencyInput from "react-currency-input-field";
import { useHistory } from "react-router-dom";
import { ensureHttps } from "../../utils";
import { DatePicker } from "@mui/x-date-pickers";

const validationSchema = yup.object({
  first_name: yup.string().required("Field is required"),
  last_name: yup.string().required("Field is required"),
  phone: yup.string().required("Field is required"),
  manual_quote_type: yup.string().required("Field is required"),
  company_id: yup.string().required("Field is required"),
  total_premium: yup.string().required("Field is required"),
  effective_date: yup.string().when("sub_status", {
    is: "Won",
    then: yup.string().required("Field is required"),
  }),
  manual_carrier_name: yup.string().when("company_id", {
    is: "other",
    then: yup.string().required("Field is required"),
  }),
  status: yup.string().required("Field is required"),
  quote_url: yup.string().required("Field is required"),
  comment: yup.string().required("Field is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Field is required"),
});
const initialValues: any = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  manual_quote_type: "",
  company_id: "",
  total_premium: "",
  effective_date: "",
  status: "",
  sub_status: "",
  fullStatus: "",
  existingCustomer: false,
  quote_url: "",
  comment: "",
  manual_carrier_name: "",
  addr1: "",
  city: "",
  state: "",
  zip: "",
};

const AddApplicationPage = (props) => {
  const classes = useStyles();
  const inputRef: any = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const referralToken = useSelector(authSelectors.referralToken);
  const { applicant, quotes } = useSelector(adminSelectors.activeQuote);
  const manualCarriers = useSelector(adminSelectors.manualCarriers);
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const {
    match: {
      params: { req_uid },
    },
  } = props;

  useEffect(() => {
    dispatch(adminActions.getManualCarriers());
    if (req_uid) {
      dispatch(adminActions.getQuoteForAgent(req_uid, activeRole, agencyId));
    }
  }, []);

  const handleSearchBoxChange = async (e, val, reason) => {
    if (e && reason !== "reset") {
      // setValue(val);
    } else {
      setSearchResults([]);
      inputRef?.current?.blur();
    }

    if (!activeRole || !agencyId) {
      console.warn("active role and active agency id is not defined");
      return;
    }
    if (e?.target.value && val) {
      const results: any = await dispatch(
        adminActions.searchQuotes(e.target.value, activeRole, agencyId),
      );
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  };

  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    async onSubmit(values) {
      if (!activeRole || !agencyId) {
        console.warn("active role and active agency id is not defined");
        return;
      }

      const appValues = {
        ...values,
        referrer: referralToken,
        agency_id: agencyId,
        effective_date: new Date(values.effective_date),
        quote_url: ensureHttps(values.quote_url),
      };
      if (appValues.company_id === "other") {
        appValues.company_id = null;
      } else {
        appValues.manual_carrier_name = "";
      }
      if (req_uid) {
        dispatch(
          adminActions.editApplication(
            req_uid,
            appValues,
            activeRole,
            agencyId,
          ),
        );
      } else {
        dispatch(
          adminActions.createApplication(appValues, activeRole, agencyId),
        );
      }
    },
  });

  const setFormFields = (applicant) => {
    const fields = [
      "first_name",
      "last_name",
      "email",
      "phone",
      "addr1",
      "city",
      "state",
      "zip",
    ];
    form.setFieldValue("existingCustomer", true);
    fields.forEach((field) => {
      form.setFieldValue(`${field}`, applicant[field]);
    });
  };

  useEffect(() => {
    if (req_uid && applicant && quotes[0]) {
      form.setFieldValue("existingCustomer", true);
      Object.keys(initialValues).map((key) => {
        if (applicant[key]) {
          form.setFieldValue(`${key}`, applicant[key]);
        }
      });
      Object.keys(initialValues).map((key) => {
        if (quotes[0][key]) {
          form.setFieldValue(`${key}`, quotes[0][key]);
        }
        form.setFieldValue(
          "company_id",
          quotes[0].carrier?.company_id || "other",
        );
      });
      form.setFieldValue(
        "fullStatus",
        QUOTE_STATUSES.findIndex((s) => s.sub_status === applicant.sub_status),
      );
    }
  }, [applicant, quotes]);

  useEffect(() => {
    const index = form.values.fullStatus;
    const fullStatus = QUOTE_STATUSES[index];

    form.setFieldValue("status", fullStatus?.status || "");
    form.setFieldValue("sub_status", fullStatus?.sub_status || "");
  }, [form.values.fullStatus]);

  useEffect(() => {
    if (props.location.search.includes("existing")) {
      setFormFields(applicant);
    }
  }, []);

  const setForm = (applicant) => {
    if (!applicant) return;
    setFormFields(applicant);
  };

  const setCustomer = (e, value) => {
    form.setFieldValue("existingCustomer", value);
    setForm("");
  };

  const onApplicationSelected = (e, val) => {
    if (val) {
      setForm(val);
    } else {
      setForm(val);
      return;
    }
  };

  return (
    <>
      <Box className={classes.backNavigationWrapper}>
        <div onClick={() => history.goBack()}>
          <ArrowBackIcon className={classes.backNavigationIcon} />
        </div>
        <Typography>{req_uid ? "Edit" : "Add manual"} application</Typography>
      </Box>
      <Box className={classes.container}>
        <Typography className={classes.header}>
          Applicant information
        </Typography>
        <form onSubmit={form.handleSubmit}>
          <ToggleButtonGroup
            className={classes.buttons}
            exclusive
            size="large"
            value={form.values.existingCustomer}
            onChange={setCustomer}
            id="existingCustomer"
          >
            <ToggleButton
              disabled={!!req_uid}
              value={false}
              className={classes.switcher}
            >
              New customer
            </ToggleButton>
            <ToggleButton
              disabled={!!req_uid}
              value={true}
              className={classes.switcher}
            >
              Existing customer
            </ToggleButton>
          </ToggleButtonGroup>
          <Grid container className={classes.formCard}>
            {!!form.values.existingCustomer && !req_uid && (
              <Grid item className={classes.autoComplete}>
                <Autocomplete
                  filterOptions={(x) => x}
                  onChange={onApplicationSelected}
                  className={classes.root}
                  options={searchResults?.length ? searchResults : []}
                  onInputChange={handleSearchBoxChange}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.key}>
                        {`${option.first_name} ${option.last_name} - ${option.email}`}
                      </li>
                    );
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          className: classes.textFieldLabel,
                        }}
                        inputRef={inputRef}
                        label="Applicant"
                        size="small"
                      ></TextField>
                    );
                  }}
                />
              </Grid>
            )}
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="first_name"
                name="first_name"
                label="First Name"
                value={form.values.first_name}
                onChange={form.handleChange}
                error={
                  form.touched.first_name && Boolean(form.errors.first_name)
                }
                helperText={form.touched.first_name && form.errors.first_name}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="last_name"
                name="last_name"
                label="Last Name"
                value={form.values.last_name}
                onChange={form.handleChange}
                error={form.touched.last_name && Boolean(form.errors.last_name)}
                helperText={form.touched.last_name && form.errors.last_name}
              />
            </Grid>

            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                id="email"
                name="email"
                label="Email address"
                onChange={form.handleChange}
                error={form.touched.email && Boolean(form.errors.email)}
                value={form.values.email}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                name="phone"
                id="phone"
                label="Phone number"
                variant="outlined"
                value={form.values.phone ? formatPhone(form.values.phone) : ""}
                onChange={form.handleChange}
                error={form.touched.phone && Boolean(form.errors.phone)}
                helperText={form.touched.phone && form.errors.phone}
              ></TextField>
            </Grid>
          </Grid>

          <Typography className={classes.header}>Policy information</Typography>
          <Grid container className={classes.formCard}>
            <Grid item className={classes.formControl}>
              <TextField
                select
                fullWidth
                variant="outlined"
                id="manual_quote_type"
                name="manual_quote_type"
                label="Insurance type"
                onChange={form.handleChange}
                error={
                  form.touched.manual_quote_type &&
                  Boolean(form.errors.manual_quote_type)
                }
                helperText={
                  form.touched.manual_quote_type &&
                  form.errors.manual_quote_type
                }
                value={form.values.manual_quote_type}
              >
                {INSURANCE_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                select
                fullWidth
                name="company_id"
                id="company_id"
                label="Carrier"
                variant="outlined"
                value={form.values.company_id}
                onChange={form.handleChange}
                error={
                  form.touched.company_id && Boolean(form.errors.company_id)
                }
                helperText={form.touched.company_id && form.errors.company_id}
              >
                {manualCarriers.map((c) => (
                  <MenuItem
                    key={c.id}
                    value={c.company_id}
                    className={classes.carriers}
                  >
                    {c.name}{" "}
                    <img
                      style={{ maxWidth: 45, maxHeight: 30 }}
                      src={c.logo_full_url}
                    ></img>
                  </MenuItem>
                ))}
                <MenuItem
                  key={"other"}
                  value={"other"}
                  className={classes.carriers}
                >
                  Other
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item className={classes.formControl}>
              <CurrencyInput
                style={{
                  borderColor:
                    form.errors.total_premium && form.touched.total_premium
                      ? "#f44336"
                      : "#AFB6D4",
                }}
                className={classes.priceInput}
                prefix="$"
                name="total_premium"
                value={form.values.total_premium}
                onValueChange={(val) =>
                  form.setFieldValue("total_premium", val)
                }
                id="price-input"
                placeholder="Premium amount (annually)"
              />
              {Boolean(
                form.errors.total_premium && form.touched.total_premium,
              ) && (
                <FormHelperText error variant="outlined">
                  {form.errors.total_premium}
                </FormHelperText>
              )}
            </Grid>
            {form.values.company_id === "other" && (
              <Grid item className={classes.formControl}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="manual_carrier_name"
                  name="manual_carrier_name"
                  label="Carrier name"
                  value={form.values.manual_carrier_name}
                  onChange={form.handleChange}
                  error={
                    form.touched.manual_carrier_name &&
                    Boolean(form.errors.manual_carrier_name)
                  }
                  helperText={
                    form.touched.manual_carrier_name &&
                    form.errors.manual_carrier_name
                  }
                />{" "}
              </Grid>
            )}
            <Grid item className={classes.formControl}>
              <DatePicker
                format="MM/dd/yyyy"
                label="Effective date"
                value={
                  (form.values.effective_date &&
                    new Date(form.values.effective_date)) ||
                  null
                }
                onChange={(date) => {
                  form.setFieldValue("effective_date", date);
                }}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                select
                fullWidth
                variant="outlined"
                id="fullStatus"
                name="fullStatus"
                label="Application status"
                onChange={form.handleChange}
                error={form.touched.status && Boolean(form.errors.status)}
                helperText={form.touched.status && form.errors.status}
                value={form.values.fullStatus}
              >
                {QUOTE_STATUSES.map((status, i) => (
                  <MenuItem key={status.sub_status} value={i}>
                    {status.status} - {status.sub_status}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid>
            <Typography>Paste the carrier link to the policy</Typography>
            <TextField
              className={classes.link}
              variant="outlined"
              id="quote_url"
              name="quote_url"
              label="Policy link"
              value={form.values.quote_url}
              onChange={form.handleChange}
              error={form.touched.quote_url && Boolean(form.errors.quote_url)}
              helperText={form.touched.quote_url && form.errors.quote_url}
            />
          </Grid>
          <Grid className={classes.message}>
            <TextField
              fullWidth
              label="Notes"
              value={form.values.comment}
              name="comment"
              multiline
              onChange={form.handleChange}
              error={form.touched.comment && Boolean(form.errors.comment)}
              helperText={form.touched.comment && form.errors.comment}
              minRows={5}
              maxRows={5}
            />
          </Grid>

          <Grid container justifyContent="flex-start">
            <Grid item className={classes.formAction}>
              <Button color="primary" variant="contained" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default AddApplicationPage;
