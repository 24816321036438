import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { ArrowBackIcon } from "../../../../../../components/icons";
import {
  useDispatch,
  useSelector,
  autoActions,
  autoSelectors,
  wizardActions,
  wizardSelectors,
  wizardNoPersistSelectors,
  lifeActions,
  useAction,
} from "../../../../../../state";
import { useStyles } from "./BackNavigation.styles";
import { Navigation } from "../../../../../../lib";

const BackNavigation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const clearSelectedLifeQuote = useAction(lifeActions.clearSelectedLifeQuote);
  const clearLifeQuotes = useAction(lifeActions.clearLifeQuotes);
  const activeDriverIndex = useSelector(autoSelectors.activeDriverIndex);
  const activeVehicleIndex = useSelector(autoSelectors.activeVehicleIndex);
  const drivers = useSelector(autoSelectors.uddDrivers);
  const driverState = useSelector(autoSelectors.state);
  const isQuoteSelected = useSelector(wizardSelectors.isQuoteSelected) || null;
  const selectedDrivers = useSelector(autoSelectors.selectedDrivers);
  const { selectedVehicles = null } = useSelector(autoSelectors.vehiclesForm);
  const showSelectVehicleView = useSelector(
    autoSelectors.showSelectVehicleView,
  );
  const showSelectDriverView = useSelector(autoSelectors.showSelectDriverView);
  const vehicles = useSelector(autoSelectors.uddVehicles);
  const wizardActiveStep = useSelector(wizardSelectors.activeStep);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const drivers_sameState = drivers
    ? drivers.filter((d) => d.State === driverState)
    : [];

  const driversLength = drivers_sameState?.length || 0;
  const selectedVehiclesLength = selectedVehicles?.length || 0;
  const selectedDriversLength = selectedDrivers?.length || 0;
  const vehicleLength = vehicles?.length || 0;

  const handleBack = useCallback(() => {
    if (mode === "auto") {
      switch (wizardActiveStep) {
        case 1: {
          const vehicleStep =
            Boolean(selectedVehiclesLength) &&
            activeVehicleIndex + 1 <= selectedVehiclesLength;
          if (activeVehicleIndex === 0) {
            if (vehicleLength > 0 && !showSelectVehicleView) {
              dispatch(autoActions.setShowSelectVehicleView(true));
            } else {
              Navigation.go("/auto/wizard/start");
            }
            return;
          }
          if (vehicleStep) dispatch(autoActions.toPreviousVehicle());
          break;
        }
        case 2: {
          const driverStep =
            Boolean(selectedDriversLength) &&
            activeDriverIndex + 1 <= selectedDriversLength;
          if (showSelectDriverView) {
            Navigation.go("/auto/wizard/vehicles");
            return;
          }
          if (activeDriverIndex === 0) {
            dispatch(autoActions.setShowSelectDriverView(true));
            return;
          }
          if (driverStep) dispatch(autoActions.toPreviousDriver());
          break;
        }
        case 3: {
          Navigation.go("/auto/wizard/drivers");
          break;
        }
        case 4: {
          Navigation.go("/auto/wizard/discounts");
          break;
        }
        case 5:
          if (history.location.pathname.includes("auto/wizard/quotes/")) {
            dispatch(wizardActions.setOfflineChatSubmitted(false));
            dispatch(wizardActions.setPrefContactMethod(undefined));
            Navigation.go("/auto/wizard/quotes");
          } else {
            Navigation.go("/auto/wizard/contact");
          }
          break;
        default:
          history.goBack();
      }
    } else if (mode === "home") {
      switch (wizardActiveStep) {
        case 1:
          if (history.location.pathname === "/home/wizard/details/confirm") {
            Navigation.go("/home/wizard/details/form");
          } else {
            Navigation.go("/home/wizard/start");
          }
          break;
        case 2:
          Navigation.go("/home/wizard/details/confirm");
          break;
        case 3:
          Navigation.go("/home/wizard/discounts");
          break;
        case 4:
          Navigation.go("/home/wizard/insured");
          break;
        case 5:
          if (history.location.pathname.includes("/home/wizard/quotes/")) {
            Navigation.go("/home/wizard/quotes");
          } else {
            Navigation.go("/home/wizard/contact");
          }
          break;
        default:
          history.goBack();
      }
    } else if (mode === "renters") {
      switch (wizardActiveStep) {
        case 1:
          Navigation.go("/renters/wizard/start");
          break;
        case 2:
          Navigation.go("/renters/wizard/details/form");
          break;
        case 3:
          Navigation.go("/renters/wizard/discounts");
          break;
        case 4:
          Navigation.go("/renters/wizard/insured");
          break;
        case 5:
          if (history.location.pathname.includes("/renters/wizard/quotes/")) {
            Navigation.go("/renters/wizard/quotes");
          } else {
            Navigation.go("/renters/wizard/contact");
          }
          break;
        default:
          history.goBack();
      }
    } else if (mode === "life") {
      switch (wizardActiveStep) {
        case 1:
          Navigation.go("/life/wizard/start");
          break;
        case 2:
          Navigation.go("/life/wizard/insured");
          break;
        case 3:
          Navigation.go("/life/wizard/coverage");
          break;
        case 4:
          if (history.location.pathname.includes("/life/wizard/quotes/")) {
            clearSelectedLifeQuote();
            Navigation.go("/life/wizard/quotes");
          } else {
            clearLifeQuotes();
            Navigation.go("/life/wizard/contact");
          }
          break;
        default:
          history.goBack();
      }
    } else if (mode === "bundle") {
      switch (wizardActiveStep) {
        case 0: {
          switch (history.location.pathname) {
            case "/bundle/wizard/vehicles": {
              const vehicleStep =
                Boolean(selectedVehiclesLength) &&
                activeVehicleIndex + 1 <= selectedVehiclesLength;
              if (activeVehicleIndex === 0) {
                if (vehicleLength > 0 && !showSelectVehicleView) {
                  dispatch(autoActions.setShowSelectVehicleView(true));
                } else {
                  Navigation.go("/bundle/wizard/start");
                }
                return;
              }
              if (vehicleStep) dispatch(autoActions.toPreviousVehicle());
              break;
            }
            case "/bundle/wizard/drivers": {
              const driverStep =
                Boolean(selectedDriversLength) &&
                activeDriverIndex + 1 <= selectedDriversLength;
              if (showSelectDriverView) {
                Navigation.go("/bundle/wizard/vehicles");
                return;
              }
              if (activeDriverIndex === 0) {
                dispatch(autoActions.setShowSelectDriverView(true));
                return;
              }
              if (driverStep) dispatch(autoActions.toPreviousDriver());
              break;
            }
            case "/bundle/wizard/auto-discounts":
              Navigation.go("/bundle/wizard/drivers");
              break;
            case "/bundle/wizard/continue":
              Navigation.go("/bundle/wizard/auto-discounts");
              break;
            default:
              history.goBack();
          }
          break;
        }
        case 1: {
          switch (history.location.pathname) {
            case "/bundle/wizard/details/form":
              Navigation.go("/bundle/wizard/continue");
              break;
            case "/bundle/wizard/details/confirm":
              Navigation.go("/bundle/wizard/details/form");
              break;
            case "/bundle/wizard/home-discounts":
              Navigation.go("/bundle/wizard/details/confirm");
              break;
            case "/bundle/wizard/insured":
              Navigation.go("/bundle/wizard/home-discounts");
              break;
            default:
              history.goBack();
          }
          break;
        }
        case 2: {
          switch (history.location.pathname) {
            case "/bundle/wizard/contact":
              Navigation.go("/bundle/wizard/insured");
              break;
            default:
              history.goBack();
          }
          break;
        }
        case 5: {
          if (history.location.pathname.includes("/wizard/quotes/")) {
            Navigation.go("/bundle/wizard/quotes");
          } else {
            Navigation.go("/bundle/wizard/contact");
          }
          break;
        }
        default:
          history.goBack();
      }
    } else if (mode === "auto-renters") {
      switch (wizardActiveStep) {
        case 0: {
          switch (history.location.pathname) {
            case "/auto-renters/wizard/vehicles": {
              const vehicleStep =
                Boolean(selectedVehiclesLength) &&
                activeVehicleIndex + 1 <= selectedVehiclesLength;
              if (activeVehicleIndex === 0) {
                if (vehicleLength > 0 && !showSelectVehicleView) {
                  dispatch(autoActions.setShowSelectVehicleView(true));
                } else {
                  Navigation.go("/auto-renters/wizard/start");
                }
                return;
              }
              if (vehicleStep) dispatch(autoActions.toPreviousVehicle());
              break;
            }
            case "/auto-renters/wizard/drivers": {
              const driverStep =
                Boolean(selectedDriversLength) &&
                activeDriverIndex + 1 <= selectedDriversLength;
              if (showSelectDriverView) {
                Navigation.go("/auto-renters/wizard/vehicles");
                return;
              }
              if (activeDriverIndex === 0) {
                dispatch(autoActions.setShowSelectDriverView(true));
                return;
              }
              if (driverStep) dispatch(autoActions.toPreviousDriver());
              break;
            }
            case "/auto-renters/wizard/auto-discounts":
              Navigation.go("/auto-renters/wizard/drivers");
              break;
            case "/auto-renters/wizard/continue":
              Navigation.go("/auto-renters/wizard/auto-discounts");
              break;
            default:
              history.goBack();
          }
          break;
        }
        case 1: {
          switch (history.location.pathname) {
            case "/auto-renters/wizard/details/form":
              Navigation.go("/auto-renters/wizard/continue");
              break;
            case "/auto-renters/wizard/home-discounts":
              Navigation.go("/auto-renters/wizard/details/form");
              break;
            case "/auto-renters/wizard/insured":
              Navigation.go("/auto-renters/wizard/home-discounts");
              break;
            default:
              history.goBack();
          }
          break;
        }
        case 2: {
          switch (history.location.pathname) {
            case "/auto-renters/wizard/contact":
              Navigation.go("/auto-renters/wizard/insured");
              break;
            default:
              history.goBack();
          }
          break;
        }
        case 5: {
          if (history.location.pathname.includes("/wizard/quotes/")) {
            Navigation.go("/auto-renters/wizard/quotes");
          } else {
            Navigation.go("/auto-renters/wizard/contact");
          }
          break;
        }
        default:
          history.goBack();
      }
    } else {
      history.goBack();
    }
  }, [
    dispatch,
    driversLength,
    history,
    wizardActiveStep,
    selectedVehiclesLength,
    selectedDriversLength,
    activeVehicleIndex,
    activeDriverIndex,
    showSelectVehicleView,
    showSelectDriverView,
    isQuoteSelected,
    vehicleLength,
  ]);

  return (
    <>
      {!history.location.pathname.includes("/start") && (
        <ArrowBackIcon className={classes.backIcon} onClick={handleBack} />
      )}
    </>
  );
};

export default BackNavigation;
