import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import qs from "query-string";
import { KEY_PREFIX } from "redux-persist";
import { store } from "..";
// Local
import { REACT_APP_API_URL, REACT_APP_PERSIST_KEY } from "../../config";
import { USERS_API } from "../../constants";
import { AppThunk, AuthState } from "../types";
import { uiActions } from "../ui/actions";
import { auth } from "./state";

const { actions } = auth;
/** Connection used to make authorized, authenticated API calls. */
let apiClient: AxiosInstance;
export function logout(dispatch) {
  // NOTE: We could do  window.localStorage.clear(); but other JS might be
  // using localStorage, so just remove the key that our Redux app saves.
  window.localStorage.removeItem(`${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`);
  dispatch(actions.setAuthState(undefined));
}
/**
 * @param promise
 */
async function handleAuthResponse<T = any>(
  promise: Promise<AxiosResponse<T>>,
): Promise<AxiosResponse<T>> {
  let error: AxiosError<T> | undefined;
  promise = promise.catch((err) => {
    error = err;
    return err.response;
  });
  const res = await promise;
  if (error && res) {
    const status = res.status;
    if (status === 401) {
      logout(store.dispatch);
    }
    //throw error;
  }
  return res;
}
/** Client for making authenticated API calls. */
export const authClient = {
  delete(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.delete(url, config));
  },
  download() {
    return Promise.reject("TODO: Implement apiDownload.");
  },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.get<T>(url, config));
  },
  post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.post<T>(url, data, config));
  },
  put<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.put<T>(url, data, config));
  },
};
export function createApiClient(state: AuthState) {
  const config: AxiosRequestConfig = {
    baseURL: REACT_APP_API_URL,
    headers: {},
  };
  if (state && state.token) {
    config.headers.Authorization = `Bearer ${state.token}`;
  }
  config.headers["Content-Type"] = "application/json";
  apiClient = axios.create(config);
}
export const authActions = {
  ...actions,
  /** @param {any} [authResponseData] Response data to load. Optional. */
  load(authResponseData: any): AppThunk {
    let authStateFromResponse: AuthState;
    if (authResponseData) {
      const { user, token } = authResponseData;
      if (user) {
        authStateFromResponse = {
          userId: user.id,
          roles: user.user_agency?.map((ua) => ua.role),
          token,
          user: user,
        };
      }
    }
    return async (dispatch, getState) => {
      let authState: AuthState;
      if (authStateFromResponse) {
        authState = authStateFromResponse;
        dispatch(actions.setAuthState(authState));
      } else {
        authState = getState().auth;
      }
      createApiClient(authState);
    };
  },
  login(values: { email: string; password: string }): AppThunk {
    return async (dispatch) => {
      const {
        data: { user, token, roles, expiration },
      } = await axios.post("/auth/login", values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      const authState: AuthState = {
        userId: user.id,
        token,
        user,
        roles,
        tokenExpiration: expiration,
      };
      createApiClient(authState);
      dispatch(actions.setAuthState(authState));
      dispatch(authActions.checkUsersTime(user.id));
    };
  },
  checkUsersTime(id) {
    return async (dispatch) => {
      const response = await authClient.get("/server-time");
      if (!response.data) {
        return null;
      }
      const internetTime = new Date(response.data).getTime();
      const currTime = new Date().getTime();

      if (Math.abs(internetTime - currTime) > 1000 * 60 * 2) {
        console.error(`User with userId of ${id} has a clock that is off.`);
        dispatch(
          uiActions.showError(
            "Seems like your computer clock is off. Please fix. If you already updated your clock and are still getting this message, you may need to refresh your browser",
          ),
        );
      }
    };
  },
  logout(): AppThunk {
    return async (dispatch) => {
      logout(dispatch);
    };
  },
  recoverPassword(values: { email: string }): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      await axios.post("/auth/password/forgot", values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      await logout(dispatch);
    };
  },
  resetPassword(values: {
    password1: string;
    password2: string;
    token: string;
  }): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      try {
        await axios.put("/auth/password/reset", values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        await logout(dispatch);
      } catch (e) {
        dispatch(uiActions.showError("Oops Something went wrong"));
        throw e;
      }
    };
  },
  confirmAccount(values: {
    email: string;
    newPassword: string;
    token: string;
  }): AppThunk {
    return async () => {
      await axios.put("/auth/confirm-account", values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
    };
  },
  updateProfile(values: {
    userId: number;
    first_name: string;
    last_name: string;
    email: string;
  }): AppThunk {
    return async (dispatch) => {
      const {
        data: { user },
      } = await authClient.put(`${USERS_API}/${values.userId}`, values);

      const authState: AuthState = {
        userId: user.id,
        roles: user.user_agency?.map((ua) => ua.role),
        user,
      };
      dispatch(actions.setAuthState(authState));
    };
  },
  changePassword(values: {
    oldPassword: string;
    newPassword: string;
  }): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        await authClient.post("/auth/password/change", values);
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showSuccess("Password successfully changed!"));
      } catch (e: any) {
        const message = e?.response?.data?.message;
        dispatch(uiActions.setLoading(false));
        dispatch(
          uiActions.showError(
            message || "Oops Something went wrong, please try again!",
          ),
        );
        throw e;
      }
    };
  },
};

export function redirectToLogin() {
  window.location.replace(
    "/auth/login?after=" +
      encodeURIComponent(window.location.pathname + window.location.search),
  );
}

/**
 * Serializes URL params correctly for `express-openapi-validator`. See:
 * - https://github.com/axios/axios/issues/678#issuecomment-634632500
 * - https://github.com/axios/axios/blob/8a8c534a609cefb10824dec2f6a4b3ca1aa99171/lib/helpers/buildURL.js
 * - https://github.com/axios/axios/blob/59ab559386273a185be18857a12ab0305b753e50/lib/utils.js#L177
 *
 * @param params The query params.
 */
function serializeParams(params: Record<string, any>) {
  if (params instanceof URLSearchParams) {
    return params.toString();
  }
  const formattedParams = {};
  const keys = Object.keys(params);
  const { length } = keys;
  for (let i = 0; i < length; i++) {
    const key = keys[i];
    let value = params[key];
    if (value === null || value === undefined) {
      continue;
    }
    if (Object.prototype.toString.call(value) === "[object Date]") {
      // Format Dates...
      value = value.toISOString();
    } else if (typeof value === "object") {
      // Format objects and arrays...
      value = JSON.stringify(value);
    }
    formattedParams[key] = value;
  }
  // URLSearchParams does not handle arrays...
  // return new URLSearchParams(formattedParams).toString();
  return qs.stringify(formattedParams);
}

axios.defaults.paramsSerializer = serializeParams;
