import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { TextField } from "formik-mui";
import { Field, FormikValues } from "formik";
import { Form, yup, useFormik } from "../../../lib";
import {
  commercialActions,
  commercialSelectors,
  uiSelectors,
  useAction,
  useDispatch,
  useSelector,
} from "../../../state";
import { phoneRegExp } from "../../../utils";
import { AddressAsyncAutocomplete } from "../../../components";
import { useParams } from "react-router-dom";
import { RelianceLoader } from "./CommercialContinueForm";

const policyTypes = [
  {
    value: "WC",
    label: "Workers compensation",
  },
  {
    value: "BOP",
    label: "Business owners policy",
  },
  // {
  //   value: "CYBER",
  //   label: "Cyber liability",
  // },
  // {
  //   value: "CGL",
  //   label: "General liability",
  // },
];

const validationSchema = yup.object({
  policyType: yup.string().required("Required"),
  businessName: yup.string().required("Required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email address is required"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  address: yup.object({
    zipcode: yup.string(),
    state: yup.string(),
    city: yup.string(),
    street_line: yup.string(),
    label: yup.string().required("Address is required"),
  }),
});

const PolicyTypeSelect = ({ form }) => (
  <FormControl sx={{ width: "300px", height: "48px" }}>
    <InputLabel id="policy-type-label">Policy type</InputLabel>
    <Select
      label="Policy type"
      value={form.values.policyType || ""}
      onChange={(e) => form.setFieldValue("policyType", e.target.value)}
    >
      {policyTypes.map((t) => (
        <MenuItem value={t.value} key={t.value}>
          {t.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export const CommercialStart = () => {
  const dispatch = useDispatch();
  const setApplicationFields = useAction(
    commercialActions.setApplicationFields,
  );
  const params: { id: string } = useParams();
  const applicationData = useSelector(commercialSelectors.applicationData);
  const isLoading = useSelector(uiSelectors.loading);
  const applicant = applicationData.applicant || {};
  const details: FormikValues = {
    policyType: applicant.commercial_type,
    businessName: applicant.business_name,
    email: applicant.email,
    phone: applicant.phone,
    firstName: applicant.first_name,
    lastName: applicant.last_name,
  };

  useEffect(() => {
    dispatch(commercialActions.resetState());
    dispatch(commercialActions.getApplicationData(params.id));
  }, []);

  // useEffect(() => {
  //   dispatch(commercialActions.resetState());
  //   dispatch(commercialActions.getApplicationData(params.id));
  // }, [applicationData]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: details,
    validationSchema,
    async onSubmit(values, { setSubmitting }) {
      await setApplicationFields(values);
      dispatch(commercialActions.startApplication(values, params.id));
      setSubmitting(false);
    },
  });
  return isLoading ? (
    <RelianceLoader />
  ) : (
    <Form form={form}>
      <Typography variant="h4" sx={{ margin: "20px 0" }}>
        Start application
      </Typography>
      <Box
        sx={{ maxWidth: "650px", display: "flex", flexWrap: "wrap", gap: 2 }}
      >
        <Field
          component={TextField}
          name="firstName"
          value={form.values.firstName}
          label="First name"
          variant="outlined"
          sx={{ width: "300px" }}
        />
        <Field
          component={TextField}
          name="lastName"
          label="Last name"
          variant="outlined"
          sx={{ width: "300px" }}
        />
        <Field
          component={TextField}
          name="phone"
          label="Phone"
          variant="outlined"
          sx={{ width: "300px" }}
        />
        <Field
          component={TextField}
          name="email"
          label="Email"
          variant="outlined"
          sx={{ width: "300px" }}
        />
        <Grid
          container
          sx={{ maxWidth: "616px", gap: 1, justifyContent: "space-between" }}
        >
          <Grid item md={9} xs={12}>
            <AddressAsyncAutocomplete form={form} />
          </Grid>
          <Grid item md={2.5} xs={12}>
            <Field
              component={TextField}
              name="apt"
              label="Apt/Unit#"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Field
          component={TextField}
          name="businessName"
          label="Business name"
          variant="outlined"
          sx={{ width: "300px" }}
        />
        <Field
          component={() => <PolicyTypeSelect form={form} />}
          name="policyType"
        />
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!form.values.address?.label}
          type="submit"
          sx={{ height: 44, width: 146 }}
        >
          Start
        </Button>
      </Box>
    </Form>
  );
};
