import React, { useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import clsx from "clsx";
import { useStyles } from "./Quotes.styles";
import {
  useSelector,
  wizardSelectors,
  wizardNoPersistSelectors,
} from "../../../state";

export const QuoteButtons = (p: any) => {
  const classes = useStyles();
  const {
    goToSlide,
    quotes,
    carouselState: { currentSlide },
  } = p;
  const [value, setValue] = React.useState(0);
  const activeQuoteCard = useSelector(wizardSelectors.activeQuoteCard);
  const mode = useSelector(wizardNoPersistSelectors.mode);

  const haveLowest = quotes.find((quote) => quote.insurance_level_id === 1);
  const haveRobust = quotes.find((quote) => quote.insurance_level_id === 2);
  const haveSuper = quotes.find((quote) => quote.insurance_level_id === 3);
  const haveCustom = quotes.find((quote) => quote.insurance_level_id === 0);

  const handleSwitch = (level) => {
    goToSlide(level);
    setValue(level);
  };

  useEffect(() => {
    if (currentSlide !== undefined) {
      setValue(currentSlide);
    }
  }, [currentSlide]);

  useEffect(() => {
    if (activeQuoteCard === 0) {
      handleSwitch(quotes.length - 1);
    }
  }, [activeQuoteCard, quotes]);
  useEffect(() => {
    handleSwitch(1);
  }, []);

  return (
    <div
      className={clsx(
        classes.carouselButtonGroup,
        mode === "bundle" || mode === "auto-renters" ? "bundleMode" : "",
      )}
    >
      {quotes.length === 4 ? (
        <Tabs value={value} variant="scrollable" scrollButtons="auto">
          <Tab label="Basic" onClick={() => handleSwitch(0)} />
          <Tab label="Robust" onClick={() => handleSwitch(1)} />
          <Tab label="Super" onClick={() => handleSwitch(2)} />
          <Tab label="Custom" onClick={() => handleSwitch(3)} />
        </Tabs>
      ) : (
        <Tabs value={value} variant="scrollable" scrollButtons="auto">
          {haveLowest && <Tab label="Basic" onClick={() => handleSwitch(0)} />}
          {haveLowest && haveRobust && (
            <Tab label="Robust" onClick={() => handleSwitch(1)} />
          )}
          {haveLowest && haveRobust && haveSuper && (
            <Tab label="Super" onClick={() => handleSwitch(2)} />
          )}

          {!haveLowest && haveRobust && (
            <Tab label="Robust" onClick={() => handleSwitch(0)} />
          )}
          {!haveLowest && haveRobust && haveSuper && (
            <Tab label="Super" onClick={() => handleSwitch(1)} />
          )}

          {!haveLowest && !haveRobust && haveSuper && (
            <Tab label="Super" onClick={() => handleSwitch(0)} />
          )}

          {haveCustom && (
            <Tab
              label="Custom"
              onClick={() => handleSwitch(quotes.length - 1)}
            />
          )}
        </Tabs>
      )}
    </div>
  );
};
