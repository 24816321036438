import React, { useRef, useState } from "react";
import { useStyles } from "./ModalUpload.styles";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { CloseIcon } from "../icons";
import { useSelector } from "react-redux";
import {
  adminActions,
  adminSelectors,
  authSelectors,
  useAction,
} from "../../state";
import { DropzoneArea } from "react-mui-dropzone";

export default function ModalUpload() {
  const setUploadModalOpen = useAction(adminActions.setUploadModalOpen);
  const isOpen = useSelector(adminSelectors.uploadModalOpen);
  const activeQuote = useSelector(adminSelectors.activeQuote);
  const role = useSelector(authSelectors.activeRole);
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const uploadDocs = useAction(adminActions.uploadDocs);
  const classes = useStyles();
  const fileUploadRef = useRef<FormData>(new FormData());
  const [fileSelected, setFileSelected] = useState(false);

  const { req_uid } = activeQuote.applicant;

  const handleCloseModal = () => {
    fileUploadRef.current = new FormData();
    setUploadModalOpen(false);
    setFileSelected(false);
  };
  const handleFileDrop = (file) => {
    fileUploadRef.current.append("file", file[0]);
    setFileSelected(true);
  };
  const handleFileDelete = () => {
    fileUploadRef.current = new FormData();
    setFileSelected(false);
  };
  const handleSubmit = async () => {
    await uploadDocs(req_uid, fileUploadRef.current, role, agencyId);
    fileUploadRef.current = new FormData();
    handleCloseModal();
  };
  return (
    <>
      <Modal open={isOpen} onClose={() => handleCloseModal()}>
        <Box className={classes.box}>
          <Box className={classes.wrapper}>
            <div className={classes.titleWrapper}>
              <Typography className={classes.title}>
                Upload supporting documents
              </Typography>

              <IconButton
                className={classes.closeBtn}
                onClick={() => handleCloseModal()}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Typography className={classes.subTitle}>
              You will not be able to remove the documents once they are
              uploaded to the application.
            </Typography>
            <DropzoneArea
              showPreviews={true}
              filesLimit={1}
              acceptedFiles={[".png", ".jpg", ".jpeg", ".svg", ".pdf"]}
              maxFileSize={25000000}
              showPreviewsInDropzone={false}
              previewText=""
              dropzoneText="Drop an image or click to browse"
              dropzoneClass={classes.dropzone}
              dropzoneParagraphClass={classes.dropzoneText}
              useChipsForPreview={true}
              showAlerts={["error"]}
              previewGridClasses={{
                container: classes.containerCustom,
                item: classes.item,
                image: classes.dropzoneImage,
              }}
              onDrop={(file) => handleFileDrop(file)}
              onDelete={() => handleFileDelete()}
            />
          </Box>
          <Box className={classes.buttons}>
            <Button
              className={classes.cancelBtn}
              size="small"
              onClick={() => handleCloseModal()}
            >
              Cancel
            </Button>
            <Button
              className={classes.selectBtn}
              variant="contained"
              color="primary"
              disabled={!fileSelected}
              onClick={() => handleSubmit()}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
