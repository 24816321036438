import { add, sub } from "date-fns";
import { formatToDate } from "../utils";

export const MILEAGE_OPTIONS = [
  { label: "0-5,000 miles", value: 2500 },
  { label: "5,000-7,500 miles", value: 6250 },
  { label: "7,500-9,999 miles", value: 8750 },
  { label: "10,000-12,499 miles", value: 11250 },
  { label: "12,500-14,999 miles", value: 13750 },
  { label: "15,000+", value: 15000 },
];

export const TICKET_CATEGORIES = [
  { label: "0-10", value: 1 },
  { label: "10-15", value: 2 },
  { label: "15-30", value: 3 },
  { label: "30+", value: 4 },
];
export const TICKET_AMOUNTS = ["None", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const TICKETS_MIN_DATE = sub(new Date(), { years: 5 });
export const CLAIM_MIN_DATE = sub(new Date(), { years: 5 });

export const BODILY_INJURY_OPTIONS = [
  { label: "$15,000 / $30,000", value: { perPerson: 15000, perAcc: 30000 } },
  { label: "$25,000 / $50,000", value: { perPerson: 25000, perAcc: 50000 } },
  { label: "$50,000 / $100,000", value: { perPerson: 50000, perAcc: 100000 } },
  {
    label: "$100,000 / $300,000",
    value: { perPerson: 100000, perAcc: 300000 },
  },
  {
    label: "$250,000 / $500,000",
    value: { perPerson: 250000, perAcc: 500000 },
  },
  {
    label: "$500,000 / $1,000,000",
    value: { perPerson: 500000, perAcc: 1000000 },
  },
  { label: "I don't know", value: {} },
];

export const DISCOUNTS_OPTIONS = [
  { label: "Active military or veteran", value: "M" },
  { label: "Paperless", value: "P" },
  { label: "Autopay", value: "A" },
];

export const MARITAL_STATUS_OPTIONS = [
  { label: "Single", value: "S" },
  { label: "Married", value: "M" },
  { label: "Divorced", value: "D" },
  { label: "Widowed", value: "W" },
];

export const DOB_MAX_DATE = sub(new Date(), { years: 16 });
export const EFFECTIVE_MIN_DATE = add(new Date(), { days: 30 });

export const predefinedQuoteFields = {
  order_credit_score: true,
  effective_date: formatToDate(add(new Date(), { days: 7 })),
};

export const predefinedDriverFields = {
  employed_date: formatToDate(sub(new Date(), { years: 2 })),
  license_status: "Valid",
};
