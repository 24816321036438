import { SmsIcon, EmailIcon, PhoneIcon } from "../components/icons";

export const ContactMethods = {
  text: {
    icon: SmsIcon,
    label: "Phone Number",
    method: "number",
    action: "text",
    button: "Text me",
  },
  call: {
    icon: PhoneIcon,
    label: "Phone Number",
    method: "number",
    action: "call",
    button: "Call me",
  },
  email: {
    icon: EmailIcon,
    label: "Email Address",
    method: "address",
    action: "email",
    button: "Email me",
  },
};
