import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { AddIcon } from "../../../components/icons";
import { Navigation, history } from "../../../lib";
import {
  adminActions,
  adminSelectors,
  useSelector,
  useDispatch,
} from "../../../state";
import { useMobile } from "../../../themes";
import { useStyles } from "./ApplicantSection.styles";
import { useParams } from "react-router";
import { Applicant } from "../../../state/wizard/types";
import { NotesSection } from "./NotesSection";
import {
  ApplicantInfo,
  LifeCoverage,
  DriversList,
  VehiclesList,
  HomeApplicants,
  SupportingDocs,
} from "./Components";

interface Props {
  applicant: Applicant;
}

export const ApplicantSection = ({ applicant }: Props) => {
  const classes = useStyles();
  const isMobile = useMobile();
  const params: Record<string, any> = useParams();
  const {
    quoteType,
    baseInfo,
    quote_documents: quoteDocuments,
    aff_agent,
    quote_comments,
    agency,
  } = useSelector(adminSelectors.activeQuote);

  const drivers = applicant.drivers ? applicant.drivers : [];
  const vehicles = applicant.vehicles; // ? applicant.vehicles : [];
  const dispatch = useDispatch();
  const quoteId = params.masterQuoteId;

  const hasCoApplicant = !!applicant.coApplicant?.first_name;
  let homeApplicants;
  if (hasCoApplicant) {
    homeApplicants = [applicant, applicant.coApplicant];
  }

  const setAsPrimary = (driver) => {
    dispatch(
      adminActions.setActiveQuoteNew({
        applicant: {
          first_name: driver.first_name,
          middle_name: driver.middle_name,
          last_name: driver.last_name,
          gender: driver.gender,
          dob: driver.dob,
          created_at: new Date(),
          req_uid: params.masterQuoteId,
        },
        domain: "",
        quotes: [],
      }),
    );
    return Navigation.go("/admin/quote-management/new");
  };

  return (
    <Box className={classes.root}>
      <ApplicantInfo
        applicant={applicant}
        baseInfo={baseInfo}
        quoteType={quoteType}
        aff_agent={aff_agent}
        agency={agency}
      />
      {!isMobile && (
        <Box className={classes.bottomSection}>
          {applicant?.effective_date && (
            <Box className={classes.contactItem}>
              <Typography className={classes.header}>
                Effective date{"  "}&nbsp;
                <span>
                  {new Date(
                    applicant.effective_date?.replace("Z", ""),
                  ).toLocaleDateString()}
                </span>
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {quoteType === "Life" && <LifeCoverage baseInfo={baseInfo} />}
      {(quoteType === "Auto" || quoteType === "Bundle") &&
        // || quoteType === "AutoRenters"
        drivers.length > 0 && (
          <DriversList drivers={drivers} setAsPrimary={setAsPrimary} />
        )}
      {!!vehicles?.length && <VehiclesList vehicles={vehicles} />}
      {(quoteType === "Home" || quoteType === "Bundle") && homeApplicants && (
        <HomeApplicants
          homeApplicants={homeApplicants}
          setAsPrimary={setAsPrimary}
        />
      )}
      {<SupportingDocs quoteDocuments={quoteDocuments} />}
      <NotesSection quoteId={quoteId} comments={quote_comments} />
      {!isMobile && (
        <Button
          color="primary"
          startIcon={<AddIcon />}
          className={classes.addButton}
          onClick={() => history.push(`/admin/create-application?existing`)}
        >
          Manual application
        </Button>
      )}
    </Box>
  );
};
