import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ApplicationQuestion } from "../../../state/commercial/types";
import { QuestionField } from "./FormField";

const handleCondition = (
  conditionMatch,
  dependency,
  value,
  setVisibleQuestions,
) => {
  const operator = conditionMatch[1];
  const conditionValue = parseInt(conditionMatch[2], 10);
  const selectedValue = parseInt(value, 10);

  let show = false;

  switch (operator) {
    case ">=":
      show = selectedValue >= conditionValue;
      break;
    case "<=":
      show = selectedValue <= conditionValue;
      break;
    case ">":
      show = selectedValue > conditionValue;
      break;
    case "<":
      show = selectedValue < conditionValue;
      break;
    case "=":
      show = selectedValue === conditionValue;
      break;
    default:
      break;
  }

  // Show/hide dependent questions based on the condition result
  dependency.childQuestionIds.forEach((childId) => {
    setVisibleQuestions((prev) => ({
      ...prev,
      [childId]: show,
    }));
  });
};
const QuestionForm = ({ questions }: { questions: ApplicationQuestion[] }) => {
  const [visibleQuestions, setVisibleQuestions] = useState(
    questions.reduce((acc, question) => {
      acc[question.questionId] = !question.hasParentQuestion;
      return acc;
    }, {}),
  );

  const handleConditionalDisplay = (questionId, value) => {
    const question = questions.find((q) => q.questionId === questionId);

    if (question && question.dependentQuestions.length > 0) {
      question.dependentQuestions.forEach((dependency) => {
        if (dependency.dependencyType === "CONDITIONAL") {
          const conditionMatch =
            dependency.condition.match(/(>=|<=|>|<|=)(\d+)/);
          if (conditionMatch) {
            handleCondition(
              conditionMatch,
              dependency,
              value,
              setVisibleQuestions,
            );
          } else {
            const show =
              value === dependency.condition ||
              dependency.condition.includes(value);
            dependency.childQuestionIds.forEach((childId) => {
              setVisibleQuestions((prev) => ({
                ...prev,
                [childId]: show,
              }));
            });
          }
        }
      });
    }
  };
  useEffect(() => {
    questions.forEach((question) => {
      if (question.defaultAnswer) {
        handleConditionalDisplay(question.questionId, question.defaultAnswer);
      }
    });
  }, [questions]);
  return (
    <Box sx={{ maxWidth: "650px" }}>
      {questions.map((question) => {
        if (!visibleQuestions[question.questionId]) return null;
        return (
          <Box key={question.questionId} sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {question.question}
            </Typography>
            <QuestionField
              question={question}
              handleConditionalDisplay={handleConditionalDisplay}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default QuestionForm;
