import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  coverageWrapper: {
    border: "1px solid #DADFF6",
    borderRadius: 3,
    marginBottom: 32,
    padding: 12,
    "& .MuiAccordionDetails-root": {
      display: "block",
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    "& .MuiAccordion-root": {
      boxShadow: "none",
      border: "none",
      [theme.breakpoints.down('sm')]: {},
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      border: "none",
      borderBottom: "1px solid #DADFF6",
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      "& .MuiAccordionSummary-root": {
        padding: "10px 16px",
      },
    },
  },
  headingTxtWrapper: {
    width: 180,
    height: 28,
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ({ level }: any) =>
      level === 1
        ? "#FFCC002B"
        : level === 2
        ? "#F376201F"
        : level === 3
        ? "#2ED27221"
        : "#D2C8E0",
    "& .MuiTypography-root": {
      fontSize: 16,
      color: ({ level }: any) =>
        level === 1
          ? "#FFCC00"
          : level === 2
          ? "#F37620"
          : level === 3
          ? "#2ED272"
          : "#AB73FA",
      [theme.breakpoints.down('md')]: {
        fontWeight: 600,
      },
    },
    [theme.breakpoints.down('md')]: {
      background: "none !important",
      justifyContent: "flex-start !important",
    },
  },
}));
