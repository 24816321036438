import React from "react";
import { Box } from "@mui/material";

import { useStyles } from "./AgencySettingsPage.styles";
import SocialProfilesCard from "./cards/SocialProfilesCard";
import SocialPreviewCard from "./cards/SocialPreviewCard";

const SocialMediaSettingsPage = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.sectionWrapper}>
        <SocialProfilesCard />
        <SocialPreviewCard />
      </Box>
    </>
  );
};

export default SocialMediaSettingsPage;
