import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Box,
  Typography,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authClient,
  uiActions,
  useDispatch,
  useSelector,
} from "../../state";
import { useFormik, yup } from "../../lib";

import { useStyles } from "./AddProductPage.styles";
import { useParams } from "react-router-dom";
import { REACT_APP_ASSETS_URL } from "../../config";
import { DropzoneArea } from "react-mui-dropzone";

const validationSchema = yup.object({
  product_name: yup.string().required("Field is required"),
  meta: yup.string().required("Field is required"),
});

const initialValues = {
  product_name: "",
  meta: "",
  category_id: -1,
  logo_path: "",
  new_category_name: "",
};

const AddProductPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const products = useSelector(adminSelectors.referralProducts);
  const params: { id } = useParams();
  const [fileDropped, setFileDropped] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [activeProduct, setActiveProduct] = useState(initialValues);

  const fileUploadRef = useRef<FormData>();

  useEffect(() => {
    const match = products.find((r) => r.id === parseInt(params.id));
    if (match) {
      setInEditMode(true);
      setActiveProduct({
        product_name: match.product_name,
        meta: match.meta,
        logo_path: match.logo_path,
        category_id: match.category_id,
        new_category_name: "",
      });
    }
  }, [products, params.id]);

  async function uploadFile() {
    if (fileDropped) {
      const { status, data } = await authClient.post(
        "/file-upload",
        fileUploadRef.current,
      );
      if (status === 200) {
        return data.path;
      } else {
        dispatch(uiActions.showError("image upload failed"));
      }
    }
    return "";
  }

  async function handleFileDrop(file) {
    fileUploadRef.current = new FormData();
    fileUploadRef.current.append("file", file[0]);
    setFileDropped(true);
  }

  async function handleFileDelete() {
    fileUploadRef.current = new FormData();
    setFileDropped(false);
  }

  function uniqueBy(arr, prop, p) {
    return arr.reduce((a, d) => {
      if (!a.find((a) => a[prop][p] === d[prop][p])) {
        a.push(d);
      }
      return a;
    }, []);
  }
  const categories = uniqueBy(products, ["referral_category"], ["name"]);
  const form = useFormik({
    enableReinitialize: true,
    initialValues: activeProduct,
    validationSchema,
    async onSubmit(values) {
      const new_path = await uploadFile();
      const productValues = {
        ...values,
        logo_path: new_path || activeProduct.logo_path,
        newCategory: form.values.category_id === 0 ? true : false,
        category_id: form.values.category_id,
      };
      if (inEditMode && form.values.category_id !== 0) {
        dispatch(adminActions.updateProduct(params.id, productValues));
      } else {
        dispatch(adminActions.createProduct(productValues));
      }
      form.resetForm();
    },
  });

  useEffect(() => {
    dispatch(adminActions.getReferralProducts());
  }, []);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                select
                fullWidth
                name="category_id"
                id="category"
                label="Category"
                variant="outlined"
                value={form.values.category_id}
                onChange={form.handleChange}
                error={
                  form.touched.category_id && Boolean(form.errors.category_id)
                }
                helperText={form.touched.category_id && form.errors.category_id}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.category_id}>
                    {category.referral_category.name}
                  </MenuItem>
                ))}
                <MenuItem key={0} value={0}>
                  New category
                </MenuItem>
              </TextField>
            </Grid>
            {form.values.category_id === 0 && (
              <Grid item className={classes.formControl}>
                <TextField
                  fullWidth
                  name="new_category_name"
                  id="new_category_name"
                  label="Category name"
                  variant="outlined"
                  value={form.values.new_category_name}
                  onChange={form.handleChange}
                ></TextField>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="product_name"
                name="product_name"
                label="Product Name"
                value={form.values.product_name}
                onChange={form.handleChange}
                error={
                  form.touched.product_name && Boolean(form.errors.product_name)
                }
                helperText={
                  form.touched.product_name && form.errors.product_name
                }
              />
            </Grid>
            <Grid item className={classes.descriptionField}>
              <TextField
                fullWidth
                variant="outlined"
                id="meta"
                name="meta"
                multiline
                minRows={10}
                label="Product description"
                value={form.values.meta}
                onChange={form.handleChange}
                error={form.touched.meta && Boolean(form.errors.meta)}
                helperText={form.touched.meta && form.errors.meta}
              />
            </Grid>
          </Grid>
          <Grid item className={classes.formControl}>
            {activeProduct.logo_path && (
              <Box className={classes.textFieldWrapper}>
                <Typography className={classes.fieldTitle}>
                  Current image
                </Typography>
                <img
                  alt="preview image"
                  src={`${REACT_APP_ASSETS_URL}/${activeProduct.logo_path}`}
                  className={classes.previewImg}
                ></img>
              </Box>
            )}
            <Box className={classes.textFieldWrapper}>
              <Typography className={classes.fieldTitle}>
                Upload product logo
              </Typography>
              <DropzoneArea
                showPreviews={true}
                filesLimit={1}
                acceptedFiles={[".png", ".jpg", ".jpeg", ".svg"]}
                maxFileSize={3000000}
                showPreviewsInDropzone={false}
                dropzoneText="Drop an image or click to browse"
                dropzoneClass={classes.dropzone}
                dropzoneParagraphClass={classes.dropzoneText}
                previewGridClasses={{
                  item: classes.dropzone,
                  image: classes.dropzoneImage,
                }}
                onDrop={(file) => handleFileDrop(file)}
                onDelete={() => handleFileDelete()}
              />
            </Box>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  form.isSubmitting ||
                  !form.values.product_name ||
                  !form.values.meta ||
                  (!fileDropped && !inEditMode)
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddProductPage;
