import React, { useEffect, useCallback } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Paper,
  Box,
  Typography,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useFormik } from "../../lib";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import { useStyles } from "./AddCoveragePage.styles";
import { states } from "../../assets/data/us_states";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const default_coverage_data = {
  bi: { perAcc: "500000", perPerson: "250000" },
  pd: { limit: "250000" },
  um: { perAcc: "500000", perPerson: "250000" },
  coll: { deductible: "500" },
  comp: { deductible: "250" },
  medex: { limit: "5000" },
  undum: { perAcc: "500000", perPerson: "250000" },
};

const levels = [
  { id: 1, name: "Low" },
  { id: 2, name: "Medium" },
  { id: 3, name: "High" },
];

const initialValues = {
  level: "",
  coverage_data: default_coverage_data,
  state: "",
  quote_type: "",
  is_auto: false,
  is_home: false,
  agency_group_id: "",
};

const AddCoveragePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const agencyGroups = useSelector(adminSelectors.agency_groups);

  const form = useFormik({
    initialValues,
    async onSubmit(values) {
      await dispatch(adminActions.createCoverage(values));
    },
  });

  useEffect(() => {
    dispatch(adminActions.setPageTitle("Add Coverage"));
    dispatch(adminActions.getAgencyGroups());
  }, [dispatch]);

  const handleSwitcherChange = useCallback(
    () => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) {
        if (value === "auto") {
          form.setFieldValue("quote_type", "auto");
          form.setFieldValue("is_auto", true);
          form.setFieldValue("is_home", false);
        } else {
          form.setFieldValue("quote_type", "home");
          form.setFieldValue("is_home", true);
          form.setFieldValue("is_auto", false);
        }
        form.setFieldValue("state", "");
      }
    },
    [form],
  );

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid item xs={8} className={classes.agencySelect}>
            <TextField
              select
              name="agency_group_id"
              label="Agency Group"
              variant="outlined"
              value={form.values.agency_group_id}
              onChange={form.handleChange}
              error={
                form.touched.agency_group_id &&
                Boolean(form.errors.agency_group_id)
              }
              helperText={
                form.touched.agency_group_id && form.errors.agency_group_id
              }
            >
              {agencyGroups.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid container>
            <Grid item xs={10} className={classes.formField}>
              <ToggleButtonGroup
                className={classes.switcherGroup}
                exclusive
                size="large"
                value={form.values.quote_type}
                onChange={handleSwitcherChange()}
              >
                <ToggleButton value={"auto"} className={classes.switcher}>
                  Auto
                </ToggleButton>
                <ToggleButton value={"home"} className={classes.switcher}>
                  Home
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                select
                id="level"
                name="level"
                label="Insurance Level"
                variant="outlined"
                value={form.values.level}
                onChange={form.handleChange}
                error={form.touched.level && Boolean(form.errors.level)}
                helperText={form.touched.level && form.errors.level}
              >
                {levels.map((level) => (
                  <MenuItem key={level.id} value={level.id}>
                    {level.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                select
                id="state"
                name="state"
                label="State"
                variant="outlined"
                disabled={form.values.quote_type === "home"}
                value={form.values.state}
                onChange={form.handleChange}
                error={form.touched.state && Boolean(form.errors.state)}
                helperText={form.touched.state && form.errors.state}
              >
                {states.map((state) => (
                  <MenuItem key={state.abbr} value={state.abbr}>
                    {state.abbr} &middot; {state.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={10} className={classes.formControl}>
              <Typography style={{ color: "#b4b4d4" }}>
                Coverage Data
              </Typography>
              <Paper>
                <Box className={classes.jsonInput}>
                  <JSONInput
                    id="coverage_data"
                    name="coverage_data"
                    label="Coverage Data"
                    placeholder={default_coverage_data}
                    value={form.values.coverage_data}
                    onChange={(e) => {
                      form.setFieldValue("coverage_data", e.jsObject);
                    }}
                    colors={{
                      default: "#484c4f",
                      background: "#eff1f6",
                      background_warning: "#FEECEB",
                      string: "#FA7921",
                      number: "#70CE35",
                      colon: "#49B8F7",
                      keys: "#1277ab",
                    }}
                    height={"100%"}
                    width={"100%"}
                    locale={locale}
                    error={
                      form.touched.coverage_data &&
                      Boolean(form.errors.coverage_data)
                    }
                    helperText={
                      form.touched.coverage_data && form.errors.coverage_data
                    }
                  ></JSONInput>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  form.values.quote_type === "home"
                    ? !(
                        form.values.level &&
                        form.values.coverage_data &&
                        form.values.agency_group_id
                      )
                    : !(
                        form.values.level &&
                        form.values.state &&
                        form.values.coverage_data &&
                        form.values.agency_group_id
                      )
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddCoveragePage;
