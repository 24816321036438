import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  statsPage: {
    width: "96%",
    height: "100%",
    "& .MuiPaper-root": {
      height: 350,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  pageHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "& h1": {
      fontSize: 20,
      fontWeight: 600,
      margin: "8px 0",
    },
    [theme.breakpoints.down('md')]: {
      display: "block",
    },
  },
  formControl: {
    marginBottom: 5,
    "& .MuiOutlinedInput-root": {
      minHeight: 52,
      width: 500,
      [theme.breakpoints.down('sm')]: {
        width: "100%",
      },
    },
  },

  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  dateWrapper: {
    display: "flex",
    paddingBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    width: "65%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  selectDateField: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      border: 0,
    },
    "& .MuiInputBase-input": {
      padding: "0 24px 0 0",
    },
    "& .MuiInputBase-root": {
      color: theme.palette.primary.main,
      textTransform: "uppercase",
    },
    "& .MuiInput-underline:before": {
      border: 0,
    },
    "& .MuiInput-underline:after": {
      border: 0,
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "white",
    },
  },
  selectField: {
    width: 550,
    "& .MuiOutlinedInput-root": {
      height: 52,
    },
  },
  toggle: {
    width: 170,
    height: 52,
    "&.MuiToggleButton-root": {
      border: "1px solid #AFB6D4",
      textTransform: "initial",
      color: "#AFB6D4",
      fontSize: 16,
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: "50%",
    },
  },
  selectWrapper: {
    display: "flex",
    flexDirection: "column",
    "& .MuiToggleButtonGroup-root": {
      marginRight: 20,
    },
  },
  viewBtn: {
    height: 52,
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
    },
  },
  insightsWrapper: {
    display: "flex",
    gap: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      "& .MuiToggleButtonGroup-root": {
        width: "100%",
      },
    },
  },
  fieldsWrapper: {
    display: "flex",
    gap: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      gap: 0,
      "& .MuiToggleButtonGroup-root": {
        width: "100%",
      },
    },
  },
  rightSection: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    marginLeft: 20,
    [theme.breakpoints.down('lg')]: {
      margin: 0,
      width: "90%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  bottomSection: { display: "flex", flexWrap: "wrap" },
  agentInfo: {
    display: "flex",
    width: "max-content",
    alignItems: "center",
    border: "1px solid #DADFF6",
    borderRadius: 3,
    margin: "20px 0",
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  statItem: {
    display: "flex",
    boxSizing: "border-box",
    width: 224,
    border: "1px solid #DADFF6",
    borderRadius: 3,
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: 15,
    },
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.primary.main,
    height: 47,
    width: 47,
    borderRadius: 3,
    backgroundColor: "rgb(233 236 246)",
  },
  statNum: {
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: 1,
  },
  statText: {
    fontSize: 16,
  },
  statWrapper: {
    marginLeft: 17,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  image: {
    height: 100,
    width: 100,
    borderRadius: "50%",
  },
  imageIcon: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      fontSize: 70,
    },
  },
  text: { fontSize: 16, fontWeight: 500, marginBottom: 15 },
  agentName: {
    fontSize: 20,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  agentData: {
    margin: "0 20px",
  },
  agentStart: {
    color: "#8D98C2",
    fontSize: 14,
  },
  contactItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "start",
    margin: 3,
    textDecoration: "auto",
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      height: 18,
      marginRight: 10,
      marginTop: 2,
    },
    "& a": {
      textDecoration: "auto",
    },
  },
  contactWrapper: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  chartTitle: {
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  totalAmounts: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0 20px 0",
    width: "65%",
    [theme.breakpoints.down('lg')]: {
      width: "100%",
      gap: 8,
      flexWrap: "wrap",
      padding: 0,
    },
  },
  totalItem: {
    width: 224,
    padding: 16,
    display: "flex",
    backgroundColor: "#DCF8E7",
    borderRadius: 3,
    [theme.breakpoints.down('sm')]: {
      width: "47%",
      padding: 0,
      height: 70,
    },
  },
  total: {
    backgroundColor: "#2eb662",
    color: "white",
    fontWeight: 600,
    fontSize: 16,
    height: 47,
    width: 47,
    borderRadius: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      height: 32,
      width: 32,
      margin: "20px 15px 20px 20px ",
    },
  },
  totalText: {
    display: "flex",
    alignItems: "center",
    padding: 12,
    fontWeight: 600,
    fontSize: 17,
    color: "#2eb662",
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      padding: 0,
    },
  },
  bottomWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      display: "initial",
    },
  },
  mobileColumns: {
    border: "1px solid #DADFF6",
    width: "65%",
    paddingRight: 20,
    [theme.breakpoints.down('lg')]: {
      textAlign: "center",
      width: "100%",
      padding: 0,
    },
  },
  mobileTitle: { fontWeight: 600, padding: 20, display: "flex" },
  mobileAgentInfo: {
    display: "flex",
    padding: "35px 0",
    borderTop: "1px solid #DADFF6",
    borderBottom: "1px solid #DADFF6",
    marginBottom: 20,
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [`& .agent-image`]: {
      borderRadius: "50%",
      width: 50,
      height: 50,
      marginRight: 8,
    },
  },
  mobileInfo: { display: "flex", alignItems: "center" },
  agentFlex: {
    display: "flex",
    flexDirection: "column",
  },
  appBar: {
    boxShadow: "none",
    margin: "20px 0",

    backgroundColor: "white",
    borderBottom: "1px solid #DADFF6",
    "& .MuiTab-root": {
      fontWeight: 400,
      textTransform: "none",
      width: 150,
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#000C3B",
      textTransform: "none",
      borderBottom: "2px solid black",
    },
  },
}));
