import React from "react";
import { useStyles } from "./ApplicantSection.styles";
import {
  PhoneIcon,
  EmailIcon,
  PlaceIcon,
  PersonIcon,
  PermContactCalendarIcon,
  AttachMoneyIcon,
  DirectionsCar,
  InsertDriveFileIcon,
  GetAppIcon,
  AddIcon,
  BusinessIcon,
  AutorenewIcon,
} from "../../../components/icons";
import CoverageIcon from "../../../assets/img/life/CoverageIcon";
import { Box, Button, Divider, Grid, Link, Typography } from "@mui/material";
import { defaultTheme, useMobile } from "../../../themes";
import { formatCurrency, replaceWhiteSpaceWithPlus } from "../../../utils";
import { formatPhone } from "../../../lib";
import { adminActions, useDispatch } from "../../../state";
import { QuoteDocument } from "../../../state/admin/state";
import { Link as WebLink } from "react-router-dom";

export const ApplicantInfo = ({
  applicant,
  baseInfo,
  quoteType,
  aff_agent,
  agency,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const birthDay = `${baseInfo?.Birthdate?.month}/${baseInfo?.Birthdate?.day}/${baseInfo?.Birthdate?.year}`;
  const { addr1, city, state, zip } = applicant;
  return (
    <Box className={classes.topSection}>
      <Box>
        <Typography className={classes.header}>
          Applicant information
        </Typography>
        <Box className={classes.contactWrapper}>
          {applicant.email && (
            <Box className={classes.contactItem}>
              <EmailIcon />
              <Link href={`mailto:${applicant.email}`}>
                {" "}
                <Typography className={classes.contactTxt}>
                  {applicant.email}
                </Typography>
              </Link>
            </Box>
          )}
          {applicant.phone && (
            <Box className={classes.contactItem}>
              <PhoneIcon />

              <Link href={`tel:${applicant.phone}`}>
                <Typography className={classes.contactTxt}>
                  {formatPhone(applicant.phone)}
                </Typography>
              </Link>
            </Box>
          )}
          {applicant.addr1 && (
            <Box>
              <Box className={classes.contactItem}>
                <PlaceIcon />
                <Typography className={classes.contactTxt}>
                  {applicant.addr1}
                  <br /> {applicant.city}
                  {", "} {applicant.state} {applicant.zip}
                </Typography>
              </Box>
              {quoteType === "Home" || quoteType === "Bundle" ? (
                <Box sx={{ margin: "-15px 0 8px 34px" }}>
                  <WebLink
                    style={{ fontSize: 14 }}
                    target="_blank"
                    to={{
                      pathname: replaceWhiteSpaceWithPlus(
                        `https://www.google.com/maps/place/${addr1}+${city}+${state}+${zip}/`,
                      ),
                    }}
                  >
                    Map
                  </WebLink>
                  <WebLink
                    style={{ fontSize: 14, margin: "0 10px" }}
                    target="_blank"
                    to={{
                      pathname: replaceWhiteSpaceWithPlus(
                        `https://www.zillow.com/homes/${addr1}+${city}+${state}+${zip}/`,
                      ),
                    }}
                  >
                    Zillow
                  </WebLink>
                </Box>
              ) : null}
            </Box>
          )}
          {!!baseInfo && (
            <Box className={classes.contactItem}>
              <PermContactCalendarIcon />
              <div className={classes.contactTxt}>
                <Typography>{baseInfo?.Birthdate.ActualAge} years</Typography>
                <div className={classes.birthDate}>{birthDay}</div>
              </div>
            </Box>
          )}
          {applicant.previousAddress?.street_line ? (
            <Box>
              {" "}
              <Divider />
              <Typography
                sx={{
                  margin: "10px 0 6px 34px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                Previous address
              </Typography>
              <Box className={classes.contactItem}>
                <PlaceIcon />
                <Typography
                  className={classes.contactTxt}
                  sx={{ marginBottom: 0 }}
                >
                  {applicant.previousAddress.street_line}
                  <br /> {applicant.previousAddress.city}
                  {", "} {applicant.previousAddress.state}{" "}
                  {applicant.previousAddress.zip}
                </Typography>
              </Box>
            </Box>
          ) : null}
          {agency?.name ? (
            <Box className={classes.contactItem}>
              <BusinessIcon />
              <Typography className={classes.contactTxt}>
                {agency.name}
              </Typography>
            </Box>
          ) : null}
          {aff_agent?.affiliate_agent.first_name ? (
            <Box className={classes.contactItem}>
              <AutorenewIcon />
              <Typography
                sx={{ fontWeight: 500 }}
                className={classes.contactTxt}
              >
                {aff_agent.affiliate_agent.first_name +
                  " " +
                  aff_agent.affiliate_agent.last_name}
              </Typography>
            </Box>
          ) : (
            <Box>
              {" "}
              <Button
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  letterSpacing: 1,
                }}
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => {
                  dispatch(adminActions.setAssignReferrerOpen(true));
                }}
              >
                Add referrer
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const SupportingDocs = ({ quoteDocuments }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <Box className={classes.topSection}>
      <Box>
        <Typography className={classes.header}>Supporting documents</Typography>
        {quoteDocuments?.length ? (
          <Box className={classes.contactWrapper}>
            {quoteDocuments.map((doc: QuoteDocument) => (
              <Box className={classes.docs} key={doc.file_path}>
                <Box className={classes.contactItem}>
                  <InsertDriveFileIcon />
                  <Typography className={classes.contactTxt}>
                    {doc.filename}
                  </Typography>
                </Box>
                <span
                  className={classes.downloadBtn}
                  onClick={() => dispatch(adminActions.download(doc.file_path))}
                >
                  <GetAppIcon />
                </span>
              </Box>
            ))}
          </Box>
        ) : (
          <Typography className={classes.emptyState}>
            There are no documents yet
          </Typography>
        )}
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => dispatch(adminActions.setUploadModalOpen(true))}
        >
          Upload document
        </Button>
      </Box>
    </Box>
  );
};

export const LifeCoverage = ({ baseInfo }) => {
  const classes = useStyles();
  return (
    <Box className={classes.topSection}>
      <Box className={classes.contactWrapper}>
        <Typography className={classes.coverage}>Coverage</Typography>

        {baseInfo?.title && (
          <Box className={classes.contactItem}>
            <CoverageIcon color={defaultTheme.palette.primary.main} />
            <Typography className={classes.titleTxt}>
              {baseInfo?.title}
            </Typography>
          </Box>
        )}
        {baseInfo?.FaceAmount && (
          <Box className={classes.contactItem}>
            <AttachMoneyIcon />
            <Typography className={classes.contactTxt}>
              {formatCurrency(baseInfo?.FaceAmount)}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const VehiclesList = ({ vehicles }) => {
  const classes = useStyles();
  const isMobile = useMobile();
  return (
    <Box className={classes.bottomSection}>
      {!isMobile && (
        <Typography className={classes.driversTitle}>Vehicles</Typography>
      )}
      {vehicles.map((vehicle, index) => {
        return (
          <div key={index} className={classes.driverCard}>
            <Grid container>
              <DirectionsCar className={classes.driverIcon} />
              <div>
                <p>
                  {vehicle.make} {vehicle.model} {vehicle.model_year}
                </p>

                <p className={classes.driverAccentText}>{vehicle.vin}</p>
              </div>
            </Grid>
          </div>
        );
      })}
    </Box>
  );
};

export const DriversList = ({ drivers, setAsPrimary }) => {
  const classes = useStyles();
  const isMobile = useMobile();
  return (
    <Box className={classes.bottomSection}>
      {!isMobile && (
        <Typography className={classes.driversTitle}>Drivers</Typography>
      )}
      {drivers.map((driver: any, index) => {
        const isPrimary = Boolean(driver["relationship_to_applicant"] === "I");
        return (
          <div key={index} className={classes.driverCard}>
            <Grid
              container
              className={!isPrimary ? "hoverable" : ""}
              onClick={() => (isPrimary ? {} : setAsPrimary(driver))}
            >
              <PersonIcon className={classes.driverIcon} />
              <div>
                <p>
                  {driver["first_name"]} {driver["last_name"]}
                </p>
                <p className={classes.driverAccentText}>{driver["dob"]}</p>
                <p className={classes.driverAccentText}>
                  DL#: {driver.manualLicense || driver["dlNumber"]}
                </p>
              </div>
              <Box className={classes.primaryContainer}>
                {isPrimary ? (
                  <p className={classes.primaryTag}>Primary</p>
                ) : (
                  <p className={classes.makePrimaryTag}>Make primary</p>
                )}
              </Box>
            </Grid>
          </div>
        );
      })}
    </Box>
  );
};

export const HomeApplicants = ({ homeApplicants, setAsPrimary }) => {
  const classes = useStyles();
  const isMobile = useMobile();
  return (
    <Box className={classes.bottomSection}>
      {!isMobile && (
        <Typography className={classes.homeTitle}>Home Applicants</Typography>
      )}
      {homeApplicants.map((applicant, index) => {
        const isPrimary = index === 0;
        return (
          <div key={index} className={classes.driverCard}>
            <Grid
              container
              className={!isPrimary ? "hoverable" : ""}
              onClick={() => (isPrimary ? {} : setAsPrimary(applicant))}
            >
              <PersonIcon className={classes.driverIcon} />
              <div>
                <p>
                  {applicant["first_name"]} {applicant["last_name"]}
                </p>
                <p className={classes.driverAccentText}>{applicant["dob"]}</p>
              </div>
              <Box className={classes.primaryContainer}>
                {isPrimary ? (
                  <p className={classes.primaryTag}>Primary</p>
                ) : (
                  <p className={classes.makePrimaryTag}>Make primary</p>
                )}
              </Box>
            </Grid>
          </div>
        );
      })}
    </Box>
  );
};
