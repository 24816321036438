import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { useSelector } from "react-redux";
import { adminSelectors } from "../../../state";
import { barOptions, mobileOptions } from "../../../state/admin/types";
import { useMobile } from "../../../themes";

export const ApplicationsStats = () => {
  const { statusStats } = useSelector(adminSelectors.agentStats);

  const isMobile = useMobile();

  const [mobileAppData, setMobileAppData] = useState<any>();
  // const [totalApps, setTotalApps] = useState(0);
  // const [amounts, setAmounts] = useState<any>();
  const [statusData, setStatusData] = useState<any>();

  const barData = [
    [
      "",
      "",
      {
        role: "annotation",
      },
      { role: "style" },
    ],
    ["", 0, null, "#dcf8e7"],
    ["New - New lead", 0, null, "#dcf8e7"],
    ["New - Need to present", 0, null, "#dcf8e7"],
    ["", 0, null, "#d2dcff"],
    ["In progress - Agent reviewed", 0, null, "#d2dcff"],
    ["", 0, null, "#ffefcc"],
    ["Pending - Waiting on client", 0, null, "#ffefcc"],
    ["Pending - Need payment info", 0, null, "#ffefcc"],
    ["Pending - Need app signature", 0, null, "#ffefcc"],
    ["", 0, null, "#eeeff5"],
    ["Closed - Won", 0, null, "#eeeff5"],
    ["Closed - Price too high", 0, null, "#eeeff5"],
    ["Closed - No response", 0, null, "#eeeff5"],
  ];

  const mobileData = [
    [
      "",
      "",
      {
        role: "annotation",
      },
      { role: "style" },
    ],
    ["New - New lead", 0, 0, "#dcf8e7"],
    ["New - Need to present", 0, 0, "#dcf8e7"],
    ["In progress - Agent reviewed", 0, 0, "#d2dcff"],
    ["Pending - Waiting on client", 0, 0, "#d2dcff"],
    ["Pending - Need payment info", 0, 0, "#ffefcc"],
    ["Pending - Need app signature", 0, 0, "#ffefcc"],
    ["Closed - Won", 0, 0, "#eeeff5"],
    ["Closed - Price too high", 0, 0, "#eeeff5"],
    ["Closed - No response", 0, 0, "#eeeff5"],
  ];

  // let statusesAmount;

  useEffect(() => {
    if (isMobile) return;
    const data = barData.map((d, i) => {
      if (i === 0) {
        return d;
      } else {
        const curr = statusStats.find((s) => s.fullStatus === d[0]);
        if (!curr) {
          return d;
        } else {
          d[1] = curr.statusCount;
          d[2] = curr.statusCount;
        }
        return d;
      }
    });

    setStatusData(data);

    // const totalApplications = statusStats.reduce((prev, curr) => {
    //   return prev + curr.statusCount;
    // }, 0);
    // setTotalApps(totalApplications);

    // statusesAmount = statusStats?.reduce(
    //   (acc, curr) => {
    //     const currCount = acc[curr.fullStatus?.split(" -")[0]] || 0;
    //     return {
    //       ...acc,
    //       [curr.fullStatus?.split(" -")[0]]: currCount + curr.statusCount,
    //     };
    //   },
    //   { ["New"]: 0, "In progress": 0, Pending: 0, Closed: 0 },
    // );
    // setAmounts(statusesAmount);
  }, [statusStats, isMobile]);

  useEffect(() => {
    if (!isMobile) return;
    const mobileStats = mobileData.map((d, i) => {
      if (i === 0) {
        return d;
      } else {
        const curr = statusStats.find((s) => s.fullStatus === d[0]);
        if (!curr) {
          return d;
        } else {
          d[1] = curr.statusCount;
          d[2] = curr.statusCount;
        }
        return d;
      }
    });
    setMobileAppData(mobileStats);
  }, [statusStats, isMobile]);

  return isMobile ? (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="700px"
      options={mobileOptions}
      data={mobileAppData}
    />
  ) : (
    <Chart
      chartType="BarChart"
      width="100%"
      height="500px"
      data={statusData}
      options={barOptions}
    />
  );
};
