import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  addWrapper: {
    padding: 30,
    "& .MuiFormControl-root": {
      marginTop: 10,
    },
    [theme.breakpoints.down('sm')]: {
      padding: "25px 0",
    },
  },
  header: {
    fontWeight: 600,
  },
  addNote: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      color: "white",
      width: 20,
      height: 20,
      backgroundColor: "#0033ff",
      verticalAlign: "middle",
      borderRadius: 3,
      "&:hover": { cursor: "pointer" },
    },
  },
  submit: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 24,
  },
  cancelBtn: {
    width: 98,
    marginRight: 12,
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
  },
  submitBtn: {
    width: 98,
    alignSelf: "flex-end",
  },
  notesContainer: {
    marginRight: 20,
  },
  notesWrapper: {
    padding: "0 30px 30px 30px",
    [theme.breakpoints.down('sm')]: {
      padding: "0 0 10px ",
    },
  },
  author: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  icon: {
    color: "grey",
    marginRight: 5,
    fontSize: 20,
  },
  flex: {
    display: "flex",
  },
  container: { borderBottom: "1px solid rgba(141, 152, 194, .2)" },
}));
