import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      marginTop: 18,
    },

    maxWidth: 400,
    textAlign: "center",
  },
  img: {
    width: 163,
    height: 114,
    marginTop: 56,
    marginBottom: 24,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 24,
    margin: "0 auto",
  },
  paragraph: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 8,
    margin: "0 auto",
  },
  subparagraph: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: 24,
    margin: "0 auto",
    maxWidth: 375,
  },
}));
