import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    maxWidth: "fit-content",
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      margin: "none",
      padding: "0 20px",
    },
  },
  formSection: {
    display: "block",
    width: 215,
    [theme.breakpoints.down('sm')]: {
      margin: "none",
      width: 350,
      flexDirection: "column",
      "&:last-child": {
        marginBottom: 1,
      },
    },
  },
  formItem: {
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: 300,
      marginBottom: 15,
    },
  },
  formFlex: {
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    },
  },
  mobileContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
    padding: 15,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  mobileWrapper: {
    boxShadow: "0 2px 12px 0 #F5F6FE",
    // padding: 15,
  },
  notMobile: { boxShadow: "none" },
  bold: { fontWeight: 600 },
  hide: {
    display: "none",
  },
  healthTitle: {
    color: "#8D98C2;",
    marginTop: 5,
    fontSize: 13,
    fontWeight: 500,
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      textAlign: "initial",
      marginTop: -10,
      paddingBottom: 14,
    },
  },
  infoIcon: {
    fontSize: 16,
    marginLeft: 4,
    color: "#8d98c2",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
