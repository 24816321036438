import React, { useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
  authSelectors,
} from "../../state";
import { UDD_COLUMNS as columns } from "../../constants/tables";

export const useStyles = makeStyles((theme) => ({
  uddPage: {
    width: "96%",
    height: "100%",
    [theme.breakpoints.down('md')]: {
      width: "100%",
      paddingBottom: 48,
    },
  },
}));

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function UDDPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const uddRequests = useSelector(adminSelectors.uddRequests);
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);

  const [pageSize, setPageSize] = React.useState(25);
  useEffect(() => {
    if (!activeRole || !agencyId) {
      console.warn("active role and active agency id is not defined");
      return;
    }
    dispatch(adminActions.getUDDRequests(activeRole, agencyId));
    dispatch(adminActions.setPageTitle("UDD Requests"));
  }, [dispatch, activeRole, agencyId]);

  return (
    <div className={classes.uddPage}>
      <DataGridPro
        autoHeight
        rows={uddRequests}
        columns={columns}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
