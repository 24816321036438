import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Typography,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useFormik, yup } from "../../lib";

import { useStyles } from "./AddUserAgencyPage.styles";

const initialValues = {
  user_id: "",
  agency_id: "",
  role: "",
  referral_prod_id: "",
};
const validationSchema = yup.object({});

const AddUserAgencyPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [agenciesLoading, setAgenciesLoading] = useState(true);
  const [availAgencies, setAvailAgencies] = useState<any>([]);

  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const users = useSelector(adminSelectors.users);
  const agencies = useSelector(adminSelectors.agencies);
  const products = useSelector(adminSelectors.referralProducts);

  const user_agencies = useSelector(adminSelectors.user_agencies);
  const roles = [
    "agent",
    "super_agent",
    "agency_admin",
    "affiliate_agent",
    // "sales_director",
  ];

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values) {
      const user = {
        ...values,
        agency_id: form.values.agency_id ? form.values.agency_id : +0,
        role: form.values.role ? form.values.role : "referral_product_owner",
        referral_prod_id: form.values.referral_prod_id
          ? form.values.referral_prod_id
          : null,
      };
      await dispatch(adminActions.createUserAgency(user));
    },
  });

  useEffect(() => {
    if (!activeRole || !agencyId) {
      console.warn("active role and active agency id is not defined");
      return;
    }
    dispatch(adminActions.getUsers(activeRole, agencyId));
    dispatch(adminActions.getAgencies());
    dispatch(adminActions.getUserAgency());
  }, [dispatch, activeRole, agencyId]);

  useEffect(() => {
    if (+form.values.agency_id === 0) {
      dispatch(adminActions.getReferralProducts());
    }
  }, [form.values.agency_id]);

  useEffect(() => {
    if (form.values.user_id) {
      const existingUserAgenciesForUser = user_agencies
        .filter(
          (user_agency) => user_agency.user_id === Number(form.values.user_id),
        )
        .map((a) => a.agency_id);
      const goodAgencies = agencies.filter(
        (agency) => !existingUserAgenciesForUser.includes(agency.id),
      );
      setAvailAgencies(goodAgencies);
      setAgenciesLoading(false);
    }
  }, [form.values.user_id]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item xs={4} className={classes.formControl}>
              <TextField
                select
                id="user_id"
                name="user_id"
                label="User"
                variant="outlined"
                value={form.values.user_id}
                onChange={form.handleChange}
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.last_name} {user.first_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={4} className={classes.formControl}>
              <TextField
                select
                id="agency_id"
                name="agency_id"
                label="Agency"
                variant="outlined"
                value={form.values.agency_id}
                disabled={
                  agenciesLoading ||
                  !form.values.user_id ||
                  !availAgencies.length
                }
                onChange={form.handleChange}
              >
                {availAgencies.map((agency) => (
                  <MenuItem key={agency.id} value={agency.id}>
                    {agency.name}
                  </MenuItem>
                ))}
              </TextField>
              {form.values.user_id && !availAgencies.length && (
                <Typography className={classes.note}>
                  No available agencies for selected user.
                </Typography>
              )}
            </Grid>

            {+form.values.agency_id !== 0 && (
              <Grid item xs={4} className={classes.formControl}>
                <TextField
                  select
                  id="role"
                  name="role"
                  label="Role"
                  variant="outlined"
                  value={form.values.role}
                  onChange={form.handleChange}
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            {+form.values.agency_id === 0 && (
              <Grid item xs={4} className={classes.formControl}>
                <TextField
                  select
                  id="product"
                  name="referral_prod_id"
                  label="Product"
                  variant="outlined"
                  value={form.values.referral_prod_id}
                  onChange={form.handleChange}
                >
                  {products.map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.product_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  !form.values.user_id ||
                  (!form.values.agency_id && +form.values.agency_id !== 0) ||
                  (!form.values.role && +form.values.agency_id !== 0) ||
                  (!form.values.role && !form.values.referral_prod_id)
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddUserAgencyPage;
