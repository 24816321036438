import { AppState } from "../states";

export const lifeSelectors = {
  startForm(state: AppState) {
    return state.life.startForm || {};
  },
  insuredDetails(state: AppState) {
    return state.life.insuredDetails;
  },
  coverageDetails(state: AppState) {
    return state.life.coverageDetails;
  },
  quotes(state: AppState) {
    return state.life.quotes || [];
  },
  baseInfo(state: AppState) {
    return state.life.baseInfo;
  },
  selectedQuote(state: AppState) {
    return state.life.selectedQuote;
  },
};
