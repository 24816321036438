import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { BlockIcon, LockIcon } from "../../../../components/icons";
import { AddressAsyncAutocomplete } from "../../../../components/forms/inputs";

import {
  useAction,
  autoActions,
  useSelector,
  autoSelectors,
  wizardActions,
  wizardSelectors,
  authSelectors,
} from "../../../../state";
import { Form, useFormik } from "../../../../lib";
import { startValidationSchema } from "../../../../utils";
import { StartWizardInitialValues } from "../../../../constants";

import { useStyles } from "./StartWizard.styles";
import { UNSUPPORTED_STATES } from "../../../../state/wizard/types";

const StartWizard = () => {
  const classes = useStyles();
  // const validateAddress = useAction(wizardActions.validateAddress);
  const setStartDetails = useAction(autoActions.setStartDetails);
  const setApplicantIndex = useAction(autoActions.setApplicantIndex);

  const uddCodeVerifyOn = useSelector(wizardSelectors.settings)
    .udd_code_verification?.value;
  const startForm = useSelector(autoSelectors.startForm);
  const addressError = useSelector(wizardSelectors.addressError);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const isStartFormFilled = !!Object.keys(startForm).length;
  const setAddressError = useAction(wizardActions.setAddressError);
  const setAddressModalOpen = useAction(wizardActions.setAddressModalOpen);

  const [skipLookup, setSkipLookup] = useState(true);

  const form = useFormik({
    initialValues: isStartFormFilled ? startForm : StartWizardInitialValues,
    validationSchema: startValidationSchema,
    async onSubmit(values, { setSubmitting }) {
      // let isCoveredArea = await validateAddress(values.address?.state, "auto");
      // if ((mode === "bundle" || mode === "auto-renters") && isCoveredArea) {
      //   isCoveredArea = await validateAddress(values.address?.state, "home");
      // }

      const state = values.address?.state;
      if (state && UNSUPPORTED_STATES.includes(state)) {
        setAddressError(
          `${window.location.hostname} is not yet available in your area. Check back soon!`,
        );
        return;
      }
      setSubmitting(false);
      // if (!isCoveredArea) return;
      await setStartDetails({ ...values, skipLookup });
      setAddressModalOpen(true);
      setApplicantIndex(undefined); //since name could have changed
    },
  });

  useEffect(() => {
    setAddressError("");
  }, []);

  useEffect(() => {
    form.setFieldTouched("address.label", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form form={form} className="maxWidth">
      <Grid container spacing={2} className={classes.fields}>
        <Grid item md={5} xs={12}>
          <TextField
            id="first_name"
            name="first_name"
            label="First name"
            value={form.values.first_name}
            onChange={form.handleChange}
            error={form.touched.first_name && Boolean(form.errors.first_name)}
            helperText={form.touched.first_name && form.errors.first_name}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <TextField
            id="middle_name"
            name="middle_name"
            label="Middle"
            value={form.values.middle_name}
            onChange={form.handleChange}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <TextField
            id="last_name"
            name="last_name"
            label="Last name"
            value={form.values.last_name}
            onChange={form.handleChange}
            error={form.touched.last_name && Boolean(form.errors.last_name)}
            helperText={form.touched.last_name && form.errors.last_name}
          />
        </Grid>
        <Grid item md={9} xs={12} style={{ marginTop: 24 }}>
          <AddressAsyncAutocomplete form={form} />
          {Boolean(addressError?.length) && (
            <FormHelperText error variant="outlined">
              {addressError}
            </FormHelperText>
          )}
        </Grid>
        <Grid item md={3} xs={12} className={classes.aptField}>
          <TextField
            id="apt"
            name="apt"
            label="Apt/Unit#"
            value={form.values.apt}
            onChange={form.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={0}
            wrap="nowrap"
          >
            <Grid item xs={6} style={{ marginTop: 24 }}>
              <Typography
                className={`${classes.feature} ${classes.firstFeature}`}
              >
                <LockIcon color="primary" />
                Cutting edge encryption
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 24 }}>
              <Typography className={classes.feature}>
                <BlockIcon color="primary" />
                We never sell your info
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 24 }}
      >
        <Button
          type="submit"
          id="submit"
          variant="contained"
          color="primary"
          disabled={!form.isValid}
          className={classes.submitButton}
          onClick={() => setSkipLookup(false)}
        >
          Look up my vehicles
        </Button>
      </Grid>
      {!isLoggedIn && !!uddCodeVerifyOn && (
        <Typography className={classes.text}>
          We'll need to verify your phone number
        </Typography>
      )}
      {form.isValid && !isLoggedIn && !!uddCodeVerifyOn && (
        <Box className={classes.linkWrapper}>
          <Button
            className={classes.link}
            type="submit"
            onClick={() => setSkipLookup(true)}
          >
            I'll enter my vehicles manually
          </Button>
        </Box>
      )}
    </Form>
  );
};

export default React.memo(StartWizard);
