import { format, isValid } from "date-fns";

export const formatToDate = (date: number | Date) => {
  return format(date, "yyyy-MM-dd");
};

export const formatStringToDate = (date: string) => {
  return format(new Date(date), "yyyy-MM-dd");
};

export const formatStringToFullDate = (date: string) => {
  return format(new Date(date), "PPpp");
};

export const customFormatDate = (
  date: string | Date,
  formatString = "MM/dd/yyyy",
) => {
  if (!date) {
    return "";
  }
  const d = new Date(date);
  if (!isValid(d)) {
    return "";
  }
  return format(new Date(date), formatString);
};
