import React from "react";

export const DwellingImage = ({ fillColor }) => {
  return (
    <svg
      width="84px"
      height="84px"
      viewBox="0 0 84 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 2</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="84" height="84"></rect>
      </defs>
      <g
        id="↳-home-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Home---Create-a-custom-quote-side-bar"
          transform="translate(-564.000000, -114.000000)"
        >
          <g id="Group-2" transform="translate(564.000000, 114.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use id="Rectangle" fill="#FFFFFF" xlinkHref="#path-1"></use>
            <g id="Group-3" mask="url(#mask-2)">
              <g transform="translate(-62.400000, 14.400000)">
                <g id="Group-14" strokeWidth="1" fill="none">
                  <polygon
                    id="Path"
                    fill="#002679"
                    fillRule="nonzero"
                    points="13.8180274 28.2580208 10.9873366 23.5345913 37.4084854 0.860759494 105.349169 0.860759494 105.349169 7.47356082 80.8151471 33.9268213 52.5061869 39.5949367"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#DADFF6"
                    fillRule="nonzero"
                    points="117.000935 0 108.139032 0 108.139032 4.62883799 105.349578 1.46539051 82.7871728 23.9823335 79.0263176 27.7349305 74.3261006 46.4992764 82.7871728 67.1392405 126.031214 67.1392405 126.031214 24.9204827 117.000935 14.6790766"
                  ></polygon>
                  <rect
                    id="Rectangle"
                    fill="#E7EBFD"
                    fillRule="nonzero"
                    x="10.9873366"
                    y="23.8860759"
                    width="71.7408449"
                    height="43.2531646"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fill={fillColor}
                    fillRule="nonzero"
                    x="57.3065007"
                    y="37.443038"
                    width="15.2960961"
                    height="13.556962"
                  ></rect>
                  <polygon
                    id="Path"
                    fill={fillColor}
                    fillRule="nonzero"
                    points="103.194789 42.1772152 103.194789 37.443038 97.5934016 37.443038 97.5934016 42.1772152 102.727725 42.1772152"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill={fillColor}
                    fillRule="nonzero"
                    points="102.727725 46.2658228 97.5934016 46.2658228 97.5934016 51 103.194789 51 103.194789 46.2658228"
                  ></polygon>
                  <rect
                    id="Rectangle"
                    fill={fillColor}
                    fillRule="nonzero"
                    x="107.28811"
                    y="37.443038"
                    width="5.60138729"
                    height="4.73417722"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fill={fillColor}
                    fillRule="nonzero"
                    x="107.28811"
                    y="46.2658228"
                    width="5.60138729"
                    height="4.73417722"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    x="140.034682"
                    y="14.2025316"
                    width="1"
                    height="39.5949367"
                    rx="0.5"
                  ></rect>
                </g>
                <rect
                  id="Rectangle"
                  fill="#000000"
                  x="49.6190024"
                  y="67.8176944"
                  width="103.980998"
                  height="1"
                ></rect>
              </g>
            </g>
            <path
              d="M66.2,18.8 C66.375,17.575 66.8125,16.6125 67.5125,15.9125 C68.2125,15.2125 69.175,14.775 70.4,14.6 C69.175,14.425 68.2125,13.9875 67.5125,13.2875 C66.8125,12.5875 66.375,11.625 66.2,10.4 C66.025,11.625 65.5875,12.5875 64.8875,13.2875 C64.1875,13.9875 63.225,14.425 62,14.6 C63.225,14.775 64.1875,15.2125 64.8875,15.9125 C65.5875,16.6125 66.025,17.575 66.2,18.8 Z"
              id="Path"
              fill="#FFCC00"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M62.8875,25.9125 C61.4541667,24.4791667 60.5583333,22.5083333 60.2,20 C59.8416667,22.5083333 58.9458333,24.4791667 57.5125,25.9125 C56.0791667,27.3458333 54.1083333,28.2416667 51.6,28.6 C54.1083333,28.9583333 56.0791667,29.8541667 57.5125,31.2875 C58.9458333,32.7208333 59.8416667,34.6916667 60.2,37.2 C60.5583333,34.6916667 61.4541667,32.7208333 62.8875,31.2875 C64.3208333,29.8541667 66.2916667,28.9583333 68.8,28.6 C66.2916667,28.2416667 64.3208333,27.3458333 62.8875,25.9125 Z"
              id="Path"
              fill="#FFCC00"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M75.8,28.4 C75.625,29.625 75.1875,30.5875 74.4875,31.2875 C73.7875,31.9875 72.825,32.425 71.6,32.6 C72.825,32.775 73.7875,33.2125 74.4875,33.9125 C75.1875,34.6125 75.625,35.575 75.8,36.8 C75.975,35.575 76.4125,34.6125 77.1125,33.9125 C77.8125,33.2125 78.775,32.775 80,32.6 C78.775,32.425 77.8125,31.9875 77.1125,31.2875 C76.4125,30.5875 75.975,29.625 75.8,28.4 Z"
              id="Path"
              fill="#FFCC00"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
