import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 500,
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
    },
  },
  formWrapper: { marginTop: 24 },
  fields: {
    maxWidth: 300,
    margin: "auto",
    marginBottom: theme.spacing(1),
  },
  form: {
    maxWidth: 640,
    marginTop: theme.spacing(2),
  },
  inputField: {
    height: 96,
    width: 66,
    borderRadius: 3,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
    border: "none",
    margin: 12,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    [theme.breakpoints.down('sm')]: {
      width: 50,
    },
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        height: 96,
        fontSize: 32,
        padding: "0 16",
        textAlign: "center",
      },
    },
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%",
    // },
  },
  errorText: {
    color: "#EA0D26",
    fontSize: 12,
    fontWeight: 500,
  },
  submitButton: {
    width: 227,
    marginTop: 24,
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  linkWrapper: {
    textAlign: "center",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    textTransform: "none",
    fontSize: 14,
    fontWeight: 500,
    marginTop: 10,
    transition: "all 300ms linear",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
      backgroundColor: "transparent",
    },
  },
}));
