import React, { useEffect } from "react";
import { Button } from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import { AddIcon } from "../../components/icons";

import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
  authSelectors,
} from "../../state";
import { AGENCY_COLUMNS as columns } from "../../constants/tables";
import { useStyles } from "./CarriersPage.styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function AgencyPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const agencies = useSelector(adminSelectors.agencies);
  const [pageSize, setPageSize] = React.useState(25);
  const isSuperAgent = useSelector(authSelectors.isSuperAgent);
  useEffect(() => {
    dispatch(adminActions.getAgencies());
    dispatch(adminActions.setPageTitle("Agencies"));
  }, [dispatch]);

  return (
    <div className={classes.page}>
      {isSuperAgent && (
        <div className={classes.topArea}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => dispatch(adminActions.setAgencyModalOpen(true))}
          >
            <AddIcon /> Add Agency
          </Button>
        </div>
      )}

      <DataGridPro
        rows={agencies}
        columns={columns}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
