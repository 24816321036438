import sub from "date-fns/sub";
import { yup } from "../lib";

export const lifeInsuredSchema = yup.object({
  gender: yup.string().required("This field is required"),
  healthClass: yup.string().required("This field is required"),
  smoke: yup.string().required("This field is required"),
  dob: yup
    .date()
    .max(
      sub(new Date(), { years: 18 }),
      "Applicant must be at least 18 years of age",
    )
    .required("This field is required")
    .typeError("Invalid date of birth"),
});
export const lifeCoverageSchema = yup.object({
  yearTerm: yup.string().required("This field is required"),
  amount: yup.string().required("This field is required"),
});
