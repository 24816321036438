import React, { useCallback } from "react";
import { IconButton } from "@mui/material";

import { DeleteIcon } from "../icons";

import { useAction, adminActions } from "../../state";
import { GridRowId } from "@mui/x-data-grid-pro";

interface RowMenuProps {
  id: GridRowId;
  category?: boolean;
}

const ActionCell = (props: RowMenuProps) => {
  const { id, category = false } = props;

  const getFAQs = useAction(adminActions.getFAQs);
  const getFAQCategories = useAction(adminActions.getFAQCategories);
  const deleteFAQ = useAction(adminActions.deleteFAQ);
  const deleteFAQCategory = useAction(adminActions.deleteFAQCategory);

  const deleteAction = category ? deleteFAQCategory : deleteFAQ;
  const updateAction = category ? getFAQCategories : getFAQs;

  const handleDeleteClick = useCallback(
    async (event) => {
      event.stopPropagation();
      const isSuccess = await deleteAction(id);
      isSuccess && updateAction();
    },
    [id, deleteAction, updateAction],
  );

  return (
    <IconButton
      color="inherit"
      size="small"
      aria-label="delete"
      onClick={handleDeleteClick}
    >
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
};

export const FAQActionCell = (props: RowMenuProps) => <ActionCell {...props} />;

export const FAQCategoryActionCell = (props: RowMenuProps) => (
  <ActionCell {...props} category />
);
