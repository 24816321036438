import React, { useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";

import { MainSnackbar } from "../../components/snackbars";

import { Navigation, useLocation } from "../../lib";
import { authSelectors, useSelector } from "../../state";

import { useStyles } from "./AuthLayout.styles";
import fiveMinLogo from "../../assets/img/5minute_logo.svg";
import reliLogo from "../../assets/img/reliexchange_logo.png";

export const AuthLayout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const isUserLoggedIn = useSelector(authSelectors.isUserLoggedIn);
  const agencyDomain = useSelector(authSelectors.domain);
  const role = useSelector(authSelectors.activeRole);

  useEffect(() => {
    if (isUserLoggedIn && role === "referral_product_owner") {
      Navigation.go("/admin/referrals");
      return;
    }
    if (isUserLoggedIn && role === "affiliate_agent") {
      Navigation.go("/admin/applications");
      return;
    }
    if (isUserLoggedIn && !location.query.after)
      Navigation.go("/admin/dashboard");
  }, [isUserLoggedIn]);

  return (
    <Box className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} className={classes.secondaryPanel}>
          <Box>
            <img
              alt="Company logo"
              src={
                agencyDomain === "www.5minuteinsure.com"
                  ? fiveMinLogo
                  : reliLogo
              }
              className={classes.logo}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Container
            className={classes.container}
            maxWidth="sm"
            disableGutters={true}
          >
            <div className={classes.content}>{children}</div>
          </Container>
        </Grid>
      </Grid>
      <MainSnackbar />
    </Box>
  );
};
