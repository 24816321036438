import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
  (theme) => ({
    authCard: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center right",
      backgroundSize: "auto 100%",
      display: "flex",
      flexDirection: "column",
      minWidth: "30vw",
      position: "relative",

      [theme.breakpoints.down('md')]: {
        minWidth: "100%",
      },
    },
    logo: {},
    titleText: {
      color: theme.palette.primary.main,
    },
    subtitleText: {
      marginBottom: "2vh",
    },
    errorText: {
      color: theme.palette.error.main,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      flex: 1,

      "& .MuiFormControl-root": {
        paddingBottom: "2vh",
        minHeight: "2.2vh",
      },
    },
    flexJustifySpace: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",

      [theme.breakpoints.down('lg')]: {
        flexDirection: "column",
      },
    },
    buttonBox: {
      marginTop: "1.7vh",
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",

      "& button": {
        borderRadius: 3,
        width: 200,
        height: 48,
        textTransform: "uppercase",
        padding: "0 1.6vmax",
        color: "#fff",
      },
    },
    buttonBoxSingle: {
      justifyContent: "center",
    },
    bottomLink: {
      marginTop: 24,
    },
  }),
  { classNamePrefix: "AuthPage" },
);
