import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddressShape,
  DiscountsFormShape,
  DriversFormShape,
  SelectedVehiclesShape,
  StartFormShape,
  UDDDriversShape,
  UDDInfoResponse,
  UDDVehiclesShape,
  VehiclesFormShape,
  QuoteData,
} from "./types";

const initialState: AutoState = {
  activeVehicleIndex: 0,
  activeDriverIndex: 0,

  showSelectVehicleView: true,
  showSelectDriverView: true,

  startForm: {},
  vehiclesForm: {},
  driversForm: {
    selectedDrivers: [],
    applicantIndex: undefined,
  },
  discountsForm: {},

  makesLoading: false,
  modelsLoading: false,

  addressSuggestions: [],
  addressSuggestionsLoading: false,

  quotes: [],
  bundleQuotes: [],
  bundleQuotesFailed: false,
  bundleCustomQuotes: [],
  selectedQuote: null,
  showQuoteCardDetails: false,
  quotesLoading: false,

  coverageInfo: {
    "0": {
      LOBCd: "",
      Coverages: [],
      StateProvCd: "",
      moreCoverages: [],
      displayCoverages: [],
    },
    defaults: [],
  },
  customData: {
    bi: "",
    pd: "",
    um: "",
    undum: "",
    umpd: "",
    umpdded: "",
    undpd: "",
    medex: "",
    pip: "",
    pipded: "",
    attendantcare: "",
    medicalexpenseonly: "",
    extendedmedical: "",
    obel: "",
    tort: "",
    vehicles: [],
  },
};

export const auto = {
  ...createSlice({
    name: "auto",
    initialState,
    reducers: {
      resetState: () => initialState,
      // GENERAL WIZARD
      setStartDetails(state, { payload }: PayloadAction<StartFormShape>) {
        state.startForm = payload;
        state.showSelectVehicleView = true;
      },
      setUDDInfo(state, { payload }: PayloadAction<UDDInfoResponse>) {
        state.uddVehicles = payload.responseData.vehicles;
        state.uddDrivers = payload.responseData.drivers;
      },
      setAddressAutocompleteResults(state, { payload }: PayloadAction<any>) {
        state.addressSuggestions = payload.map((suggestion) => ({
          ...suggestion,
          label: `${suggestion.street_line}, ${suggestion.city}, ${suggestion.state}, ${suggestion.zipcode}`,
        }));
      },
      clearAddressAutocompleteResults(state) {
        state.addressSuggestions = initialState.addressSuggestions;
      },
      setAddressSuggestionsLoading(state, { payload }: PayloadAction<boolean>) {
        state.addressSuggestionsLoading = payload;
      },
      setCoverageInfo(state, { payload }: PayloadAction<any>) {
        state.coverageInfo = payload;
      },
      // VEHICLES
      setVehicle(
        state,
        { payload: { vehiclesForm } }: PayloadAction<{ vehiclesForm: any }>,
      ) {
        state.vehiclesForm = vehiclesForm;
      },
      setVehicleStep(state, { payload }: PayloadAction<number>) {
        state.activeVehicleIndex = payload;
      },
      toNextVehicle(state) {
        state.activeVehicleIndex = state.activeVehicleIndex + 1;
      },
      toPreviousVehicle(state) {
        state.activeVehicleIndex = state.activeVehicleIndex - 1;
      },
      resetActiveVehicle(state) {
        state.activeVehicleIndex = initialState.activeVehicleIndex;
      },
      setVehiclesForm(state) {
        state.showSelectVehicleView = false;
      },
      setSelectedVehicles(
        state,
        { payload }: PayloadAction<SelectedVehiclesShape[]>,
      ) {
        state.vehiclesForm.selectedVehicles = payload;
      },
      setSelectedDrivers(state, { payload }: PayloadAction<any[]>) {
        state.driversForm.selectedDrivers = payload;
      },
      setApplicantIndex(state, { payload }: PayloadAction<number | undefined>) {
        state.driversForm.applicantIndex = payload;
      },
      setSelectedVehicleData(
        state,
        {
          payload: { lookupData, index },
        }: PayloadAction<{ lookupData: any; index: number }>,
      ) {
        state.vehiclesForm = {
          ...state.vehiclesForm,
          selectedVehicles: state.vehiclesForm?.selectedVehicles?.map((v, i) =>
            i === index ? { ...v, ...lookupData } : v,
          ),
        };
      },
      setVehicleLookupError(state, { payload }: PayloadAction<string>) {
        state.vehiclesForm.vinError = payload;
      },
      setShowSelectVehicleView(state, { payload }: PayloadAction<boolean>) {
        state.showSelectVehicleView = payload;
      },
      removeVehicle(state, { payload }: PayloadAction<number>) {
        const index = payload;
        const submittedVehicles = state.vehiclesForm?.submittedVehicles;
        const selectedVehicles = state.vehiclesForm?.selectedVehicles;

        selectedVehicles?.splice(index, 1);
        submittedVehicles?.splice(index, 1);

        state.vehiclesForm = {
          ...state.vehiclesForm,
          selectedVehicles,
          submittedVehicles,
        };
      },
      // DRIVERS
      setDriverStep(state, { payload }: PayloadAction<number>) {
        state.activeDriverIndex = payload;
      },
      toNextDriver(state) {
        state.activeDriverIndex = state.activeDriverIndex + 1;
      },
      toPreviousDriver(state) {
        state.activeDriverIndex = state.activeDriverIndex - 1;
      },
      resetActiveDriver(state) {
        state.activeDriverIndex = initialState.activeDriverIndex;
      },
      setDriversForm(state, { payload }: PayloadAction<DriversFormShape>) {
        state.driversForm = payload;
      },
      removeDriverAndUpdateAppIndex(
        state,
        { payload: index }: PayloadAction<number>,
      ) {
        const { selectedDrivers, applicantIndex } = state.driversForm;

        selectedDrivers.splice(index, 1);

        let newAppIndex = applicantIndex;
        if (applicantIndex !== undefined && applicantIndex > index) {
          newAppIndex = applicantIndex - 1;
        }

        state.driversForm = {
          ...state.driversForm,
          selectedDrivers,
          applicantIndex: newAppIndex,
        };
      },
      setShowSelectDriverView(state, { payload }: PayloadAction<boolean>) {
        state.showSelectDriverView = payload;
      },

      //DISCOUNTS
      setDiscounts(state, { payload }: PayloadAction<any>) {
        state.discountsForm = payload;
      },
      setCustomData(state, { payload }: PayloadAction<any>) {
        state.customData = payload;
      },
      // QUOTES
      setAutoQuotes(state, { payload }: PayloadAction<any>) {
        //need to pull out extra values not to mess up if these values passed back
        // eslint-disable-next-line
        const { customQuotes, meta, req_uid, status, ...quotes } = payload;
        state.customQuotes = customQuotes;
        state.quotes = Object.values(quotes);
      },
      setBundleAutoQuotes(state, { payload }: PayloadAction<any>) {
        //need to pull out extra values not to mess up if these values passed back
        // eslint-disable-next-line
        const { customQuotes, meta, req_uid, status, ...quotes } = payload;
        state.bundleCustomQuotes = customQuotes;
        state.bundleQuotes = Object.values(quotes);
      },
      setBundleAutoQuotesFailed(state, { payload }: PayloadAction<boolean>) {
        state.bundleQuotesFailed = payload;
      },
      clearAutoQuotes(state) {
        state.quotes = [];
        state.customQuotes = [];
        state.bundleQuotes = [];
        state.bundleCustomQuotes = [];
      },
      setPlaceHolderCustomQuote(state, { payload }) {
        !payload //not linked
          ? (state.customQuotes = [{ is_loading: true }])
          : (state.bundleCustomQuotes = [{ is_loading: true }]);
      },
      setSelectedAutoQuote(state, { payload }: PayloadAction<any>) {
        state.selectedQuote = payload;
      },
      clearSelectedAutoQuote(state) {
        state.selectedQuote = null;
      },
      toggleQuoteCardDetails(state) {
        state.showQuoteCardDetails = !state.showQuoteCardDetails;
      },
      // VEHICLES
      setMakes(
        state,
        {
          payload: { makes, index },
        }: PayloadAction<{ makes: string[]; index: number }>,
      ) {
        state.vehiclesForm = {
          ...state.vehiclesForm,
          selectedVehicles: state.vehiclesForm?.selectedVehicles?.map((v, i) =>
            i === index ? { ...v, makes } : v,
          ),
        };
      },
      setMakesLoading(state, { payload }: PayloadAction<boolean>) {
        state.makesLoading = payload;
      },
      setModels(
        state,
        {
          payload: { models, index },
        }: PayloadAction<{ models: string[]; index: number }>,
      ) {
        state.vehiclesForm = {
          ...state.vehiclesForm,
          selectedVehicles: state.vehiclesForm?.selectedVehicles?.map((v, i) =>
            i === index ? { ...v, models } : v,
          ),
        };
      },
      setModelsLoading(state, { payload }: PayloadAction<boolean>) {
        state.modelsLoading = payload;
      },
      // DRIVERS
      setIndustriesOccupations(state, { payload }: PayloadAction<any[]>) {
        state.industriesOccupations = payload;
      },
      setIndustries(state, { payload }: PayloadAction<any[]>) {
        state.industries = payload;
      },
      setIndustriesOccupationsLoading(
        state,
        { payload }: PayloadAction<boolean>,
      ) {
        state.industriesOccupationsLoading = payload;
      },
      // QUOTES

      //REHYDRATE QUOTE
      setWizard(state, { payload }: PayloadAction<any>) {
        state.activeDriverIndex = payload.activeDriverIndex;
        state.activeVehicleIndex = payload.activeVehicleIndex;
        state.discountsForm = payload.discountsForm;
        state.driversForm = payload.driversForm;
        state.startForm = payload.startForm;
        state.vehiclesForm = payload.vehiclesForm;
        state.customData = payload.autoCustomData;
      },
      // BUNDLED QUOTES
      setQuotesLoading(state, { payload }: PayloadAction<boolean>) {
        state.quotesLoading = payload;
      },
    },
  }),
  persist: true,
};

export interface AutoState {
  // Stepper
  activeVehicleIndex: number;
  activeDriverIndex: number;

  showSelectVehicleView: boolean;
  showSelectDriverView: boolean;

  // Forms
  startForm: StartFormShape;
  vehiclesForm: VehiclesFormShape;
  driversForm: DriversFormShape;
  discountsForm: DiscountsFormShape;

  // Quotes
  quotes?: QuoteData[];
  bundleQuotes?: QuoteData[];
  bundleQuotesFailed: boolean;
  selectedQuote: any;
  customQuotes?: any[];
  bundleCustomQuotes?: any[];
  customQuotesLoading?: Boolean;
  activeQuoteCard?: any;
  coverages?: any[];
  modalOpen?: boolean;
  modalOpenCoverage?: string;
  showQuoteCardDetails?: Boolean;
  coverageInfo: CoverageInfo;
  quotesLoading: boolean;
  customData: CustomData;

  // UDD Driver Info
  uddVehicles?: UDDVehiclesShape[];
  uddDrivers?: UDDDriversShape[];

  makes?: string[];
  makesLoading?: boolean;
  models?: string[];
  modelsLoading?: boolean;
  industriesOccupations?: any[];
  industries?: any[];
  industriesOccupationsLoading?: boolean;

  // Start address smarty streets suggestions
  addressSuggestions: AddressShape[];
  addressSuggestionsLoading: boolean;
}

interface CoverageInfo {
  "0": TurboCvgData;
  defaults: Default[];
}

interface Default {
  CoverageCd: string;
  Limits: (Limit2 | Deductible)[];
  Deductibles?: Deductible[];
}

interface Limit2 {
  Val1: string;
  Val2: string;
}

interface TurboCvgData {
  LOBCd: string;
  Coverages?: Coverage[];
  StateProvCd: string;
  moreCoverages?: string[];
  displayCoverages?: string[];
}

interface Coverage {
  Scope: string;
  Limits?: Limit[];
  CoverageCd: string;
  IsRequired: string;
  IsFreeEntry: string;
  Deductibles?: Deductible[];
  Options?: Deductible[];
}

interface Deductible {
  Val1: string;
}

interface Limit {
  Val1: string;
  Val2?: string;
}

interface CustomData {
  bi: any;
  pd: any;
  um: any;
  undum: any;
  umpd: any;
  umpdded: any;
  undpd: any;
  medex: any;
  pip: any;
  pipded: any;
  attendantcare: any;
  medicalexpenseonly: any;
  extendedmedical: any;
  obel: any;
  tort: any;
  vehicles: Vehicle[];
}

interface Vehicle {
  coll: any;
  comp: any;
  towing: any;
  rental: any;
  collEnabled: boolean;
  compEnabled: boolean;
  towingEnabled: boolean;
  rentalEnabled: boolean;
}
