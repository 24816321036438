export interface StartFormShape {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  address?: AddressShape;
  addressError?: string;
  apt?: string;
}

export interface AddressShape {
  zipcode: string;
  state: string;
  full_state?: string;
  city: string;
  street_line: string;
  label?: string;
}

export interface InsuredDetails {
  dob: string;
  gender: string;
  smoke: boolean;
  healthClass: string;
}

export interface CoverageDetails {
  amount: string;
  yearTerm: string;
}

export interface LifeState {
  // activeStep: number;
  startForm: StartFormShape;
  insuredDetails: InsuredDetails;
  quotes: LifeQuote[];
  baseInfo: LifeBaseInfo | null;
  selectedQuote: LifeQuote | null;
  // quotesLoading: boolean;
  coverageDetails: CoverageDetails;
}

export interface LifeQuote {
  name: string;
  amRating: string;
  premium: string;
  monthlyPremium: string;
  productName: string;
  healthCategory: string;
  healthCode: string;
  compImgUrl: string;
  companyCode: string;
}

export interface LifeBaseInfo {
  state_fromzipcode: string;
  Birthdate: BirthDate;
  faceamount: string;
  health: string;
  healthtxt: string;
  sex: string;
  smoker: string;
  category: string;
  Sort: string;
  Mode: string;
  FaceAmount: number;
  Health: string;
  NewCategory: string;
  REMOTE_IP: string;
  Smoker: string;
  State: number;
  ZipCode: string;
  title: string;
}

interface BirthDate {
  day: string;
  month: string;
  year: string;
  NearestAge: string;
  ActualAge: string;
}

export const LIFE_AMOUNTS = [
  100, 250, 500, 750, 1000, 1500, 2000, 3000, 4000, 5000,
];

export const healthText = `
<p>
<span>Preferred Plus</span> -
 This is if you have a good health history, you're not on any medications,
  and not considered overweight based on a healthy body mass index (BMI). You also have no family history of diseases
  and have not used any nicotine or tobacco-based products within the last five years.
  </p><p>
  <span>Preferred</span> - You are in good health but take medication for the treatment of a minor condition, 
 such as controlled cholesterol. You are not overweight.
  You may have a family history of cancer or other diseases.
  and have not used any nicotine or tobacco-based products within the last three years.
   You also possibly participate in dangerous occupations such as risk sports or risky work occupations.
   </p><p>
   <span>Standard Plus</span> - You are in better than average health, but may have minor health issues. 
You may be slightly overweight, but you also have no family history of diseases,
and have not used any nicotine or tobacco-based products within the last three years.
</p><p>
<span>Standard</span> - Most common category. You are of average health and take multiple medications.
 You might be considered overweight, and may have a family history of cancer or other diseases.
 </p>`;
