import makeStyles from "@mui/styles/makeStyles";
import insuranceLogosBanner from "../../assets/img/insurance-logos-banner.jpg";

export const useStyles = makeStyles((theme) => ({
  customQuoteBtn: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: "1px",
    padding: 0,
    color: theme.palette.primary.main,
    position: "absolute",
    bottom: 20,
    right: 64,
    transition: "opacity 300ms linear",
    "& svg": {
      marginRight: 5,
      fontSize: 20,
    },
    "&:hover": {
      background: "transparent",
      opacity: 0.5,
    },
  },
  content: {
    padding: "24px 40px",
    width: "calc(100vw - 300px)",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw - 165px)",
      padding: "24px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: 16,
    },
  },
  helpText: {
    fontSize: 14,
    marginBottom: 12,
    textAlign: "right",
    position: "sticky",
    top: 0,
    zIndex: 3,
    "& a": {
      color: "inherit",
      fontWeight: 600,
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      top: 24,
    },
  },
  mobileHeader: {
    borderBottom: "1px solid #DADFF6",
    display: "flex",
    margin: "-16px -16px 0",
    padding: 16,
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: 2,
  },
  menuIconWrapper: {
    margin: -16,
    padding: 16,
    height: 24,
    boxSizing: "content-box",
  },
  startOverBtn: {
    position: "fixed",
    top: 100,
    right: 30,
    height: 40,
    zIndex: theme.zIndex.modal,
    [theme.breakpoints.down("sm")]: {
      top: -36,
      position: "absolute",
      right: 48,
      color: theme.palette.primary.main,
      fontSize: 13,
      fontWeight: 600,
      letterSpacing: 1,
      backgroundColor: "transparent",
      boxShadow: "none",
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    },
  },
  wizardTitleContainer: {
    background: "#fff",
    position: "sticky",
    top: 0,
    zIndex: 2,
    transition: "all 300ms linear",
    [theme.breakpoints.up("sm")]: {
      "&.scrolled": {
        paddingTop: 32,
        "& $agentIcon": {
          height: 56,
          width: 56,
          marginBottom: 0,
        },
        "& $title": {
          fontSize: 24,
        },
        "&:after": {
          content: "''",
          background:
            "linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0) 100%)",
          height: 80,
          width: "100%",
          position: "absolute",
          bottom: -80,
          pointerEvents: "none",
          [theme.breakpoints.down("md")]: {
            width: "100%",
          },
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: -28,
      background: "transparent",
      top: 29,
    },
  },
  agentIcon: {
    height: 80,
    width: 80,
    marginBottom: 16,
    transition: "all 300ms linear",
    position: "relative",
    borderRadius: "100%",
    [theme.breakpoints.down("md")]: {
      height: 64,
      width: 64,
    },
    [theme.breakpoints.down("sm")]: {
      height: 48,
      width: 48,
    },
  },
  title: {
    fontSize: 32,
    fontWeight: 300,
    textAlign: "center",
    maxWidth: 700,
    [theme.breakpoints.down("md")]: { fontSize: 28 },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      background: "#fff",
      width: "100%",
      margin: "-36px -16px 0",
      padding: "32px 16px 0",
      "&:after": {
        content: "''",
        background:
          "linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0) 100%)",
        position: "absolute",
        width: "100%",
        height: 40,
        bottom: -40,
        left: 0,
        pointerEvents: "none",
      },
    },
  },
  startOverIcon: {
    color: theme.palette.secondary.main,
    fontSize: 32,
    position: "fixed",
    right: 40,
    top: 80,
    zIndex: 1,
    padding: 0,
    [theme.breakpoints.down("md")]: {
      right: 24,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      right: 16,
      top: 16,
    },
  },
  wizardStepContent: {
    margin: "40px auto",
    "& .maxWidth": {
      maxWidth: 640,
      margin: "0 auto",
    },
  },
  backdrop: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 280,
    zIndex: theme.zIndex.modal + 200,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 160,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  quotesLoadingBackground: {
    right: 0,
    bottom: 0,
    paddingBottom: 120,
    width: "calc(100vw - 280px)",
    height: "calc(100vh - 40px)",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw - 160px)",
      height: "calc(100vh - 64px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100vh",
      padding: "0 16px 120px",
    },
  },
  insuranceLogosBanner: {
    height: 60,
    marginTop: 120,
    position: "absolute",
    width: "calc(100vw - 360px)",
    right: 40,
    background: `transparent url(${insuranceLogosBanner})`,
    backgroundSize: "cover",
    animation: "$infiniteShiftLeft 500s linear",
    "&:before": {
      content: "''",
      background:
        "linear-gradient(270deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)",
      height: 80,
      width: 172,
      display: "block",
    },
    "&:after": {
      content: "''",
      background:
        "linear-gradient(270deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)",
      height: 80,
      width: 172,
      transform: "scaleX(-1)",
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
    },
    [theme.breakpoints.down("md")]: {
      width: "calc(100vw - 248px)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100vw - 80px)",
    },
  },
  "@keyframes infiniteShiftLeft": {
    "0%": {
      backgroundPosition: 50000,
    },
    "100%": {
      backgroundPosition: 0,
    },
  },
  quotesLoadingIcon: {
    position: "relative",
    "& > div": {
      position: "relative",
      zIndex: 1,
    },
    "&:before": {
      content: "''",
      // TODO: need to fix for reli
      // background:
      //   "linear-gradient(270deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)",
      height: 80,
      width: 172,
      display: "block",
      position: "absolute",
      transform: "scaleX(-1)",
      top: "50%",
      marginTop: -40,
      left: -22,
    },
    "&:after": {
      content: "''",
      // TODO: need to fix for reli
      // background:
      //   "linear-gradient(270deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)",
      height: 80,
      width: 172,
      display: "block",
      position: "absolute",
      top: "50%",
      marginTop: -40,
      right: -22,
    },
  },
  quotesLoadingText: {
    fontSize: 32,
    fontWeight: 300,
    marginTop: -48,
    marginBottom: 16,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  quotesLoadingSubtext: {
    fontSize: 15,
    textAlign: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    zIndex: theme.zIndex.modal + 100,
    overflow: "auto",
    maxWidth: 780,
    maxHeight: "90vh",
    padding: "30px 40px 40px",
    borderRadius: 3,
    backgroundColor: "#FFF",
    boxShadow: "0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)",
  },
  backdrop_custom: {
    zIndex: theme.zIndex.modal,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  //sidebar content styles
  sidebarHeader: {
    width: "100%",
  },
  sidebarTitle: {
    marginBottom: 16,
    fontSize: 20,
    fontWeight: 500,
    color: "#fff",
  },
  sidebarItemWrap: {
    marginBottom: 30,
  },
  sidebarItem: {
    fontSize: 16,
    fontWeight: 300,
    color: "#fff",
    // cursor: ({ editDisabled }: any) => (!editDisabled ? "pointer" : ""),
    opacity: 0.75,
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    "&:hover": {
      // opacity: ({ editDisabled }: any) => (!editDisabled ? 1 : ""),
    },
    "&:hover .MuiSvgIcon-root": {
      opacity: 1,
    },
  },
  sidebarItemText: {
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  sidebarItemIcon: {
    fontSize: 24,
    marginLeft: 16,
    opacity: 0,
    transition: "opacity 300ms linear",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
      marginLeft: 8,
    },
  },
  oval: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 81,
    height: 81,
    marginBottom: 23,
    border: "1px solid #DADFF6",
    color: "#EFF1FB",
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
    borderRadius: "50%",
  },
  ovalWrapper: {
    height: "100%",
  },
  wizardStepImgWrapper: {
    height: 272,
    maxHeight: "60%",
    [theme.breakpoints.down("md")]: {
      height: 136,
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-height: 600px)"]: { display: "none" },
  },
  poweredBy: {
    backgroundColor: "#fff",
    position: "fixed",
    right: 0,
    bottom: 95,
    width: 101,
    height: 96,
    borderRadius: 3,
    padding: 12,
    background: theme.palette.primary.main,
    boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
    zIndex: 99999999,
    textAlign: "center",
    "& .MuiTypography-root": {
      color: "#000000",
      fontSize: 10,
    },
    "& a, a:hover": {
      textDecoration: "none",
    },
    "& img": {
      width: "80%",
    },
    [theme.breakpoints.down("md")]: {
      padding: 8,
      bottom: 16,
      width: 90,
      height: 90,
      top: "65%",
      "& .MuiTypography-root": {
        fontSize: 8,
      },
      "& img": {
        width: "60%",
      },
    },
  },
}));
