import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  box: {
    minWidth: 550,
    // minHeight: 370,
    borderRadius: 3,
    position: "absolute",
    overflow: "scroll",
    top: "50%",
    left: "50%",
    width: 550,
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 32px 0px rgba(0,0,0,0.08)",
    padding: 30,
    maxHeight: 742,
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: 550,
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  // img: {
  //   width: 88,
  //   height: 104,
  // },
  title: {
    fontSize: 24,
    fontWeight: 600,
  },
  input: {
    maxWidth: "65%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "85%",
    },
  },
  selectBtn: {
    fontWeight: 600,
    letterSpacing: "1px",
    transition: "all 300ms linear",
    height: 48,
    width: 120,
    color: "#fff",
    marginBottom: 16,
  },
  cancelBtn: {
    fontWeight: 600,
    letterSpacing: "1px",
    transition: "all 300ms linear",
    height: 48,
    width: 120,
    marginBottom: 16,
  },
  closeBtn: {
    display: "flex",
    marginLeft: "auto",
    paddingRight: 0,
    color: "#a3a8bf",
    width: 24,
    height: 24,
    "& .MuiSvgIcon-root": {
      width: 24,
      height: 24,
    },
  },
  inputWrapper: {
    width: "85%",
    textAlign: "center",
  },
  carrierImg: {
    height: 40,
    margin: "auto 0",
    maxWidth: 117,
  },
  quoteData: {
    display: "flex",
    width: "80%",
    justifyContent: "space-between",
    margin: 30,
  },
  premium: {
    fontSize: 40,
    color: theme.palette.primary.main,
  },
  level: {
    fontSize: 14,
    color: "black",
    display: "block",
    fontFamily: "Montserrat",
    fontWeight: 600,
  },
  carrierName: {
    fontSize: 17,
    color: theme.palette.primary.main,
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 20,
    },
  },
  purchaseTitle: {
    fontWeight: 600,
    margin: "35px 0 20px 0",
  },
  purchaseLevel: {
    color: "#8D98C2",
    fontSize: 14,
  },
  priceLabel: {
    color: "#8D98C2",
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 500,
  },
  priceInput: {
    width: "100%",
    border: "1px solid #8D98C2",
    borderRadius: 3,
    height: 48,
    fontSize: 16,
    fontFamily: "Montserrat",
    paddingLeft: 10,
    "&:focus-visible": {
      outline: "none",
    },
  },
  bundlePriceWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  bundlePriceContainer: { width: "46%", marginTop: 25 },
  bundlePriceInput: {
    width: "100%",
    border: "1px solid #8D98C2",
    borderRadius: 3,
    height: 48,
    fontSize: 16,
    fontFamily: "Montserrat",
    paddingLeft: 10,
    "&:focus-visible": {
      outline: "none",
    },
  },
  purchaseType: {
    fontWeight: 600,
    fontSize: 16,
  },
  purchaseData: {
    // width: "80%",
    padding: "6% 10%",
    margin: "auto",
    marginBottom: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #DADFF6",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 20px",
    },
  },
  bundleQuote: {
    border: "1px solid #DADFF6",
    width: "46%",
    borderRadius: 3,
    // padding: 25,
    display: "flex",
    flexDirection: "column",
  },
  bundleImg: {
    height: 30,
    margin: "22px 0 10px 22px",
    [theme.breakpoints.down("sm")]: {
      margin: "22px 0 10px 10px",
    },
  },
  bundleBottom: {
    margin: "0 0 22px 22px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 22px 10px",
    },
  },
  commentsWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
  },
  picker: { marginTop: 20 },
  bundleWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  dateInputWrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  commentsTitle: {
    fontSize: 20,
    fontWeight: 600,
    // margin: 15,
    marginTop: 0,
    marginBottom: 25,
    // textAlign: "center",
  },
  singleSubTitle: { fontSize: 16 },
  singleCheck: { margin: "20px 0 25px 10px" },
  commentsInput: {
    width: "100%",
    "& .MuiInputBase-root .MuiOutlinedInput-input ": {
      height: 100,
      padding: 0,
    },
    marginTop: 20,
  },
  titleInput: {},
  statusDropdown: {
    height: 24,
    width: 150,
    borderRadius: 3,
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    marginBottom: 24,
    marginRight: 25,
    alignSelf: "start",
  },
  subStatusDropdown: {
    height: 24,
    width: 315,
    borderRadius: 3,
    color: theme.palette.primary.main,
    fontFamily: "Montserrat",
    alignSelf: "start",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "15px 0",
    },
  },
  commentsButtons: {
    display: "flex",
    justifyContent: "end",
    alignSelf: "end",
    marginTop: 20,
  },
  alert: {
    width: "100%",
    maxWidth: "100%",
    margin: "20px 0 0 0",
    backgroundColor: "#f2f4f9",
    opacity: 0.8,
    color: "#000000",
    "& .MuiAlert-icon": {
      color: "#000000",
    },
  },
  notificationText: {
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 10,
    },
  },
  notification: {
    marginTop: 20,
  },
  checkBox: {
    marginLeft: 10,
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
      fontWeight: 500,
    },
  },
  notificationInputs: {
    display: "flex",
    margin: "15px 0",
  },
  notificationNum: {
    width: 70,
    marginRight: 30,
  },
  notificationInterval: {
    width: 190,
  },
  calendarInputs: {
    width: 230,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  invite: {
    "& div:first-child": {
      display: "flex",
    },

    // justifyContent: "space-between",
  },
  calendarActions: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    "& .MuiTypography-root": {
      fontWeight: 500,
      textTransform: "uppercase",
      fontSize: "inherit",
      cursor: "pointer",
    },
    "& .MuiButtonBase-root": {
      width: 90,
      marginLeft: 15,
    },
    "& .MuiSvgIcon-root": {
      marginRight: 15,
    },
  },
  scheduleTop: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  schedule: {
    border: ".5px solid #8D98C2",
    borderRadius: 3,
    width: 490,
    minHeight: 94,
    padding: 15,
    marginBottom: 15,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  controls: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& .MuiTypography-root": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
      fontSize: 14,
      marginLeft: 10,
    },
    "& .MuiTextField-root": {
      width: 0,
      height: 0,
      border: "none",
      "& .MuiInputAdornment-root": {
        display: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
    },
  },
  event: {
    display: "flex",
    alignItems: "center",
    padding: 12,
    border: ".5px solid #8D98C2",
    borderRadius: 3,
    marginBottom: 10,
    "&:last-child": {
      marginBottom: 0,
    },
    "& p": {
      fontSize: 12,
    },
    "& p:first-child": {
      color: theme.palette.primary.main,
      backgroundColor: "#d4ddff",
      padding: 5,
      borderRadius: 3,
      fontWeight: 500,
      marginRight: 12,
      width: 130,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        padding: 1,
        marginBottom: 3,
      },
    },
    "& p:nth-child(2)": {
      fontWeight: 600,
      overflow: "hidden",
      width: "60%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: 5,
    },
  },
  noEvents: {
    fontWeight: 500,
  },
  attendees: {
    display: "flex",
    gap: 8,
    margin: "0 0 20px 40px",
    flexWrap: "wrap",
    maxWidth: 450,
    "& div": {
      display: "flex",
      alignItems: "center",
      padding: 2,
      backgroundColor: "#F3F6FD",
      borderRadius: 3,
      "& .MuiSvgIcon-root": {
        height: 18,
        verticalAlign: "middle",
        cursor: "pointer",
        marginLeft: 5,
      },
    },
  },
}));
