import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  adminSelectors,
  wizardActions,
  wizardSelectors,
} from "../../state";
import { useStyles } from "./ModalCarrier.styles";
import { IconButton, Box, Modal, Paper } from "@mui/material";
import { CloseIcon } from "../icons";
import { healthText } from "../../state/life/types";

export default function ModalCarrier() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isCarrierModal = useSelector(adminSelectors.carrierModalOpen);
  const isExplainerModal = useSelector(wizardSelectors.explainerModalOpen);
  const isOpen = isCarrierModal || isExplainerModal;

  const healthExplainer = healthText;
  const carrierNotes = useSelector(adminSelectors.carrierNotes);

  function createMarkup() {
    return { __html: isCarrierModal ? carrierNotes : healthExplainer };
  }

  function CarrierNotes() {
    return <div dangerouslySetInnerHTML={createMarkup()} />;
  }
  const handleCloseCarrierModal = () => {
    dispatch(adminActions.setCarrierModalOpen(false));
    dispatch(wizardActions.setExplainerModalOpen(false));
  };
  return (
    <Modal open={isOpen} onClose={handleCloseCarrierModal}>
      <Paper className={classes.paper}>
        <Box className={classes.closeIconWrapper}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseCarrierModal}
            size="large">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Box>
        <Box className={classes.textWrapper}>
          <CarrierNotes />
        </Box>
        {/* <Box>{healthExplainer}</Box> */}
      </Paper>
    </Modal>
  );
}
