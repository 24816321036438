import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import ReactGA from "react-ga";
import { App } from "./App";
import "./assets/css/styles.css";
import {
  ErrorSentryFallBack,
  init,
  setUser,
} from "./components/errors/ErrorSentry";
import "./polyfill";
import "./utils/liveChat";
import { authActions, store } from "./state";
import "./utils/socket";
import "./utils/fingerprint";
import { CustomThemeProvider } from "./themes/CustomThemeProvider";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { REACT_APP_MUI_LICENSE } from "./config";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { CssBaseline } from "@mui/material";
LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE!);
function render() {
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={ErrorSentryFallBack} showDialog>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ReduxProvider store={store}>
          <CustomThemeProvider>
            <CssBaseline />
            <App />
          </CustomThemeProvider>
        </ReduxProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>,
    document.getElementById("root"),
  );
}

function main() {
  const dispatch: any = store.dispatch;
  dispatch(authActions.load({}));
  init();
  const state: any = store.getState();
  if (state.auth?.user) {
    setUser({ email: state.auth?.user?.email, id: state.auth?.user?.id });
  }
  ReactGA.initialize("UA-190162613-1");
  setTimeout(() => {
    const location = window.location;
    const page = `${location.pathname}${location.search}${location.hash}`;
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }, 2000);
  render();
}

main();
