import React from "react";

export const TowingImage = ({ fillColor }) => {
  return (
    <svg
      width="84px"
      height="84px"
      viewBox="0 0 84 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>undraw_towing_-6-yy4 2</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="84" height="84"></rect>
      </defs>
      <g
        id="↳-auto-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Create-a-custom-quote-side-bar"
          transform="translate(-584.000000, -1388.000000)"
        >
          <g
            id="undraw_towing_-6-yy4-2"
            transform="translate(584.000000, 1388.000000)"
          >
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use id="Rectangle" fill="#FFFFFF" xlinkHref="#path-1"></use>
            <g id="Group-6" mask="url(#mask-2)">
              <g transform="translate(-47.000000, 14.000000)">
                <rect
                  id="Rectangle"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  x="56.8347657"
                  y="51.6219628"
                  width="84.5400061"
                  height="7.48839258"
                ></rect>
                <g
                  id="Group-5"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(48.262528, 15.165316)"
                >
                  <rect
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    x="56.2614737"
                    y="2.36475624"
                    width="1.97062911"
                    height="13.991469"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    x="77.5442716"
                    y="30.5447577"
                    width="17.1444762"
                    height="6.70013996"
                  ></rect>
                  <polygon
                    id="Path"
                    fill="#D0CDE1"
                    fillRule="nonzero"
                    points="79.2193323 40.2007974 58.9218098 40.2007974 58.9218098 14.6812248 74.0957285 14.6812248 79.2193323 27.551987"
                  ></polygon>
                  <rect
                    id="Rectangle"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    x="62.07483"
                    y="16.2576942"
                    width="8.67076907"
                    height="8.67076907"
                  ></rect>
                  <polygon
                    id="Path"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    points="77.2486153 25.0270415 71.7309006 25.0270415 71.7309006 16.3562773 74.0957285 16.3562773"
                  ></polygon>
                  <rect
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    x="62.4689555"
                    y="29.8550377"
                    width="1.97062911"
                    height="3.74419629"
                  ></rect>
                  <path
                    d="M73.4059531,21.8739919 L73.5044857,21.8739919 C74.5384161,21.8739919 75.3765822,22.7121581 75.3765822,23.7460884 L75.3765822,23.7460884 C75.3765822,24.7800188 74.5384161,25.618185 73.5044857,25.618185 L73.4059531,25.618185 L73.4059531,21.8739919 Z"
                    id="Path"
                    fill="#3F3D56"
                    fillRule="nonzero"
                  ></path>
                  <g
                    id="Group-4"
                    transform="translate(0.000000, 35.569863)"
                    fillRule="nonzero"
                  >
                    <path
                      d="M0.0985309674,0.886783588 L0.197063562,0.886783588 C1.23099392,0.886783588 2.06916008,1.72494975 2.06916008,2.75888011 L2.06916008,2.75888011 C2.06916008,3.79281046 1.23099392,4.63097662 0.197063562,4.63097662 L0.0985309674,4.63097662 L0.0985309674,0.886783588 Z"
                      id="Path"
                      fill="#3F3D56"
                    ></path>
                    <circle
                      id="Oval"
                      fill="#3F3D56"
                      cx="9.85314719"
                      cy="8.07958164"
                      r="7.48839258"
                    ></circle>
                    <circle
                      id="Oval"
                      fill="#D0CDE1"
                      cx="9.85314719"
                      cy="8.07958164"
                      r="3.94125822"
                    ></circle>
                    <rect
                      id="Rectangle"
                      fill="#3F3D56"
                      x="0"
                      y="0"
                      width="59.5130119"
                      height="1.77356718"
                    ></rect>
                  </g>
                  <path
                    d="M87.1018243,36.4283723 C90.1048221,36.4283723 92.8059573,37.8831799 94.6802082,40.2008528 L98.5314767,40.2008528 L94.7872804,29.7710719 L77.3472081,26.3314703 L77.8398662,40.2008528 L79.5234404,40.2008528 C81.3976913,37.8831783 84.0988282,36.4283723 87.1018243,36.4283723 Z"
                    id="Path"
                    fill="#D0CDE1"
                    fillRule="nonzero"
                  ></path>
                  <circle
                    id="Oval"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    cx="87.2988879"
                    cy="42.6641302"
                    r="8.5722381"
                  ></circle>
                  <rect
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    x="55.3746901"
                    y="11.1340579"
                    width="4.13832179"
                    height="29.8550377"
                  ></rect>
                  <rect
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    x="72.6176972"
                    y="25.4211214"
                    width="1.97062911"
                    height="1"
                  ></rect>
                  <circle
                    id="Oval"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    cx="57.2467882"
                    cy="2.75888173"
                    r="2.75888173"
                  ></circle>
                </g>
                <polygon
                  id="Rectangle"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(87.596477, 10.743855) rotate(-67.908490) translate(-87.596477, -10.743855) "
                  points="86.6111625 -9.25614459 88.5817917 -9.25614459 88.5817917 30.7438554 86.6111625 30.7438554"
                ></polygon>
                <rect
                  id="Rectangle"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  x="65.8077714"
                  y="1.77356718"
                  width="1.97062911"
                  height="10"
                ></rect>
                <circle
                  id="Oval"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  cx="66.793086"
                  cy="2.75888173"
                  r="2.75888173"
                ></circle>
                <path
                  d="M69.6031582,31.8275492 L69.6013292,31.8275492 L63.9441413,31.7742417 C63.8882897,31.7737243 63.8352804,31.7495334 63.7982914,31.7076826 C63.7613024,31.6658319 63.7438077,31.6102521 63.7501564,31.5547601 L66.6895529,5.90742034 C66.7009789,5.8079147 66.7852057,5.73277814 66.8853652,5.73277814 L66.8862308,5.73277814 C66.9867221,5.73324395 67.0707811,5.80922037 67.0813694,5.90915332 L69.799164,31.6097989 C69.8050163,31.6653576 69.7870169,31.7207899 69.7496414,31.7623121 C69.712266,31.8038342 69.6590258,31.8275492 69.6031598,31.8275492 L69.6031582,31.8275492 Z M64.1667032,31.3822332 L69.3839645,31.4313067 L66.8774732,7.72929783 L64.1667032,31.3822332 Z"
                  id="Shape"
                  fill="#3F3D56"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M163.046687,66.3343715 C159.994202,71.4336143 -0.242527221,71.0055484 0,66.3340688 C3.05244453,61.2351287 163.289182,61.6631962 163.046687,66.3343715 Z"
                  id="Path"
                  fill="#D0CDE1"
                  fillRule="nonzero"
                ></path>
                <rect
                  id="Rectangle"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  x="35.4670858"
                  y="48.3843457"
                  width="67.262105"
                  height="1.77356718"
                ></rect>
                <g
                  id="Group-Copy"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(54.918494, 24.000000)"
                >
                  <rect
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    x="0.205907587"
                    y="19.2413793"
                    width="4.94178208"
                    height="1"
                  ></rect>
                  <path
                    d="M40.9756098,19.3959352 L39.9516629,19.6342457 L1.03687528,19.6551724 L0.686941448,18.8349203 C0.0766338328,17.4279225 -0.132838751,15.8779201 0.081923555,14.3580614 C0.249524155,13.2252153 0.669508841,12.0995698 1.62340992,11.6106581 L3.35031232,0.03876376 L24.8798659,0 L33.2095695,6.75922769 C33.2095695,6.75922769 37.1388138,6.68816447 39.2734591,9.36181198 C39.9490152,10.2086713 40.4451084,11.3306248 40.578235,12.8181339 L40.6624788,14.2089854 L40.9756098,19.3959352 Z"
                    id="Path"
                    fill={fillColor}
                    fillRule="nonzero"
                  ></path>
                  <ellipse
                    id="Oval"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    cx="32.4304449"
                    cy="19.137931"
                    rx="4.83882829"
                    ry="4.86206897"
                  ></ellipse>
                  <ellipse
                    id="Oval"
                    fill="#CCCCCC"
                    fillRule="nonzero"
                    cx="32.4304449"
                    cy="19.137931"
                    rx="2.57384483"
                    ry="2.5862069"
                  ></ellipse>
                  <ellipse
                    id="Oval"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    cx="8.75107244"
                    cy="19.137931"
                    rx="4.83882829"
                    ry="4.86206897"
                  ></ellipse>
                  <ellipse
                    id="Oval"
                    fill="#CCCCCC"
                    fillRule="nonzero"
                    cx="8.75107244"
                    cy="19.137931"
                    rx="2.57384483"
                    ry="2.5862069"
                  ></ellipse>
                  <polygon
                    id="Path"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    points="29.8566001 5.9930997 18.2319251 5.99827492 16.812908 5.99827492 14.4169041 6 14.4169041 5.82128218 14.4152176 3.6663179 14.4135311 1.24655454 16.0074378 1.24655454 17.426455 1.24482946 19.9401762 1.24310439 21.3591934 1.24310439 24.2611459 1.24137931"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    points="12.5603628 5.9965486 6.3865248 6 6.38482999 4.76646888 6.38482999 2.61089568 6.38313519 1.24483071 7.28714452 1.24483071 8.71315398 1.24310501 12.558668 1.24137931"
                  ></polygon>
                  <rect
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    x="14.4135311"
                    y="9.51724138"
                    width="1"
                    height="1.44827586"
                  ></rect>
                  <polygon
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    transform="translate(27.534969, 7.738369) rotate(89.969370) translate(-27.534969, -7.738369) "
                    points="27.1395185 6.99060625 27.9304162 6.99060827 27.9304201 8.48613113 27.1395224 8.48612911"
                  ></polygon>
                  <path
                    d="M27.385709,4.34482759 L27.4680734,4.34482759 C28.3323418,4.34482759 29.0329697,5.08587352 29.0329697,6 L29.0329697,6 C29.0329697,6.91412648 28.3323418,7.65517241 27.4680734,7.65517241 L27.385709,7.65517241 L27.385709,4.34482759 Z"
                    id="Path"
                    fill="#3F3D56"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M40.7697022,14.2610462 C39.5126706,14.3927354 38.3394189,13.6345628 37.9891431,12.4642078 C37.6388672,11.2938527 38.2130477,10.0503858 39.3499276,9.51724138 C40.0404415,10.3460409 40.5475188,11.4440682 40.6835931,12.8998553 L40.7697022,14.2610462 Z"
                    id="Path"
                    fill="#3F3D56"
                    fillRule="nonzero"
                  ></path>
                  <polygon
                    id="Path"
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    points="17.5021449 1.24137931 14.4152609 5.79310345 14.4135311 3.64978339 16.0466648 1.24309506"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    points="8.85402623 1.24137931 6.58904277 4.75862069 6.58904277 2.60679319 7.46681042 1.24310201"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    points="21.414389 1.24137931 18.2983458 6 16.8844221 6 20.0004653 1.24137931"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
