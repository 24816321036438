import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Box,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, yup } from "../../../../lib";

import { states } from "../../../../assets/data/us_states";

import { useStyles } from "../AgencySettingsPage.styles";
import { AddressAsyncAutocomplete, EditIcon } from "../../../../components";
import { Agency } from "../../../../state/admin/state";
import { formatPhone, phoneRegExp } from "../../../../utils";
import { useParams } from "react-router";

const validationSchema = yup.object({
  phone: yup
    .string()
    .matches(phoneRegExp, "Enter a valid phone number")
    .min(10, "Enter a valid phone number")
    .required("Phone number is required"),
  email_to: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  email_address: yup
    .string()
    .email("Enter a valid email")
    .required("Email from address is required"),
  address: yup.object({
    street_line: yup.string().required("Street is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zipcode: yup.string().required("Zip is required"),
  }),
  country: yup.string().required("Country is required"),
});

const ContactInfoCard = () => {
  const [inEditMode, setInEditMode] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const activeAgencyId = params.id
    ? Number(params.id)
    : useSelector(authSelectors.activeAgencyId);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const fullAgencyData: Agency = useSelector(adminSelectors.activeAgencyData);

  const {
    phone = "",
    email_to = "",
    email_address = "",
    street = "",
    suite = "",
    city = "",
    state = "",
    zip = "",
    country = "",
    location = { type: "Point", coordinates: [] },
  } = useSelector(adminSelectors.activeAgencyData);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone,
      email_to,
      email_address,
      suite,
      country,
      address: {
        street_line: street,
        city,
        state,
        full_state: "",
        zipcode: zip,
        label: "",
        location,
      },
    },
    validationSchema,
    async onSubmit(values) {
      const agencyData = {
        ...fullAgencyData,
        phone: values.phone,
        email_to: values.email_to,
        email_address: values.email_address,
        street: values.address.street_line,
        city: values.address.city,
        state: values.address.state,
        zip: values.address.zipcode,
        suite: values.suite,
        country: values.country,
        location: values.address.location,
      };
      dispatch(
        adminActions.updateAgency(activeAgencyId, agencyData, isSuperAdmin),
      );
      setInEditMode(false);
      form.resetForm();
    },
  });

  const handleCancel = () => {
    setInEditMode(false);
    form.resetForm();
  };
  useEffect(() => {
    dispatch(adminActions.getAgency(activeAgencyId));
  }, []);

  const showEditButton = !inEditMode;

  if (!activeAgencyId) return null;

  const SectionTitle = ({ title }) => {
    return (
      <Box className={classes.sectionTitleWrapper}>
        <Typography className={classes.sectionTitle}>{title}</Typography>
        {showEditButton && (
          <Button
            className={classes.editBtnWrapper}
            onClick={() => setInEditMode(!inEditMode)}
          >
            <EditIcon style={{ color: "white" }} />
          </Button>
        )}
      </Box>
    );
  };

  const Field = ({ fieldName, fieldValue }) => {
    return (
      <Box className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle}>{fieldName}</Typography>
        <Typography className={classes.field}>{fieldValue}</Typography>
      </Box>
    );
  };

  return (
    <>
      {inEditMode ? (
        <Card variant="outlined" className={classes.section}>
          <form id="contactInfoForm" onSubmit={form.handleSubmit}>
            <SectionTitle title={"Contact information"} />
            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"phone"}
                name={"phone"}
                label={"Phone"}
                variant="outlined"
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                value={form.values.phone}
                onChange={form.handleChange}
                error={form.touched.phone && Boolean(form.errors.phone)}
                helperText={form.touched.phone && form.errors.phone}
              />
            </Box>
            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"email_to"}
                name={"email_to"}
                label={"Email"}
                variant="outlined"
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                value={form.values.email_to}
                onChange={form.handleChange}
                error={form.touched.email_to && Boolean(form.errors.email_to)}
                helperText={form.touched.email_to && form.errors.email_to}
              />
            </Box>
            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"email_address"}
                name={"email_address"}
                label={"Email From Address"}
                variant="outlined"
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                value={form.values.email_address}
                onChange={form.handleChange}
                error={
                  form.touched.email_address &&
                  Boolean(form.errors.email_address)
                }
                helperText={
                  form.touched.email_address && form.errors.email_address
                }
              />
            </Box>

            <Box className={classes.textFieldWrapper}>
              <Typography className={classes.addressTitle}>Address</Typography>
              <AddressAsyncAutocomplete form={form} />
            </Box>

            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"address.street_line"}
                name={"address.street_line"}
                label={"Street"}
                variant="outlined"
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                value={form.values.address.street_line}
                onChange={form.handleChange}
                error={
                  form.touched.address?.street_line &&
                  Boolean(form.errors.address?.street_line)
                }
                helperText={
                  form.touched.address?.street_line &&
                  form.errors.address?.street_line
                }
              />
            </Box>
            <Box className={classes.textFieldsWrapper}>
              <TextField
                id={"suite"}
                name={"suite"}
                label={"Unit / Suite"}
                variant="outlined"
                value={form.values.suite}
                onChange={form.handleChange}
                error={form.touched.suite && Boolean(form.errors.suite)}
                helperText={form.touched.suite && form.errors.suite}
              />
            </Box>
            <Box className={classes.textFieldsWrapper}>
              <TextField
                id={"address.city"}
                name={"address.city"}
                label={"City"}
                variant="outlined"
                value={form.values.address.city}
                onChange={form.handleChange}
                style={{ maxWidth: "70%" }}
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                error={
                  form.touched.address?.city &&
                  Boolean(form.errors.address?.city)
                }
                helperText={
                  form.touched.address?.city && form.errors.address?.city
                }
              />
              <TextField
                select
                id={"address.state"}
                name={"address.state"}
                label={"State"}
                variant="outlined"
                value={form.values.address.state}
                style={{ maxWidth: "25%" }}
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                onChange={form.handleChange}
                error={
                  form.touched.address?.state &&
                  Boolean(form.errors.address?.state)
                }
                helperText={
                  form.touched.address?.state && form.errors.address?.state
                }
              >
                {states.map((state) => (
                  <MenuItem key={state.abbr} value={state.abbr}>
                    {state.abbr}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box className={classes.textFieldsWrapper}>
              <TextField
                id={"address.zipcode"}
                name={"address.zipcode"}
                label={"Zip"}
                variant="outlined"
                value={form.values.address.zipcode}
                onChange={form.handleChange}
                style={{ maxWidth: "40%" }}
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                error={
                  form.touched.address?.zipcode &&
                  Boolean(form.errors.address?.zipcode)
                }
                helperText={
                  form.touched.address?.zipcode && form.errors.address?.zipcode
                }
              />
              <TextField
                id={"country"}
                name={"country"}
                label={"Country"}
                variant="outlined"
                value={form.values.country}
                style={{ maxWidth: "55%" }}
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                onChange={form.handleChange}
                error={form.touched.country && Boolean(form.errors.country)}
                helperText={form.touched.country && form.errors.country}
              />
            </Box>
            <Box className={classes.submit}>
              <Button
                variant="contained"
                onClick={() => handleCancel()}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitBtn}
                // disabled={!form.dirty || !form.isValid}
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      ) : (
        <Card
          variant="outlined"
          className={classes.section}
          style={{ minHeight: 438 }}
        >
          <SectionTitle title={"Contact information"} />
          <Field fieldName={"Phone"} fieldValue={formatPhone(phone)} />
          <Field fieldName={"Email"} fieldValue={email_to} />
          <Field fieldName={"Email From Address"} fieldValue={email_address} />
          <Box className={classes.fieldWrapper}>
            <Typography className={classes.fieldTitle}>Address</Typography>
            <Typography className={classes.field}>
              {street}
              {suite ? `, ${suite}` : ""}
            </Typography>
            <Typography className={classes.field}>
              {city}
              {","} {state} {zip}
            </Typography>
            <Typography className={classes.field}>{country}</Typography>
          </Box>
        </Card>
      )}
    </>
  );
};

export default ContactInfoCard;
