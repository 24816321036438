import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  box: {
    width: 650,
    borderRadius: 3,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 32px 0px rgba(0,0,0,0.08)",
    padding: 40,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    [theme.breakpoints.down('md')]: {
      width: "100%",
      maxWidth: 650,
    },
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  img: {
    width: 88,
    height: 104,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 20,
  },
  input: {
    maxWidth: "60%",
  },
  selectBtn: {
    fontWeight: 600,
    letterSpacing: "1px",
    transition: "all 300ms linear",
    height: 40,
    width: 120,
    color: "#fff",
    marginTop: 28,
    marginBottom: 16,
  },
  closeBtn: {
    display: "flex",
    marginLeft: "auto",
    paddingRight: 0,
    color: "#a3a8bf",
    width: 24,
    height: 24,
    "& .MuiSvgIcon-root": {
      width: 24,
      height: 24,
    },
  },
}));
