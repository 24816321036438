import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  Chip,
  Typography,
  Box,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useFormik } from "../../lib";

import { useStyles } from "./EditUsersPage.styles";
import { useParams } from "react-router";
import { DeleteIcon } from "../../components";

const initialValues = {
  id: -1,
  first_name: "",
  last_name: "",
  email: "",
  role: "",
  phone: "",
  directorAgencies: [1],
};

const EditUsersPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const agencies = useSelector(adminSelectors.agencies);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const activeAgencyId = useSelector(authSelectors.activeAgencyId);
  const currentUser = useSelector(adminSelectors.currentUser);

  const getAgencies = adminActions.getAgencies;
  const params: { id: string } = useParams();
  const [activeUser, setActiveUser] = useState(initialValues);

  const availableRoles = ["sales_director", "sales_admin"];
  const [currentAgencies, setCurrentAgencies] = useState<Array<number>>([]);
  const [addedAgencies, setAddedAgencies] = useState<Array<number>>([]);
  const currentAgencyIds = currentUser.directorAgencies?.map(
    (a) => a.agency_id,
  );

  const handleDelete = (agencyId) => {
    const directorGroupId = currentUser.directorAgencies[0]?.group_id;
    dispatch(
      adminActions.deleteAgencyDirector(
        agencyId,
        directorGroupId,
        currentUser.id,
      ),
    );
  };
  useEffect(() => {
    dispatch(adminActions.getDirector(+params.id));
  }, []);

  useEffect(() => {
    setCurrentAgencies(currentAgencyIds);
    setActiveUser({
      id: currentUser.id,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      role: currentUser.role,
      phone: currentUser.phone,
      directorAgencies: addedAgencies,
    });
  }, [currentUser]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: activeUser,
    async onSubmit() {
      const userId = form.values.id;
      const user = {
        role: form.values.role,
        directorAgencies: addedAgencies,
      };
      dispatch(adminActions.updateDirector(userId, user));
      setAddedAgencies([]);
    },
  });

  const handleMultiSelect = (event) => {
    setAddedAgencies(event.target.value);
  };
  useEffect(() => {
    if (isAdmin) {
      dispatch(getAgencies());
    } else {
      form.setFieldValue("agency", activeAgencyId);
    }
  }, [activeAgencyId]);

  useEffect(() => {
    if (form.values.role === "referral_product_owner") {
      dispatch(adminActions.getReferralProducts());
    }
  }, [form.values.role]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="first_name"
                name="first_name"
                label="First Name"
                value={form.values.first_name}
                onChange={form.handleChange}
                disabled
                error={
                  form.touched.first_name && Boolean(form.errors.first_name)
                }
                helperText={form.touched.first_name && form.errors.first_name}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="last_name"
                name="last_name"
                label="Last Name"
                disabled
                value={form.values.last_name}
                onChange={form.handleChange}
                error={form.touched.last_name && Boolean(form.errors.last_name)}
                helperText={form.touched.last_name && form.errors.last_name}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                id="email"
                name="email"
                label="Email address"
                disabled
                onChange={form.handleChange}
                error={form.touched.email && Boolean(form.errors.email)}
                value={form.values.email}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                select
                fullWidth
                name="role"
                id="role"
                label="Role"
                variant="outlined"
                value={form.values.role}
                onChange={form.handleChange}
                error={form.touched.role && Boolean(form.errors.role)}
                helperText={form.touched.role && form.errors.role}
              >
                {availableRoles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                type="phone"
                id="phone"
                name="phone"
                disabled
                label="Phone number"
                onChange={form.handleChange}
                error={form.touched.phone && Boolean(form.errors.phone)}
                value={form.values.phone}
              />
            </Grid>
            {isAdmin &&
              (form.values.role === "sales_director" ||
                form.values.role === "sales_admin") && (
                <Grid item>
                  <FormControl variant="outlined" className={classes.agencies}>
                    <InputLabel className={classes.label}>Agencies</InputLabel>

                    <Select
                      multiple
                      fullWidth
                      name="directorAgencies"
                      id="directorAgencies"
                      labelId="test-select-label"
                      label="Tag"
                      value={addedAgencies}
                      onChange={handleMultiSelect}
                      renderValue={() => ""}
                    >
                      <MenuItem disabled value="">
                        <em>Agencies</em>
                      </MenuItem>
                      {agencies.map((agency) => {
                        return (
                          <MenuItem
                            key={agency.id}
                            value={agency.id}
                            disabled={!!currentAgencies.includes(agency.id)}
                          >
                            <Checkbox
                              checked={
                                currentAgencies.includes(agency.id) ||
                                addedAgencies.includes(agency.id)
                              }
                            />
                            <ListItemText primary={agency.name} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
          </Grid>
          {(!!currentAgencies?.length || !!addedAgencies?.length) && (
            <Grid className={classes.selectedAgenciesWrapper}>
              {!!currentAgencies?.length && (
                <div>
                  {" "}
                  <Typography>Current agencies</Typography>
                </div>
              )}
              <div>
                {" "}
                <div className={classes.selectedAgencies}>
                  {currentAgencies.map((id) => {
                    const name = agencies?.find((a) => a.id === id)?.name;
                    return (
                      <Chip
                        key={id}
                        label={name}
                        className={classes.chip}
                        onDelete={() => handleDelete(id)}
                        deleteIcon={<DeleteIcon />}
                      />
                    );
                  })}
                </div>{" "}
                <div>
                  {!!addedAgencies?.length && (
                    <Box>
                      <p>
                        {" "}
                        <Typography>Selected agencies</Typography>
                      </p>
                      {addedAgencies.map((id) => {
                        const name = agencies?.find((a) => a.id === id)?.name;
                        return (
                          <Chip
                            key={id}
                            label={name}
                            className={classes.chip}
                          />
                        );
                      })}
                    </Box>
                  )}
                </div>
              </div>
            </Grid>
          )}

          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={form.isSubmitting}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default EditUsersPage;
