import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  tabsWrapper: {
    borderBottom: 1,
    borderColor: "divider",
    marginLeft: 174,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      "& .MuiTabs-flexContainer": {
        justifyContent: "space-around",
      },
    },
  },
  divider: {
    border: ".5px solid #DADFF6",
    borderRadius: 3,
    margin: 0,
  },
  sectionWrapper: {
    display: "flex",
    marginLeft: 174,
    alignItems: "flex-start",
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  section: {
    width: 480,
    minHeight: 390,
    margin: 16,
    border: "1px solid #DFE0E3",
    borderRadius: 3,
    padding: 30,
    paddingTop: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginBottom: 16,
      width: "100%",
    },
  },
  sectionTitleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  textFieldWrapper: {
    paddingTop: 28,
    "& .MuiDropzoneArea-root": {
      minHeight: 100,
    },
    "& .MuiSnackbar-anchorOriginBottomLeft": {
      display: "none",
    },
    "& .MuiGrid-spacing-xs-8": {
      margin: 0,
    },
    "& .MuiTypography-subtitle1": {
      fontSize: 13,
      fontWeight: 400,
    },
  },
  textFieldsWrapper: {
    paddingTop: 28,
    display: "flex",
    justifyContent: "space-between",
  },
  fieldWrapper: {
    padding: "12px 0px",
  },
  asterisk: {
    color: "red",
  },
  fieldTitle: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: 8,
  },
  addressTitle: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: 16,
  },
  field: {
    fontSize: 16,
  },
  logo: {
    maxWidth: 200,
    maxHeight: 100,
  },
  imageField: {
    minHeight: 125,
    maxWidth: 125,
  },
  imagePreviewField: {
    minHeight: 300,
  },
  previewImg: {
    maxWidth: 375,
    maxHeight: 250,
  },
  spacer: { marginTop: 32 },
  avatarImg: {
    borderRadius: "100%",
    height: 100,
    marginBottom: 16,
  },
  currentImg: {
    maxWidth: 350,
    maxHeight: 250,
  },
  deleteIcon: {
    "& .MuiInputAdornment-positionEnd": {
      marginTop: 32,
      marginLeft: "auto",
      alignSelf: "flex-start",
    },
  },
  editBtnWrapper: {
    backgroundColor: "#8D98C2",
    minWidth: 32,
    "&:hover": {
      opacity: 0.5,
      backgroundColor: "#8D98C2",
    },
  },
  dropzone: {
    maxHeight: 150,
    width: "100%",
    backgroundColor: "#E4E7F2",
    minHeight: 100,
    marginBottom: 16,
    "&.MuiDropzoneArea-root": {
      backgroundColor: "#E4E7F2",
    },
  },
  dropzoneText: {
    fontSize: 13,
    fontWeight: 400,
  },
  dropzoneImage: {
    height: 20,
  },
  submit: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 24,
  },
  cancelBtn: {
    width: 98,
    marginRight: 12,
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
  },
  submitBtn: {
    width: 98,
    alignSelf: "flex-end",
  },
  colorsWrapper: {
    marginTop: 16,
    "& .MuiOutlinedInput-input": {
      paddingLeft: 8,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 8,
    },
  },
  colorWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    "& .react-colorful": {
      position: "absolute",
      zIndex: 2,
      height: 120,
      width: 150,
      bottom: -108,
    },
  },
  smallText: {
    fontSize: 13,
    fontWeight: 300,
  },
  medText: {
    fontSize: 16,
  },
  color: {
    width: 27,
    height: 27,
    borderRadius: "50%",
    border: "1px solid #E0E0E0",
    marginRight: 24,
  },
  editColor: {
    width: 27,
    height: 27,
    borderRadius: "50%",
    border: "1px solid #E0E0E0",
  },
  imageWrapper: {
    marginTop: 12,
  },
  socialImageWrapper: {
    padding: "12px 12px",
    paddingTop: 0,
    marginTop: 12,
    boxShadow: "0 2px 24px 0 #E4E7F2",
    "& img": {
      margin: "0 auto",
      maxWidth: 375,
      maxHeight: 250,
      display: "flex",
    },
  },
  socialDataSmallText: {
    fontSize: 10,
  },
  socialDataMedText: {
    fontSize: 12,
  },
  socialDataBigText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 32,
    textAlign: "center",
  },
  profileImage: {
    color: "#EFF1FB",
    height: 140,
    width: 140,
    margin: "0 auto",
    display: "flex",
    marginBottom: 32,
    borderRadius: "50%",
  },
  imageButtonsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "75%",
    margin: "0 auto",
  },
  paper: {
    top: "50%",
    left: "50%",
    position: "absolute",
    width: 400,
    height: "auto",
    backgroundColor: "#FFFFFF",
    transform: "translate(-50%, -50%)",
    maxWidth: "96%",
    maxHeight: "96%",
    padding: "24px 16px",
    display: "flex",
    flexDirection: "column",
    "& .reactEasyCrop_Container": {
      position: "relative",
    },
    "& .reactEasyCrop_Contain": {
      position: "relative",
    },
  },
  slider: {
    marginLeft: 8,
    marginRight: 16,
  },
  btnWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 24,
  },
  icon: {
    color: theme.palette.primary.main,
    height: 22,
  },
  uploadWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.5,
    },
  },
  uploader: {
    display: "none",
  },
  mediumText: {
    fontSize: 14,
    fontFamily: "Lato",
    padding: 8,
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  logoSizeText: {
    color: "#A4A5A7",
    fontSize: 10,
    display: "flex",
    justifyContent: "flex-end",
  },
  logoSizeFieldWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    "& .MuiFormControl-fullWidth": { width: "47%" },
  },
  addBtnWrapper: {
    textAlign: "end",
    marginTop: 20,
  },
  chip: {
    margin: 2,
  },
  selectedAgenciesWrapper: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  selectedAgencies: {
    marginTop: 10,
    display: "flex",
    flexWrap: "wrap",
  },
}));
