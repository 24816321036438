import { STORY_TYPE } from "../state/home/types";
import { formatStringToDate, formatToDate } from "./date";
import { getDwellingAmount, getHeatingUpdate } from "./helperMethods";

export const mapHomeQuoteValues = ({
  start,
  contact,
  custom_quote,
  customData,
  insured,
  details,
  discounts,
  is_linked,
  mode,
}) => {
  const applicant = {
    first_name: insured.firstName,
    middle_name: insured.middleName,
    last_name: insured.lastName,
    email: contact.email,
    phone: contact.phone,
    addr1: `${start.address.street_line} ${start.apt}`.trim(), //TODO: applicant address can be diff than property add
    city: start.address.city,
    state: start.address.state,
    full_state: start.address.full_state,
    zip: start.address.zipcode,
    gender: insured.gender,
    dob: formatStringToDate(insured.dob),
    marital_status: insured.maritalStatus,
    co_applicant: {
      first_name: insured.coapplicant.firstName,
      middle_name: insured.coapplicant.middleName,
      last_name: insured.coapplicant.lastName,
      gender: insured.coapplicant.gender,
      dob: insured.coapplicant.dob
        ? formatStringToDate(insured.coapplicant.dob)
        : "",
    },
    previousAddress: details.previousAddress,
  };

  const dwellingAmount =
    +customData?.dwelling || +discounts.updatedDwellingAmount;

  const property_data = {
    addr1: `${start.address.street_line} ${start.apt}`.trim(),
    city: start.address.city,
    state: start.address.state,
    full_state: start.address.full_state,
    zip: start.address.zipcode,
    dwelling_amount: getDwellingAmount(details.propertyType, dwellingAmount),
    dwelling_use: details.propertyType,
    form_type:
      details.propertyType === "A" ? 4 : details.propertyType === "C" ? 6 : 3,
    owned: details.own,
    purchase_date:
      details.own === "Y"
        ? formatToDate(new Date(details.yearPurchased, 1, 1))
        : formatToDate(new Date()),
    purchase_price: details.DwellingAmt,
    story_type:
      STORY_TYPE.filter((x) => x.label === details.StoryType)[0]?.value ||
      details.StoryType,
    usage_type: details.usageType,
    year_of_construction: Number(details.YearOfConstruction),
    square_footage: Number(details.SquareFootage),
  };

  const home_details = {
    construction: details.exteriorWalls,
    // construction_style: details.constructionType,
    foundation_type: details.foundation,
    swimming_pool: details.SwimmingPool === "true" ? true : false,
    pool_type: details.poolType,
    is_pool_fenced: details.fencedInPool === "true" ? true : false,
    trampoline: details.trampoline === "true" ? true : false,
    number_of_families: details.NumberOfFamilies,
    number_of_full_baths: details.NumberOfFullBaths,
    number_of_half_baths: details.numberOfHalfBaths,
    // occupancy: "O",
    months_unoccupied:
      details.vacantUnoccupied === "NotVacantOrUnoccupied" ? 0 : 12,
    roof_composition: details.roofType,
    roofing_update_year: details.roofBuilt || details.yearPurchased,
    under_construction: details.underConstruction,
    vacant: details.vacantUnoccupied,
    heating_update_year: getHeatingUpdate(+details.YearOfConstruction),
  };

  const _discounts = {
    fire_extinguisher: discounts.discounts.fireExtinguishers,
    smoke_detectors: discounts.discounts.smokeDetectors,
    sprinklers: discounts.discounts.sprinklers ? "Full" : "None",
    auto_policy: 0, //TODO
    home_policy_cancelled: discounts.policyCanceledLastFive,
    dangerous_animals: discounts.dangerousAnimals ? "Akita" : "None",
    fire_alarm: discounts.discounts.fireAlarm ? "Local" : "None",
    burglar_alarm: discounts.discounts.burglarAlarm ? "Local" : "None",
    paperless_discount: discounts.discounts.paperless ? true : false,
    losses: discounts.claims,
    deadbolts: discounts.discounts.deadbolts,
  };

  const coverage = {
    other: { deductible: Number(customData?.generalDed) },
    wind_hail:
      mode === "home" || mode === "bundle"
        ? {
            deductible:
              customData?.windDed?.length === 1
                ? Math.round((customData?.windDed * customData.dwelling) / 100)
                : Number(customData?.windDed),
          }
        : {
            deductible: +customData?.windDed,
          },
    loss_of_use:
      mode === "home" || mode === "bundle"
        ? { percentage: Number(customData?.lossUse) }
        : { limit: Number(customData?.lossUse) },
    medical_payments: { limit: Number(customData?.medPay) },
    other_structures: { percentage: Number(customData?.otherStruct) },
    personal_property:
      mode === "home" || mode === "bundle"
        ? { percentage: Number(customData?.personalProp) }
        : { limit: Number(customData?.personalProp) },
    personal_property_replacement_cost: customData?.p_p_replacement_cost
      ? true
      : false,
    personal_liability: { limit: Number(customData?.personalLiab) },
    extended_replacement: {
      percentage: customData?.extendedRepEnabled
        ? Number(customData?.extendedRep) + 100
        : 0,
    },
    water_backup: {
      limit: customData?.waterBackupEnabled
        ? Number(customData?.waterBackup)
        : "",
    },
  };

  const effectiveDate =
    new Date(insured.effectiveDate) > new Date()
      ? insured.effectiveDate
      : new Date();

  return {
    effective_date: formatStringToDate(effectiveDate),
    custom_quote,
    quote_type: mode === "home" || mode === "bundle" ? "home" : "renters",
    is_linked,
    applicant,
    property_data,
    home_details,
    discounts: _discounts,
    custom: custom_quote ? coverage : {},
  };
};
