import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 28,
    marginBottom: 24,
  },
  item: {
    marginBottom: 16,
    borderRadius: 3,
    boxShadow: `0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC`,
    cursor: "pointer",

    "&:hover": {
      boxShadow: `0 2px 40px 2px ${theme.palette.secondary.contrastText}, 0 2px 24px 0 #DADFF6, 0 2px 40px 0 #DADFF6`,
    },
  },
  checkboxWrap: {
    alignSelf: "flex-start",
  },
  content: {
    paddingTop: 9,
    paddingBottom: 9,
  },
  vin: {
    fontSize: 14,
    color: "#000",
  },
}));
