const useCoverageName = (name) => {
  switch (name) {
    // auto
    case "BI":
      return "Bodily injury Liability";
    case "PD":
      return "Property Damage Liability";
    case "UM":
      return "Uninsured Motorist Bodily";
    case "UMPD":
      return "Uninsured Motorist Property Damage";
    case "UNDUM":
      return "Underinsured Motorist Bodily";
    case "UNDPD":
      return "Underinsured Motorist Property Damage";
    case "MEDPM":
      return "Medical Payments";
    case "COMP":
      return "Comprehensive";
    case "COLL":
      return "Collision";
    case "TL":
    case "TW":
      return "Towing Coverage";
    case "RREIM":
      return "Rental Car Coverage";
    case "PIP":
      return "Personal Injury Protection";
    // home
    case "Dwelling":
      return "Dwelling (A)";
    case "Contents":
      return "Personal Property (C)";
    case "Med Pay":
      return "Medical Payments (F)";
    case "Other Structures":
      return "Other Structures (B)";
    case "Liability":
      return "Personal Liability (E)";
    case "Loss of Use":
      return "Loss of Use (D)";
    case "Extended Replacement Cost":
      return "Extended Replacement Cost";
    case "Deductible 1":
      return "Wind/Hail Deductible";
    case "Deductible 2":
      return "General Deductible";
    case "HO Water backup":
      return "Water Backup";
    case "HO 490":
      return "Personal Property Replacement Cost";
    default:
      throw new Error("Invalid CoverageName");
  }
};

export default useCoverageName;
