import React, { useEffect, useState } from "react";
import { Card, Typography, Box, Button, TextField } from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, yup } from "../../../../lib";

import { useStyles } from "../AgencySettingsPage.styles";
import { EditIcon } from "../../../../components";
import { Agency } from "../../../../state/admin/state";
import { useParams } from "react-router";

const validationSchema = yup.object({
  homepage_title: yup
    .string()
    .required("Title is required")
    .max(77, "Title cannot exceed 77 characters"),
  homepage_subtitle: yup
    .string()
    .required("Subtitle is required")
    .max(61, "Subtitle cannot exceed 61 characters"),
});

const HomepageCard = () => {
  const [inEditMode, setInEditMode] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const activeAgencyId = params.id
    ? Number(params.id)
    : useSelector(authSelectors.activeAgencyId);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const activeAgencyData: Agency = useSelector(adminSelectors.activeAgencyData);
  const { homepage_title, homepage_subtitle } = activeAgencyData;

  const form = useFormik({
    enableReinitialize: true,
    initialValues: { homepage_title, homepage_subtitle },
    validationSchema,
    async onSubmit(values) {
      const agencyData = {
        ...activeAgencyData,
        homepage_title: values.homepage_title,
        homepage_subtitle: values.homepage_subtitle,
      };

      dispatch(
        adminActions.updateAgency(activeAgencyId, agencyData, isSuperAdmin),
      );
      setInEditMode(false);
      form.resetForm();
    },
  });

  const handleCancel = () => {
    setInEditMode(false);
    form.resetForm();
  };

  useEffect(() => {
    dispatch(adminActions.getAgency(activeAgencyId));
  }, []);

  const showEditButton = !inEditMode && isSuperAdmin;

  if (!activeAgencyId) return null;

  const SectionTitle = ({ title }) => {
    return (
      <Box className={classes.sectionTitleWrapper}>
        <Typography className={classes.sectionTitle}>{title}</Typography>
        {showEditButton && (
          <Button
            className={classes.editBtnWrapper}
            onClick={() => setInEditMode(!inEditMode)}
          >
            <EditIcon style={{ color: "white" }} />
          </Button>
        )}
      </Box>
    );
  };

  const AgencyField = ({ fieldName, fieldValue }) => {
    return (
      <Box className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle}>{fieldName}</Typography>
        <Typography className={classes.field}>{fieldValue}</Typography>
      </Box>
    );
  };

  return (
    <>
      {inEditMode ? (
        <Card variant="outlined" className={classes.section}>
          <form onSubmit={form.handleSubmit}>
            <SectionTitle title={"Homepage"} />
            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"homepage_title"}
                name={"homepage_title"}
                label={"Title"}
                variant="outlined"
                multiline
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                value={form.values.homepage_title}
                onChange={form.handleChange}
                error={Boolean(form.errors.homepage_title)}
                helperText={form.errors.homepage_title}
              />
            </Box>

            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"homepage_subtitle"}
                name={"homepage_subtitle"}
                label={"Subtitle"}
                variant="outlined"
                multiline
                required={true}
                InputLabelProps={{
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
                value={form.values.homepage_subtitle}
                onChange={form.handleChange}
                error={Boolean(form.errors.homepage_subtitle)}
                helperText={form.errors.homepage_subtitle}
              />
            </Box>

            <Box className={classes.submit}>
              <Button
                variant="contained"
                onClick={() => handleCancel()}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitBtn}
                disabled={!form.isValid}
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      ) : (
        <Card variant="outlined" className={classes.section}>
          <SectionTitle title={"Homepage"} />
          <AgencyField
            fieldName={"Title"}
            fieldValue={activeAgencyData.homepage_title}
          />
          <AgencyField
            fieldName={"Subtitle"}
            fieldValue={activeAgencyData.homepage_subtitle}
          />
        </Card>
      )}
    </>
  );
};

export default HomepageCard;
