import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  page: {
    width: "96%",
    height: 750,
    [theme.breakpoints.down('md')]: {
      width: "100%",
      paddingBottom: 48,
    },
  },
  topArea: {
    paddingTop: 16,
    paddingBottom: 16,
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  table: {
    minWidth: 650,
  },
  link: {
    textDecoration: "none",
  },
  agencyField: {
    width: "300px",
  },
  // dataTable: {
  //   "& .MuiDataGrid-main": {
  //     fontFamily: "Montserrat",
  //     fontWeight: "bold",
  //   },
  //   "& .MuiDataGrid-columnHeaderTitle": {
  //     fontWeight: "bold",
  //   },
  //   "& .MuiDataGrid-columnHeaders": {
  //     backgroundColor: "#E5EBFF",
  //   },
  //   "& a": {
  //     color: "inherit",
  //     width: "100%",
  //     textAlign: "center",
  //   },
  // },
}));
