import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@mui/material";

import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Form, Navigation, useFormik } from "../../../../lib";
import {
  lifeActions,
  lifeSelectors,
  useAction,
  useDispatch,
  useSelector,
  wizardActions,
} from "../../../../state";
import { DOB_MAX_DATE } from "../../../../state/home/types";
import { lifeInsuredSchema as validationSchema } from "../../../../utils";
import ScrollToOnError from "../../../../components/forms/ScrollToOnError";
import { useStyles } from "./InsuredDetails.styles";
import { InfoIcon } from "../../../../components/icons";
import { InsuredDetails } from "../../../../state/life/types";
import { DatePicker } from "@mui/x-date-pickers";

export default function InsuredContainer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  //STATE
  const [formSubmitted, setFormSubmitted] = useState(false);

  //SELECTORS
  const insured = useSelector(lifeSelectors.insuredDetails);

  //ACTIONS
  const setInsured = useAction(lifeActions.setInsured);

  const initialValues: InsuredDetails = {
    gender: "",
    smoke: false,
    dob: insured.dob || "",
    healthClass: "",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik: any = useFormik({
    initialValues: insured || initialValues,
    validationSchema: validationSchema,
    validateOnChange: formSubmitted,
    async onSubmit(values) {
      await setInsured(values);
      Navigation.go(`/life/wizard/coverage`);
    },
  });

  const handleToggleChange =
    (fieldName) => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) {
        formik.setFieldValue(fieldName, value);
      }
    };

  const handleDateChange = useCallback(
    (date) => {
      formik.setFieldValue("dob", date);
    },
    [formik],
  );

  return (
    <>
      <Form form={formik} onSubmit={formik.handleSubmit} className="maxWidth">
        <ScrollToOnError name="dob">
          <Box className={classes.formSection}>
            <FormLabel className={classes.formLabel} component="legend">
              Date of birth
            </FormLabel>
            <div
              className="datepicker"
              style={{ width: 304, maxWidth: "100%" }}
            >
              <DatePicker
                format="MM/dd/yyyy"
                label="MM/DD/YYYY"
                // name="dob"
                maxDate={DOB_MAX_DATE}
                value={
                  (formik.values.dob && new Date(formik.values.dob)) || null
                }
                onChange={handleDateChange}
                // error={!!formik.errors.dob}
                // helperText={formik.touched.dob && formik.errors.dob}
              />
            </div>
          </Box>
        </ScrollToOnError>
        <ScrollToOnError name="gender">
          <FormControl className={classes.formSection} component="fieldset">
            <FormLabel className={classes.formLabel} component="legend">
              Gender
            </FormLabel>
            <ToggleButtonGroup
              className={`${classes.toggle} ${
                formik.errors.gender ? classes.error : ""
              }`}
              value={formik.values.gender}
              onChange={handleToggleChange("gender")}
              exclusive
              style={{ width: "100%" }}
              size="large"
            >
              <ToggleButton
                value={"M"}
                aria-label="yes"
                style={{ width: "50%", textTransform: "capitalize" }}
                color="primary"
              >
                Male
              </ToggleButton>
              <ToggleButton
                value={"F"}
                aria-label="no"
                style={{ width: "50%", textTransform: "capitalize" }}
                color="primary"
              >
                Female
              </ToggleButton>
            </ToggleButtonGroup>
            {Boolean(formik.errors.gender) && (
              <FormHelperText error variant="outlined">
                {formik.errors.gender}
              </FormHelperText>
            )}
          </FormControl>
        </ScrollToOnError>

        <ScrollToOnError name="smoke">
          <FormControl className={classes.formSection} component="fieldset">
            <FormLabel className={classes.formLabel} component="legend">
              Do you smoke?
            </FormLabel>
            <ToggleButtonGroup
              className={`${classes.toggle} ${
                formik.errors.smoke ? classes.error : ""
              }`}
              value={formik.values.smoke}
              onChange={handleToggleChange("smoke")}
              exclusive
              style={{ width: "100%" }}
              size="large"
            >
              <ToggleButton
                value={true}
                aria-label="yes"
                style={{ width: "50%", textTransform: "capitalize" }}
                color="primary"
              >
                Yes
              </ToggleButton>
              <ToggleButton
                value={false}
                aria-label="no"
                style={{ width: "50%", textTransform: "capitalize" }}
                color="primary"
              >
                No
              </ToggleButton>
            </ToggleButtonGroup>
            {Boolean(formik.errors.smoke) && (
              <FormHelperText error variant="outlined">
                {formik.errors.smoke}
              </FormHelperText>
            )}
          </FormControl>
        </ScrollToOnError>
        <ScrollToOnError name="healthClass">
          <FormControl
            className={`${classes.formSection} ${
              formik.errors.maritalStatus ? classes.error : ""
            }`}
            component="fieldset"
          >
            <Box className={classes.labelWrapper}>
              <FormLabel className={classes.healthLabel} component="legend">
                What is your health class?
              </FormLabel>
              <span
                onClick={() =>
                  dispatch(wizardActions.setExplainerModalOpen(true))
                }
              >
                <InfoIcon className={classes.infoIcon}></InfoIcon>
              </span>
            </Box>

            <RadioGroup
              name="healthClass"
              value={formik.values.healthClass}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="PP"
                control={<Radio color="primary" />}
                label="Preferred plus"
              />
              <FormControlLabel
                value="P"
                control={<Radio color="primary" />}
                label="Preferred"
              />
              <FormControlLabel
                value="RP"
                control={<Radio color="primary" />}
                label="Standard plus"
              />
              <FormControlLabel
                value="R"
                control={<Radio color="primary" />}
                label="Standard"
              />
            </RadioGroup>
            {Boolean(formik.errors.healthClass) && (
              <FormHelperText error variant="outlined">
                {formik.errors.healthClass}
              </FormHelperText>
            )}
          </FormControl>
        </ScrollToOnError>

        <Button
          variant="contained"
          color="primary"
          className={classes.nextButton}
          type="submit"
          disabled={!formik.isValid}
          onClick={() => setFormSubmitted(true)}
        >
          Next
        </Button>
      </Form>
    </>
  );
}
