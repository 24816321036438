import {
  autoSelectors,
  homeSelectors,
  useSelector,
  wizardSelectors,
  wizardNoPersistSelectors,
  uiSelectors,
  lifeSelectors,
} from "../state";
import { formatTitleCase } from "../utils";

const useAgentTitle = () => {
  const activeVehicleIndex = useSelector(autoSelectors.activeVehicleIndex);
  const showSelectVehicleView = useSelector(
    autoSelectors.showSelectVehicleView,
  );
  const showSelectDriverView = useSelector(autoSelectors.showSelectDriverView);
  const { first_name = null } = useSelector(autoSelectors.startForm);
  const applicant_name = useSelector(lifeSelectors.startForm).first_name;
  const { selectedVehicles = null } = useSelector(autoSelectors.vehiclesForm);
  const selectedDrivers = useSelector(autoSelectors.selectedDrivers);
  const activeDriverIndex = useSelector(autoSelectors.activeDriverIndex);
  const driver = selectedDrivers[activeDriverIndex];
  const isSpouse = driver?.spouse || false;
  const isQuoteSelected = useSelector(wizardSelectors.isQuoteSelected) || null;
  const areQuotesSelected = useSelector(wizardSelectors.areQuotesSelected);
  const insured = useSelector(homeSelectors.insured);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const agent_first_name =
    useSelector(uiSelectors.agencyData).default_agent_firstname || "Abbie";

  const contains = (url) => location.pathname.includes(url);
  switch (true) {
    // home pages
    case contains("/home/wizard/start"):
      return `Hi! I'm ${agent_first_name}. What is the address?`;
    case contains("/renters/wizard/start"):
      return `Hi! I'm ${agent_first_name}. What's the address?`;
    case contains("/wizard/details/form"):
      return "Share some details about your home";
    case contains("/wizard/details/confirm"):
      return "Are these details correct?";
    case contains("home/wizard/discounts"):
    case contains("renters/wizard/discounts"):
    case contains("/auto-renters/wizard/home-discounts"):
      return "Let's get you some discounts";
    case contains("home/wizard/insured"):
    case contains("renters/wizard/insured"):
      return "Who's name is being on the policy?";
    // auto pages
    case contains("auto/wizard/start"):
      return `Hi! I'm ${agent_first_name}. Tell me a bit about yourself.`;
    case contains("auto-renters/wizard/start"):
      return `Hi! I'm ${agent_first_name}. Let's start with some details.`;
    case contains("/wizard/verify"):
    case contains("/wizard/code-entry"):
      return `Let's confirm it's you`;
    case contains("/wizard/vehicles"):
      if (showSelectVehicleView) {
        return first_name
          ? `Thanks ${first_name?.replace(
              /\w\S*/g,
              formatTitleCase(first_name),
            )}, which vehicles are we insuring?`
          : "Thanks! Which vehicles are we insuring?";
      } else {
        return selectedVehicles?.length === 1
          ? "Ok. Tell me about your vehicle."
          : activeVehicleIndex === 0
          ? "Ok. Let's start with your first vehicle"
          : activeVehicleIndex === 1
          ? "Great. Let’s continue with your second vehicle."
          : "Great. Let’s continue with your third vehicle.";
      }
    case contains("/wizard/drivers"):
      if (showSelectDriverView)
        return "On to drivers now. Who are we insuring?";
      if (isSpouse) return "Let’s add your spouse to your policy.";
      if (selectedDrivers?.length === 1)
        return "Ok. Tell me about your driver.";

      return activeDriverIndex === 0
        ? "Ok. Let's start with your first driver."
        : activeDriverIndex === 1
        ? "Great. Let’s continue with your second driver."
        : "Which other drivers are we insuring?";
    case contains("auto/wizard/discounts"):
    case contains("/auto-renters/wizard/auto-discounts"):
      return "Let's find you some discounts!";
    // bundle pages
    case contains("bundle/wizard/start"):
      return `Hi! I'm ${agent_first_name}. Let me get some details.`;
    case contains("life/wizard/insured"):
      return `Thanks ${applicant_name}, let's get your health and lifestyle information.`;
    case contains("life/wizard/coverage"):
      return `Great. Let's choose your coverage.`;
    case contains("life/wizard/start"):
      return `Hi! I'm ${agent_first_name}. Let's get started.`;
    case contains("bundle/wizard/auto-discounts"):
      return "Let's find you some discounts!";
    case contains("/wizard/contact"):
      return first_name
        ? `Ok ${first_name?.replace(
            /\w\S*/g,
            formatTitleCase(first_name),
          )}, your quotes are just one click away!`
        : `Ok ${insured.firstName || ""}, Your quotes are just one click away!`;
    case contains("life/wizard/quotes/"):
      return "Great Choice!";
    case contains("life/wizard/quotes"):
      return "Your quotes are ready!";
    case contains("/wizard/quotes"):
      return mode === "bundle" || mode === "auto-renters"
        ? areQuotesSelected
          ? "Great Choice!"
          : "Your quotes are ready!"
        : isQuoteSelected
        ? "Great Choice!"
        : "Your quotes are ready!";
    default:
      return "";
  }
};

export default useAgentTitle;
