import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors, authSelectors } from "../../state";
import { useStyles } from "./ModalAgentTransfer.styles";

export default function ModalAssignReferrer() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isOpen = useSelector(adminSelectors.assignReferrerModalOpen);
  const { applicant } = useSelector(adminSelectors.activeQuote);
  const role = useSelector(authSelectors.activeRole);
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const agents = useSelector(adminSelectors.referralAgents);

  const [selectedAgentToken, setSelectedAgentToken] = useState("");
  useEffect(() => {
    if (isOpen) {
      dispatch(adminActions.getReferringAgents());
    }
  }, [isOpen]);

  const handleCloseModal = () => {
    dispatch(adminActions.setAssignReferrerOpen(false));
    setSelectedAgentToken("");
  };

  const handleAssignReferrer = () => {
    dispatch(
      adminActions.assignQuoteReferrer(
        applicant.req_uid,
        selectedAgentToken,
        role,
        agencyId,
      ),
    );
    dispatch(adminActions.setAssignReferrerOpen(false));
    setSelectedAgentToken("");
  };

  return (
    <Fade in={!!isOpen}>
      <div>
        <Modal open={!!isOpen} onClose={() => handleCloseModal()}>
          <Box className={classes.box}>
            <>
              <Box className={classes.transferWrapper}>
                <Typography className={classes.transferTitle}>
                  Assign referrer to quote
                </Typography>
                <div>
                  <TextField
                    select
                    variant="outlined"
                    fullWidth
                    className={classes.transferDropdown}
                    label="Select a referral agent"
                    id="status"
                    value={selectedAgentToken}
                    onChange={(e) => setSelectedAgentToken(e.target.value)}
                    size="small"
                    sx={{ marginTop: "10px" }}
                  >
                    {agents.map((a, i) => (
                      <MenuItem key={i} value={a.affiliate_token}>
                        {a.affiliate_agent.first_name +
                          " " +
                          a.affiliate_agent.last_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <Box className={classes.transferButtons}>
                  <Button
                    className={classes.cancelBtn}
                    size="small"
                    onClick={() => {
                      dispatch(adminActions.setAssignReferrerOpen(false));
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.selectBtn}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!selectedAgentToken}
                    onClick={() => handleAssignReferrer()}
                  >
                    Assign
                  </Button>
                </Box>
              </Box>
            </>
          </Box>
        </Modal>
      </div>
    </Fade>
  );
}
