import React, { useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Switch,
  Typography,
  Paper,
  Box,
} from "@mui/material";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import { adminActions, useDispatch } from "../../state";
import { useFormik, yup } from "../../lib";

import { useStyles } from "./AddStatePage.styles";
import { states } from "../../assets/data/us_states";

const initialValues = {
  state: "",
  enabled_admin: false,
  enabled_global: false,
  state_requirements: undefined,
};
const validationSchema = yup.object({
  state: yup.string().required("State is required"),
});

const AddStatePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values) {
      await dispatch(adminActions.createState(values));
    },
  });

  useEffect(() => {
    dispatch(adminActions.setPageTitle("Add State"));
  }, [dispatch]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                select
                id="state"
                name="state"
                label="State"
                variant="outlined"
                value={form.values.state}
                onChange={form.handleChange}
                error={form.touched.state && Boolean(form.errors.state)}
                helperText={form.touched.state && form.errors.state}
              >
                {states.map((state) => (
                  <MenuItem key={state.abbr} value={state.abbr}>
                    {state.abbr} &middot; {state.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <Typography>Enable for Admin</Typography>
              <Switch
                checked={!!form.values.enabled_admin}
                color="primary"
                onChange={() =>
                  form.setFieldValue(
                    "enabled_admin",
                    !form.values.enabled_admin,
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <Typography>Enable Global</Typography>
              <Switch
                checked={!!form.values.enabled_global}
                color="primary"
                onChange={() =>
                  form.setFieldValue(
                    "enabled_global",
                    !form.values.enabled_global,
                  )
                }
              />
            </Grid>
            <Grid item xs={10} className={classes.formControl}>
              <Typography style={{ color: "#b4b4d4" }}>
                State Options
              </Typography>
              <Paper>
                <Box className={classes.jsonInput}>
                  <JSONInput
                    key={"state_requirements"}
                    id="state_requirements"
                    name="state_requirements"
                    label="State Requirements"
                    placeholder={form.values.state_requirements}
                    onChange={(e) => {
                      form.setFieldValue("state_requirements", e.jsObject);
                    }}
                    colors={{
                      default: "#484c4f",
                      background: "#eff1f6",
                      background_warning: "#FEECEB",
                      string: "#FA7921",
                      number: "#70CE35",
                      colon: "#49B8F7",
                      keys: "#1277ab",
                    }}
                    height={"100%"}
                    width={"100%"}
                    locale={locale}
                    error={
                      form.touched.state_requirements &&
                      Boolean(form.errors.state_requirements)
                    }
                    helperText={
                      form.touched.state_requirements &&
                      form.errors.state_requirements
                    }
                  ></JSONInput>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!form.values.state || !form.values.state_requirements}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddStatePage;
