import React, { useState } from "react";
import { useStyles } from "./ModalCoverageType.styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { CloseIcon, AddIcon, RemoveIcon } from "../icons";
import { useDispatch, useSelector } from "react-redux";
import { wizardActions, wizardSelectors } from "../../state";

export default function ModalCoverage() {
  const dispatch = useDispatch();
  const { isOpen, title, description, faqs } = useSelector(
    wizardSelectors.coverageModal,
  );

  const defaultExpandValue = [
    { index: 0, value: false },
    { index: 1, value: false },
    { index: 2, value: false },
    { index: 3, value: false },
    { index: 4, value: false },
  ];
  const [expandedList, setExpandedList] = useState(defaultExpandValue);
  const classes = useStyles();

  const handleCloseCoverageModal = () => {
    setExpandedList(defaultExpandValue);
    dispatch(wizardActions.resetCoverageModal());
  };

  const handleExpandAccordion = (index) => () => {
    const expandedListTemp = [...expandedList];
    expandedListTemp[index] = {
      index,
      value: !expandedListTemp[index].value,
    };
    setExpandedList(expandedListTemp);
  };

  return <>
    <Modal
      className={classes.modal}
      open={!!isOpen}
      onClose={handleCloseCoverageModal}
    >
      <Box className={classes.box}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title}>{title}</Typography>
          <IconButton
            className={classes.closeBtn}
            onClick={handleCloseCoverageModal}
            size="large">
            <CloseIcon />
          </IconButton>
        </div>
        <Typography className={classes.explanation}>{description}</Typography>
        <div>
          {faqs && Array.isArray(faqs) && <Divider />}
          {faqs &&
            Array.isArray(faqs) &&
            faqs.map((faq, index) => {
              return (
                <Box key={index}>
                  <Accordion
                    className={classes.accordionWrapper}
                    onChange={handleExpandAccordion(index)}
                    expanded={expandedList[index].value}
                  >
                    <AccordionSummary
                      expandIcon={
                        !expandedList[index].value ? (
                          <AddIcon />
                        ) : (
                          <RemoveIcon />
                        )
                      }
                    >
                      <Typography className={classes.heading}>
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Divider />
                </Box>
              );
            })}
        </div>
      </Box>
    </Modal>
  </>;
}
