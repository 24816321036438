import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Typography,
  Box,
  Button,
  TextField,
  Modal,
  Paper,
  Slider,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authClient,
  authSelectors,
  uiActions,
  useDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, yup } from "../../../../lib";

import { useStyles } from "../AgencySettingsPage.styles";
import { DeleteIcon, EditIcon } from "../../../../components";
import { REACT_APP_ASSETS_URL } from "../../../../config";
import { useParams } from "react-router";
import AbbieIcon from "../../../../assets/img/5minute-avatar.png";
import { CloudUpload } from "@mui/icons-material";
import Cropper from "react-easy-crop";

const validationSchema = yup.object({
  default_agent_firstname: yup
    .string()
    .max(20, "First name cannot exceed 20 characters"),
});

const AgentAvatarCard = () => {
  const initialPixels = { height: 0, width: 0, x: 0, y: 0 };

  const [inEditMode, setInEditMode] = useState(false);
  const [openCropper, setOpenCropper] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [pixels, setPixels] = useState(initialPixels);
  const [tempFilePath, setTempFilePath] = useState<string>("");
  const [photoRemoved, setPhotoRemoved] = useState<boolean>(false);

  const fileUploadRef = useRef<FormData>();

  const fileRef = useRef(null);
  const _fileRef: any = fileRef.current;

  const classes = useStyles();
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const activeAgencyId = params.id
    ? Number(params.id)
    : useSelector(authSelectors.activeAgencyId);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const activeAgencyData = useSelector(adminSelectors.activeAgencyData);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      default_agent_image_path: activeAgencyData.default_agent_image_path,
      default_agent_firstname:
        activeAgencyData.default_agent_firstname || "Abbie",
    },
    validationSchema,
    async onSubmit(values) {
      const agencyData = {
        ...activeAgencyData,
        default_agent_image_path: values.default_agent_image_path,
        default_agent_firstname: values.default_agent_firstname,
      };
      dispatch(
        adminActions.updateAgency(activeAgencyId, agencyData, isSuperAdmin),
      );
      setInEditMode(false);
      form.resetForm();
    },
  });

  const handleCancel = () => {
    setInEditMode(false);
    setPhotoRemoved(false);
    form.resetForm();
  };

  useEffect(() => {
    dispatch(adminActions.getAgency(activeAgencyId));
  }, [activeAgencyId]);

  const showEditButton = !inEditMode;

  if (!activeAgencyId) return null;

  const SectionTitle = ({ title }) => {
    return (
      <Box className={classes.sectionTitleWrapper}>
        <Typography className={classes.sectionTitle}>{title}</Typography>
        {showEditButton && (
          <Button
            className={classes.editBtnWrapper}
            onClick={() => setInEditMode(!inEditMode)}
          >
            <EditIcon style={{ color: "white" }} />
          </Button>
        )}
      </Box>
    );
  };

  async function saveFile(file) {
    fileUploadRef.current = new FormData();
    fileUploadRef.current.append("file", file);

    const { status, data } = await authClient.post(
      "/file-upload",
      fileUploadRef.current,
    );
    if (status === 200) {
      setTempFilePath(data.path);
      setOpenCropper(true);
    } else {
      dispatch(uiActions.showError("Error uploading image"));
    }
  }

  async function cropFile() {
    const { status, data } = await authClient.post("/crop-and-save", {
      original_filepath: tempFilePath,
      pixels,
    });
    if (status === 200) {
      form.setFieldValue("default_agent_image_path", data.path);
    } else {
      dispatch(uiActions.showError("Error cropping image"));
    }
    setOpenCropper(false);
  }

  async function handleFileDrop(event) {
    const file = event.target.files && event.target.files[0];
    setPixels(initialPixels);
    setZoom(1);
    setPhotoRemoved(false);
    saveFile(file);
  }

  async function handleCropComplete(area, pixels) {
    setPixels(pixels);
  }

  async function handleCropCancel() {
    setOpenCropper(false);
  }

  return (
    <>
      <input
        id="imageInput"
        className={classes.uploader}
        type="file"
        ref={fileRef}
        onChange={(e) => handleFileDrop(e)}
        onClick={(event) => (event.currentTarget.value = "")}
      ></input>
      {inEditMode ? (
        <Card variant="outlined" className={classes.section}>
          <form onSubmit={form.handleSubmit}>
            <SectionTitle title={"Avatar"} />
            <Box className={classes.textFieldWrapper}>
              <img
                alt="preview image"
                src={
                  photoRemoved
                    ? AbbieIcon
                    : form.values.default_agent_image_path
                    ? `${REACT_APP_ASSETS_URL}/${form.values.default_agent_image_path}`
                    : activeAgencyData.default_agent_image_path
                    ? `${REACT_APP_ASSETS_URL}/${activeAgencyData.default_agent_image_path}`
                    : AbbieIcon
                }
                className={classes.profileImage}
              ></img>
            </Box>

            {form.values.default_agent_image_path ? (
              <Box className={classes.imageButtonsWrapper}>
                <Box
                  className={classes.uploadWrapper}
                  onClick={() => _fileRef && _fileRef.click()}
                >
                  <CloudUpload className={classes.icon} />
                  <Typography className={classes.mediumText}>
                    Replace photo
                  </Typography>
                </Box>
                <Box
                  className={classes.uploadWrapper}
                  onClick={() => {
                    form.setFieldValue("default_agent_image_path", "");
                    setPhotoRemoved(true);
                  }}
                >
                  <DeleteIcon className={classes.icon} />
                  <Typography className={classes.mediumText}>
                    Remove photo
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                className={classes.uploadWrapper}
                onClick={() => _fileRef && _fileRef.click()}
              >
                <CloudUpload className={classes.icon} />
                <Typography className={classes.mediumText}>
                  Upload photo
                </Typography>
              </Box>
            )}

            <Modal
              open={openCropper}
              onClose={() => {
                setOpenCropper(false);
              }}
            >
              <Paper className={classes.paper}>
                <Typography className={classes.title}>Crop photo</Typography>
                <Box>
                  <Cropper
                    image={`${REACT_APP_ASSETS_URL}/${tempFilePath}`}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    cropShape="round"
                    showGrid={false}
                    onCropChange={(crop) => setCrop(crop)}
                    onCropComplete={(area, pixels) => {
                      handleCropComplete(area, pixels);
                    }}
                    onZoomChange={(zoom) => setZoom(zoom)}
                  />
                </Box>
                <Box className={classes.slider}>
                  <Slider
                    value={zoom}
                    min={1}
                    max={10}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, z) => {
                      if (Array.isArray(z)) {
                        setZoom(z[0]);
                      } else {
                        setZoom(z);
                      }
                    }}
                  />
                </Box>
                <Box className={classes.btnWrapper}>
                  <Button
                    variant="contained"
                    onClick={() => handleCropCancel()}
                    className={classes.cancelBtn}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.submitBtn}
                    onClick={() => cropFile()}
                  >
                    Save
                  </Button>
                </Box>
              </Paper>
            </Modal>

            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"default_agent_firstname"}
                name={"default_agent_firstname"}
                label={"Agent First Name"}
                variant="outlined"
                value={form.values.default_agent_firstname}
                onChange={form.handleChange}
                error={Boolean(form.errors.default_agent_firstname)}
                helperText={form.errors.default_agent_firstname}
              />
            </Box>

            <Box className={classes.submit}>
              <Button
                variant="contained"
                onClick={() => handleCancel()}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitBtn}
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      ) : (
        <Card variant="outlined" className={classes.section}>
          <SectionTitle title={"Avatar"} />
          <Typography className={classes.smallText}>
            Choose the default agent for your website and emails
          </Typography>
          <Box className={classes.imageWrapper}>
            <Box className={classes.spacer}></Box>
            <img
              src={
                activeAgencyData.default_agent_image_path
                  ? `${REACT_APP_ASSETS_URL}/${activeAgencyData.default_agent_image_path}`
                  : AbbieIcon
              }
              className={classes.avatarImg}
            ></img>
          </Box>
          <Typography className={classes.medText}>
            Hi! I'm {activeAgencyData.default_agent_firstname || "Abbie"}.
          </Typography>
        </Card>
      )}
    </>
  );
};

export default AgentAvatarCard;
