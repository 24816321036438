import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  // DETAILS FORM
  formSection: {
    display: "flex",
    marginBottom: 40,
  },
  formLabel: {
    color: "#000",
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 16,
    lineHeight: "1.2",
    "&.Mui-focused": {
      color: "inherit",
    },
  },
  formSelect: {
    maxWidth: 304,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  toggle: {
    "& .MuiToggleButton-root": {
      borderRadius: 0,
      borderColor: "#ccd2e9",
      textTransform: "initial",
      color: "#AFB6D4",
      transition: "all 300ms linear",
    },
    "& .MuiToggleButton-root.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  selectDropdown: {},
  nextButton: {
    width: 220,
    height: 48,
    display: "block",
    margin: "0 auto 20px",
  },
  // CONFIRM DETAILS
  confirmDetailsContainer: {
    maxWidth: 920,
    margin: "40px auto",
    background: "#EFF1FB",
    padding: "48px 48px 24px 48px",
    width: "100%",
    overflowX: "hidden",
    "& label + .MuiInput-formControl": {
      marginTop: 8,
    },
    [theme.breakpoints.down("md")]: {
      padding: "32px 32px 16px 32px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "24px 24px 0 24px",
      "& .MuiGrid-item": {
        marginBottom: 16,
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
  confirmDetailsLabel: {
    fontSize: 13,
    display: "block",
  },
  confirmDetailsInput: {
    "& .MuiOutlinedInput-root": {
      minHeight: 66,
    },
    "& .MuiOutlinedInput-input": {
      padding: 0,
      paddingTop: 15,
    },
    "& fieldset": {
      border: "none",
      borderBottom: "1px solid rgba(0, 0, 0, .2)",
      borderRadius: 0,
      padding: 0,
    },
  },
  confirmDetailsSelect: {
    width: "100%",
    "&.MuiInput-underline:before, &.MuiInput-underline:after, &.MuiInput-underline.Mui-focused:after, &.MuiInput-underline:hover:not(.Mui-disabled):before":
      {
        borderBottom: "none",
      },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-selectMenu": {
      display: "inline-block",
    },
  },
  error: {
    "& .MuiRadio-root": {
      color: "#f44336",
    },
    "& .MuiToggleButton-root": {
      borderColor: "#f44336",
    },
  },
}));
