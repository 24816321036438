import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  quotesContainer: {
    marginTop: 40,
  },
  quote: {
    display: "flex",
    margin: "auto",
    maxWidth: 1100,
    justifyContent: "space-between",
    boxShadow: "0 2px 12px 0 #F5F6FE",
    alignItems: "center",
    height: 75,
    borderRadius: 3,
  },
  quoteProduct: {
    display: "flex",
    marginLeft: 15,
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  prodDesc: {
    margin: "2px 50px",
    [theme.breakpoints.down('lg')]: {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      display: "none",
    },
  },
  prodName: {
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: 14,
    },
  },
  mobileNone: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  rating: {
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  mobileRatingWrapper: {
    marginLeft: 10,
    height: 30,
    width: 30,
    opacity: 0.75,
    // transform: "scaleX(-1)",
    borderRadius: "50%",
    backgroundColor: "#DADFF6",
    [theme.breakpoints.up(600)]: {
      display: "none",
    },
  },
  mobileRating: {
    display: "flex",
    height: 30,
    width: 30,
    fontWeight: 600,
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up(600)]: {
      display: "none",
    },
  },
  quoteRight: {
    display: "flex",
    marginRight: 15,
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      marginRight: 5,
    },
  },
  logoContainer: {
    width: 100,
    [theme.breakpoints.down('lg')]: {
      marginRight: 10,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 65,
      display: "flex",
      alignItems: "center",
    },
  },
  logo: {
    maxWidth: 95,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 25,
    },
  },
  price: {
    color: theme.palette.primary.main,
    fontSize: 30,
    fontWeight: 500,
    marginRight: 30,
    [theme.breakpoints.down('lg')]: {
      fontSize: 25,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      textAlign: "end",
      marginRight: 15,
    },
  },
  priceInterval: {
    color: "grey",
    fontSize: 14,
    verticalAlign: "middle",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  mobileInterval: {
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
    [theme.breakpoints.down('sm')]: {
      display: "block",
      color: "grey",
      fontSize: 14,
    },
  },
  button: {
    height: 40,
    width: 200,
    [theme.breakpoints.down('lg')]: {
      width: 140,
    },
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  switcherGroup: {
    [theme.breakpoints.down('sm')]: {
      width: "95%",
    },
    "& .MuiButtonBase-root": {
      border: "1px solid #DADFF6",
      width: 177,
      height: 32,
      fontWeight: 600,
      fontSize: 14,
      textTransform: "initial",
      [theme.breakpoints.down('sm')]: {
        height: 32,
        fontSize: 12,
        marginTop: 15,
        paddingBottom: 20,
      },
    },
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      border: "none",
    },
  },
  switcherWrapper: {
    textAlign: "center",
    marginTop: 35,
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
    },
  },
  mobileButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    color: "white",
    height: 30,
    width: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  NoQuotesContainer: {
    maxWidth: 450,
    margin: "7% auto",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      marginTop: "20%",
    },
    "& .MuiTypography-root": {
      fontWeight: "bold",
      fontSize: 20,
    },
  },
  image: {
    marginRight: 70,
  },
}));
