import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const fpPromise = FingerprintJS.load();
export let fingerPrint;
// Get the visitor identifier when you need it.
fpPromise
  .then((fp) => fp.get())
  .then((result) => {
    fingerPrint = result;
  });
