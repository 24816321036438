import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 560,
    padding: 16,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    "& .MuiCard-root": {
      border: "none !important",
      padding: 8,
    },
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginLeft: 22,
  },
  title: {
    fontWeight: 600,
    fontSize: 22,
    paddingBottom: 8,
    paddingTop: 8,
  },
  closeIconWrapper: {
    justifyContent: "center",
    alignItems: "center",
  },
  closeIcon: {
    color: "#AFB6D4",
  },
}));
