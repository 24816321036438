import React from "react";
import { Box } from "@mui/material";
import { useStyles } from "./CoverageSection.styles";
import { CoverageCard } from "./CoverageCard";
import { adminSelectors, useSelector } from "../../../state";

export const LifeCoverageSection = ({ payFrequency }) => {
  const classes = useStyles({});
  const activeQuote = useSelector(adminSelectors.activeQuote);
  const quotes = activeQuote.quotes;

  const selectedQuote = quotes.find(
    (q) =>
      q.companyCode ===
      activeQuote.applicant.selected_quote_life?.split("+")[1],
  );
  const otherQuotes = quotes.filter(
    (q) =>
      q.companyCode !==
      activeQuote.applicant.selected_quote_life?.split("+")[1],
  );
  if (selectedQuote) {
    otherQuotes.unshift(selectedQuote);
  }
  return (
    <Box className={classes.coverageWrapper}>
      {/* <Typography>{coverageLevelTitle}</Typography> */}
      {!!quotes[0].name &&
        otherQuotes.map((q, index) => {
          const quote = {
            ...q,
            displayPremium:
              payFrequency === "full"
                ? +q.premium?.replace(/,/g, "")
                : +q.monthlyPremium?.replace(/,/g, ""),
            carrier: {
              logo_full_url: `https://www.compulifeapi.com/images/logosapi/${q.compImgUrl}-small.png`,
            },
          };
          return (
            <CoverageCard
              key={index}
              quote={quote}
              isLast={index === quotes.length - 1}
              hasPurchased={null}
            />
          );
        })}
    </Box>
  );
};
