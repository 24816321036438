import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LifeBaseInfo } from "../life/types";
import {
  BaseReferralData,
  EventItem,
  GoogleCalendar,
  ReferralProduct,
  Referrals,
  AlertItem,
} from "./types";

interface PmtInfo {
  bill_city: string;
  bill_first_name: string;
  bill_last_name: string;
  bill_state: string;
  bill_street: string;
  bill_zip: string;
  creditcard_number: string;
  cvc: string;
  expiration: string;
  name_on_account: string;
  account_number: string;
  routing_number: string;
}

export interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  user_agency?: any;
  sales_director?: any[];
  phone: string;
}

export interface AgencyCarrierGroup {
  agency_group_id: number;
  carrier_id: number;
  id: number;
  is_active: boolean;
}
export interface Carrier {
  id: number;
  name: string;
  company_id: number;
  is_auto: boolean;
  is_home: boolean;
  is_active: boolean;
  state: string;
  logo_full_url: string;
  logo_path: string;
  agency_group_carriers: AgencyCarrierGroup[];
}
export interface Agency {
  id: number;
  name: string;
  logo_path: string;
  domain: string;
  theme: Theme;
  agency_group?: any;
  group_id?: number;
  email_address: string;
  phone: string;
  email_to: string;
  social_links?: SocialLinks;
  preview_title?: string;
  preview_description?: string;
  preview_image_path?: string;
  homepage_title?: string;
  homepage_subtitle?: string;
  street: string;
  suite?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  bio_image_path?: string;
  bio_text?: string;
  default_agent_image_path?: string;
  default_agent_firstname?: string;
  logo_height: number;
  location: {
    type: string;
    coordinates: Array<number>;
  };
  show_in_directory: boolean;
  restrict_agent_viewing: boolean;
  google_doc_url?: string;
}

interface Theme {
  palette: {
    mode: string;
    primary: { main: string; light: string; contrastText: string };
    secondary: { main: string; light: string; contrastText: string };
    info: { main: string };
  };
}
interface SocialLinks {
  facebook: string;
  instagram: string;
  twitter: string;
  linkedin: string;
}
export interface Agency_Group {
  id: number;
  name: string;
  location_id: string;
}
export interface User_Agency {
  id: number;
  user_id: number;
  agency_id: number;
  referral_token: string;
  role: string;
}

export interface Director {
  role: string;
  first_name: string;
  last_name: string;
  agency_groups_id: number;
  agencyIds: number[];
  user_id: number;
}
export interface Agent {
  id: number;
  referral_token: string;
  user_id: number;
  agency_id: number;
  referral_prod_id?: any;
  role: string;
  user: AgentUser;
  agency: {
    name: string;
    id: number;
  };
}

export interface AgentStats {
  appStats: {
    min_days_to_close: string;
    max_days_to_close: string;
    avg_days_to_close: string;
  };
  daysOpen: {
    min_days_in_status: string;
    max_days_in_status: string;
    avg_days_in_status: string;
  };
  statusStats: StatusStat[];
  agentData?: AgentData;
  totalSales: { totalSales: string };
  conversionRate: { conversion_rate: string };
}

interface AgentData {
  first_name: string;
  last_name: string;
  email: string;
  phone?: any;
  profile_image_path?: any;
  created_at: string;
  // user_agency: { created_at: string }[];
}

interface StatusStat {
  fullStatus: string;
  statusCount: number;
}

interface AgentUser {
  first_name: string;
  last_name: string;
  affiliate_agent: { affiliate_token?: string }[];
}

export interface State {
  id: number;
  state: string;
}

export interface Coverage {
  id: number;
  level: number;
  coverage_data: JSON;
  state: string;
}

export interface CoverageUI {
  id: number;
  internal_name: string;
  label: string;
  description: string;
  tooltip: string;
  image_path: string;
}

export interface AllowedIPs {
  id: number;
  ip: string;
  comment: string;
  added_by_user_id: number;
}

export interface FAQ {
  id: number;
  category_id: number;
  question: string;
  answer: string;
  order: number;
}

export interface FAQCategory {
  id: number;
  order: number;
  category_name: string;
}

interface Customer {
  agency: {
    name: string;
  };
}

export type QuoteType = "auto" | "home" | "renters";

export interface Application {
  id: number;
  req_uid: string;
  email: string;
  first_name: string;
  last_name: string;
  addr1: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  agency: {
    name: string;
  };
  quote_types: string;
  status: string;
  sub_status: string;
  created_at: string;
  last_updated?: string;
  agent?: QuoteAgent;
  referrer?: string;
  commercial_type?: string;
  business_name?: string;
  affiliate_agent: { first_name?: string; last_name?: string };
}

export interface UDDRequests {
  id: number;
  driver_address: string;
  driver_city: string;
  driver_zip: string;
  driver_first_name: string;
  driver_last_name: string;
  response: string;
  ip: string;
  customer: Customer;
}

export interface Report {
  id: number;
  name: string;
}
export interface EmailData {
  premium: number;
  logo: string;
  emailAddress: string;
  insurance_level_id: any;
  turbo_quote_id: string;
}

export interface QuoteAgent {
  id: number;
  first_name: string;
  last_name: string;
  agent_role?: string;
}
export interface ReferralAgent {
  affiliate_token: string;
  affiliate_agent: {
    first_name: string;
    last_name: string;
  };
}
export interface QuoteDocument {
  file_path: string;
  filename: string;
  mime_type: string;
  size: number;
}

export interface USMapData {
  main_settings: MainSettings;
  state_specific: {
    key: {
      name?: string;
      color?: string;
      hover_color?: string;
      hide?: string;
      description?: string;
    };
  };
  locations: {};
  labels: {
    key: {
      parent_id: string;
      x: string;
      y: string;
      pill: string;
      width: number;
      display: string;
      color: string;
      hover_color: string;
      line: string;
    };
  };
  legend: {
    entries: any[];
  };
  regions: {};
}

interface MainSettings {
  width: string;
  background_color: string;
  background_transparent: string;
  border_color: string;
  popups: string;
  state_description: string;
  state_color: string;
  state_hover_color: string;
  state_url: string;
  border_size: string;
  all_states_inactive: string;
  all_states_zoomable: string;
  location_description: string;
  location_color: string;
  location_opacity: number;
  location_hover_opacity: number;
  location_url: string;
  location_size: number;
  location_type: string;
  location_image_source: string;
  location_border_color: string;
  location_border: number;
  location_hover_border: number;
  all_locations_inactive: string;
  all_locations_hidden: string;
  label_color: string;
  label_hover_color: string;
  label_size: string;
  label_font: string;
  hide_labels: string;
  hide_eastern_labels: string;
  manual_zoom: string;
  back_image: string;
  initial_back: string;
  initial_zoom: number;
  initial_zoom_solo: string;
  region_opacity: number;
  region_hover_opacity: number;
  zoom_out_incrementally: string;
  zoom_percentage: number;
  zoom_time: number;
  popup_color: string;
  popup_opacity: number;
  popup_shadow: number;
  popup_corners: number;
  popup_font: string;
  popup_nocss: string;
  div: string;
  auto_load: string;
  url_new_tab: string;
  images_directory: string;
  fade_time: number;
  import_labels: string;
  link_text: string;
  state_image_url: string;
  state_image_position: string;
  location_image_url: string;
}

export interface AdminState {
  pageTitle: string | undefined;
  activeQuote?: {
    applicant: any;
    quotes: any;
    quoteType: "Bundle" | "Home" | "Auto" | "Life" | "AutoRenters" | undefined;
    baseInfo?: LifeBaseInfo | null;
    agent: {
      user: {
        first_name: string;
        last_name: string;
        phone: string;
        email: string;
      };
      agency: { name: string; domain: string };
    };
    aff_agent: { affiliate_agent: { first_name?: string; last_name?: string } };
    quote_documents?: QuoteDocument[];
    quote_comments?: any[];
    quote_activity: any[];
    quote_alerts: [];
    agency: { domain: string };
  };
  referralData: BaseReferralData;
  loadingNewDriverQuotes: boolean;
  pmtInfo: PmtInfo;
  showOfflinePaymentLink: boolean;
  selectedAgencyGrp: number;
  selectedCoverage: Coverage;
  activeAgencyData: Agency;
  agencyModalOpen: boolean;
  emailModalOpen: boolean;
  uploadModalOpen: boolean;
  activityModalOpen: boolean;
  agentTransferModalOpen: boolean;
  emailModalData: EmailData;
  carrierModalOpen: boolean;
  assignReferrerModalOpen: boolean;
  listModalOpen: boolean;
  bindModalOpen: boolean;
  carrierNotes: string;
  currentUser: any;
  directors: Director[];
  agents: Agent[];
  referralAgents: ReferralAgent[];
  bundleBindData: any;
  agentStats: AgentStats;
  googleCalendars: GoogleCalendar[];
  hasOAuthAccessToken: boolean;
  quoteStatus: { status: string; sub_status: string };
  availableCarriers: {
    state: string;
    carriers: { name: string; logo_path: string }[];
  }[];
  events: EventItem[];
  schedule: EventItem[];
  alerts: AlertItem[];
  taskModalOpen: boolean;
  taskModalData: AlertItem;
  faqs?: {
    count: number;
    rows: FAQ[];
  };
  faqCategories?: {
    count: number;
    rows: FAQCategory[];
  };
  users: {
    count: number;
    rows: User[];
  };
  carriers: {
    count: number;
    rows: Carrier[];
  };
  manualCarriers: {
    count: number;
    rows: Carrier[];
  };
  products: {
    count: number;
    rows: ReferralProduct[];
  };
  agencies: {
    count: number;
    rows: Agency[];
  };
  agency_groups: {
    count: number;
    rows: Agency_Group[];
  };
  user_agencies: {
    count: number;
    rows: User_Agency[];
  };

  states: {
    count: number;
    rows: State[];
  };
  coverage: {
    count: number;
    rows: Coverage[];
  };
  coverage_ui: {
    count: number;
    rows: CoverageUI[];
  };
  allowedIPs: {
    count: number;
    rows: AllowedIPs[];
  };
  applications: Application[];
  referrals: {
    count: number;
    rows: Referrals[];
  };
  uddRequests: {
    count: number;
    rows: UDDRequests[];
  };
  reports: {
    count: number;
    rows: Report[];
  };
}

const initialState: AdminState = {
  pageTitle: "",
  activeQuote: {
    applicant: {},
    quotes: {
      "1": [],
      "2": [],
      "3": [],
    },
    quoteType: undefined,
    agent: {
      user: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      },
      agency: { name: "", domain: "" },
    },
    aff_agent: { affiliate_agent: { first_name: "", last_name: "" } },
    quote_alerts: [],
    quote_activity: [],
    quote_documents: [],
    quote_comments: [],
    agency: { domain: "" },
  },
  referralData: {
    referralData: {
      id: 0,
      first_name: "",
      last_name: "",
      contact_method: "",
      status: "New referral",
      created_at: "",
    },
    productData: {
      logo_full_url: "",
      product_name: "",
      referral_category: { name: "" },
    },
  },
  events: [],
  schedule: [],
  alerts: [],
  referralAgents: [],
  availableCarriers: [],
  loadingNewDriverQuotes: false,
  pmtInfo: {
    bill_city: "",
    bill_first_name: "",
    bill_last_name: "",
    bill_state: "",
    bill_street: "",
    bill_zip: "",
    creditcard_number: "",
    cvc: "",
    expiration: "",
    name_on_account: "",
    account_number: "",
    routing_number: "",
  },
  showOfflinePaymentLink: false,
  selectedAgencyGrp: 1,
  selectedCoverage: { id: 0, level: 0, coverage_data: JSON, state: "" },
  agencyModalOpen: false,
  carrierModalOpen: false,
  uploadModalOpen: false,
  emailModalOpen: false,
  activityModalOpen: false,
  agentTransferModalOpen: false,
  assignReferrerModalOpen: false,
  listModalOpen: false,
  taskModalOpen: false,
  bindModalOpen: false,
  bundleBindData: {},
  taskModalData: {
    id: 0,
    req_uid: "",
    user_id: 0,
    summary: "",
    description: "",
    start: "",
    end: "",
    task_quote_alerts: [],
  },
  emailModalData: {
    premium: 0,
    logo: "",
    emailAddress: "",
    insurance_level_id: "",
    turbo_quote_id: "",
  },
  carrierNotes: "",
  quoteStatus: { status: "", sub_status: "" },
  activeAgencyData: {
    id: 0,
    name: "",
    logo_path: "",
    domain: "",
    theme: {
      palette: {
        mode: "light",
        primary: { main: "", light: "", contrastText: "" },
        secondary: { main: "", light: "", contrastText: "" },
        info: { main: "" },
      },
    },
    email_address: "",
    phone: "",
    email_to: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    logo_height: 80,
    location: { type: "Point", coordinates: [] },
    show_in_directory: true,
    restrict_agent_viewing: false,
  },
  agentStats: {
    appStats: {
      min_days_to_close: "",
      max_days_to_close: "",
      avg_days_to_close: "",
    },
    daysOpen: {
      min_days_in_status: "",
      max_days_in_status: "",
      avg_days_in_status: "",
    },
    totalSales: { totalSales: "" },
    statusStats: [],
    conversionRate: { conversion_rate: "" },
    agentData: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      profile_image_path: "",
      created_at: "",
      // user_agency: [],
    },
  },
  currentUser: {},
  users: {
    count: 0,
    rows: [],
  },
  carriers: {
    count: 0,
    rows: [],
  },
  manualCarriers: {
    count: 0,
    rows: [],
  },
  products: {
    count: 0,
    rows: [],
  },
  directors: [],
  agents: [],
  agencies: {
    count: 0,
    rows: [],
  },
  agency_groups: {
    count: 0,
    rows: [],
  },
  user_agencies: {
    count: 0,
    rows: [],
  },
  states: {
    count: 0,
    rows: [],
  },
  coverage: {
    count: 0,
    rows: [],
  },
  coverage_ui: {
    count: 0,
    rows: [],
  },
  allowedIPs: {
    count: 0,
    rows: [],
  },
  applications: [],
  referrals: {
    count: 0,
    rows: [],
  },
  uddRequests: {
    count: 0,
    rows: [],
  },
  reports: {
    count: 0,
    rows: [],
  },
  googleCalendars: [],
  hasOAuthAccessToken: false,
};

export const admin = {
  ...createSlice({
    name: "admin",
    initialState,
    reducers: {
      setActiveQuoteNew(state, { payload }: PayloadAction<any | undefined>) {
        if (!payload) {
          return state;
        }
        return {
          ...state,
          activeQuote: payload,
        };
      },
      setLoadingNewDriverQuotes(state, { payload }: PayloadAction<boolean>) {
        state.loadingNewDriverQuotes = payload;
      },
      setPageTitle(state, { payload }: PayloadAction<string | undefined>) {
        state.pageTitle = payload;
      },
      savePmtInfo(state, { payload }: PayloadAction<PmtInfo>) {
        state.pmtInfo = payload;
      },
      setShowOfflinePaymentLink(state, { payload }: PayloadAction<boolean>) {
        state.showOfflinePaymentLink = payload;
      },
      setFAQs(state, { payload }: PayloadAction<any>) {
        state.faqs = payload;
      },
      setFAQCategories(state, { payload }: PayloadAction<any>) {
        state.faqCategories = payload;
      },
      setUsers(state, { payload }: PayloadAction<any>) {
        state.users = payload;
      },
      setCarriers(state, { payload }: PayloadAction<any>) {
        state.carriers = payload;
      },
      setAvailableCarriers(state, { payload }: PayloadAction<any>) {
        state.availableCarriers = payload;
      },
      setManualCarriers(state, { payload }: PayloadAction<any>) {
        state.manualCarriers = payload;
      },
      setProducts(state, { payload }: PayloadAction<any>) {
        state.products = payload;
      },
      setAgencies(state, { payload }: PayloadAction<any>) {
        state.agencies = payload;
      },
      setAgentStats(state, { payload }: PayloadAction<AgentStats>) {
        state.agentStats = payload;
      },
      setActiveAgencyData(state, { payload }: PayloadAction<any>) {
        Object.keys(payload).forEach((key) => {
          if (payload[key] === null) {
            delete payload[key];
          }
        });
        state.activeAgencyData = payload;
      },
      setAgencyModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.agencyModalOpen = payload;
      },
      setEmailModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.emailModalOpen = payload;
      },
      setUploadModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.uploadModalOpen = payload;
      },
      setActivityModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.activityModalOpen = payload;
      },
      setAgentTransferModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.agentTransferModalOpen = payload;
      },
      setAssignReferrerOpen(state, { payload }: PayloadAction<boolean>) {
        state.assignReferrerModalOpen = payload;
      },
      setListModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.listModalOpen = payload;
      },
      setEmailModalData(state, { payload }: PayloadAction<EmailData>) {
        state.emailModalData = payload;
      },
      setBindModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.bindModalOpen = payload;
      },
      setCarrierModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.carrierModalOpen = payload;
      },
      setTaskModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.taskModalOpen = payload;
      },
      setTaskModalData(state, { payload }: PayloadAction<any>) {
        state.taskModalData = payload;
      },
      setCarrierNotes(state, { payload }: PayloadAction<string>) {
        state.carrierNotes = payload;
      },
      setAgencyGroups(state, { payload }: PayloadAction<any>) {
        state.agency_groups = payload;
      },
      setUserAgencies(state, { payload }: PayloadAction<any>) {
        state.user_agencies = payload;
      },
      setStates(state, { payload }: PayloadAction<any>) {
        state.states = payload;
      },
      setCoverage(state, { payload }: PayloadAction<any>) {
        state.coverage = payload;
      },
      setSelectedCoverage(state, { payload }: PayloadAction<any>) {
        state.selectedCoverage = payload;
      },
      setCoverageUI(state, { payload }: PayloadAction<any>) {
        state.coverage_ui = payload;
      },
      setAllowedIPs(state, { payload }: PayloadAction<any>) {
        state.allowedIPs = payload;
      },
      setApplications(state, { payload }: PayloadAction<any>) {
        state.applications = payload;
      },
      setReferrals(state, { payload }: PayloadAction<any>) {
        state.referrals = payload;
      },
      setReferralData(state, { payload }: PayloadAction<any>) {
        state.referralData = payload;
      },
      setUDDRequests(state, { payload }: PayloadAction<any>) {
        state.uddRequests = payload;
      },
      setReports(state, { payload }: PayloadAction<any>) {
        state.reports = payload;
      },
      setSelectedAgencyGrp(state, { payload }: PayloadAction<number>) {
        state.selectedAgencyGrp = payload;
      },
      setCurrentUser(state, { payload }: PayloadAction<number>) {
        state.currentUser = payload;
      },
      setDirectors(state, { payload }: PayloadAction<any>) {
        state.directors = payload;
      },
      setQuoteStatus(state, { payload }: PayloadAction<any>) {
        state.quoteStatus = payload;
      },
      setBundleBindData(state, { payload }: PayloadAction<any>) {
        state.bundleBindData = payload;
      },
      setAgents(state, { payload }: PayloadAction<any>) {
        state.agents = payload;
      },
      setReferralAgents(state, { payload }: PayloadAction<any>) {
        state.referralAgents = payload;
      },
      setEvents(state, { payload }: PayloadAction<EventItem[]>) {
        state.events = payload;
      },
      setCalendars(state, { payload }: PayloadAction<any>) {
        state.googleCalendars = payload;
      },
      setSchedule(state, { payload }: PayloadAction<EventItem[]>) {
        state.schedule = payload;
      },
      setAlerts(state, { payload }: PayloadAction<AlertItem[]>) {
        state.alerts = payload;
      },
      setOAuthAccessToken(state, { payload }: PayloadAction<boolean>) {
        state.hasOAuthAccessToken = payload;
      },
    },
  }),
  persist: false,
};
