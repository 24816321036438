import React, { useMemo } from "react";
import { Box, List } from "@mui/material";
import clsx from "clsx";
import {
  autoSelectors,
  useSelector,
  homeSelectors,
} from "../../../../../state";
import PropListItem from "./PropListItem";
import { useStyles } from "../../Quotes.styles";
import { arrayToEntityList } from "../../../../../lib";

const QuoteProps = ({ coverageData, carrier, quoteType }) => {
  const classes = useStyles();
  const showQuoteCardDetails = useSelector(autoSelectors.showQuoteCardDetails);

  //This is an array since TR returns coverage for all vehicles
  const {
    Coverages = [],
    Deductibles = [],
    Endorsements = [],
  } = coverageData[0];
  const CoveragesObj = useMemo(
    () => arrayToEntityList(Coverages, "CoverageName"),
    [Coverages],
  ).entities;
  const DeductiblesObj = useMemo(
    () => arrayToEntityList(Deductibles, "DeductibleName"),
    [Deductibles],
  ).entities;
  const EndorsementsObj = useMemo(
    () => arrayToEntityList(Endorsements, "Name"),
    [Endorsements],
  ).entities;

  const displayCoverages =
    quoteType === "home"
      ? useSelector(homeSelectors.displayCoverages) || []
      : quoteType === "renters"
      ? useSelector(homeSelectors.rentersDisplayCoverages) || []
      : useSelector(autoSelectors.displayCoverages) || [];
  const moreCoverages =
    quoteType === "home" || quoteType === "renters"
      ? useSelector(homeSelectors.moreCoverages) || []
      : quoteType === "renters"
      ? useSelector(homeSelectors.rentersMoreCoverages) || []
      : useSelector(autoSelectors.moreCoverages) || [];

  return (
    <Box>
      <List className={classes.props}>
        {displayCoverages &&
          displayCoverages.map((coverage, i) => {
            return (
              <PropListItem
                prop={CoveragesObj[coverage] ?? DeductiblesObj[coverage]}
                fullProps={coverageData}
                coverageName={coverage}
                key={i}
                carrier={carrier.toLowerCase()}
                quoteType={quoteType}
              />
            );
          })}
      </List>
      <List
        className={clsx(
          classes.moreProps,
          showQuoteCardDetails ? "open" : "closed",
        )}
        style={{
          maxHeight: showQuoteCardDetails
            ? `${moreCoverages.length * 112}px`
            : 0,
        }}
      >
        {moreCoverages.map((coverage, i) => {
          return (
            <PropListItem
              prop={CoveragesObj[coverage] ?? EndorsementsObj[coverage]}
              fullProps={coverageData}
              coverageName={coverage}
              key={i}
              carrier={carrier.toLowerCase()}
              quoteType={quoteType}
            />
          );
        })}
      </List>
    </Box>
  );
};

export default QuoteProps;
