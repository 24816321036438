import { Box, Tab, IconButton, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React, { useState } from "react";
import { ExpandMoreIcon } from "../../../components/icons";
import { adminSelectors, useSelector } from "../../../state";
import { useStyles } from "./BundleQuotesContainer.styles";
import clsx from "clsx";
import FilteredBundleCoverageSection from "./FilteredBundleCoverageSection";

const BundleQuotesContainer = () => {
  const classes = useStyles();
  const activeQuote = useSelector(adminSelectors.activeQuote);
  const quotes = activeQuote?.quotes;
  const custom = Object(quotes["0"]);
  const low = Object(quotes["1"]);
  const med = Object(quotes["2"]);
  const high = Object(quotes["3"]);

  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [autoLinkedExpanded, setAutoLinkedExpanded] = useState(false);
  const [autoUnlinkedExpanded, setAutoUnlinkedExpanded] = useState(false);
  const [homeLinkedExpanded, setHomeLinkedExpanded] = useState(false);
  const [homeUnlinkedExpanded, setHomeUnlinkedExpanded] = useState(false);

  const handleExpandClick = (
    quote_type: "auto" | "home",
    is_linked: boolean,
  ) => {
    if (quote_type === "auto") {
      if (is_linked) {
        setAutoLinkedExpanded(!autoLinkedExpanded);
      } else {
        setAutoUnlinkedExpanded(!autoUnlinkedExpanded);
      }
    } else {
      if (is_linked) {
        setHomeLinkedExpanded(!homeLinkedExpanded);
      } else {
        setHomeUnlinkedExpanded(!homeUnlinkedExpanded);
      }
    }
  };

  return (
    <TabContext value={value}>
      <Box className={classes.tabsWrapper}>
        <TabList onChange={handleChange}>
          <Tab label="Auto" value="1" />
          <Tab label="Home" value="2" />
        </TabList>
      </Box>

      <TabPanel value="1">
        <Box className={classes.sectionWrapper}>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: autoLinkedExpanded,
            })}
            onClick={() => {
              handleExpandClick("auto", true);
            }}
            aria-expanded={autoLinkedExpanded}
            aria-label="show linked quotes"
            size="large">
            <ExpandMoreIcon />
          </IconButton>
          <Typography variant="h6">Bundled Quotes</Typography>
        </Box>
        {autoLinkedExpanded && (
          <FilteredBundleCoverageSection
            low={low}
            med={med}
            high={high}
            custom={custom}
            quote_type={"auto"}
            is_linked={true}
          />
        )}
        <Box className={classes.sectionWrapper}>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: autoUnlinkedExpanded,
            })}
            onClick={() => handleExpandClick("auto", false)}
            aria-expanded={autoUnlinkedExpanded}
            aria-label="show unlinked quotes"
            size="large">
            <ExpandMoreIcon />
          </IconButton>
          <Typography variant="h6">Individual Quotes</Typography>
        </Box>
        {autoUnlinkedExpanded && (
          <FilteredBundleCoverageSection
            low={low}
            med={med}
            high={high}
            custom={custom}
            quote_type={"auto"}
            is_linked={false}
          />
        )}
      </TabPanel>

      <TabPanel value="2">
        <Box className={classes.sectionWrapper}>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: homeLinkedExpanded,
            })}
            onClick={() => handleExpandClick("home", true)}
            aria-expanded={homeLinkedExpanded}
            aria-label="show linked quotes"
            size="large">
            <ExpandMoreIcon />
          </IconButton>
          <Typography variant="h6">Bundled Quotes</Typography>
        </Box>
        {homeLinkedExpanded && (
          <FilteredBundleCoverageSection
            low={low}
            med={med}
            high={high}
            custom={custom}
            quote_type={"home"}
            is_linked={true}
          />
        )}
        <Box className={classes.sectionWrapper}>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: homeUnlinkedExpanded,
            })}
            onClick={() => handleExpandClick("home", false)}
            aria-expanded={homeUnlinkedExpanded}
            aria-label="show unlinked quotes"
            size="large">
            <ExpandMoreIcon />
          </IconButton>
          <Typography variant="h6">Individual Quotes</Typography>
        </Box>
        {homeUnlinkedExpanded && (
          <FilteredBundleCoverageSection
            low={low}
            med={med}
            high={high}
            custom={custom}
            quote_type={"home"}
            is_linked={false}
          />
        )}
      </TabPanel>
    </TabContext>
  );
};

export default React.memo(BundleQuotesContainer);
