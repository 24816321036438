import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";

import RichTextEditor from "../../../../components/forms/inputs/RichTextEditor";

import { useFormik, yup } from "../../../../lib";
import useFAQs from "../../../../hooks/admin/useFAQs";
import { useAction, adminActions } from "../../../../state";

import { useStyles } from "./CreateFAQPage.styles";

const initialValues = {
  category_id: null,
  question: "",
  answer: "",
  order: 0,
};

const validationSchema = yup.object({
  category_id: yup.number().nullable().required("Order is required"),
  question: yup.string().required("Question is required"),
  answer: yup.string().required("Answer is required"),
  order: yup.number().required("Order is required"),
});

const CreateFAQPage = () => {
  const classes = useStyles();

  const { faqCategories = [] } = useFAQs();

  const setPageTitle = useAction(adminActions.setPageTitle);
  const createFAQ = useAction(adminActions.createFAQ);

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values) {
      createFAQ(values);
    },
  });

  useEffect(() => {
    setPageTitle("Create FAQ");
  }, [setPageTitle]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                select
                fullWidth
                name="category_id"
                id="category_id"
                label="Category"
                variant="outlined"
                value={form.values.category_id || ""}
                onChange={form.handleChange}
                error={
                  form.touched.category_id && Boolean(form.errors.category_id)
                }
                helperText={form.touched.category_id && form.errors.category_id}
              >
                {faqCategories.map((category) => (
                  <MenuItem
                    key={`category-${category.category_name}`}
                    value={category.id}
                  >
                    {category.category_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="question"
                name="question"
                label="Question"
                value={form.values.question}
                onChange={form.handleChange}
                error={form.touched.question && Boolean(form.errors.question)}
                helperText={form.touched.question && form.errors.question}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                type="number"
                variant="outlined"
                id="order"
                name="order"
                label="Order"
                value={form.values.order}
                onChange={form.handleChange}
                error={form.touched.order && Boolean(form.errors.order)}
                helperText={form.touched.order && form.errors.order}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <RichTextEditor
                name="answer"
                placeholder="Answer"
                setFieldValue={form.setFieldValue}
              />
              {form.touched.answer && form.errors.answer && (
                <FormHelperText error>{form.errors.answer}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={form.isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateFAQPage;
