import React, { useState, useCallback } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import InputMask from "react-input-mask";
// import { format, isValid } from "date-fns";

import { LockIcon } from "../../../components/icons";
import PaymentInProgress from "./PaymentInProgress";

import {
  useSelector,
  useDispatch,
  autoActions,
  wizardSelectors,
} from "../../../state";
import { Form, states, useFormik } from "../../../lib";
import { payInfoValidationSchema as validationSchema } from "../../../utils";

import { useStyles } from "./PayForm.styles";
// import { DatePicker } from "@mui/x-date-pickers";

const PayForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const req_uid = useSelector(wizardSelectors.req_uid);
  const selectedQuote = useSelector(wizardSelectors.selectedQuote);

  const [inProgress, setInprogress] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const initialValues: any = {
    account_name: "",
    aba_number: "",
    acct_number: "",
    cc_number: "",
    expiry_date: null,
    expiry_date_str: null,
    cvv: "",
    first_name: "",
    last_name: "",
    addr1: "",
    city: "",
    state: "",
    zip: "",
    ...selectedQuote,
  };
  const submitForm = useCallback(
    (values) => {
      dispatch(
        autoActions.savePaymentInfo(
          selectedQuote?.payment_type,
          req_uid,
          values,
        ),
      );
    },
    [dispatch, selectedQuote?.payment_type, req_uid],
  );

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: formSubmitted,
    initialValues,
    validationSchema,
    async onSubmit(values) {
      setInprogress(true);
      submitForm(values);
    },
  });

  return (
    <Box className={classes.root}>
      <Form form={form}>
        <Box className={classes.header}>
          <Box className={classes.titleWrapper}>
            <LockIcon htmlColor="#FFFFFF" />
            <Typography className={classes.title}>Payment form</Typography>
          </Box>
          <Typography className={classes.subTitle}>
            Please enter your details in the secure payment form below.
          </Typography>
        </Box>
        {inProgress ? (
          <PaymentInProgress isOffline={true} />
        ) : (
          <Box className={classes.bottomSection}>
            {selectedQuote?.payment_type !== "cc" ? (
              <Grid className={classes.subSection} container spacing={3}>
                <Grid item xs={12}>
                  <Typography className={classes.subSectionTitle}>
                    Payment info
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="account_name"
                    name="account_name"
                    label="Name on account"
                    value={form.values.account_name}
                    onChange={form.handleChange}
                    error={!!form.errors.account_name}
                    helperText={form.errors.account_name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    inputProps={{ maxLength: 9, minLength: 9 }}
                    id="aba_number"
                    name="aba_number"
                    label="Routing number"
                    value={form.values.aba_number}
                    onChange={form.handleChange}
                    error={!!form.errors.aba_number}
                    helperText={form.errors.aba_number}
                    onBlur={(e) => {
                      submitForm(form.values);
                      form.handleBlur(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="acct_number"
                    name="acct_number"
                    label="Account number"
                    value={form.values.acct_number}
                    onChange={form.handleChange}
                    error={
                      !!form.errors.acct_number && !!form.touched.acct_number
                    }
                    helperText={
                      form.touched.acct_number ? form.errors.acct_number : ""
                    }
                    onBlur={(e) => {
                      submitForm(form.values);
                      form.handleBlur(e);
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid className={classes.subSection} container spacing={3}>
                <Grid item xs={12}>
                  <Typography className={classes.subSectionTitle}>
                    Payment info
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="account_name"
                    name="account_name"
                    label="Name on account"
                    value={form.values.account_name}
                    onChange={form.handleChange}
                    error={!!form.errors.account_name}
                    helperText={form.errors.account_name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputMask
                    mask="9999 9999 9999 9999"
                    maskChar=" "
                    onChange={form.handleChange}
                    value={form.values.cc_number}
                    onBlur={(e) => {
                      submitForm(form.values);
                      form.handleBlur(e);
                    }}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        id="cc_number"
                        name="cc_number"
                        label="Card number"
                        // value={form.values.cc_number}
                        // onChange={form.handleChange}
                        error={!!form.errors.cc_number}
                        helperText={form.errors.cc_number}
                      />
                    )}
                  </InputMask>
                </Grid>
                {/* <Grid item md={6}>
                  <DatePicker
                    views={["month", "year"]}
                    id="expiry_date"
                    label="Exp date"
                    format="MM/yy"
                    value={form.values.expiry_date}
                    onChange={(date) => {
                      if (date && isValid(date)) {
                        const result = format(date, "MM/yy");
                        form.setFieldValue("expiry_date_str", result);
                      }
                      form.setFieldValue("expiry_date", date);
                    }}
                    minDate={new Date()}
                    error={
                      !!form.errors.expiry_date && !!form.touched.expiry_date
                    }
                    helperText={
                      form.touched.expiry_date ? form.errors.expiry_date : ""
                    }
                    onBlur={(e) => {
                      submitForm(form.values);
                      form.handleBlur(e);
                    }}
                  />
                </Grid> */}
                <Grid item md={6}>
                  <TextField
                    inputProps={{ maxLength: 4, minLength: 3 }}
                    id="cvv"
                    name="cvv"
                    label="CVV"
                    value={form.values.cvv}
                    onChange={form.handleChange}
                    error={!!form.errors.cvv && !!form.touched.cvv}
                    helperText={form.touched.cvv ? form.errors.cvv : ""}
                    onBlur={(e) => {
                      submitForm(form.values);
                      form.handleBlur(e);
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid className={classes.subSection} container spacing={3}>
              <Grid item xs={12}>
                <Typography className={classes.subSectionTitle}>
                  Billing info
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="first_name"
                  name="first_name"
                  label="First name"
                  value={form.values.first_name}
                  onChange={form.handleChange}
                  error={!!form.errors.first_name}
                  helperText={form.errors.first_name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="last_name"
                  name="last_name"
                  label="Last name"
                  value={form.values.last_name}
                  onChange={form.handleChange}
                  error={!!form.errors.last_name}
                  helperText={form.errors.last_name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="addr1"
                  name="addr1"
                  label="Address"
                  value={form.values.addr1}
                  onChange={form.handleChange}
                  error={!!form.errors.addr1}
                  helperText={form.errors.addr1}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="city"
                  name="city"
                  label="City"
                  value={form.values.city}
                  onChange={form.handleChange}
                  error={!!form.errors.city}
                  helperText={form.errors.city}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  select
                  id="state"
                  name="state"
                  label="State"
                  value={form.values.state}
                  onChange={form.handleChange}
                  error={!!form.errors.state}
                  helperText={form.errors.state}
                >
                  {states.map((s) => (
                    <MenuItem key={s.abbrev} value={s.abbrev}>
                      {s.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  id="zip"
                  name="zip"
                  label="Zip"
                  value={form.values.zip}
                  onChange={form.handleChange}
                  error={!!form.errors.zip}
                  helperText={form.errors.zip}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              onClick={() => setFormSubmitted(true)}
            >
              Pay Now
            </Button>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default PayForm;
