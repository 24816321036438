import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, AuthUser } from "../types";

const initialState: AuthState = {};

export const auth = {
  ...createSlice({
    name: "auth",
    initialState,
    reducers: {
      setToken(state, { payload }: PayloadAction<string>) {
        state.token = payload;
      },
      setAuthState(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) {
          return initialState;
        }
        return {
          ...state,
          ...payload,
        };
      },
      setUser(state, { payload }: PayloadAction<AuthUser>) {
        const _user = { ...state.user, ...payload };
        state.user = _user;
      },
      setActiveAgencyId(state, { payload }: PayloadAction<number>) {
        state.activeAgencyId = payload;
      },
    },
  }),
  persist: true,
};
