import React, { useEffect } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { useStyles } from "./ShowMoreCoverages.styles";
import {
  autoActions,
  autoSelectors,
  homeActions,
  homeSelectors,
  useAction,
  useSelector,
  wizardActions,
  wizardSelectors,
  wizardNoPersistSelectors,
} from "../../../state";
import { formatCurrency, getInsuranceLevelTitle } from "../../../utils";
import { Navigation } from "../../../lib";
import ReactGA from "react-ga";

const ShowMoreCoverages = ({
  quoteCount,
  level,
  moreOptionsOpen,
  quotes,
  showingBundled,
}) => {
  // actions
  const clearSelectedAutoQuote = useAction(autoActions.clearSelectedAutoQuote);
  const clearSelectedHomeQuote = useAction(homeActions.clearSelectedHomeQuote);
  const saveSelectedAutoQuote = useAction(autoActions.saveSelectedQuote);
  const saveSelectedHomeQuote = useAction(homeActions.saveSelectedQuote);
  const setCarouselMode = useAction(wizardActions.setCarouselMode);
  const setSelectedAutoQuote = useAction(autoActions.setSelectedAutoQuote);
  const setSelectedHomeQuote = useAction(homeActions.setSelectedHomeQuote);
  // selectors
  const allQuoteData = useSelector(wizardSelectors.allQuotes);
  const activeQuoteCard = useSelector(wizardSelectors.activeQuoteCard);
  const carouselMode = useSelector(wizardSelectors.carouselMode);
  const isAutoQuoteSelected = useSelector(autoSelectors.selectedQuote);
  const isHomeQuoteSelected = useSelector(homeSelectors.selectedQuote);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const req_uid = useSelector(wizardSelectors.req_uid);
  // consts
  const classes = useStyles({
    quoteCount,
    moreOptionsOpen,
    moreOptionsCount: allQuoteData[activeQuoteCard]?.length,
  });
  const excludedIds = quotes?.map((level) => level.turbo_quote_id) || [];

  useEffect(() => {
    ReactGA.event({
      category: "User",
      action: "Clicked show more coverages",
    });
  }, []);

  const handleSelectQuote = async (id, quote) => {
    if (quote.quote_type === "auto") {
      setSelectedAutoQuote(quote);
      await saveSelectedAutoQuote(req_uid, id);
      if (mode === "auto") {
        Navigation.go(`/auto/wizard/quotes/${req_uid}`);
      }
    }
    if (quote.quote_type === "home" || quote.quote_type === "renters") {
      setSelectedHomeQuote(quote);
      await saveSelectedHomeQuote(req_uid, id, quote.quote_type);
      if (mode === "home") {
        Navigation.go(`/home/wizard/quotes/${req_uid}`);
      }
      if (mode === "renters") {
        Navigation.go(`/renters/wizard/quotes/${req_uid}`);
      }
    }
    if (mode === "bundle" || mode === "auto-renters") {
      if (carouselMode === "auto") {
        if (isHomeQuoteSelected) {
          Navigation.go(`/${mode}/wizard/quotes/${req_uid}`);
        } else {
          setCarouselMode("home");
        }
      }
      if (carouselMode === "home") {
        if (isAutoQuoteSelected) {
          Navigation.go(`/${mode}/wizard/quotes/${req_uid}`);
        } else {
          setCarouselMode("auto");
        }
      }
    }
  };

  const getAllFilteredQuotes = () => {
    const filteredQuotes = allQuoteData[level]
      .filter(
        (q) =>
          !excludedIds?.includes(q.turbo_quote_id) &&
          (q.quote_type === mode ||
            q.quote_type === carouselMode ||
            (q.quote_type === "renters" && carouselMode === "home")) &&
          q.is_linked === showingBundled,
      )
      .sort((a, b) => a.TotalPremium - b.TotalPremium);
    return filteredQuotes;
  };

  return (
    <Box className={classes.root}>
      {level !== undefined && Boolean(allQuoteData[level]?.length) && (
        <Box key={level}>
          <Box className={classes.coverageLevelSection}>
            <Typography className={classes.coverageLevelTitle}>
              {getInsuranceLevelTitle(Number(level))}
            </Typography>
            {allQuoteData[level]?.filter(
              (q) =>
                (q.quote_type === mode ||
                  q.quote_type === carouselMode ||
                  (q.quote_type === "renters" && carouselMode === "home")) &&
                q.is_linked === showingBundled,
            ).length > 1 ? (
              getAllFilteredQuotes().map((quote, i) => {
                const selectedQuote =
                  quote.turbo_quote_id ===
                    isAutoQuoteSelected?.turbo_quote_id ||
                  quote.turbo_quote_id === isHomeQuoteSelected?.turbo_quote_id;
                return (
                  <Card
                    className={classes.card}
                    key={i}
                    onClick={() =>
                      selectedQuote
                        ? quote.quote_type === "auto"
                          ? clearSelectedAutoQuote()
                          : clearSelectedHomeQuote()
                        : handleSelectQuote(quote.turbo_quote_id, quote)
                    }
                  >
                    <Box flexDirection="row">
                      <img
                        className={classes.carrierImg}
                        src={quote.logo_full_url}
                        alt="carrier_logo"
                      />
                      <Grid container style={{ paddingBottom: 32 }}>
                        <Grid item xs={10}>
                          <Typography className={classes.premium}>
                            {quote.quote_type === "auto"
                              ? formatCurrency(
                                  Math.round(quote.TotalPremium / 6),
                                )
                              : formatCurrency(Math.round(quote.TotalPremium))}
                            <span className={classes.mo}>
                              {quote.quote_type === "auto" ? "/mo" : "/yr"}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid
                          className={classes.selectText}
                          key={i}
                          item
                          xs={2}
                          container
                        >
                          {selectedQuote ? "Deselect" : "Select"}
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                );
              })
            ) : (
              <Typography className={classes.noQuotes}>
                No additional quotes for this coverage level
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ShowMoreCoverages;
