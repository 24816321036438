import React from "react";
import { Card, Typography } from "@mui/material";
// import {
//   activeChat_agent,
//   activeChat_snag,
//   customerSDK_agentChat,
//   customerSDK_snagChat,
// } from "../../../utils/liveChat";
// import { ChatIcon, FiberManualRecordIcon } from "../../../components/icons";
import { ContactMethods } from "../../../utils/contact";
import { useAction, wizardActions } from "../../../state";
// import { IS_AGENCY_SITE } from "../../../config";
import { useStyles } from "./SelectedQuote.styles";
// import { timeoutAsync } from "../../../lib/utils";

export default function ContactCards({ title }) {
  const classes = useStyles({});
  const showContactModal = useAction(wizardActions.showContactModal);
  // const setShowChat = useAction(wizardActions.setShowChat);
  // const [online, setOnline] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     //eslint-disable-next-line
  //     const customerSDK = !snag ? customerSDK_agentChat : customerSDK_snagChat;
  //     await timeoutAsync(500); //wait till chat is instantiated
  //     const activeChat = !snag ? activeChat_agent : activeChat_snag;
  //     if (
  //       activeChat &&
  //       activeChat.availability &&
  //       activeChat.availability === "online"
  //     ) {
  //       setOnline(true);
  //     }
  //   })();
  // }, []);

  return (
    <div className={classes.contactWrapper}>
      <Typography className={classes.title}>{title}</Typography>
      {/* {!IS_AGENCY_SITE && online && (
        <Card className={classes.contactCard} onClick={() => setShowChat(true)}>
          <ChatIcon color={"primary"} />
          <Typography className={classes.contactTitle}>
            Chat now online
          </Typography>
          <Typography className={classes.status}>
            <FiberManualRecordIcon
              htmlColor="#2ED272"
              style={{
                fontSize: 12,
                marginRight: 4,
              }}
            />
            We're online!
          </Typography>
        </Card>
      )} */}
      {Object.keys(ContactMethods).map((method) => {
        const Icon = ContactMethods[method].icon;
        return (
          <Card
            key={method}
            className={classes.contactCard}
            onClick={() => showContactModal(method)}
          >
            <Icon color="primary" />
            <Typography className={classes.contactTitle}>
              {ContactMethods[method].button}
            </Typography>
            <Typography className={classes.status}>
              Within 1 business day
            </Typography>
          </Card>
        );
      })}
    </div>
  );
}
