import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Button,
} from "@mui/material";
import { scroller } from "react-scroll";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../state";
// eslint-disable-next-line no-var
declare var simplemaps_usmap: any;
declare const simplemaps_usmap_mapdata: USMapData;
import { useStyles } from "./AddApplicationPage.styles";
import { Autocomplete } from "@mui/material";
import { USMapData } from "../../state/admin/state";
import { states } from "../../lib";
import { ExpandMoreIcon } from "../../components";
import { REACT_APP_ASSETS_URL } from "../../config";
import { useMobile } from "../../themes";

const ViewCarriersPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const [expandedAccordions, setExpandedAccordions] = useState<boolean[]>([]);
  const [expandedAll, setExpandedAll] = useState<boolean>(false);
  const [available, setAvailable] = useState<any[]>([]);
  const isMobile = useMobile("sm");

  useEffect(() => {
    dispatch(adminActions.getAvailableCarriers(agencyId));
  }, []);

  const availableCarriers = useSelector(adminSelectors.availableCarriers);

  const handleAccordionChange = (
    accordionIndex: number,
    isExpanded: boolean,
  ) => {
    setExpandedAccordions((prevExpanded) => {
      const newExpanded: boolean[] = [...prevExpanded];
      newExpanded[accordionIndex] = isExpanded;
      return newExpanded;
    });
  };

  const scrollToState = (id) => {
    setExpandedAccordions([]);
    setExpandedAll(false);
    const index = availableCarriers.findIndex((a) => a.state === id);
    handleAccordionChange(index, true);

    scroller.scrollTo(id, {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -230,
    });
  };

  const allStates = states;
  useEffect(() => {
    const availableStates = availableCarriers.map((ac) => ac.state);
    const unavailableStates = allStates.filter(
      (state) => !availableStates.includes(state.abbrev),
    );
    setAvailable(allStates.filter((s) => availableStates?.includes(s.abbrev)));
    if (typeof simplemaps_usmap !== "undefined") {
      simplemaps_usmap_mapdata.main_settings.width = isMobile
        ? "375"
        : "responsive";
      availableCarriers.map((entry) => {
        simplemaps_usmap_mapdata.labels[entry.state] = {
          ...simplemaps_usmap_mapdata.labels[entry.state],
          parent_id: entry.state,
          pill: "yes",
          color: "#ffffff",
          hover_color: "#ffffff",
        };
        simplemaps_usmap_mapdata.state_specific[entry.state] = {
          ...simplemaps_usmap_mapdata.state_specific[entry.state],
          description: "Check availability",
          color: "#0033ff",
          hover_color: "#0033ff",
        };
      });
      unavailableStates.map((entry) => {
        simplemaps_usmap_mapdata.labels[entry.abbrev] = {
          ...simplemaps_usmap_mapdata.labels[entry.abbrev],
          parent_id: entry.abbrev,
          pill: "yes",
          color: "#000",
          hover_color: "#000",
        };
        simplemaps_usmap_mapdata.state_specific[entry.abbrev] = {
          ...simplemaps_usmap_mapdata.state_specific[entry.abbrev],
          description: "Not Available",
          color: "#f3f3f3",
          hover_color: "#f3f3f3",
        };
      });
      simplemaps_usmap.load();
      simplemaps_usmap.hooks.click_state = (id) => {
        const element = document.getElementById(id);
        if (element) {
          scrollToState(element.id);
        }
      };
    }
  }, [availableCarriers]);

  return (
    <>
      <Grid item className={classes.autoComplete} paddingTop={2}>
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          View carriers by state
        </Typography>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          marginTop: 4,
        }}
      >
        <Typography sx={{ fontWeight: 500 }}>
          Search for a state or click on the map to view a list of carriers for
          the selected state
        </Typography>
        <Autocomplete
          sx={{ width: 350 }}
          onChange={(_e, v) => {
            if (v) {
              scrollToState(v.abbrev);
            }
          }}
          options={available || []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                value=""
                label="Search for a state"
                size="small"
              ></TextField>
            );
          }}
        />
        <Box
          sx={{
            height: "500px",
            maxWidth: "700px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            id="map"
            sx={{
              margin: "25px 0",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              margin: "20px 0",
              justifyContent: "end",
            }}
          >
            <Button
              onClick={() => setExpandedAll(!expandedAll)}
              sx={{ color: "#204FFF" }}
            >
              Expand all
            </Button>
          </Box>
          {availableCarriers.map((state, i) => {
            const stateName = allStates.find(
              (stateObj) => stateObj.abbrev === state.state,
            )?.name;

            return (
              <Box
                key={state.state || i}
                id={state.state || "DC"}
                sx={{ minWidth: !isMobile ? "700px" : "375px" }}
              >
                <Divider />
                <Accordion
                  expanded={expandedAccordions[i] || expandedAll ? true : false}
                  onChange={(_e, isExpanded) =>
                    handleAccordionChange(i, isExpanded)
                  }
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        color: "#204FFF",
                        fontFamily: "Montserrat",
                        fontSize: 20,
                        fontWeight: 500,
                      }}
                    >
                      {stateName || "DC"}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      {state.carriers.map((carrier) => (
                        <Box
                          sx={{ width: "160px", height: "60px" }}
                          key={carrier.name}
                        >
                          <img
                            src={`${REACT_APP_ASSETS_URL}/${carrier.logo_path}`}
                            height="30"
                          />
                        </Box>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default ViewCarriersPage;
