import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import ScrollToOnError from "../../../../../components/forms/ScrollToOnError";

import {
  autoActions,
  autoSelectors,
  useAction,
  useSelector,
  wizardNoPersistSelectors,
} from "../../../../../state";
import {
  DOB_MAX_DATE,
  MARITAL_STATUS_OPTIONS,
  TICKETS_MIN_DATE,
  TICKET_AMOUNTS,
  TICKET_CATEGORIES,
} from "../../../../../constants";
import { useIndustriesOccupations } from "../../../../../hooks";
import { Form, Navigation, useFormik } from "../../../../../lib";
import { numberDriver } from "../../../../../utils";
import { driverValidationSchema as validationSchema } from "../../../../../utils/validationSchemas";

import { useStyles } from "./DriverFields.styles";
import { DeleteIcon } from "../../../../../components/icons";
import { REASON_FOR_NO_INS } from "../../../../../state/auto/types";
import { DatePicker } from "@mui/x-date-pickers";

const DriverFields = ({ index }) => {
  const classes = useStyles();
  // actions
  const removeDriver = useAction(autoActions.removeDriverAndUpdateAppIndex);
  const setDriver = useAction(autoActions.setDriver);
  const toNextDriver = useAction(autoActions.toNextDriver);
  const toPreviousDriver = useAction(autoActions.toPreviousDriver);
  const setShowSelectDriversView = useAction(
    autoActions.setShowSelectDriverView,
  );
  // selectors
  const activeDriverIndex = useSelector(autoSelectors.activeDriverIndex);
  const selectedDrivers = useSelector(autoSelectors.selectedDrivers);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const applicantIndex = useSelector(autoSelectors.applicantIndex);
  // consts
  const currentDriver = selectedDrivers[index];
  const isApplicant = applicantIndex === index;
  const isLastDriver = activeDriverIndex + 1 === selectedDrivers.length;
  // state
  const [formSubmitted, setFormSubmitted] = useState(false);

  const form: any = useFormik({
    enableReinitialize: true,
    validateOnChange: formSubmitted,
    initialValues: {
      ...currentDriver,
      isApplicant,
      isLastDriver,
      continuous_insurance: currentDriver.continuous_insurance || "Y",
      no_insurance_reason: currentDriver.no_insurance_reason || "",
      accidents: currentDriver.accidents || false,
      collision: currentDriver.collision || false,
      MiddleName: currentDriver.MiddleName || "",
      manualLicense: currentDriver.manualLicense || "",
      dob: currentDriver.dob ? new Date(currentDriver.dob) : "",
      relationship_to_applicant: isApplicant
        ? "I"
        : currentDriver.spouse
        ? "S"
        : currentDriver.relationship_to_applicant,
    },
    validationSchema,
    async onSubmit(values) {
      const selectedDrivers = await setDriver({
        driver: values,
        index: activeDriverIndex,
      });

      const isLast = activeDriverIndex + 1 === selectedDrivers.length;
      if (isLast) {
        if (mode === "auto") {
          Navigation.go(`/auto/wizard/discounts`);
        } else if (mode === "bundle") {
          Navigation.go(`/bundle/wizard/auto-discounts`);
        } else if (mode === "auto-renters") {
          Navigation.go(`/auto-renters/wizard/auto-discounts`);
        }
      } else {
        toNextDriver();
      }
    },
  });

  const clearViolationInfo = useCallback(() => {
    form.setFieldValue("accidents", false);
    form.setFieldValue("faultAccidents", undefined);
    form.setFieldValue("dwi", undefined);
    form.setFieldValue("collision", false);
    form.setFieldValue("tickets", []);
    form.setFieldValue("ticketamounts", "");
  }, [form]);

  const clearTicketData = useCallback(() => {
    form.setFieldValue("ticketamounts", "");
    form.setFieldValue("tickets", []);
    form.setFieldValue("faultAccidents", undefined);
    form.setFieldValue("dwi", undefined);
  }, [form]);

  const handleRemoveDriver = useCallback(() => {
    removeDriver(activeDriverIndex);

    if (index === 0) {
      setShowSelectDriversView(true);
    } else {
      toPreviousDriver();
    }
  }, [removeDriver, activeDriverIndex, toPreviousDriver]);

  const handleTicketAmountChange = useCallback(
    (amount) => {
      form.setFieldValue("ticketamounts", amount?.target?.value);

      const tickets: any[] = [];
      for (let i = 0; i < amount?.target?.value; i++) {
        tickets.push({ speed: undefined, date: undefined });
      }

      form.setFieldValue("tickets", tickets);
    },
    [form],
  );

  const handleInsureOnPolicyChange = useCallback(
    (fieldName) => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) form.setFieldValue(fieldName, value);
      if (!value) clearViolationInfo();
    },
    [clearViolationInfo, form],
  );
  const handleAccidentsChange = useCallback(
    (fieldName) => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) form.setFieldValue(fieldName, value);
      if (!value) clearTicketData();
    },
    [clearTicketData, form],
  );
  const handleSwitcherChange = useCallback(
    (fieldName) => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) form.setFieldValue(fieldName, value);
      if (fieldName === "relationship_to_applicant" && value === "S") {
        form.setFieldValue("marital_status", "M");
      }
    },
    [form],
  );

  const isSpouse = Boolean(form.values.spouse);
  const insureOnPolicy = Boolean(form.values.insureDriver);
  const { industriesOccupations, industries, industriesOccupationsLoading } =
    useIndustriesOccupations();

  useEffect(() => {
    if (
      !!form.values.industry &&
      industriesOccupations &&
      industriesOccupations[form.values.industry] &&
      industriesOccupations[form.values.industry]?.length === 1
    ) {
      form.setFieldValue(
        "occupation_class",
        industriesOccupations[form.values.industry][0]?.Value,
      );
    }
    //eslint-disable-next-line
  }, [form.values.industry]);

  return (
    <Form form={form}>
      <Box className={classes.root}>
        <Box className={classes.field}>
          {!currentDriver.isUserEntered &&
          currentDriver.FirstName !== "" &&
          currentDriver.LastName !== "" ? (
            <>
              <Typography variant="h6" className={classes.fieldTitle}>
                {numberDriver(index + 1) + " driver"}
              </Typography>
              <Typography variant="h5" className={classes.name}>
                {currentDriver?.FirstName} {currentDriver?.MiddleName}{" "}
                {currentDriver?.LastName}
              </Typography>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5}>
                <ScrollToOnError name="FirstName">
                  <TextField
                    error={Boolean(form.errors.FirstName)}
                    id="FirstName"
                    name="FirstName"
                    label="First name"
                    value={form.values.FirstName || ""}
                    onChange={form.handleChange}
                  />
                </ScrollToOnError>

                {Boolean(form.errors.FirstName) && (
                  <FormHelperText error variant="outlined">
                    {form.errors.FirstName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={2}>
                <ScrollToOnError name="MiddleName">
                  <TextField
                    id="MiddleName"
                    name="MiddleName"
                    label="Middle"
                    value={form.values.MiddleName || ""}
                    onChange={form.handleChange}
                  />
                </ScrollToOnError>
              </Grid>
              <Grid item xs={12} sm={5}>
                <ScrollToOnError name="LastName">
                  <TextField
                    error={Boolean(form.errors.LastName)}
                    id="LastName"
                    name="LastName"
                    label="Last name"
                    value={form.values.LastName || ""}
                    onChange={form.handleChange}
                  />
                </ScrollToOnError>

                {Boolean(form.errors.LastName) && (
                  <FormHelperText error variant="outlined">
                    {form.errors.LastName}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
        <Box className={classes.field}>
          <Typography variant="h6" className={classes.fieldTitle}>
            License
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <TextField
                id="license"
                name="manualLicense"
                label="License number (optional)"
                value={form.values.license}
                onChange={form.handleChange}
              />
            </Grid>
          </Grid>
        </Box>

        {!isApplicant && (
          <Box className={classes.field}>
            <Typography variant="h6" className={classes.fieldTitle}>
              Insure this person on the policy?
            </Typography>

            <Grid container spacing={5}>
              <Grid item xs={12}>
                <ScrollToOnError name="insureDriver">
                  <ToggleButtonGroup
                    className={classes.switcherGroup}
                    exclusive
                    size="large"
                    value={form.values.insureDriver}
                    onChange={handleInsureOnPolicyChange("insureDriver")}
                  >
                    <ToggleButton value={true} className={classes.switcher}>
                      Yes
                    </ToggleButton>
                    <ToggleButton value={false} className={classes.switcher}>
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ScrollToOnError>

                {Boolean(form.errors.insureDriver) && (
                  <FormHelperText error variant="outlined">
                    {form.errors.insureDriver}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        )}

        {!isApplicant && !isSpouse && (
          <Box className={classes.field}>
            <Typography variant="h6" className={classes.fieldTitle}>
              Relationship
            </Typography>

            <Grid container spacing={5}>
              <Grid item xs={12}>
                <ScrollToOnError name="relationship_to_applicant">
                  <ToggleButtonGroup
                    exclusive
                    size="large"
                    className={classes.switcherGroup}
                    value={form.values.relationship_to_applicant}
                    onChange={handleSwitcherChange("relationship_to_applicant")}
                  >
                    {applicantIndex !== undefined &&
                      selectedDrivers[applicantIndex]?.marital_status !== "M" &&
                      form.values.relationship_to_applicant === "S" &&
                      form.setFieldValue("relationship_to_applicant", "")}
                    {Boolean(
                      applicantIndex !== undefined &&
                        selectedDrivers[applicantIndex]?.marital_status === "M",
                    ) && (
                      <ToggleButton value="S" className={classes.switcher}>
                        Spouse
                      </ToggleButton>
                    )}

                    <ToggleButton value="C" className={classes.switcher}>
                      Child
                    </ToggleButton>
                    <ToggleButton value="R" className={classes.switcher}>
                      Other
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ScrollToOnError>

                {Boolean(form.errors.relationship_to_applicant) && (
                  <FormHelperText error variant="outlined">
                    {form.errors.relationship_to_applicant}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        )}

        <Box className={classes.field}>
          <Typography variant="h6" className={classes.fieldTitle}>
            Gender
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12}>
              <ScrollToOnError name="gender">
                <ToggleButtonGroup
                  className={`${classes.switcherGroup} ${
                    form.errors.gender ? classes.error : ""
                  }`}
                  exclusive
                  size="large"
                  value={form.values.gender}
                  onChange={handleSwitcherChange("gender")}
                >
                  <ToggleButton
                    value="M"
                    className={classes.switcher}
                    id="male"
                  >
                    Male
                  </ToggleButton>
                  <ToggleButton value="F" className={classes.switcher}>
                    Female
                  </ToggleButton>
                </ToggleButtonGroup>
              </ScrollToOnError>

              {Boolean(form.errors.gender) && (
                <FormHelperText error variant="outlined">
                  {form.errors.gender}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Box>

        {!isSpouse && form.values.relationship_to_applicant !== "S" && (
          <Box className={classes.field}>
            <Typography variant="h6" className={classes.fieldTitle}>
              Marital status
            </Typography>
            <ScrollToOnError name="marital_status">
              <FormControl
                component="fieldset"
                className={form.errors.marital_status ? classes.error : ""}
              >
                <RadioGroup
                  name="marital_status"
                  value={form.values.marital_status || ""}
                  onChange={form.handleChange}
                >
                  {MARITAL_STATUS_OPTIONS.map((status) => (
                    <FormControlLabel
                      key={`marital_status-${status.value}`}
                      value={status.value}
                      label={status.label}
                      control={<Radio color="primary" />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </ScrollToOnError>

            {Boolean(form.errors.marital_status) && (
              <FormHelperText error variant="outlined">
                {form.errors.marital_status}
              </FormHelperText>
            )}
          </Box>
        )}

        <Box className={classes.field}>
          <Typography variant="h6" className={classes.fieldTitle}>
            Date of birth
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <ScrollToOnError name="dob">
                <div>
                  <DatePicker
                    label={form.values.dob === null ? "MM/DD/YYYY" : ""}
                    format="MM/dd/yyyy"
                    value={form.values.dob || null}
                    onChange={(date) => {
                      form.setFieldValue("dob", date);
                    }}
                    maxDate={DOB_MAX_DATE}
                  />
                </div>
              </ScrollToOnError>

              {Boolean(form.errors.dob) && (
                <FormHelperText error variant="outlined">
                  {form.errors.dob}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.field}>
          <Typography variant="h6" className={classes.fieldTitle}>
            What is {isApplicant ? "your" : "their"} highest level of education?
          </Typography>

          <ScrollToOnError name="education_level">
            <FormControl
              component="fieldset"
              className={form.errors.education_level ? classes.error : ""}
            >
              <RadioGroup
                name="education_level"
                //value={form.values.educationSpouse || ""}
                value={form.values.education_level || ""}
                onChange={form.handleChange}
              >
                <FormControlLabel
                  value="H"
                  label="High school diploma / GED"
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  value="A"
                  label="Associate degree"
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  value="B"
                  label="Bachelor’s degree"
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  value="S"
                  label="Master’s degree"
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  value="P"
                  label="Doctoral degree"
                  control={<Radio color="primary" />}
                />
                <FormControlLabel
                  value="N"
                  label="No diploma"
                  control={<Radio color="primary" />}
                />
              </RadioGroup>
            </FormControl>
          </ScrollToOnError>

          {Boolean(form.errors.education_level) && (
            <FormHelperText error variant="outlined">
              {form.errors.education_level}
            </FormHelperText>
          )}
        </Box>

        <Box className={classes.field}>
          <Typography variant="h6" className={classes.fieldTitle}>
            {isApplicant
              ? "What do you do?"
              : isSpouse
              ? "What does your spouse do?"
              : "What does this driver do?"}
          </Typography>

          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <ScrollToOnError name="industry">
                <TextField
                  select
                  error={Boolean(form.errors.industry)}
                  id="industry"
                  name="industry"
                  label="Industry"
                  value={form.values.industry || ""}
                  onChange={form.handleChange}
                  disabled={
                    !industries?.length && !industriesOccupationsLoading
                  }
                  InputProps={{
                    endAdornment: industriesOccupationsLoading ? (
                      <CircularProgress
                        color="primary"
                        size={22}
                        className={classes.selectLoader}
                      />
                    ) : null,
                  }}
                >
                  {(industries || []).map((industry) => (
                    <MenuItem key={`industry-${industry}`} value={industry}>
                      {industry}
                    </MenuItem>
                  ))}
                </TextField>
              </ScrollToOnError>

              {Boolean(form.errors.industry) && (
                <FormHelperText error variant="outlined">
                  {form.errors.industry}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <ScrollToOnError name="occupation_class">
                <TextField
                  select
                  error={Boolean(form.errors.occupation_class)}
                  id="occupation_class"
                  name="occupation_class"
                  label="Occupation"
                  value={form.values.occupation_class || ""}
                  onChange={form.handleChange}
                  disabled={
                    !form.values.industry && !industriesOccupationsLoading
                  }
                  InputProps={{
                    endAdornment: industriesOccupationsLoading ? (
                      <CircularProgress
                        color="primary"
                        size={22}
                        className={classes.selectLoader}
                      />
                    ) : null,
                  }}
                >
                  {(form.values.industry
                    ? industriesOccupations
                      ? industriesOccupations[form.values.industry]
                      : []
                    : []
                  ).map((occupation) => (
                    <MenuItem
                      key={`occupation-${occupation.occupation}`}
                      value={occupation.Value}
                    >
                      {occupation.occupation}
                    </MenuItem>
                  ))}
                </TextField>
              </ScrollToOnError>

              {Boolean(form.errors.occupation_class) && (
                <FormHelperText error variant="outlined">
                  {form.errors.occupation_class}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Box>

        {isApplicant && (
          <Box className={classes.field}>
            <Typography variant="h6" className={classes.fieldTitle}>
              Do you currently have insurance?
            </Typography>

            <Grid container spacing={5}>
              <Grid item xs={12}>
                <ScrollToOnError name="continuous_insurance">
                  <ToggleButtonGroup
                    className={classes.switcherGroup}
                    exclusive
                    size="large"
                    value={form.values.continuous_insurance}
                    onChange={handleSwitcherChange("continuous_insurance")}
                  >
                    <ToggleButton
                      value="Y"
                      className={classes.switcher}
                      id="insured"
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton value="N" className={classes.switcher}>
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ScrollToOnError>

                {Boolean(form.errors.continuous_insurance) && (
                  <FormHelperText error variant="outlined">
                    {form.errors.continuous_insurance}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
        {form.values.continuous_insurance === "N" && (
          <Box className={classes.field}>
            <Typography variant="h6" className={classes.fieldTitle}>
              What is the reason for no prior insurance?
            </Typography>

            <Grid item xs={12} sm={6}>
              <ScrollToOnError name="no_insurance_reason">
                <TextField
                  select
                  error={Boolean(form.errors.no_insurance_reason)}
                  id="no_insurance_reason"
                  name="no_insurance_reason"
                  value={form.values.no_insurance_reason}
                  onChange={form.handleChange}
                >
                  {REASON_FOR_NO_INS.map((reason) => (
                    <MenuItem key={reason.value} value={reason.value}>
                      {reason.label}
                    </MenuItem>
                  ))}
                </TextField>
              </ScrollToOnError>

              {Boolean(form.errors.no_insurance_reason) && (
                <FormHelperText error variant="outlined">
                  {form.errors.no_insurance_reason}
                </FormHelperText>
              )}
            </Grid>
          </Box>
        )}

        {(isApplicant || insureOnPolicy) && (
          <>
            <Box className={classes.field}>
              <Typography variant="h6" className={classes.fieldTitle}>
                Any accidents or tickets in the past 5 years?
              </Typography>

              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <ScrollToOnError name="accidents">
                    <ToggleButtonGroup
                      className={classes.switcherGroup}
                      exclusive
                      size="large"
                      value={form.values.accidents}
                      onChange={handleAccidentsChange("accidents")}
                    >
                      <ToggleButton value={true} className={classes.switcher}>
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        value={false}
                        className={classes.switcher}
                        id="noAccidents"
                      >
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ScrollToOnError>

                  {Boolean(form.errors.accidents) && (
                    <FormHelperText error variant="outlined">
                      {form.errors.accidents}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Box>

            {form.values.accidents && (
              <>
                <Box className={classes.field}>
                  <Typography variant="h6" className={classes.fieldTitle}>
                    How many speeding tickets you have received in the past 5
                    years?
                  </Typography>
                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <ScrollToOnError name="ticketamounts">
                        <TextField
                          select
                          id="ticketamounts"
                          name="ticketamounts"
                          label="Amount (optional)"
                          value={form.values.ticketamounts || ""}
                          onChange={handleTicketAmountChange}
                        >
                          {TICKET_AMOUNTS.map((amount) => (
                            <MenuItem key={`amount-${amount}`} value={amount}>
                              {amount}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ScrollToOnError>
                    </Grid>
                  </Grid>

                  {Boolean(form.values.tickets && form.values.tickets.length) &&
                    form.values.tickets.map((ticket, index) => (
                      <Grid container spacing={5} key={index}>
                        <Grid item xs={6}>
                          <ScrollToOnError name={`tickets`}>
                            <TextField
                              select
                              id={`tickets[${index}].speed`}
                              name={`tickets[${index}].speed`}
                              label="Speed Amount"
                              value={
                                (form.values.tickets &&
                                  form.values.tickets[index] &&
                                  form.values.tickets[index].speed) ||
                                ""
                              }
                              className={classes.ticketFields}
                              onChange={form.handleChange}
                              error={Boolean(
                                form.errors.tickets &&
                                  form.errors.tickets[index] &&
                                  form.errors.tickets[index].speed,
                              )}
                              helperText={
                                form.errors.tickets &&
                                form.errors.tickets[index] &&
                                form.errors.tickets[index].speed
                              }
                            >
                              {TICKET_CATEGORIES.map((category) => (
                                <MenuItem
                                  key={`category-${category.label}`}
                                  value={category.value}
                                >
                                  {category.label} MPH above the limit
                                </MenuItem>
                              ))}
                            </TextField>
                          </ScrollToOnError>
                        </Grid>
                        <Grid item xs={6}>
                          <ScrollToOnError name={`tickets`}>
                            <DatePicker
                              label="MM/DD/YYYY"
                              format="MM/dd/yyyy"
                              className={classes.ticketFields}
                              value={
                                (form.values.tickets &&
                                  form.values.tickets[index] &&
                                  form.values.tickets[index].date &&
                                  new Date(form.values.tickets[index].date)) ||
                                null
                              }
                              onChange={(date) => {
                                form.setFieldValue(
                                  `tickets[${index}].date`,
                                  date,
                                );
                              }}
                              minDate={TICKETS_MIN_DATE}
                              maxDate={new Date()}
                            />
                          </ScrollToOnError>
                          {Boolean(
                            form.errors.tickets &&
                              form.errors.tickets[index] &&
                              form.errors.tickets[index].date,
                          ) && (
                            <FormHelperText error variant="outlined">
                              {form.errors.tickets &&
                                form.errors.tickets[index] &&
                                form.errors.tickets[index].date}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                </Box>
                <Box className={classes.field}>
                  <Typography variant="h6" className={classes.fieldTitle}>
                    Have you had any at fault accidents in the past 5 years?
                  </Typography>

                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <ScrollToOnError name="faultAccidents">
                        <ToggleButtonGroup
                          className={classes.switcherGroup}
                          exclusive
                          size="large"
                          value={form.values.faultAccidents}
                          onChange={handleSwitcherChange("faultAccidents")}
                        >
                          <ToggleButton
                            value={true}
                            className={classes.switcher}
                          >
                            Yes
                          </ToggleButton>
                          <ToggleButton
                            value={false}
                            className={classes.switcher}
                          >
                            No
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ScrollToOnError>

                      {Boolean(form.errors.faultAccidents) && (
                        <FormHelperText error variant="outlined">
                          {form.errors.faultAccidents}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Box>

                <Box className={classes.field}>
                  <Typography variant="h6" className={classes.fieldTitle}>
                    Have you had any accidents due to driving while intoxicated?
                  </Typography>

                  <Grid container spacing={5}>
                    <Grid item xs={12}>
                      <ScrollToOnError name="dwi">
                        <ToggleButtonGroup
                          className={classes.switcherGroup}
                          exclusive
                          size="large"
                          value={form.values.dwi}
                          onChange={handleSwitcherChange("dwi")}
                        >
                          <ToggleButton
                            value={true}
                            className={classes.switcher}
                          >
                            Yes
                          </ToggleButton>
                          <ToggleButton
                            value={false}
                            className={classes.switcher}
                          >
                            No
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ScrollToOnError>

                      {Boolean(form.errors.dwi) && (
                        <FormHelperText error variant="outlined">
                          {form.errors.dwi}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}

            <Box className={classes.field}>
              <Typography variant="h6" className={classes.fieldTitle}>
                Any non accident claims in the past 5 years (i.e. shattered
                windshield, hit a deer)?
              </Typography>

              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <ScrollToOnError name="collision">
                    <ToggleButtonGroup
                      className={classes.switcherGroup}
                      exclusive
                      size="large"
                      value={form.values.collision}
                      onChange={handleSwitcherChange("collision")}
                    >
                      <ToggleButton value={true} className={classes.switcher}>
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        value={false}
                        className={classes.switcher}
                        id="noCollision"
                      >
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ScrollToOnError>

                  {Boolean(form.errors.collision) && (
                    <FormHelperText error variant="outlined">
                      {form.errors.collision}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Typography style={{ marginBottom: 12 }}>
              * All insurance companies will see your actual driving record when
              you checkout. The accuracy of this information will ensure we get
              your quote right the first time.
            </Typography>
          </>
        )}

        {isLastDriver && !isSpouse && (
          <Box className={classes.field}>
            <Typography variant="h6" className={classes.fieldTitle}>
              Are there any other drivers to insure?
            </Typography>

            <Grid container spacing={5}>
              <Grid item xs={12}>
                <ScrollToOnError name="moreDrivers">
                  <ToggleButtonGroup
                    className={classes.switcherGroup}
                    exclusive
                    size="large"
                    value={form.values.moreDrivers}
                    onChange={handleSwitcherChange("moreDrivers")}
                  >
                    <ToggleButton value={true} className={classes.switcher}>
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      id="noMoreDrivers"
                      value={false}
                      className={classes.switcher}
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ScrollToOnError>
              </Grid>
            </Grid>
          </Box>
        )}

        {!isApplicant && !currentDriver.spouse && (
          <Box>
            <Button
              onClick={handleRemoveDriver}
              className={classes.removeDriverBtn}
            >
              <DeleteIcon className={classes.removeIcon} />
              REMOVE THIS DRIVER
            </Button>
          </Box>
        )}
      </Box>

      <Grid container justifyContent="center" alignItems="center">
        <Button
          onClick={() => setFormSubmitted(true)}
          id="submit"
          type="submit"
          variant="contained"
          color="primary"
        >
          {isLastDriver ? "Let's keep moving" : "Next Driver"}
        </Button>
      </Grid>
    </Form>
  );
};

export default React.memo(DriverFields);
