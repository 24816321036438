import { createSelector } from "@reduxjs/toolkit";
import { AppState, AuthState, AuthUser } from "../types";

const _state = (state: AppState) => state.auth;
const _roles = (state: AppState) =>
  state.auth.user?.user_agency?.map((ua) => ua.role);
const _isAdmin = createSelector(
  _roles,
  (roles) =>
    roles?.includes("super_admin") ||
    roles?.includes("super_agent") ||
    roles?.includes("admin") ||
    roles?.includes("agency_admin"),
);
const _isSuperAdmin = createSelector(_roles, (roles) =>
  roles?.includes("super_admin"),
);
const _isSuperAgent = createSelector(
  _roles,
  (roles) => roles?.includes("super_agent") || roles?.includes("super_admin"),
);
const _isAgencyAdmin = createSelector(
  _roles,
  (roles) => roles?.includes("super_admin") || roles?.includes("agency_admin"),
);
const _isLoggedIn = createSelector(
  _state,
  _roles,
  (state, roles) =>
    !!state.token &&
    !(roles?.length === 1 && roles?.includes("affiliate_agent")),
);
const _isUserLoggedIn = createSelector(_state, (state) => !!state.token);

const _user = createSelector<any, AuthUser | Record<string, any>>(
  _state,
  (state) => state.user ?? {},
);
const _userId = createSelector(_state, (state: AuthState) => state.userId ?? 0);
const _userFirstName = createSelector(
  _state,
  (state) => state.user?.first_name ?? "",
);
const _userLastName = createSelector(
  _state,
  (state) => state.user?.last_name ?? "",
);

const _activeAgencyId = createSelector(_state, (state) =>
  state.activeAgencyId === 0
    ? 0
    : state.activeAgencyId ||
      (state.user?.user_agency && state.user?.user_agency[0]?.agency_id),
);
const _referralToken = createSelector(_state, (state) =>
  state.activeAgencyId
    ? state.user?.user_agency?.filter(
        (ua) => ua.agency_id === state.activeAgencyId,
      )[0]?.referral_token
    : state.user?.user_agency && state.user?.user_agency[0]?.referral_token,
);
const _affiliateToken = createSelector(
  _state,
  (state) =>
    state.user?.affiliate_agent &&
    state.user?.affiliate_agent[0]?.affiliate_token,
);
const _domain = createSelector(_state, (state) =>
  state.activeAgencyId
    ? state.user?.user_agency?.filter(
        (ua) => ua.agency_id === state.activeAgencyId,
      )[0]?.agency.domain
    : state.user?.user_agency && state.user?.user_agency[0]?.agency.domain,
);
const _activeRole = createSelector(
  _state,
  (state: AuthState) =>
    state.user?.user_agency?.find((a) => a.agency_id === state.activeAgencyId)
      ?.role ||
    (state.user?.user_agency && state.user?.user_agency[0]?.role),
);
const _user_agency = createSelector(
  _state,
  (state) => state.user?.user_agency ?? [],
);

export const authSelectors = {
  activeRole: _activeRole,
  isAdmin: _isAdmin,
  isSuperAdmin: _isSuperAdmin,
  isSuperAgent: _isSuperAgent,
  isAgencyAdmin: _isAgencyAdmin,
  isLoggedIn: _isLoggedIn,
  isUserLoggedIn: _isUserLoggedIn,
  roles: _roles,
  user: _user,
  userId: _userId,
  userFirstName: _userFirstName,
  userLastName: _userLastName,
  activeAgencyId: _activeAgencyId,
  referralToken: _referralToken,
  affiliateToken: _affiliateToken,
  domain: _domain,
  user_agency: _user_agency,
  token(state: AppState) {
    return state.auth.token;
  },
  userAgency(state: AppState) {
    return state.auth.user?.user_agency;
  },
};
