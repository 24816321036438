import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down('sm')]: {
      marginTop: 18,
    },

    maxWidth: 400,
    textAlign: "center",
  },
  paragraph: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 8,
    margin: "0 auto",
  },
}));
