import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
  authSelectors,
  useAction,
} from "../../state";

import { useStyles } from "./AgentStatsPage.styles";
import { DATE_INTERVALS } from "../../state/auto/types";
import { CancelIcon, EmailIcon, PersonIcon, PhoneIcon } from "../../components";
import { REACT_APP_ASSETS_URL } from "../../config";
import clsx from "clsx";
import { useMobile } from "../../themes";
import { formatPhone } from "../../lib";

import { AppStatsItems } from "./components/AppStatsItems";
import { AgentStatsItems } from "./components/AgentStatsItems";
import { DateSelectField } from "./components/DateSelectField";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

interface Agent {
  referral_token?: string;
  first_name: string;
  last_name: string;
  agency?: {
    name: string;
  };
  agency_groups_id?: number;
  agentValue: string | number;
  role?: string;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ width: "100%" }}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}
export const TotalItem = (props) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.totalItem}
      style={{ backgroundColor: props.secondaryColor }}
    >
      <Box
        className={classes.total}
        style={{ backgroundColor: props.primaryColor }}
      >
        {props.amount}
      </Box>{" "}
      <div className={classes.totalText} style={{ color: props.primaryColor }}>
        {props.type}
      </div>
    </Box>
  );
};
export default function AgentStatsPage() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const isMobile = useMobile();
  const getAgentStats = useAction(adminActions.getAgentStats);
  const getGroupStats = useAction(adminActions.getGroupStats);
  const { statusStats, agentData, conversionRate, totalSales } = useSelector(
    adminSelectors.agentStats,
  );

  const agencyId = useSelector(authSelectors.activeAgencyId);
  const user = useSelector(authSelectors.user);
  const activeRole = useSelector(authSelectors.activeRole);
  const _agents = useSelector(adminSelectors.agents);
  const _directors = useSelector(adminSelectors.directors);

  const [startValue, setStartValue] = useState(DATE_INTERVALS[1].value);
  const [totalApps, setTotalApps] = useState(0);
  const [amounts, setAmounts] = useState<any>();
  const [type, setType] = useState<string>("a");
  const [viewStats, setViewStats] = useState<boolean | undefined>(undefined);
  const [value, setValue] = useState<any>(0);

  const agents = _agents.map((agent) => ({
    ...agent,
    first_name: agent.user.first_name,
    last_name: agent.user.last_name,
    agentValue:
      agent.role === "affiliate_agent"
        ? agent.user.affiliate_agent[0]?.affiliate_token
        : agent.referral_token,
  }));
  const directors = _directors.reduce((acc: Agent[], curr) => {
    if (curr.role === "sales_director") {
      acc.push({ ...curr, agentValue: curr.agency_groups_id });
    }
    return acc;
  }, []);
  const people = type === "a" ? agents : directors;

  const bound = statusStats?.find(
    (s) => s.fullStatus === "Closed - Won",
  )?.statusCount;

  const handleChange = (i, newValue) => {
    setValue(newValue);
  };

  const [persons, setPersons] = useState<Agent[]>([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: null,
  };

  // is super or sales admin
  const is_s_admin =
    activeRole === "super_admin" || activeRole === "sales_admin";

  const hasAccess =
    activeRole === "sales_director" ||
    activeRole === "sales_admin" ||
    activeRole === "agency_admin" ||
    activeRole === "super_admin";
  const handleInputChange = (event: SelectChangeEvent<unknown>, child) => {
    const index = persons.findIndex(
      (p) => p.agentValue === child.props.value.agentValue,
    );
    if (index === -1) {
      setPersons(event.target.value as Agent[]);
    } else {
      const newArray = [...persons];
      newArray.splice(index, 1);
      setPersons(newArray);
    }
  };

  const handleDelete = (index) => {
    const newArray = [...persons];
    newArray.splice(index, 1);
    setPersons(newArray);
  };

  useEffect(() => {
    setValue(0);
  }, []);

  useEffect(() => {
    if (!activeRole || !agencyId) return;
    setPersons([]);
    if (hasAccess) {
      dispatch(
        adminActions.getAgents(
          activeRole,
          user.sales_director[0]?.agency_groups_id,
          agencyId,
        ),
      );
      if (is_s_admin) {
        dispatch(adminActions.getDirectors());
      }
    }
  }, [activeRole, agencyId]);

  useEffect(() => {
    if (viewStats === undefined) return;
    const agentValues = persons.map((agent) => agent.agentValue);

    if (type === "a") {
      getAgentStats(activeRole, agencyId, agentValues, startValue);
    } else {
      getGroupStats(agentValues, startValue);
    }
  }, [startValue, viewStats]);

  useEffect(() => {
    setPersons([]);
  }, [type]);

  let statusesAmount;
  useEffect(() => {
    const totalApplications = statusStats.reduce((prev, curr) => {
      return prev + curr.statusCount;
    }, 0);
    setTotalApps(totalApplications);
    statusesAmount = statusStats?.reduce(
      (acc, curr) => {
        const currCount = acc[curr.fullStatus?.split(" -")[0]] || 0;
        return {
          ...acc,
          [curr.fullStatus?.split(" -")[0]]: currCount + curr.statusCount,
        };
      },
      { ["New"]: 0, "In progress": 0, Pending: 0, Closed: 0 },
    );
    setAmounts(statusesAmount);
  }, [statusStats]);

  return (
    <>
      {hasAccess && (
        <div className={classes.statsPage}>
          <div className={classes.pageHeader}>
            <div>
              <Typography variant="h1">Agent Insights</Typography>
            </div>
          </div>
          <div className={classes.insightsWrapper}>
            {is_s_admin && (
              <div>
                <div className={classes.text}>View insights for</div>
                <ToggleButtonGroup
                  value={type}
                  exclusive
                  onChange={(e, val) => {
                    if (!val) return;
                    setType(val);
                  }}
                >
                  <ToggleButton value="a" className={classes.toggle}>
                    Agents
                  </ToggleButton>
                  <ToggleButton value="g" className={classes.toggle}>
                    Groups
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            )}
            <div className={classes.selectWrapper}>
              <div className={classes.text}>
                {type === "a"
                  ? "Select Agent(s) or Referral Partner(s)"
                  : "Select a group by director"}
              </div>
              <div className={classes.fieldsWrapper}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>
                    {type === "a" ? "Agents" : "Directors"}
                  </InputLabel>
                  <Select
                    multiple
                    sx={{
                      maxHeight: "500px",
                    }}
                    variant="outlined"
                    value={persons}
                    onChange={handleInputChange}
                    label={type === "a" ? "Agents" : "Directors"}
                    renderValue={(selected) => {
                      return (
                        <div className={classes.chips}>
                          {(selected as Agent[]).map((value: Agent, i) => (
                            <Chip
                              key={value.agentValue}
                              label={value.last_name + " " + value.first_name}
                              className={classes.chip}
                              onDelete={() => handleDelete(i)}
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          ))}
                        </div>
                      );
                    }}
                    MenuProps={MenuProps}
                  >
                    {people.map((agent) => (
                      <MenuItem key={agent.agentValue} value={agent as any}>
                        <Checkbox
                          checked={
                            !!persons.find(
                              (p) =>
                                p.agentValue ===
                                (agent.agency_groups_id || agent.agentValue),
                            )
                          }
                        />
                        <ListItemText
                          primary={`${
                            agent.last_name + " " + agent.first_name
                          }${type === "a" ? ` - ${agent.agency.name}` : ""}`}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {isMobile && type === "a" && (
                  <div>Select user(s) to view their statistics</div>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.viewBtn}
                  onClick={() => setViewStats(!viewStats)}
                  disabled={!persons.length}
                >
                  See Stats
                </Button>
              </div>
              {!isMobile && type === "a" && (
                <div>Select user(s) to view their statistics</div>
              )}
            </div>
          </div>
        </div>
      )}
      {viewStats !== undefined && (
        <Box>
          <Box className={classes.bottomWrapper}>
            {agentData?.first_name ? (
              !isMobile ? (
                <Box className={classes.agentInfo}>
                  {agentData.profile_image_path ? (
                    <div className={classes.image}>
                      <img
                        className={classes.image}
                        src={`${REACT_APP_ASSETS_URL}/${agentData.profile_image_path}`}
                      />
                    </div>
                  ) : (
                    <div className={classes.imageIcon}>
                      <PersonIcon />
                    </div>
                  )}
                  <div className={classes.agentData}>
                    <div className={classes.agentName}>
                      {agentData.first_name + " " + agentData.last_name}
                    </div>
                    <div className={classes.agentStart}>
                      Agent since{" "}
                      {new Date(agentData.created_at).toLocaleDateString()}
                    </div>
                  </div>
                  <div>
                    <div className={classes.contactWrapper}>
                      <div className={classes.contactItem}>
                        <EmailIcon />
                        <a href={`mailto:${agentData.email}`}>
                          {" "}
                          {agentData.email}
                        </a>
                      </div>
                      {agentData.phone && (
                        <div className={classes.contactItem}>
                          <PhoneIcon />{" "}
                          <a href={`tel:${agentData.phone}`}>
                            {formatPhone(agentData.phone)}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </Box>
              ) : (
                <div>
                  <Box className={classes.mobileAgentInfo}>
                    <div className={classes.mobileInfo}>
                      {agentData.profile_image_path ? (
                        <div>
                          <img
                            className={clsx("agent-image", classes.image)}
                            src={`${REACT_APP_ASSETS_URL}/${agentData.profile_image_path}`}
                          />
                        </div>
                      ) : (
                        <div className={classes.imageIcon}>
                          <PersonIcon />
                        </div>
                      )}
                      <div className={classes.agentFlex}>
                        <div className={classes.agentName}>
                          {agentData.first_name + " " + agentData.last_name}
                        </div>
                        <div className={classes.agentStart}>
                          Agent since{" "}
                          {new Date(agentData.created_at).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                    <div className={classes.contactWrapper}>
                      <div className={classes.contactItem}>
                        <a href={`mailto:${agentData.email}`}>
                          {" "}
                          {agentData.email}
                        </a>
                      </div>
                      {agentData.phone && (
                        <div className={classes.contactItem}>
                          <a href={`tel:${agentData.phone}`}>
                            {formatPhone(agentData.phone)}
                          </a>
                        </div>
                      )}
                    </div>
                  </Box>
                </div>
              )
            ) : null}
            <AppBar
              position="static"
              color="default"
              className={classes.appBar}
            >
              <Tabs
                value={value}
                aria-label="simple tabs example"
                variant={isMobile ? "fullWidth" : "standard"}
                textColor="primary"
                onChange={handleChange}
              >
                <Tab label="Applications" />
                <Tab label="Stats" />
              </Tabs>
            </AppBar>
          </Box>
          <Box>
            <TabPanel value={value} index={0}>
              <Box>
                <Box className={classes.dateWrapper}>
                  {!isMobile && (
                    <Typography variant="h6" className={classes.chartTitle}>
                      Applications statuses
                    </Typography>
                  )}
                  <Box>
                    <DateSelectField
                      startValue={startValue}
                      setStartValue={setStartValue}
                    />
                  </Box>
                </Box>

                {value === 0 && <AppStatsItems amounts={amounts} />}
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box>
                <Box className={classes.dateWrapper}>
                  <Typography className={classes.chartTitle} variant="h6">
                    Agent stats
                  </Typography>
                  <DateSelectField
                    startValue={startValue}
                    setStartValue={setStartValue}
                  />
                </Box>
                <AgentStatsItems
                  totalSales={totalSales}
                  bound={bound}
                  totalApps={totalApps}
                  conversionRate={conversionRate}
                />
              </Box>
            </TabPanel>
          </Box>
        </Box>
      )}
    </>
  );
}
