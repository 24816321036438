import React from "react";

export const CollImage = ({ fillColor }) => {
  return (
    <svg
      width="84px"
      height="84px"
      viewBox="0 0 84 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 31</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="84" height="84"></rect>
      </defs>
      <g
        id="↳-auto-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Create-a-custom-quote-side-bar"
          transform="translate(-584.000000, -1236.000000)"
        >
          <g id="Group-9" transform="translate(584.000000, 1236.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use id="Rectangle" fill="#FFFFFF" xlinkHref="#path-1"></use>
            <g id="Group-8" mask="url(#mask-2)">
              <g transform="translate(-30.778612, 31.572416)">
                <path
                  d="M46.5054528,41.5415558 C46.9130149,43.0481041 48.3089513,43.9811543 48.3089513,43.9811543 C48.3089513,43.9811543 49.0441317,42.471601 48.6365816,40.9650527 C48.2290316,39.4585044 46.8330856,38.5254542 46.8330856,38.5254542 C46.8330856,38.5254542 46.0979051,40.0350147 46.5054528,41.5415558 Z"
                  id="Path"
                  stroke="none"
                  fill="#3F3D56"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M47.1043189,41.2177893 C48.222639,42.3064301 48.3592445,43.9799224 48.3592445,43.9799224 C48.3592445,43.9799224 46.6826822,43.8883822 45.5643597,42.7997414 C44.4460371,41.7111007 44.3094341,40.0376059 44.3094341,40.0376059 C44.3094341,40.0376059 45.9859964,40.1291486 47.1043189,41.2177893 Z"
                  id="Path"
                  stroke="none"
                  fill={fillColor}
                  fillRule="nonzero"
                ></path>
                <path
                  d="M77.5417649,41.5415558 C77.949315,43.0481041 79.3452633,43.9811543 79.3452633,43.9811543 C79.3452633,43.9811543 80.0804438,42.471601 79.6728937,40.9650527 C79.2653436,39.4585044 77.8693977,38.5254542 77.8693977,38.5254542 C77.8693977,38.5254542 77.1342172,40.0350147 77.5417649,41.5415558 Z"
                  id="Path"
                  stroke="none"
                  fill="#3F3D56"
                  fillRule="nonzero"
                ></path>
                <g
                  id="Group-Copy-3"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(71.000000, 0.000000)"
                >
                  <rect
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    x="0"
                    y="35.3038448"
                    width="9.47176323"
                    height="1.72213877"
                  ></rect>
                  <path
                    d="M74.9130364,35.6879256 L73.0410211,36.1264097 L1.8956515,36.1649141 L1.25589027,34.6556753 C0.140104641,32.0668426 -0.242860429,29.2148857 0.149775496,26.4183925 C0.456188747,24.3339903 1.22401937,22.2628371 2.96797455,21.3632546 L6.12515763,0.071324129 L45.4862371,0 L60.7148912,12.4367716 C60.7148912,12.4367716 67.8984724,12.3060174 71.8011053,17.2254468 C73.0361805,18.7836419 73.9431553,20.848002 74.1865421,23.584973 L74.3405596,26.1440972 L74.9130364,35.6879256 Z"
                    id="Path"
                    fill={fillColor}
                    fillRule="nonzero"
                  ></path>
                  <circle
                    id="Oval"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    cx="15.9297836"
                    cy="34.8733101"
                    r="9.04122854"
                  ></circle>
                  <circle
                    id="Oval"
                    fill="#CCCCCC"
                    fillRule="nonzero"
                    cx="15.9297836"
                    cy="34.8733101"
                    r="4.73588161"
                  ></circle>
                  <polygon
                    id="Path"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    points="55.1084406 11.1814159 33.7190065 11.1907805 31.108011 11.1907805 26.6993572 11.193902 26.6993572 10.8705132 26.6962541 6.9711191 26.6931509 2.59257269 29.6259437 2.59257269 32.2369392 2.58945118 36.8621933 2.58632967 39.4731888 2.58632967 44.8127894 2.58320815"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    points="23.2488734 11.1876567 12.0611138 11.193902 12.0580426 8.96183528 12.0580426 5.0613393 12.0549714 2.58945344 13.6931483 2.58945344 16.2772542 2.5863308 23.2458022 2.58320815"
                  ></polygon>
                  <rect
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    x="26.6931509"
                    y="17.2213877"
                    width="1.72213877"
                    height="2.58320815"
                  ></rect>
                  <polygon
                    id="Path"
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    points="31.8595672 1.72213877 25.8354573 10.3328326 25.8320815 6.27822115 29.0191716 1.72538454"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    points="16.3603183 2.58320815 12.0549714 8.61069384 12.0549714 4.9231137 13.7234578 2.58616034"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    points="39.6091917 2.58320815 33.6861255 11.193902 30.9984978 11.193902 36.9215641 2.58320815"
                  ></polygon>
                </g>
                <path
                  d="M110.079453,43.9214851 C110.079453,43.9347601 110.059025,43.9475048 110.022196,43.9568917 C109.985367,43.9662785 109.935364,43.9715314 109.88328,43.9714851 L12.9747834,43.9714851 C12.8664411,43.9714851 12.7786123,43.9490992 12.7786123,43.9214851 C12.7786123,43.8938711 12.8664411,43.8714851 12.9747834,43.8714851 L109.88328,43.8714851 C109.935364,43.8714387 109.985368,43.8766915 110.022197,43.8860784 C110.059026,43.8954654 110.079453,43.9082101 110.079453,43.9214851 Z"
                  id="Path"
                  stroke="none"
                  fill="#3F3D56"
                  fillRule="nonzero"
                ></path>
                <g
                  id="Group-Copy"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(0.000000, 0.000000)"
                >
                  <path
                    d="M74.9130364,35.6879256 L73.0410211,36.1264097 L1.8956515,36.1649141 L1.25589027,34.6556753 C0.140104641,32.0668426 -0.242860429,29.2148857 0.149775496,26.4183925 C0.456188747,24.3339903 1.22401937,22.2628371 2.96797455,21.3632546 L6.12515763,0.071324129 L45.4862371,0 L60.7148912,12.4367716 C60.7148912,12.4367716 67.8984724,12.3060174 71.8011053,17.2254468 C73.0361805,18.7836419 73.9431553,20.848002 74.1865421,23.584973 L74.3405596,26.1440972 L74.9130364,35.6879256 Z"
                    id="Path"
                    fill="#8D98C2"
                    fillRule="nonzero"
                  ></path>
                  <circle
                    id="Oval"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    cx="58.9832528"
                    cy="34.8733101"
                    r="9.04122854"
                  ></circle>
                  <circle
                    id="Oval"
                    fill="#CCCCCC"
                    fillRule="nonzero"
                    cx="58.9832528"
                    cy="34.8733101"
                    r="4.73588161"
                  ></circle>
                  <polygon
                    id="Path"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    points="55.1084406 11.1814159 33.7190065 11.1907805 31.108011 11.1907805 26.6993572 11.193902 26.6993572 10.8705132 26.6962541 6.9711191 26.6931509 2.59257269 29.6259437 2.59257269 32.2369392 2.58945118 36.8621933 2.58632967 39.4731888 2.58632967 44.8127894 2.58320815"
                  ></polygon>
                  <polygon
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    transform="translate(50.382728, 14.159454) rotate(89.969370) translate(-50.382728, -14.159454) "
                    points="49.6591431 12.7912187 51.1063059 12.7912224 51.1063129 15.5276889 49.6591501 15.5276852"
                  ></polygon>
                  <path
                    d="M49.9420243,7.74962446 L50.1142409,7.74962446 C51.9213502,7.74962446 53.3863018,9.09892309 53.3863018,10.7633673 L53.3863018,10.7633673 C53.3863018,12.4278115 51.9213502,13.7771102 50.1142409,13.7771102 L49.9420243,13.7771102 L49.9420243,7.74962446 Z"
                    id="Path"
                    fill="#3F3D56"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M74.9130364,25.8052723 C72.6601713,26.0435631 70.5574574,24.6716547 69.9296893,22.5539047 C69.3019212,20.4361547 70.3309735,18.1861092 72.3685016,17.2213877 C73.6060478,18.7210952 74.5148371,20.7079686 74.7587108,23.342206 L74.9130364,25.8052723 Z"
                    id="Path"
                    fill="#3F3D56"
                    fillRule="nonzero"
                  ></path>
                  <polygon
                    id="Path"
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    points="31.8595672 1.72213877 25.8354573 10.3328326 25.8320815 6.27822115 29.0191716 1.72538454"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    points="39.6091917 2.58320815 33.6861255 11.193902 30.9984978 11.193902 36.9215641 2.58320815"
                  ></polygon>
                </g>
                <path
                  d="M79.7786123,26.4275837 C79.7786123,26.4275837 78.7786123,27.4275837 76.7786123,29.4275837 L77.7786123,30.4275837 L76.7786123,31.4275837"
                  id="Path-2"
                  stroke="#FFFFFF"
                  strokeWidth="1"
                  fill="none"
                  transform="translate(78.278612, 28.927584) scale(-1, 1) rotate(60.000000) translate(-78.278612, -28.927584) "
                ></path>
                <path
                  d="M80.0955996,20.244571 C80.0955996,20.244571 79.701262,20.5615583 78.9125869,21.1955329 L79.7786123,21.6955329 L76.0465615,24.1596345"
                  id="Path-2-Copy-3"
                  stroke="#FFFFFF"
                  strokeWidth="1"
                  fill="none"
                  transform="translate(78.071081, 22.202103) scale(-1, 1) rotate(60.000000) translate(-78.071081, -22.202103) "
                ></path>
                <path
                  d="M77.7786123,13.4275837 C77.7786123,13.4275837 76.7786123,14.4275837 74.7786123,16.4275837 L75.7786123,17.4275837 L74.7786123,18.4275837"
                  id="Path-2-Copy"
                  stroke="#FFFFFF"
                  strokeWidth="1"
                  fill="none"
                  transform="translate(76.278612, 15.927584) scale(-1, -1) translate(-76.278612, -15.927584) "
                ></path>
                <path
                  d="M80.7786123,36.4275837 C80.7786123,36.4275837 79.7786123,36.0942504 77.7786123,35.4275837 L77.7786123,37.4275837 L74.7786123,36.4275837"
                  id="Path-2-Copy-2"
                  stroke="#FFFFFF"
                  strokeWidth="1"
                  fill="none"
                  transform="translate(77.778612, 36.427584) scale(-1, -1) rotate(21.000000) translate(-77.778612, -36.427584) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
