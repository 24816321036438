import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Typography,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useFormik, yup } from "../../lib";

import { useStyles } from "./AddUserAgencyPage.styles";
import { useParams } from "react-router";

const initialValues = {
  id: -1,
  user_id: -1,
  agency_id: -1,
  role: "",
};
const validationSchema = yup.object({});

const EditUserAgencyPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const [agenciesLoading, setAgenciesLoading] = useState(true);
  const [availAgencies, setAvailAgencies] = useState<any>([]);
  const [activeUserAgency, setActiveUserAgency] = useState(initialValues);

  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const users = useSelector(adminSelectors.users);
  const agencies = useSelector(adminSelectors.agencies);
  const user_agencies = useSelector(adminSelectors.user_agencies);
  const roles = [
    "agent",
    "super_agent",
    "agency_admin",
    "affiliate_agent",
    // "sales_director",
  ];

  const form = useFormik({
    enableReinitialize: true,
    initialValues: activeUserAgency,
    validationSchema,
    async onSubmit(values) {
      await dispatch(adminActions.updateUserAgency(params.id, values));
    },
  });

  useEffect(() => {
    if (!activeRole || !agencyId) {
      console.warn("active role and active agency id is not defined");
      return;
    }
    dispatch(adminActions.getUsers(activeRole, agencyId));
    dispatch(adminActions.getAgencies());
    dispatch(adminActions.getUserAgency());
  }, [dispatch, activeRole, agencyId]);

  useEffect(() => {
    if (form.values.user_id) {
      const existingUserAgenciesForUser = user_agencies
        .filter(
          (user_agency) => user_agency.user_id === Number(form.values.user_id),
        )
        .map((a) => a.agency_id);
      const goodAgencies = agencies.filter(
        (agency) =>
          !existingUserAgenciesForUser.includes(agency.id) ||
          agency.id === form.values.agency_id,
      );
      setAvailAgencies(goodAgencies);
      setAgenciesLoading(false);
    }
  }, [form.values.user_id, form.values.agency_id]);

  useEffect(() => {
    if (!user_agencies.length) {
      dispatch(adminActions.getUserAgency());
    } else {
      const matchingUA = user_agencies.find(
        (r) => r.id === parseInt(params.id),
      );
      if (matchingUA) {
        setActiveUserAgency({
          id: matchingUA.id,
          user_id: matchingUA.user_id,
          agency_id: matchingUA.agency_id,
          role: matchingUA.role,
        });
      }
    }
  }, [dispatch, agencies, params]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item xs={4} className={classes.formControl}>
              <TextField
                select
                id="user_id"
                name="user_id"
                label="User"
                variant="outlined"
                value={form.values.user_id}
                onChange={form.handleChange}
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {user.first_name} {user.last_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={4} className={classes.formControl}>
              <TextField
                select
                id="agency_id"
                name="agency_id"
                label="Agency"
                variant="outlined"
                value={form.values.agency_id}
                disabled={
                  agenciesLoading ||
                  !form.values.user_id ||
                  !availAgencies.length
                }
                onChange={form.handleChange}
              >
                {availAgencies.map((agency) => (
                  <MenuItem key={agency.id} value={agency.id}>
                    {agency.name}
                  </MenuItem>
                ))}
              </TextField>
              {form.values.user_id && !availAgencies.length && (
                <Typography className={classes.note}>
                  No available agencies for selected user.
                </Typography>
              )}
            </Grid>

            <Grid item xs={4} className={classes.formControl}>
              <TextField
                select
                id="role"
                name="role"
                label="Role"
                variant="outlined"
                value={form.values.role}
                onChange={form.handleChange}
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  !form.values.user_id ||
                  !form.values.agency_id ||
                  !form.values.role
                }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default EditUserAgencyPage;
