import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "./CoverageSection_Bundle.styles";
import { CoverageCard } from "./CoverageCard";
import { ExpandLessIcon } from "../../../components";
import { useMobile } from "../../../themes";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useSelector } from "react-redux";
import { adminSelectors } from "../../../state";

interface CoverageSectionProps {
  quotes: Array<any>;
  level: number;
  selectedQuotes: Array<any>; //may or may not be in this coverage section
  purchasedQuotes: Array<any>; //may or may not be in this coverage section
}

export const CoverageSection_Bundle = ({
  quotes,
  level,
  selectedQuotes,
  purchasedQuotes,
}: CoverageSectionProps) => {
  const classes = useStyles({ level });
  const isMobile = useMobile();
  const { quoteTypes } = useSelector(adminSelectors.activeQuote);
  const bundleBindData = useSelector(adminSelectors.bundleBindData);
  const isAutoRenters = quoteTypes.includes("AutoRenters");

  const [expanded, setExpanded] = useState(false);
  const [toggleValue, setToggleValue] = useState("auto");
  const otherQuote = isAutoRenters ? "renters" : "home";
  useEffect(() => {
    if (purchasedQuotes.length) {
      setExpanded(Boolean(quotes.find((q) => q.full_premium_override)));
    } else {
      if (selectedQuotes?.length) {
        const responses = selectedQuotes.map((selectedQuote) => {
          return quotes.find(
            (q) => q.req_txn_id === selectedQuote[0]?.req_txn_id,
          );
        });
        setExpanded(Boolean(responses.find((response) => !!response)));
      } else {
        setExpanded(true);
      }
    }
  }, [selectedQuotes, quotes, purchasedQuotes]);

  useEffect(() => {
    if (!isMobile) return;
    if (bundleBindData.isLinked) {
      if (bundleBindData.singleQuoteType !== "auto") {
        setToggleValue("auto");
      } else {
        setToggleValue(otherQuote);
      }
    }
  }, [bundleBindData]);

  const handleChange = (event, newValue) => {
    if (newValue) setToggleValue(newValue);
  };

  const coverageLevelTitle =
    level === 1
      ? "Low Coverages"
      : level === 2
      ? "Medium Coverages"
      : level === 3
      ? "High Coverages"
      : "Custom Coverages";

  const Quotes = (quote_type) =>
    quotes.filter((quote) => quote.quote_type === quote_type);

  return (
    <Box className={classes.coverageWrapper}>
      {isMobile && (
        <>
          <ToggleButtonGroup
            className={classes.switcherGroup}
            exclusive
            size="small"
            value={toggleValue}
            onChange={handleChange}
          >
            <ToggleButton
              value={"auto"}
              style={{
                borderRadius: "3px 0 0 0",
              }}
            >
              Auto
            </ToggleButton>
            <ToggleButton
              value={isAutoRenters ? "renters" : "home"}
              style={{
                borderRadius: "0 3px 0 0",
              }}
            >
              {isAutoRenters ? "Renters" : "Home"}
            </ToggleButton>
          </ToggleButtonGroup>
          <hr className={classes.tabDivider} />
        </>
      )}
      <Accordion onChange={() => setExpanded(!expanded)} expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandLessIcon />}>
          <Box className={classes.headingTxtWrapper}>
            <Typography>{coverageLevelTitle}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.cardsWrapper}>
            {!isMobile ? (
              <>
                <Box className={classes.cardWrapper}>
                  <Typography className={classes.cardWrapperTxt}>
                    Auto
                  </Typography>
                  {Quotes("auto").map((quote, index) => (
                    <CoverageCard
                      key={index}
                      quote={quote}
                      isLast={index === Quotes("auto").length - 1}
                      hasPurchased={purchasedQuotes}
                    />
                  ))}
                </Box>
                <hr className={classes.divider} />
                {!isAutoRenters ? (
                  <Box className={classes.cardWrapper}>
                    <Typography className={classes.cardWrapperTxt}>
                      Home
                    </Typography>
                    {Quotes("home").map((quote, index) => (
                      <CoverageCard
                        key={index}
                        quote={quote}
                        isLast={index === Quotes("home").length - 1}
                        hasPurchased={purchasedQuotes}
                      />
                    ))}
                  </Box>
                ) : (
                  <Box className={classes.cardWrapper}>
                    <Typography className={classes.cardWrapperTxt}>
                      Renters
                    </Typography>
                    {Quotes("renters").map((quote, index) => (
                      <CoverageCard
                        key={index}
                        quote={quote}
                        isLast={index === Quotes("renters").length - 1}
                        hasPurchased={purchasedQuotes}
                      />
                    ))}
                  </Box>
                )}
              </>
            ) : (
              <>
                {Quotes(toggleValue).map((quote, index) => (
                  <CoverageCard
                    key={index}
                    quote={quote}
                    isLast={index === Quotes(toggleValue).length - 1}
                    hasPurchased={purchasedQuotes}
                  />
                ))}
              </>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
