import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  backBtn: {
    "& .MuiSvgIcon-root": {
      fontSize: 32,
    },
    [theme.breakpoints.down('sm')]: {
      "& .MuiSvgIcon-root": {
        fontSize: 24,
      },
    },
  },
  backIcon: {
    color: theme.palette.secondary.main,
    fontSize: 32,
    position: "fixed",
    left: 320,
    top: 80,
    zIndex: 1,
    cursor: "pointer",
    transition: "opacity 300ms linear",
    "&:hover": {
      opacity: 0.5,
    },
    [theme.breakpoints.down('md')]: {
      left: 184,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      left: 16,
      top: 64,
    },
  },
}));
