import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, MenuItem, TextField } from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import { AddIcon } from "../../components/icons";

import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
  useAction,
} from "../../state";
import AdminPages from ".";
import { COVERAGE_COLUMNS as columns } from "../../constants/tables";

import { useStyles } from "./CoveragePage.style";
import { history } from "../../lib";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function CoveragePage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(50);
  const coverage = useSelector(adminSelectors.coverage);
  const agencyGroups = useSelector(adminSelectors.agency_groups);
  const selectedAgencyGrp = useSelector(adminSelectors.selectedAgencyGrp);
  const setSelectedAgencyGrp = useAction(adminActions.setSelectedAgencyGrp);

  const handleAgencyGrpFilter = (e) => {
    setSelectedAgencyGrp(e.target.value);
  };

  useEffect(() => {
    dispatch(adminActions.getAgencyGroups());
  }, []);

  useEffect(() => {
    dispatch(adminActions.getCoverage(selectedAgencyGrp));
    dispatch(adminActions.setPageTitle("Coverage"));
  }, [dispatch, selectedAgencyGrp]);

  return (
    <div className={classes.page}>
      <div className={classes.topArea}>
        <TextField
          className={classes.agencyField}
          select
          value={selectedAgencyGrp}
          label="Agency Group"
          onChange={handleAgencyGrpFilter}
          variant="outlined"
        >
          {agencyGroups.map(({ id, name }, idx) => (
            <MenuItem key={idx} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <Link className={classes.link} to={AdminPages.createCoverage.path}>
          <Button variant="outlined" color="primary">
            <AddIcon /> Add Coverage
          </Button>
        </Link>
      </div>

      <DataGridPro
        autoHeight
        onRowClick={(e) =>
          history.push(AdminPages.editCoverage.path.replace(":id", "" + e.id))
        }
        rows={coverage}
        columns={columns}
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
