import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Tabs, Tab } from "@mui/material";
import { DataGridPro, GridCellEditCommitParams } from "@mui/x-data-grid-pro";

import { AddIcon } from "../../../../components/icons";

import AdminPages from "../../index";
import {
  FAQS_COLUMNS as faqsColumns,
  FAQ_CATEGORIES_COLUMNS as faqCategoriesColumns,
} from "../../../../constants/tables";
import useFAQs from "../../../../hooks/admin/useFAQs";
import { useAction, adminActions } from "../../../../state";

import { useStyles } from "./FAQsPage.styles";

const FAQsPage = () => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const {
    faqs = [],
    faqCategories = [],
    updateFAQ,
    updateFAQCategory,
  } = useFAQs();

  const setPageTitle = useAction(adminActions.setPageTitle);

  useEffect(() => {
    setPageTitle("FAQs");
  }, [setPageTitle]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  const handleFAQCategoryCellChange = useCallback(
    (params: GridCellEditCommitParams) => {
      updateFAQCategory(params);
    },
    [updateFAQCategory],
  );

  const handleFAQCellChange = useCallback(
    (params: GridCellEditCommitParams) => {
      updateFAQ(params);
    },
    [updateFAQ],
  );

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="FAQ Categories" id="faq-categories" />
        <Tab label="FAQs" id="faqs" />
      </Tabs>
      <div hidden={value !== 0} id="faq-categories">
        <div className={classes.topArea}>
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={AdminPages.createFAQCategory.path}
            className={classes.link}
          >
            <AddIcon /> Add FAQ category
          </Button>
        </div>

        <div className={classes.page}>
          <DataGridPro
            autoHeight
            rows={faqCategories}
            columns={faqCategoriesColumns}
            pageSize={20}
            disableSelectionOnClick
            onCellEditCommit={handleFAQCategoryCellChange}
          />
        </div>
      </div>
      <div hidden={value !== 1} id="faqs">
        <div className={classes.topArea}>
          <Button
            variant="outlined"
            color="primary"
            component={Link}
            to={AdminPages.createFAQ.path}
            className={classes.link}
          >
            <AddIcon /> Add FAQ
          </Button>
        </div>

        <div style={{ width: "100%" }}>
          <DataGridPro
            autoHeight
            rows={faqs}
            columns={faqsColumns}
            pageSize={25}
            disableSelectionOnClick
            onCellEditCommit={handleFAQCellChange}
          />
        </div>
      </div>
    </>
  );
};

export default FAQsPage;
