import {
  StartWizardShape,
  VehiclesWizardShape,
  VehicleInitialValuesShape,
} from "../state/auto/types";

export const StartWizardInitialValues: StartWizardShape = {
  first_name: "",
  middle_name: "",
  last_name: "",
  address: {
    street_line: "",
    state: "",
    city: "",
    zipcode: "",
  },
  apt: "",
  skipLookup: false,
};

export const VehiclesWizardInitialValues: VehiclesWizardShape = {
  selectedVehicles: [],
  notListedVehicles: false,
};

export const VehicleInitialValues: VehicleInitialValuesShape = {
  VIN: "",
  ModelYear: "",
  Maker: "",
  Model: "",
  Type: "",
  miles: 8750, //preselect 10k miles
  usage: "",
  moreVehicles: undefined,
};
