import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 24,
  },
  item: {
    marginBottom: 16,
    padding: "12px 24px 12px 24px",
    borderRadius: 3,
    boxShadow: `0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC`,
    cursor: "pointer",

    "&:hover": {
      boxShadow: `0 2px 40px 2px ${theme.palette.secondary.contrastText}, 0 2px 24px 0 #DADFF6, 0 2px 40px 0 #DADFF6`,
    },
  },
  checkboxWrap: {
    alignSelf: "flex-start",
  },
  content: {
    paddingTop: 9,
    paddingBottom: 9,
    fontWeight: 500,
    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  additionalDriverWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  additionalDriverTitle: {
    display: "flex",
    width: "100%",
  },
  additionalDriverFieldsWrapper: {
    margin: "12px 0px",
    display: "flex",
  },
  deleteIcon: { marginLeft: "auto" },
  textFieldLrg: {
    maxWidth: "43%",
  },
  textFieldSmll: {
    maxWidth: "14%",
    margin: "0px 12px",
  },
  field: {
    paddingTop: 28,
    paddingBottom: 28,

    "& .MuiOutlinedInput-input": {
      padding: "0 14px",
    },

    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(14px, 16px) scale(1)",
    },
  },
  fieldTitle: {
    marginBottom: 24,
    fontWeight: 600,
  },
}));
