import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  // container
  wrapper: {
    maxWidth: 1200,
    margin: "0 auto",
    [theme.breakpoints.up("xl")]: {},
  },
  // bundle
  bundleActionsContainer: {
    marginTop: -16,
    marginBottom: 24,
  },
  mobileBundleActionsContainer: {
    position: "fixed",
    zIndex: 2,
    top: 144,
    left: 0,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
    paddingTop: 16,
  },
  switcherGroup: {
    width: 272,
    height: 32,
    overflow: "hidden",
    zIndex: 1,
    position: "relative",
  },
  switcher: {
    width: "50%",
    overflow: "hidden",
    "&.MuiToggleButton-root": {
      textTransform: "initial",
      color: theme.palette.secondary.main,
    },
    "&.MuiToggleButton-root.Mui-selected, &.MuiToggleButton-root.Mui-selected:hover":
      {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: "#fff",
      },
    "& $backgroundIcon": {
      display: "none",
    },
    "&.Mui-selected": {
      "& $backgroundIcon": {
        display: "block",
      },
    },
  },
  backgroundIcon: {
    color: "#ccd2e9",
    fontSize: 58,
    position: "absolute",
    left: -16,
    opacity: 0.3,
    top: -13,
  },
  // carousel
  carouselContainer: {
    width: "100%",
    marginTop: -40,
    [theme.breakpoints.up(1536)]: {
      justifyContent: "space-evenly",
    },
    "&.marginTop": {
      marginTop: 64,
    },
    "& .react-multi-carousel-item": {
      marginTop: 40,
      marginBottom: 40,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        marginTop: 80,
      },
    },
    [theme.breakpoints.down("md")]: {
      "& .react-multiple-carousel__arrow--right": {
        right: 0,
      },
      "& .react-multiple-carousel__arrow--left": {
        left: 0,
      },
    },
  },
  carouselButtonGroup: {
    width: "100%",
    position: "fixed",
    background: "#fff",
    top: 124,
    paddingTop: 12,
    zIndex: 1,
    left: 0,
    right: 0,
    height: 60,
    borderBottom: "1px solid #EFF1FB",
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& .MuiTabs-root": {
      width: "100%",
    },
    "&.bundleMode": {
      top: 200,
    },
  },
  // no quotes container
  noQuotesContainer: {
    height: "calc(100vh - 280px)",
    background: "#F9FAFC",
    maxHeight: 496,
    marginBottom: 8,
    padding: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& p:first-child": {
      fontSize: 25,
      maxWidth: 640,
      textAlign: "center",
      marginBottom: 24,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 24,
      marginTop: 96,
      "& p": {
        fontSize: 22,
      },
    },
  },
  // quote props
  root: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      "& .MuiList-root": {
        height: "auto",
      },
    },
  },
  shadows: {
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 1,
    height: 48,
    // display: (props: any) => (props.isQuoteSelected ? "none" : "block"),
  },
  topShadow: {
    top: 0,
    background: "linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0) 100%)",
  },
  bottomShadow: {
    bottom: 0,
    background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)",
  },
  props: {
    paddingBottom: 0,
    height: "auto",
    margin: "0 -40px",
    padding: "24px 40px 0",
    borderTop: "1px solid #EFF1FB",
    [theme.breakpoints.down("sm")]: {
      "& .MuiList-root": {
        height: 600,
      },
    },
  },
  propsSubTitle: {
    textAlign: "center",
  },
  moreProps: {
    borderTop: "1px solid #EFF1FB",
    transition: "max-height 200ms linear",
    "&.closed": {
      visibility: "hidden",
      paddingTop: 0,
      marginTop: 0,
    },
    "&:open": {
      paddingTop: 11.5,
      marginTop: 11.5,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    zIndex: theme.zIndex.modal + 100,
    overflow: "auto",
    width: 780,
    maxHeight: "90vh",
    padding: "30px 40px 40px",
    borderRadius: 3,
    boxShadow: "0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)",
    backgroundColor: "#FFF",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      maxHeight: "100%",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.modal,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    opacity: "0.25 !important",
  },
  quotesActionBtns: {
    [theme.breakpoints.down("sm")]: {
      marginTop: -32,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingBottom: 32,
    },
  },
  showMoreBtn: {
    boxShadow: "none",
    backgroundColor: "#eff1fb",
    height: 40,
    margin: "16px 16px 32px 16px",
    display: "flex",
    alignItems: "center",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px",
    },
  },
  bundleCheckoutBypass: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    cursor: "pointer",
    transition: "opacity 300ms linear",
    "&:hover": {
      opacity: 0.5,
    },
  },
  // mobile bundle upsell styles
  bundleBoxWrapper: {
    position: "fixed",
    left: 0,
    bottom: 0,
    "& .bundleBoxCollapse": {
      height: 28,
      width: 28,
      borderRadius: 100,
      background: "#cdd2e9",
      boxShadow: "0 2px 5px 0 #DADFF6",
      position: "absolute",
      bottom: 16,
      left: 16,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      "&.open": {
        top: -16,
        bottom: "unset",
      },
    },
  },
  bundleBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      margin: 0,
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        fontSize: 14,
        fontWeight: 500,
      },
    },
    "& button": {
      marginTop: 24,
      height: 32,
      padding: "0 24px",
      // backgroundColor: "#d5d5d5",
      [theme.breakpoints.down("sm")]: {
        minWidth: 142,
        padding: 0,
        marginTop: 16,
      },
    },
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      boxShadow: "0 2px 24px 0 #dadff6, 0 2px 40px 0 #eff1fb",
      width: "100vw",
      padding: "24px 16px 32px 16px",
    },
  },
  // bundle quotes failed styles
  bundleQuotesFailedTitle: {
    fontSize: 32,
    width: 576,
    margin: "0 auto",
    textAlign: "center",
    marginBottom: 40,
  },
  bundleQuotesFailedContact: {
    display: "flex",
    justifyContent: "center",
  },
  alert: {
    width: 800,
    maxWidth: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },
}));
