import React, { useState, useRef } from "react";
import { TextField } from "@mui/material";
import {
  adminActions,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { Autocomplete } from "@mui/material";
import { useStyles } from "./AdminSearchBox.styles";
import { history } from "../../lib";

const AdminSearchBox = () => {
  const classes = useStyles();
  const inputRef: any = useRef();
  const dispatch = useDispatch();
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const [value, setValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchBoxChange = async (e, val, reason) => {
    if (e && reason !== "reset") {
      setValue(val);
    } else {
      setValue("");
      inputRef?.current?.blur();
      return;
    }

    if (!activeRole || !agencyId) {
      console.warn("active role and active agency id is not defined");
      return;
    }
    if (e?.target.value && val) {
      const results: any = await dispatch(
        adminActions.searchQuotes(e.target.value, activeRole, agencyId),
      );
      setSearchResults(results);
    } else {
      setValue("");
      setSearchResults([]);
    }
  };

  const onApplicationSelected = (e, val) => {
    setValue("");
    if (!val) {
      return;
    }
    history.push("/admin/quote-management/" + val.req_uid);
  };

  const isOptionEqualToValue = (option, value) => {
    return option.req_uid === value.req_uid;
  };

  return (
    <Autocomplete
      autoSelect={false}
      filterOptions={(x) => x}
      onChange={onApplicationSelected}
      className={classes.root}
      options={searchResults?.length ? searchResults : []}
      onInputChange={handleSearchBoxChange}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.key}>
            {`${option.first_name} ${option.last_name} - ${option.email} - ${
              option.phone ?? ""
            } ${option.agency?.domain ? `- ${option.agency?.domain}` : ""}`}
          </li>
        );
      }}
      onBlur={() => setSearchResults([])}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputLabelProps={{
              className: classes.textFieldLabel,
            }}
            inputRef={inputRef}
            label="Search quote by name, email, or phone"
            size="small"
          />
        );
      }}
      inputValue={value}
    />
  );
};

export default AdminSearchBox;
