import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  autoActions,
  autoSelectors,
  homeActions,
  homeSelectors,
  useAction,
  useSelector,
  wizardActions,
  wizardSelectors,
  wizardNoPersistSelectors,
  useDispatch,
  authSelectors,
  lifeActions,
  lifeSelectors,
} from "../../../state";
import { Form, Navigation, useFormik, yup } from "../../../lib";
import { BlockIcon } from "../../../components";
import AutoCustomQuoteForm from "../../wizard/auto/custom/CustomQuoteForm";
import HomeCustomQuoteForm from "../../wizard/home/custom/CustomQuoteForm";
import { HomeIcon, DirectionsCar } from "../../../components/icons";
import { phoneRegExp } from "../../../utils";
import { useMobile } from "../../../themes";
import { useStyles } from "./Contact.styles";

const ContactForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const [redirect, setRedirect] = useState("");
  const setContactInfo = useAction(wizardActions.setContactInfo);
  const setQuoteWasHydrated = useAction(wizardActions.setQuoteWasHydrated);
  const contact = useSelector(wizardSelectors.contactInfo);
  const mode = useSelector(wizardNoPersistSelectors.mode);

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  const quotes =
    mode === "life"
      ? useSelector(lifeSelectors.quotes) || []
      : mode === "home" || mode === "renters"
      ? useSelector(homeSelectors.quotes) || []
      : useSelector(autoSelectors.quotes) || [];
  const [customQuoteModal, setCustomQuoteModal] = useState<boolean>(false);
  const viewRef = useRef<HTMLDivElement>(null);
  const submitQuoteAuto = useAction(autoActions.setQuotesSubmit);
  const submitQuoteHome = useAction(homeActions.submitQuote);
  const submitQuoteLife = useAction(lifeActions.submitQuote);

  const clearQuoteData = () => {
    dispatch(wizardActions.clearQuotes());
    dispatch(autoActions.clearAutoQuotes());
    dispatch(homeActions.clearHomeQuotes());
    dispatch(lifeActions.clearLifeQuotes());
    dispatch(autoActions.clearSelectedAutoQuote());
    dispatch(homeActions.clearSelectedHomeQuote());
    dispatch(lifeActions.clearSelectedLifeQuote());
    setQuoteWasHydrated(false);
  };

  const submitQuote = async () => {
    clearQuoteData();
    let id;
    if (mode === "auto-renters") {
      id = await submitQuoteAuto(true);
      Navigation.go("/auto-renters/wizard/quotes");
      if (id) {
        submitQuoteHome(true, false);
        submitQuoteAuto(false, id);
        submitQuoteHome(false, false);
      }
    }
    if (mode === "auto") {
      id = await submitQuoteAuto(false);
      if (id) setRedirect("/auto/wizard/quotes");
    }
    if (mode === "life") {
      return submitQuoteLife(false, isLoggedIn);
    }
    if (mode === "home") {
      id = await submitQuoteHome(false);
      if (id) setRedirect("/home/wizard/quotes");
    }
    if (mode === "renters") {
      id = await submitQuoteHome(false, false, true);
      if (id) setRedirect("/renters/wizard/quotes");
    }
    if (mode === "bundle") {
      id = await submitQuoteAuto(true);
      Navigation.go("/bundle/wizard/quotes");
      if (id) {
        submitQuoteHome(true, false);
        submitQuoteAuto(false, id);
        submitQuoteHome(false, false);
      }
    }
    return id;
  };

  // Tabs code
  const [value, setValue] = useState(0);

  const setFormValue = () => {
    if (value === 0) {
      setValue(1);
      viewRef.current?.scrollIntoView();
    } else {
      setValue(0);
      viewRef.current?.scrollIntoView();
    }
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  //wasn't working to check quotes length in submitQuote so moved to useEffect with 'redirect' - RP
  useEffect(() => {
    if (redirect && quotes.length >= 2) {
      Navigation.go(redirect);
    }
  }, [quotes, redirect]);

  const initialValues: any = {
    email: contact?.email || "",
    phone: contact?.phone || "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email address is required"),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required"),
  });

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values) {
      setContactInfo(values);
      if (isLoggedIn && mode !== "life") {
        setCustomQuoteModal(true);
        return;
      }
      submitQuote();
    },
  });

  return (
    <Grid>
      <Form form={form}>
        <Grid container spacing={2} className={classes.fields}>
          <Grid item xs={12}>
            <TextField
              type="email"
              id="email"
              name="email"
              label="Email"
              value={form.values.email}
              onChange={form.handleChange}
              error={form.touched.email && Boolean(form.errors.email)}
              helperText={form.touched.email && form.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="phone"
              name="phone"
              label="Phone number"
              value={form.values.phone}
              onChange={form.handleChange}
              error={form.touched.phone && Boolean(form.errors.phone)}
              helperText={form.touched.phone && form.errors.phone}
            />
          </Grid>
          <Grid className={classes.featureWrapper} item md={12} xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="baseline"
              spacing={isMobile ? 0 : 6}
            >
              <Grid item md={6} xs={6}>
                <Typography className={classes.featureNoSpam}>
                  <BlockIcon color="primary" />
                  Absolutely no spam
                </Typography>
              </Grid>
              <Grid item md={6} xs={6}>
                <Typography className={classes.feature}>
                  <BlockIcon color="primary" />
                  We never sell your info
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" alignItems="center">
          {!isLoggedIn || mode === "life" ? (
            <Button
              fullWidth={Boolean(isMobile)}
              id="submit"
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !(form.values.email && form.values.phone) || !form.isValid
              }
            >
              See my quotes
            </Button>
          ) : (
            <Button
              fullWidth={Boolean(isMobile)}
              id="submit"
              type="submit"
              variant="contained"
              color="primary"
              disabled={
                !(form.values.email && form.values.phone) || !form.isValid
              }
            >
              Set coverages
            </Button>
          )}
        </Grid>
      </Form>

      <Modal
        className={classes.modal}
        open={customQuoteModal}
        onClose={() => {
          setCustomQuoteModal(false);
          setValue(0);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ className: classes.backdrop_custom }}
      >
        <Fade in={customQuoteModal}>
          <Paper square variant="outlined" className={classes.paper}>
            {mode === "auto" ? (
              <AutoCustomQuoteForm
                handleCloseModal={() => setCustomQuoteModal(false)}
                bundled={false}
                setFormValue={null}
              />
            ) : mode === "home" || mode === "renters" ? (
              <HomeCustomQuoteForm
                handleCloseModal={() => setCustomQuoteModal(false)}
                bundled={false}
                setFormValue={null}
              />
            ) : mode === "bundle" || mode === "auto-renters" ? (
              <>
                <div ref={viewRef}></div>
                <AppBar
                  position="static"
                  color="default"
                  className={classes.appBar}
                >
                  <Tabs
                    value={value}
                    aria-label="simple tabs example"
                    variant="fullWidth"
                    textColor="primary"
                  >
                    <Tab
                      label={`${
                        mode === "bundle" ? "Home" : "Renters"
                      }  coverages`}
                      icon={<HomeIcon />}
                      disabled={value === 1}
                    />
                    <Tab
                      label="Auto coverages"
                      icon={<DirectionsCar />}
                      disabled={value === 0}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <HomeCustomQuoteForm
                    handleCloseModal={() => {
                      setCustomQuoteModal(false);
                    }}
                    bundled={false}
                    setFormValue={setFormValue}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <AutoCustomQuoteForm
                    handleCloseModal={() => setCustomQuoteModal(false)}
                    bundled={false}
                    setFormValue={setFormValue}
                  />
                </TabPanel>
              </>
            ) : null}
          </Paper>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default React.memo(ContactForm);
