import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { history } from "../../../lib";
import { useAction, wizardActions } from "../../../state";

export const HydrateQuote = (props) => {
  const dispatch = useDispatch();
  const { id } = props.match.params;
  const windowURL = new URL(window.location.href);
  const params = windowURL.searchParams;
  const query = windowURL.search.includes("utm_source") ? windowURL.search : "";
  const carouselMode = params.get("bundle_mode");

  const selected_quote = params.get("selected_quote");
  const setQuoteWasHydrated = useAction(wizardActions.setQuoteWasHydrated);
  const setCarouselMode = useAction(wizardActions.setCarouselMode);
  const mode = props.location.pathname.includes("home")
    ? "home"
    : props.location.pathname.includes("auto-renters")
    ? "auto-renters"
    : props.location.pathname.includes("renters")
    ? "renters"
    : props.location.pathname.includes("life")
    ? "life"
    : props.location.pathname.includes("bundle")
    ? "bundle"
    : "auto";

  useEffect(() => {
    (async () => {
      let goToContact;
      await dispatch(
        wizardActions.getWizardInfo(id, query, (agentQuoted) => {
          if (agentQuoted) {
            goToContact = true;
          }
        }),
      );
      if (goToContact && mode !== "life") {
        history.push(`/${mode}/wizard/contact`);
        return;
      }
      setQuoteWasHydrated(true);
      if (carouselMode) setCarouselMode(carouselMode);

      history.push(`/${mode}/wizard/quotes`);
    })();
  }, [dispatch, id, selected_quote, setQuoteWasHydrated]);
  return <Typography>Loading quote</Typography>;
};
