import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useStyles } from "./NotesSection.styles";
import { AddIcon, EventIcon, PersonIcon } from "../../../components/icons";
import {
  adminActions,
  authSelectors,
  useAction,
  useSelector,
} from "../../../state";
import { useMobile } from "../../../themes";
export const NotesSection = ({
  quoteId,
  comments,
  showNoteInput = false,
  setShowNoteInput = (v) => setShowNoteInput(v),
}) => {
  const classes = useStyles();
  const isMobile = useMobile();
  const activeAgencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const postComment = useAction(adminActions.postQuoteComment);
  const [showInput, setShowInput] = useState(false);
  const [comment, setComment] = useState("");
  const orderedComments = comments?.toReversed();
  const handlePostComment = () => {
    postComment(quoteId, activeRole, activeAgencyId, comment);
    setShowInput(false);
    setComment("");
    if (isMobile) {
      setShowNoteInput(false);
    }
  };
  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.addWrapper}>
          {!isMobile && (
            <Box className={classes.addNote}>
              <Typography className={classes.header}>Notes</Typography>
              <div onClick={() => setShowInput(true)}>
                <AddIcon />
              </div>
            </Box>
          )}
          {!comments?.length && !showInput && !showNoteInput && (
            <Typography>There are no notes yet.</Typography>
          )}
          {(showInput || showNoteInput) && (
            <Box>
              <TextField
                variant="outlined"
                id="standard-textarea"
                label="Add a note"
                multiline
                minRows={4}
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              />
              <Box className={classes.submit}>
                <Button
                  variant="contained"
                  className={classes.cancelBtn}
                  onClick={() => {
                    setShowInput(false);
                    if (isMobile) {
                      setShowNoteInput(false);
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.submitBtn}
                  onClick={handlePostComment}
                  disabled={!comment.length}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
        </Box>
        <Grid className={classes.notesContainer}>
          {!!comments?.length &&
            orderedComments?.map((c) => (
              <Box key={c.id} className={classes.notesWrapper}>
                <Box>
                  <Typography>{c.comment}</Typography>
                </Box>
                <Box className={classes.author}>
                  <Box className={classes.flex}>
                    <PersonIcon className={classes.icon} />
                    <span>{c.agent.first_name + " " + c.agent.last_name}</span>
                  </Box>
                  <Box className={classes.flex}>
                    <span>
                      <EventIcon className={classes.icon} />
                    </span>
                    {new Date(c.created_at).toLocaleDateString()}
                  </Box>
                </Box>
              </Box>
            ))}
        </Grid>
      </Box>
    </>
  );
};
