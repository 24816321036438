import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { useFormikContext } from "formik";
import { getNumbersOnly } from "../../../utils";
import { ApplicationQuestion } from "../../../state/commercial/types";

export const QuestionField = ({
  question,
  handleConditionalDisplay,
}: {
  question: ApplicationQuestion;
  handleConditionalDisplay: any;
}) => {
  const { errors, touched, handleChange, handleBlur, values }: any =
    useFormikContext();

  switch (question.questionType) {
    case "RADIO":
      return (
        <FormControl
          component="fieldset"
          error={
            touched[question.questionId] && Boolean(errors[question.questionId])
          }
        >
          <RadioGroup
            name={question.questionId}
            value={values[question.questionId] || ""}
            onChange={(e) => {
              handleChange(e);
              handleConditionalDisplay(question.questionId, e.target.value);
            }}
            onBlur={handleBlur}
          >
            {question.answerOptions.map((option) => (
              <>
                <FormControlLabel
                  key={option.answerOptionValue}
                  value={option.answerOptionValue}
                  control={<Radio />}
                  label={option.answerOptionText}
                />
              </>
            ))}
          </RadioGroup>
          {touched[question.questionId] &&
            Boolean(errors[question.questionId]) && (
              <FormHelperText>{errors[question.questionId]}</FormHelperText>
            )}
        </FormControl>
      );

    case "SINGLESELECTDROPDOWN":
      return (
        <FormControl
          fullWidth
          error={
            touched[question.questionId] && Boolean(errors[question.questionId])
          }
        >
          <InputLabel>{question.question}</InputLabel>
          <Select
            name={question.questionId}
            value={values[question.questionId] || ""}
            label={question.question}
            onChange={(e) => {
              handleChange(e);
              handleConditionalDisplay(question.questionId, e.target.value);
            }}
            onBlur={handleBlur}
          >
            {question.answerOptions.map((option) => (
              <MenuItem
                key={option.answerOptionValue}
                value={option.answerOptionValue}
              >
                {option.answerOptionText}
              </MenuItem>
            ))}
          </Select>
          {touched[question.questionId] &&
            Boolean(errors[question.questionId]) && (
              <FormHelperText>{errors[question.questionId]}</FormHelperText>
            )}
        </FormControl>
      );

    case "MULTISELECTDROPDOWN":
      return (
        <FormControl
          fullWidth
          key={question.questionId}
          error={
            touched[question.questionId] && Boolean(errors[question.questionId])
          }
        >
          <InputLabel>{question.question}</InputLabel>
          <Select
            multiple
            name={question.questionId}
            value={values[question.questionId] || []}
            onChange={(e) => {
              handleChange(e);
              handleConditionalDisplay(question.questionId, e.target.value);
            }}
            onBlur={handleBlur}
            label={question.question}
          >
            {question.answerOptions.map((option) => (
              <MenuItem
                key={option.answerOptionValue}
                value={option.answerOptionValue}
              >
                {option.answerOptionText}
              </MenuItem>
            ))}
          </Select>
          {touched[question.questionId] &&
            Boolean(errors[question.questionId]) && (
              <FormHelperText>{errors[question.questionId]}</FormHelperText>
            )}
        </FormControl>
      );
    case "TEXT":
    case "ADDRESS":
      return (
        <TextField
          fullWidth
          name={question.questionId}
          label={question.question}
          value={values[question.questionId] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched[question.questionId] && Boolean(errors[question.questionId])
          }
          helperText={
            touched[question.questionId] && errors[question.questionId]
          }
        />
      );
    case "DATE":
      return (
        <TextField
          fullWidth
          type="date"
          name={question.questionId}
          label={question.question}
          value={values[question.questionId] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            touched[question.questionId] && Boolean(errors[question.questionId])
          }
          helperText={
            touched[question.questionId] && errors[question.questionId]
          }
        />
      );

    case "NUMBER":
    case "YEAR":
      return (
        <TextField
          fullWidth
          name={question.questionId}
          label={question.question}
          value={getNumbersOnly(values[question.questionId]) || ""}
          onChange={(e) => {
            handleChange(e);
            handleConditionalDisplay(question.questionId, e.target.value);
          }}
          onBlur={handleBlur}
          error={
            touched[question.questionId] && Boolean(errors[question.questionId])
          }
          helperText={
            touched[question.questionId] && errors[question.questionId]
          }
        />
      );

    default:
      return null;
  }
};

export const FormField = ({ type, label, fullName }) => {
  const {
    errors,
    touched,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
  }: any = useFormikContext();
  const getValueFromPath = (obj, path) => {
    return path
      .split(".")
      .reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : ""),
        obj,
      );
  };

  const value = getValueFromPath(values, fullName);
  switch (type) {
    case "STRING":
      return (
        <TextField
          fullWidth
          name={`${fullName}`}
          label={label}
          value={value || ""}
          onChange={(e) => setFieldValue(`${fullName}`, e.target.value)}
          onBlur={handleBlur}
          error={touched[fullName] && Boolean(errors[fullName])}
          helperText={touched[fullName] && errors[fullName]}
          sx={{ width: "280px" }}
        />
      );

    case "NUMBER":
      return (
        <TextField
          fullWidth
          name={`${fullName}`}
          label={label}
          value={getNumbersOnly(value) || ""}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched[fullName] && Boolean(errors[fullName])}
          helperText={touched[fullName] && errors[fullName]}
          sx={{ width: "280px" }}
        />
      );

    default:
      return null;
  }
};
