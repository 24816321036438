import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../icons";
import { useDispatch, useSelector } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import { adminActions, adminSelectors, authSelectors } from "../../state";
import { useStyles } from "./ModalAgent.styles";
import { formatTitleCase, getInsuranceLevelTitle } from "../../utils";
import { DatePicker } from "@mui/x-date-pickers";

export default function ModalAgent() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const isOpen = useSelector(adminSelectors.bindModalOpen);

  const { singleQuoteType, req_uid, life, isBundle } = useSelector(
    adminSelectors.bundleBindData,
  );
  const bundleBindData = useSelector(adminSelectors.bundleBindData);

  const userId = useSelector(authSelectors.userId);
  const role = useSelector(authSelectors.activeRole);
  const activeAgencyId = useSelector(authSelectors.activeAgencyId);

  const [commentsInput, setCommentsInput] = useState("");
  const [price, setPrice] = useState<any>();
  const [hasBundle, setHasBundle] = useState(false);
  const [isBundleQuote, setIsBundleQuote] = useState(false);
  const [buySingle, setBuySingle] = useState(true);
  const [otherQuote, setOtherQuote] = useState("");
  const [singleQuote, setSingleQuote] = useState<any>();
  const [otherPrice, setOtherPrice] = useState<any>();
  const [effectiveDate, setEffectiveDate] = useState<any>();

  useEffect(() => {
    if (!isOpen) return;
    setEffectiveDate(bundleBindData.effectiveDate);
    if (
      (!!bundleBindData.auto && !!bundleBindData.home) ||
      (!!bundleBindData.auto && !!bundleBindData.renters)
    ) {
      const other = bundleBindData.home ? "home" : "renters";
      setOtherQuote(other);
      setHasBundle(true);
      setPrice(bundleBindData.auto.premium);
      setOtherPrice(bundleBindData[other].premium);
      setSingleQuote(false);
      setBuySingle(true);
    } else {
      const singleQuote = singleQuoteType;

      setPrice(bundleBindData[singleQuote]?.premium);
      setSingleQuote({
        logo: bundleBindData[singleQuote]?.logo,
        level: getInsuranceLevelTitle(
          bundleBindData[singleQuote]?.insurance_level_id,
        ),
        quoteType: formatTitleCase(bundleBindData.singleQuoteType),
        turbo_quote_id: bundleBindData[singleQuote]?.turbo_quote_id,
      });
      if (isBundle === false) {
        setBuySingle(true);
        setIsBundleQuote(false);
      } else {
        setBuySingle(false);
        setIsBundleQuote(true);
      }
      setHasBundle(false);
    }
  }, [bundleBindData, otherQuote, isOpen]);

  const handleCloseModal = () => {
    dispatch(adminActions.setBindModalOpen(false));
    if (!singleQuote) {
      dispatch(adminActions.setBundleBindData({}));
    }
    setCommentsInput("");
  };

  const handleSubmitActivity = async () => {
    if (!buySingle) {
      handleCloseModal();
      return;
    }
    const values = {
      message: commentsInput,
      quoteType: life ? "life" : "",
      user_id: userId,
      req_uid,
      price,
      turbo_quote_id: singleQuote.turbo_quote_id,
      effective_date: effectiveDate,
    };
    if (!role || !activeAgencyId) return;
    if (hasBundle) {
      const autoValues = {
        message: commentsInput,

        user_id: userId,
        req_uid: bundleBindData.req_uid,
        price,
        turbo_quote_id: bundleBindData.auto.turbo_quote_id,
        effective_date: effectiveDate,
      };
      const homeValues = {
        user_id: userId,
        req_uid: bundleBindData.req_uid,
        price: otherPrice,
        turbo_quote_id: bundleBindData[otherQuote].turbo_quote_id,
        effective_date: effectiveDate,
      };
      await dispatch(
        adminActions.setPolicyBound(autoValues, req_uid, role, activeAgencyId),
      );
      dispatch(
        adminActions.setPolicyBound(homeValues, req_uid, role, activeAgencyId),
      );
    } else {
      dispatch(
        adminActions.setPolicyBound(values, req_uid, role, activeAgencyId),
      );
    }
    setCommentsInput("");
    handleCloseModal();
  };

  return (
    <Fade in={!!isOpen}>
      <div>
        <Modal open={!!isOpen} onClose={() => handleCloseModal()}>
          <Box className={classes.box}>
            <IconButton
              className={classes.closeBtn}
              onClick={handleCloseModal}
              size="large"
            >
              <CloseIcon />
            </IconButton>

            <>
              <Box className={classes.commentsWrapper}>
                <Box className={classes.commentsTitle}>
                  {!buySingle || hasBundle
                    ? "Confirm purchased bundle policies"
                    : "Confirm status change"}
                </Box>
                {isBundleQuote && !hasBundle && (
                  <Box>
                    <Box className={classes.singleSubTitle}>
                      Please select both auto + home quotes to mark this
                      application as won
                    </Box>
                    <FormControlLabel
                      className={classes.singleCheck}
                      control={
                        <Checkbox
                          checked={!!buySingle}
                          onChange={() => setBuySingle(!buySingle)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Only a single policy was purchased"
                    />
                  </Box>
                )}
                <div>
                  {buySingle && (
                    <div>
                      <TextField
                        select
                        variant="outlined"
                        className={classes.statusDropdown}
                        label="Status"
                        id="status"
                        name="status"
                        disabled
                        size="small"
                        value="Closed"
                      >
                        <MenuItem key={"i"} value="Closed">
                          Closed
                        </MenuItem>
                      </TextField>
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        className={classes.subStatusDropdown}
                        label="Reason"
                        id="status"
                        name="sub_status"
                        size="small"
                        value="Won"
                        disabled
                      >
                        <MenuItem key={"ie"} value="Won">
                          {" "}
                          Won
                        </MenuItem>
                      </TextField>
                    </div>
                  )}
                  {buySingle && (
                    <Box>
                      <Typography className={classes.purchaseTitle}>
                        {hasBundle ? "Purchased policies" : "Purchased policy"}
                      </Typography>
                      {!hasBundle && (
                        <Box className={classes.purchaseData}>
                          <Box>
                            {bundleBindData.carrierName ? (
                              <div className={classes.carrierName}>
                                {bundleBindData.carrierName}
                              </div>
                            ) : (
                              <img
                                className={classes.carrierImg}
                                src={singleQuote?.logo}
                                alt="carrier_logo"
                              />
                            )}{" "}
                          </Box>
                          <Box>
                            {" "}
                            <Box className={classes.purchaseType}>
                              {formatTitleCase(singleQuote?.quoteType)}{" "}
                              insurance
                            </Box>
                            <Box>
                              <span className={classes.purchaseLevel}>
                                {singleQuote?.level}
                              </span>
                            </Box>
                          </Box>{" "}
                        </Box>
                      )}
                      {hasBundle && (
                        <Box className={classes.bundleWrapper}>
                          <Box className={classes.bundleQuote}>
                            <Box>
                              <img
                                className={classes.bundleImg}
                                src={bundleBindData.auto?.logo}
                                alt="carrier_logo"
                              />{" "}
                            </Box>
                            <Box className={classes.bundleBottom}>
                              {" "}
                              <Box className={classes.purchaseType}>
                                {formatTitleCase(
                                  bundleBindData.auto?.quoteType,
                                )}{" "}
                                insurance
                              </Box>
                              <Box>
                                <span
                                  className={classes.purchaseLevel}
                                >{`${getInsuranceLevelTitle(
                                  bundleBindData.auto?.insurance_level_id,
                                )}`}</span>
                              </Box>
                            </Box>{" "}
                          </Box>
                          <Box className={classes.bundleQuote}>
                            <Box>
                              <img
                                className={classes.bundleImg}
                                src={bundleBindData[otherQuote]?.logo}
                                alt="carrier_logo"
                              />{" "}
                            </Box>
                            <Box className={classes.bundleBottom}>
                              {" "}
                              <Box className={classes.purchaseType}>
                                {formatTitleCase(
                                  bundleBindData[otherQuote]?.quoteType,
                                )}{" "}
                                insurance
                              </Box>
                              <Box>
                                <span
                                  className={classes.purchaseLevel}
                                >{`${getInsuranceLevelTitle(
                                  bundleBindData[otherQuote]
                                    ?.insurance_level_id,
                                )}`}</span>
                              </Box>
                            </Box>{" "}
                          </Box>
                        </Box>
                      )}
                      {!hasBundle && buySingle && (
                        <Box>
                          <label
                            htmlFor="price-input"
                            className={classes.priceLabel}
                          >
                            Purchase price (annually)
                          </label>
                          <CurrencyInput
                            className={classes.priceInput}
                            style={{ width: "100%" }}
                            prefix="$"
                            value={price}
                            onValueChange={(val) => setPrice(val)}
                            name="input-name"
                            id="price-input"
                          />
                        </Box>
                      )}
                      {hasBundle && (
                        <Box className={classes.bundlePriceWrapper}>
                          <Box className={classes.bundlePriceContainer}>
                            <label
                              htmlFor="price-input"
                              className={classes.priceLabel}
                            >
                              Auto policy purchase price
                            </label>
                            <CurrencyInput
                              className={classes.bundlePriceInput}
                              prefix="$"
                              value={price}
                              onValueChange={(val) => setPrice(val)}
                              name="input-name"
                              id="price-input"
                            />
                          </Box>
                          <Box className={classes.bundlePriceContainer}>
                            <label
                              htmlFor="price-input"
                              className={classes.priceLabel}
                            >
                              {formatTitleCase(otherQuote)} policy purchase
                              price
                            </label>
                            <CurrencyInput
                              className={classes.bundlePriceInput}
                              prefix="$"
                              value={otherPrice}
                              onValueChange={(val) => setOtherPrice(val)}
                              name="input-name"
                              id="price"
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}
                  {buySingle && (
                    <>
                      <Box className={classes.picker}>
                        <DatePicker
                          label="Effective date"
                          format="MM/dd/yyyy"
                          value={new Date(effectiveDate)}
                          onChange={(date) => setEffectiveDate(date)}
                        />
                      </Box>
                      <Box className={classes.commentsInput}>
                        <TextField
                          fullWidth
                          label="Comments"
                          id="fullWidth"
                          value={commentsInput}
                          onChange={(e) => {
                            setCommentsInput(e.target.value);
                          }}
                          multiline
                          minRows={5}
                          maxRows={5}
                        />
                      </Box>
                    </>
                  )}
                </div>
                <Box className={classes.commentsButtons}>
                  <Button
                    className={classes.cancelBtn}
                    size="small"
                    onClick={() => {
                      handleCloseModal();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={classes.selectBtn}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!commentsInput && buySingle}
                    onClick={() => handleSubmitActivity()}
                  >
                    {buySingle ? "Update" : "Okay"}
                  </Button>
                </Box>
              </Box>
            </>
          </Box>
        </Modal>
      </div>
    </Fade>
  );
}
