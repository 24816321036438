import PayPage from "./PayPage/PayPage";
import HitSnagPage from "./HitSnag/HitSnagPage";
import SomethingWentWrong from "./SomethingWentWrong";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import { NotAuthorizedPage } from "./NotAuthorizedPage";

export const MainPages = {
  hitSnag: {
    pathExact: true,
    anon: true,
    path: "/hit-snag",
    title: "Hit a snag",
    type: "HIT_SNAG_PAGE",
    view: HitSnagPage,
  },
  notAuthorized: {
    anon: true,
    path: "/unauthorized",
    title: "Not Authorized",
    type: "PAGE_NOT_AUTHORIZED",
    view: NotAuthorizedPage,
  },
  payAch: {
    anon: true,
    path: "/payment/:req_uid",
    title: "Home",
    type: "PAGE_PAYMENT",
    view: PayPage,
  },
  somethingWentWrong: {
    anon: true,
    path: "/something-went-wrong",
    title: "Something Went Wrong",
    type: "PAGE_SOMETHING_WENT_WRONG",
    view: SomethingWentWrong,
  },
  notFound: {
    anon: true,
    path: "*",
    title: "Page Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFoundPage,
  },
};

export default MainPages;

export const MainArea = {
  pages: MainPages,
};
