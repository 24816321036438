import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  searchCard: {
    padding: 32,
    minHeight: 220,
  },
  searchField: {
    marginTop: 16,
    // width: 340,
  },
  formCard: {
    borderRadius: 0,
    width: 550,
    marginBottom: "2em",
    margin: "0 auto",
  },
  formWrap: {
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formControl: {
    padding: "0 2em 2em",
  },
  formField: {
    width: "50%",
    padding: "0 2em 2em",
  },
  formInput: {
    "& .MuiOutlinedInput-input": {
      height: "auto",
    },
  },
  note: {
    fontSize: 14,
    lineHeight: 1.5,
    color: theme.palette.secondary.main,
  },
  formAction: {
    paddingRight: "2em",
  },
  selectLoader: {
    position: "absolute",
    right: 35,
  },
  switcherGroup: {
    width: "100%",
  },
  switcher: {
    width: "50%",

    "&.MuiToggleButton-root": {
      borderRadius: 0,
      borderColor: "#ccd2e9",
      textTransform: "initial",
      color: theme.palette.secondary.main,
    },
    "&.MuiToggleButton-root.Mui-selected, &.MuiToggleButton-root.Mui-selected:hover":
      {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: "#fff",
      },
  },
}));
