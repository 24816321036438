import React, { useEffect, useRef, useState } from "react";
import { Card, Typography, Box, Button, TextField } from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authClient,
  authSelectors,
  uiActions,
  useDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, yup } from "../../../../lib";

import { useStyles } from "../AgencySettingsPage.styles";
import { EditIcon } from "../../../../components";
import { Agency } from "../../../../state/admin/state";
import { REACT_APP_ASSETS_URL } from "../../../../config";
import { useParams } from "react-router";
import { DropzoneArea } from "react-mui-dropzone";

const validationSchema = yup.object({
  preview_title: yup.string().max(70, "Title cannot exceed 70 characters"),
  preview_description: yup
    .string()
    .max(170, "Description cannot exceed 170 characters"),
});

const SocialPreviewCard = () => {
  const [inEditMode, setInEditMode] = useState(false);
  const [fileDropped, setFileDropped] = useState(false);
  const fileUploadRef = useRef<FormData>();

  const classes = useStyles();
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const activeAgencyId = params.id
    ? Number(params.id)
    : useSelector(authSelectors.activeAgencyId);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const activeAgencyData: Agency = useSelector(adminSelectors.activeAgencyData);
  const { preview_image_path, preview_title, preview_description } =
    activeAgencyData;

  async function uploadFile() {
    if (fileDropped) {
      const { status, data } = await authClient.post(
        "/file-upload",
        fileUploadRef.current,
      );
      if (status === 200) {
        return data.path;
      } else {
        dispatch(uiActions.showError("Preview image upload failed"));
      }
    }
    return "";
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues: { preview_image_path, preview_title, preview_description },
    validationSchema,
    async onSubmit(values) {
      const new_path = await uploadFile();

      const agencyData = {
        ...activeAgencyData,
        preview_image_path: new_path || activeAgencyData.preview_image_path,
        preview_title: values.preview_title,
        preview_description: values.preview_description,
      };
      dispatch(
        adminActions.updateAgency(activeAgencyId, agencyData, isSuperAdmin),
      );
      setInEditMode(false);
      form.resetForm();
    },
  });

  const handleCancel = () => {
    setInEditMode(false);
    form.resetForm();
  };

  useEffect(() => {
    dispatch(adminActions.getAgency(activeAgencyId));
  }, []);

  const showEditButton = !inEditMode && isSuperAdmin;

  if (!activeAgencyId) return null;

  const SectionTitle = ({ title }) => {
    return (
      <Box className={classes.sectionTitleWrapper}>
        <Typography className={classes.sectionTitle}>{title}</Typography>
        {showEditButton && (
          <Button
            className={classes.editBtnWrapper}
            onClick={() => setInEditMode(!inEditMode)}
          >
            <EditIcon style={{ color: "white" }} />
          </Button>
        )}
      </Box>
    );
  };

  async function handleFileDrop(file) {
    fileUploadRef.current = new FormData();
    fileUploadRef.current.append("file", file[0]);
    setFileDropped(true);
  }

  async function handleFileDelete() {
    fileUploadRef.current = new FormData();
    setFileDropped(false);
  }

  return (
    <>
      {inEditMode ? (
        <Card variant="outlined" className={classes.section}>
          <form onSubmit={form.handleSubmit}>
            <SectionTitle title={"Social preview"} />

            {form.values.preview_image_path && (
              <Box className={classes.socialImageWrapper}>
                <img
                  src={`${REACT_APP_ASSETS_URL}/${preview_image_path}`}
                ></img>
                <Typography className={classes.socialDataSmallText}>
                  {activeAgencyData.domain}
                </Typography>
                <Typography className={classes.socialDataBigText}>
                  {activeAgencyData.preview_title}
                </Typography>
                <Typography className={classes.socialDataMedText}>
                  {activeAgencyData.preview_description}
                </Typography>
              </Box>
            )}

            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"preview_title"}
                name={"preview_title"}
                label={"Title"}
                variant="outlined"
                multiline
                rows={2}
                value={form.values.preview_title}
                onChange={form.handleChange}
                error={Boolean(form.errors.preview_title)}
                helperText={form.errors.preview_title}
                // inputProps={{
                //   maxLength: 70,
                // }}
              />
            </Box>

            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"preview_description"}
                name={"preview_description"}
                label={"Description"}
                variant="outlined"
                multiline
                rows={2}
                value={form.values.preview_description}
                onChange={form.handleChange}
                error={Boolean(form.errors.preview_description)}
                helperText={form.errors.preview_description}
              />
            </Box>

            <Box className={classes.textFieldWrapper}>
              <Typography className={classes.fieldTitle}>
                Upload preview image
              </Typography>

              <DropzoneArea
                showPreviews={true}
                filesLimit={1}
                acceptedFiles={[".png", ".jpg", ".jpeg", ".svg"]}
                maxFileSize={3000000}
                showPreviewsInDropzone={false}
                dropzoneText="Drop an image or click to browse"
                dropzoneClass={classes.dropzone}
                dropzoneParagraphClass={classes.dropzoneText}
                previewGridClasses={{
                  item: classes.dropzone,
                  image: classes.dropzoneImage,
                }}
                onDrop={(file) => handleFileDrop(file)}
                onDelete={() => handleFileDelete()}
              />
            </Box>

            <Box className={classes.submit}>
              <Button
                variant="contained"
                onClick={() => handleCancel()}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitBtn}
                disabled={!form.isValid}
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      ) : (
        <Card
          variant="outlined"
          className={classes.section}
          style={{ minHeight: 456 }}
        >
          <SectionTitle title={"Social preview"} />
          <Typography className={classes.smallText}>
            Choose how your website will look when shared
          </Typography>
          <Box className={classes.socialImageWrapper}>
            <img src={`${REACT_APP_ASSETS_URL}/${preview_image_path}`}></img>
            <Typography className={classes.socialDataSmallText}>
              {activeAgencyData.domain}
            </Typography>
            <Typography className={classes.socialDataBigText}>
              {activeAgencyData.preview_title}
            </Typography>
            <Typography className={classes.socialDataMedText}>
              {activeAgencyData.preview_description}
            </Typography>
          </Box>
        </Card>
      )}
    </>
  );
};

export default SocialPreviewCard;
