import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  containerWrapper: {
    padding: 10,
    width: "100%",
    justifyContent: "center",
  },
  container: {
    padding: 10,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  coverageWrapper: {
    height: "100%",
    padding: 10,
    width: "100%",
    maxWidth: 1280,
  },
  sidebar: {
    position: "relative",
    width: "280px",
    height: "100%",
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 40,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 12px 0 0",
  },
  oval: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 81,
    height: 81,
    marginBottom: 23,
    border: "1px solid #DADFF6",
    color: "#EFF1FB",
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
    borderRadius: "50%",
  },
  ovalWrapper: {
    height: "100%",
  },
  mainContent: {
    width: "calc(100% - 280px)",
  },
}));
