import React, { useEffect, useRef } from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useStyles } from "./PaymentInProgress.styles";
import {
  useDispatch,
  useSelector,
  wizardActions,
  wizardSelectors,
} from "../../../state";
import { CheckIcon } from "../../../components/icons";
import ReactGA from "react-ga";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  const classes = useStyles();
  return (
    <Box
      position="relative"
      display="inline-flex"
      className={classes.progressWrapper}
    >
      <CircularProgress
        thickness={2}
        style={{ width: 128, height: 128 }}
        variant="determinate"
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {props.value === 100 ? (
            <CheckIcon className={classes.checkIcon} />
          ) : (
            `${Math.round(props.value)}%`
          )}
        </Typography>
      </Box>
    </Box>
  );
}

export default function PaymentInProgress({ isOffline = false }) {
  const [progress, setProgress] = React.useState(10);
  const classes = useStyles();
  const dispatch = useDispatch();
  const alreadyPaid = useRef(false);
  const selectedQuote = useSelector(wizardSelectors.selectedQuote);

  useEffect(() => {
    if (alreadyPaid.current === false) {
      ReactGA.event({
        category: "User",
        action: "Submitted Payment",
        value: selectedQuote.TotalPremium,
      });
    }
    alreadyPaid.current = true;
  }, [selectedQuote.turbo_quote_id, selectedQuote.TotalPremium]);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 100) {
          clearInterval(timer);
          dispatch(wizardActions.showPmtPopup(false));
          return prevProgress;
        }
        return prevProgress + 10;
      });
    }, 1500);
    return () => {
      clearInterval(timer);
    };
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <CircularProgressWithLabel value={progress} />
      {isOffline && progress === 100 ? (
        <>
          <Typography className={classes.mainText}>
            Your quote has been processed
          </Typography>
          <Typography className={classes.subText}>
            An agent will bind your quote and follow up with next steps.
            <br />
            <br />
            You can now close your browser window.
          </Typography>
        </>
      ) : (
        <>
          <Typography className={classes.mainText}>
            Finalizing quote with carrier…
          </Typography>
          <Typography className={classes.subText}>
            Hang in there just a minute
          </Typography>
        </>
      )}
    </Box>
  );
}
