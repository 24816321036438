import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  quoteCard: {
    borderRadius: 3,
    border: "none",
    padding: "40px 32px 24px",
    boxShadow: "0 2px 24px 0 #dadff6, 0 2px 40px 0 #f9fafc",
    margin: "12px",
    "&.quote-card-shadow": {
      boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
    },
    [theme.breakpoints.down('sm')]: {
      boxShadow: "none",
      border: "1px solid #cdd2e9",
    },
  },
  bestValueText: {
    marginTop: "-19.5px",
    fontWeight: 500,
    color: theme.palette.primary.light,
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    "& svg": {
      fontSize: 13,
      marginRight: 8,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 11,
      marginTop: "-16.5px",
    },
  },
  topContainer: {
    width: 220,
    margin: "0 auto",
  },
  coverageContainer: {
    [theme.breakpoints.down('sm')]: {
      width: ({ isQuoteSelected }: any) => (isQuoteSelected ? 220 : "100%"),
      margin: ({ isQuoteSelected }: any) =>
        isQuoteSelected ? "0 auto" : "auto",
    },
  },
  insuranceLevel: {
    fontSize: 20,
    fontWeight: 600,
    color: "#000",
    marginBottom: 24,
    whiteSpace: "nowrap",
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  carrierImg: {
    height: 24,
    minHeight: 24,
    maxHeight: 24,
    overflow: "hidden",
  },
  largePaymentAmount: {
    fontSize: 40,
    color: theme.palette.primary.main,
    "& span": {
      fontSize: 14,
      color: theme.palette.secondary.main,
    },
  },
  smallPaymentAmount: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    "& span": {
      fontWeight: "600",
    },
  },
  seeMore: {
    fontSize: 14,
    fontFamily: "Lato",
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginTop: 24,
    letterSpacing: "1px",
    textAlign: "center",
    textTransform: "uppercase",
    cursor: "pointer",
  },
  selectBtn: {
    fontWeight: 600,
    letterSpacing: "1px",
    transition: "all 300ms linear",
    height: 32,
    width: "100%",
    color: theme.palette.primary.contrastText,
    marginTop: 16,
    marginBottom: 16,
  },
  expandBtn: {
    marginTop: 16,
    cursor: "pointer",
    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  expandText: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    marginRight: 8,
    textTransform: "uppercase",
  },
  // selected quote card styles
  selectedCarrierImg: {
    height: 32,
  },
  selectedInsuranceLevel: {
    color: theme.palette.secondary.main,
    fontSize: 12,
  },
  selectedMonthlyPayments: {
    marginTop: 16,
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      justifyContent: "flex-start",
      gap: "calc(10%)",
      width: "auto",
    },
  },
  selectedMonthlyPayment: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 600,
  },
  selectedMonthlyPaymentLabel: {
    color: theme.palette.secondary.main,
    fontSize: 13,
    fontWeight: 500,
  },
  selectedTotalPayment: {
    fontWeight: 600,
    fontSize: 16,
    color: "#7983A8",
    marginRight: 16,
  },
}));
