import React from "react";

export const Home2Image = ({ fillColor, fillContrast }) => {
  return (
    <svg
      width="401px"
      height="321px"
      viewBox="0 0 401 321"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 3</title>
      <g
        id="↳-home-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="2.1-Home-Wizard---home-details-2"
          transform="translate(1.000000, -489.000000)"
        >
          <g id="Group-3" transform="translate(0.000000, 490.000000)">
            <g
              id="undraw_discount_d4bd"
              transform="translate(0.000000, -0.000000)"
              stroke="#DADFF6"
            >
              <g
                id="Group"
                transform="translate(30.988729, 83.803822)"
                strokeWidth="0.72"
              >
                <path
                  d="M106.37203,1.43788772 C106.37203,1.43788772 98.9779479,138.14533 67.801628,218.755079"
                  id="Path-Copy"
                  transform="translate(87.086829, 110.096484) rotate(-14.000000) translate(-87.086829, -110.096484) "
                ></path>
                <path
                  d="M0,122.715851 C0,122.715851 19.6043157,187.169877 18.7663963,229.68455"
                  id="Path-Copy-2"
                ></path>
              </g>
              <g id="Group" fillRule="nonzero" strokeWidth="0.9">
                <path
                  d="M76.4132038,83.5039312 C74.9451231,81.6338376 73.608524,79.6638694 72.4130575,77.608251 L100.634985,72.968243 L70.1144659,73.1954599 C64.1959228,60.2985774 63.839635,45.5302962 69.1293552,32.3621941 L110.086078,53.6368697 L72.3115612,25.8326983 C81.931466,9.08974219 100.092479,-0.859576334 119.358599,0.0584771717 C138.624719,0.976530677 155.760493,12.6077831 163.749704,30.1898345 C171.738914,47.7718859 169.239894,68.3520977 157.275864,83.5039312 C159.561317,86.4173323 161.526943,89.568692 163.138765,92.9035349 L126.504589,111.965836 L165.568704,98.8111739 C171.143738,115.308934 168.024522,133.510498 157.275864,147.202391 C173.865415,168.327929 171.45723,198.675226 151.744172,216.911285 C132.031114,235.147345 101.634072,235.147345 81.9210141,216.911285 C62.2079556,198.675226 59.7997709,168.327929 76.3893223,147.202391 C61.7107655,128.505193 61.7107655,102.177212 76.3893223,83.4800136 L76.4132038,83.5039312 Z"
                  id="Path"
                ></path>
                <path
                  d="M48.0774935,202.909237 C48.5929492,201.858868 49.0349219,200.773899 49.4002203,199.662162 L35.3437948,200.319403 L50.0555635,197.303018 C51.586176,190.490017 50.2486344,183.346026 46.3573546,177.550382 L28.8200213,191.983757 L44.1586817,174.733847 C37.8181464,167.661212 28.0612171,164.73287 18.8828546,167.147826 C9.7044921,169.562782 2.64606309,176.915481 0.597631363,186.19537 C-1.45080036,195.47526 1.8547682,205.123923 9.16087668,211.190729 C8.35865,212.826919 7.73466019,214.544876 7.29969372,216.314903 L26.8783242,221.737117 L6.73387928,219.406922 C5.73652175,227.91773 9.09752258,236.35811 15.6695355,241.846765 C9.84410496,253.712819 14.1039505,268.071372 25.4545625,274.828906 C36.8051745,281.58644 51.4343,278.473349 59.0582195,267.678017 C66.6821389,256.882684 64.7402582,242.030866 54.5976457,233.562827 C59.7515015,223.061168 57.0613289,210.390351 48.086543,202.895281 L48.0774935,202.909237 Z"
                  id="Path-Copy-3"
                ></path>
              </g>
            </g>
            <g
              id="Group-14"
              transform="translate(36.279070, 123.720930)"
              fillRule="nonzero"
            >
              <path
                d="M14.2758475,179.172316 C16.9406602,189.16194 26.0678796,195.348837 26.0678796,195.348837 C26.0678796,195.348837 30.874799,185.339272 28.210065,175.349648 C25.5453309,165.360024 16.4180486,159.173127 16.4180486,159.173127 C16.4180486,159.173127 11.6111292,169.182676 14.2758475,179.172316 Z"
                id="Path"
                fill="#002679"
              ></path>
              <path
                d="M18.5936113,176.520331 C26.0334919,183.941212 26.9422911,195.348837 26.9422911,195.348837 C26.9422911,195.348837 15.7885765,194.724823 8.34867986,187.303942 C0.908783254,179.883061 0,168.475452 0,168.475452 C0,168.475452 11.1537147,169.09945 18.5936113,176.520331 Z"
                id="Path"
                fill={fillContrast}
              ></path>
              <polygon
                id="Path"
                fill="#002679"
                points="32.6205076 101.241372 25.9060492 90.0237156 88.5775738 36.1757106 249.734314 36.1757106 249.734314 51.8804297 191.539095 114.704187 124.389642 128.165375"
              ></polygon>
              <polygon
                id="Path"
                fill="#DADFF6"
                points="277.082131 34.1085271 256.124766 34.1085271 256.124766 45.0825359 249.528033 37.5826622 196.170582 90.9656344 187.276598 99.8622588 176.161134 144.348607 196.170582 193.281654 298.437686 193.281654 298.437686 93.1897905 277.082131 68.9095541"
              ></polygon>
              <rect
                id="Rectangle"
                fill="#E7EBFD"
                x="25.9060492"
                y="90.9560724"
                width="169.943683"
                height="102.325581"
              ></rect>
              <rect
                id="Rectangle"
                fill={fillColor}
                x="135.747698"
                y="122.997416"
                width="36.2684688"
                height="32.0413437"
              ></rect>
              <rect
                id="Rectangle"
                fill="#CCCCCC"
                x="90.1530511"
                y="121.963824"
                width="32.123501"
                height="71.3178295"
              ></rect>
              <polygon
                id="Path"
                fill={fillColor}
                points="54.9208242 133.333333 54.9208242 121.963824 41.4496787 121.963824 41.4496787 133.333333 53.7974147 133.333333"
              ></polygon>
              <polygon
                id="Path"
                fill={fillColor}
                points="53.7974147 142.635659 41.4496787 142.635659 41.4496787 154.005168 54.9208242 154.005168 54.9208242 142.635659"
              ></polygon>
              <rect
                id="Rectangle"
                fill={fillColor}
                x="65.2832439"
                y="121.963824"
                width="13.4711456"
                height="11.369509"
              ></rect>
              <rect
                id="Rectangle"
                fill={fillColor}
                x="65.2832439"
                y="142.635659"
                width="13.4711456"
                height="11.369509"
              ></rect>
              <polygon
                id="Path"
                fill="#FFFFFF"
                points="244.553104 134.366925 244.553104 122.997416 231.081959 122.997416 231.081959 134.366925 243.42983 134.366925"
              ></polygon>
              <polygon
                id="Path"
                fill="#FFFFFF"
                points="243.42983 143.669251 231.081959 143.669251 231.081959 155.03876 244.553104 155.03876 244.553104 143.669251"
              ></polygon>
              <rect
                id="Rectangle"
                fill="#FFFFFF"
                x="253.879282"
                y="122.997416"
                width="13.4711456"
                height="11.369509"
              ></rect>
            </g>
            <rect
              id="Rectangle"
              fill="#000000"
              x="64.1860465"
              y="319.069767"
              width="335.813953"
              height="1"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};
