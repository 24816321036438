import { Api, Navigation, history } from "../../lib";
import { getReferralToken } from "../../utils";
import { uiActions, wizardActions } from "../states";
import { life } from "./state";
const { actions } = life;

export const lifeActions = {
  ...actions,
  submitQuote(onQuotesPage, isLoggedIn) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const { startForm, insuredDetails, coverageDetails } = getState().life;
      const { contactInfo } = getState().wizard;
      let referrer;
      if (isLoggedIn) {
        const { user } = getState().auth;
        referrer = getReferralToken(user);
      }
      const payload = {
        startForm,
        insuredDetails,
        coverageDetails,
        contact: contactInfo,
        quoteType: "life",
        agentQuoted: isLoggedIn,
        referrer,
      };
      const { data, status } = await Api.post(`/life-quote`, payload);
      if (status === 200) {
        dispatch(wizardActions.setReqUid(data.req_uid));
        dispatch(actions.setQuotes(data.companies));
        dispatch(actions.setBaseInfo(data.baseInfo));
        dispatch(uiActions.setLoading(false));
        if (isLoggedIn) {
          if (window.location.hostname === "test.reliexchange.com") {
            window.location.replace(
              `https://staging.5mininsure.com/admin/quote-management/${data.req_uid}`,
            );
          } else if (window.location.host.includes("reliexchange")) {
            window.location.replace(
              `https://portal.reliexchange.com/admin/quote-management/${data.req_uid}`,
            );
          } else {
            history.push(`/admin/quote-management/${data.req_uid}`);
          }
          return;
        }

        if (!onQuotesPage) {
          Navigation.go("/life/wizard/quotes");
        }
      } else {
        dispatch(uiActions.setLoading(false));
        history.push("/hit-snag");
      }
    };
  },
  getQuote() {
    return async (dispatch, getState) => {
      const { req_uid } = getState().wizard;
      const { data, status } = await Api.get(
        `/quote/${req_uid}/quote-type/life/linked/0`,
      );
      if (status === 200) {
        dispatch(actions.setQuotes(data.companies));
        dispatch(actions.setBaseInfo(data.baseInfo));
      } else {
        history.push("/hit-snag");
      }
    };
  },
};
