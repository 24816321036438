import StartWizard from "./start/StartWizard";
import VerifyPhone from "./verify/VerifyPhone";
import CodeEntry from "./passCode/CodeEntry";
import VehiclesWizard from "./vehicles/VehiclesWizard";
import DriversWizard from "./drivers/DriversWizard";
import DiscountFields from "./discounts/DiscountFields";
import ContactForm from "../contact/ContactForm";
import Quotes from "../quotes/QuotesContainer";
import SelectedQuote from "../selectedQuote/SelectedQuoteContainer";
import { HydrateQuote } from "../hydrate/HydrateQuote";

export const AutoWizardPages = {
  autoWizardStart: {
    pathExact: true,
    anon: true,
    path: "/auto/wizard/start",
    type: "AUTO_PAGE_WIZARD_START",
    view: StartWizard,
  },
  autoWizardVerify: {
    pathExact: true,
    anon: true,
    path: "/auto/wizard/verify",
    type: "AUTO_PAGE_VERIFY_CODE",
    view: VerifyPhone,
  },
  autoWizardCodeEntry: {
    pathExact: true,
    anon: true,
    path: "/auto/wizard/code-entry",
    type: "AUTO_PAGE_CODE",
    view: CodeEntry,
  },
  autoWizardVehicles: {
    pathExact: true,
    anon: true,
    path: "/auto/wizard/vehicles",
    type: "AUTO_PAGE_WIZARD_VEHICLES",
    view: VehiclesWizard,
  },
  autoWizardDrivers: {
    pathExact: true,
    anon: true,
    path: "/auto/wizard/drivers",
    type: "AUTO_PAGE_WIZARD_DRIVERS",
    view: DriversWizard,
  },
  autoWizardDiscounts: {
    pathExact: true,
    anon: true,
    path: "/auto/wizard/discounts",
    type: "AUTO_PAGE_WIZARD_DISCOUNT",
    view: DiscountFields,
  },
  autoWizardContact: {
    pathExact: true,
    anon: true,
    path: "/auto/wizard/contact",
    type: "AUTO_PAGE_WIZARD_CONTACT",
    view: ContactForm,
  },
  autoWizardQuotes: {
    pathExact: true,
    anon: true,
    path: "/auto/wizard/quotes",
    type: "AUTO_PAGE_WIZARD_QUOTES",
    view: Quotes,
  },
  autoWizardSelectedQuote: {
    pathExact: true,
    anon: true,
    path: "/auto/wizard/quotes/:id",
    type: "AUTO_PAGE_WIZARD_QUOTES",
    view: SelectedQuote,
  },
  autoWizardHydrateQuote: {
    pathExact: true,
    anon: true,
    path: "/auto/wizard/hydrate-quote/:id",
    type: "AUTO_PAGE_WIZARD_HYDRATE",
    view: HydrateQuote,
  },
};

export default AutoWizardPages;
