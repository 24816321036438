import React from "react";
import { Typography } from "@mui/material";
import { useStyles } from "./HitSnagCompleteCard.styles";
import { useSelector, wizardSelectors } from "../../../../state";
import { ContactConfirm } from "../../../../assets/img/contact_confirm";
import { useTheme } from "@mui/material/styles";

const HitSnagCompleteCard = () => {
  const classes = useStyles({});
  const theme = useTheme();

  const contactType = useSelector(wizardSelectors.contactType);

  return (
    <div className={classes.wrapper}>
      <ContactConfirm fillColor={theme.palette.primary.main} />
      <Typography className={classes.paragraph}>
        Great! A licensed insurance agent will review the information you
        provided and {contactType} you regarding your quote.
      </Typography>
    </div>
  );
};

export default HitSnagCompleteCard;
