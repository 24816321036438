import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    height: 128,
    paddingLeft: 40,
    paddingRight: 40,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      height: 96,
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  headerContent: {
    height: "100%",
  },
  logoWrapper: {
    "& img": {
      width: "auto",
      height: ({ logo_height }: any) => (logo_height ? logo_height : 80),
      [theme.breakpoints.down('md')]: {
        height: ({ logo_height }: any) =>
          logo_height ? logo_height * 0.6 : 48,
      },
    },
  },
  helpTextLight: {
    fontSize: 14,
  },
  phoneLink: {
    fontWeight: 600,
    textDecoration: "none",
    color: "#000",
  },
  root: {
    padding: "120px 120px 0",
    "& img": {
      position: "absolute",
      bottom: 80,
      right: 80,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 16,
      marginTop: 64,
      "& img": {
        maxWidth: 280,
        bottom: 16,
        right: 16,
      },
    },
  },
  title: {
    fontSize: "48px",
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: "24px",
    },
  },
  subTitle: {
    paddingBottom: 40,
    color: theme.palette.secondary.main,
    fontSize: "20px",
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px",
    },
  },
}));
