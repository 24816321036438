import { add, endOfDay, startOfDay } from "date-fns";
import { history, Navigation, timeoutAsync } from "../../lib";
import { authActions, authClient, createApiClient } from "../auth/actions";
import { AppState, AppThunk, AuthState, Role } from "../types";
import { uiActions } from "../ui/actions";
import { admin } from "./state";
import { Status } from "./types";
import { v4 as uuidv4 } from "uuid";

const { actions } = admin;

const isSuperAdmin = (role) => role === "super_admin";
const isSuperAgent = (role) => role === "super_agent";
const isSalesAdmin = (role) => role === "sales_admin";
const isSalesDirector = (role) => role === "sales_director";

export const hasDirectorPermissions = (role) => {
  return (
    role === "super_admin" ||
    role === "super_agent" ||
    role === "sales_admin" ||
    role === "sales_director"
  );
};

export const adminActions = {
  ...actions,
  getQuoteForAgent(
    quoteId: string,
    role: Role | undefined,
    agencyId: number | undefined,
  ): AppThunk {
    return async (dispatch) => {
      if (!role) {
        throw new Error("Role needed to retrieve quotes for agent");
      }
      const url = hasDirectorPermissions(role)
        ? `/quote/${quoteId}/data`
        : `/quote/${quoteId}/data/agency/${agencyId}`;
      const { data, status } = await authClient.get(url);
      if (status === 200) {
        dispatch(actions.setActiveQuoteNew(data));
      } else {
        dispatch(uiActions.showError("Failed to get quote."));
      }
    };
  },
  getNewDriverQuoteID(quoteId: string, applicant: any): AppThunk {
    return async (dispatch) => {
      dispatch(adminActions.setLoadingNewDriverQuotes(true));
      const postObj = {
        req_uid: quoteId,
        new_applicant: applicant,
      };
      const { data, status } = await authClient.post(`/quote-swap-applicant`, {
        ...postObj,
      });
      if (status === 200) {
        const { req_uid } = data;
        dispatch(adminActions.getNewDriverQuotes(req_uid));
      }
    };
  },
  getNewDriverQuotes(req_uid: string): AppThunk {
    return async (dispatch) => {
      const { data } = await authClient.get(`/re-quote/${req_uid}/data`);
      if (data.meta?.isCompleted) {
        const { quotes } = data;
        dispatch(actions.setActiveQuoteNew(quotes));
        dispatch(adminActions.setLoadingNewDriverQuotes(false));
      } else {
        await timeoutAsync(1500);
        await dispatch(adminActions.getNewDriverQuotes(req_uid));
      }
    };
  },
  getNewAgentQuotes(req_uid: string): AppThunk {
    return async (dispatch) => {
      let url;
      if (window.location.hostname === "test.reliexchange.com") {
        url = `https://staging.5mininsure.com/api/V1/re-quote/${req_uid}/data`;
      } else if (window.location.host.includes("reliexchange")) {
        url = `https://portal.reliexchange.com/api/V1/re-quote/${req_uid}/data`;
      } else {
        url = `/re-quote/${req_uid}/data`;
      }
      const { data } = await authClient.get(url);
      if (data.meta?.isCompleted) {
        const { quotes } = data;
        dispatch(actions.setActiveQuoteNew(quotes));
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.setLoadingMessage(null));
        if (window.location.hostname === "test.reliexchange.com") {
          window.location.replace(
            `https://staging.5mininsure.com/admin/quote-management/${req_uid}`,
          );
        } else if (window.location.host.includes("reliexchange")) {
          window.location.replace(
            `https://portal.reliexchange.com/admin/quote-management/${req_uid}`,
          );
        } else {
          history.push(`/admin/quote-management/${req_uid}`);
        }
      } else {
        await timeoutAsync(1500);
        await dispatch(adminActions.getNewAgentQuotes(req_uid));
      }
    };
  },
  showPmtPopup(
    req_uid: string,
    turbo_quote_id: string,
    role: Role,
    agencyId: number,
  ): AppThunk {
    return async (dispatch) => {
      const url = isSuperAdmin(role)
        ? `/payment/${req_uid}/txn/${turbo_quote_id}/show-popup`
        : `/payment/${req_uid}/txn/${turbo_quote_id}/show-popup/agency/${agencyId}`;
      const { status } = await authClient.get(url);

      if (status === 204) {
        dispatch(uiActions.showSuccess("Payment shown."));
      } else if (status === 403) {
        dispatch(uiActions.showError("Unauthorized. Failed to show payment."));
        history.push("/admin");
      } else {
        dispatch(uiActions.showError("Failed to show payment."));
      }
    };
  },
  hidePmtPopup(
    req_uid: string,
    turbo_quote_id: string,
    role: Role,
    agencyId: number,
  ): AppThunk {
    return async (dispatch) => {
      const url = isSuperAdmin(role)
        ? `/payment/${req_uid}/txn/${turbo_quote_id}/hide-popup`
        : `/payment/${req_uid}/txn/${turbo_quote_id}/hide-popup/agency/${agencyId}`;
      const { status } = await authClient.get(url);

      if (status === 204) {
        dispatch(uiActions.showSuccess("Payment closed."));
      } else if (status === 403) {
        dispatch(uiActions.showError("Unauthorized. Failed to close payment."));
        history.push("/admin");
      } else {
        dispatch(uiActions.showError("Failed to close payment."));
      }
    };
  },
  getPmtInfo(req_uid: string, role: Role, agencyId: number): AppThunk {
    return async (dispatch) => {
      const url = isSuperAdmin(role)
        ? `/payment/${req_uid}`
        : `/payment/${req_uid}/agency/${agencyId}`;

      const { status, data } = await authClient.get(url);

      if (status === 200) {
        dispatch(adminActions.savePmtInfo(data));
      } else if (status === 403) {
        dispatch(
          uiActions.showError("Unauthorized. Failed to retrieve payment info."),
        );
        history.push("/admin");
      } else if (status !== 404) {
        dispatch(uiActions.showError("Failed to retrieve payment info."));
      }
    };
  },
  updateQuote(values, req_uid, txnId, role: Role, agencyId: number): AppThunk {
    return async (dispatch) => {
      const url = isSuperAdmin(role)
        ? `/quote/${req_uid}/txn/${txnId}`
        : `/quote/${req_uid}/txn/${txnId}/agency/${agencyId}`;
      const { status } = await authClient.put(url, values);

      if (status === 204) {
        dispatch(adminActions.setShowOfflinePaymentLink(true));
        dispatch(uiActions.showSuccess("Updated quote."));
      } else if (status === 403) {
        dispatch(uiActions.showError("Unauthorized. Failed to update quote."));
        history.push("/admin");
      } else {
        dispatch(uiActions.showError("Failed to update quote"));
      }
    };
  },
  updateQuoteStatus(values, req_uid, role: Role, agencyId: number): AppThunk {
    return async (dispatch) => {
      const url =
        isSuperAdmin(role) ||
        isSuperAgent(role) ||
        isSalesAdmin(role) ||
        isSalesDirector(role)
          ? `/quote-status/${req_uid}`
          : `/agency/${agencyId}/quote-status/${req_uid}`;
      const { status } = await authClient.put(url, values);

      if (status === 204) {
        dispatch(adminActions.getQuoteForAgent(req_uid, role, agencyId));
      } else if (status === 403) {
        dispatch(uiActions.showError("Unauthorized. Failed to update quote."));
      } else {
        dispatch(uiActions.showError("Failed to update status"));
      }
    };
  },
  createEvent(values, role, agencyId): AppThunk {
    return async (dispatch) => {
      const url = `/calendar/event`;
      const { status } = await authClient.post(url, values);

      if (status === 204) {
        if (values.req_uid) {
          dispatch(
            adminActions.getQuoteForAgent(values.req_uid, role, agencyId),
          );
          return;
        }
        dispatch(adminActions.getAlerts());
        if (values.reminder) {
          dispatch(adminActions.getEvents());
        }
      } else if (status === 403) {
        dispatch(uiActions.showError("Unauthorized. Failed to create task."));
      } else {
        dispatch(uiActions.showError("Failed to create task"));
      }
    };
  },
  completeTask(taskId, role, agencyId, req_uid): AppThunk {
    return async (dispatch) => {
      const url = `/task/${taskId}`;
      const { status } = await authClient.delete(url);

      if (status === 204) {
        if (req_uid) {
          dispatch(adminActions.getQuoteForAgent(req_uid, role, agencyId));
          return;
        }
        dispatch(adminActions.getAlerts());
      } else if (status === 403) {
        dispatch(uiActions.showError("Unauthorized. Failed to complete task."));
      } else {
        dispatch(uiActions.showError("Failed to complete task"));
      }
    };
  },
  updateTask(taskId, values, role, agencyId): AppThunk {
    return async (dispatch) => {
      const url = `/task/${taskId}`;
      const { status } = await authClient.put(url, { ...values });

      if (status === 204) {
        if (values.req_uid) {
          dispatch(
            adminActions.getQuoteForAgent(values.req_uid, role, agencyId),
          );
          return;
        }
        dispatch(adminActions.getAlerts());
        if (values.reminder || values.calendarId) {
          dispatch(adminActions.getEvents());
        }
      } else if (status === 403) {
        dispatch(uiActions.showError("Unauthorized. Failed to update task."));
      } else {
        dispatch(uiActions.showError("Failed to update task"));
      }
    };
  },
  getEvents(
    start = new Date(),
    end = add(new Date(), { days: 6 }),
    viewingSchedule = false,
  ): AppThunk {
    return async (dispatch) => {
      if (viewingSchedule) {
        dispatch(uiActions.setLoadingMessage("true"));
      } else {
        dispatch(uiActions.setLoading(true));
      }
      const startDate = new Date(startOfDay(new Date(start))).toISOString();
      const endDate = new Date(endOfDay(new Date(end))).toISOString();

      const url = `/events?start=${startDate}&end=${endDate}`;
      const { status, data } = await authClient.get(url);
      if (status === 200) {
        if (viewingSchedule) {
          dispatch(adminActions.setSchedule(data));
          dispatch(uiActions.setLoadingMessage(""));
        } else {
          dispatch(adminActions.setEvents(data));
          dispatch(uiActions.setLoading(false));
        }
      } else {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Failed to get events"));
      }
    };
  },
  getCalendars(): AppThunk {
    return async (dispatch) => {
      const url = `/google/calendars`;
      const { status, data } = await authClient.get(url);
      if (status === 200) {
        dispatch(adminActions.setCalendars(data));
      } else {
        dispatch(uiActions.showError("Failed to get calendars"));
      }
    };
  },
  getAlerts(): AppThunk {
    return async (dispatch) => {
      const url = `/alerts`;
      const { status, data } = await authClient.get(url);
      if (status === 200) {
        dispatch(adminActions.setAlerts(data));
      } else {
        dispatch(uiActions.showError("Failed to get alerts"));
      }
    };
  },
  setPolicyBound(values, req_uid, role: Role, agencyId: number): AppThunk {
    return async (dispatch) => {
      const url =
        isSuperAdmin(role) ||
        isSuperAgent(role) ||
        isSalesAdmin(role) ||
        isSalesDirector(role)
          ? `/quote-bind/${req_uid}`
          : `/agency/${agencyId}/quote-bind/${req_uid}`;
      const { status } = await authClient.put(url, values);

      if (status === 204) {
        dispatch(adminActions.getQuoteForAgent(req_uid, role, agencyId));
      } else if (status === 403) {
        dispatch(uiActions.showError("Unauthorized. Failed to bind quote."));
      } else {
        dispatch(uiActions.showError("Failed to bind quote"));
      }
    };
  },
  // setBound(req_uid: string, role: Role, agencyId: number): AppThunk {
  //   return async (dispatch) => {
  //     const url = isSuperAdmin(role)
  //       ? `/bind-quote/${req_uid}`
  //       : `agency/${agencyId}/bind-quote/${req_uid}/`;
  //     const { status } = await authClient.put(url, {});

  //     if (status === 204) {
  //       dispatch(uiActions.showSuccess("Policy bound."));
  //     } else if (status === 403) {
  //       dispatch(uiActions.showError("Unauthorized. Failed to bind policy."));
  //       history.push("/admin");
  //     } else {
  //       dispatch(uiActions.showError("Failed to bind policy."));
  //     }
  //   };
  // },
  createQuoteForTesting(formdata: Record<string, any>): Promise<any> {
    return authClient.post(`/quote/`, formdata);
  },
  searchQuotes(query, role: Role, agencyId: number) {
    return async (dispatch) => {
      const url =
        isSuperAdmin(role) ||
        isSuperAgent(role) ||
        isSalesAdmin(role) ||
        isSalesDirector(role)
          ? `/quote-search?query=${query}`
          : `/quote-search/agency/${agencyId}?query=${query}`;

      const { status, data } = await authClient.get(url);

      if (status === 200) {
        return data.rows.map((quote) => ({
          ...quote,
          key: quote.req_uid + quote.created_at,
          label: quote.first_name,
        }));
      } else {
        dispatch(uiActions.showError("Failed to pull up quote"));
      }
    };
  },
  getFAQs() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/faqs`);

      if (status === 200) {
        dispatch(adminActions.setFAQs(data));
      } else {
        dispatch(uiActions.showError("Failed to get FAQs"));
      }
    };
  },
  updateFAQ(params) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      const {
        faqs: { rows: faqs },
      } = getState().admin;
      const faq = faqs.find((f) => f.id === params.id);
      const faqFields = {
        category_id: faq?.category_id,
        question: faq?.question,
        answer: faq?.answer,
        order: faq?.order,
      };

      const values = {
        ...faqFields,
        [params.field]:
          params.field === "order" || params.field === "category_id"
            ? Number(params.value)
            : params.value,
      };

      const { status } = await authClient.put(`/faqs/${params.id}`, values);

      if (status === 200 || status === 204) {
        dispatch(uiActions.showSuccess("FAQ updated"));
      } else {
        dispatch(uiActions.showError("Failed to update FAQ"));
      }

      dispatch(uiActions.setLoading(false));
    };
  },
  deleteFAQ(id) {
    return async (dispatch) => {
      let isSuccess;
      dispatch(uiActions.setLoading(true));

      const { status } = await authClient.delete(`/faqs/${id}`);

      if (status === 200 || status === 204) {
        dispatch(uiActions.showSuccess("FAQ deleted"));
        isSuccess = true;
      } else {
        dispatch(uiActions.showError("Failed to delete FAQ"));
        isSuccess = false;
      }

      dispatch(uiActions.setLoading(false));
      return isSuccess;
    };
  },
  createFAQ(values) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { status } = await authClient.post("/faqs", values);

      if (status === 200 || status === 204) {
        dispatch(uiActions.showSuccess("FAQ created"));
        Navigation.go("/admin/faqs");
      } else {
        dispatch(uiActions.showError("Failed to create FAQ"));
      }

      dispatch(uiActions.setLoading(false));
    };
  },
  getFAQCategories() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/faqs-categories`);

      if (status === 200) {
        dispatch(adminActions.setFAQCategories(data));
      } else {
        dispatch(uiActions.showError("Failed to get FAQ Categories"));
      }
    };
  },
  updateFAQCategory(params) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      const {
        faqCategories: { rows: faqCategories },
      } = getState().admin;
      const faqCategory = faqCategories.find((c) => c.id === params.id);

      const values = {
        [params.field]:
          params.field === "order" ? Number(params.value) : params.value,
        ...(params.field === "category_name"
          ? { order: Number(faqCategory?.order) }
          : { category_name: faqCategory?.category_name }),
      };

      const { status } = await authClient.put(
        `/faqs-categories/${params.id}`,
        values,
      );

      if (status === 200 || status === 204) {
        dispatch(uiActions.showSuccess("FAQ category updated"));
      } else {
        dispatch(uiActions.showError("Failed to update FAQ category"));
      }

      dispatch(uiActions.setLoading(false));
    };
  },
  deleteFAQCategory(id) {
    return async (dispatch) => {
      let isSuccess;
      dispatch(uiActions.setLoading(true));

      const { status } = await authClient.delete(`/faqs-categories/${id}`);

      if (status === 200 || status === 204) {
        dispatch(uiActions.showSuccess("FAQ category deleted"));
        isSuccess = true;
      } else {
        dispatch(uiActions.showError("Failed to delete FAQ category"));
        isSuccess = false;
      }

      dispatch(uiActions.setLoading(false));
      return isSuccess;
    };
  },
  createFAQCategory(values) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { status } = await authClient.post("/faqs-categories", values);

      if (status === 200 || status === 204) {
        dispatch(uiActions.showSuccess("FAQ category created"));
        Navigation.go("/admin/faqs");
      } else {
        dispatch(uiActions.showError("Failed to create FAQ category"));
      }

      dispatch(uiActions.setLoading(false));
    };
  },
  getUsers(role: Role, agencyId: number) {
    return async (dispatch) => {
      const url =
        isSuperAdmin(role) || isSuperAgent(role) || isSalesAdmin(role)
          ? `/users`
          : `/users/agency/${agencyId}`;

      const { status, data } = await authClient.get(url);

      if (status === 200) {
        dispatch(adminActions.setUsers(data));
      } else {
        dispatch(uiActions.showError("Failed to get users"));
      }
    };
  },
  // getUser(id: number, role: Role) {
  //   return async (dispatch) => {
  //     const url =
  //       isSuperAdmin(role) || isSuperAgent(role)
  //         ? `/users/${id}`
  //         : `/users/agency/${id}`;

  //     const { status, data } = await authClient.get(url);

  //     if (status === 200) {
  //       dispatch(adminActions.setCurrentUser(data));
  //     } else {
  //       dispatch(uiActions.showError("Failed to get user"));
  //     }
  //   };
  // },
  getAgentStats(role, agencyId, referralTokens, startValue) {
    return async (dispatch) => {
      const url =
        role === "sales_admin" || role === "sales_director"
          ? `/director/agent/stats?startValue=${startValue}`
          : role === "agency_admin" || role === "agent"
          ? `/agency/${agencyId}/agent/stats?startValue=${startValue}`
          : `/agent/stats?startValue=${startValue}`;

      const { status, data } = await authClient.post(url, { referralTokens });

      if (status === 200) {
        dispatch(adminActions.setAgentStats(data));
      } else {
        dispatch(uiActions.showError("Failed to get stats"));
      }
    };
  },
  getGroupStats(groupIds, startValue) {
    return async (dispatch) => {
      const url = `/group/stats?startValue=${startValue}`;

      const { status, data } = await authClient.post(url, { groupIds });

      if (status === 200) {
        dispatch(adminActions.setAgentStats(data));
      } else {
        dispatch(uiActions.showError("Failed to get stats"));
      }
    };
  },
  getDirectors() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/directors`);

      if (status === 200) {
        dispatch(adminActions.setDirectors(data));
      } else {
        dispatch(uiActions.showError("Failed to get directors"));
      }
    };
  },
  getDirector(id: number) {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/director/${id}`);

      if (status === 200) {
        dispatch(adminActions.setCurrentUser(data));
      } else {
        dispatch(uiActions.showError("Failed to get user"));
      }
    };
  },
  deleteAgencyDirector(agencyId: number, directorGroupId, directorUserId) {
    return async (dispatch) => {
      const { status } = await authClient.delete(
        `/${agencyId}/agency-group/${directorGroupId}`,
      );
      if (status === 204) {
        dispatch(adminActions.getDirector(directorUserId));
        dispatch(adminActions.getDirectors());
      } else {
        dispatch(uiActions.showError("Failed to get user"));
      }
    };
  },
  createUser(values, role: Role, agencyId: number) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      //TODO: figure out if should provide all agencies or have dropdown instead of defualting to first agency id

      const payload = {
        ...values,
      };
      const url =
        isSuperAdmin(role) ||
        isSuperAgent(role) ||
        isSalesAdmin(role) ||
        isSalesDirector(role)
          ? `/users`
          : `/users/agency/${agencyId}`;

      const { status } = await authClient.post(url, payload);

      if (status === 204) {
        dispatch(uiActions.showSuccess("user added"));
        dispatch(adminActions.getUsers(role, agencyId));
        Navigation.go("/admin/users");
      } else {
        dispatch(uiActions.showError("Failed to add users"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createApplication(values, role: Role, agencyId: number) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const req_uid = uuidv4();
      const payload = {
        ...values,
        req_uid,
      };
      const url =
        isSuperAdmin(role) ||
        isSuperAgent(role) ||
        isSalesAdmin(role) ||
        isSalesDirector(role)
          ? `/create-application`
          : `/agency/${agencyId}/create-application`;

      const { status } = await authClient.post(url, payload);

      if (status === 204) {
        history.push(`/admin/quote-management/${req_uid}`);
      } else {
        dispatch(uiActions.showError("Failed to create application"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  editApplication(req_uid, values, role: Role, agencyId: number) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const payload = {
        ...values,
      };
      const url =
        isSuperAdmin(role) ||
        isSuperAgent(role) ||
        isSalesAdmin(role) ||
        isSalesDirector(role)
          ? `/edit-application/${req_uid}`
          : `/agency/${agencyId}/edit-application/${req_uid}`;

      const { status } = await authClient.put(url, payload);

      if (status === 204) {
        history.push(`/admin/quote-management/${req_uid}`);
      } else {
        dispatch(uiActions.showError("Failed to edit application"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  // updateUser(id, values, role: Role, agencyId: number) {
  //   return async (dispatch) => {
  //     // TODO: switch order of url (in swagger)
  //     const url =
  //       isSuperAdmin(role) ||
  //       isSuperAgent(role) ||
  //       isSalesAdmin(role) ||
  //       isSalesDirector(role)
  //         ? `/users/${id}`
  //         : `/users/${id}/agency/${agencyId}`;

  //     const { status } = await authClient.put(url, values);
  //     if (status === 204) {
  //       dispatch(adminActions.getUsers(role, agencyId));
  //     } else {
  //       dispatch(uiActions.showError("Failed to update user"));
  //     }
  //   };
  // },
  updateDirector(id, values) {
    return async (dispatch) => {
      const { status } = await authClient.put(`/director/${id}`, values);
      if (status === 204) {
        dispatch(adminActions.getDirector(id));
      } else {
        dispatch(uiActions.showError("Failed to update user"));
      }
    };
  },
  transferQuoteOwnership(req_uid, referral_token, role, agencyId) {
    return async (dispatch) => {
      const { status } = await authClient.put(`/quote/${req_uid}/transfer`, {
        agent: referral_token,
      });
      if (status === 204) {
        dispatch(adminActions.getQuoteForAgent(req_uid, role, agencyId));
      } else {
        dispatch(uiActions.showError("Failed to transfer quote"));
      }
    };
  },
  deleteUser(id, role: Role, agencyId: number) {
    return async (dispatch) => {
      const url =
        isSuperAdmin(role) || isSuperAgent(role)
          ? `/users/${id}`
          : `/users/${id}/agency/${agencyId}`;

      const { status } = await authClient.delete(url);

      if (status === 204) {
        dispatch(adminActions.getUsers(role, agencyId));
      } else {
        dispatch(uiActions.showError("Failed to delete user"));
      }
    };
  },
  updateUserProfile(values) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(`/users/me/profile`, values);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Profile updated"));
        dispatch(adminActions.getMyProfile());
        history.push("/admin/dashboard");
      } else {
        dispatch(uiActions.showError("Failed to update profile"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getMyProfile(): AppThunk {
    return async (dispatch, getState) => {
      const { auth } = getState();
      if (auth?.userId) {
        const { data, status } = await authClient.get(`/users/me/profile`);
        if (status === 200) {
          dispatch(authActions.setUser(data));
        }
      }
    };
  },
  refreshToken(): AppThunk {
    return async (dispatch, getState) => {
      const { auth } = getState();
      if (auth.token) {
        const { data, status } = await authClient.get(`/auth/refresh-token`, {
          headers: { Authorization: "Bearer " + auth.token },
        });
        const { user, token, roles, expiration } = data;
        if (status === 200) {
          dispatch(authActions.setAuthState(data));
          const authState: AuthState = {
            userId: user.id,
            token,
            user,
            roles,
            tokenExpiration: expiration,
          };
          createApiClient(authState);
        }
      }
    };
  },
  createCarrier(payload) {
    return async (dispatch) => {
      const { status } = await authClient.post(
        `/agency-group/${payload.agency_group}/carriers`,
        payload,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("Carrier created"));
        window.location.replace("/admin/carriers");
      } else {
        dispatch(uiActions.showError("Failed to create carrier"));
      }
    };
  },
  getCarriers(agency_group_id) {
    return async (dispatch) => {
      const { status, data } = await authClient.get(
        `/agency-group/${agency_group_id}/carriers`,
      );
      if (status === 200) {
        dispatch(adminActions.setCarriers(data));
      } else {
        dispatch(uiActions.showError("Failed to get carriers"));
      }
    };
  },
  getCarriersByAgent() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/agent/view-carriers`);
      if (status === 200) {
        dispatch(adminActions.setCarriers(data));
      } else {
        dispatch(uiActions.showError("Failed to get carriers"));
      }
    };
  },
  getAvailableCarriers(agencyId) {
    return async (dispatch) => {
      const { status, data } = await authClient.get(
        `/agency-group-carrier/${agencyId}`,
      );
      if (status === 200) {
        dispatch(adminActions.setAvailableCarriers(data));
      } else {
        dispatch(uiActions.showError("Failed to get carriers"));
      }
    };
  },
  getManualCarriers() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/manual-carriers`);
      if (status === 200) {
        dispatch(adminActions.setManualCarriers(data));
      } else {
        dispatch(uiActions.showError("Failed to get carriers"));
      }
    };
  },
  getReferralProducts() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/referral-product`);
      if (status === 200) {
        dispatch(adminActions.setProducts(data));
      } else {
        dispatch(uiActions.showError("Failed to get products"));
      }
    };
  },
  updateCarriers(id, agency_group_id, values, inEditMode = false) {
    return async (dispatch, getState: () => AppState) => {
      const {
        admin: { selectedAgencyGrp },
      } = getState();
      let promise;
      if (values.is_active !== undefined) {
        promise = authClient.put(
          `/agency-group-carrier/${agency_group_id}`,
          values,
        );
      } else {
        promise = authClient.put(`/carrier/${id}`, values);
      }
      const { status } = await promise;
      if (status === 204) {
        if (inEditMode) {
          history.push("/admin/carriers");
          return;
        }
        dispatch(adminActions.getCarriers(selectedAgencyGrp));
      } else {
        dispatch(uiActions.showError("Failed to update carrier"));
      }
    };
  },
  deleteCarriers(agency_group_carrier_id) {
    return async (dispatch) => {
      const { status } = await authClient.delete(
        `/agency-group-carrier/${agency_group_carrier_id}`,
      );
      if (status === 204) {
        dispatch(adminActions.getCarriers(1));
      } else {
        dispatch(uiActions.showError("Failed to delete carrier"));
      }
    };
  },
  addAgencyDirector(agency_id, directorIds) {
    return async (dispatch) => {
      const { status } = await authClient.post(`/agency-group/${agency_id}`, {
        directorIds,
      });
      if (status === 204) {
        dispatch(uiActions.showSuccess("Added director"));
        dispatch(adminActions.getDirectors());
      } else {
        dispatch(uiActions.showError("Failed to add director"));
      }
    };
  },
  createAgency(payload) {
    return async (dispatch) => {
      const { status } = await authClient.post(`/agency`, payload);
      if (status === 204) {
        dispatch(adminActions.getAgencies());
      } else {
        dispatch(uiActions.showError("Failed to create agency"));
      }
    };
  },
  getAgencies() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/agency`);
      if (status === 200) {
        dispatch(adminActions.setAgencies(data));
      } else {
        dispatch(uiActions.showError("Failed to get agencies"));
      }
    };
  },
  updateAgency(id, values, isSuperAdmin = false) {
    return async (dispatch) => {
      const { status } = await authClient.put(`/agency/${id}`, values);
      if (status === 204) {
        if (isSuperAdmin) {
          dispatch(adminActions.getAgencies());
        }
        dispatch(adminActions.getAgency(id));
      } else {
        dispatch(uiActions.showError("Failed to update agency"));
      }
    };
  },
  deleteAgency(id) {
    return async (dispatch) => {
      const { status } = await authClient.delete(`/agency/${id}`);
      if (status === 204) {
        dispatch(adminActions.getAgencies());
      } else {
        dispatch(uiActions.showError("Failed to delete agency"));
      }
    };
  },
  getAgency(id) {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/agency/${id}`);
      if (status === 200) {
        dispatch(adminActions.setActiveAgencyData(data));
      } else {
        dispatch(uiActions.showError("Failed to get active agency data"));
      }
    };
  },
  uploadLogo(path, file) {
    return async (dispatch) => {
      const { status, data } = await authClient.post(path, file);
      if (status === 200) {
        return data.path;
      }
      dispatch(uiActions.showError("Upload failed"));
    };
  },
  uploadDocs(req_uid, files, role, agencyId) {
    return async (dispatch) => {
      const { status } = await authClient.post(
        `/documents-upload/${req_uid}`,
        files,
      );
      if (status === 204) {
        dispatch(adminActions.getQuoteForAgent(req_uid, role, agencyId));
      } else {
        dispatch(uiActions.showError("Upload failed"));
      }
    };
  },
  download(file_path: string) {
    return async (dispatch) => {
      const { status, data } = await authClient.get(
        `/document-download?file_path=${file_path}`,
      );
      if (status === 200) {
        const link = document.createElement("a");
        link.href = data.signedUrl;
        document.body.appendChild(link);
        link.click();
      } else {
        dispatch(uiActions.showError("Failed to download document"));
        console.error("download failed", data);
      }
    };
  },
  getAgencyGroups() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/agency-group`);
      if (status === 200) {
        dispatch(adminActions.setAgencyGroups(data));
      } else {
        dispatch(uiActions.showError("Failed to get agency groups"));
      }
    };
  },
  createUserAgency(payload) {
    return async (dispatch) => {
      const { status } = await authClient.post(`/user-agency`, payload);
      if (status === 204) {
        dispatch(uiActions.showSuccess("User agency created"));
        window.location.replace("/admin/user-agencies");
      } else {
        dispatch(uiActions.showError("Failed to create user_agency"));
      }
    };
  },
  getUserAgency() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/user-agency`);
      if (status === 200) {
        dispatch(adminActions.setUserAgencies(data));
      } else {
        dispatch(uiActions.showError("Failed to get user_agencies"));
      }
    };
  },
  updateUserAgency(id, values) {
    return async (dispatch) => {
      const { status } = await authClient.put(`/user-agency/${id}`, values);
      if (status === 204) {
        dispatch(adminActions.getUserAgency());
        dispatch(uiActions.showSuccess("User agency updated"));
        window.location.replace("/admin/user-agencies");
      } else {
        dispatch(uiActions.showError("Failed to update user_agency"));
      }
    };
  },
  deleteUserAgency(id) {
    return async (dispatch) => {
      const { status } = await authClient.delete(`/user-agency/${id}`);
      if (status === 204) {
        dispatch(adminActions.getUserAgency());
      } else {
        dispatch(uiActions.showError("Failed to delete user_agency"));
      }
    };
  },
  async getCarriersPerState(agency_group_id, state, quote_type) {
    const { status, data } = await authClient.post(
      `/agency-group/${agency_group_id}/carriersperstate?State=${state}`,
      { quote_type },
    );
    if (status === 200) {
      return data;
    } else {
      console.error("getCarriersPerState failed", data);
      return {};
    }
  },
  getCarrierOptions(state, auto_home, agency_group_id) {
    return async (dispatch) => {
      const { status, data } = await authClient.get(
        `/agency-group/${agency_group_id}/active-companies?State=${state}&Type=${auto_home}`,
      );
      if (status === 200) {
        return data;
      } else {
        console.error("getOptions failed", data);
        dispatch(uiActions.showError("getCarrierOptions failed"));
        return {};
      }
    };
  },
  async getLogoPerCarrier() {
    const { status, data } = await authClient.get(`/logo-per-carrier`);
    if (status === 200) {
      return data;
    } else {
      console.error("getLogoPerCarrier failed", data);
      return {};
    }
  },
  createState(payload) {
    return async (dispatch) => {
      const { status, data } = await authClient.post(
        `/supportedstates`,
        payload,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("State added"));
        window.location.replace("/admin/states");
      } else {
        dispatch(uiActions.showError("Failed to add state"));
        console.error("createState failed", data);
        return {};
      }
    };
  },
  updateState(id, values) {
    return async (dispatch) => {
      const { status } = await authClient.put(`/supportedstates/${id}`, values);
      if (status === 204) {
        dispatch(adminActions.getStates());
      } else {
        dispatch(uiActions.showError("Failed to update state"));
      }
    };
  },
  getStates() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/supportedstates`);
      if (status === 200) {
        dispatch(adminActions.setStates(data));
        return data;
      } else {
        dispatch(uiActions.showError("Failed to get supported states"));
        console.error("getStates failed", data);
        return {};
      }
    };
  },
  async getOnlyStates() {
    const { status, data } = await authClient.get(`/states`);
    if (status === 200) {
      return data;
    } else {
      console.error("getStates failed", data);
      return {};
    }
  },
  createCoverage(payload) {
    return async (dispatch) => {
      const { status, data } = await authClient.post(`/coverage`, payload);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Coverage added"));
        history.push("/admin/coverage");
      } else {
        dispatch(uiActions.showError("Failed to add coverage"));
        console.error("createCoverage failed", data);
        return {};
      }
    };
  },
  editCoverage(payload) {
    return async (dispatch) => {
      const { id, ...rest } = payload;
      const { status, data } = await authClient.put(`/coverage/` + id, rest);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Coverage updated"));
        history.push("/admin/coverage");
      } else {
        dispatch(uiActions.showError("Failed to update coverage"));
        console.error("createCoverage failed", data);
      }
    };
  },
  getSelectedCoverage(id) {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/coverage/${id}`);
      if (status === 200) {
        dispatch(adminActions.setSelectedCoverage(data));
      } else {
        dispatch(uiActions.showError("Failed to get coverage"));
        console.error("getCoverage failed", data);
        return {};
      }
    };
  },
  getCoverage(agencyGroupId) {
    return async (dispatch) => {
      const { status, data } = await authClient.get(
        `/agency-group/${agencyGroupId}/coverage`,
      );
      if (status === 200) {
        dispatch(adminActions.setCoverage(data));
      } else {
        dispatch(uiActions.showError("Failed to get coverage"));
        console.error("getCoverage failed", data);
        return {};
      }
    };
  },
  deleteCoverage(id) {
    return async (dispatch) => {
      const { status } = await authClient.delete(`/coverage/${id}`);
      if (status === 204) {
        history.push("/admin/coverage");
        // dispatch(adminActions.getCoverage(agencyGroupId));
      } else {
        dispatch(uiActions.showError("Failed to delete coverage"));
      }
    };
  },
  createCoverageUI(payload) {
    return async (dispatch) => {
      const { status, data } = await authClient.post(`/coverage-ui`, payload);
      if (status === 204) {
        dispatch(adminActions.setCoverageUI(data));
        dispatch(uiActions.showSuccess("Coverage ui added"));
        window.location.replace("/admin/coverage-ui");
      } else {
        dispatch(uiActions.showError("Failed to add coverage ui"));
        console.error("createCoverageUI failed", data);
        return {};
      }
    };
  },
  editCoverageUI(payload) {
    return async (dispatch) => {
      const { id, ...rest } = payload;
      const { status, data } = await authClient.put(`/coverage-ui/` + id, rest);
      if (status === 204) {
        dispatch(uiActions.showSuccess("CoverageUI updated"));
        history.push("/admin/coverage-ui");
      } else {
        dispatch(uiActions.showError("Failed to update coverage-ui"));
        console.error("createCoverageUI failed", data);
      }
    };
  },
  getCoverageUI() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/coverage-ui`);
      if (status === 200) {
        dispatch(adminActions.setCoverageUI(data));
      } else {
        dispatch(uiActions.showError("Failed to get coverage-ui"));
        console.error("getCoverageUI failed", data);
        return {};
      }
    };
  },
  deleteCoverageUI(id) {
    return async (dispatch) => {
      const { status } = await authClient.delete(`/coverage-ui/${id}`);
      if (status === 204) {
        dispatch(adminActions.getCoverageUI());
      } else {
        dispatch(uiActions.showError("Failed to delete coverage-ui"));
      }
    };
  },
  createAllowedIP(payload) {
    return async (dispatch) => {
      const { status, data } = await authClient.post(`/allowed-ips`, payload);
      if (status === 204) {
        dispatch(adminActions.setAllowedIPs(data));
        dispatch(uiActions.showSuccess("IP address added"));
        window.location.replace("/admin/allowed-ips");
      } else {
        dispatch(uiActions.showError("Failed to add IP address"));
        console.error("createAllowedIP failed", data);
        return {};
      }
    };
  },
  getAllowedIPs() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/allowed-ips`);
      if (status === 200) {
        dispatch(adminActions.setAllowedIPs(data));
      } else {
        dispatch(uiActions.showError("Failed to get allowed IPs"));
        console.error("getAllowedIPs failed", data);
        return {};
      }
    };
  },
  deleteAllowedIP(id) {
    return async (dispatch) => {
      const { status } = await authClient.delete(`/allowed-ips/${id}`);
      if (status === 204) {
        dispatch(adminActions.getAllowedIPs());
      } else {
        dispatch(uiActions.showError("Failed to delete allowed ip"));
      }
    };
  },
  getApplications(
    role: Role,
    agencyId: number,
    activeAgentId: number | boolean,
  ) {
    return async (dispatch) => {
      const url =
        (isSuperAdmin(role) ||
          isSuperAgent(role) ||
          isSalesAdmin(role) ||
          isSalesDirector(role)) &&
        !activeAgentId
          ? `/recent-quotes`
          : `/recent-quotes/agency/${activeAgentId ? activeAgentId : agencyId}`;

      const { status, data } = await authClient.get(url);

      if (status === 200) {
        dispatch(adminActions.setApplications(data));
      } else {
        dispatch(uiActions.showError("Failed to get applications"));
        console.error("getApplications failed", data);
      }
    };
  },
  getAgents(role, groupId: number, agencyId) {
    return async (dispatch) => {
      const url =
        role === "super_admin"
          ? "/all-agents"
          : role === "sales_admin" || role === "sales_director"
          ? `/agencies-group/${groupId}/agents`
          : `/agency_id/${agencyId}/agents`;
      const { status, data } = await authClient.get(url);

      if (status === 200) {
        dispatch(adminActions.setAgents(data));
      } else {
        dispatch(uiActions.showError("Failed to get agents"));
        console.error("getAgents failed", data);
      }
    };
  },
  getReferringAgents() {
    return async (dispatch) => {
      const url = `/referring-agents`;
      const { status, data } = await authClient.get(url);

      if (status === 200) {
        dispatch(adminActions.setReferralAgents(data));
      } else {
        dispatch(uiActions.showError("Failed to get referral agents"));
        console.error("getReferringAgents failed", data);
      }
    };
  },
  assignQuoteReferrer(
    req_uid: string,
    affiliateToken: string,
    role: Role | undefined,
    agencyId: number | undefined,
  ) {
    return async (dispatch) => {
      const url = `/quote/${req_uid}/assign-referrer`;
      const { status, data } = await authClient.put(url, { affiliateToken });
      if (status === 204) {
        dispatch(uiActions.showSuccess("Referrer set"));
        dispatch(adminActions.getQuoteForAgent(req_uid, role, agencyId));
      } else {
        dispatch(uiActions.showError("Failed to get set referrer"));
        console.error("assignQuoteReferrer failed", data);
      }
    };
  },
  createProduct(payload) {
    return async (dispatch) => {
      const { status, data } = await authClient.post(`/referral-product`, {
        ...payload,
      });
      if (status === 204) {
        dispatch(uiActions.showSuccess("Success"));
        history.push("/admin/referral-products");
      } else {
        dispatch(uiActions.showError("Failed to add product"));
        console.error("createProduct failed", data);
        return {};
      }
    };
  },
  updateProduct(id, values) {
    return async (dispatch) => {
      const { status } = await authClient.put(
        `/referral-product/${id}`,
        values,
      );
      if (status === 204) {
        dispatch(adminActions.getReferralProducts());
        dispatch(uiActions.showSuccess("Success"));
        if (window.location.pathname !== "/admin/referral-products") {
          history.push("/admin/referral-products");
        }
      } else {
        dispatch(uiActions.showError("Failed to update"));
      }
    };
  },
  getReferrals(
    role: Role | undefined,
    agencyId: number | undefined,
    ownerUserId,
  ) {
    return async (dispatch) => {
      const url =
        isSuperAdmin(role) ||
        isSuperAgent(role) ||
        isSalesAdmin(role) ||
        isSalesDirector(role)
          ? `/recent-referrals`
          : agencyId
          ? `/agency/${agencyId}/recent-referrals`
          : `/owner/${ownerUserId}/recent-referrals`;

      const { status, data } = await authClient.get(url);

      if (status === 200) {
        dispatch(adminActions.setReferrals(data));
      } else {
        dispatch(uiActions.showError("Failed to get referrals"));
        console.error("getReferrals failed", data);
      }
    };
  },
  getReferralData(
    referralId: number,
    role: Role | undefined,
    agencyId: number | undefined,
    ownerUserId: number | undefined,
  ): AppThunk {
    return async (dispatch) => {
      if (!role) {
        throw new Error("Role needed to retrieve referral for agent");
      }
      const url = hasDirectorPermissions(role)
        ? `/referral/${referralId}/data`
        : agencyId
        ? `/agency/${agencyId}/referral/${referralId}/data`
        : `/owner/${ownerUserId}/referral/${referralId}/data`;
      const { data, status } = await authClient.get(url);
      if (status === 200) {
        dispatch(actions.setReferralData(data));
      } else {
        dispatch(uiActions.showError("Failed to get referral"));
        console.error("createCoverageUI failed", data);
      }
    };
  },
  updateReferralStatus(
    referralId: number,
    referralStatus: Status,
    role: Role | undefined,
    agencyId: number | undefined,
    ownerUserId: number | undefined,
  ): AppThunk {
    return async (dispatch) => {
      if (!role) {
        throw new Error("Role needed to retrieve quotes for agent");
      }
      const url = hasDirectorPermissions(role)
        ? `/update-referral/${referralId}`
        : agencyId
        ? `/agency/${agencyId}/update-referral/${referralId}`
        : `/owner/${ownerUserId}/update-referral/${referralId}`;
      const { status, data } = await authClient.put(url, { referralStatus });
      if (status === 204) {
        dispatch(uiActions.showSuccess("Status updated"));
      } else {
        dispatch(uiActions.showError("Failed to update status"));
        console.error("createCoverageUI failed", data);
      }
    };
  },
  submitReferralActivity(
    referralId: number,
    values,
    role: Role | undefined,
    agencyId: number | undefined,
    ownerUserId: number | undefined,
  ): AppThunk {
    return async (dispatch) => {
      if (!role) {
        throw new Error("Role needed to submit activity");
      }
      const url = hasDirectorPermissions(role)
        ? `/add-referral-activity/${referralId}`
        : agencyId
        ? `/agency/${agencyId}/add-referral-activity/${referralId}`
        : `/owner/${ownerUserId}/add-referral-activity/${referralId}`;
      const { status, data } = await authClient.post(url, { ...values });
      if (status === 204) {
        dispatch(uiActions.showSuccess("Added activity"));
        dispatch(
          adminActions.getReferralData(referralId, role, agencyId, ownerUserId),
        );
      } else {
        dispatch(uiActions.showError("Failed to add activity"));
        console.error("addActivity failed", data);
      }
    };
  },
  postUserNote(
    agentId: number,
    note: string,
    agencyId: number,
    role: Role,
  ): AppThunk {
    return async (dispatch) => {
      const url = hasDirectorPermissions(role)
        ? `/user/${agentId}/add-note`
        : `/agent/${agentId}/add-note`;
      const { status, data } = await authClient.post(url, { note });
      if (status === 204) {
        dispatch(adminActions.getUsers(role, agencyId));
      } else {
        dispatch(uiActions.showError("Failed to add note"));
        console.error("postUserNote failed", data);
      }
    };
  },
  postQuoteComment(
    quoteId: string,
    role: Role | undefined,
    agencyId: number | undefined,
    comment,
  ): AppThunk {
    return async (dispatch) => {
      if (!role) {
        throw new Error("Role needed to add comment for agent");
      }
      const url = hasDirectorPermissions(role)
        ? `/add-comment/${quoteId}`
        : `/agency/${agencyId}/add-comment/${quoteId}`;
      const { status, data } = await authClient.put(url, { comment });
      if (status === 204) {
        dispatch(adminActions.getQuoteForAgent(quoteId, role, agencyId));
      } else {
        dispatch(uiActions.showError("Failed to add comment"));
        console.error("postQuoteComment failed", data);
      }
    };
  },
  getUDDRequests(role: Role, agencyId: number | undefined) {
    return async (dispatch) => {
      const url =
        isSuperAdmin(role) ||
        isSalesAdmin(role) ||
        isSalesDirector(role) ||
        isSalesAdmin(role)
          ? `/udd-requests`
          : `/udd-requests/agency/${agencyId}`;

      const { status, data } = await authClient.get(url);

      if (status === 200) {
        dispatch(adminActions.setUDDRequests(data));
      } else {
        dispatch(uiActions.showError("Failed to get UDD requests"));
        console.error("getUDDRequests failed", data);
      }
    };
  },
  getReports() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/reports`);
      if (status === 200) {
        dispatch(adminActions.setReports(data));
      } else {
        dispatch(uiActions.showError("Failed to get reports data"));
        console.error("Failed to get reports data", data);
      }
    };
  },
  getReportById(id) {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/reports/` + id);
      if (status === 200) {
        return data;
      } else {
        dispatch(uiActions.showError("Failed to get reports data"));
        console.error("Failed to get reports data", data);
        return { data: {}, name: "" };
      }
    };
  },
  /**GSuite start */
  getOAuthRedirect(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.get(`/google/oauth-redirect`);
      if (status === 200) {
        window.location.href = data.oauthRedirectUrl;
        dispatch(uiActions.showSuccess("Hang tight"));
      } else {
        dispatch(uiActions.showError("failed to authenticate"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getOAuthTokens(params): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.post(
        `/google/oauth-code`,
        params,
      );
      if (status === 200) {
        dispatch(uiActions.showSuccess("Success"));
        dispatch(actions.setOAuthAccessToken(data.hasAccessToken));
        dispatch(adminActions.checkGoogleAuthStatus());
      } else {
        dispatch(uiActions.showError("failed to getOAuthTokens"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  checkGoogleAuthStatus(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.get(`/google/oauth-status`);
      if (status === 200) {
        dispatch(actions.setOAuthAccessToken(data.hasAccessToken));
      } else {
        dispatch(uiActions.showError("failed to get Google status"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
};
