import { sub } from "date-fns";
import { predefinedDriverFields, predefinedQuoteFields } from "../constants";
import { formatStringToDate, formatToDate } from "./date";
import { DriverShape } from "../state/auto/types";

const nonDigit = /\D/gim;
const regexOne = /^1/gim;

export const mapQuoteValues = function (values) {
  const {
    startForm: { address },
    vehiclesForm,
    contact,
    driversForm,
    discountsForm,
    is_linked,
  } = values;

  const mapViolations = (driver) => {
    // eslint-disable-next-line
    const violations = new Array();
    //TODO: update violation dates instead of random dates below
    if (driver.accidents) {
      if (driver.faultAccidents) {
        violations.push({
          violation_type: 13,
          violation_date: formatToDate(sub(new Date(), { years: 1 })),
        });
      } else {
        violations.push({
          violation_type: 59,
          violation_date: formatToDate(sub(new Date(), { years: 1 })),
        });
      }
      if (driver.tickets && driver.tickets?.length) {
        // eslint-disable-next-line
        driver.tickets.map((ticket) => {
          let violation_code = 0; //speeding vs unsafe speed
          ticket?.speed === 1 || ticket?.speed === 2
            ? (violation_code = 79)
            : (violation_code = 80);

          violations.push({
            violation_type: violation_code,
            violation_date: formatStringToDate(ticket.date),
          });
        });
      }
    }
    if (driver.collision) {
      violations.push({
        violation_type: 138,
        violation_date: formatToDate(sub(new Date(), { years: 1 })),
      });
    }
    if (driver.dwi) {
      violations.push({
        violation_type: 5,
        violation_date: formatToDate(sub(new Date(), { years: 1 })),
      });
    }
    return violations;
  };
  const drivers = driversForm.selectedDrivers.map((driver, i) => ({
    ...predefinedDriverFields,
    first_name: driver.FirstName,
    last_name: driver.LastName,
    middle_name: driver.MiddleName,
    gender: driver.gender,
    dob: formatStringToDate(driver.dob),
    marital_status: driver.marital_status || "M",
    occupation_class: driver.occupation_class,
    employment_military: discountsForm?.employment_military,
    education_level: driver.education_level,
    manualLicense: driver.manualLicense,
    veh_id:
      discountsForm.whoDrives[i]?.veh_id ||
      driversForm.selectedDrivers.length === 1
        ? 1
        : 0,
    violations: mapViolations(driver),
    relationship_to_applicant: driver.relationship_to_applicant,
  }));
  const vehicles = vehiclesForm?.selectedVehicles?.map((vehicle, i) => ({
    model_year: vehicle.ModelYear,
    make: vehicle.Maker,
    model: vehicle.Model,
    body_type: vehicle.Trim,
    vin: vehicle.VIN,
    usage: vehicle.usage,
    pip_type: "P",
    driver_id:
      discountsForm &&
      discountsForm?.whoDrives[i] &&
      discountsForm?.whoDrives[i].driver_id
        ? discountsForm?.whoDrives[i].driver_id
        : i,
    leased_vehicle:
      vehicle.Type === "own" || vehicle.Type === "finance" ? 0 : 1,
    non_owned_indicator: vehicle.Type === "own" ? 0 : 1,
    miles: vehicle.miles,
  }));
  const bi = { perPerson: 100000, perAcc: 300000 };

  const effectiveDate =
    new Date(discountsForm.effectiveDate) > new Date()
      ? discountsForm.effectiveDate
      : new Date();

  const applicant: DriverShape =
    driversForm.applicantIndex >= 0
      ? driversForm.selectedDrivers[driversForm.applicantIndex]
      : driversForm.selectedDrivers[0]; //backwards compatible
  return {
    ...predefinedQuoteFields,
    custom_quote: false,
    rate_state: address.state,
    quote_type: "auto",
    is_linked: is_linked || false,
    effective_date: formatStringToDate(effectiveDate),
    applicant: {
      dob: formatStringToDate(applicant.dob),
      first_name: applicant.FirstName,
      gender: applicant.gender,
      middle_name: applicant.MiddleName,
      last_name: applicant.LastName,
      zip: address.zipcode,
      addr1: address.street_line,
      city: address.city,
      state: address.state,
      residence_owned_rented: discountsForm
        ? discountsForm.residence_owned_rented
        : "O",
      paperless_discount: discountsForm?.paperless_discount,
      email: contact.email,
      phone: contact.phone
        ?.replace(nonDigit, "")
        ?.replace(regexOne, "")
        ?.replace(/\s/g, ""),
    },
    drivers,
    vehicles,

    previous_ins: {
      expiration: formatStringToDate(effectiveDate),
      months_coverage: discountsForm
        ? Number(discountsForm.months_coverage)
        : 0,
      ...(Object.keys(bi).length ? { bi } : {}),
      continuous_insurance: applicant.continuous_insurance,
      no_insurance_reason: applicant.no_insurance_reason,
    },
  };
};

export const mapCustomQuoteValues = function (values) {
  const {
    pd,
    bi,
    um,
    undum,
    umpd,
    umpdded,
    undpd,
    medex,
    pip,
    pipded,
    attendantcare,
    medicalexpenseonly,
    extendedmedical,
    obel,
    tort,
    vehicles,
    umpdEnabled,
    medEnabled,
    pipEnabled,
  } = values;

  const quoteValues = mapQuoteValues(values);

  const bodilyInjuryProtectionValue = {
    perPerson: bi ? Number(JSON.parse(bi)?.Val1) : 0,
    perAcc: bi ? Number(JSON.parse(bi)?.Val2) : 0,
  };
  const pdLiabilityValue = {
    limit: pd ? Number(JSON.parse(pd).Val1) : 0,
  };
  const medexValue = {
    limit: medEnabled && medex ? Number(JSON.parse(medex).Val1) : 0,
  };
  const uninsuredMotoristValue = {
    perPerson: um ? Number(JSON.parse(um).Val1) : 0,
    perAcc: um ? Number(JSON.parse(um).Val2) : 0,
  };
  const underinsuredMotoristValue = {
    perPerson: undum ? Number(JSON.parse(undum).Val1) : 0,
    perAcc: undum ? Number(JSON.parse(undum).Val2) : 0,
  };
  const uninsuredMotoristPDValue = {
    limit: umpdEnabled && umpd ? Number(JSON.parse(umpd).Val1) : 0,
    deductible: umpdEnabled && umpdded ? Number(JSON.parse(umpdded).Val1) : 0,
  };
  const underinsuredMotoristPDValue = {
    limit: undpd ? Number(JSON.parse(undpd).Val1) : 0,
  };
  const pipValue = {
    limit: pipEnabled && pip ? Number(JSON.parse(pip).Val1) : 0,
    deductible: pipded ? Number(JSON.parse(pipded).Val1) : 0,
  };
  const attendantCareValue = {
    limit: attendantcare ? Number(JSON.parse(attendantcare).Val1) : 0,
  };
  const extendedMedicalValue = {
    limit: extendedmedical ? Number(JSON.parse(extendedmedical).Val1) : 0,
  };
  const obelValue = {
    limit: obel ? Number(JSON.parse(obel).Val1) : 0,
  };
  const medicalExpenseOnlyValue = medicalexpenseonly
    ? JSON.parse(medicalexpenseonly).Val1
    : "";

  const tortValue = tort ? JSON.parse(tort).Val1 : "";

  const customQuoteVehicles = quoteValues.vehicles.map(
    (customQuoteVehicle, i) => {
      return {
        ...customQuoteVehicle,
        coverage: {
          bi: bodilyInjuryProtectionValue,
          pd: pdLiabilityValue,
          medex: medexValue,
          pip: pipValue,
          attendantcare: attendantCareValue,
          medicalexpenseonly: medicalExpenseOnlyValue,
          extendedmedical: extendedMedicalValue,
          obel: obelValue,
          tort: tortValue,
          um: uninsuredMotoristValue,
          undum: underinsuredMotoristValue,
          umpd: uninsuredMotoristPDValue,
          undpd: underinsuredMotoristPDValue,

          ...vehicles.map((vehicle) => {
            const roadsideAssistanceValue =
              vehicle.towingEnabled &&
              vehicle.towing &&
              JSON.parse(vehicle.towing).Val1 === "Y"
                ? 40
                : 0;
            const rentalCarCoverageValue =
              vehicle.rentalEnabled && vehicle.rental
                ? Number(JSON.parse(vehicle.rental).Val1)
                : 0;

            return {
              coll: {
                deductible: vehicle.collEnabled
                  ? Number(JSON.parse(vehicle.coll).Val1)
                  : 0,
              },
              comp: {
                deductible: vehicle.compEnabled
                  ? Number(JSON.parse(vehicle.comp).Val1)
                  : 0,
              },
              towing: {
                limit: roadsideAssistanceValue,
              },
              rental: {
                limit: rentalCarCoverageValue,
              },
            };
          })[i],
        },
      };
    },
  );
  return {
    ...quoteValues,
    vehicles: customQuoteVehicles,
    custom_quote: true,
  };
};
