import React from "react";

import Alert from '@mui/material/Alert';
import { IconButton, Snackbar } from "@mui/material";

import { CloseIcon } from "../../components/icons";

import { uiActions, uiSelectors, useAction, useSelector } from "../../state";

const vertical = "top";
const horizontal = "center";

const MainSnackbar = () => {
  const notification = useSelector(uiSelectors.notification);
  const hideNotification = useAction(uiActions.hideNotification);

  return (
    <Snackbar
      open={!!notification}
      onClose={hideNotification}
      autoHideDuration={notification?.duration}
      message={notification?.message}
      anchorOrigin={{ vertical, horizontal }}
    >
      {notification?.variant && (
        <Alert
          severity={notification.variant}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={hideNotification}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {notification.message}
        </Alert>
      )}
    </Snackbar>
  );
};

export default React.memo(MainSnackbar);
