import React, { useCallback, useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";

import { VisibilityIcon, VisibilityOffIcon } from "../../icons";

const PasswordField = ({
  name,
  label,
  value,
  handleChange,
  touched,
  error,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    [],
  );

  return (
    <TextField
      fullWidth
      id={name}
      name={name}
      label={label}
      type={showPassword ? "text" : "password"}
      value={value}
      onChange={handleChange}
      error={touched && Boolean(error)}
      helperText={touched && error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              size="large">
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default React.memo(PasswordField);
