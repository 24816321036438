import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  helpText: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 12,
    textAlign: "right",
    position: "absolute",
    top: 56,
    right: 40,
    zIndex: 3,
    "& a": {
      color: "inherit",
      fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      top: 24,
    },
  },
  mobileHeader: {
    borderBottom: "1px solid #DADFF6",
    display: "flex",
    padding: 16,
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: 2,
  },
  drawerButton: {
    position: "relative",
    zIndex: 99,
  },
  container: {
    height: "100%",
  },
  payPageContent: {
    width: "calc(100% - 280px)",
    overflow: "auto",
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: 64,
    [theme.breakpoints.down('md')]: {
      width: "calc(100vw - 160px)",
      margin: "0 auto",
    },
    [theme.breakpoints.down('sm')]: {
      position: "relative",
      width: "100%",
      padding: 16,
      overflowX: "hidden",
      minHeight: "calc(100vh - 28px)",
      marginTop: -28,
    },
  },
  selectedQuoteWrap: {
    marginTop: 24,
    display: "flex",
    justifyContent: "space-evenly",
    maxWidth: "90%",
    gap: 32,
    alignItems: "flex-start",
    [theme.breakpoints.down('sm')]: {
      display: "block",
    },
  },
  quoteCardContainer: {
    position: "sticky",
    top: 24,
  },
}));
