import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  list: {
    width: "auto",
  },
  fullList: {
    width: "auto",
  },
  button: {
    width: 40,
    minWidth: 40,
    height: 40,
    color: "white",
    backgroundColor: "#0033ff",
  },
  listItem: {
    color: "#000C3B",
    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  wrapper: { padding: 30 },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      marginRight: 10,
      verticalAlign: "middle",
    },
    "& .MuiTypography-root": {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  rightDrawer: {
    width: "100%",
    "& .MuiDrawer-paper": {
      width: "100%",
      minHeight: "40%",
      height: "auto",
      bottom: 0,
      top: "auto",
    },
  },
  addButton: {
    height: 21,
    width: 21,
    backgroundColor: "#0033ff",
    minWidth: 21,
    "& .MuiSvgIcon-root": {
      color: "white",
      margin: 0,
    },
  },
}));
