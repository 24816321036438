import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  mobileHeader: {
    color: "#DADFF6",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid #DADFF6`,
    height: 56,
  },
  drawerButton: {
    position: "relative",
    zIndex: 99,
  },
  mobileSidebarContainer: {
    "& .MuiDrawer-paper": {
      width: 280,
      height: "100vh",
    },
  },
  drawerBottom: {
    height: "calc(100vh - 128px)",
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    height: 128,
    paddingLeft: 40,
    paddingRight: 40,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      height: 96,
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  headerContent: {
    height: "100%",
  },
  logoWrapper: {
    "& img": {
      width: "auto",
      height: 64,
      [theme.breakpoints.down('md')]: {
        height: 48,
      },
    },
  },
  helpTextLight: {
    fontSize: 14,
  },
  phoneLink: {
    fontWeight: 600,
    textDecoration: "none",
    color: "#000",
  },
  mainContent: {
    height: "calc(100vh - 128px)",
    display: "flex",
    [theme.breakpoints.down('md')]: {
      height: "calc(100vh - 96px)",
    },
    [theme.breakpoints.down('sm')]: {
      height: "auto",
      maxWidth: "100vw",
      position: "relative",
    },
  },
  backdrop: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 280,
    zIndex: theme.zIndex.modal + 200,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    textAlign: "center",
    [theme.breakpoints.down('md')]: {
      paddingLeft: 160,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
}));
