import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Popper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  adminActions,
  adminSelectors,
  authSelectors,
  uiSelectors,
  useAction,
  useDispatch,
  useSelector,
} from "../../state";
import { useStyles } from "./DashboardPage.styles";
import { DATE_INTERVALS } from "../../state/auto/types";
import { useMobile } from "../../themes";
import {
  StarIcon,
  DescriptionIcon,
  VisibilityIcon,
  MoreVertIcon,
  NotificationsIcon,
  EditIcon,
  InsertInvitationIcon,
  AddIcon,
  AccessAlarmsIcon,
  CheckCircleOutlineIcon,
  ChevronRightIcon,
  AccessTimeIcon,
  ChevronLeftIcon,
  ExpandMoreIcon,
  ArrowForwardIcon,
  LaunchIcon,
  FiberManualRecordIcon,
} from "../../components";
import { useLocation, useHistory } from "react-router-dom";
import Carousel from "react-multi-carousel";
import {
  add,
  differenceInCalendarDays,
  format,
  parseISO,
  subDays,
} from "date-fns";

import { AppStatsItems } from "./components/AppStatsItems";
import { AgentStatsItems } from "./components/AgentStatsItems";
import GoogleIcon from "../../components/icons/GoogleIcon";
import { DateSelectField } from "./components/DateSelectField";
import { REACT_APP_ASSETS_URL } from "../../config";
import { EventItem, responsive } from "../../state/admin/types";
import { getDay, invalidDates } from "../../utils";
import { DatePicker } from "@mui/x-date-pickers";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      style={{ width: "100%" }}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

const DashboardPage = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const { statusStats, conversionRate, totalSales } = useSelector(
    adminSelectors.agentStats,
  );
  const referralToken = useSelector(authSelectors.referralToken);

  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const openTaskModal = useAction(adminActions.setTaskModalOpen);
  const setTaskModalData = useAction(adminActions.setTaskModalData);
  const classes = useStyles();
  const isMobile = useMobile();
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const hasOAuthAccessToken = useSelector(adminSelectors.hasOAuthAccessToken);
  const user = useSelector(authSelectors.user);
  const calendars = useSelector(adminSelectors.googleCalendars);
  const events = useSelector(adminSelectors.events);
  const alerts = useSelector(adminSelectors.alerts);
  const loading = useSelector(uiSelectors.loading);

  const [error, setError] = useState<boolean>(false);
  const today = new Date();
  const defaultEndDate = add(new Date(), { days: 6 });
  const [startDate, setStartDate] = useState<any>(today);
  const [endDate, setEndDate] = useState<any>(new Date(defaultEndDate));

  const [showStartPicker, setShowStartPicker] = useState(false);
  const [showEndPicker, setShowEndPicker] = useState(false);
  const [displayedCalendars, setDisplayedCalendars] = useState({});
  const [displayedEvents, setDisplayedEvents] = useState<EventItem[]>([]);
  const [colors, setColors] = useState<any>([]);
  const [amounts, setAmounts] = useState<any>();
  const [amountBound, setAmountBound] = useState<any>();
  const [totalApps, setTotalApps] = useState(0);
  const [taskId, setTaskId] = useState<any>();
  const [value, setValue] = useState<any>(0);
  const [startValue, setStartValue] = useState(DATE_INTERVALS[1].value);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [calendarEl, setCalendarEl] = useState<null | HTMLElement>(null);
  const hours = new Date().getHours();
  const domain = window.location.origin;

  const handleChange = (i, newValue) => {
    setValue(newValue);
  };

  const handleCalendarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayedCalendars({
      ...displayedCalendars,
      [event.target.name]: event.target.checked,
    });
  };

  const setDates = (val) => {
    const dif =
      differenceInCalendarDays(new Date(endDate), new Date(startDate)) + 1;

    if (val === "forward") {
      setStartDate(add(new Date(startDate), { days: dif }));
      setEndDate(add(new Date(endDate), { days: dif }));
    } else {
      setStartDate(subDays(new Date(startDate), dif));
      setEndDate(subDays(new Date(endDate), dif));
    }
  };

  const PopperInstance = (props) => {
    const { id, user_id } = props.task;
    return (
      <Popper
        id={"id"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"bottom-end"}
      >
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
            setTaskId(undefined);
          }}
        >
          <Box className={classes.popper}>
            <Box
              className={classes.popperContent}
              onClick={() => {
                setTaskModalData({
                  snooze: true,
                  id,
                  user_id,
                });
                openTaskModal(true);
                setAnchorEl(null);
              }}
            >
              <AccessAlarmsIcon /> <Typography>Snooze</Typography>
            </Box>{" "}
            <Box
              className={classes.popperContent}
              onClick={() => {
                setTaskModalData({
                  complete: true,
                  id,
                  user_id,
                });
                openTaskModal(true);
                setAnchorEl(null);
              }}
            >
              <CheckCircleOutlineIcon /> <Typography>Complete</Typography>
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    );
  };
  const MobilePopper = (props) => {
    const event = props.event;
    return (
      <Popper
        id={"id"}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement={"left-start"}
      >
        <ClickAwayListener
          onClickAway={() => {
            setAnchorEl(null);
            setTaskId(undefined);
          }}
        >
          <Box className={classes.popper}>
            <Box
              className={classes.popperContent}
              onClick={() => {
                setTaskModalData({
                  ...event,
                  start: event.startTime,
                  end: event.endTime,
                  event_id: event.eventId,
                });
                openTaskModal(true);
              }}
            >
              <EditIcon /> <Typography>Edit</Typography>
            </Box>{" "}
            <Box className={classes.popperContent}>
              <a href={event.link} target="_blank" rel="noreferrer">
                <span>
                  {" "}
                  <InsertInvitationIcon />
                </span>
                <Typography>View in calendar</Typography>{" "}
              </a>{" "}
            </Box>
            {event.appUrl && (
              <Box className={classes.popperContent}>
                <a href={event.appUrl} target="_blank" rel="noreferrer">
                  <span>
                    {" "}
                    <DescriptionIcon />
                  </span>
                  <Typography>Go to application</Typography>{" "}
                </a>{" "}
              </Box>
            )}
          </Box>
        </ClickAwayListener>
      </Popper>
    );
  };

  let statusesAmount;

  useEffect(() => {
    const totalApplications = statusStats.reduce((prev, curr) => {
      return prev + curr.statusCount;
    }, 0);
    const boundAmount = statusStats.find(
      (s) => s.fullStatus === "Closed - Won",
    )?.statusCount;
    setAmountBound(boundAmount);
    setTotalApps(totalApplications);
    statusesAmount = statusStats?.reduce(
      (acc, curr) => {
        const currCount = acc[curr.fullStatus?.split(" -")[0]] || 0;
        return {
          ...acc,
          [curr.fullStatus?.split(" -")[0]]: currCount + curr.statusCount,
        };
      },
      { ["New"]: 0, "In progress": 0, Pending: 0, Closed: 0 },
    );
    setAmounts(statusesAmount);
  }, [statusStats]);

  useEffect(() => {
    if (referralToken) {
      dispatch(
        adminActions.getAgentStats(
          activeRole,
          agencyId,
          [referralToken],
          startValue,
        ),
      );
    }
  }, [referralToken, startValue]);

  // google auth
  useEffect(() => {
    if (code) {
      dispatch(adminActions.getOAuthTokens({ code }));
      queryParams.delete("code");
      history.replace({
        search: "",
      });
    }
  }, [code]);

  useEffect(() => {
    dispatch(adminActions.checkGoogleAuthStatus());
    dispatch(adminActions.getAlerts());
  }, []);

  useEffect(() => {
    if (invalidDates(startDate, endDate, setError)) return;
    if (hasOAuthAccessToken) {
      dispatch(adminActions.getEvents(startDate, endDate));
    }
  }, [hasOAuthAccessToken, startDate, endDate]);

  useEffect(() => {
    if (hasOAuthAccessToken) {
      dispatch(adminActions.getCalendars());
    }
  }, [hasOAuthAccessToken]);

  useEffect(() => {
    const c = calendars.reduce((a, c) => ({ ...a, [c.id]: c.selected }), {});
    setDisplayedCalendars(c);
  }, [calendars]);

  useEffect(() => {
    setDisplayedEvents(
      events.filter((e) => !!displayedCalendars[e.calendarId]),
    );
    const calendarColors = calendars.map((c) => ({ [c.id]: c.color }));
    setColors(calendarColors);
  }, [displayedCalendars, events]);

  const handleDateChange = (date, interval) => {
    setShowStartPicker(false);
    setShowEndPicker(false);
    if (interval === "start") {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  return (
    <div className={classes.container}>
      <Box>
        <Typography variant="h3" className={classes.greeting}>
          Good {hours > 12 ? "afternoon" : hours > 18 ? "evening" : "morning"},{" "}
          {user.first_name ? user.first_name : ""}
        </Typography>
        <Typography className={classes.date}>
          <StarIcon />
          Agent since {new Date(user.created_at).toLocaleDateString()}
        </Typography>
        <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
            value={value}
            aria-label="simple tabs example"
            variant={isMobile ? "fullWidth" : "standard"}
            textColor="primary"
            onChange={handleChange}
          >
            <Tab label="Tasks" />
            <Tab label="Applications" />
            <Tab label="Stats" />
          </Tabs>
        </AppBar>
      </Box>
      <TabPanel value={value} index={0}>
        {!!alerts.length && (
          <Box className={classes.taskHeader}>
            <Box className={classes.mobileAlert}>
              <Typography className={classes.headers}>Alerts</Typography>
              {isMobile && (
                <Box className={classes.alertAmount}>{alerts.length}</Box>
              )}
            </Box>
            <Button
              variant="contained"
              color="primary"
              size={!isMobile ? "large" : "small"}
              onClick={() => openTaskModal(true)}
              startIcon={!isMobile && <AddIcon />}
              className={classes.button}
            >
              {isMobile ? <AddIcon /> : "Task"}
            </Button>
          </Box>
        )}
        {!!alerts.length && isMobile ? (
          <Box className={classes.mobileTasks}>
            {alerts.map((t) => {
              return (
                <Accordion key={t.id} className={classes.accordion}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <NotificationsIcon /> <Typography>{t.summary}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{t.description}</Typography>
                    <Box className={classes.mobileDate}>
                      <InsertInvitationIcon /> <span> {getDay(t.start)}</span>
                    </Box>
                    <Box className={classes.accordActions}>
                      <Box>
                        <span
                          onClick={() => {
                            setTaskModalData({
                              snooze: true,
                              id: t.id,
                              user_id: t.user_id,
                            });
                            openTaskModal(true);
                          }}
                        >
                          {" "}
                          <AccessAlarmsIcon />
                        </span>
                        <span
                          onClick={() => {
                            setTaskModalData({
                              complete: true,
                              id: t.id,
                              user_id: t.user_id,
                            });
                            openTaskModal(true);
                          }}
                        >
                          {" "}
                          <CheckCircleOutlineIcon />
                        </span>
                        <span
                          onClick={() => {
                            setTaskModalData(t);
                            openTaskModal(true);
                          }}
                        >
                          {" "}
                          <EditIcon />
                        </span>
                      </Box>{" "}
                      {t.req_uid && (
                        <Box>
                          <a
                            href={`${domain}/admin/quote-management/${t.req_uid}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button>
                              Application <ArrowForwardIcon />
                            </Button>
                          </a>
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        ) : (
          <Carousel
            key={"a"}
            containerClass={classes.carousel}
            centerMode={true}
            slidesToSlide={2}
            responsive={responsive}
            shouldResetAutoplay={false}
            autoPlay={false}
            arrows={!isMobile}
            renderButtonGroupOutside={isMobile}
          >
            {alerts.map((t) => {
              return (
                <Box className={classes.alertItem} key={t.id}>
                  <Box className={classes.taskDate}>
                    <span>
                      <NotificationsIcon />
                      <span> {getDay(t.start)}</span>
                    </span>{" "}
                    <div
                      onClick={(e) => {
                        setAnchorEl(anchorEl ? null : e.currentTarget);
                        setTaskId(t.id);
                      }}
                    >
                      <MoreVertIcon />
                    </div>
                    {anchorEl && taskId === t.id && <PopperInstance task={t} />}
                  </Box>
                  <Typography> {t.summary}</Typography>
                  <Box className={classes.description}>{t.description}</Box>
                  <Box>
                    {t.req_uid ? (
                      <a
                        href={`${domain}/admin/quote-management/${t.req_uid}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          <DescriptionIcon />
                        </span>
                      </a>
                    ) : (
                      <span
                        onClick={() => {
                          setTaskModalData(t);
                          openTaskModal(true);
                        }}
                      >
                        <VisibilityIcon />
                      </span>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Carousel>
        )}
        <div>
          <Box className={classes.controls}>
            <Typography className={classes.headers}>My agenda</Typography>
          </Box>

          {!!hasOAuthAccessToken && (
            <>
              <Box className={classes.controlsWrapper}>
                <Box className={classes.controlsWrapper}>
                  <div>
                    <span className={classes.dateControls}>
                      <div onClick={() => setDates("back")}>
                        {" "}
                        <ChevronLeftIcon />
                      </div>
                      <div onClick={() => setDates("forward")}>
                        {" "}
                        <ChevronRightIcon />
                      </div>
                      {"  "}
                      <div>
                        {" "}
                        <Typography
                          onClick={() => {
                            setShowStartPicker(true);
                          }}
                        >
                          {format(startDate, "PP").split(",")[0]}
                        </Typography>
                        &nbsp; - &nbsp;
                        <Typography
                          onClick={() => {
                            setShowEndPicker(true);
                          }}
                        >
                          {" "}
                          {format(endDate, "PP").split(",")[0]}
                        </Typography>{" "}
                      </div>
                      <DatePicker
                        open={showStartPicker}
                        format="MM/dd/yyyy"
                        value={startDate}
                        onChange={(date) => handleDateChange(date, "start")}
                      />
                      <DatePicker
                        open={showEndPicker}
                        format="MM/dd/yyyy"
                        value={endDate}
                        onChange={(date) => handleDateChange(date, "end")}
                      />
                    </span>{" "}
                    {error && (
                      <ClickAwayListener
                        onClickAway={() => {
                          if (!invalidDates(startDate, endDate, setError)) {
                            setError(false);
                          }
                        }}
                      >
                        <Typography color="error">
                          End date cannot be before start date
                        </Typography>
                      </ClickAwayListener>
                    )}
                  </div>
                  {isMobile ? (
                    <div
                      className={classes.mobileCalIcon}
                      onClick={(e) => {
                        setCalendarEl(calendarEl ? null : e.currentTarget);
                      }}
                    >
                      {" "}
                      <InsertInvitationIcon />
                    </div>
                  ) : (
                    <Box
                      className={classes.calendars}
                      onClick={(e) => {
                        setCalendarEl(calendarEl ? null : e.currentTarget);
                      }}
                    >
                      <InsertInvitationIcon />
                      <Typography>My Calendars</Typography>
                      <ExpandMoreIcon />
                    </Box>
                  )}
                </Box>
                <Popper
                  id={"calendarEl"}
                  open={Boolean(calendarEl)}
                  anchorEl={calendarEl}
                  placement={"bottom-end"}
                  disablePortal
                  style={{ zIndex: 10000 }}
                >
                  <ClickAwayListener
                    onClickAway={() => {
                      setCalendarEl(null);
                    }}
                  >
                    <Box className={classes.calendarPopper}>
                      <Typography>Show/Hide Calendars</Typography>
                      {!!calendars.length &&
                        calendars.map((c) => {
                          return (
                            <div key={c.id}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={!!displayedCalendars[c.id]}
                                    name={c.id}
                                    style={{ color: c.color }}
                                    onChange={(e) => handleCalendarChange(e)}
                                  />
                                }
                                label={c.name}
                              />
                            </div>
                          );
                        })}
                    </Box>
                  </ClickAwayListener>
                </Popper>
                <Box>
                  {" "}
                  {!alerts.length && (
                    <Button
                      variant="contained"
                      color="primary"
                      size={!isMobile ? "large" : "small"}
                      onClick={() => openTaskModal(true)}
                      startIcon={!isMobile && <AddIcon />}
                      className={classes.button}
                    >
                      {isMobile ? <AddIcon /> : "Task"}
                    </Button>
                  )}
                </Box>
              </Box>
              {!loading && !!displayedEvents.length ? (
                <div className={classes.itemsContainer}>
                  {displayedEvents.map((e, i) => {
                    const c = colors.find(
                      (cc) => Object.keys(cc)[0] === e.calendarId,
                    );
                    const color = c && c[e.calendarId];
                    return (
                      <Box className={classes.calendarItem} key={e.eventId + i}>
                        <Box className={classes.flex}>
                          {isMobile ? (
                            <Box className={classes.mobileDateWrapper}>
                              <Box>{format(parseISO(e.startTime), "LLL")}</Box>
                              <Box className={classes.mobileDay}>
                                {format(parseISO(e.startTime), "d")}
                              </Box>
                            </Box>
                          ) : (
                            <Box className={classes.day}>
                              {" "}
                              {format(parseISO(e.startTime), "eee")}
                            </Box>
                          )}
                          <Box className={classes.agenda}>
                            {!isMobile ? (
                              <div className={classes.flex}>
                                {!isMobile && (
                                  <Box className={classes.color}>
                                    <FiberManualRecordIcon
                                      style={{
                                        color: color,
                                      }}
                                    />
                                  </Box>
                                )}
                                <Typography>{e.summary}</Typography>{" "}
                                <InsertInvitationIcon />
                                {format(parseISO(e.startTime), "MMMM d")}
                                <AccessTimeIcon />
                                {format(parseISO(e.startTime), "p")}
                              </div>
                            ) : (
                              <div>
                                <Typography>
                                  {e.summary}{" "}
                                  <span className={classes.color}>
                                    {" "}
                                    <FiberManualRecordIcon
                                      style={{
                                        color: color,
                                      }}
                                    />
                                  </span>
                                </Typography>
                              </div>
                            )}
                            {e.description && (
                              <Box className={classes.agendaSubText}>
                                <span>
                                  {" "}
                                  {e.description?.replace(/(<([^>]+)>)/gi, "")}
                                </span>
                              </Box>
                            )}
                            {!!isMobile && e.hangoutLink && (
                              <a
                                href={e.hangoutLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Button
                                  variant="contained"
                                  className={classes.hangOutLink}
                                >
                                  Join with Google Meet
                                </Button>
                              </a>
                            )}
                          </Box>
                        </Box>
                        {isMobile ? (
                          <span
                            className={classes.mobileIcon}
                            onClick={(c) => {
                              setAnchorEl(anchorEl ? null : c.currentTarget);
                              setTaskId(e.eventId);
                            }}
                          >
                            <MoreVertIcon />
                            {anchorEl && taskId === e.eventId && (
                              <MobilePopper event={e} />
                            )}
                          </span>
                        ) : (
                          <Box className={classes.actions}>
                            {e.hangoutLink && (
                              <a
                                href={e.hangoutLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Button variant="contained">
                                  Join with Google Meet
                                </Button>
                              </a>
                            )}{" "}
                            {e.appUrl && (
                              <a
                                href={e.appUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {" "}
                                <div className={classes.icons}>
                                  <DescriptionIcon />
                                </div>
                              </a>
                            )}
                            <div
                              className={classes.icons}
                              onClick={() => {
                                setTaskModalData({
                                  ...e,
                                  start: e.startTime,
                                  end: e.endTime,
                                  event_id: e.eventId,
                                });
                                openTaskModal(true);
                              }}
                            >
                              <EditIcon />
                            </div>
                            <a href={e.link} target="_blank" rel="noreferrer">
                              <div className={classes.icons}>
                                {" "}
                                <InsertInvitationIcon />
                              </div>
                            </a>
                          </Box>
                        )}
                      </Box>
                    );
                  })}
                </div>
              ) : (
                !loading && (
                  <div className={classes.googleContainer}>
                    <img
                      src={`${REACT_APP_ASSETS_URL}/images/empty-state.svg`}
                    ></img>
                    <Typography className={classes.text}>
                      No Events scheduled
                    </Typography>
                  </div>
                )
              )}
            </>
          )}
        </div>
        {!hasOAuthAccessToken && (
          <Box className={classes.googleContainer}>
            <Box className={classes.googleSignIn}>
              <Typography>
                Your agenda is synced with your Google calendar
              </Typography>
              <Typography variant="h5">
                Sign in with your work email to view your agenda
              </Typography>
              <Button
                variant="outlined"
                onClick={() => dispatch(adminActions.getOAuthRedirect())}
                startIcon={<GoogleIcon />}
                endIcon={<span style={{ width: 20 }}></span>}
              >
                {/* <GoogleIcon /> */}
                Sign in with google
              </Button>
            </Box>
          </Box>
        )}
      </TabPanel>
      <div>
        <Box className={classes.leftWrapper}>
          <TabPanel value={value} index={1}>
            <Box>
              <Box className={classes.dateWrapper}>
                {!isMobile && (
                  <Typography variant="h6" className={classes.appTitle}>
                    Application statuses
                  </Typography>
                )}
                <Box className={classes.items}>
                  {!isMobile && (
                    <Button
                      startIcon={<LaunchIcon />}
                      onClick={() => history.push("/admin/applications")}
                    >
                      Applications
                    </Button>
                  )}
                  <DateSelectField
                    startValue={startValue}
                    setStartValue={setStartValue}
                  />
                  {isMobile && (
                    <Box
                      className={classes.mobileAppsBtn}
                      onClick={() => history.push("/admin/applications")}
                    >
                      <DescriptionIcon />
                    </Box>
                  )}
                </Box>
              </Box>
              <AppStatsItems amounts={amounts} />
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box>
              <Box className={classes.dateWrapper}>
                {!isMobile && (
                  <Typography variant="h6" className={classes.appTitle}>
                    My Stats
                  </Typography>
                )}
                <DateSelectField
                  startValue={startValue}
                  setStartValue={setStartValue}
                />
              </Box>
              <AgentStatsItems
                totalSales={totalSales}
                bound={amountBound}
                totalApps={totalApps}
                conversionRate={conversionRate}
              />
            </Box>
          </TabPanel>
          <Box></Box>
        </Box>
      </div>
    </div>
  );
};

export default React.memo(DashboardPage);
