import { AdminPages } from "./admin";
import { AuthPages } from "./auth";
import { MainPages } from "./main";
import { AutoWizardPages } from "./wizard/auto";
import { HomeWizardPages } from "./wizard/home";
import { LifeWizardPages } from "./wizard/life";
import { BundleWizardPages } from "./wizard/bundle";
import { AutoRentersWizard } from "./wizard/bundle/auto-renters";

export const Pages = {
  admin: AdminPages,
  auth: AuthPages,
  main: MainPages,
  autoWizard: AutoWizardPages,
  homeWizard: HomeWizardPages,
  lifeWizard: LifeWizardPages,
  bundleWizard: BundleWizardPages,
  autoRentersWizard: AutoRentersWizard,
};

export default Pages;

/**
 * Function to process each page in `Pages`.
 * @param {(page:PageDefinition,pageName:string,areaName:string,pages:typeof Pages)=>void} handler
 */
export function eachPage(handler) {
  Object.keys(Pages).forEach((areaName) => {
    const area = Pages[areaName];
    Object.keys(area).forEach((pageName) => {
      handler(area[pageName], pageName, areaName, Pages);
    });
  });
}
