import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  prop: {
    display: "block",
    padding: 0,
    borderBottom: "1px solid #EFF1FB",
    paddingBottom: 11.5,
    marginBottom: 11.5,
    "&:last-child": {
      borderBottom: "none",
      marginBottom: 0,
      paddingBottom: 0,
    },
    "&:hover": {
      "& $info": {
        opacity: 1,
      },
    },
  },
  coverageName: {
    fontSize: 12,
    color: "rgba(0, 0, 0, .81)",
  },
  limit: {
    fontWeight: 500,
  },
  extendedCost: {
    display: "block",
    fontSize: 10,
    color: theme.palette.primary.main,
    cursor: "pointer",
    height: 16,
    "&:hover": {
      opacity: 0.5,
    },
  },
  vehicle: {
    fontSize: 10,
    fontWeight: 500,
    marginBlock: 4,
  },
  deductible: {
    fontWeight: 400,
    fontSize: 12,
    paddingRight: 20,
    marginLeft: "auto",
  },
  info: {
    marginLeft: "auto",
    display: "flex",
    transition: "opacity 300ms linear",
    opacity: 0,
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
      color: "#AFB6D4",
    },

    [theme.breakpoints.down('sm')]: {
      opacity: 1,
    },
  },
  paper: {
    width: 200,
    padding: 8,
    borderRadius: 2,
    backgroundColor: "rgba(97, 97, 97, 0.9)",
  },
  note: {
    fontSize: 10,
    color: "#FFF",
  },
}));
