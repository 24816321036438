interface Faq {
  answer: string;
  question: string;
}

export interface CoverageModal {
  isOpen: boolean;
  internal_name: string;
  title: string;
  description: string;
  faqs?: Faq[];
}

export interface WizardState {
  activeStep: number;
  addressError: string;
  activeQuoteCard: number;
  allQuotes: any;
  carouselMode: "auto" | "home" | undefined;
  chatMode: "online" | "offline" | undefined;
  contactInfo: any;
  contactModal: any;
  addressModalOpen: boolean;
  contactType: string;
  coverageDetails: any;
  coverageModal: CoverageModal;
  // TODO: determine if customQuotes are living on auto/home state or wizard state
  customQuotes: any[];
  customQuotesLoading: boolean;
  offlineChatSubmitted: boolean;
  prefContactMethod: "email" | "call" | "sms" | undefined;
  quoteWasHydrated: boolean;
  req_uid: string; //The ID that represents all quotes for individual user
  settings: Record<string, any>;
  shopperApprovedShown: boolean;
  showChat: boolean;
  showPmtPopup: boolean;
  explainerModalOpen: boolean;
  selectedAdminQuote: any;
  upgradedBundle: "auto" | "home" | undefined;
}

interface Coverage {
  Limit: number[];
  Premium: number;
  Deductible: number;
  CoverageName: string;
}

interface Coverage2 {
  VIN: string;
  Make: string;
  Year: number;
  Model: string;
  Coverages: Coverage[];
}

export interface QuoteData {
  insurance_level_id: number;
  premium: string;
  PayPlanDescription: string;
  PayPlanDownPayment: number;
  PayPlanNumOfPayments: number;
  PayPlanPaymentAmount: number;
  TotalPremium: number;
  carrier: string;
  logo_path?: any;
  turbo_quote_id: string;
  coverage: Coverage2[];
}

interface CoApplicant {
  dob: string;
  gender: string;
  last_name: string;
  first_name: string;
  middle_name: string;
}
interface Vehicle {
  make: string;
  model: string;
  model_year: number;
  vin: string;
}

export interface Applicant {
  req_uid: string;
  first_name: string;
  middle_name?: string;
  last_name: string;
  addr1: string;
  city: string;
  state: string;
  zip: string;
  drivers?: [];
  vehicles?: Vehicle[];
  email: string;
  phone: string;
  previous_ins: { continuous_insurance: string };
  coApplicant?: CoApplicant;
  created_at: string;
  effective_date?: string;
  comments?: [];
}

export const UNSUPPORTED_STATES = ["AK", "LA", "MA"];
