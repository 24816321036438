import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 40,
  },
  VINField: {
    padding: "24px",
    marginTop: 40,
    borderRadius: 3,
    boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
    "& .MuiOutlinedInput-input": {
      padding: "0 14px",
    },
    "& span": {
      fontWeight: "bold",
      color: theme.palette.secondary.main,
      marginLeft: "3px",
    },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(14px, 16px) scale(1)",
    },
    [theme.breakpoints.down('sm')]: {
      padding: 16,
      marginTop: 0,
    },
  },
  VINTitle: {
    paddingBottom: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  VINGridItem: {
    flex: "1 1 auto",
  },
  vinErrorMessage: {
    position: "absolute",
    textAlign: "center",
    margin: 0,
    paddingTop: 4,
    width: "calc(100% - 16px)",
  },
  VINButton: {
    flex: "0 0 auto",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      height: 32,
      marginTop: 32,
      "& .MuiButton-root": {
        height: 32,
        width: "100%",
      },
    },
  },
  field: {
    marginTop: 40,
    marginBottom: 40,
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(14px, 16px) scale(1)",
    },
  },
  smallMarginField: {
    marginTop: 20,
    marginBottom: 0,
  },
  fieldTitle: {
    marginBottom: 24,
    fontWeight: 600,
  },
  selectLoader: {
    position: "absolute",
    right: 35,
  },
  switcherGroup: {
    width: "100%",
  },
  switcher: {
    width: "50%",

    "&.MuiToggleButton-root": {
      borderRadius: 0,
      borderColor: "#ccd2e9",
      textTransform: "initial",
      color: "#AFB6D4",
    },

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  removeVehicleBtn: {
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: "1px",
  },
  removeIcon: {
    height: 20,
    marginRight: 4,
  },
  error: {
    "& .MuiRadio-root": {
      color: "#f44336",
    },
    "& .MuiToggleButton-root": {
      borderColor: "#f44336",
    },
  },
}));
