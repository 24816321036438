import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {},
  topArea: {
    padding: "16px 0",
    display: "flex",
    flexDirection: "row-reverse",
  },
  table: {
    minWidth: 650,
  },
  link: {
    textDecoration: "none",
  },
}));
