import StartWizard from "../auto/start/StartWizard";
import VerifyPhone from "../auto/verify/VerifyPhone";
import CodeEntry from "../auto/passCode/CodeEntry";
import VehiclesWizard from "../auto/vehicles/VehiclesWizard";
import DriversWizard from "../auto/drivers/DriversWizard";
import DiscountFields from "../auto/discounts/DiscountFields";
import Continue from "./Continue";
import DetailsContainer from "../home/details/DetailsContainer";
import DiscountsForm from "../home/discounts/DiscountsForm";
import InsuredContainer from "../home/insured/InsuredForm";
import ContactForm from "../contact/ContactForm";
import Quotes from "../quotes/QuotesContainer";
import SelectedQuote from "../selectedQuote/SelectedQuoteContainer";
import { HydrateQuote } from "../hydrate/HydrateQuote";

export const AutoRentersWizard = {
  bundleWizardStart: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/start",
    type: "PAGE_BUNDLE_WIZARD_START",
    view: StartWizard,
  },
  autoWizardVerify: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/verify",
    type: "AUTO_PAGE_VERIFY_CODE",
    view: VerifyPhone,
  },
  autoCodeEntry: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/code-entry",
    type: "AUTO_PAGE_CODE_ENTRY",
    view: CodeEntry,
  },
  bundleWizardVehicles: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/vehicles",
    type: "PAGE_BUNDLE_WIZARD_VEHICLES",
    view: VehiclesWizard,
  },
  bundleWizardDrivers: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/drivers",
    type: "PAGE_BUNDLE_WIZARD_DRIVERS",
    view: DriversWizard,
  },
  bundleWizardAutoDiscounts: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/auto-discounts",
    type: "PAGE_BUNDLE_WIZARD_DISCOUNT",
    view: DiscountFields,
  },
  bundleWizardContinue: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/continue",
    type: "PAGE_BUNDLE_WIZARD_CONTINUE",
    view: Continue,
  },
  bundleWizardDetails: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/details/form",
    type: "PAGE_BUNDLE_WIZARD_DETAILS_FORM",
    view: DetailsContainer,
  },
  bundleWizardHomeDiscounts: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/home-discounts",
    type: "PAGE_BUNDLE_WIZARD_DISCOUNTS",
    view: DiscountsForm,
  },
  bundleWizardInsured: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/insured",
    type: "PAGE_BUNDLE_WIZARD_INSURED",
    view: InsuredContainer,
  },
  bundleWizardContact: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/contact",
    type: "PAGE_BUNDLE_WIZARD_CONTACT",
    view: ContactForm,
  },
  bundleWizardQuotes: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/quotes",
    type: "PAGE_BUNDLE_WIZARD_QUOTES",
    view: Quotes,
  },
  bundleWizardSelectedQuote: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/quotes/:id",
    type: "PAGE_BUNDLE_WIZARD_QUOTES",
    view: SelectedQuote,
  },
  bundleWizardHydrateQuote: {
    pathExact: true,
    anon: true,
    path: "/auto-renters/wizard/hydrate-quote/:id",
    type: "PAGE_BUNDLE_WIZARD_HYDRATE",
    view: HydrateQuote,
  },
};

export default AutoRentersWizard;
