import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Paper,
  Box,
  Typography,
} from "@mui/material";

import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useFormik } from "../../lib";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import { useStyles } from "./AddCoveragePage.styles";
import { states } from "../../assets/data/us_states";
import { useParams } from "react-router";

const levels = [
  { id: 1, name: "Low" },
  { id: 2, name: "Medium" },
  { id: 3, name: "High" },
];

const initialValues = {
  id: -1,
  level: 1,
  coverage_data: {},
  state: "",
};

const EditCoveragePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params: { id: string } = useParams();
  const coverage = useSelector(adminSelectors.selectedCoverage);
  const [activeCoverage, setActiveCoverage] = useState(initialValues);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: activeCoverage,
    async onSubmit(values) {
      await dispatch(adminActions.editCoverage(values));
    },
  });

  useEffect(() => {
    dispatch(adminActions.setPageTitle("Add Coverage"));
    dispatch(adminActions.getSelectedCoverage(+params.id));
  }, [dispatch]);

  useEffect(() => {
    setActiveCoverage({
      id: coverage.id,
      level: coverage.level,
      coverage_data: coverage.coverage_data,
      state: coverage.state,
    });
  }, [dispatch, params, coverage]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                disabled
                select
                id="level"
                name="level"
                label="Insurance Level"
                variant="outlined"
                value={form.values.level}
                onChange={form.handleChange}
                error={form.touched.level && Boolean(form.errors.level)}
                helperText={form.touched.level && form.errors.level}
              >
                {levels.map((level) => (
                  <MenuItem key={level.id} value={level.id}>
                    {level.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                disabled
                select
                id="state"
                name="state"
                label="State"
                variant="outlined"
                value={form.values.state}
                onChange={form.handleChange}
                error={form.touched.state && Boolean(form.errors.state)}
                helperText={form.touched.state && form.errors.state}
              >
                {states.map((state) => (
                  <MenuItem key={state.abbr} value={state.abbr}>
                    {state.abbr} &middot; {state.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={10} className={classes.formControl}>
              <Typography style={{ color: "#b4b4d4" }}>
                Coverage Data
              </Typography>
              <Paper>
                <Box className={classes.jsonInput}>
                  <JSONInput
                    key={activeCoverage.id}
                    id="coverage_data"
                    name="coverage_data"
                    label="Coverage Data"
                    placeholder={form.values.coverage_data}
                    onChange={(e) => {
                      form.setFieldValue("coverage_data", e.jsObject);
                    }}
                    colors={{
                      default: "#484c4f",
                      background: "#eff1f6",
                      background_warning: "#FEECEB",
                      string: "#FA7921",
                      number: "#70CE35",
                      colon: "#49B8F7",
                      keys: "#1277ab",
                    }}
                    height={"100%"}
                    width={"100%"}
                    locale={locale}
                    error={
                      form.touched.coverage_data &&
                      Boolean(form.errors.coverage_data)
                    }
                    helperText={
                      form.touched.coverage_data && form.errors.coverage_data
                    }
                  ></JSONInput>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!form.values.coverage_data}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default EditCoveragePage;
