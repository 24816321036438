import React from "react";
import { Route } from "react-router-dom";

// Local
import { emptyHandler } from "../utils";
import { RouteGuard } from "./RouteGuard";

/**
 * Function to process each page in a `pages` object.
 * @param {PagesByArea} pages
 * @param {(page:PageDefinition,pageName:string,areaName:string,pages:typeof pages)=>void} handler
 */
export function eachPage(pages, handler) {
  Object.keys(pages).forEach((areaName) => {
    const area = pages[areaName];
    Object.keys(area).forEach((pageName) => {
      handler(area[pageName], pageName, areaName, pages);
    });
  });
}

/**
 * @typedef {object} CreatePageConfig
 * @property {(page:PageDefinition)=>void} [each] A function to configure pages.
 * @property {string[]} [roles] The default roles to apply when `!page.anon`.
 */

/**
 * Applies default roles to the given `pages`.
 * @template TPages
 * @param {TPages} pages
 * @param {CreatePageConfig} [config]
 * @returns {TPages}
 */
export function createPages(pages, config = {}) {
  const { each = emptyHandler, roles = [] } = config;
  eachPage(pages, (page) => {
    if (!page.anon && !page.roles) {
      page.roles = roles;
    }
    each(page);
  });
  return pages;
}

/** @param {PageDefinition} page */
function setDocumentTitle(page) {
  const { title, documentTitle = title } = page;
  if (documentTitle) document.title = documentTitle;
}

/**
 * Returns a function for `Route.render`, to render the given page.
 * @param {PageDefinition} page
 */
export function pageRouteRenderer(page) {
  const { layout: LayoutView } = page;
  function renderPage(props) {
    const { view: PageView } = page;
    setDocumentTitle(page);
    return <PageView page={page} {...props} />;
  }
  function renderPageWithLayout(props) {
    const { view: PageView } = page;
    setDocumentTitle(page);
    return (
      <LayoutView page={page} {...props}>
        <PageView page={page} {...props} />
      </LayoutView>
    );
  }
  return LayoutView ? renderPageWithLayout : renderPage;
}

/**
 * Renders a single page route.
 * @param {PageDefinition} page
 */
export function renderPageRoute(page) {
  if (!page) {
    return null;
  }
  const { path, pathExact, roles, key = path } = page;
  if (roles && roles.length > 0) {
    return (
      <RouteGuard
        key={key}
        roles={roles}
        exact={pathExact}
        path={path}
        render={pageRouteRenderer(page)}
      />
    );
  }
  return (
    <Route
      key={key}
      exact={pathExact}
      path={path}
      render={pageRouteRenderer(page)}
    />
  );
}

/**
 * Renders one or more page routes.
 * @param {PageDefinition | PageDefinitions} pages
 */
export function renderPageRoutes(pages) {
  if (!pages) {
    throw new Error("Missing pages: " + typeof pages);
  }
  const routes = (Array.isArray(pages) ? pages : [pages]).flatMap((item) =>
    // If item has a path it's a PageDefinition else it's PageDefinitions.
    item.path ? item : Object.keys(item).map((key) => item[key]),
  );
  return routes.map(renderPageRoute);
}
