import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    maxWidth: "100vw",
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      height: 16,
    },
    [theme.breakpoints.down('md')]: {
      height: 40,
      maxWidth: "100%",
      "& .MuiOutlinedInput-root": {
        height: 40,
        minHeight: 40,
      },
    },
  },
  textFieldLabel: {
    lineHeight: "25px",
    [theme.breakpoints.down('md')]: {
      lineHeight: "normal",
    },
  },
}));
