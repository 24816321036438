import React from "react";
import { CoverageSection } from "../components";

const FilteredBundleCoverageSection = ({
  low,
  med,
  high,
  custom,
  quote_type,
  is_linked,
}) => {
  const FilteredCoverageSection = ({ heading, level }) => {
    return (
      <CoverageSection
        quotes={level.filter(
          (quote) =>
            quote.quote_type === quote_type && quote.is_linked === is_linked,
        )}
        heading={`${heading} Coverages`}
      />
    );
  };

  return (
    <>
      <FilteredCoverageSection heading="Low" level={low} />
      <FilteredCoverageSection heading="Medium" level={med} />
      <FilteredCoverageSection heading="High" level={high} />
      <FilteredCoverageSection heading="Custom" level={custom} />
    </>
  );
};

export default React.memo(FilteredBundleCoverageSection);
