import ContactForm from "../contact/ContactForm";
import DetailsContainer from "./details/DetailsContainer";
import DiscountsForm from "./discounts/DiscountsForm";
import { HydrateQuote } from "../hydrate/HydrateQuote";
import InsuredContainer from "./insured/InsuredForm";
import Quotes from "../quotes/QuotesContainer";
import SelectedQuote from "../selectedQuote/SelectedQuoteContainer";
import StartForm from "./start/StartForm";

export const HomeWizardPages = {
  homeWizardStart: {
    pathExact: true,
    anon: true,
    path: "/home/wizard/start",
    type: "PAGE_HOME_WIZARD_START_FORM",
    view: StartForm,
  },
  homeWizardDetails: {
    pathExact: true,
    anon: true,
    path: "/home/wizard/details/form",
    type: "PAGE_HOME_WIZARD_DETAILS_FORM",
    view: DetailsContainer,
  },
  homeWizardDetailsConfirm: {
    pathExact: true,
    anon: true,
    path: "/home/wizard/details/confirm",
    type: "PAGE_HOME_WIZARD_DETAILS_CONFIRM",
    view: DetailsContainer,
  },
  homeWizardDiscounts: {
    pathExact: true,
    anon: true,
    path: "/home/wizard/discounts",
    type: "PAGE_HOME_WIZARD_DISCOUNTS",
    view: DiscountsForm,
  },
  homeWizardInsured: {
    pathExact: true,
    anon: true,
    path: "/home/wizard/insured",
    type: "PAGE_HOME_WIZARD_INSURED",
    view: InsuredContainer,
  },
  homeWizardContact: {
    pathExact: true,
    anon: true,
    path: "/home/wizard/contact",
    type: "PAGE_HOME_WIZARD_CONTACT",
    view: ContactForm,
  },
  homeWizardQuotes: {
    pathExact: true,
    anon: true,
    path: "/home/wizard/quotes",
    type: "PAGE_HOME_WIZARD_QUOTES",
    view: Quotes,
  },
  homeWizardSelectedQuote: {
    pathExact: true,
    anon: true,
    path: "/home/wizard/quotes/:id",
    type: "PAGE_HOME_WIZARD_SELECTED_QUOTE",
    view: SelectedQuote,
  },
  homeWizardHydrateQuote: {
    pathExact: true,
    anon: true,
    path: "/home/wizard/hydrate-quote/:id",
    type: "PAGE_HOME_WIZARD_HYDRATE",
    view: HydrateQuote,
  },
  rentersWizardStart: {
    pathExact: true,
    anon: true,
    path: "/renters/wizard/start",
    type: "PAGE_HOME_WIZARD_START_FORM",
    view: StartForm,
  },
  rentersWizardDetails: {
    pathExact: true,
    anon: true,
    path: "/renters/wizard/details/form",
    type: "PAGE_HOME_WIZARD_DETAILS_FORM",
    view: DetailsContainer,
  },
  rentersWizardDiscounts: {
    pathExact: true,
    anon: true,
    path: "/renters/wizard/discounts",
    type: "PAGE_HOME_WIZARD_DISCOUNTS",
    view: DiscountsForm,
  },
  rentersWizardInsured: {
    pathExact: true,
    anon: true,
    path: "/renters/wizard/insured",
    type: "PAGE_HOME_WIZARD_INSURED",
    view: InsuredContainer,
  },
  rentersWizardContact: {
    pathExact: true,
    anon: true,
    path: "/renters/wizard/contact",
    type: "PAGE_HOME_WIZARD_CONTACT",
    view: ContactForm,
  },
  rentersWizardQuotes: {
    pathExact: true,
    anon: true,
    path: "/renters/wizard/quotes",
    type: "PAGE_HOME_WIZARD_QUOTES",
    view: Quotes,
  },
  rentersWizardSelectedQuote: {
    pathExact: true,
    anon: true,
    path: "/renters/wizard/quotes/:id",
    type: "PAGE_HOME_WIZARD_SELECTED_QUOTE",
    view: SelectedQuote,
  },
  rentersWizardHydrateQuote: {
    pathExact: true,
    anon: true,
    path: "/renters/wizard/hydrate-quote/:id",
    type: "PAGE_HOME_WIZARD_HYDRATE",
    view: HydrateQuote,
  },
};

export default HomeWizardPages;
