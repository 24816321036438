import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Radio,
  OutlinedInput,
  FormHelperText,
  List,
  ListItemIcon,
  ListItem,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";

import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { add } from "date-fns";
import { Form, Navigation, useFormik } from "../../../../lib";
import {
  autoSelectors,
  homeActions,
  homeSelectors,
  useAction,
  useSelector,
  wizardNoPersistSelectors,
} from "../../../../state";
import { DOB_MAX_DATE, Insured } from "../../../../state/home/types";
import {
  formatTitleCase,
  insuredSchema as validationSchema,
  NewDriverShape,
} from "../../../../utils";
import ScrollToOnError from "../../../../components/forms/ScrollToOnError";
import { useMobile } from "../../../../themes";
import { useStyles } from "./Insured.styles";
import { DriverShape } from "../../../../state/auto/types";
import { InfoIcon } from "../../../../components/icons";
import { DatePicker } from "@mui/x-date-pickers";

export default function InsuredContainer() {
  const classes = useStyles();
  const isMobile = useMobile();

  //STATE
  const [formSubmitted, setFormSubmitted] = useState(false);

  //SELECTORS
  const insured = useSelector(homeSelectors.insured);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const applicant: DriverShape =
    useSelector(autoSelectors.applicant) || NewDriverShape();
  const bundleEffectiveDate = useSelector(
    autoSelectors.discounts,
  ).effectiveDate;
  const selectedDrivers = useSelector(autoSelectors.selectedDrivers);

  //ACTIONS
  const setInsured = useAction(homeActions.setInsured);

  const coapplicantDrivers = [
    ...selectedDrivers.filter((d) => d !== applicant),
    NewDriverShape(),
  ];

  const initialValues: Insured = {
    firstName: insured.firstName || applicant.FirstName || "",
    middleName: insured.middleName || applicant.MiddleName || "",
    lastName: insured.lastName || applicant.LastName || "",
    gender: insured.gender || applicant.gender || "",
    maritalStatus: insured.maritalStatus || applicant.marital_status || "",
    dob: insured.dob
      ? new Date(insured.dob)
      : applicant.dob
      ? new Date(applicant.dob)
      : "",
    addCoapplicant: insured.addCoapplicant || "",
    coapplicant: {
      arrayIndex: insured.coapplicant?.arrayIndex || -1,
      firstName: insured.coapplicant?.firstName || "",
      middleName: insured.coapplicant?.middleName || "",
      lastName: insured.coapplicant?.lastName || "",
      dob: insured.coapplicant?.dob ? new Date(insured.coapplicant?.dob) : "",
      gender: insured.coapplicant?.gender || "",
    },
    effectiveDate: insured.effectiveDate
      ? new Date(insured.effectiveDate)
      : mode === "bundle" || mode === "auto-renters"
      ? bundleEffectiveDate
        ? new Date(bundleEffectiveDate)
        : add(new Date(), { days: 7 })
      : add(new Date(), { days: 7 }),
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik: any = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnChange: formSubmitted,
    async onSubmit(values: Insured) {
      await setInsured(values);
      Navigation.go(`/${mode}/wizard/contact`);
    },
  });
  const clearCoApplicantInfo = useCallback(() => {
    formik.setFieldValue("coapplicant.firstName", "");
    formik.setFieldValue("coapplicant.lastName", "");
    formik.setFieldValue("coapplicant.dob", "");
    formik.setFieldValue("coapplicant.gender", "");
    formik.setFieldValue("coapplicant.arrayIndex", -1);
  }, [formik]);

  const handleToggleChange =
    (fieldName) => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) {
        formik.setFieldValue(fieldName, value);
      }
    };
  useEffect(() => {
    if (formik.values.addCoapplicant === false) {
      clearCoApplicantInfo();
    }
  }, [formik.values.addCoapplicant]);

  const handleDateChange = useCallback(
    (date) => {
      formik.setFieldValue("dob", date);
    },
    [formik],
  );
  const handleEffectiveDateChange = useCallback(
    (date) => {
      formik.setFieldValue("effectiveDate", date);
    },
    [formik],
  );
  const handleCoapplicantSelection = (driver: DriverShape, index: number) => {
    formik.setFieldValue("coapplicant.arrayIndex", index);
    formik.setFieldValue("coapplicant.firstName", driver.FirstName);
    formik.setFieldValue("coapplicant.middleName", driver.MiddleName);
    formik.setFieldValue("coapplicant.lastName", driver.LastName);
    formik.setFieldValue("coapplicant.dob", driver.dob);
    formik.setFieldValue("coapplicant.gender", driver.gender);
  };

  return (
    <Form form={formik} onSubmit={formik.handleSubmit} className="maxWidth">
      <Box className={classes.formSection}>
        <Grid container justifyContent="space-between">
          <FormControl variant="outlined" className={classes.inputThird}>
            <ScrollToOnError name="firstName">
              <OutlinedInput
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                placeholder="First name"
                error={!!formik.errors.firstName}
                style={{ width: "100%" }}
              />
              {Boolean(formik.errors.firstName) && (
                <FormHelperText error variant="outlined">
                  {formik.errors.firstName}
                </FormHelperText>
              )}
            </ScrollToOnError>
          </FormControl>
          <FormControl variant="outlined" className={classes.inputSmall}>
            <OutlinedInput
              name="middleName"
              value={formik.values.middleName}
              onChange={formik.handleChange}
              placeholder={isMobile ? "Middle name" : "Middle"}
            />
          </FormControl>

          <FormControl variant="outlined" className={classes.inputThird}>
            <ScrollToOnError name="lastName">
              <OutlinedInput
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                placeholder="Last name"
                error={!!formik.errors.lastName}
                style={{ width: "100%" }}
              />
              {Boolean(formik.errors.lastName) && (
                <FormHelperText error variant="outlined">
                  {formik.errors.lastName}
                </FormHelperText>
              )}
            </ScrollToOnError>
          </FormControl>
        </Grid>
      </Box>
      <ScrollToOnError name="gender">
        <FormControl className={classes.formSection} component="fieldset">
          <FormLabel className={classes.formLabel} component="legend">
            Gender
          </FormLabel>
          <ToggleButtonGroup
            className={`${classes.toggle} ${
              formik.errors.gender ? classes.error : ""
            }`}
            value={formik.values.gender}
            onChange={handleToggleChange("gender")}
            exclusive
            style={{ width: "100%" }}
            size="large"
          >
            <ToggleButton
              value={"M"}
              aria-label="yes"
              style={{ width: "50%", textTransform: "capitalize" }}
              color="primary"
            >
              Male
            </ToggleButton>
            <ToggleButton
              value={"F"}
              aria-label="no"
              style={{ width: "50%", textTransform: "capitalize" }}
              color="primary"
            >
              Female
            </ToggleButton>
          </ToggleButtonGroup>
          {Boolean(formik.errors.gender) && (
            <FormHelperText error variant="outlined">
              {formik.errors.gender}
            </FormHelperText>
          )}
        </FormControl>
      </ScrollToOnError>
      <ScrollToOnError name="maritalStatus">
        <FormControl
          className={`${classes.formSection} ${
            formik.errors.maritalStatus ? classes.error : ""
          }`}
          component="fieldset"
        >
          <FormLabel className={classes.formLabel} component="legend">
            Marital status
          </FormLabel>
          <RadioGroup
            name="maritalStatus"
            value={formik.values.maritalStatus}
            onChange={formik.handleChange}
          >
            <FormControlLabel
              value="S"
              control={<Radio color="primary" />}
              label="Single"
            />
            <FormControlLabel
              value="M"
              control={<Radio color="primary" />}
              label="Married"
            />
            <FormControlLabel
              value="D"
              control={<Radio color="primary" />}
              label="Divorced"
            />
            <FormControlLabel
              value="W"
              control={<Radio color="primary" />}
              label="Widowed"
            />
          </RadioGroup>
          {Boolean(formik.errors.maritalStatus) && (
            <FormHelperText error variant="outlined">
              {formik.errors.maritalStatus}
            </FormHelperText>
          )}
        </FormControl>
      </ScrollToOnError>
      <ScrollToOnError name="dob">
        <Box className={classes.formSection}>
          <FormLabel className={classes.formLabel} component="legend">
            Date of birth
          </FormLabel>
          <div className="datepicker">
            <DatePicker
              format="MM/dd/yyyy"
              label="MM/DD/YYYY"
              // name="dob"
              maxDate={DOB_MAX_DATE}
              value={(formik.values.dob && new Date(formik.values.dob)) || null}
              onChange={handleDateChange}
              // style={{ width: 304, maxWidth: "100%" }}
              // error={!!formik.errors.dob}
              // helperText={formik.touched.dob && formik.errors.dob}
              // keyboardIcon={null}
            />
          </div>
          {Boolean(formik.errors.dob) && (
            <FormHelperText error variant="outlined">
              {formik.errors.dob}
            </FormHelperText>
          )}
        </Box>
      </ScrollToOnError>
      <ScrollToOnError name="addCoapplicant">
        <FormControl className={classes.formSection} component="fieldset">
          <FormLabel className={classes.formLabel} component="legend">
            Would you like to add a spouse or a co-applicant?
          </FormLabel>
          <ToggleButtonGroup
            className={`${classes.toggle} ${
              formik.errors.addCoapplicant ? classes.error : ""
            }`}
            value={formik.values.addCoapplicant}
            onChange={handleToggleChange("addCoapplicant")}
            exclusive
            style={{ width: "100%" }}
            size="large"
          >
            <ToggleButton
              value={true}
              aria-label="yes"
              style={{ width: "50%", textTransform: "capitalize" }}
              color="primary"
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value={false}
              aria-label="no"
              style={{ width: "50%", textTransform: "capitalize" }}
              color="primary"
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
          {Boolean(formik.errors.addCoapplicant) && (
            <FormHelperText error variant="outlined">
              {formik.errors.addCoapplicant}
            </FormHelperText>
          )}
        </FormControl>
      </ScrollToOnError>
      {formik.values.addCoapplicant && (
        <>
          {mode !== "home" && coapplicantDrivers.length > 1 && (
            <Grid container justifyContent="space-between">
              <List className={classes.list}>
                {coapplicantDrivers.map((driver, i) => {
                  return (
                    <ListItem
                      key={`driver-${i}`}
                      component={Paper}
                      onClick={() => handleCoapplicantSelection(driver, i)}
                      className={classes.item}
                    >
                      <ListItemIcon className={classes.checkboxWrap}>
                        <Radio
                          edge="start"
                          checked={i === formik.values.coapplicant?.arrayIndex}
                          disableRipple
                          color="primary"
                          className={`driver-${i}`}
                        />
                      </ListItemIcon>
                      <Box className={classes.content}>
                        <Typography>
                          {formatTitleCase(driver.FirstName) || "Someone"}{" "}
                          {formatTitleCase(driver.MiddleName) || ""}{" "}
                          {formatTitleCase(driver.LastName) || "else"}
                        </Typography>
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}
          {(formik.values.coapplicant?.arrayIndex ===
            coapplicantDrivers.length - 1 ||
            coapplicantDrivers.length === 1 ||
            mode === "home") && (
            <>
              <Box className={classes.formSection}>
                <Grid container justifyContent="space-between">
                  <FormControl
                    variant="outlined"
                    className={classes.inputThird}
                  >
                    <ScrollToOnError name="coapplicant.firstName">
                      <OutlinedInput
                        name="coapplicant.firstName"
                        value={formik.values.coapplicant?.firstName || ""}
                        onChange={formik.handleChange}
                        placeholder="First name"
                        error={!!formik.errors["coapplicant"]?.["firstName"]}
                        style={{ width: "100%" }}
                      />
                      {Boolean(formik.errors.coapplicant) && (
                        <FormHelperText error variant="outlined">
                          {formik.errors["coapplicant"]?.["firstName"]}
                        </FormHelperText>
                      )}
                    </ScrollToOnError>
                  </FormControl>

                  <FormControl
                    variant="outlined"
                    className={classes.inputSmall}
                  >
                    <OutlinedInput
                      name="coapplicant.middleName"
                      value={formik.values.coapplicant?.middleName || ""}
                      onChange={formik.handleChange}
                      placeholder={isMobile ? "Middle name" : "Middle"}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.inputThird}
                  >
                    <ScrollToOnError name="coapplicant.lastName">
                      <OutlinedInput
                        name="coapplicant.lastName"
                        value={formik.values.coapplicant?.lastName || ""}
                        onChange={formik.handleChange}
                        placeholder="Last name"
                        error={!!formik.errors["coapplicant"]?.["lastName"]}
                        style={{ width: "100%" }}
                      />
                      {Boolean(formik.errors.coapplicant) && (
                        <FormHelperText error variant="outlined">
                          {formik.errors["coapplicant"]?.["lastName"]}
                        </FormHelperText>
                      )}
                    </ScrollToOnError>
                  </FormControl>
                </Grid>
              </Box>
              <ScrollToOnError name="coapplicant.gender">
                <FormControl
                  className={classes.formSection}
                  component="fieldset"
                >
                  <FormLabel className={classes.formLabel} component="legend">
                    Gender
                  </FormLabel>
                  <ToggleButtonGroup
                    className={`${classes.toggle} ${
                      formik.errors.gender ? classes.error : ""
                    }`}
                    value={formik.values.coapplicant.gender}
                    onChange={handleToggleChange("coapplicant.gender")}
                    exclusive
                    style={{ width: "100%" }}
                    size="large"
                  >
                    <ToggleButton
                      value={"M"}
                      aria-label="yes"
                      style={{ width: "50%", textTransform: "capitalize" }}
                      color="primary"
                    >
                      Male
                    </ToggleButton>
                    <ToggleButton
                      value={"F"}
                      aria-label="no"
                      style={{ width: "50%", textTransform: "capitalize" }}
                      color="primary"
                    >
                      Female
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {Boolean(formik.errors["coapplicant"]?.["gender"]) && (
                    <FormHelperText error variant="outlined">
                      {formik.errors["coapplicant"]?.["gender"]}
                    </FormHelperText>
                  )}
                </FormControl>
              </ScrollToOnError>
              <ScrollToOnError name="coapplicant.dob">
                <Box className={classes.formSection}>
                  <FormLabel className={classes.formLabel} component="legend">
                    Date of birth
                  </FormLabel>
                  <div className="datepicker">
                    <DatePicker
                      format="MM/dd/yyyy"
                      label="MM/DD/YYYY"
                      maxDate={DOB_MAX_DATE}
                      value={
                        (formik.values.coapplicant?.dob &&
                          new Date(formik.values.coapplicant?.dob)) ||
                        null
                      }
                      onChange={(date) => {
                        formik.setFieldValue("coapplicant.dob", date);
                      }}
                    />
                  </div>
                  {Boolean(formik.errors.coapplicant?.dob) && (
                    <FormHelperText error variant="outlined">
                      {formik.errors.coapplicant?.dob}
                    </FormHelperText>
                  )}
                </Box>
              </ScrollToOnError>
            </>
          )}
        </>
      )}
      {mode !== "bundle" && (
        <ScrollToOnError name="effectiveDate">
          <Box className={classes.formSection}>
            <div className={classes.info}>
              <FormLabel className={classes.formLabel} component="legend">
                When do you want the policy to take effect?
                <Tooltip
                  title="15 days in advance will get you the most savings"
                  placement="top"
                >
                  <InfoIcon />
                </Tooltip>
              </FormLabel>
            </div>
            <DatePicker
              format="MM/dd/yyyy"
              // name="effectiveDate"
              disablePast={true}
              // autoOk
              value={formik.values.effectiveDate || null}
              onChange={handleEffectiveDateChange}
              // style={{ width: 304, maxWidth: "100%" }}
              // error={!!formik.errors.effectiveDate}
              // helperText={
              //   formik.touched.effectiveDate && formik.errors.effectiveDate
              // }
            />
          </Box>
        </ScrollToOnError>
      )}
      <Button
        variant="contained"
        color="primary"
        className={classes.nextButton}
        type="submit"
        disabled={!formik.isValid}
        onClick={() => setFormSubmitted(true)}
      >
        Next
      </Button>
    </Form>
  );
}
