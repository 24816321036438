import { Box, Button, Link, TextField, Typography } from "@mui/material";
import React from "react";
import { Pages } from "../";
import {
  Form,
  Navigation,
  RouterLink,
  useFormik,
  useLocation,
  yup,
} from "../../lib";
import { authActions, useAction } from "../../state";
import { useStyles } from "./Auth.styles";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPasswordPage = () => {
  const classes = useStyles();
  const recoverPassword = useAction(authActions.recoverPassword);
  const location = useLocation();

  const form: any = useFormik({
    initialValues: {
      email: location.query.email || "",
    },
    validationSchema,
    async onSubmit(values) {
      await recoverPassword(values);
      Navigation.replace(Pages.auth.recoverySent, {
        query: {
          email: values.email,
        },
      });
    },
  });

  return (
    <Box className={classes.authCard}>
      <Typography variant="h2" component="h1" className={classes.titleText}>
        Forgot password
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitleText}>
        Enter your email address and we will send you a link to reset your
        password.
      </Typography>
      <Form form={form} className={classes.form}>
        <TextField
          autoFocus
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={form.values.email}
          onChange={form.handleChange}
          error={form.touched.email && Boolean(form.errors.email)}
          helperText={form.touched.email && form.errors.email}
        />
        <div className={classes.buttonBox}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={form.isSubmitting}
          >
            Reset Password
          </Button>
          <div className={classes.bottomLink}>
            <Link component={RouterLink} to={`/auth/login`}>
              Back to sign in
            </Link>
          </div>
        </div>
      </Form>
    </Box>
  );
};

export default React.memo(ForgotPasswordPage);
