import ContactForm from "../contact/ContactForm";
import { HydrateQuote } from "../hydrate/HydrateQuote";
import InsuredContainer from "./insured/InsuredDetails";
import CoverageContainer from "./coverage/CoverageDetails";
import Quotes from "../life/quotes/LifeQuotes";
import SelectedQuote from "../life/quotes/selectedQuote/SelectedQuoteContainer";
import StartForm from "./start/StartWizard";

export const LifeWizardPages = {
  lifeWizardStart: {
    pathExact: true,
    anon: true,
    path: "/life/wizard/start",
    type: "PAGE_LIFE_WIZARD_START_FORM",
    view: StartForm,
  },
  lifeWizardInsured: {
    pathExact: true,
    anon: true,
    path: "/life/wizard/insured",
    type: "PAGE_LIFE_WIZARD_INSURED",
    view: InsuredContainer,
  },
  lifeWizardCoverage: {
    pathExact: true,
    anon: true,
    path: "/life/wizard/coverage",
    type: "PAGE_LIFE_COVERAGE",
    view: CoverageContainer,
  },
  lifeWizardContact: {
    pathExact: true,
    anon: true,
    path: "/life/wizard/contact",
    type: "PAGE_LIFE_WIZARD_CONTACT",
    view: ContactForm,
  },
  lifeWizardQuotes: {
    pathExact: true,
    anon: true,
    path: "/life/wizard/quotes",
    type: "PAGE_LIFE_WIZARD_QUOTES",
    view: Quotes,
  },
  lifeWizardSelectedQuote: {
    pathExact: true,
    anon: true,
    path: "/life/wizard/quotes/:id",
    type: "PAGE_LIFE_WIZARD_SELECTED_QUOTE",
    view: SelectedQuote,
  },
  lifeWizardHydrateQuote: {
    pathExact: true,
    anon: true,
    path: "/life/wizard/hydrate-quote/:id",
    type: "PAGE_LIFE_WIZARD_HYDRATE",
    view: HydrateQuote,
  },
};

export default LifeWizardPages;
