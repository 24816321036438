import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  card: {
    width: 440,
    minHeight: 480,
    borderRadius: 3,
    border: "none",
    marginTop: 40,
    display: "flex",
    flexDirection: "column",
    marginBottom: 32,
    boxShadow: (props: any) =>
      props.isQuoteSelected
        ? "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC"
        : "rgb(245 246 254) 2px 2px 12px 2px",

    "&:hover": {
      boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
      // cursor: (props: any) => (props.isQuoteSelected ? "auto" : "pointer"),

      "& button": {
        color: theme.palette.primary.main,
      },
    },
    [theme.breakpoints.down(600)]: {
      margin: "0 -16px 0 -16px",
      width: "calc(100% + 32px) !important",
      minHeight: 450,
      marginTop: 16,
    },
    [theme.breakpoints.down(768)]: {
      width: "100%",
    },
  },
  cardHeader: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    justifyContent: "space-between",
    padding: 22,
    borderBottom: "1px solid #DADFF6",
  },
  chatWithUsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chatHeading: {
    fontWeight: 600,
    fontSize: 20,
  },
  onlineStatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontWeight: 300,
    fontSize: 14,
  },
  queueMsg: {
    color: theme.palette.secondary.main,
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 400,
  },
  threadSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  messageInputBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #DADFF6",
    height: 64,
    "&.MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
  messageInput: {
    height: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: "none",
  },
  agentChatWrapper: {
    margin: "0px 16px",
    padding: 8,
    borderRadius: "3px 24px 24px 24px",
    fontSize: 14,
  },
  customerChatWrapper: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "24px 3px 24px 24px",
    margin: "0px 16px",
    padding: 8,
    fontSize: 14,
    color: "#FFFFFF",
    alignSelf: "flex-end",
  },
  agentChatBlurb: {
    display: "inline-block",
    backgroundColor: "rgb(239, 241, 251)",
    padding: 12,
    borderRadius: "3px 24px 24px 24px",
    fontSize: 14,
    maxWidth: 300,
  },
  customerChatBlurb: {
    display: "inline-block",
    borderRadius: "24px 3px 24px 24px",
    backgroundColor: theme.palette.primary.light,
    padding: 12,
    fontSize: 14,
    color: "#FFFFFF",
    maxWidth: 300,
    alignSelf: "flex-end",
  },
  customerMessageTime: {
    marginBottom: 8,
    textAlign: "right",
    color: theme.palette.secondary.main,
    fontSize: 11,
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  agentMessageTime: {
    marginBottom: 8,
    color: theme.palette.secondary.main,
    fontSize: 11,
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  avatar: {
    width: 24,
    height: 24,
  },
  offlineChatBoxContainer: {
    height: "100%",
    minHeight: 480,
    [theme.breakpoints.down('sm')]: {
      minHeight: "auto",
    },
  },
  offlineHeader: {
    padding: 8,
    backgroundColor: "#DADFF6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  offlineBox: {
    height: "calc(100% - 36px)",
    overflow: "auto",
  },
  buttonWrapper: {
    color: theme.palette.primary.light,
    display: "flex",
    gap: 8,
    "& .MuiButtonBase-root": {
      color: theme.palette.primary.light,
    },
    "& .MuiButton-outlined": {
      border: `1px solid  ${theme.palette.primary.light}`,
    },
  },
}));
