import { AppState } from "../states";

export const homeSelectors = {
  start(state: AppState) {
    return state.home.start || {};
  },
  insured(state: AppState) {
    return state.home.insured || {};
  },
  details(state: AppState) {
    return state.home.details || {};
  },
  discounts(state: AppState) {
    return state.home.discounts;
  },
  displayCoverages(state: AppState) {
    return state.home.coverageInfo.displayCoverages;
  },
  moreCoverages(state: AppState) {
    return state.home.coverageInfo.moreCoverages ?? [];
  },
  rentersDisplayCoverages(state: AppState) {
    return state.home.rentersCoverageInfo.displayCoverages;
  },
  rentersMoreCoverages(state: AppState) {
    return state.home.rentersCoverageInfo.moreCoverages ?? [];
  },
  rentersCustomData(state: AppState) {
    return state.home.rentersCoverageInfo.coverageData;
  },
  customCoverages(state: AppState) {
    return state.home.coverageInfo.coverageData;
  },
  customData(state: AppState) {
    return state.home.customData;
  },
  quotes(state: AppState) {
    return state.home.quotes || [];
  },
  bundleQuotes(state: AppState) {
    return state.home.bundleQuotes || [];
  },
  bundleQuotesFailed(state: AppState) {
    return state.home.bundleQuotesFailed;
  },
  selectedQuote(state: AppState) {
    return state.home.selectedQuote;
  },
};
