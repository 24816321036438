import { AppState } from "../types";
import { UIViewState } from "./state";

export const uiSelectors = {
  loading(state: AppState) {
    return state.ui.loading;
  },
  loadingMessage(state: AppState) {
    return state.ui.loadingMessage;
  },

  notification(state: AppState) {
    return state.ui.notification;
  },

  showExitIntent(state: AppState) {
    return state.ui.showExitIntent;
  },
  loadingAgency(state: AppState) {
    return state.ui.loadingAgency;
  },
  agencyData(state: AppState) {
    return state.ui.agencyData;
  },
  fbUser(state: AppState) {
    return state.ui.fbUser;
  },
  adminMenuOpen(state: AppState) {
    return state.ui.adminMenuOpen;
  },
  // Views

  /** Dynamically creates a selector to select the given `view[name]`. */
  forView(name: keyof UIViewState) {
    return (state: AppState) => state.ui.view[name];
  },
};
