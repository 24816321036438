import React, { useEffect } from "react";
import { Grid, Button, TextField, Card, CardContent } from "@mui/material";

import {
  adminActions,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useFormik } from "../../lib";

import { useStyles } from "./AddCarrierPage.styles";

const initialValues = {
  ip: "",
  comment: "",
  added_by_user_id: undefined,
};

const AddAllowedIpPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const user_id = useSelector(authSelectors.userId);

  const form = useFormik({
    initialValues,
    async onSubmit(values) {
      await dispatch(adminActions.createAllowedIP(values));
    },
  });

  useEffect(() => {
    form.setFieldValue("added_by_user_id", user_id);
    //eslint-disable-next-line
  }, [user_id]);

  useEffect(() => {
    dispatch(adminActions.setPageTitle("Add Allowed IP"));
  }, [dispatch]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            <Grid item xs={5} className={classes.formControl}>
              <TextField
                fullWidth
                name="ip"
                label="IP Address"
                variant="outlined"
                value={form.values.ip}
                onChange={form.handleChange}
              ></TextField>
            </Grid>

            <Grid item xs={5} className={classes.formControl}>
              <TextField
                fullWidth
                name="comment"
                label="Comments/Notes"
                variant="outlined"
                value={form.values.comment}
                onChange={form.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!form.values.ip}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddAllowedIpPage;
