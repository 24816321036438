import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import ScrollToOnError from "../../../../components/forms/ScrollToOnError";

import {
  useAction,
  useSelector,
  autoActions,
  autoSelectors,
  wizardNoPersistSelectors,
  wizardSelectors,
} from "../../../../state";
import { useMobile } from "../../../../themes";
//import { BODILY_INJURY_OPTIONS } from "../../../constants";
import { Form, Navigation, useFormik } from "../../../../lib";
import { discountsValidationSchema as validationSchema } from "../../../../utils/validationSchemas";

import { useStyles } from "./DiscountFields.styles";
import { add } from "date-fns";
import { InfoIcon } from "../../../../components";
import { DatePicker } from "@mui/x-date-pickers";
import { EFFECTIVE_MIN_DATE } from "../../../../constants/common";

const DiscountFields = () => {
  const classes = useStyles();
  const isMobile = useMobile();

  const setDiscounts = useAction(autoActions.setDiscounts);
  const discounts = useSelector(autoSelectors.discounts);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const selectedDrivers = useSelector(autoSelectors.selectedDrivers);
  const applicantIndex = useSelector(autoSelectors.applicantIndex);
  const selectedVehicles = useSelector(autoSelectors.selectedVehicles);
  const upgradedBundle = useSelector(wizardSelectors.upgradedBundle);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const initialValues: any = {
    selectedVehiclesAmount: selectedVehicles?.length || 0,
    selectedDrivers,
    applicantIndex,
    months_coverage: discounts?.months_coverage || -1,
    bodily_injury:
      discounts?.bodily_injury ||
      JSON.stringify({
        perPerson: 0,
        perAcc: 0,
      }),
    residence_owned_rented: discounts?.residence_owned_rented
      ? discounts.residence_owned_rented
      : mode === "bundle"
      ? "O"
      : "",
    employment_military: false,
    autopay: false,
    paperless_discount: true,
    whoDrives: selectedVehicles?.map(() => ({
      driver_id: 1,
      veh_id: undefined,
    })),
    effectiveDate: discounts.effectiveDate || add(new Date(), { days: 7 }),
    ...discounts,
  };

  const form = useFormik({
    // enableReinitialize: true,
    validateOnChange: formSubmitted,
    initialValues: initialValues,
    validationSchema,
    async onSubmit(values) {
      setDiscounts(values);
      if (mode === "auto") {
        Navigation.go("/auto/wizard/contact");
      }
      if (mode === "bundle") {
        if (upgradedBundle) {
          Navigation.go("/bundle/wizard/contact");
        } else {
          Navigation.go("/bundle/wizard/continue");
        }
      }
      if (mode === "auto-renters") {
        //TODO: rentersUpgrade
        // if (upgradedBundle) {
        //   Navigation.go("/bundle/wizard/contact");
        // } else
        {
          Navigation.go("/auto-renters/wizard/continue");
        }
      }
    },
  });

  const handleWhoDrivesChange = ({ target: { value } }) => {
    const parsed = JSON.parse(value);
    form.setFieldValue(`whoDrives[${parsed.veh_id - 1}]`, parsed);
  };

  return (
    <Form form={form} className="maxWidth">
      <Box className={classes.root}>
        <>
          {Boolean(
            applicantIndex !== undefined &&
              selectedDrivers[applicantIndex]?.continuous_insurance === "Y",
          ) && (
            <>
              <Box className={classes.field}>
                <Typography variant="h6" className={classes.fieldTitle}>
                  How long have you been continuously insured for?
                </Typography>
                <ScrollToOnError name="months_coverage">
                  <FormControl
                    component="fieldset"
                    className={form.errors.months_coverage ? classes.error : ""}
                  >
                    <RadioGroup
                      name="months_coverage"
                      value={form.values.months_coverage || ""}
                      onChange={form.handleChange}
                    >
                      <FormControlLabel
                        value="3"
                        label="Less than 6 months"
                        control={<Radio color="primary" />}
                      />
                      <FormControlLabel
                        value="8"
                        label="6 months - 1 year"
                        control={<Radio color="primary" />}
                      />
                      <FormControlLabel
                        value="24"
                        label="1 - 3 years"
                        control={<Radio color="primary" />}
                      />
                      <FormControlLabel
                        value="60"
                        label="More than 3 years"
                        control={<Radio color="primary" />}
                      />
                    </RadioGroup>
                  </FormControl>
                </ScrollToOnError>

                {Boolean(form.errors.months_coverage) && (
                  <FormHelperText error variant="outlined">
                    {form.errors.months_coverage}
                  </FormHelperText>
                )}
              </Box>

              {/*
                <Box className={classes.field}>
                  <Typography variant="h6" className={classes.fieldTitle}>
                    What is the closest to previous coverage for bodily injury?
                  </Typography>
                  <ScrollToOnError name="bodily_injury">
                    <FormControl component="fieldset">
                      <RadioGroup
                        name="bodily_injury"
                        value={form.values.bodily_injury}
                        onChange={form.handleChange}
                      >
                        {BODILY_INJURY_OPTIONS.map((option, i) => (
                          <FormControlLabel
                            key={`bodily_injury-${i}`}
                            value={JSON.stringify(option.value)}
                            label={option.label}
                            control={<Radio color="primary" />}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </ScrollToOnError>

                  {Boolean(form.errors.bodily_injury) && (
                    <FormHelperText error variant="outlined">
                      {form.errors.bodily_injury}
                    </FormHelperText>
                  )}
                </Box> */}
            </>
          )}

          <Box className={classes.field}>
            <Typography variant="h6" className={classes.fieldTitle}>
              Do you currently own or rent your home?
            </Typography>
            <ScrollToOnError name="residence_owned_rented">
              <FormControl
                component="fieldset"
                className={
                  form.errors.residence_owned_rented ? classes.error : ""
                }
              >
                <RadioGroup
                  name="residence_owned_rented"
                  value={form.values.residence_owned_rented || ""}
                  onChange={form.handleChange}
                >
                  <FormControlLabel
                    value="O"
                    label="I own my home / condo"
                    control={<Radio color="primary" />}
                  />
                  <FormControlLabel
                    value="R"
                    label="I rent"
                    control={<Radio color="primary" />}
                  />
                </RadioGroup>
              </FormControl>
            </ScrollToOnError>

            {Boolean(form.errors.residence_owned_rented) && (
              <FormHelperText error variant="outlined">
                {form.errors.residence_owned_rented}
              </FormHelperText>
            )}
          </Box>

          <Box className={classes.field}>
            <Typography variant="h6" className={classes.fieldTitle}>
              Do any of these discounts apply to you?
            </Typography>

            <ScrollToOnError name="employment_military">
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    name="employment_military"
                    value={form.values.employment_military}
                    label="Active military or veteran"
                    control={<Checkbox color="primary" />}
                    checked={form.values.employment_military}
                    onChange={() =>
                      form.setFieldValue(
                        "employment_military",
                        !form.values.employment_military,
                      )
                    }
                  />
                  <FormControlLabel
                    name="paperless_discount"
                    value={form.values.paperless_discount}
                    label="Paperless"
                    control={<Checkbox color="primary" />}
                    checked={form.values.paperless_discount}
                    onChange={() =>
                      form.setFieldValue(
                        "paperless_discount",
                        !form.values.paperless_discount,
                      )
                    }
                  />
                  <FormControlLabel
                    name="autopay"
                    value={form.values.autopay}
                    label="Autopay"
                    control={<Checkbox color="primary" />}
                    checked={form.values.autopay}
                    onChange={() =>
                      form.setFieldValue("autopay", !form.values.autopay)
                    }
                  />
                </FormGroup>
              </FormControl>
            </ScrollToOnError>
          </Box>

          {selectedDrivers &&
            selectedDrivers?.length > 1 &&
            selectedVehicles?.map((vehicle, index) => (
              <Box key={`vehicle-${index}`} className={classes.whoDrivesField}>
                <Typography variant="h6" className={classes.fieldTitle}>
                  Who is the primary driver of the {vehicle.Maker}{" "}
                  {vehicle.Model} {vehicle.ModelYear} - {vehicle.VIN}?
                </Typography>

                <ScrollToOnError name={`vehicles[${index}]`}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      name={`vehicles[${index}]`}
                      value={
                        form.values.whoDrives
                          ? JSON.stringify(form.values.whoDrives[index])
                          : ""
                      }
                      onChange={handleWhoDrivesChange}
                    >
                      {selectedDrivers?.map((driver, i) => (
                        <FormControlLabel
                          key={`driver-${i}`}
                          value={JSON.stringify({
                            driver_id: i + 1,
                            veh_id: index + 1,
                          })}
                          label={`${driver.FirstName} ${driver.MiddleName} ${driver.LastName}`.replace(
                            /\w\S*/g,
                            function (txt) {
                              return (
                                txt.charAt(0).toUpperCase() +
                                txt.substring(1).toLowerCase()
                              );
                            },
                          )}
                          control={<Radio color="primary" />}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </ScrollToOnError>
              </Box>
            ))}

          {Boolean(form.errors.whoDrives) && (
            <FormHelperText error variant="outlined">
              {form.errors.whoDrives}
            </FormHelperText>
          )}
        </>
        <Box className={classes.field}>
          <div className={classes.info}>
            <Typography variant="h6" className={classes.fieldTitle}>
              When do you want the policy to take effect?
              <Tooltip
                title="15 days in advance will get you the most savings"
                placement="top"
              >
                <InfoIcon />
              </Tooltip>
            </Typography>
          </div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6}>
              <ScrollToOnError name="effectiveDate">
                <DatePicker
                  label="MM/DD/YYYY"
                  format="MM/dd/yyyy"
                  disablePast={true}
                  value={
                    (form.values.effectiveDate &&
                      new Date(form.values.effectiveDate)) ||
                    null
                  }
                  maxDate={EFFECTIVE_MIN_DATE}
                  onChange={(date) => form.setFieldValue("effectiveDate", date)}
                />
              </ScrollToOnError>
              {Boolean(form.errors.effectiveDate) && (
                <FormHelperText error variant="outlined">
                  {form.errors.effectiveDate}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid container justifyContent="center" alignItems="center">
        <Button
          fullWidth={Boolean(isMobile)}
          id="submit"
          onClick={() => setFormSubmitted(true)}
          type="submit"
          variant="contained"
          color="primary"
        >
          Let's keep moving
        </Button>
      </Grid>
    </Form>
  );
};

export default DiscountFields;
