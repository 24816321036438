/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Container,
  Grid,
  Hidden,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { MenuIcon } from "../../components/icons";
// import { initReqClientSocket, reqSocket } from "../../utils/socket";
import { useStyles } from "./WizardLayout.styles";
import WizardTitle from "./WizardStepTitle";
import WizardStepper from "./stepper/Stepper";
import Sidebar from "../sidebar/Sidebar";
import Lottie from "lottie-react";
import {
  /* fbChatInit, */ useLocation,
  /* liveChatInit,*/ formatPhone,
} from "../../lib";
import {
  autoSelectors,
  homeSelectors,
  uiSelectors,
  useAction,
  useDispatch,
  useSelector,
  wizardActions,
  wizardNoPersistActions,
  wizardNoPersistSelectors,
  authActions,
  adminActions,
} from "../../state";
import relianceLoader from "../../assets/reliance-loader.json";
import reliExchangeLoader from "../../assets/reliExchange-loader.gif";
import ModalCoverageType from "../../components/modals/ModalCoverageType";
import ModalContact from "../../components/modals/ModalContact";
import { ModalExitIntent } from "../../components/modals/ModalExitIntent";
import { MainSnackbar } from "../../components/snackbars";
import powerByLogo from "../../assets/img/reliExchange.png";
import { IS_AGENCY_SITE, LOGIN_IFRAME_POST_URL } from "../../config";
// import { useMobile } from "../../themes";
import { store } from "../../state/store";
import ModalCarrier from "../../components/modals/ModalCarrier";
import ModalAddress from "../../components/modals/ModalAddress";

console.log("LOGIN_IFRAME_POST_URL", LOGIN_IFRAME_POST_URL);

window.addEventListener("message", async (e) => {
  const dispatch: any = store.dispatch;
  if (
    e.origin === LOGIN_IFRAME_POST_URL &&
    e.data &&
    typeof e.data === "string" &&
    e.data?.startsWith("eyJhbG") //atob("eyJhbG")='{"al' the beginning of all JWT
  ) {
    //TODO: delete below
    const state: any = store.getState();
    if (!state?.auth?.token) {
      await dispatch(authActions.setToken(e.data));
      dispatch(adminActions.refreshToken());
    }
  } else if (
    e.origin === LOGIN_IFRAME_POST_URL &&
    e.data &&
    e.data === "logout"
  ) {
    await dispatch(authActions.setAuthState(undefined));
    dispatch(adminActions.refreshToken());
  }
});

export function WizardLayout({ children }) {
  const iframeRef: any = useRef();
  const classes = useStyles();
  const dispatch = useDispatch();
  // const isMobile = useMobile();
  const location = useLocation();
  const loading = useSelector(uiSelectors.loading);
  const { phone } = useSelector(uiSelectors.agencyData);
  // const isFbUser = useSelector(uiSelectors.fbUser);
  const loadingMessage = useSelector(uiSelectors.loadingMessage);
  const [mobileOpen, setMobileOpen] = useState(false);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const start =
    mode === "home" || mode === "renters"
      ? useSelector(homeSelectors.start)
      : useSelector(autoSelectors.startForm);
  // const req_uid = useSelector(wizardSelectors.req_uid);

  // const activeStep = useSelector(wizardSelectors.activeStep);
  const setWizardMode = useAction(wizardNoPersistActions.setWizardMode);
  const setActiveWizardStep = useAction(wizardActions.setActiveWizardStep);
  // const isQuoteSelected = useSelector(wizardSelectors.isQuoteSelected) || null;

  const isLoadingAgency = useSelector(uiSelectors.loadingAgency);

  const contains = (url) => location.pathname.includes(url);
  // TODO: renters bundle
  const getActiveStep = () => {
    switch (true) {
      // bundle only has 3 steps, auto and home pages are in a nested stepper
      case contains("/home/wizard/start"):
      case contains("/renters/wizard/start"):
      case contains("/auto/wizard/start"):
      case contains("/life/wizard/start"):
      case contains("/bundle/wizard/start"):
      case contains("/bundle/wizard/vehicles"):
      case contains("/bundle/wizard/drivers"):
      case contains("/bundle/wizard/auto-discounts"):
      case contains("/auto-renters/wizard/start"):
      case contains("/auto-renters/wizard/vehicles"):
      case contains("/auto-renters/wizard/drivers"):
      case contains("/auto-renters/wizard/auto-discounts"):
        return 0;
      case contains("/home/wizard/details/form"):
      case contains("/renters/wizard/details/form"):
      case contains("/home/wizard/details/confirm"):
      case contains("/auto/wizard/vehicles"):
      case contains("/bundle/wizard/details/form"):
      case contains("/bundle/wizard/details/confirm"):
      case contains("/bundle/wizard/home-discounts"):
      case contains("/bundle/wizard/insured"):
      case contains("/life/wizard/insured"):
      case contains("/auto-renters/wizard/details/form"):
      case contains("/auto-renters/wizard/home-discounts"):
      case contains("/auto-renters/wizard/insured"):
        return 1;
      case contains("/home/wizard/discounts"):
      case contains("/renters/wizard/discounts"):
      case contains("/auto/wizard/drivers"):
      case contains("/bundle/wizard/contact"):
      case contains("/life/wizard/coverage"):
      case contains("/auto-renters/wizard/contact"):
        return 2;
      case contains("/home/wizard/insured"):
      case contains("/renters/wizard/insured"):
      case contains("/auto/wizard/discounts"):
      case contains("/life/wizard/contact"):
        return 3;
      case contains("/home/wizard/contact"):
      case contains("/renters/wizard/contact"):
      case contains("/auto/wizard/contact"):
      case contains("/life/wizard/quotes"):
        return 4;
      case contains("/home/wizard/quotes"):
      case contains("/renters/wizard/quotes"):
      case contains("/auto/wizard/quotes"):
      case contains("/bundle/wizard/quotes"):
      case contains("/auto-renters/wizard/quotes"):
        return 5;
      default:
        return 0;
    }
  };

  useEffect(() => {
    setWizardMode(
      location.pathname.includes("bundle")
        ? "bundle"
        : location.pathname.includes("auto-renters")
        ? "auto-renters"
        : location.pathname.includes("home")
        ? "home"
        : location.pathname.includes("renters")
        ? "renters"
        : location.pathname.includes("auto")
        ? "auto"
        : location.pathname.includes("life")
        ? "life"
        : "",
    );
    setActiveWizardStep(getActiveStep());
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (!start.address?.state) return;
    dispatch(wizardActions.getStateRequirements());
  }, [start]);

  useEffect(() => {
    dispatch(wizardActions.getWizardSettings());
  }, []);

  // useEffect(() => {
  //   if (IS_AGENCY_SITE) return;
  //   if (isFbUser) {
  //     fbChatInit();
  //     window.LiveChatWidget.call("hide");
  //   } else {
  //     liveChatInit();
  //     window.LiveChatWidget.call("hide");
  //   }
  // }, [isFbUser]);

  // useEffect(() => {
  //   const notShowing = isMobile && activeStep > 4;
  //   if (IS_AGENCY_SITE || isFbUser || notShowing || isQuoteSelected) return;
  //   const timer = setTimeout(() => {
  //     window.LiveChatWidget && window.LiveChatWidget.call("minimize");
  //   }, 30000);
  //   return () => {
  //     clearTimeout(timer);
  //     window.LiveChatWidget.call("hide");
  //   };
  // }, [location.pathname, isQuoteSelected, isMobile, activeStep, isFbUser]);

  // this is used when admin triggers show payment
  // useEffect(() => {
  //   if (req_uid) {
  //     initReqClientSocket(req_uid);
  //     if (reqSocket) {
  //       reqSocket.on("new_message", (e) => {
  //         switch (e.action) {
  //           case "getSelectedQuote":
  //             dispatch(wizardActions.getQuoteData(e.req_txn_id));
  //             break;
  //           case "showPmtPopup":
  //             dispatch(wizardActions.showPmtPopup(e.showPaymentPopup));
  //             break;
  //           default:
  //             console.error("not handled");
  //         }
  //       });
  //     }
  //   }
  // }, [dispatch, req_uid, reqSocket]);
  return isLoadingAgency ? null : (
    <Grid
      container
      wrap="nowrap"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <Box className={classes.content}>
        <Box>
          <Hidden smDown>
            <Typography className={classes.helpText}>
              Need help? Call us at{" "}
              <Link href={`tel:${phone}`}>{formatPhone(phone)}</Link>
            </Typography>
          </Hidden>
          <Hidden smUp>
            <Box className={classes.mobileHeader}>
              <Box
                className={classes.menuIconWrapper}
                onClick={() => {
                  setMobileOpen(!mobileOpen);
                }}
              >
                <MenuIcon />
              </Box>
            </Box>
          </Hidden>
          <WizardTitle />
          <Container className={classes.wizardStepContent}>
            {children}
          </Container>
        </Box>
        <WizardStepper />
        {/* Notifications are displayed in main snackbar */}
        <MainSnackbar />
        {loading &&
          (loadingMessage === "Loading quotes..." ? (
            <div className={classes.quotesLoadingBackground}>
              <div>
                <div className={classes.insuranceLogosBanner} />
                <Box
                  className={classes.quotesLoadingIcon}
                  style={{
                    pointerEvents: "none",
                    height: "300px",
                    width: "300px",
                    display: "flex",
                    margin: "0 auto",
                  }}
                >
                  {IS_AGENCY_SITE ? (
                    <img
                      src={reliExchangeLoader}
                      style={{ marginBottom: 20, padding: 45 }}
                    ></img>
                  ) : (
                    <Lottie animationData={relianceLoader} loop />
                  )}
                </Box>
                <Typography className={classes.quotesLoadingText}>
                  Getting you quotes from many competitive carriers…
                </Typography>
                <Typography className={classes.quotesLoadingSubtext}>
                  See you in less than 30 seconds...
                </Typography>
              </div>
            </div>
          ) : (
            <Backdrop className={classes.backdrop} open={true}>
              <Box
                style={{
                  pointerEvents: "none",
                  height: "300px",
                  width: "300px",
                }}
              >
                {IS_AGENCY_SITE ? (
                  <img
                    src={reliExchangeLoader}
                    style={{ marginBottom: 20, height: "200px" }}
                  ></img>
                ) : (
                  <Lottie animationData={relianceLoader} loop />
                )}
              </Box>
              {loadingMessage && <Typography>{loadingMessage}</Typography>}
            </Backdrop>
          ))}
      </Box>
      {IS_AGENCY_SITE && (
        <Paper className={classes.poweredBy}>
          <Link href="https://reliexchange.com">
            <Typography>Powered by</Typography>
            <img src={powerByLogo} alt="Reli exchange logo" />
          </Link>
        </Paper>
      )}
      <ModalCoverageType />
      <ModalExitIntent />
      <ModalContact />
      <ModalAddress />
      <ModalCarrier />
      <iframe
        ref={iframeRef}
        onLoad={() => {
          iframeRef?.current?.contentWindow.postMessage(
            "send_token",
            LOGIN_IFRAME_POST_URL,
          );
        }}
        style={{
          border: "none",
          outline: "none",
          width: 1,
          height: 1,
          position: "fixed",
          top: 10,
          left: 10,
        }}
        src={`${LOGIN_IFRAME_POST_URL}/receiver.html`}
      />
    </Grid>
  );
}
