import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  formCard: {
    borderRadius: 3,
    border: "none",
    boxShadow: "0 2px 24px 0 #E0E0E0, 0 2px 40px 0 #F9FAFC",
    padding: "40px 30px 12px 30px",
    maxWidth: 540,
    margin: "0 auto",
  },
  formWrap: {
    padding: 0,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 32,
    textAlign: "center",
  },
  profileImage: {
    color: "#EFF1FB",
    height: 140,
    width: 140,
    margin: "0 auto",
    display: "flex",
    marginBottom: 32,
    borderRadius: "50%",
  },
  imageButtonsWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60%",
    margin: "0 auto",
  },
  paper: {
    top: "50%",
    left: "50%",
    position: "absolute",
    width: 400,
    height: "auto",
    backgroundColor: "#FFFFFF",
    transform: "translate(-50%, -50%)",
    maxWidth: "96%",
    maxHeight: "96%",
    padding: "24px 16px",
    display: "flex",
    flexDirection: "column",
    "& .reactEasyCrop_Container": {
      position: "relative",
    },
    "& .reactEasyCrop_Contain": {
      position: "relative",
    },
  },
  slider: {
    marginLeft: 8,
    marginRight: 16,
  },
  btnWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 24,
  },
  cancelBtn: {
    width: 98,
    marginRight: 12,
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
  },
  submitBtn: {
    width: 98,
    alignSelf: "flex-end",
  },
  formControl: {
    width: "50%",
    maxWidth: 300,
    marginTop: 40,
    padding: 6,
    paddingBottom: 18,

    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  formControlFull: {
    width: "100%",
    padding: 6,
    paddingBottom: 18,
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  formAction: {
    paddingRight: 6,
  },
  asterisk: {
    color: "red",
  },
  saveBtn: {
    width: 98,
    marginTop: 24,
  },
  smallText: {
    fontSize: 13,
    fontWeight: 300,
    margin: "8px 0px 24px 0px",
    paddingRight: 8,
  },
  uploader: {
    display: "none",
  },
  mediumText: {
    fontSize: 14,
    fontFamily: "Lato",
    padding: 8,
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  bigText: {
    fontSize: 16,
  },
  icon: {
    color: theme.palette.primary.main,
    height: 22,
  },
  uploadWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.5,
    },
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
}));
