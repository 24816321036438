import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  select: {
    fontSize: 14,
  },
  fieldName: {
    marginRight: 7,

    fontSize: 12,
    fontWeight: 700,
  },
  fieldValue: {
    fontSize: 12,
    fontWeight: 500,
  },
  card: {
    width: "100%",
    height: 74,
    borderBottom: ({ isLast }: any) => (isLast ? "none" : ".5px solid #DADFF6"),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 3,
    outline: ({ isSelectedQuote, purchased, pending }: any) =>
      purchased
        ? "2px solid #2EB662"
        : pending
        ? "2px solid #FFAE00"
        : isSelectedQuote
        ? `2px solid ${theme.palette.primary.main}`
        : ".5px solid #FFFFFF",
    "&:hover": {
      boxShadow: ({ isSelectedQuote, purchased, pending }: any) =>
        isSelectedQuote || purchased || pending
          ? "none"
          : "0 2px 20px 0 rgba(141,152,194,0.15)",
      border: "none",
      outline: ({ isSelectedQuote, purchased, pending }: any) =>
        purchased
          ? "2px solid #2EB662"
          : pending
          ? "2px solid #FFAE00"
          : isSelectedQuote
          ? `2px solid ${theme.palette.primary.main}`
          : ".5px solid #FFFFFF",
    },
  },
  selectedQuoteTag: {
    height: 22,
    width: 118,
    outline: ({ purchased, pending }: any) =>
      purchased
        ? "2px solid #2EB662"
        : pending
        ? "2px solid #FFAE00"
        : `2px solid ${theme.palette.primary.main}`,
    borderRadius: "3px 3px 0 0",
    backgroundColor: ({ purchased, pending }: any) =>
      purchased ? " #2EB662" : pending ? "#FFAE00" : theme.palette.primary.main,
    margin: "12px 0px 2px 0px",
    "& .MuiTypography-root": {
      fontSize: 12,
      color: "#FFFFFF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 2,
    },
  },
  carrierImg: {
    height: 20,
    marginLeft: 12,
    maxWidth: 110,
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 100,
      margin: 0,
      paddingLeft: 12,
    },
  },
  carrierName: {
    margin: "0 25px",
    verticalAlign: "middle",
  },
  imgWrapper: {
    width: 140,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 100,
    },
  },
  cardLeft: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  purchaseBtn: {
    color: "#0033FF",
    textDecoration: "underline",
    fontWeight: 500,
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
    },
  },
  quoteDataWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 42,
    [theme.breakpoints.down("md")]: {
      gap: 24,
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("sm")]: {
      gap: 18,
    },
  },
  quotePremiumTxt: {
    fontWeight: 600,
  },
  manualQuoteText: {
    fontWeight: 600,
    marginRight: 25,
  },
  bindLink: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: 16,
    paddingRight: 5,
  },
  quoteFailedText: {
    fontWeight: 500,
    color: "#FF0000",
    fontSize: 14,
    marginRight: 8,
    textTransform: "uppercase",
  },
  quoteFailedIcon: {
    fontSize: 20,
    color: "#8d98c2",
    "&:hover": {
      cursor: "pointer",
    },
  },
  infoContainer: {
    marginLeft: 13,
    display: "flex",
  },
  carrierInfoIcon: {
    fontSize: 20,
    marginLeft: 4,
    color: "#8d98c2",
    "&:hover": {
      cursor: "pointer",
    },
  },
  linksWrapper: { display: "flex", alignItems: "center" },
  emailLink: {
    color: theme.palette.primary.main,
    marginLeft: 15,
    marginRight: 10,
    verticalAlign: "middle",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      color: "white",
      width: 40,
      height: 40,
      minWidth: 40,
      backgroundColor: "#0033ff",
      margin: 0,
    },
  },
  lifeProductInfo: {
    marginLeft: 12,
  },
  mobileQuoteWrapper: {
    padding: 16,
    boxShadow: "0 2px 20px 0 rgba(141,152,194,0.15)",
    borderRadius: 3,
    "& img": {
      margin: 0,
      maxHeight: 35,
      height: "auto",
    },
    "& a": {
      "& div": {
        padding: 0,
        fontWeight: 600,
      },
    },
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topDiv: {
    marginBottom: 15,
  },
}));
