import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { CloseIcon, InfoIconOutlined } from "../../../../components/icons";
import {
  useAction,
  autoActions,
  useSelector,
  autoSelectors,
  authSelectors,
  wizardNoPersistSelectors,
} from "../../../../state";
import {
  getLimits,
  getDeductibles,
  getOptions,
  customQuoteValidationSchema as validationSchema,
  getLimits_Rental,
  getDefaultValue,
} from "../../../../utils";
import { Form, useFormik } from "../../../../lib";
import { CustomQuoteFormShape } from "../../../../state/auto/types";

import ReactGA from "react-ga";
import { useStyles } from "./CustomQuoteForm.styles";
import ScrollToOnError from "../../../../components/forms/ScrollToOnError";
import { BiImage } from "../../../../assets/img/coverages/bi";
import { UmImage } from "../../../../assets/img/coverages/um";
import { PdImage } from "../../../../assets/img/coverages/pd";
import { UmpdImage } from "../../../../assets/img/coverages/umpd";
import { Med_PipImage } from "../../../../assets/img/coverages/med_pip";
import { CompImage } from "../../../../assets/img/coverages/comp";
import { CollImage } from "../../../../assets/img/coverages/coll";
import { TowingImage } from "../../../../assets/img/coverages/towing";
import { RentalImage } from "../../../../assets/img/coverages/rental";

const CustomQuoteForm = ({ handleCloseModal, bundled, setFormValue }) => {
  const classes = useStyles();
  const theme = useTheme();

  const setCustomQuoteForm = useAction(autoActions.setCustomQuoteForm);
  const setAgentCustomForm = useAction(autoActions.setAgentCustomForm);
  const setAgentBundleQuote = useAction(autoActions.setAgentBundleQuote);
  const setCustomData = useAction(autoActions.setCustomData);

  const coverages = useSelector(autoSelectors.coveragesObj) || {};
  const customData = useSelector(autoSelectors.customData);
  const defaults = useSelector(autoSelectors.defaultCoverages) || {};
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const { selectedVehicles = [] } = useSelector(autoSelectors.vehiclesForm);

  const state = useSelector(autoSelectors.state);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  // General options
  const biLimits = getLimits(coverages.BI).filter((x) => x.label.includes("/"));
  const pdLimits = getLimits(coverages.PD);
  const umLimits = getLimits(coverages.UM).filter((x) => x.label.includes("/"));
  const umpdLimits = getLimits(coverages.UMPD);
  const umpdDeductibles = getDeductibles(coverages.UMPD);
  const medpmLimits = coverages.MEDPM
    ? getLimits(coverages.MEDPM)
    : getLimits(coverages.MEDEX);
  const attendantCareLimits = getLimits(coverages.MCARE);
  const pipLimits = getLimits(coverages.PIP);
  const pipDeductibles = getDeductibles(coverages.PIP);
  const extendedMedicalLimits = getLimits(coverages.EXTMED);
  const obelLimits = getLimits(coverages.OBEL);
  const medicalExpenseOnlyOptions = getOptions(coverages.MEDEXPONLY);
  const tortOptions = getOptions(coverages.TORT);

  // Vehicle options
  const collCoverages = [...getDeductibles(coverages.COLL)];
  const compCoverages = [...getDeductibles(coverages.COMP)];
  const tlCoverages = [...getOptions(coverages.TW)];
  const rreimCoverages = [...getLimits_Rental(coverages.RREIM)];

  //Requirements
  const biRequired = coverages.BI?.IsRequired === "true";
  const pdRequired = coverages.PD?.IsRequired === "true";
  const umRequired = coverages.UM?.IsRequired === "true";
  const undumRequired = coverages.UNDUM?.IsRequired === "true";
  const umpdRequired = coverages.UMPD?.IsRequired === "true";
  const umpdDeductibleRequired = Boolean(umpdDeductibles?.length);
  const undpdRequired = coverages.UNDPD?.IsRequired === "true";
  const collRequired = coverages.COLL?.IsRequired === "true";
  const compRequired = coverages.COMP?.IsRequired === "true";
  const tlRequired = coverages.TL?.IsRequired === "true";
  const rreimRequired = coverages.RREIM?.IsRequired === "true";
  const pipRequired = coverages.PIP?.IsRequired === "true";
  const pipDeductibleRequired = Boolean(pipRequired && pipDeductibles?.length);
  const medpmRequired =
    coverages.MEDPM?.IsRequired === "true" ||
    coverages.MEDEX?.IsRequired === "true";
  const attendantCareRequired = coverages.MCARE?.IsRequired === "true";

  const CustomQuoteInitialValues = {
    bi:
      customData?.bi ||
      JSON.stringify(
        getLimits(defaults.filter((x) => x.CoverageCd === "bi")[0])[0],
      ),
    pd:
      customData?.pd ||
      JSON.stringify(
        getLimits(defaults.filter((x) => x.CoverageCd === "pd")[0])[0],
      ),
    um:
      customData?.um ||
      JSON.stringify(
        getLimits(defaults.filter((x) => x.CoverageCd === "um")[0])[0] ||
          getDefaultValue(umLimits),
      ),
    undum: customData?.undum || "",
    umpd:
      customData?.umpd ||
      JSON.stringify(
        getLimits(defaults.filter((x) => x.CoverageCd === "umpd")[0])[0] ||
          getDefaultValue(umpdLimits),
      ),
    umpdded:
      customData?.umpdded ||
      JSON.stringify(
        getDeductibles(defaults.filter((x) => x.CoverageCd === "umpd")[0])[0] ||
          getDefaultValue(umpdDeductibles),
      ),
    undpd: customData?.undpd || "",
    medex:
      customData?.medex ||
      JSON.stringify(
        getLimits(defaults.filter((x) => x.CoverageCd === "medex")[0])[0] ||
          getDefaultValue(medpmLimits),
      ),
    pip:
      customData?.pip ||
      JSON.stringify(
        getLimits(defaults.filter((x) => x.CoverageCd === "pip")[0])[0] ||
          getDefaultValue(pipLimits),
      ),
    pipded:
      customData?.pipded ||
      JSON.stringify(
        getDeductibles(defaults.filter((x) => x.CoverageCd === "pip")[0])[0] ||
          getDefaultValue(pipDeductibles),
      ),
    attendantcare: JSON.stringify(
      getLimits(
        defaults.filter((x) => x.CoverageCd === "attendantcare")[0],
      )[0] || getDefaultValue(attendantCareLimits),
    ),
    medicalexpenseonly: JSON.stringify(
      getDefaultValue(medicalExpenseOnlyOptions),
    ),
    extendedmedical: JSON.stringify(getDefaultValue(extendedMedicalLimits)),
    obel: JSON.stringify(getDefaultValue(obelLimits)),
    tort: JSON.stringify(getDefaultValue(tortOptions)),
    vehicles: [],
    umpdEnabled: umpdRequired,

    biRequired: false,
    pdRequired: false,
    umRequired: false,
    umpdRequired: false,
    umpdDeductibleRequired: false,
    medpmRequired: false,
    collRequired: false,
    compRequired: false,
    tlRequired: false,
    rreimRequired: false,
    pipRequired: false,
    pipDeductibleRequired: false,
    attendantCareRequired: false,
    medicalExpenseOnlyRequired: false,
    extendedMedicalRequired: false,
    tortRequired: false,
  };

  const initialValues: CustomQuoteFormShape = {
    ...CustomQuoteInitialValues,
    biRequired,
    pdRequired,
    umRequired,
    umpdRequired,
    umpdDeductibleRequired,
    medpmRequired,
    collRequired,
    compRequired,
    tlRequired,
    rreimRequired,
    pipRequired,
    pipDeductibleRequired,
    attendantCareRequired,
    vehicles: selectedVehicles.map((v, i) => ({
      coll:
        customData?.vehicles[i]?.coll ||
        JSON.stringify(
          getDeductibles(
            defaults.filter((x) => x.CoverageCd === "coll")[0],
          )[0] || getDefaultValue(collCoverages),
        ),
      comp:
        customData?.vehicles[i]?.comp ||
        JSON.stringify(
          getDeductibles(
            defaults.filter((x) => x.CoverageCd === "comp")[0],
          )[0] || getDefaultValue(compCoverages),
        ),
      towing:
        customData?.vehicles[i]?.towing ||
        JSON.stringify(getDefaultValue(tlCoverages)),
      rental:
        customData?.vehicles[i]?.rental ||
        JSON.stringify(getDefaultValue(rreimCoverages)),
      collEnabled:
        customData?.vehicles[i]?.collEnabled === false ? false : true,
      compEnabled:
        customData?.vehicles[i]?.compEnabled === false ? false : true,
      towingEnabled: customData?.vehicles[i]?.towingEnabled,
      rentalEnabled: customData?.vehicles[i]?.rentalEnabled,
      info: v,
    })),
  };

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [medicalSelection, setMedicalSelection] = useState("pip");

  const form = useFormik({
    enableReinitialize: true,
    validateOnChange: formSubmitted,
    initialValues,
    validationSchema,
    async onSubmit(values) {
      handleCloseModal();
      setCustomData(values);
      document.getElementById("wizardContentContainer")?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (isLoggedIn && (mode === "bundle" || mode === "auto-renters")) {
        setAgentBundleQuote(values);
        return;
      }
      if (isLoggedIn) {
        setAgentCustomForm(values);
        return;
      }
      setCustomQuoteForm(values, bundled);
      ReactGA.event({
        category: "User",
        action: "Submitted Custom Quote",
      });
    },
  });

  useEffect(() => {
    if (undumRequired && form.values.um) {
      form.values.undum = form.values.um;
    }
    // eslint-disable-next-line
  }, [form.values.um]);

  useEffect(() => {
    if (undpdRequired && form.values.umpd) {
      form.values.undpd = form.values.umpd;
    }
    // eslint-disable-next-line
  }, [form.values.umpd, undpdRequired]);

  useEffect(() => {
    if (state === "TX") {
      if (medicalSelection === "pip") {
        form.setFieldValue("pipEnabled", true);
        form.setFieldValue("medEnabled", false);
      } else {
        form.setFieldValue("pipEnabled", false);
        form.setFieldValue("medEnabled", true);
      }
    } else {
      form.setFieldValue("medEnabled", true);
      form.setFieldValue("pipEnabled", true);
    }
  }, [medicalSelection, form.values.pipEnabled, form.values.medEnabled]);

  useEffect(() => {
    ReactGA.event({
      category: "User",
      action: "Custom Quote Form Popup",
    });
  }, []);

  const errorUM = () => {
    return Boolean(
      Number(JSON.parse(form.values.bi)?.Val1) <
        Number(JSON.parse(form.values.um)?.Val1) ||
        Number(JSON.parse(form.values.bi)?.Val2) <
          Number(JSON.parse(form.values.um)?.Val2),
    );
  };

  const errorUMPD = () => {
    return Boolean(
      Number(JSON.parse(form.values.pd)?.Val1) <
        Number(JSON.parse(form.values.umpd)?.Val1) ||
        Number(JSON.parse(form.values.pd)?.Val2) <
          Number(JSON.parse(form.values.umpd)?.Val2),
    );
  };

  const handleClose = () => {
    if (isLoggedIn && (mode === "bundle" || mode === "auto-renters")) {
      setFormValue();
      setCustomData(form.values);
    } else {
      handleCloseModal();
    }
  };

  return <>
    {!isLoggedIn ? (
      <Box className={classes.titleWrapper}>
        <Box>
          <Typography variant="h5" className={classes.title}>
            Create a custom quote
          </Typography>
        </Box>

        <Box className={classes.closeIconWrapper}>
          <IconButton edge="start" color="inherit" onClick={handleCloseModal} size="large">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Box>
      </Box>
    ) : (
      <Box className={classes.titleWrapper}>
        <Box>
          <Typography variant="h5" className={classes.title}>
            Set auto coverages
          </Typography>
        </Box>
        {mode === "auto" && (
          <Box className={classes.closeIconWrapper}>
            <IconButton edge="start" color="inherit" onClick={handleCloseModal} size="large">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>
        )}
      </Box>
    )}

    <Form form={form}>
      {Boolean(biLimits?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <BiImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Bodily Injury Liability
              </Typography>
              <Typography className={classes.description}>
                Pays for injuries to another person due to an accident that's
                your fault.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="bi">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="bi"
                    name="bi"
                    value={form.values.bi}
                    defaultValue={null}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.bi)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {biLimits.map((coverage, i) => (
                      <MenuItem
                        key={`biCoverage-${i}`}
                        value={JSON.stringify(coverage)}
                      >
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per person / per incident
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {Boolean(pdLimits?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <PdImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Property Damage Liability
              </Typography>
              <Typography className={classes.description}>
                Pays for property damage due to an accident that's your fault.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="pd">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="pd"
                    name="pd"
                    value={form.values.pd}
                    defaultValue={null}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.pd)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {pdLimits.map((coverage, i) => (
                      <MenuItem
                        key={`pdCoverage-${i}`}
                        value={JSON.stringify(coverage)}
                      >
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {Boolean(umLimits?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <UmImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Uninsured/Underinsured Motorist Bodily Injury
              </Typography>
              <Typography className={classes.description}>
                Pays for medical expenses if someone gets hurt from an
                uninsured/underinsured driver.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="um">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="um"
                    name="um"
                    value={form.values.um}
                    defaultValue={null}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.um)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: 16,
                        color: errorUM()
                          ? "#EA0D26"
                          : theme.palette.primary.main,
                        fontWeight: 500,
                        height: 30,
                      },
                    }}
                  >
                    {umLimits.map((coverage, i) => (
                      <MenuItem
                        key={`umCoverage-${i}`}
                        disabled={
                          Number(JSON.parse(form.values.bi)?.Val1) <
                            Number(coverage.Val1) ||
                          Number(JSON.parse(form.values.bi)?.Val2) <
                            Number(coverage.Val2)
                        }
                        value={JSON.stringify(coverage)}
                      >
                        {coverage.label}
                      </MenuItem>
                    ))}
                    {umLimits.filter(
                      (coverage) =>
                        Number(JSON.parse(form.values.bi)?.Val1) <
                          Number(coverage.Val1) ||
                        Number(JSON.parse(form.values.bi)?.Val2) <
                          Number(coverage.Val2),
                    ).length && (
                      <MenuItem
                        className={classes.coverageDisabledInfoWrapper}
                        disabled
                      >
                        <div className={classes.coverageDisabledInfoBox}>
                          <InfoIconOutlined className={classes.infoIcon} />
                          <Typography
                            className={classes.coverageDisabledInfoText}
                          >
                            Coverage can’t exceed 'Bodily Injury Liability'
                          </Typography>
                        </div>
                      </MenuItem>
                    )}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per person / per incident
                </Typography>
                {errorUM() && (
                  <Box className={classes.coverageErrorWrapper}>
                    <InfoIconOutlined className={classes.coverageErrorIcon} />
                    <Typography className={classes.coverageErrorText}>
                      Coverage can’t exceed 'Bodily Injury Liability'
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
      {Boolean(umpdLimits?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <UmpdImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Uninsured/Underinsured Motorist Property Damage
              </Typography>
              <Typography className={classes.description}>
                Pays for property damage due to an accident caused by an
                uninsured/underinsured driver.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                {!umpdRequired && (
                  <Switch
                    className={classes.coverageSwitch}
                    checked={form.values.umpdEnabled}
                    color="primary"
                    onChange={() => {
                      form.setFieldValue(
                        `umpdEnabled`,
                        !form.values.umpdEnabled,
                      );
                    }}
                    value={form.values.umpdEnabled}
                  />
                )}
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="umpd">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="umpd"
                    name="umpd"
                    disabled={!form.values.umpdEnabled}
                    value={form.values.umpd}
                    defaultValue={null}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.umpd)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: 16,
                        color: errorUMPD()
                          ? "#EA0D26"
                          : theme.palette.primary.main,
                        fontWeight: 500,
                        height: 30,
                      },
                    }}
                  >
                    {umpdLimits.map((coverage, i) => (
                      <MenuItem
                        key={`umpdCoverage-${i}`}
                        disabled={
                          Number(JSON.parse(form.values.pd)?.Val1) <
                            Number(coverage.Val1) ||
                          Number(JSON.parse(form.values.pd)?.Val2) <
                            Number(coverage.Val2)
                        }
                        value={JSON.stringify(coverage)}
                      >
                        {coverage.label}
                      </MenuItem>
                    ))}
                    {umpdLimits.filter(
                      (coverage) =>
                        Number(JSON.parse(form.values.pd)?.Val1) <
                          Number(coverage.Val1) ||
                        Number(JSON.parse(form.values.pd)?.Val2) <
                          Number(coverage.Val2),
                    ).length && (
                      <MenuItem
                        className={classes.coverageDisabledInfoWrapper}
                        disabled
                      >
                        <div className={classes.coverageDisabledInfoBox}>
                          <InfoIconOutlined className={classes.infoIcon} />
                          <Typography
                            className={classes.coverageDisabledInfoText}
                          >
                            Coverage can’t exceed 'Property Damage Liability'
                          </Typography>
                        </div>
                      </MenuItem>
                    )}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
                {errorUMPD() && (
                  <Box className={classes.coverageErrorWrapper}>
                    <InfoIconOutlined className={classes.coverageErrorIcon} />
                    <Typography className={classes.coverageErrorText}>
                      Coverage can’t exceed 'Property Damage Liability'
                    </Typography>
                  </Box>
                )}
              </Box>
              {Boolean(umpdDeductibles?.length) && (
                <Box className={classes.coverageAmountWrapper}>
                  <Typography className={classes.coverageHeader_2}>
                    Deductible
                  </Typography>
                  <ScrollToOnError name="umpd">
                    <TextField
                      select
                      variant="standard"
                      className={classes.dropdown}
                      id="umpdded"
                      name="umpdded"
                      value={form.values.umpdded}
                      disabled={!form.values.umpdEnabled}
                      defaultValue={null}
                      onChange={form.handleChange}
                      error={Boolean(form.errors.umpdded)}
                      size="small"
                      InputProps={{
                        disableUnderline: true,
                        className: classes.dropdownText,
                      }}
                    >
                      {umpdDeductibles.map((coverage, i) => (
                        <MenuItem
                          key={`umpddedCoverage-${i}`}
                          value={JSON.stringify(coverage)}
                        >
                          {coverage.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ScrollToOnError>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
      {state === "TX" && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.medInfo}>
            <Box className={classes.medInfoTextWrapper}>
              <InfoIconOutlined className={classes.infoIcon} />
              Medical Payments and Personal Injury Protection are mutually
              exclusive
            </Box>
            <ToggleButtonGroup
              className={classes.switcherGroup}
              exclusive
              size="small"
              value={medicalSelection}
              onChange={() => {
                if (medicalSelection === "pip") {
                  setMedicalSelection("med");
                } else {
                  setMedicalSelection("pip");
                }
              }}
            >
              <ToggleButton value={"med"} className={classes.switcher}>
                Medical
              </ToggleButton>
              <ToggleButton value={"pip"} className={classes.switcher}>
                Pip
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </>
      )}
      {Boolean(medpmLimits?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <Med_PipImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Medical Payments
              </Typography>
              <Typography className={classes.description}>
                Pays for medical expenses for you and your passengers
                regardless of who is at fault.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="medex">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="medex"
                    name="medex"
                    value={form.values.medex}
                    defaultValue={null}
                    onChange={form.handleChange}
                    disabled={!form.values.medEnabled}
                    error={Boolean(form.errors.medex)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {medpmLimits.map((coverage, i) => (
                      <MenuItem
                        key={`medpmCoverage-${i}`}
                        value={JSON.stringify(coverage)}
                      >
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {Boolean(pipLimits?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <Med_PipImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Personal Injury Protection
              </Typography>
              <Typography className={classes.description}>
                Pays for medical expenses for you and your passengers
                regardless of who is at fault.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="pip">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="pip"
                    name="pip"
                    value={form.values.pip}
                    defaultValue={null}
                    onChange={form.handleChange}
                    disabled={!form.values.pipEnabled}
                    error={Boolean(form.errors.pip)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {pipLimits.map((coverage, i) => (
                      <MenuItem
                        key={`pipCoverage-${i}`}
                        value={JSON.stringify(coverage)}
                      >
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
                {Boolean(pipDeductibles?.length) && (
                  <>
                    <Typography className={classes.coverageHeader_2}>
                      Deductible
                    </Typography>
                    <ScrollToOnError name="pipded">
                      <TextField
                        select
                        variant="standard"
                        className={classes.dropdown}
                        id="pipded"
                        name="pipded"
                        value={form.values.pipded}
                        defaultValue={null}
                        onChange={form.handleChange}
                        error={Boolean(form.errors.pipded)}
                        size="small"
                        InputProps={{
                          disableUnderline: true,
                          className: classes.dropdownText,
                        }}
                      >
                        {pipDeductibles.map((coverage, i) => (
                          <MenuItem
                            key={`pipdedCoverage-${i}`}
                            value={JSON.stringify(coverage)}
                          >
                            {coverage.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ScrollToOnError>
                  </>
                )}
                {Boolean(attendantCareLimits?.length) && (
                  <>
                    <Typography className={classes.coverageHeader_2}>
                      Attendant Care
                    </Typography>
                    <ScrollToOnError name="attendantcare">
                      <TextField
                        select
                        variant="standard"
                        className={classes.dropdown}
                        id="attendantcare"
                        name="attendantcare"
                        value={form.values.attendantcare}
                        defaultValue={null}
                        onChange={form.handleChange}
                        error={Boolean(form.errors.attendantcare)}
                        size="small"
                        InputProps={{
                          disableUnderline: true,
                          className: classes.dropdownText,
                        }}
                      >
                        {attendantCareLimits.map((coverage, i) => (
                          <MenuItem
                            key={`attendantcare-${i}`}
                            value={JSON.stringify(coverage)}
                          >
                            {coverage.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ScrollToOnError>
                  </>
                )}
                {Boolean(medicalExpenseOnlyOptions?.length) && (
                  <>
                    <Typography className={classes.coverageHeader_2}>
                      Medical payments only?
                    </Typography>
                    <ScrollToOnError name="medicalexpenseonly">
                      <TextField
                        select
                        variant="standard"
                        className={classes.dropdown}
                        id="medicalexpenseonly"
                        name="medicalexpenseonly"
                        value={form.values.medicalexpenseonly}
                        defaultValue={null}
                        onChange={form.handleChange}
                        error={Boolean(form.errors.medicalexpenseonly)}
                        size="small"
                        InputProps={{
                          disableUnderline: true,
                          className: classes.dropdownText,
                        }}
                      >
                        {medicalExpenseOnlyOptions.map((coverage, i) => (
                          <MenuItem
                            key={`medicalexpenseonly-${i}`}
                            value={JSON.stringify(coverage)}
                          >
                            {coverage.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ScrollToOnError>
                  </>
                )}
                {Boolean(extendedMedicalLimits?.length) && (
                  <>
                    <Typography className={classes.coverageHeader_2}>
                      Extended medical
                    </Typography>
                    <ScrollToOnError name="extendedmedical">
                      <TextField
                        select
                        variant="standard"
                        className={classes.dropdown}
                        id="extendedmedical"
                        name="extendedmedical"
                        value={form.values.extendedmedical}
                        defaultValue={null}
                        onChange={form.handleChange}
                        error={Boolean(form.errors.extendedmedical)}
                        size="small"
                        InputProps={{
                          disableUnderline: true,
                          className: classes.dropdownText,
                        }}
                      >
                        {extendedMedicalLimits.map((coverage, i) => (
                          <MenuItem
                            key={`extendedmedical-${i}`}
                            value={JSON.stringify(coverage)}
                          >
                            {coverage.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ScrollToOnError>
                  </>
                )}
                {Boolean(tortOptions?.length) && (
                  <>
                    <Typography className={classes.coverageHeader_2}>
                      Tort
                    </Typography>
                    <ScrollToOnError name="tort">
                      <TextField
                        select
                        variant="standard"
                        className={classes.dropdown}
                        id="tort"
                        name="tort"
                        value={form.values.tort}
                        defaultValue={null}
                        onChange={form.handleChange}
                        error={Boolean(form.errors.tort)}
                        size="small"
                        InputProps={{
                          disableUnderline: true,
                          className: classes.dropdownText,
                        }}
                      >
                        {tortOptions.map((coverage, i) => (
                          <MenuItem
                            key={`tort-${i}`}
                            value={JSON.stringify(coverage)}
                          >
                            {coverage.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ScrollToOnError>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
      {Boolean(obelLimits?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <Med_PipImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>OBEL</Typography>
              <Typography className={classes.description}>
                OBEL (Optional Basic Economic Loss) provides additional
                coverage beyond the no-fault PIP limit. It can be used to
                cover medical bills, lost wages and necessary therapies due to
                an accident.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="obel">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="obel"
                    name="obel"
                    value={form.values.obel}
                    defaultValue={null}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.obel)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {obelLimits.map((coverage, i) => (
                      <MenuItem
                        key={`obel-${i}`}
                        value={JSON.stringify(coverage)}
                      >
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      <hr className={classes.divider} />

      {selectedVehicles.map((vehicle, i) => (
        <Box key={`vehicle-${i}`}>
          <Box className={classes.vehicleTitleWrapper}>
            <Typography className={classes.vehicleTitle}>
              {vehicle.ModelYear} {vehicle.Maker} {vehicle.Model}
            </Typography>
            {vehicle.Type !== "own" && (
              <Box className={classes.vehicleInfo}>
                <InfoIconOutlined className={classes.infoIcon} />
                Comprehensive and Collision coverage is required for leased
                and financed vehicles.
              </Box>
            )}
          </Box>
          <div
            style={{
              borderLeft: "1px solid #CDD2E9",
              right: 249,
              top: 10,
            }}
          >
            {Boolean(compCoverages?.length) && (
              <>
                <hr className={classes.divider_Vehicle} />
                <Box className={classes.coverageWrapper_Vehicle}>
                  <Box className={classes.imgWrapper}>
                    <CompImage fillColor={theme.palette.primary.main} />
                  </Box>
                  <Box className={classes.coverageDescriptionWrapper}>
                    <Typography className={classes.label}>
                      Comprehensive coverage
                    </Typography>
                    <Typography className={classes.description}>
                      Helps pay to repair or replace your vehicle when damaged
                      by something other than an accident.
                    </Typography>
                  </Box>
                  <Box className={classes.coverageAmountWrapper}>
                    <Switch
                      className={classes.coverageSwitch}
                      checked={
                        form.values.vehicles &&
                        form.values.vehicles[i] &&
                        form.values.vehicles[i].compEnabled
                      }
                      color="primary"
                      onChange={() => {
                        if (vehicle.Type === "own") {
                          form.setFieldValue(
                            `vehicles[${i}].compEnabled`,
                            !form.values.vehicles[i].compEnabled,
                          );
                        }
                      }}
                      value={
                        form.values.vehicles &&
                        form.values.vehicles[i] &&
                        form.values.vehicles[i].compEnabled
                      }
                    />
                    <Box>
                      <Typography className={classes.coverageHeader}>
                        Deductible
                      </Typography>
                      <ScrollToOnError name={`vehicles[${i}].comp`}>
                        <TextField
                          select
                          variant="standard"
                          className={classes.dropdown}
                          id="comp"
                          name={`vehicles[${i}].comp`}
                          defaultValue={null}
                          disabled={!form.values.vehicles[i]?.compEnabled}
                          value={
                            form.values.vehicles &&
                            form.values.vehicles[i] &&
                            form.values.vehicles[i].comp
                          }
                          onChange={form.handleChange}
                          error={
                            form.errors?.vehicles &&
                            form.errors?.vehicles[i] &&
                            Boolean(form.errors?.vehicles[i].comp)
                          }
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            className: classes.dropdownText,
                          }}
                        >
                          {compCoverages.map((coverage, i) => (
                            <MenuItem
                              key={`compCoverage-${i}`}
                              value={JSON.stringify(coverage)}
                            >
                              {coverage.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ScrollToOnError>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {Boolean(collCoverages?.length) && (
              <>
                <hr className={classes.divider_Vehicle} />
                <Box className={classes.coverageWrapper_Vehicle}>
                  <Box className={classes.imgWrapper}>
                    <CollImage fillColor={theme.palette.primary.main} />
                  </Box>
                  <Box className={classes.coverageDescriptionWrapper}>
                    <Typography className={classes.label}>
                      Collision coverage
                    </Typography>
                    <Typography className={classes.description}>
                      Helps pay to repair or replace your vehicle if damaged
                      in an accident.
                    </Typography>
                  </Box>
                  <Box className={classes.coverageAmountWrapper}>
                    <Switch
                      className={classes.coverageSwitch}
                      checked={
                        form.values.vehicles &&
                        form.values.vehicles[i] &&
                        form.values.vehicles[i].collEnabled
                      }
                      color="primary"
                      onChange={() => {
                        if (vehicle.Type === "own") {
                          form.setFieldValue(
                            `vehicles[${i}].collEnabled`,
                            !form.values.vehicles[i].collEnabled,
                          );
                        }
                      }}
                      value={
                        form.values.vehicles &&
                        form.values.vehicles[i] &&
                        form.values.vehicles[i].collEnabled
                      }
                    />
                    <Box>
                      <Typography className={classes.coverageHeader}>
                        Deductible
                      </Typography>
                      <ScrollToOnError name={`vehicles[${i}].coll`}>
                        <TextField
                          select
                          variant="standard"
                          className={classes.dropdown}
                          id="coll"
                          name={`vehicles[${i}].coll`}
                          defaultValue={null}
                          disabled={!form.values.vehicles[i]?.collEnabled}
                          value={
                            form.values.vehicles &&
                            form.values.vehicles[i] &&
                            form.values.vehicles[i].coll
                          }
                          onChange={form.handleChange}
                          error={
                            form.errors?.vehicles &&
                            form.errors?.vehicles[i] &&
                            Boolean(form.errors?.vehicles[i].coll)
                          }
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            className: classes.dropdownText,
                          }}
                        >
                          {collCoverages.map((coverage, i) => (
                            <MenuItem
                              key={`collCoverage-${i}`}
                              value={JSON.stringify(coverage)}
                            >
                              {coverage.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ScrollToOnError>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            {Boolean(tlCoverages?.length) && (
              <>
                <hr className={classes.divider_Vehicle} />
                <Box className={classes.coverageWrapper_Vehicle}>
                  <Box className={classes.imgWrapper}>
                    <TowingImage fillColor={theme.palette.primary.main} />
                  </Box>
                  <Box className={classes.coverageDescriptionWrapper}>
                    <Typography className={classes.label}>
                      Roadside assistance
                    </Typography>
                    <Typography className={classes.description}>
                      Provides roadside assistance (change a flat, jump start,
                      etc.) and towing coverage up to a specific distance if
                      your car breaks down.
                    </Typography>
                  </Box>
                  <Box className={classes.coverageAmountWrapper}>
                    <Switch
                      className={classes.coverageSwitch}
                      checked={
                        form.values.vehicles &&
                        form.values.vehicles[i] &&
                        form.values.vehicles[i].towingEnabled
                      }
                      color="primary"
                      onChange={() => {
                        form.setFieldValue(
                          `vehicles[${i}].towingEnabled`,
                          !form.values.vehicles[i].towingEnabled,
                        );
                      }}
                      value={
                        form.values.vehicles &&
                        form.values.vehicles[i] &&
                        form.values.vehicles[i].towingEnabled
                      }
                    />
                  </Box>
                </Box>
              </>
            )}
            {Boolean(rreimCoverages?.length) && (
              <>
                <hr className={classes.divider_Vehicle} />
                <Box className={classes.coverageWrapper_Vehicle}>
                  <Box className={classes.imgWrapper}>
                    <RentalImage fillColor={theme.palette.primary.main} />
                  </Box>
                  <Box className={classes.coverageDescriptionWrapper}>
                    <Typography className={classes.label}>
                      Rental car
                    </Typography>
                    <Typography className={classes.description}>
                      Helps pay for rental car fees while your car is being
                      repaired under a claim.
                    </Typography>
                  </Box>
                  <Box className={classes.coverageAmountWrapper}>
                    <Switch
                      className={classes.coverageSwitch}
                      checked={
                        form.values.vehicles &&
                        form.values.vehicles[i] &&
                        form.values.vehicles[i].rentalEnabled
                      }
                      color="primary"
                      onChange={() => {
                        form.setFieldValue(
                          `vehicles[${i}].rentalEnabled`,
                          !form.values.vehicles[i].rentalEnabled,
                        );
                      }}
                      value={
                        form.values.vehicles &&
                        form.values.vehicles[i] &&
                        form.values.vehicles[i].rentalEnabled
                      }
                    />
                    <Box>
                      <Typography className={classes.coverageHeader}>
                        Coverage
                      </Typography>
                      <ScrollToOnError name={`vehicles[${i}].rental`}>
                        <TextField
                          select
                          variant="standard"
                          className={classes.dropdown}
                          id="rental"
                          name={`vehicles[${i}].rental`}
                          defaultValue={null}
                          value={
                            form.values.vehicles &&
                            form.values.vehicles[i] &&
                            form.values.vehicles[i].rental
                          }
                          disabled={!form.values.vehicles[i]?.rentalEnabled}
                          onChange={form.handleChange}
                          error={
                            form.errors?.vehicles &&
                            form.errors?.vehicles[i] &&
                            Boolean(form.errors?.vehicles[i].rental)
                          }
                          size="small"
                          InputProps={{
                            disableUnderline: true,
                            className: classes.dropdownText,
                          }}
                        >
                          {rreimCoverages.map((coverage, i) => (
                            <MenuItem
                              key={`rreimCoverage-${i}`}
                              value={JSON.stringify(coverage)}
                            >
                              {coverage.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </ScrollToOnError>
                      <Typography className={classes.coverageFooter}>
                        per day / per incident
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            <hr className={classes.divider_Vehicle} />
          </div>
        </Box>
      ))}

      <Grid
        component="footer"
        container
        justifyContent="flex-end"
        alignItems="center"
        classes={{ container: classes.modalFooter }}
        spacing={2}
      >
        <Grid item>
          <Button
            size="large"
            type="reset"
            className={classes.button}
            onClick={handleClose}
            style={{
              backgroundColor: "#eff1fb",
              lineHeight: "18px",
              padding: "8px 16px",
            }}
          >
            {isLoggedIn && (mode === "bundle" || mode === "auto-renters")
              ? "Back"
              : "Cancel"}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.button}
            onClick={() => setFormSubmitted(true)}
          >
            Quote
          </Button>
        </Grid>
      </Grid>
    </Form>
  </>;
};

{
  /* medical payments old validation
                     disabled={
                        form.values.vehicles[0].info.rate_state === "TX" &&
                        !form.values.pip.includes("None") &&
                        form.values.pip !== ""
                      }
                      title={
                        form.values.vehicles[0].info.rate_state === "TX" &&
                        !form.values.pip.includes("None") &&
                        form.values.pip !== ""
                          ? "medical payments cannot be selected with pip"
                          : ""
                      }
                      defaultValue={""}

                      pip old validation
                          form.values.vehicles[0].info.rate_state === "TX" &&
                        !form.values.medex.includes("None") &&
                        form.values.medex !== ""
                          ? "pip cannot be selected with medical payments"
                          : ""
                      }
                      disabled={
                        form.values.vehicles[0].info.rate_state === "TX" &&
                        !form.values.medex.includes("None") &&
                        form.values.medex !== ""
                      }
                      */
}
export default CustomQuoteForm;
