import React, { useState, useCallback } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const useStyles = makeStyles(() => ({
  answerEditorWrapper: {
    border: "1px solid #AFB6D4",
    borderRadius: 4,
  },
  answerEditor: {
    padding: "0 1em",
  },
}));

const RichTextEditor = ({ setFieldValue, placeholder, name }) => {
  const classes = useStyles();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = useCallback(
    (editorState) => {
      setFieldValue(
        name,
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
      setEditorState(editorState);
    },
    [name, setFieldValue],
  );

  return (
    <>
      <Editor
        id={name}
        name={name}
        editorState={editorState}
        placeholder={placeholder}
        wrapperClassName={classes.answerEditorWrapper}
        editorClassName={classes.answerEditor}
        onEditorStateChange={onEditorStateChange}
      />
    </>
  );
};

export default RichTextEditor;
