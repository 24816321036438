import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";

import {
  useAction,
  useSelector,
  wizardActions,
  wizardNoPersistSelectors,
} from "../../../../state";
import { Api, Navigation } from "../../../../lib";
import { phoneFormatter } from "../../../../utils";

import { useStyles } from "./VerifyPhone.styles";

const VerifyPhone = () => {
  const classes = useStyles();
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const setContactInfo = useAction(wizardActions.setContactInfo);

  const [phone, setPhone] = useState("");
  const sendCode = () => {
    Api.post("/get-code", { phone: phone.replace(/\D/g, "") });
    setContactInfo({ phone });
    Navigation.go(`/${mode}/wizard/code-entry`);
  };
  return (
    <>
      <Box className={classes.container}>
        <Typography>
          We use artificial intelligence to look up your vehicles based on the
          information you provided. But first we’ll text you a one-time
          verification code to confirm your number.
        </Typography>
        <Typography className={classes.boldText}>
          Enter your mobile number
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={classes.fields}
        >
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.phoneField}>
              <InputLabel htmlFor="phone">Phone</InputLabel>
              <OutlinedInput
                className={classes.phoneField}
                id="phone"
                name="phone"
                label="Phone"
                autoFocus
                startAdornment={
                  <InputAdornment position="start">+1</InputAdornment>
                }
                value={phone}
                onChange={(e) => {
                  setPhone(phoneFormatter(e.target.value));
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={phone.length < 14}
              className={classes.submitButton}
              onClick={() => sendCode()}
            >
              Send code
            </Button>
          </Grid>
        </Grid>

        <Typography className={classes.smallText}>
          By providing your phone number, you agree to receive text messages
          from{" "}
          {window.location.host.includes("reliexchange")
            ? "Reli exchange"
            : "5 Minutes Insure"}
          . Standard message and data rates apply.
        </Typography>
        <Box className={classes.linkWrapper}>
          <Button
            className={classes.link}
            type="submit"
            onClick={() => Navigation.go(`/${mode}/wizard/vehicles`)}
          >
            Skip and enter my vehicles manually
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(VerifyPhone);
