import { Api } from "../../lib";
import { AppThunk } from "../types";
import { ui, UIViewType, UIViewState } from "./state";

const { actions } = ui;

export const uiActions = {
  ...actions,

  // Notifications

  showError(message = "There was an error processing your request.") {
    return actions.showNotification({ message, variant: "error" });
  },

  showMessage(message: string) {
    return actions.showNotification({ message });
  },

  showSuccess(message: string) {
    return actions.showNotification({ message, variant: "success" });
  },

  // Views

  /** Dynamically creates an action function which sets `view[name] = false`. */
  toHideView(name: keyof UIViewState) {
    return () => actions.setView({ [name]: false });
  },
  /** Dynamically creates an action function which removes `view[name]`. */
  toRemoveView(name: keyof UIViewState) {
    return () => actions.setView({ [name]: undefined });
  },
  /** Dynamically creates an action function which sets `view[name] = value`. */
  toSetView(name: keyof UIViewState) {
    return (value?: UIViewType) => actions.setView({ [name]: value });
  },
  /** Dynamically creates an action function which sets `view[name] = true`. */
  toShowView(name: keyof UIViewState) {
    return () => actions.setView({ [name]: true });
  },
  setAgency(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoadingAgency(true));
      const domain = window.location.hostname;
      const { status, data } = await Api.get(`/agency/domain/${domain}`);
      if (status === 200) {
        await dispatch(uiActions.setAgencyData(data));
      } else {
        console.error("setAgency failed", domain, data);
      }
      dispatch(uiActions.setLoadingAgency(false));
    };
  },
};
