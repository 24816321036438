import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  searchCard: {
    padding: 32,
    minHeight: 220,
  },
  searchField: {
    marginTop: 16,
    // width: 340,
  },
  editPmtLink: {
    marginRight: 16,
  },
  formCard: {
    borderRadius: 0,
    marginBottom: "2em",
    width: "50%",
    margin: "0 auto",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  formWrap: {
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formControl: {
    width: "100%",
    minWidth: 330,
    padding: "0 2em 2em",
  },
  descriptionField: {
    width: "100%",
    minWidth: 330,
    padding: "0 2em 2em",
  },
  formAction: {
    paddingRight: "2em",
  },
  dropzone: {
    maxHeight: 150,
    width: "100%",
    backgroundColor: "#E4E7F2",
    minHeight: 100,
    marginBottom: 16,
    "&.MuiDropzoneArea-root": {
      backgroundColor: "#E4E7F2",
    },
  },
  dropzoneText: {
    fontSize: 13,
    fontWeight: 400,
  },
  dropzoneImage: {
    height: 20,
  },
  fieldTitle: {
    fontSize: 13,
    fontWeight: 400,
    marginBottom: 8,
  },
  textFieldWrapper: {
    paddingTop: 28,
    "& .MuiDropzoneArea-root": {
      minHeight: 100,
    },
    "& .MuiSnackbar-anchorOriginBottomLeft": {
      display: "none",
    },
    "& .MuiGrid-spacing-xs-8": {
      margin: 0,
    },
    "& .MuiTypography-subtitle1": {
      fontSize: 13,
      fontWeight: 400,
    },
  },
  previewImg: {
    maxWidth: 175,
  },
}));
