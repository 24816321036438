import React, { useState } from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import {
  FAQActionCell,
  FAQCategoryActionCell,
} from "../components/admin/FAQActionsCell";
import { IconButton, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, authSelectors, hasDirectorPermissions } from "../state";
import {
  DeleteIcon,
  DirectionsCar,
  EditIcon,
  HomeIcon,
  ApartmentIcon,
} from "../components";
import LifeIcon from "../assets/img/life/LifeIcon";
import { Link } from "react-router-dom";
import { history } from "../lib";
import AdminPages from "../pages/admin";
import {
  AssuredWorkloadIcon,
  PolicyIcon,
  VisibilityIcon,
} from "../components/icons";
import { defaultTheme } from "../themes";
import { UserNotesModal } from "../components/modals/UserNotesModal";

export const REPORT_COLUMNS: GridColDef[] = [
  {
    field: "name",
    headerName: "Report Name",
    width: 200,
    editable: true,
  },
  {
    field: "id",
    headerName: "View",
    width: 150,
    align: "center",
    editable: false,
    // eslint-disable-next-line
    renderCell: (row) => (
      <Link
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        to={AdminPages.viewReports.path.replace(":id", row.id.toString())}
      >
        <IconButton size="large">
          <VisibilityIcon />
        </IconButton>
      </Link>
    ),
  },
];
const ActiveField = (props) => {
  const dispatch = useDispatch();
  return (
    <Switch
      checked={props.value}
      color="primary"
      onChange={() =>
        dispatch(
          adminActions.updateCarriers(props.row.id, props.row.agency_group_id, {
            [props.field]: !props.value,
          }),
        )
      }
    />
  );
};
const ActiveField_Product = (props) => {
  const dispatch = useDispatch();
  return (
    <Switch
      checked={props.value}
      color="primary"
      onChange={() =>
        dispatch(
          adminActions.updateProduct(props.row.id, {
            [props.field]: !props.value,
          }),
        )
      }
    />
  );
};
const ActiveField_Coverage = (props: GridRenderCellParams) => {
  // TODO:
  // const dispatch = useDispatch();
  // const payload = {
  //   id: props.row.id,
  //   [props.field]: !props.value,
  // };
  return (
    <Switch
      checked={!!props.value}
      color="primary"
      disabled={true}
      // onChange={() => dispatch(adminActions.editCoverage(payload))}
    />
  );
};
const TimeField = (params) => {
  if (
    !params.value ||
    params.value === true ||
    typeof params.value === "object"
  )
    return <span></span>;
  const date = new Date(params.value);
  return (
    <span>
      {" "}
      {date.toLocaleDateString()} ,{" "}
      {date.toLocaleTimeString("en-US", {
        hour: "numeric",
        hour12: true,
        minute: "numeric",
      })}
    </span>
  );
};
const EnableField = (props) => {
  const dispatch = useDispatch();
  return (
    <Switch
      checked={!!props.value}
      color="primary"
      onChange={() => {
        if (window.confirm("Are you sure you'd like to make this change?")) {
          dispatch(
            adminActions.updateState(props.row.id, {
              [props.field]: !props.value,
            }),
          );
        }
      }}
    />
  );
};
export const QuoteLinkField = ({ row }) => {
  if (!row.req_uid) {
    return <div></div>;
  }
  const role = useSelector(authSelectors.activeRole);
  if (row.commercial_type) {
    const quoted =
      row.commercial_status === "quoted" ||
      row.commercial_status === "referred";
    const to = quoted
      ? AdminPages.quote_mgmt_page.path.replace(":masterQuoteId", row.req_uid)
      : AdminPages.commercialStart.path.replace(":id", row.req_uid);
    return (
      <div style={{ paddingRight: 15, color: "#0033ff" }}>
        {" "}
        <Link to={to} target="_blank" onClick={(e) => e.stopPropagation()}>
          View
        </Link>
      </div>
    );
  }

  return role === "affiliate_agent" ? (
    <div></div>
  ) : (
    <div style={{ paddingRight: 15, color: "#0033ff" }}>
      {" "}
      <Link
        to={AdminPages.quote_mgmt_page.path.replace(
          ":masterQuoteId",
          row.req_uid,
        )}
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        View
      </Link>
    </div>
  );
};

export const StatusField = ({ row }) => {
  const useStyles = makeStyles(() => ({
    status: {
      padding: "2px 5px",
      fontWeight: 600,
      backgroundColor: ({ status }: any) =>
        status === "New"
          ? "#2ED27221"
          : status === "In progress"
          ? "#d4ddff"
          : status === "Pending"
          ? "#FFCC002B"
          : status === "Closed"
          ? "#eeeff5"
          : "",
      color: ({ status }: any) =>
        status === "New"
          ? "#2eb662"
          : status === "In progress"
          ? "blue"
          : status === "Pending"
          ? "#ffb20d"
          : status === "Closed"
          ? "#757fa4"
          : "",
    },
  }));
  const status = row.status;
  const classes = useStyles({ status });
  if (!row.status) {
    return <div></div>;
  }
  return <div className={classes.status}>{row.fullStatus}</div>;
};

const ReferralLinkField = ({ row }) => {
  const role = useSelector(authSelectors.activeRole);
  return role === "affiliate_agent" ? (
    <></>
  ) : (
    <Link to={AdminPages.viewReferral.path.replace(":referralId", row.id)}>
      view
    </Link>
  );
};
export const QuoteTypeField = ({ value }: any) => {
  return (
    <p style={{ width: "100%", textAlign: "left", margin: 0 }}>
      {value === "auto" ? (
        <DirectionsCar
          style={{ color: defaultTheme.palette.primary.main, fontSize: 22 }}
        />
      ) : value === "home" ? (
        <HomeIcon
          style={{ color: defaultTheme.palette.primary.main, fontSize: 22 }}
        />
      ) : value === "BOP" || value === "WC" ? (
        <AssuredWorkloadIcon
          style={{ color: defaultTheme.palette.primary.main, fontSize: 22 }}
        />
      ) : value === "life" ? (
        <LifeIcon color={defaultTheme.palette.primary.main} />
      ) : value === "renters" ? (
        <ApartmentIcon
          style={{ color: defaultTheme.palette.primary.main, fontSize: 22 }}
        />
      ) : value === "autoRenters" ? (
        <>
          <DirectionsCar
            style={{ color: defaultTheme.palette.primary.main, fontSize: 22 }}
          />
          <ApartmentIcon
            style={{
              color: defaultTheme.palette.primary.main,
              fontSize: 22,
              marginLeft: 8,
            }}
          />
        </>
      ) : value === "bundle" ? (
        <>
          <DirectionsCar
            style={{ color: defaultTheme.palette.primary.main, fontSize: 22 }}
          />
          <HomeIcon
            style={{
              color: defaultTheme.palette.primary.main,
              fontSize: 22,
              marginLeft: 8,
            }}
          />
        </>
      ) : value === "manual" ? (
        <PolicyIcon
          style={{ color: defaultTheme.palette.primary.main, fontSize: 22 }}
        />
      ) : (
        <></>
      )}
    </p>
  );
};
const DeleteField_Carriers = (props) => {
  const dispatch = useDispatch();
  return (
    <>
      <IconButton
        onClick={() => {
          dispatch(adminActions.deleteCarriers(props.value));
        }}
        size="large"
      >
        <DeleteIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          history.push(
            AdminPages.editCarrier.path.replace(":id", "" + props.row.id),
          );
        }}
        size="large"
      >
        {" "}
        <EditIcon />
      </IconButton>{" "}
    </>
  );
};
const DeleteField_AllowedIP = (props) => {
  const dispatch = useDispatch();
  return (
    <IconButton
      onClick={() => {
        dispatch(adminActions.deleteAllowedIP(props.row.id));
      }}
      size="large"
    >
      <DeleteIcon />
    </IconButton>
  );
};
const DeleteField_CoverageUI = (props) => {
  const dispatch = useDispatch();
  return (
    <IconButton
      onClick={() => {
        dispatch(adminActions.deleteCoverageUI(props.row.id));
      }}
      size="large"
    >
      <DeleteIcon />
    </IconButton>
  );
};
const DeleteField_Agency = (props) => {
  const dispatch = useDispatch();
  return (
    <IconButton
      onClick={() => {
        dispatch(adminActions.deleteAgency(props.row.id));
      }}
      size="large"
    >
      <DeleteIcon />
    </IconButton>
  );
};
const DeleteField_UserAgency = (props) => {
  const dispatch = useDispatch();
  return (
    <IconButton
      onClick={() => {
        dispatch(adminActions.deleteUserAgency(props.row.id));
      }}
      size="large"
    >
      <DeleteIcon />
    </IconButton>
  );
};
const DeleteField_User = (props) => {
  const dispatch = useDispatch();
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  if (!activeRole || !agencyId) {
    console.warn("No active role or agency Id");
    return null;
  }
  return (
    <IconButton
      onClick={() => {
        dispatch(adminActions.deleteUser(props.row.id, activeRole, agencyId));
      }}
      size="large"
    >
      <DeleteIcon />
    </IconButton>
  );
};

const CoverageField = (props) => {
  return <div>{JSON.stringify(Object.keys(props.row.coverage_data))}</div>;
};
const FAQField = (props) => {
  return <div>{JSON.stringify(props.row.faqs)}</div>;
};
const ThemeField = (props) => {
  return <div>{JSON.stringify(props.row.theme)}</div>;
};
const SocialLinksField = (props) => {
  return <div>{JSON.stringify(props.row.social_links)}</div>;
};

const AdditionalQuestionsField = (props) => {
  return <div>{JSON.stringify(props.row.state_requirements)}</div>;
};
const EditField_States = (props) => {
  // const dispatch = useDispatch();
  return (
    <>
      <IconButton
        onClick={() => {
          history.push(
            AdminPages.editStates.path.replace(":id", "" + props.row.id),
          );
        }}
        size="large"
      >
        <EditIcon />
      </IconButton>
    </>
  );
};
const EditField_Product = (props) => {
  return (
    <>
      <IconButton
        onClick={() => {
          history.push(
            AdminPages.editProduct.path.replace(":id", "" + props.row.id),
          );
        }}
        size="large"
      >
        <EditIcon />
      </IconButton>
    </>
  );
};
const EditField_User = (props) => {
  if (props.row.sales_director) {
    return (
      <>
        <IconButton
          onClick={() => {
            history.push(
              AdminPages.editUsers.path.replace(":id", "" + props.row.id),
            );
          }}
          size="large"
        >
          <EditIcon />
        </IconButton>
      </>
    );
  }
  return <></>;
};
const EditField_CoverageUI = (props) => {
  return (
    <>
      <IconButton
        onClick={() => {
          history.push(
            AdminPages.editCoverageUI.path.replace(":id", "" + props.row.id),
          );
        }}
        size="large"
      >
        <EditIcon />
      </IconButton>
    </>
  );
};
const EditField_Agency = (props) => {
  return (
    <>
      <IconButton
        onClick={() => {
          history.push(
            AdminPages.editAgency.path.replace(":id", "" + props.row.id),
          );
        }}
        size="large"
      >
        <EditIcon />
      </IconButton>
    </>
  );
};
// const EditField_UserAgency = (props) => {
//   return (
//     <>
//       <IconButton
//         onClick={() => {
//           history.push(
//             AdminPages.editUserAgency.path.replace(":id", "" + props.row.id),
//           );
//         }}
//       >
//         <EditIcon />
//       </IconButton>
//     </>
//   );
// };

export const USERS_COLUMNS: GridColDef[] = [
  {
    field: "last_name",
    headerName: "Last name",
    width: 175,
    editable: false,
  },
  {
    field: "first_name",
    headerName: "First name",
    width: 175,
    editable: false,
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    editable: false,
  },
  {
    field: "rolesAsString",
    headerName: "Role",
    width: 150,
    editable: false,
  },
  {
    field: "agencyName",
    headerName: "Agency",
    width: 200,
    editable: false,
  },
  {
    field: "user_notes",
    headerName: "Notes",
    // headerAlign: "center",
    width: 100,
    renderCell: ({ row }) => {
      const [open, setOpen] = useState(false);
      const role = useSelector(authSelectors.activeRole);
      return hasDirectorPermissions(role) ||
        (row.user_agency &&
          row.user_agency?.find((ua) => ua.role === "affiliate_agent")) ? (
        <UserNotesModal
          notes={row.user_notes}
          open={open}
          setOpen={setOpen}
          agentId={row.id}
        />
      ) : (
        <></>
      );
    },
  },
  {
    field: "referralToken",
    headerName: "Referral Token",
    width: 100,
    editable: false,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
    editable: false,
  },
  {
    field: "use_profile_image",
    headerName: "Use Image",
    width: 150,
    editable: false,
  },
  {
    field: "disable_notifications",
    headerName: "Notifications Disabled",
    width: 100,
    editable: false,
  },
  {
    field: "edit",
    headerName: "Actions",
    width: 125,
    editable: false,
    // eslint-disable-next-line
    renderCell: (params) => {
      return (
        <>
          <DeleteField_User {...params} />
          <EditField_User {...params} />
        </>
      );
    },
  },
];
export const CARRIER_COLUMNS: GridColDef[] = [
  {
    field: "logo_full_url",
    headerName: "Carrier",
    width: 150,
    editable: false,
    renderCell: (params) => (
      <div
        style={{
          marginLeft: 10,
        }}
      >
        <img src={params.row.logo_full_url} width="100" alt="logo"></img>
      </div>
    ),
    renderHeader: () => (
      <div style={{ marginLeft: 10, fontWeight: 500 }}>Carrier</div>
    ),
  },
  {
    field: "name",
    headerName: "Carrier Name",
    width: 250,
    editable: false,
  },
  {
    field: "company_id",
    headerName: "Company Id",
    width: 200,
    editable: false,
  },
  {
    field: "cvg_type",
    headerName: "Coverage Type",
    width: 150,
    editable: false,
    valueGetter: (params) => {
      return params.row.is_auto ? "Auto" : "Home";
    },
    renderCell: (params) => <span>{params.row.is_auto ? "Auto" : "Home"}</span>,
  },
  {
    field: "is_active",
    headerName: "Is Active",
    width: 150,
    renderCell: (params) => <ActiveField {...params} />,
  },
  {
    field: "supports_no_prior_coverage",
    headerName: "Supports No Prior Coverage",
    width: 150,
    renderCell: (params) => <ActiveField {...params} />,
  },
  {
    field: "state",
    headerName: "State",
    width: 150,
    editable: false,
  },
  {
    field: "agency_group_id",
    headerName: "Actions",
    width: 150,
    editable: false,
    // eslint-disable-next-line
    renderCell: (params) => <DeleteField_Carriers {...params} />,
  },
];
export const AGENT_CARRIER_COLUMNS: GridColDef[] = [
  {
    field: "logo_full_url",
    headerName: "Carrier",
    width: 250,
    editable: false,
    renderHeader: () => (
      <div
        style={{
          marginLeft: 60,
          fontWeight: 500,
          width: 300,
        }}
      >
        Carrier
      </div>
    ),
    renderCell: (params) => (
      <div
        style={{
          textAlign: "center",
          width: 250,
        }}
      >
        <img src={params.row.logo_full_url} width="100" alt="logo"></img>
      </div>
    ),
  },
  {
    field: "cvg_type",
    headerName: "Coverage Type",
    width: 150,
    editable: false,
    renderCell: (params) => <span>{params.row.is_auto ? "Auto" : "Home"}</span>,
    valueGetter: (params) => {
      return params.row.is_auto ? "Auto" : "Home";
    },
  },
  {
    field: "state",
    headerName: "State",
    width: 150,
    editable: false,
  },
];
export const PRODUCTS_COLUMNS: GridColDef[] = [
  {
    field: "logo",
    headerName: "",
    width: 100,
    editable: false,
    renderCell: (params) => (
      <img src={params.row.logo} height="25" alt="logo"></img>
    ),
  },
  {
    field: "product_name",
    headerName: "Product Name",
    width: 200,
    editable: false,
  },
  {
    field: "category",
    headerName: "Category",
    width: 150,
    editable: false,
  },
  {
    field: "is_active",
    headerName: "Active",
    width: 150,
    renderCell: (params) => <ActiveField_Product {...params} />,
  },
  {
    field: "edit",
    headerName: "Actions",
    width: 100,
    editable: false,
    // eslint-disable-next-line
    renderCell: (params) => <EditField_Product {...params} />,
  },
];

export const STATES_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "state",
    headerName: "Supported States",
    width: 125,
    editable: false,
  },
  {
    field: "state_requirements",
    headerName: "State Requirements",
    width: 300,
    editable: false,
    renderCell: (params) => <AdditionalQuestionsField {...params} />,
  },
  {
    field: "is_enabled_admin_auto",
    headerName: "Enable Admin Auto",
    width: 160,
    renderCell: (params) => <EnableField {...params} />,
  },
  {
    field: "is_enabled_global_auto",
    headerName: "Enable Global Auto",
    width: 160,
    renderCell: (params) => <EnableField {...params} />,
  },
  {
    field: "is_enabled_admin_home",
    headerName: "Enable Admin Home",
    width: 160,
    renderCell: (params) => <EnableField {...params} />,
  },
  {
    field: "is_enabled_global_home",
    headerName: "Enable Global Home",
    width: 160,
    renderCell: (params) => <EnableField {...params} />,
  },
  {
    field: "edit",
    headerName: "Actions",
    width: 100,
    editable: false,
    // eslint-disable-next-line
    renderCell: (params) => <EditField_States {...params} />,
  },
];
export const AGENCY_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "name",
    headerName: "Agency Name",
    width: 250,
    editable: false,
  },
  {
    field: "domain",
    headerName: "Domain",
    width: 250,
    editable: false,
  },
  {
    field: "logo_path",
    headerName: "Logo Path",
    width: 250,
    editable: false,
  },
  {
    field: "groupName",
    headerName: "Group Name",
    width: 250,
    editable: false,
  },
  {
    field: "email_address",
    headerName: "Email From Address",
    width: 150,
    editable: false,
  },
  {
    field: "email_to",
    headerName: "Email To Address",
    width: 150,
    editable: false,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
    editable: false,
  },
  {
    field: "preview_title",
    headerName: "Preview Title",
    width: 150,
    editable: false,
  },
  {
    field: "preview_description",
    headerName: "Preview Description",
    width: 150,
    editable: false,
  },
  {
    field: "preview_image_path",
    headerName: "Preview Image Path",
    width: 150,
    editable: false,
  },
  {
    field: "homepage_title",
    headerName: "Home Page Title",
    width: 150,
    editable: false,
  },
  {
    field: "homepage_subtitle",
    headerName: "Home Page Sub-title",
    width: 150,
    editable: false,
  },
  {
    field: "street",
    headerName: "Street",
    width: 150,
    editable: false,
  },
  {
    field: "suite",
    headerName: "Unit / Suite",
    width: 150,
    editable: false,
  },
  {
    field: "city",
    headerName: "City",
    width: 150,
    editable: false,
  },
  {
    field: "state",
    headerName: "State",
    width: 150,
    editable: false,
  },
  {
    field: "zip",
    headerName: "Zip",
    width: 150,
    editable: false,
  },
  {
    field: "country",
    headerName: "Country",
    width: 150,
    editable: false,
  },
  {
    field: "social_links",
    headerName: "Social Media Links",
    width: 150,
    editable: false,
    renderCell: (params) => <SocialLinksField {...params} />,
  },
  {
    field: "theme",
    headerName: "Theme",
    width: 250,
    editable: false,
    renderCell: (params) => <ThemeField {...params} />,
  },
  {
    field: "edit",
    headerName: "Actions",
    width: 125,
    editable: false,
    // eslint-disable-next-line
    renderCell: (params) => {
      return (
        <>
          <EditField_Agency {...params} />
          <DeleteField_Agency {...params} />
        </>
      );
    },
  },
];
export const USER_AGENCY_COLUMNS: GridColDef[] = [
  {
    field: "last_name",
    valueGetter: (params: any) =>
      params.row.user?.last_name || params.row.last_name,
    headerName: "Last Name",
    width: 150,
    editable: false,
  },
  {
    field: "first_name",
    valueGetter: (params: any) =>
      params.row.user?.first_name || params.row.first_name,
    headerName: "First Name",
    width: 150,
    editable: false,
  },
  {
    field: "agency",
    headerName: "Agency",
    valueGetter: (params: any) => params.row.agency?.name,
    width: 150,
    editable: false,
  },
  {
    field: "product_name",
    headerName: "Product",
    valueGetter: (params: any) => params.row.product?.product_name,
    width: 150,
    editable: false,
  },
  {
    field: "referral_token",
    headerName: "Referral Token",
    width: 400,
    editable: false,
  },
  {
    field: "role",
    headerName: "Role",
    valueGetter: (params: any) =>
      params.row.role || params.row.sales_director[0].role,
    width: 250,
    editable: false,
  },
  {
    field: "edit",
    headerName: "Actions",
    width: 125,
    editable: false,
    // eslint-disable-next-line
    renderCell: (params) => {
      return (
        <>
          {/* <EditField_UserAgency {...params} /> */}
          <DeleteField_UserAgency {...params} />
        </>
      );
    },
  },
];
export const COVERAGE_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 150 },
  {
    field: "level",
    headerName: "Level",
    width: 150,
    editable: false,
  },
  {
    field: "state",
    headerName: "State",
    width: 150,
    editable: false,
  },
  {
    field: "is_auto",
    headerName: "Is Auto",
    width: 150,
    renderCell: (params) => <ActiveField_Coverage {...params} />,
  },
  {
    field: "is_home",
    headerName: "Is Home",
    width: 150,
    renderCell: (params) => <ActiveField_Coverage {...params} />,
  },
  {
    field: "coverage_data",
    headerName: "Coverage Data",
    width: 600,
    editable: false,
    renderCell: (params) => <CoverageField {...params} />,
  },
];
export const COVERAGE_UI_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "internal_name",
    headerName: "Internal Name",
    width: 150,
    editable: false,
  },
  {
    field: "title",
    headerName: "Title",
    width: 250,
    editable: false,
  },
  {
    field: "description",
    headerName: "Description",
    width: 300,
    editable: false,
  },
  {
    field: "faqs",
    headerName: "FAQs",
    width: 350,
    editable: false,
    renderCell: (params) => <FAQField {...params} />,
  },
  {
    field: "delete",
    headerName: "Actions",
    width: 125,
    editable: false,
    // eslint-disable-next-line
    renderCell: (params) => {
      return (
        <>
          <DeleteField_CoverageUI {...params} />
          <EditField_CoverageUI {...params} />
        </>
      );
    },
  },
];
export const ALLOWED_IPS_COLUMNS: GridColDef[] = [
  { field: "id", headerName: "ID", width: 200 },
  {
    field: "ip",
    headerName: "IP Address",
    width: 250,
    editable: false,
  },
  {
    field: "comment",
    headerName: "Comments",
    width: 400,
    editable: false,
  },
  {
    field: "added_by_user_id",
    headerName: "Added By User ID",
    width: 200,
    editable: false,
  },
  {
    field: "delete",
    headerName: "Actions",
    width: 150,
    editable: false,
    // eslint-disable-next-line
    renderCell: (params) => <DeleteField_AllowedIP {...params} />,
  },
];
export const FAQS_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "order",
    headerName: "Order",
    width: 130,
    editable: true,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "category_id",
    headerName: "Category ID",
    flex: 1,
    editable: true,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "question",
    headerName: "Question",
    flex: 2,
    editable: true,
    sortable: false,
  },
  {
    field: "answer",
    headerName: "Answer",
    flex: 3,
    editable: true,
    sortable: false,
  },
  {
    field: "actions",
    headerName: " ",
    renderCell: FAQActionCell,
    sortable: false,
    width: 100,
    headerAlign: "center",
    filterable: false,
    align: "center",
    disableColumnMenu: true,
    disableReorder: true,
  },
];

export const FAQ_CATEGORIES_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 95,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "order",
    headerName: "Order",
    width: 125,
    align: "right",
    headerAlign: "right",
    editable: true,
  },
  {
    field: "category_name",
    headerName: "Name",
    flex: 1,
    editable: true,
    sortable: false,
  },
  {
    field: "actions",
    headerName: " ",
    renderCell: FAQCategoryActionCell,
    sortable: false,
    width: 100,
    headerAlign: "center",
    filterable: false,
    align: "center",
    disableColumnMenu: true,
    disableReorder: true,
  },
];
export const APPLICATION_COLUMNS: GridColDef[] = [
  {
    field: "quote_type",
    headerName: "Type",
    align: "center",
    headerAlign: "left",
    sortable: true,
    width: 100,
    renderCell: (params) => {
      return <QuoteTypeField {...params} />;
    },
  },
  {
    field: "created_at",
    headerName: "Submitted",
    sortable: true,
    width: 200,
    renderCell: (params) => <TimeField {...params} />,
  },
  {
    field: "fullStatus",
    headerName: "Status",
    sortable: true,
    width: 250,
    renderCell: (params) => <StatusField {...params} />,
  },

  {
    field: "phone",
    headerName: "Phone",
    sortable: true,
    width: 150,
  },
  // {
  //   field: "state",
  //   headerName: "State",
  //   sortable: true,
  //   width: 150,
  // },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    width: 245,
  },
  {
    field: "affiliate_agent",
    headerName: "Referral Source",
    sortable: true,
    width: 150,
  },
  {
    field: "id",
    headerName: "ID",
    width: 65,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "req_uid",
    headerName: "",
    width: 100,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => <QuoteLinkField {...params} />,
  },
];

export const DIRECTOR_APPLICATION_COLUMNS: GridColDef[] = [
  {
    field: "quote_type",
    headerName: "Type",
    align: "left",
    headerAlign: "left",
    sortable: true,
    width: 100,
    renderCell: (params) => {
      return <QuoteTypeField {...params} />;
    },
  },
  {
    field: "agencyName",
    headerName: "Agency",
    sortable: true,
    width: 200,
  },
  {
    field: "created_at",
    headerName: "Submitted",
    sortable: true,
    width: 215,
    renderCell: (params) => <TimeField {...params} />,
  },
  {
    field: "fullStatus",
    headerName: "Status",
    sortable: true,
    width: 255,
    renderCell: (params) => <StatusField {...params} />,
  },
  {
    field: "status_last_updated",
    headerName: "Status last updated",
    sortable: true,
    width: 215,
    renderCell: (params) => <TimeField {...params} />,
  },
  {
    field: "affiliate_agent",
    headerName: "Referral Source",
    sortable: true,
    width: 150,
  },
  {
    field: "id",
    headerName: "ID",
    width: 65,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "req_uid",
    headerName: "",
    width: 75,
    sortable: false,
    filterable: false,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => <QuoteLinkField {...params} />,
  },
];

export const REFERRAL_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "Referrals",
    width: 100,
    sortable: false,
    filterable: false,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => <ReferralLinkField {...params} />,
  },
  {
    field: "category",
    headerName: "Category",
    sortable: true,
    width: 120,
  },
  {
    field: "productName",
    headerName: "Product",
    sortable: true,
    width: 150,
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    width: 125,
  },
  {
    field: "created_at",
    headerName: "Date",
    sortable: true,
    width: 200,
    renderCell: (params) => <TimeField {...params} />,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: true,
    width: 125,
  },
  {
    field: "phone",
    headerName: "Phone",
    sortable: true,
    width: 125,
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    width: 200,
  },
  {
    field: "referringAgent",
    headerName: "Ref. agent",
    sortable: true,
    width: 225,
  },
];

export const UDD_COLUMNS: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 95,
    align: "right",
    headerAlign: "right",
  },
  {
    field: "created_at",
    headerName: "Time",
    sortable: true,
    width: 200,
    renderCell: (params) => <TimeField {...params} />,
  },
  {
    field: "grecaptcha_score",
    headerName: "Recaptcha Score",
    sortable: true,
    width: 150,
  },
  {
    field: "agencyName",
    headerName: "Agency",
    sortable: true,
    width: 150,
  },
  {
    field: "responded_real_data",
    headerName: "Response Data",
    sortable: true,
    width: 150,
  },
  {
    field: "driver_first_name",
    headerName: "First Name",
    sortable: true,
    width: 150,
  },
  {
    field: "driver_last_name",
    headerName: "Last Name",
    sortable: true,
    width: 150,
  },
  {
    field: "driver_address",
    headerName: "Address",
    sortable: true,
    width: 200,
  },
  {
    field: "driver_city",
    headerName: "City",
    sortable: true,
    width: 150,
  },
  {
    field: "driver_zip",
    headerName: "Zip",
    sortable: true,
    width: 125,
  },
  // {
  //   field: "response",
  //   headerName: "Response",
  //   sortable: false,
  //   width: 200,
  // },
  {
    field: "ip",
    headerName: "IP",
    sortable: true,
    width: 200,
  },
];
