import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  selectedQuotePageWrapper: {
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-20px",
    },
  },
  selectedQuoteContainer: {
    width: 320,
    // using this to ensure that theres a scrollbar when the page loads so the page doesn't jump
    minHeight: "calc(100vh - 304px)",
  },
  //contact cards styles
  contactCardsContainer: {
    position: "sticky",
    top: 170,
    [theme.breakpoints.down("sm")]: {},
  },
  contactWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: 480,
    maxWidth: "100%",
    position: "sticky",
    top: 200,
    [theme.breakpoints.down("sm")]: {
      marginTop: 18,
      position: "static",
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 24,
    margin: "0 auto",
    textAlign: "center",
  },
  contactCard: {
    borderRadius: 3,
    border: "none",
    width: "100%",
    minHeight: 64,
    padding: "20px",
    boxShadow: `0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC`,
    marginBottom: 18,
    cursor: "pointer",
    "&:hover": {
      boxShadow: `0 2px 40px 2px ${theme.palette.secondary.contrastText}, 0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC`,
    },
    transition: "all 300ms linear",
    display: "flex",
    alignContent: "center",
  },
  contactTitle: {
    fontSize: 18,
    marginLeft: 12,
  },
  status: {
    fontWeight: 300,
    fontSize: 16,
    marginLeft: "auto",
  },
  cardTitle: {
    "& > div": {
      backgroundColor: "#dadff6",
      height: 32,
      width: 32,
      borderRadius: "100%",
      marginRight: 16,
      position: "relative",
      overflow: "hidden",
      "& svg": {
        color: theme.palette.primary.main,
        position: "absolute",
        bottom: -1,
        right: -1,
      },
    },
    "& > p": {
      fontFamily: "Lato",
      fontWeight: 600,
      fontSize: 14,
      textTransform: "uppercase",
      letterSpacing: "1px",
    },
  },
  bundleBoxContainer: {
    background: "#EFF1FB",
    borderRadius: 3,
    padding: 32,
    width: 480,
    "& svg": {
      position: "absolute",
      bottom: -8,
      right: -64,
      height: 140,
      width: 175,
    },
  },
  bundleBoxWrapper: {
    position: "fixed",
    left: 0,
    bottom: 0,
    "& .bundleBoxCollapse": {
      height: 28,
      width: 28,
      borderRadius: 100,
      background: "#cdd2e9",
      boxShadow: "0 2px 5px 0 #DADFF6",
      position: "absolute",
      bottom: 16,
      left: 16,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      "&.open": {
        top: -16,
        bottom: "unset",
      },
    },
  },
  bundleBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      margin: 0,
      fontSize: 16,
      fontWeight: 600,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
        fontSize: 14,
        fontWeight: 500,
      },
    },
    "& button": {
      marginTop: 24,
      height: 32,
      padding: "0 24px",
      [theme.breakpoints.down("sm")]: {
        minWidth: 142,
        padding: 0,
        marginTop: 16,
      },
    },
    [theme.breakpoints.down("sm")]: {
      background: "#fff",
      boxShadow: "0 2px 24px 0 #dadff6, 0 2px 40px 0 #eff1fb",
      width: "100vw",
      padding: "24px 16px 32px 16px",
    },
  },
  cardContainer: {
    boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
    borderRadius: 3,
  },
  logoContainer: {},
  topContainer: { padding: "25px 50px", borderBottom: "solid 1.5px #EFF1FB" },
  logo: {},
  premium: { display: "flex", justifyContent: "space-between", marginTop: 10 },
  price: {
    color: theme.palette.primary.main,
    fontSize: 25,
    fontWeight: 500,
  },
  priceInterval: {
    color: "#8D98C2",
    fontSize: 15,
    fontWeight: 500,
  },
  bottom: { padding: "10px 0" },
  listItem: {
    margin: "10px 50px",
    paddingBottom: 15,
    borderBottom: "solid 1.5px #EFF1FB",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  product: {
    fontWeight: 500,
    fontSize: 16,
  },
  field: {},
}));
