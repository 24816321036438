import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  Box,
  Paper,
  CardContent,
  MenuItem,
  Typography,
} from "@mui/material";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

import {
  adminActions,
  adminSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useFormik, yup } from "../../lib";

import { useStyles } from "./AddAgencyPage.styles";
import { FileUpload } from "../../components/forms/inputs/FileUpload";

const initialValues = {
  name: "",
  domain: "",
  group_id: "",
  email_address: "no-reply@reli-exchange.com",
  phone: "8777078555",
  theme: {
    palette: {
      info: { main: "#FF4343" },
      type: "light",
      primary: { main: "#0033ff", light: "#0066ff", contrastText: "#FFFFFF" },
      secondary: {
        main: "#8D98C2",
        light: "#cdd2e9",
        contrastText: "#0033ff3d",
      },
    },
  },
  email_to: "",
  social_links: {},
  preview_title: "",
  preview_description: "",
  preview_image_path: "",
  homepage_title:
    "Get personalized insurance quotes and advice - no obligation",
  homepage_subtitle: "Get the best offer fast, whatever your circumstances.",
  street: "",
  city: "",
  state: "",
  zip: "",
  country: "USA",
};
const validationSchema = yup.object({
  homepage_title: yup.string().max(77, "Character limit exceeded"),
  homepage_subtitle: yup.string().max(61, "Character limit exceeded"),
});

const AddAgencyPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [nextSelected, setNextSelected] = useState(false);
  const [agencyId, setAgencyId] = useState(0);
  const [agencyExists, setAgencyExists] = useState(false);
  const [uploadedPreviewImagePath, setUploadedPreviewImagePath] = useState<
    undefined | string
  >();

  const agencies = useSelector(adminSelectors.agencies);
  const groups = useSelector(adminSelectors.agency_groups);

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit() {},
  });

  useEffect(() => {
    dispatch(adminActions.setPageTitle("Add Agency"));
    dispatch(adminActions.getAgencies());
    dispatch(adminActions.getAgencyGroups());
  }, [dispatch]);

  useEffect(() => {
    if (nextSelected) {
      const newAgencyId = agencies.filter(
        (agency) =>
          agency.name === form.values.name &&
          agency.domain === form.values.domain,
      )[0]?.id;
      setAgencyId(newAgencyId);
    }
  }, [agencies, nextSelected]);

  useEffect(() => {
    if (form.values.name) {
      form.setFieldValue(
        "preview_title",
        `${form.values.name} | Powered by RELI Exchange`,
      );
      form.setFieldValue(
        "preview_description",
        `Instantly compare auto and home insurance quotes from top insurers | ${form.values.name} | Powered by RELI Exchange.`,
      );
    }
  }, [form.values.name]);

  useEffect(() => {
    if (form.values.name && form.values.domain) {
      const existingAgency = agencies.filter(
        (agency) =>
          agency.name === form.values.name &&
          agency.domain === form.values.domain,
      );
      setAgencyExists(existingAgency.length ? true : false);
    }
  }, [form.values.name, form.values.domain]);

  useEffect(() => {
    form.setFieldValue("preview_image_path", uploadedPreviewImagePath);
  }, [uploadedPreviewImagePath]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          {!nextSelected ? (
            <>
              <Grid container>
                <Grid item xs={6} className={classes.formControl}>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    value={form.values.name}
                    onChange={form.handleChange}
                    error={agencyExists}
                    helperText={
                      agencyExists &&
                      "Agency with this name & domain already exists."
                    }
                  />
                </Grid>

                <Grid item xs={6} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="domain"
                    label="Domain"
                    variant="outlined"
                    value={form.values.domain}
                    onChange={form.handleChange}
                    error={agencyExists}
                    helperText={
                      agencyExists &&
                      "Agency with this name & domain already exists."
                    }
                  />
                </Grid>

                <Grid item xs={6} className={classes.formControl}>
                  <TextField
                    select
                    id="group_id"
                    name="group_id"
                    label="Group"
                    variant="outlined"
                    value={form.values.group_id}
                    onChange={form.handleChange}
                  >
                    {groups &&
                      groups.map((group) => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid item xs={6} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="phone"
                    label="Phone"
                    variant="outlined"
                    value={form.values.phone}
                    onChange={form.handleChange}
                  />
                </Grid>

                <Grid item xs={6} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="email_address"
                    label="Email From Address"
                    variant="outlined"
                    value={form.values.email_address}
                    onChange={form.handleChange}
                  />
                </Grid>

                <Grid item xs={6} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="email_to"
                    label="Email To Address"
                    variant="outlined"
                    value={form.values.email_to}
                    onChange={form.handleChange}
                  />
                </Grid>

                <Grid item xs={12} className={classes.sectionHeader}>
                  <Typography>Address</Typography>
                </Grid>

                <Grid item xs={12} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="street"
                    label="Street"
                    variant="outlined"
                    value={form.values.street}
                    onChange={form.handleChange}
                  />
                </Grid>

                <Grid item xs={5} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="city"
                    label="City"
                    variant="outlined"
                    value={form.values.city}
                    onChange={form.handleChange}
                  />
                </Grid>

                {/* Should be a dropdown, when redesigned */}
                <Grid item xs={2} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="state"
                    label="State"
                    variant="outlined"
                    value={form.values.state}
                    onChange={form.handleChange}
                  />
                </Grid>

                <Grid item xs={5} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="zip"
                    label="Zip"
                    variant="outlined"
                    value={form.values.zip}
                    onChange={form.handleChange}
                  />
                </Grid>

                <Grid item xs={6} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="country"
                    label="Country"
                    variant="outlined"
                    value={form.values.country}
                    onChange={form.handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box style={{ marginBottom: 10 }}></Box>
                </Grid>

                <Grid item xs={12} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="preview_title"
                    label="Social Media Preview Title"
                    variant="outlined"
                    value={form.values.preview_title}
                    onChange={form.handleChange}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="preview_description"
                    label="Social Media Preview Description"
                    variant="outlined"
                    value={form.values.preview_description}
                    onChange={form.handleChange}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="homepage_title"
                    label="Home Page Title"
                    variant="outlined"
                    value={form.values.homepage_title}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.homepage_title)}
                    helperText={form.errors.homepage_title}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formControl}>
                  <TextField
                    fullWidth
                    name="homepage_subtitle"
                    label="Home Page Sub-title"
                    variant="outlined"
                    value={form.values.homepage_subtitle}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.homepage_subtitle)}
                    helperText={form.errors.homepage_subtitle}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formControl}>
                  <FileUpload
                    label="Upload Social Media Preview Image"
                    successMsg="Image uploaded"
                    redirectUrl="/admin/agencies"
                    path={`/file-upload`}
                    fileNameCB={setUploadedPreviewImagePath}
                  />
                </Grid>

                <Grid item xs={12} className={classes.formControl}>
                  <Typography className={classes.jsonHeader}>
                    Social Media Links
                  </Typography>
                  <Paper>
                    <Box className={classes.jsonInput}>
                      <JSONInput
                        key={"social_links"}
                        id="social_links"
                        name="social_links"
                        label="Social Media Links"
                        placeholder={form.values.social_links}
                        onChange={(e) => {
                          form.setFieldValue("social_links", e.jsObject);
                        }}
                        colors={{
                          default: "#484c4f",
                          background: "#eff1f6",
                          background_warning: "#FEECEB",
                          string: "#FA7921",
                          number: "#70CE35",
                          colon: "#49B8F7",
                          keys: "#1277ab",
                        }}
                        height={"100%"}
                        width={"100%"}
                        locale={locale}
                        error={
                          form.touched.social_links &&
                          Boolean(form.errors.social_links)
                        }
                        helperText={
                          form.touched.social_links && form.errors.social_links
                        }
                      ></JSONInput>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} className={classes.formControl}>
                  <Typography className={classes.jsonHeader}>Theme</Typography>
                  <Paper>
                    <Box className={classes.jsonInput}>
                      <JSONInput
                        key={"theme"}
                        id="theme"
                        name="theme"
                        label="Theme"
                        placeholder={form.values.theme}
                        onChange={(e) => {
                          form.setFieldValue("theme", e.jsObject);
                        }}
                        colors={{
                          default: "#484c4f",
                          background: "#eff1f6",
                          background_warning: "#FEECEB",
                          string: "#FA7921",
                          number: "#70CE35",
                          colon: "#49B8F7",
                          keys: "#1277ab",
                        }}
                        height={"100%"}
                        width={"100%"}
                        locale={locale}
                        error={form.touched.theme && Boolean(form.errors.theme)}
                        helperText={form.touched.theme && form.errors.theme}
                      ></JSONInput>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end">
                <Grid item className={classes.formAction}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={
                      !form.isValid ||
                      !form.values.name ||
                      !form.values.domain ||
                      !form.values.theme ||
                      !form.values.group_id ||
                      !form.values.street ||
                      !form.values.city ||
                      !form.values.state ||
                      !form.values.zip ||
                      !form.values.country ||
                      agencyExists
                    }
                    onClick={() => {
                      setNextSelected(true);
                      dispatch(adminActions.createAgency(form.values));
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} className={classes.formControl}>
              <FileUpload
                label="Upload Agency Logo"
                successMsg="Agency created"
                redirectUrl="/admin/agencies"
                path={`/agency/${agencyId}/logo`}
                showSaveBtn={true}
              />
            </Grid>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

export default AddAgencyPage;
