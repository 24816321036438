import React from "react";

export const PdImage = ({ fillColor }) => {
  return (
    <svg
      width="84px"
      height="84px"
      viewBox="0 0 84 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="84" height="84"></rect>
      </defs>
      <g
        id="↳-auto-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Create-a-custom-quote-side-bar"
          transform="translate(-560.000000, -243.000000)"
        >
          <g id="Group" transform="translate(560.000000, 243.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use id="Rectangle" fill="#FFFFFF" xlinkHref="#path-1"></use>
            <g id="Group-4" mask="url(#mask-2)">
              <g transform="translate(-33.000000, 8.936571)">
                <path
                  d="M78.3586574,27.2179035 L77.9775218,28.8125877 L105.862306,30.1940669 L106.166737,28.4978329 L109.522885,28.6523072 L109.22771,30.3607972 L113.073888,30.551346 L117,33.3208126 L112.220103,35.7188366 L108.342187,35.4862672 L108.106047,36.8530592 L111.992427,37.0968341 L115.963769,39.92618 L111.138643,42.2643248 L107.220523,41.9785292 L106.984384,43.3453212 L110.910967,43.6423223 L114.927537,46.5315475 L110.057183,48.8098129 L106.09886,48.4707912 L104.268777,59.0634292 L100.747849,58.690798 L102.635327,48.1741472 L73.9374847,45.7162402 L71.5744441,55.6032822 L68.0535158,55.230651 L70.4739519,45.4195962 L68,45.2077077 L69.1910159,40.486686 L71.6451307,40.6723117 L71.957445,39.4063691 L69.5086202,39.2277469 L70.6996361,34.5067252 L73.1286238,34.6590845 L73.4409381,33.393142 L71.0172403,33.2477861 L72.2082562,28.5267644 L74.6121169,28.6458574 L75.0025098,27.0634292 L78.3586574,27.2179035 Z M104,41.8762156 L75.3079746,40.0634292 L75,41.179567 L103.754005,43.0634292 L104,41.8762156 Z M105,35.7256682 L77.3004045,34.0634292 L77,35.3211014 L104.760052,37.0634292 L105,35.7256682 Z"
                  id="Combined-Shape"
                  fill="#8D98C2"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M52.3654911,18.5312089 L52.9327376,20.0000387 L19.7019505,23.4580428 L19.4555617,22.1676597 L15.4592693,22.577644 L15.664925,23.8441117 L11.0442155,24.2770844 L6,26.5370919 L11.5497578,28.0279211 L16.3125703,27.6798412 L16.4930475,28.7119051 L11.6921238,29.0370899 L6.46941187,31.2045442 L12.254326,32.8550187 L17.1989811,32.616655 L17.3950018,33.6671244 L12.4118013,33.8820786 L7.00822047,35.9541705 L13.0306634,37.7670995 L18.1592237,37.6408947 L19.8844387,46.0260253 L24.6241004,46.0634292 L22.635553,37.5172494 L59.3244054,36.018244 L63.3838582,45.7674029 L68,45.6585443 L63.704006,35.7800604 L66.8259144,35.6023772 L64.7537214,30.8822875 L61.7117344,31.1024984 L61.1899823,29.8664218 L64.2107646,29.6349977 L62.2107822,25.000414 L59.2691136,25.2734095 L58.7664023,24.0598795 L61.6870817,23.7759259 L59.7593099,19.2268483 L56.9169365,19.5514168 L56.3129748,18.0634292 L52.3654911,18.5312089 Z M21.5162411,32.3970589 L56.9534365,30.1815932 L57.4451932,31.4016056 L21.745603,33.4675543 L21.5162411,32.3970589 Z M20.4725303,27.3663892 L54.6714505,24.4503089 L55.1444739,25.6481389 L20.6859762,28.4180381 L20.4725303,27.3663892 Z"
                  id="Combined-Shape-Copy"
                  fill="#8D98C2"
                  fillRule="nonzero"
                ></path>
                <g
                  id="Group-Copy"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(0.000000, 20.063429)"
                >
                  <path
                    d="M70,33.1347226 L68.2507574,33.5418364 L1.77132861,33.5775862 L1.17352497,32.1763221 C0.130916131,29.7727009 -0.226932866,27.1247801 0.13995274,24.5283549 C0.426270431,22.5930761 1.14374427,20.6700983 2.77332529,19.8348742 L5.72345021,0.0662214234 L42.5031042,0 L56.7330145,11.547014 C56.7330145,11.547014 63.4454735,11.4256143 67.0921592,15.9930955 C68.2462343,17.4398134 69.0937268,19.3564841 69.3211515,21.8976453 L69.465068,24.2736835 L70,33.1347226 Z"
                    id="Path"
                    fill={fillColor}
                    fillRule="nonzero"
                  ></path>
                  <ellipse
                    id="Oval"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    cx="55.4020101"
                    cy="32.6939655"
                    rx="8.26633166"
                    ry="8.30603448"
                  ></ellipse>
                  <ellipse
                    id="Oval"
                    fill="#CCCCCC"
                    fillRule="nonzero"
                    cx="55.4020101"
                    cy="32.6939655"
                    rx="4.39698492"
                    ry="4.41810345"
                  ></ellipse>
                  <polygon
                    id="Path"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    points="51.0050251 10.238212 31.1462054 10.247053 28.7220511 10.247053 24.6288778 10.25 24.6288778 9.94469039 24.6259967 6.26329308 24.6231156 2.12953067 27.3460396 2.12953067 29.7701939 2.12658366 34.0644677 2.12363666 36.4886221 2.12363666 41.4461242 2.12068966"
                  ></polygon>
                  <polygon
                    id="Rectangle"
                    fill="#3F3D56"
                    fillRule="nonzero"
                    transform="translate(47.038906, 13.219713) rotate(89.969370) translate(-47.038906, -13.219713) "
                    points="46.3633441 11.9422857 47.714461 11.9422891 47.7144676 14.4971407 46.3633507 14.4971372"
                  ></polygon>
                  <path
                    d="M46.7839196,7.42241379 L46.9246253,7.42241379 C48.4010838,7.42241379 49.5979899,8.68836726 49.5979899,10.25 L49.5979899,10.25 C49.5979899,11.8116327 48.4010838,13.0775862 46.9246253,13.0775862 L46.7839196,13.0775862 L46.7839196,7.42241379 Z"
                    id="Path"
                    fill="#3F3D56"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M69.6482412,24.3626206 C67.5008123,24.5875897 65.4965074,23.2923782 64.8981194,21.2930216 C64.2997315,19.2936651 65.2806231,17.1694091 67.222793,16.2586207 C68.4024208,17.6744866 69.268678,19.5502832 69.5011382,22.0372528 L69.6482412,24.3626206 Z"
                    id="Path"
                    fill="#3F3D56"
                    fillRule="nonzero"
                  ></path>
                  <polygon
                    id="Path"
                    fill="#F2F2F2"
                    fillRule="nonzero"
                    points="36.5829146 2.12068966 31.2596741 10.25 28.8442211 10.25 34.1674616 2.12068966"
                  ></polygon>
                </g>
                <polygon
                  id="Path-Copy-8"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(104.391264, 56.773130) rotate(23.000000) translate(-104.391264, -56.773130) "
                  points="114.180071 55.6513423 116.498473 57.7340176 114.180071 59.5140105 94.8600566 57.930111 92.2840546 55.8719923 94.4307659 54.0322485"
                ></polygon>
                <polygon
                  id="Path-Copy-10"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(77.816806, 20.552801) rotate(23.000000) translate(-77.816806, -20.552801) "
                  points="78.3901797 19.6465926 78.5259788 21.3290308 78.3901797 22.7669545 77.2585204 21.48744 77.1076325 19.8248392 77.2333749 18.3386473"
                ></polygon>
                <polygon
                  id="Path-Copy-12"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(79.038967, 13.529768) rotate(73.000000) translate(-79.038967, -13.529768) "
                  points="79.4648314 12.910998 79.5656941 14.0597867 79.4648314 15.041618 78.6243088 14.1679504 78.5122391 13.0327069 78.6056324 12.0179175"
                ></polygon>
                <polygon
                  id="Path-Copy-13"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(66.690567, 22.529768) rotate(173.000000) translate(-66.690567, -22.529768) "
                  points="66.8347478 22.3202779 66.8688958 22.7092107 66.8347478 23.0416185 66.5501813 22.7458305 66.5122391 22.3614836 66.5438583 22.0179175"
                ></polygon>
                <polygon
                  id="Path-Copy-14"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(70.690567, 17.529768) rotate(173.000000) translate(-70.690567, -17.529768) "
                  points="70.8347478 17.3202779 70.8688958 17.7092107 70.8347478 18.0416185 70.5501813 17.7458305 70.5122391 17.3614836 70.5438583 17.0179175"
                ></polygon>
                <polygon
                  id="Path-Copy-15"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(57.690567, 0.529768) rotate(173.000000) translate(-57.690567, -0.529768) "
                  points="57.8347478 0.320277935 57.8688958 0.709210675 57.8347478 1.0416185 57.5501813 0.745830474 57.5122391 0.361483582 57.5438583 0.0179174998"
                ></polygon>
                <polygon
                  id="Path-Copy-16"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(66.690567, 5.529768) rotate(173.000000) translate(-66.690567, -5.529768) "
                  points="66.8347478 5.32027794 66.8688958 5.70921067 66.8347478 6.0416185 66.5501813 5.74583047 66.5122391 5.36148358 66.5438583 5.0179175"
                ></polygon>
                <polygon
                  id="Path-Copy-17"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(87.690567, 7.529768) rotate(173.000000) translate(-87.690567, -7.529768) "
                  points="87.8347478 7.32027794 87.8688958 7.70921067 87.8347478 8.0416185 87.5501813 7.74583047 87.5122391 7.36148358 87.5438583 7.0179175"
                ></polygon>
                <polygon
                  id="Path-Copy-18"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(82.690567, 16.529768) rotate(173.000000) translate(-82.690567, -16.529768) "
                  points="82.8347478 16.3202779 82.8688958 16.7092107 82.8347478 17.0416185 82.5501813 16.7458305 82.5122391 16.3614836 82.5438583 16.0179175"
                ></polygon>
                <polygon
                  id="Path-Copy-11"
                  fill="#3F3D56"
                  fillRule="nonzero"
                  transform="translate(62.366133, 12.827464) rotate(123.000000) translate(-62.366133, -12.827464) "
                  points="62.9395068 11.9212561 63.0753059 13.6036943 62.9395068 15.041618 61.8078475 13.7621036 61.6569596 12.0995028 61.7827021 10.6133109"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
