import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WizardNoPersistState } from "./types";

const initialState: WizardNoPersistState = {
  mode: "",
};

export const wizardNoPersist = {
  ...createSlice({
    name: "wizardNoPersist",
    initialState,
    reducers: {
      // General Wizard Info
      setWizardMode(state, { payload }: PayloadAction<string>) {
        state.mode = payload;
      },
    },
  }),
  persist: false,
};
