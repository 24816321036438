import React from "react";
import { Box, Button, Modal, Typography, Divider } from "@mui/material";

export const StatementsModal = ({
  data,
  open,
  setOpen,
  setAcceptedConditions,
}) => {
  const handleClose = () => setOpen(false);
  const handleAccept = () => {
    setAcceptedConditions(true);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflowY: "auto",
            maxHeight: "90vh",
          }}
        >
          {data.map((providerData, index) => (
            <div key={index}>
              <Typography variant="h6" component="h2" gutterBottom>
                {providerData.provider} - {providerData.type}
              </Typography>
              {providerData.statementGroups.length > 0 ? (
                providerData.statementGroups.map((group, groupIndex) => (
                  <div key={groupIndex}>
                    <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                      {group.heading.statementText}
                    </Typography>
                    {group.statements.map((statement, statementIndex) => (
                      <Typography
                        key={statementIndex}
                        variant="body2"
                        sx={{ mt: 1 }}
                      >
                        {statement.statementText}
                      </Typography>
                    ))}
                    {group.statementGroups.length > 0 &&
                      group.statementGroups.map((nestedGroup, nestedIndex) => (
                        <div key={nestedIndex}>
                          <Divider sx={{ my: 2 }} />
                          <Typography variant="subtitle2">
                            {nestedGroup.heading.statementText}
                          </Typography>
                          {nestedGroup.statements.map(
                            (nestedStatement, nestedStatementIndex) => (
                              <Typography
                                key={nestedStatementIndex}
                                variant="body2"
                                sx={{ mt: 1 }}
                              >
                                {nestedStatement.statementText}
                              </Typography>
                            ),
                          )}
                        </div>
                      ))}
                  </div>
                ))
              ) : (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  No statements available for this provider.
                </Typography>
              )}
              {index < data.length - 1 && <Divider sx={{ my: 3 }} />}
            </div>
          ))}
          <Box mt={4} textAlign="center">
            <Button onClick={handleAccept} variant="contained" color="primary">
              Accept
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
