import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: "25px 15px 0 0px",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  flex: { display: "flex" },
  leftWrapper: {
    width: "100%",
  },
  appWrapper: {
    border: "1px solid #DADFF6",
    borderRadius: 3,
    padding: 15,
    marginBottom: 20,
  },
  greeting: {
    fontSize: 34,
    fontWeight: 600,
    paddingBottom: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      marginTop: 20,
    },
  },
  date: {
    color: "#8D98C2",
    display: "flex",
    "& .MuiSvgIcon-root": {
      marginRight: 10,
    },
  },
  appTitle: { fontSize: 22, fontWeight: 600, paddingBottom: 10 },
  taskHeader: { display: "flex", justifyContent: "space-between" },

  calHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #DADFF6",
    borderRadius: 3,
    padding: 28,
    "& .MuiTypography-root": {
      fontSize: 20,
      fontWeight: "600",
    },
    "& .MuiSvgIcon-root": {
      width: 35,
      height: 35,
      color: "blue",
      cursor: "pointer",
    },
  },
  alertItem: {
    height: 150,
    // width: 270,
    width: "18rem",
    borderRadius: 3,
    boxShadow: "0 2px 20px 0 rgba(141,152,194,0.29)",
    margin: "20px 0",
    padding: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& .MuiSvgIcon-root": {
      color: "blue",
      marginRight: 10,
      cursor: "pointer",
    },
    "& .MuiTypography-root": {
      maxWidth: 230,
      fontWeight: "600",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    "& div:nth-child(1)": {
      height: "30%",
    },
    "& p:nth-child(1)": {
      height: "30%",
    },
    "& div:nth-child(3)": {
      height: "20%",
    },
    "& div:nth-child(4)": {
      height: "20%",
    },
  },
  taskDate: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiSvgIcon-root": {
      color: "grey",
      marginRight: 0,
    },
    "& span": {
      color: "red",
      display: "flex",
      fontWeight: 500,
      "& .MuiSvgIcon-root": {
        margin: "2px 2px 0 0",
        width: 25,
        height: 20,
        color: "red",
        cursor: "initial",
      },
    },
  },
  carousel: {
    "& .react-multiple-carousel__arrow": {
      backgroundColor: "white",
      boxShadow: "0 2px 20px 0 rgba(141,152,194)",
      "&::before": {
        color: "grey",
      },
    },
  },
  description: {
    width: "16rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "auto",
  },
  controls: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  controlsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
    width: "100%",
    "& .MuiTypography-colorError": {
      marginTop: 8,
    },
  },
  calendars: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.primary.main,
    "& .MuiTypography-root": {
      padding: "0 5px",
      fontWeight: 500,
      textTransform: "uppercase",
    },
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  mobileCalIcon: {
    backgroundColor: "blue",
    height: 35,
    width: 35,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 3,
  },
  calendarItem: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #DADFF6",
    minHeight: 100,
    alignItems: "center",
    padding: "0 25px",
    color: "#8D98C2",
    fontSize: 14,
    "& .MuiTypography-root": {
      fontSize: 20,
      fontWeight: 600,
      color: "black",
      lineHeight: 1,
    },
    "&:last-of-type": {
      border: "none",
    },
    "& .MuiSvgIcon-root": {
      width: 25,
      height: 20,
      marginLeft: 10,
    },
    " & a": {
      textDecoration: "none",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "0 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  day: {
    color: "#0033ff",
    width: 85,
    height: 50,
    display: "flex",
    alignItems: "center",
    borderRadius: 3,
    justifyContent: "center",
    backgroundColor: "rgb(233 236 246)",
    fontWeight: 600,
    fontSize: 20,
    marginRight: 20,
    [theme.breakpoints.down("lg")]: {
      width: 70,
    },
  },
  itemsContainer: {
    border: "1px solid #DADFF6",
    borderRadius: 3,
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      borderRight: "none",
    },
  },
  icons: {
    color: theme.palette.primary.main,
    margin: 4,
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      width: 25,
      height: 25,
      verticalAlign: "middle",
    },
  },
  agenda: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-evenly",
    },
    "& .MuiTypography-root": {
      maxWidth: 700,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("lg")]: {
        maxWidth: 400,
      },
      [theme.breakpoints.down("sm")]: {
        width: 225,
        marginBottom: 5,
        fontSize: 16,
      },
    },
  },
  agendaSubText: {
    color: "#8D98C2",
    fontFamily: "Montserrat",
    fontSize: 16,
    maxWidth: 1000,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "& a": {
      backgroundColor: "#1c71e8",
      color: "#fff",
      padding: "2px 8px",
      borderRadius: 4,
      fontWeight: 500,
      marginRight: 10,
      fontSize: 14,
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: 645,
    },
    [theme.breakpoints.down(1025)]: {
      maxWidth: 485,
    },
    [theme.breakpoints.down("sm")]: {
      width: 235,
      fontSize: "1em",
      maxHeight: "2.1em",
      whiteSpace: "inherit",
      textOverflow: "inherit",
      lineHeight: 1,
      "& a": {
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
        padding: 0,
        borderRadius: 0,
        fontWeight: 600,
        marginRight: 5,
        fontSize: 12,
      },
    },
  },
  headers: {
    fontSize: 22,
    fontWeight: 600,
  },
  button: {
    height: 45,
    width: 150,
    borderRadius: 3,
    marginLeft: 15,
    [theme.breakpoints.down("sm")]: {
      height: 35,
      minWidth: 35,
      width: 35,
    },
  },
  popper: {
    height: 94,
    width: 190,
    borderRadius: 3,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 0px 2px 1px #DADFF6",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      "& div:nth-child(1)": {
        borderBottom: ".2px solid",
      },
      "& div:nth-child(3)": {
        borderTop: ".2px solid",
      },
    },
  },
  popperContent: {
    height: "50%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    "& .MuiTypography-root": {
      paddingLeft: 10,
    },
    "&:hover": {
      color: theme.palette.primary.main,
      cursor: "pointer",
      backgroundColor: "#f7f9ff",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      color: "#8D98C2",
      height: 48,
      padding: 0,
      "& .MuiSvgIcon-root": {
        marginLeft: 10,
      },
      "& a": {
        display: "flex",
        textDecoration: "none",
        color: "#8D98C2",
      },
    },
  },
  calendarPopper: {
    borderRadius: 3,
    backgroundColor: "#FFFFFF",
    boxShadow: " 0 2px 20px 0 rgba(141,152,194,0.25)",
    padding: 15,
    marginTop: 10,
    "& .MuiTypography-root:first-child": {
      fontWeight: 600,
      display: "block",
      marginBottom: 10,
    },
    "& .MuiTypography-root:not(first-child)": {
      fontWeight: 500,
      display: "inline",
    },
  },
  appBar: {
    boxShadow: "none",
    margin: "20px 0",

    backgroundColor: "white",
    borderBottom: "1px solid #DADFF6",
    "& .MuiTab-root": {
      fontWeight: 400,
      textTransform: "none",
      width: 150,
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#000C3B",
      textTransform: "none",
      borderBottom: "2px solid black",
    },
  },
  dateControls: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& div": {
      display: "flex",
    },
    "& .MuiTypography-root": {
      textDecoration: "underline",
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      marginRight: 8,
    },
    "& .MuiTextField-root": {
      width: 0,
      height: 0,
      border: "none",
      "& .MuiInputAdornment-root": {
        display: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none",
      },
    },
    "& .MuiPaper-root": {
      position: "relative",
    },
  },
  mobileDay: {
    textAlign: "center",
    fontSize: 24,
    color: "blue",
    fontWeight: 600,
  },
  mobileIcon: {
    marginRight: 10,
    color: "black",
    cursor: "pointer",
    transform: "rotate(90deg)",
  },
  mobileTasks: {
    margin: "20px 0",
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0 0 7px 0",
      minHeight: 48,
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 48,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
  },
  accordion: {
    boxShadow: "0 2px 20px 0 rgba(141,152,194,0.33)",
    marginBottom: 7,
    margin: "0 0 7px 0",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      "& .MuiTypography-root": {
        fontWeight: 600,
        marginBottom: 0,
        maxWidth: "initial",
        textOverflow: "initial",
        overflow: "initial",
        whiteSpace: "initial",
      },
    },
    "& .MuiAccordionSummary-content": {
      "& .MuiTypography-root": {
        fontWeight: 600,
        marginBottom: 0,
        maxWidth: 250,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      "& .MuiSvgIcon-root": {
        color: "red",
        marginRight: 10,
      },
    },
    "& .MuiAccordionDetails-root": {
      display: "flex",
      flexDirection: "column",
      padding: "0 16px 10px 16px",
    },
  },
  mobileDate: {
    marginTop: 10,
    display: "flex",
    borderBottom: "1px solid #DADFF6",
    paddingBottom: 15,
    marginBottom: 15,
    color: "red",
    fontWeight: 600,
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20,
      marginRight: 5,
    },
  },
  accordActions: {
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      marginRight: 15,
    },
    "& .MuiButton-root": {
      color: theme.palette.primary.main,
      paddingRight: 0,
      fontWeight: 500,
      fontSize: "1rem",
      "& .MuiSvgIcon-root": {
        marginLeft: 5,
        marginRight: 0,
      },
    },
  },
  mobileAlert: {
    display: "flex",
    alignItems: "center",
  },
  alertAmount: {
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
    width: "1.7em",
    height: "1.7em",
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
  },
  googleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #DADFF6",
    borderRadius: 3,
    padding: "5% 35%",
    marginTop: 30,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 0",
    },
  },
  dateWrapper: {
    display: "flex",
    paddingBottom: 10,
    alignItems: "center",
    justifyContent: "space-between",
    width: "65%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  text: {
    fontWeight: 500,
  },
  googleSignIn: {
    width: "100%",
    textAlign: "center",
    "& h5": {
      fontWeight: 500,
      margin: "20px 0 30px 0",
      padding: "0 40px",
    },
    "& .MuiButton-root": {
      width: 300,
      textTransform: "none",
      fontSize: 16,
      textAlign: "left",
      "& .MuiButton-label": {
        justifyContent: "space-between",
      },
    },
  },
  items: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiButtonBase-root": {
      marginRight: 20,
      fontFamily: "Montserrat",
      fontWeight: 400,
      fontSize: "1rem",
    },
    "& .MuiButton-root:hover": {
      textDecoration: "none",
      backgroundColor: "#fff",
    },
  },
  mobileDateWrapper: {
    margin: "0 28px 0 20px",
    textAlign: "center",
  },
  mobileAppsBtn: {
    height: 40,
    width: 40,
    backgroundColor: "blue",
    borderRadius: 5,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  color: {
    "& .MuiSvgIcon-root": {
      width: 13,
      height: 20,
      marginLeft: 0,
      marginRight: 6,
      [theme.breakpoints.down("sm")]: {
        width: 14,
        height: 11,
        marginLeft: 0,
      },
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    "& .MuiButton-root": {
      marginRight: 20,
      backgroundColor: "#1c71e8",
      color: "#fff",
      [theme.breakpoints.down("lg")]: {
        marginRight: 10,
      },
    },
  },
  hangOutLink: {
    backgroundColor: "#1c71e8",
    color: "#ffff",
    fontSize: 10,
    marginTop: 5,
    height: 25,
  },
}));
