import React, { useEffect } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";
import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
  authSelectors,
} from "../../state";
import { REFERRAL_COLUMNS as columns } from "../../constants/tables";
import { useStyles } from "./ApplicationsPage.styles";

export default function ApplicationsPage() {
  const dispatch = useDispatch();
  const classes = useStyles({});

  const referrals = useSelector(adminSelectors.referrals);
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const userId = useSelector(authSelectors.userId);
  const [pageSize, setPageSize] = React.useState(25);

  useEffect(() => {
    if (!activeRole && !agencyId) {
      console.warn("active role and active agency id is not defined");
      return;
    }
    dispatch(adminActions.getReferrals(activeRole, agencyId, userId));
  }, [dispatch, activeRole, agencyId]);

  return (
    <div className={classes.applicationsPage}>
      <div className={classes.pageHeader}>
        <div>
          <Typography variant="h1">Referrals</Typography>
        </div>
      </div>
      <div className={classes.applicationsTable}>
        <DataGridPro
          autoHeight
          rows={referrals}
          columns={columns}
          rowsPerPageOptions={[10, 25, 50, 100, 250]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
        />
      </div>
    </div>
  );
}
