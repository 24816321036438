import React, { useLayoutEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Box, Grid, Hidden, Typography } from "@mui/material";
import { MenuIcon } from "../../../components/icons";
import ChatWidget from "../../../components/chat";
import ContactCards from "../../../pages/wizard/selectedQuote/ContactCards";
import ModalContact from "../../../components/modals/ModalContact";
import Sidebar from "../../../layouts/sidebar/Sidebar";
import { uiSelectors, useSelector, wizardSelectors } from "../../../state";
import { useMobile } from "../../../themes";
import { useStyles } from "./HitSnag.styles";
import HitSnagCompleteCard from "../../wizard/quotes/cards/HitSnagCompleteCard";
import { formatPhone } from "../../../lib";
import { SnagImg } from "../../../assets/SnagImg";

const HitSnagPage = () => {
  const classes = useStyles();
  const contactType = useSelector(wizardSelectors.contactType);
  const showChat = useSelector(wizardSelectors.showChat);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobile = useMobile();
  const { phone, theme } = useSelector(uiSelectors.agencyData);

  useLayoutEffect(() => {
    scroll.scrollToTop();
  }, []);

  return (
    <Grid
      container
      direction={isMobile ? "column" : "row"}
      wrap={isMobile ? "wrap" : "nowrap"}
    >
      <Grid item>
        <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <Hidden smUp>
          <Box className={classes.mobileHeader} id="mobileHeader">
            <MenuIcon
              onClick={() => setMobileOpen(!mobileOpen)}
              className={classes.drawerButton}
            />
          </Box>
        </Hidden>
      </Grid>
      <Grid item className={classes.errorPageContent}>
        <Hidden smDown>
          <Typography className={classes.helpText}>
            Need help? Call us at{" "}
            <a href={`tel:${phone}`}>{formatPhone(phone)}</a>
          </Typography>
        </Hidden>
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ marginBottom: 16 }}
        >
          <Box mb={2} className={classes.image}>
            <SnagImg fillColor={theme.palette?.primary.main} />
          </Box>
          <Typography
            variant="h3"
            align="center"
            className={classes.errorPageTitle}
          >
            We hit a snag getting you a quote
          </Typography>
          {contactType ? (
            <HitSnagCompleteCard />
          ) : showChat ? (
            <ChatWidget agentChat={false} />
          ) : (
            <>
              <Typography className={classes.title}>
                Seems like your quote needs some special attention.
              </Typography>
              <ContactCards
                title="How would you like an insurance expert to reach out to you?"
                // snag={true}
              />
            </>
          )}
        </Grid>
      </Grid>
      <ModalContact snag={true} />
    </Grid>
  );
};

export default React.memo(HitSnagPage);
