import React, { useState } from "react";
import { Box, Button, Link, TextField, Typography } from "@mui/material";

import { PasswordField } from "../../components/forms/inputs";

import {
  Form,
  Navigation,
  RouterLink,
  useFormik,
  useLocation,
  yup,
} from "../../lib";
import { authActions, useAction } from "../../state";

import { useStyles } from "./Auth.styles";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const LoginAfterResetPage = () => {
  const classes = useStyles();
  const login = useAction(authActions.login);
  const location = useLocation();

  const [error, setError] = useState("");
  const form: any = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    async onSubmit(values) {
      try {
        await login(values);
      } catch (ex) {
        setError("Invalid username or password. Please try again.");
        return;
      }
      Navigation.replace(location.query.after || "/");
    },
  });

  return (
    <Box className={classes.authCard}>
      <Typography variant="h2" component="h1" className={classes.titleText}>
        Your password was reset!
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitleText}>
        {error ? (
          <span className={classes.errorText}>{error}</span>
        ) : (
          "Sign in again and you're good to go"
        )}
      </Typography>
      <Form form={form} className={classes.form}>
        <TextField
          autoFocus
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={form.values.email}
          onChange={form.handleChange}
          error={form.touched.email && Boolean(form.errors.email)}
          helperText={form.touched.email && form.errors.email}
        />

        <PasswordField
          name="password"
          label="Password"
          value={form.values.password}
          handleChange={form.handleChange}
          touched={form.touched.password}
          error={form.errors.password}
        />

        <div className={classes.buttonBox}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={form.isSubmitting}
          >
            Sign In
          </Button>
          <div className={classes.bottomLink}>
            <Link
              component={RouterLink}
              to={`/auth/forgot-password?email=${encodeURIComponent(
                form.values.email,
              )}`}
            >
              Forgot Password?
            </Link>
          </div>
        </div>
      </Form>
    </Box>
  );
};

export default React.memo(LoginAfterResetPage);
