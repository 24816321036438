import { states } from "../../../lib";
import { FieldsConfig } from "../../../state/commercial/types";

export const buildingTypes = [
  "FRAME",
  "JOISTED_MASONRY",
  "NON_COMBUSTIBLE",
  "MASONRY_NON_COMBUSTIBLE",
  "MODIFIED_FIRE_RESISTIVE",
  "FIRE_RESISTIVE",
];

export const securitySystems = [
  "BURGLAR_CENTRAL",
  "BURGLAR_POLICE_FIRE",
  "BURGLAR_LOCAL",
  "BURGLAR_NONE",
];

export const roofTypes = [
  "ASPHALT_SHINGLES",
  "BUILT_UP_GRAVEL",
  "BUILT_UP_WITHOUT_GRAVEL",
  "CLAY_TILES",
  "METAL",
  "FOAM",
  "MODIFIED_BITUMEN",
  "SINGLE_PLY_BALLASTED",
  "SINGLE_PLY_SPDM_RUBBER",
  "SINGLE_PLY_PVC_TPO",
  "WOOD_SHINGLES",
];

export function parseAndSplitCamelCase(input) {
  const afterDot = input.includes(".") ? input.split(".")[1] : input;
  const words = afterDot.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase();
  const result = words.charAt(0).toUpperCase() + words.slice(1);
  return result;
}
export function snakeCaseToSentence(input) {
  const result = input
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/^./, (str) => str.toUpperCase());

  return result.trim();
}
export function toCamelCase(str) {
  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toLowerCase() : match.toUpperCase(),
    )
    .replace(/\s+/g, "")
    .replace(/[_\s]+(.)/g, (match, char) => char.toUpperCase());
}

function getFieldName(name, camelCasedFields) {
  let fieldName;
  camelCasedFields.forEach((f) => {
    if (name.toLowerCase() === f.toLowerCase()) {
      fieldName = f;
    }
  });
  return fieldName || name;
}

export const getFormFields = (fieldsConfig, policyType): FieldsConfig[] => {
  const base = {
    businessInfo: {
      lookupKey: "APPLICATION_BUSINESSINFO_",
      title: "Business Info",
      fields: [],
      fieldNames: [
        "yearsOfManagementExperience",
        "annualRevenue",
        "yearOfFounding",
        "legalEntityType",
        "isNonProfit",
      ],
      exclude: [
        "contactpersoninfoFirstname",
        "contactpersoninfoLastname",
        "contactpersoninfoPhone",
        "contactpersoninfoEmail",
        "mailingaddressAddressline1",
        "mailingaddressCity",
        "mailingaddressState",
        "mailingaddressZip",
        "ownersFirstName",
        "ownersLastName",
        "ownersAnnualPayroll",
        "ownersLocationId",
        "ownersEmployeeJobClassCode",
        "businessname",
        "natureofbusiness",
      ],
    },
    owners: {
      lookupKey: "APPLICATION_BUSINESSINFO_OWNERS_",
      title: "Owners Info",
      fields: [],
      fieldNames: [""],
      exclude: ["firstName", "lastName", "locationId"],
    },
    businessLocations: {
      lookupKey: "LOCATION_",
      title: "Business Locations",
      fields: [],
      fieldNames: [],
      exclude: [
        "addressAddressline1",
        "addressCity",
        "addressState",
        "addressZip",
        "employeeinfosTotalemployeepayroll",
        "employeeinfosNumfulltimeemployees",
        "employeeinfosNumparttimeemployees",
        "employeeinfosJobcodeid",
        "buildingTotalArea",
        "buildingAreaOccupied",
        "buildingAreaOccupiedByOther",
        "buildingConstructionTypeCode",
        "buildingAnnualSales",
        "buildingYearBuilt",
        "buildingTotalStories",
        "buildingIsOwner",
        "buildingBurglarAlarmTypeCode",
        "buildingSprinkleredPercentage",
        "buildingRoofUpdateYear",
        "buildingElectricalWiringUpdateYear",
        "buildingHeatingUpdateYear",
        "buildingPlumbingUpdateYear",
        "buildinginfoBusinesspersonalpropertycoverage",
        "buildinginfoBuildingcoverage",
        "buildingRoofType",
      ],
    },
    coverageInfo: {
      lookupKey: "LOCATION_BUILDINGINFO_",
      title: "Coverage info",
      fields: [],
      fieldNames: ["businessPersonalPropertyCoverage", "buildingCoverage"],
    },
    employeeInfo: {
      lookupKey: "APPLICATION_EMPLOYEEINFO_",
      title: "Employee Info",
      fields: [],
      fieldNames: [
        "numPartTimeEmployees",
        "numFullTimeEmployees",
        "totalEmployeePayroll",
      ],
    },
    employeeInfos: {
      lookupKey: "LOCATION_EMPLOYEEINFOS_",
      title: "Employee Info",
      fields: [],
      fieldNames: [
        "jobCodeId",
        "numPartTimeEmployees",
        "numFullTimeEmployees",
        "totalEmployeePayroll",
      ],
    },
    buildingInfo: {
      lookupKey: "LOCATION_BUILDING_",
      title: "Building Info",
      fields: [],
      fieldNames: [],
    },
    pastPolicyLossInfo: {
      lookupKey: "APPLICATION_PASTPOLICYLOSSINFO_",
      title: "Loss Info",
      fields: [],
      fieldNames: [
        "totalReservedAmount",
        "lossDescription",
        "totalPaidAmount",
        "claimStatus",
        "claimDate",
        "lossDate",
        "lossType",
        "policyType",
      ],
      exclude: ["policyType"],
    },
  };
  const allFields = Object.keys(base).map((k) => {
    const curr = { ...base[k] };
    curr.node_name = k;
    fieldsConfig.forEach((field) => {
      const restOfPath = field.fieldPath.replace(curr.lookupKey, "");
      if (
        field.fieldPath.startsWith(curr.lookupKey) &&
        (field.requirementLevel === "REQUIRED" ||
          (policyType === "BOP" &&
            field.fieldPath === "LOCATION_BUILDING_ROOF_TYPE")) &&
        !curr.exclude?.includes(
          getFieldName(toCamelCase(restOfPath), curr.fieldNames),
        )
      ) {
        const newField = { ...field };
        newField.name = getFieldName(toCamelCase(restOfPath), curr.fieldNames);
        newField.fullName = `${k}.${getFieldName(
          toCamelCase(restOfPath),
          curr.fieldNames,
        )}`;
        curr.fields.push(newField);
      }
    });
    return curr;
  });
  return allFields;
};

const coverageTypes = ["MEDICAL", "INDEMNITY", "MEDICAL_AND_INDEMNITY"];

const incidentTypes = [
  "FIRE",
  "THEFT",
  "WATER_NON_WEATHER_RELATED",
  "HAIL",
  "WINDSTORM",
  "PROPERTY_DAMAGE_OTHER",
  "PERSONAL_INJURY",
  "GENERAL_LIABILITY_PRODUCTS",
  "PROFESSIONAL_LIABILITY",
  "OTHER",
  "VANDALISM",
  "PROPERTY_DAMAGE_COLLAPSE",
  "SLIP_FALL_INSIDE",
  "SLIP_FALL_OUTSIDE",
  "FOOD_SPOILAGE",
  "EMPLOYEE_PRACTICES",
  "INLAND_MARINE",
  "BODILY_INJURY_OTHER",
  "ERRORS_AND_OMISSIONS",
  "LIABILITY_MEDICAL_PAYMENTS",
  "GL_PROPERTY_DAMAGE",
];

const liabilityTypes = [
  "PERSONAL_INJURY",
  "GENERAL_LIABILITY_PRODUCTS",
  "PROFESSIONAL_LIABILITY",
  "OTHER",
  "SLIP_FALL_INSIDE",
  "SLIP_FALL_OUTSIDE",
  "EMPLOYEE_PRACTICES",
  "INLAND_MARINE",
  "ERRORS_AND_OMISSIONS",
  "LIABILITY_MEDICAL_PAYMENTS",
  "BODILY_INJURY_OTHER",
  "GL_PROPERTY_DAMAGE",
];

const cyberIncidentTypes = [
  "TECHNOLOGY_ERRORS_AND_OMISSIONS",
  "CYBER_INCIDENT",
  "BUSINESS_INTERRUPTION",
  "CONTINGENT_BUSINESS_INTERRUPTION",
  "DIGITAL_DATA",
  "NETWORK_EXTORTION",
  "PRIVACY_AND_NETWORK_SECURITY",
  "PAYMENT_CARD",
  "REGULATORY_PROCEEDING",
  "MEDIA_LIABILITY",
  "COMPUTER_FRAUD",
  "FUND_TRANSFER_FRAUD",
  "SOCIAL_ENGINEERING_FRAUD",
];

export function getTypesByPolicy(policyType) {
  switch (policyType) {
    case "WC":
      return coverageTypes;
    case "BOP":
      return incidentTypes;
    case "CGL":
      return liabilityTypes;
    case "Cyber":
      return cyberIncidentTypes;
    default:
      return [];
  }
}

export function getSelectOptionByName(fieldName, policyType = "") {
  switch (fieldName) {
    case "roofType":
      return roofTypes;
    case "burglarAlarmTypeCode":
      return securitySystems;
    case "constructionTypeCode":
      return buildingTypes;
    case "claimStatus":
      return ["OPEN", "CLOSED"];
    case "lossType":
      return getTypesByPolicy(policyType);
    case "state":
      return states.map((s) => s.abbrev);
    default:
      return [];
  }
}

export const removeEmptyValues = (obj: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => Object.keys(value).length > 0),
  );
};

export function getPolicyStatusMessage(status) {
  switch (status) {
    case "DECLINE":
      return "Policy declined";
    case "BIND_ONLINE":
      return "Ready to bind online";
    case "BRIDGE":
      return "Bridge over to carrier";
    case "REFER":
      return "Refer to underwriter";
    case "FAILED":
      return "Policy failed to process";
    default:
      return "Unknown Policy Status";
  }
}

export function getPolicyStatusColor(status) {
  switch (status) {
    case "DECLINE":
      return "#FF0000";
    case "BIND_ONLINE":
      return "#2EB662";
    case "BRIDGE":
      return "#2EB662";
    case "REFER":
      return "#0000FF";
    case "FAILED":
      return "#FF0000";
    default:
      return "#000000";
  }
}

export const formatKey = (key) => {
  return key
    .replace(/^[a-z]+/, "")
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};

export function getPaymentPlanById(data, paymentPlanId) {
  for (const quote of data) {
    for (const plan of quote.paymentPlans) {
      if (plan.cfPaymentPlanId === paymentPlanId) {
        return plan;
      }
    }
  }
  return null;
}

export const getNestedValue = (obj, path) => {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
};

export const extractInitialValues = (sections) => {
  const initialValues = {};
  sections.forEach((section) => {
    section.fields.forEach((field) => {
      const fullNamePath = field.fullName.split(".");
      fullNamePath.reduce((acc, key, index) => {
        if (index === fullNamePath.length - 1) {
          acc[key] = "";
        } else {
          if (!acc[key]) acc[key] = {};
        }
        return acc[key];
      }, initialValues);
    });
  });

  return initialValues;
};