import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HomeCoverageData, HomeState, RentersCoverageData } from "./types";

const initialState: HomeState = {
  activeStep: 0,
  start: {
    address: {
      zipcode: "",
      state: "",
      city: "",
      street_line: "",
    },
    apt: "",
    skipLookup: false,
  },
  details: {
    Address1: "",
    Address2: "",
    City: "",
    Construction: "",
    DwellingAmt: 0,
    FoundationType: "",
    NumberOfFamilies: 0,
    NumberOfFullBaths: 0,
    RoofComposition: "",
    SquareFootage: 0,
    State: "",
    propertyType: "",
    StoryType: "",
    SwimmingPool: "false",
    YearOfConstruction: 0,
    ZipCode: "",
    constructionType: "",
    exteriorWalls: "",
    foundation: "",
    roofType: "",
    roofBuilt: 0,
    numberOfHalfBaths: 0,
    fencedInPool: false,
    poolType: "",
    trampoline: "false",
    usageType: "",
    manualNumberOfFamilies: "1",
    previousAddress: {
      zipcode: "",
      state: "",
      city: "",
      street_line: "",
    },
  },
  discounts: {},
  insured: {
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    maritalStatus: "",
    dob: "",
    addCoapplicant: false,
    coapplicant: {
      arrayIndex: -1,
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      gender: "",
    },
    effectiveDate: "",
  },
  contact: {
    email: "",
    phone: "",
  },
  quotes: [],
  bundleQuotes: [],
  bundleCustomQuotes: [],
  bundleQuotesFailed: false,
  customQuotes: [],
  selectedQuote: null,
  customData: {
    dwelling: 0,
    lossUse: "",
    medPay: "",
    otherStruct: "",
    personalProp: "",
    extendedRep: "",
    personalLiab: "",
    windDed: "",
    generalDed: "",
    extendedRepEnabled: true,
    waterBackup: "",
    waterBackupEnabled: true,
    p_p_replacement_cost: true,
  },
  quotesLoading: false,
  coverageInfo: {
    moreCoverages: [],
    displayCoverages: [],
    coverageData: {
      other: undefined,
      wind_hail: undefined,
      loss_of_use: undefined,
      medical_payments: undefined,
      other_structures: undefined,
      personal_property: undefined,
      personal_liability: undefined,
      extended_replacement: undefined,
      water_backup: undefined,
    },
  },
  rentersCoverageInfo: {
    moreCoverages: [],
    displayCoverages: [],
    coverageData: {
      other: undefined,
      wind_hail: undefined,
      loss_of_use: undefined,
      medical_payments: undefined,
      personal_property: undefined,
      personal_liability: undefined,
      water_backup: undefined,
      extended_replacement: undefined,
      other_structures: undefined,
    },
  },
};

export const home = {
  ...createSlice({
    name: "home",
    initialState,
    reducers: {
      //  GENERAL HOME WIZARD STATE
      refreshHomeState: () => initialState,
      // HOME WIZARD START STATE
      setStart(state, { payload }: PayloadAction<Object>) {
        state.start = { ...state.start, ...payload };
      },
      // HOME WIZARD DETAILS STATE
      setHomeDetails(state, { payload }: PayloadAction<Object>) {
        state.details = { ...state.details, ...payload };

        state.details.NumberOfFamilies =
          state.details.NumberOfFamilies ||
          Number(state.details.manualNumberOfFamilies);
      },
      // HOME WIZARD DISCOUNTS STATE
      setDiscounts(state, { payload }: PayloadAction<Object>) {
        state.discounts = { ...state.discounts, ...payload };
      },
      // HOME WIZARD INSURED STATE
      setInsured(state, { payload }: PayloadAction<Object>) {
        state.insured = { ...state.insured, ...payload };
      },
      // HOME WIZARD CONTACT STATE
      setContact(state, { payload }: PayloadAction<Object>) {
        state.contact = { ...state.contact, ...payload };
      },
      // HOME WIZARD QUOTES STATE
      setHomeQuotes(state, { payload }: PayloadAction<any>) {
        //need to pull out extra values not to mess up if these values passed back\
        // eslint-disable-next-line
        const { customQuotes, meta, req_uid, status, ...quotes } = payload;
        state.customQuotes = customQuotes;
        state.quotes = Object.values(quotes);
      },
      setSelectedHomeQuote(state, { payload }: PayloadAction<any>) {
        state.selectedQuote = payload;
      },
      clearSelectedHomeQuote(state) {
        state.selectedQuote = null;
      },
      setBundleHomeQuotes(state, { payload }: PayloadAction<any>) {
        //need to pull out extra values not to mess up if these values passed back\
        // eslint-disable-next-line
        const { customQuotes, meta, req_uid, status, ...quotes } = payload;
        state.bundleCustomQuotes = customQuotes;
        state.bundleQuotes = Object.values(quotes);
      },
      setBundleHomeQuotesFailed(state, { payload }: PayloadAction<boolean>) {
        state.bundleQuotesFailed = payload;
      },
      clearHomeQuotes(state) {
        state.quotes = [];
        state.customQuotes = [];
        state.bundleQuotes = [];
        state.bundleCustomQuotes = [];
      },
      setPlaceHolderCustomQuote(state, { payload }) {
        !payload //not linked
          ? (state.customQuotes = [{ is_loading: true }])
          : (state.bundleCustomQuotes = [{ is_loading: true }]);
      },
      // HOME WIZARD COVERAGE DATA STATE
      setCoverageInfo(state, { payload }: PayloadAction<HomeCoverageData>) {
        state.coverageInfo = payload;
      },
      setRentersCoverageInfo(
        state,
        { payload }: PayloadAction<RentersCoverageData>,
      ) {
        state.rentersCoverageInfo = payload;
      },
      // HOME WIZARD CUSTOM QUOTE STATE
      setCustomData(state, { payload }: PayloadAction<any>) {
        state.customData = payload;
      },
      // HOME WIZARD HYDRATE QUOTE
      setWizard(state, { payload }: PayloadAction<any>) {
        state.activeStep = payload.activeStep;
        state.start = payload.start;
        state.details = payload.details;
        state.discounts = payload.discounts;
        state.insured = payload.insured;
        state.customData = payload.homeCustomData;
      },
      // BUNDLED QUOTES
      setQuotesLoading(state, { payload }: PayloadAction<boolean>) {
        state.quotesLoading = payload;
      },
    },
  }),
  persist: true,
};
