import { Box, Typography } from "@mui/material";
import React from "react";
import { CoverageCard } from ".";

interface CoverageSectionProps {
  quotes: Record<any, any>;
  heading: string;
}

export const CoverageSection = ({ quotes, heading }: CoverageSectionProps) => (
  <Box
    style={{
      padding: 20,
      margin: 10,
      border: `2px solid #ededed`,
      boxShadow: "10px 5px 5px rgb(217, 217, 217)",
    }}
  >
    <Typography variant="h6" gutterBottom>
      {heading}
    </Typography>
    <Box
      style={{
        display: "flex",
        gap: 10,
        flexWrap: "wrap",
      }}
    >
      {quotes &&
        !!quotes.length &&
        quotes?.map((i, index) => (
          <CoverageCard key={index} quote={quotes[index]} />
        ))}
    </Box>
  </Box>
);
