import React, { useEffect } from "react";
import { Box, IconButton, Link, Typography } from "@mui/material";
// import { initAuthSocket, authSocket } from "../../utils/socket";

import { MainSnackbar } from "../../components/snackbars";
import TopRightMenu from "./TopRightMenu";

import {
  useDispatch,
  // adminActions,
  useSelector,
  authSelectors,
  redirectToLogin,
  uiSelectors,
  // wizardActions,
  uiActions,
} from "../../state";

import { useStyles } from "./AdminLayout.styles";
import reliLogo from "../../assets/img/reliexchange_logo.png";
import AdminSearchBox from "../../components/inputs/AdminSearchBox";
import AgencySelectionBox from "../../components/inputs/AgencySelectionBox";
import { AdminSidebar } from "../../components/admin";
import { useMobile } from "../../themes";
import { LaunchIcon, MenuIcon } from "../../components/icons";
import ModalNewAgency from "../../components/modals/ModalNewAgency";
import ModalCarrier from "../../components/modals/ModalCarrier";
import ModalBind from "../../components/modals/ModalBind";
import ModalAgent from "../../components/modals/ModalAgent";
import ModalCoverage from "../../components/modals/ModalActivity";
import ModalAgentTransfer from "../../components/modals/ModalAgentTransfer";
import ModalTask from "../../components/modals/ModalTask";
import ModalUpload from "../../components/modals/ModalUpload";
import ModalAssignReferrer from "../../components/modals/ModalAssignReferrer";

export const AdminLayout = ({ children }) => {
  const classes = useStyles();

  // const token = useSelector(authSelectors.token);
  const activeAgencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const userAgencies = useSelector(authSelectors.user_agency);
  const affiliateToken = useSelector(authSelectors.affiliateToken);
  const isUserLoggedIn = useSelector(authSelectors.isUserLoggedIn);

  const { name } = useSelector(uiSelectors.agencyData);
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const isTablet = useMobile("md");
  const notProductOwner = activeRole !== "referral_product_owner";
  const isAffiliateAgent = activeRole === "affiliate_agent";
  const activeAgency = userAgencies.find(
    (ua) => ua.agency_id === activeAgencyId,
  );
  const domain = activeAgency?.agency.domain;
  const token = activeAgency?.referral_token;

  useEffect(() => {
    document.title = `${name} | The easy way to buy auto and home insurance online`;
  }, [name]);
  const urlParam = affiliateToken
    ? `affiliate_token=${affiliateToken}`
    : `referral_token=${token}`;
  const SiteLink = () => {
    return (
      <Link
        href={`https://${domain}?${urlParam}`}
        target="_blank"
        underline="hover"
      >
        <Box sx={{ display: "flex", gap: "5px" }}>
          <Typography>View site</Typography>
          <LaunchIcon />
        </Box>
      </Link>
    );
  };
  //currently not used
  // useEffect(() => {
  //   if (token) {
  //     initAuthSocket(token);
  //   }
  //   if (token && authSocket) {
  //     if (!activeRole || !agencyId) {
  //       if (activeRole !== "referral_product_owner") {
  //         console.warn("active role or active agency id is not defined");
  //       }
  //       return;
  //     }
  //     authSocket.on("admin_message", (e) => {
  //       switch (e.action) {
  //         case "getSelectedQuote":
  //           dispatch(wizardActions.getQuoteData(e.req_txn_id));
  //           break;
  //         case "showPmtPopup":
  //           dispatch(wizardActions.showPmtPopup(e.showPaymentPopup));
  //           break;
  //         case "updatePmt":
  //           //This is not used, payment is pulled from BE instead
  //           dispatch(adminActions.getPmtInfo(e.req_uid, activeRole, agencyId));
  //           break;
  //         default:
  //           console.error("not handled");
  //       }
  //     });
  //   } else {
  //     console.warn("not listening yet");
  //   }
  // }, [dispatch, token, authSocket, activeRole, agencyId]);

  useEffect(() => {
    if (!isUserLoggedIn) {
      redirectToLogin();
    }
  }, [isUserLoggedIn]);

  const handleToggleMenu = () => {
    dispatch(uiActions.toggleAdminMenuOpen());
  };

  return (
    <>
      <div className={classes.page}>
        <header className={classes.header}>
          {(isMobile || isTablet) && notProductOwner && (
            <IconButton onClick={handleToggleMenu} size="large">
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.topLeft}>
            <img alt="Company logo" src={reliLogo} className={classes.logo} />
          </div>
          <div className={classes.topCenter}>
            {!isMobile && !isTablet && (
              <>
                <div className={classes.agencyWrapper}>
                  {userAgencies.length > 1 && <AgencySelectionBox />}
                </div>
                {notProductOwner && (
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                  >
                    <SiteLink />
                    {!isAffiliateAgent ? <AdminSearchBox /> : null}
                  </Box>
                )}
              </>
            )}
          </div>
          <TopRightMenu />
        </header>
        {(isMobile || isTablet) && (
          <Box className={classes.mobileSubheader}>
            {userAgencies.length > 1 && <AgencySelectionBox />}
            {notProductOwner && <AdminSearchBox />}
          </Box>
        )}
        {notProductOwner && <AdminSidebar />}
        <div className={classes.content}>{children}</div>
        <MainSnackbar />
        <ModalNewAgency />
        <ModalCarrier />
        <ModalAgent />
        <ModalBind />
        <ModalCoverage />
        <ModalAgentTransfer />
        <ModalTask />
        <ModalUpload />
        <ModalAssignReferrer />
      </div>
    </>
  );
};
