import React from "react";
import { Box, Typography } from "@mui/material";
import {
  AttachMoneyIcon,
  LocalOfferIcon,
  InsertDriveFileIcon,
  BarChartIcon,
} from "../../../components";
import { TimelineStats } from "./TimelineStats";
import { useStyles } from "./AgentStatsItems.styles";

export const AgentStatsItems = ({
  totalSales,
  bound,
  totalApps,
  conversionRate,
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.totalAmounts}>
        <Box className={classes.statItem}>
          {" "}
          <div className={classes.icon}>
            <AttachMoneyIcon />
          </div>
          <div className={classes.statWrapper}>
            <div className={classes.statNum}>
              {totalSales.totalSales ? `$${totalSales.totalSales}` : 0}
            </div>
            <div className={classes.statText}>Total sales</div>
          </div>
        </Box>
        <Box className={classes.statItem}>
          <div className={classes.icon}>
            <LocalOfferIcon />
          </div>
          <div className={classes.statWrapper}>
            <div className={classes.statNum}>{bound || 0}</div>
            <div className={classes.statText}>Policies sold</div>
          </div>
        </Box>
        <Box className={classes.statItem}>
          <div className={classes.icon}>
            <InsertDriveFileIcon />
          </div>
          <div className={classes.statWrapper}>
            <div className={classes.statNum}>{totalApps}</div>
            <div className={classes.statText}>Submitted</div>
          </div>
        </Box>
        <Box className={classes.statItem}>
          <div className={classes.icon}>
            <BarChartIcon />
          </div>
          <div className={classes.statWrapper}>
            <div className={classes.statNum}>
              {Math.round(+conversionRate.conversion_rate * 10) / 10 + "%" ||
                "0%"}
            </div>
            <div className={classes.statText}>Conversion rate</div>
          </div>
        </Box>
      </Box>
      <Box className={classes.mobileColumns}>
        <Typography variant="h6" className={classes.mobileTitle}>
          Applications status timeline
        </Typography>
        <TimelineStats />
      </Box>
    </Box>
  );
};
