import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    width: "45%",
    marginLeft: 38,
    "& p:nth-child(1)": {
      marginTop: 25,
    },
    "& .MuiGrid-container": {
      gap: 26,
      columnGap: "4%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
    },
    "& .MuiOutlinedInput-root": {
      height: 52,
    },
  },
  backNavigationIcon: {
    height: 30,
    marginRight: 20,
    color: theme.palette.primary.main,
    verticalAlign: "middle",
    "&:hover": {
      cursor: "pointer",
      opacity: 0.75,
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: 12,
    },
  },
  backNavigationWrapper: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "25px 0 0 20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    "& .MuiTypography-root": {
      fontSize: 24,
      fontWeight: 600,
    },
  },

  priceInput: {
    width: "100%",
    border: "1px solid #8D98C2",
    borderRadius: 3,
    height: 52,
    fontSize: 16,
    fontFamily: "Montserrat",
    paddingLeft: 10,
    "&:focus-visible": {
      outline: "none",
    },
  },
  searchCard: {
    padding: 32,
    minHeight: 220,
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
    margin: "33px 0 30px 0",
  },
  formCard: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formControl: {
    width: "40%",
    "& input::placeholder": {
      color: "#AFB6D4",
    },
    "& .MuiSelect-root": {
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  link: {
    width: "84%",
    margin: "18px 0 26px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  message: {
    width: "84%",
    marginBottom: "26px",
    height: "auto",
    "& .MuiOutlinedInput-root ": {
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttons: {
    width: "84%",
    marginBottom: 26,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  autoComplete: {
    width: "84%",
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      height: 55,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textFieldLabel: {
    lineHeight: "25px",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "normal",
    },
  },
  switcher: {
    width: "50%",
    "&.MuiToggleButton-root": {
      borderRadius: 0,
      borderColor: "#ccd2e9",
      textTransform: "initial",
      color: "#AFB6D4",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  formAction: {
    paddingRight: "2em",
  },
  carriers: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
