import { __TESTING__ } from "../config";

export const getRecaptchaToken = (action = "submit") => {
  return new Promise((res, rej) => {
    if (__TESTING__) {
      return res("");
    }
    window.grecaptcha.enterprise.ready(function () {
      window.grecaptcha.enterprise
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
          action,
        })
        .then(res)
        .catch(rej);
    });
  });
};
