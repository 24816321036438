import React, { useEffect, useState } from "react";
import { Card, Typography, Box, Button, TextField } from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, yup } from "../../../../lib";

import { useStyles } from "../AgencySettingsPage.styles";
import { EditIcon } from "../../../../components";
import { Agency } from "../../../../state/admin/state";
import { useParams } from "react-router";
import { ensureHttps } from "../../../../utils";

const validationSchema = yup.object({});

const SocialProfilesCard = () => {
  const [inEditMode, setInEditMode] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const activeAgencyId = params.id
    ? Number(params.id)
    : useSelector(authSelectors.activeAgencyId);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const activeAgencyData: Agency = useSelector(adminSelectors.activeAgencyData);
  const social_links = activeAgencyData.social_links;

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      social_links: {
        facebook: social_links?.facebook || "",
        instagram: social_links?.instagram || "",
        twitter: social_links?.twitter || "",
        linkedin: social_links?.linkedin || "",
      },
    },
    validationSchema,
    async onSubmit(values) {
      Object.keys(values.social_links).forEach((key) => {
        values.social_links[key] = ensureHttps(values.social_links[key]);
      });
      const agencyData = {
        ...activeAgencyData,
        social_links: values.social_links,
      };

      dispatch(
        adminActions.updateAgency(activeAgencyId, agencyData, isSuperAdmin),
      );
      setInEditMode(false);
      form.resetForm();
    },
  });

  const handleCancel = () => {
    setInEditMode(false);
    form.resetForm();
  };

  useEffect(() => {
    dispatch(adminActions.getAgency(activeAgencyId));
  }, []);

  const showEditButton = !inEditMode;

  if (!activeAgencyId) return null;

  const SectionTitle = ({ title }) => {
    return (
      <Box className={classes.sectionTitleWrapper}>
        <Typography className={classes.sectionTitle}>{title}</Typography>
        {showEditButton && (
          <Button
            className={classes.editBtnWrapper}
            onClick={() => setInEditMode(!inEditMode)}
          >
            <EditIcon style={{ color: "white" }} />
          </Button>
        )}
      </Box>
    );
  };

  const AgencyField = ({ fieldName, fieldValue }) => {
    return (
      <Box className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle}>{fieldName}</Typography>
        <Typography className={classes.field}>{fieldValue}</Typography>
      </Box>
    );
  };

  return (
    <>
      {inEditMode ? (
        <Card variant="outlined" className={classes.section}>
          <form onSubmit={form.handleSubmit}>
            <SectionTitle title={"Social profiles"} />
            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"social_links.facebook"}
                name={"social_links.facebook"}
                label={"Facebook page URL"}
                variant="outlined"
                value={form.values.social_links.facebook}
                onChange={form.handleChange}
                error={Boolean(form.errors.social_links?.facebook)}
                helperText={form.errors.social_links?.facebook}
              />
            </Box>

            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"social_links.instagram"}
                name={"social_links.instagram"}
                label={"Instagram URL"}
                variant="outlined"
                value={form.values.social_links.instagram}
                onChange={form.handleChange}
                error={Boolean(form.errors.social_links?.instagram)}
                helperText={form.errors.social_links?.instagram}
              />
            </Box>

            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"social_links.twitter"}
                name={"social_links.twitter"}
                label={"Twitter URL"}
                variant="outlined"
                value={form.values.social_links.twitter}
                onChange={form.handleChange}
                error={Boolean(form.errors.social_links?.twitter)}
                helperText={form.errors.social_links?.twitter}
              />
            </Box>

            <Box className={classes.textFieldWrapper}>
              <TextField
                id={"social_links.linkedin"}
                name={"social_links.linkedin"}
                label={"LinkedIn URL"}
                variant="outlined"
                value={form.values.social_links.linkedin}
                onChange={form.handleChange}
                error={Boolean(form.errors.social_links?.linkedin)}
                helperText={form.errors.social_links?.linkedin}
              />
            </Box>

            <Box className={classes.submit}>
              <Button
                variant="contained"
                onClick={() => handleCancel()}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitBtn}
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      ) : (
        <Card
          variant="outlined"
          className={classes.section}
          style={{ minHeight: 456 }}
        >
          <SectionTitle title={"Social profiles"} />
          <AgencyField
            fieldName={"Facebook page URL"}
            fieldValue={activeAgencyData.social_links?.facebook || ""}
          />
          <AgencyField
            fieldName={"Instagram URL"}
            fieldValue={activeAgencyData.social_links?.instagram || ""}
          />
          <AgencyField
            fieldName={"Twitter URL"}
            fieldValue={activeAgencyData.social_links?.twitter || ""}
          />
          <AgencyField
            fieldName={"LinkedIn URL"}
            fieldValue={activeAgencyData.social_links?.linkedin || ""}
          />
        </Card>
      )}
    </>
  );
};

export default SocialProfilesCard;
