import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  Link,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {
  adminSelectors,
  authSelectors,
  commercialActions,
  commercialSelectors,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { CFResponse } from "./types";
import { ExpandMoreIcon, GetAppIcon, LaunchIcon } from "../../../components";
import { currencyFormat } from "../../../utils";
import { RelianceLoader } from "./CommercialContinueForm";
import {
  getPaymentPlanById,
  getPolicyStatusColor,
  getPolicyStatusMessage,
} from "./fieldsUtils";
import {
  DisplayCoverageData,
  FailuresInfo,
  PaymentPlanData,
  PurchasedTag,
} from "./CommercialComponents";

export const CommercialCoverageSection = () => {
  const dispatch = useDispatch();
  const activeQuote = useSelector(adminSelectors.activeQuote);
  const paymentPlans = useSelector(commercialSelectors.paymentPlans);
  const isLoading = useSelector(uiSelectors.loading);
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const [modalOpen, setModalOpen] = useState("");

  const {
    commercial_type: policyType,
    bound,
    req_uid: reqUuid,
    selected_quote,
    payment_id: selectedPaymentId,
  } = activeQuote.applicant;
  const quotes = activeQuote.quotes[0];

  const coverages = quotes[0].response_payload.liabilityLimits;
  const selectedPaymentPlan = getPaymentPlanById(
    paymentPlans,
    selectedPaymentId,
  );

  const handleOpenModal = (quoteId) => setModalOpen(quoteId);
  const handleCloseModal = () => setModalOpen("");

  useEffect(() => {
    dispatch(commercialActions.getPaymentPlans(reqUuid));
  }, [activeQuote.applicant.req_uid]);

  const handleBind = (planData) => {
    dispatch(
      commercialActions.selectPaymentAndBind(
        reqUuid,
        planData,
        activeRole,
        agencyId,
      ),
    );
  };
  const getPolicyDocuments = () => {
    dispatch(commercialActions.getPolicyDocuments(reqUuid));
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          marginBottom: "10px",
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: 700 }}>{policyType} Policy</Typography>
          <DisplayCoverageData coverages={coverages} />
        </Box>
        <Box>
          {selectedPaymentPlan && (
            <Box>
              <Typography sx={{ fontWeight: 700 }}>
                Selected Payment Plan
              </Typography>
              <PaymentPlanData paymentPlan={selectedPaymentPlan} />
            </Box>
          )}
        </Box>
        <Box>
          {!!bound && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Button
                endIcon={<GetAppIcon />}
                onClick={() => getPolicyDocuments()}
              >
                Download documents
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      {!!isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <RelianceLoader />
        </Box>
      )}

      {!!quotes.length &&
        quotes.map((q, index) => {
          const responsePayload: CFResponse = q.response_payload;
          const { failures, quoteType, quoteId } = responsePayload;

          const quotePaymentPlan = paymentPlans?.find(
            (pp) => pp.cfQuoteId === quoteId,
          );

          const quoteIsBound = bound && selected_quote === quoteId;
          const logo = `https://cf-static-assets-snp.api.coverforce.com/carriers/images/${q.manual_carrier_name.toLowerCase()}_logo.png`;

          return (
            <Box key={quoteId}>
              {modalOpen === quoteId && (
                <UnderwriterNoteModal
                  open={modalOpen}
                  onClose={handleCloseModal}
                  reqUuid={reqUuid}
                  cfQuoteId={quoteId}
                />
              )}
              {quoteIsBound && <PurchasedTag />}
              <Card
                key={index}
                sx={{
                  padding: "20px",
                  marginBottom: "20px",
                  borderRadius: !quoteIsBound ? "4px" : "0 4px 4px 4px",
                  border: quoteIsBound ? "2px solid #2EB662" : "auto",
                }}
                variant="outlined"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        height: "20px",
                        maxWidth: "110px",
                        marginBottom: "10px",
                      }}
                    >
                      <img src={logo} alt="carrier_logo" height={"25px"} />
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.7,
                        }}
                      >
                        <Typography>
                          {getPolicyStatusMessage(quoteType)}
                        </Typography>
                        <Typography
                          bgcolor={getPolicyStatusColor(quoteType)}
                          width="12px"
                          height="12px"
                          borderRadius={8}
                        />
                      </Box>
                      {quoteType === "REFER" && (
                        <Button
                          sx={{
                            "&:hover": {
                              backgroundColor: "#FFF",
                            },
                          }}
                          onClick={() => handleOpenModal(quoteId)}
                        >
                          Refer to underwriter
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    {q.total_premium > 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <Typography sx={{ fontWeight: 500 }}>
                            Premium:{" "}
                            {currencyFormat(
                              Math.round(q.total_premium)?.toString(),
                            )}
                          </Typography>
                          {!!failures.length && (
                            <FailuresInfo failures={failures} />
                          )}
                        </Box>
                        {q.quote_url && (
                          <Link target="_blank" href={q.quote_url}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Typography>View in carrier</Typography>
                              <LaunchIcon />
                            </Box>
                          </Link>
                        )}
                      </Box>
                    ) : (
                      <Box>
                        {" "}
                        <Grid
                          container
                          style={{ width: "auto" }}
                          alignItems="center"
                          gap={1}
                        >
                          <Typography
                            sx={{
                              fontWeight: 500,
                              color: "#FF0000",
                              fontSize: 14,
                              textTransform: "uppercase",
                            }}
                          >
                            Quote Failed
                          </Typography>
                          <FailuresInfo failures={failures} />
                        </Grid>
                      </Box>
                    )}
                  </Box>
                </Box>
                {quotePaymentPlan && (
                  <Accordion
                    sx={{
                      border: "none",
                      boxShadow: "none",
                      marginTop: "4px",
                      "&.Mui-disabled": {
                        backgroundColor: "white",
                      },
                    }}
                    disableGutters
                    disabled={!!bound}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      sx={{ border: "none" }}
                    >
                      <Typography color={"primary"}>
                        View payment plans
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {quotePaymentPlan.paymentPlans.map((pp) => (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                            padding: "4px",
                          }}
                          key={pp.cfPaymentPlanId}
                        >
                          <PaymentPlanData paymentPlan={pp} />
                          <Box>
                            <Button
                              disabled={!!bound}
                              onClick={() =>
                                handleBind({
                                  cfQuoteId: quoteId,
                                  cfPaymentPlanId: pp.cfPaymentPlanId,
                                })
                              }
                            >
                              Select and Bind
                            </Button>
                          </Box>
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                )}
              </Card>
            </Box>
          );
        })}
    </Box>
  );
};

function UnderwriterNoteModal({ open, onClose, reqUuid, cfQuoteId }) {
  const dispatch = useDispatch();
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const [note, setNote] = useState("");

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(
      commercialActions.submitToUnderwriter(
        reqUuid,
        {
          cfQuoteId,
          noteToUnderwriter: note,
        },
        activeRole,
        agencyId,
      ),
    );
    setNote("");
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2" gutterBottom>
          Note to Underwriter
        </Typography>
        <TextField
          label="Enter your note to underwriter"
          variant="outlined"
          fullWidth
          multiline
          rows={5}
          value={note}
          onChange={handleNoteChange}
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
}
