import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AccessAlarmsIcon, CloseIcon } from "../icons";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  adminSelectors,
  authSelectors,
  uiActions,
} from "../../state";
import { useStyles } from "./ModalAgent.styles";
import { formatCurrency, getInsuranceLevelTitle } from "../../utils";
import { Api } from "../../lib";
import { Status } from "../../state/admin/types";
import { QUOTE_STATUSES } from "../../state/auto/types";
import { Alert } from "@mui/material";
import { add } from "date-fns";

export default function ModalAgent() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const activityMode = useSelector(adminSelectors.activityModalOpen);
  const isOpen = useSelector(adminSelectors.emailModalOpen) || !!activityMode;
  const { premium, logo, emailAddress, insurance_level_id, turbo_quote_id } =
    useSelector(adminSelectors.emailModalData);
  const { profile_image_path, use_profile_image } = useSelector(
    authSelectors.user,
  );
  const userId = useSelector(authSelectors.userId);
  const role = useSelector(authSelectors.activeRole);
  const activeAgencyId = useSelector(authSelectors.activeAgencyId);
  const { status } = useSelector(adminSelectors.referralData).referralData;
  const quoteStatus = useSelector(adminSelectors.quoteStatus);
  const activeQuote = useSelector(adminSelectors.activeQuote).quoteType;
  const {
    applicant: { req_uid },
  } = useSelector(adminSelectors.activeQuote);

  const referral_id = window.location.pathname.split("/")[3];

  // below is only being used for status modal - not for email modal
  const isQuoteConfirm = window.location.pathname.includes("quote-management");

  let agent_avatar;
  if (use_profile_image && profile_image_path) {
    agent_avatar = profile_image_path;
  }

  const statuses: Status[] = [
    "New referral",
    "Contacted",
    "Presented",
    "Won",
    "Lost",
  ];
  const [currentStatus, setCurrentStatus] = useState<string>(status);
  const [currentSubStatus, setCurrentSubStatus] = useState<string>();
  const [userInput, setUserInput] = useState(emailAddress);
  const [commentsInput, setCommentsInput] = useState("");
  const [reminder, setReminder] = useState<boolean>();
  const [interval, setInterval] = useState<any>("months");
  const [number, setNumber] = useState(6);

  useEffect(() => {
    if (quoteStatus) {
      if (quoteStatus.sub_status === "Won") {
        setCurrentStatus("Closed");
        setCurrentSubStatus("Won");
        return;
      }
      setCurrentStatus(quoteStatus.status);
      setCurrentSubStatus(quoteStatus.sub_status);
    }
  }, [quoteStatus, currentStatus]);

  useEffect(() => {
    if (
      currentSubStatus === "No response" ||
      currentSubStatus === "Price too high"
    ) {
      setReminder(true);
      setInterval("months");
      setNumber(6);
    } else {
      setReminder(false);
    }
  }, [currentStatus, currentSubStatus]);

  const handleCloseModal = () => {
    setUserInput("");
    setCommentsInput("");
    dispatch(adminActions.setEmailModalOpen(false));
    dispatch(adminActions.setActivityModalOpen(false));
  };

  const setStatus = (val) => {
    setCurrentStatus(val);
  };

  const handleSubmit = () => {
    (async () => {
      const contactData = {
        method: "",
        contact_info: userInput,
        agent_avatar: agent_avatar || "", //to be backwards compatible
        agentSent: true,
      };
      setUserInput("");

      const { status } = await Api.put(
        `/contact-me/${turbo_quote_id}`,
        contactData,
      );
      if (status === 204) {
        dispatch(uiActions.showSuccess("Email Sent."));
      }
      handleCloseModal();
    })();
  };

  const handleSubmitActivity = () => {
    let date_due;
    if (reminder) {
      date_due = add(new Date(), { [interval]: number });
    }

    const values = {
      message: commentsInput,
      status: currentStatus,
      sub_status: currentSubStatus,
      user_id: userId,
      req_uid,
      date_due,
    };

    if (isQuoteConfirm) {
      if (!role || !activeAgencyId) return;
      dispatch(
        adminActions.updateQuoteStatus(values, req_uid, role, activeAgencyId),
      );
    } else {
      dispatch(
        adminActions.submitReferralActivity(
          +referral_id,
          values,
          role,
          activeAgencyId,
          userId,
        ),
      );
    }
    dispatch(adminActions.setActivityModalOpen(false));
    setCommentsInput("");
  };

  useEffect(() => {
    setUserInput(emailAddress);
  }, [isOpen]);
  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);

  return (
    <Fade in={!!isOpen}>
      <div>
        <Modal open={!!isOpen} onClose={() => handleCloseModal()}>
          <Box className={classes.box}>
            {!activityMode && (
              <IconButton
                className={classes.closeBtn}
                onClick={handleCloseModal}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            )}
            {!activityMode ? (
              <div className={classes.titleWrapper}>
                <Typography className={classes.title}>
                  Send this quote via email
                </Typography>
                <Box className={classes.quoteData}>
                  <img
                    className={classes.carrierImg}
                    src={logo}
                    alt="carrier_logo"
                  />
                  <Box className={classes.premium}>
                    {formatCurrency(Math.round(premium))}
                    <span className={classes.level}>{`${getInsuranceLevelTitle(
                      insurance_level_id,
                    )}`}</span>
                  </Box>
                </Box>
                <Box className={classes.inputWrapper}>
                  <TextField
                    id="contact_info"
                    label="Email address"
                    value={userInput}
                    onChange={(e) => {
                      setUserInput(e.target.value);
                    }}
                    className={classes.input}
                  />
                  <Button
                    className={classes.selectBtn}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={!userInput}
                    onClick={() => handleSubmit()}
                  >
                    Send
                  </Button>
                </Box>
              </div>
            ) : (
              <>
                <Box className={classes.commentsWrapper}>
                  <Box className={classes.commentsTitle}>
                    {!isQuoteConfirm ? "Add activity" : "Confirm status change"}
                  </Box>
                  <div>
                    <TextField
                      select
                      variant="outlined"
                      className={classes.statusDropdown}
                      label="Status"
                      id="status"
                      name="status"
                      value={currentStatus}
                      onChange={(event) => setStatus(event.target.value)}
                      size="small"
                    >
                      {!isQuoteConfirm &&
                        statuses.map((status, i) => (
                          <MenuItem key={i} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      {!!isQuoteConfirm &&
                        ["New", "In progress", "Pending", "Closed"].map(
                          (status, i) => {
                            return (
                              <MenuItem key={i} value={status}>
                                {status}
                              </MenuItem>
                            );
                          },
                        )}
                    </TextField>
                    {!!isQuoteConfirm && (
                      <TextField
                        select
                        variant="outlined"
                        fullWidth
                        className={classes.subStatusDropdown}
                        label="Reason"
                        id="status"
                        name="sub_status"
                        value={currentSubStatus}
                        onChange={(event) =>
                          setCurrentSubStatus(event.target.value)
                        }
                        size="small"
                      >
                        {QUOTE_STATUSES.filter(
                          (s) => s.status === currentStatus,
                        ).map((status, i) => (
                          <MenuItem key={i} value={status.sub_status}>
                            {status.sub_status}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </div>
                  {currentSubStatus === "Won" && (
                    <Alert severity="warning" className={classes.alert}>
                      {activeQuote === "Bundle" || activeQuote === "AutoRenters"
                        ? "Select both purchased auto + home quotes to mark this application as won."
                        : "The purchased quote must be mark as purchased before updating the status to won."}
                    </Alert>
                  )}

                  <Box className={classes.commentsInput}>
                    <TextField
                      fullWidth
                      label="Comments"
                      id="fullWidth"
                      value={commentsInput}
                      onChange={(e) => {
                        setCommentsInput(e.target.value);
                      }}
                      multiline
                      minRows={5}
                      maxRows={5}
                    />
                  </Box>
                  {(currentSubStatus === "No response" ||
                    currentSubStatus === "Price too high") && (
                    <Box className={classes.notification}>
                      <Box className={classes.notificationText}>
                        <span>
                          <AccessAlarmsIcon />
                        </span>
                        <Typography>
                          Set a notification reminder to follow up on this
                          application
                        </Typography>
                      </Box>

                      {reminder && (
                        <Box className={classes.notificationInputs}>
                          <Box>
                            <TextField
                              className={classes.notificationNum}
                              id="standard-number"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              // defaultValue={6}
                              value={number}
                              onChange={(e) => setNumber(+e.target.value)}
                            />
                          </Box>
                          <Box>
                            <FormControl
                              variant="outlined"
                              className={classes.notificationInterval}
                            >
                              <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={interval}
                                onChange={(e) => setInterval(e.target.value)}
                                defaultValue={"months"}
                              >
                                <MenuItem value={"months"}>Months</MenuItem>
                                <MenuItem value={"weeks"}>Weeks</MenuItem>
                                <MenuItem value={"days"}>Days</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                      )}
                      <Box className={classes.checkBox}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!reminder}
                              onChange={() => setReminder(!reminder)}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label=" Don’t remind me about this application"
                        />
                      </Box>
                    </Box>
                  )}
                  <Box className={classes.commentsButtons}>
                    <Button
                      className={classes.cancelBtn}
                      size="small"
                      onClick={() => {
                        dispatch(adminActions.setActivityModalOpen(false));
                        setUserInput("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.selectBtn}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!commentsInput || currentSubStatus === "Won"}
                      onClick={() => handleSubmitActivity()}
                    >
                      {isQuoteConfirm ? "Update" : "Save"}
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </Fade>
  );
}
