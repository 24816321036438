import React, { useState } from "react";
import {
  Dialog,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { useStyles } from "./ModalExitIntent.styles";
import { CloseIcon } from "..";
import { useSelector } from "react-redux";
import { uiActions, uiSelectors, useAction, autoActions } from "../../state";
import ReactGA from "react-ga";
import TopImage from "../../assets/img/holding_phone.png";

export function ModalExitIntent() {
  const setShowExitIntent = useAction(uiActions.setShowExitIntent);
  const submitForm = useAction(autoActions.submitForm);
  const classes = useStyles();
  const [mode, setMode] = useState("options");
  const handleClose = () => setShowExitIntent(false);
  const open = useSelector(uiSelectors.showExitIntent);
  const handleChange = (e, v) => {
    submitForm({
      form_type: "exitIntent",
      form_values: {
        reason: v,
      },
    });
    setMode("thanks");
    ReactGA.event({
      category: "User",
      action: "Exit Intent Option clicked",
      value: v,
    });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      className={classes.root}
    >
      <IconButton className={classes.closeBtn} onClick={handleClose} size="large">
        <CloseIcon />
      </IconButton>
      {mode === "options" ? (
        <Box className={classes.wrapper}>
          <Typography className={classes.title}>
            <span className={classes.emoji}>😔</span>Wait, why are we losing
            you?
          </Typography>
          <FormControl className={classes.formControl}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              className={classes.radioGroup}
              onChange={handleChange}
            >
              <FormControlLabel
                className={classes.option}
                value="too_expensive"
                control={<Radio />}
                label={
                  <Typography>
                    <span className={classes.emoji}>💰</span>
                    <span>Not enough savings</span>
                  </Typography>
                }
              />
              <FormControlLabel
                className={classes.option}
                value="looking_around"
                control={<Radio />}
                label={
                  <Typography>
                    <span className={classes.emoji}>🤓</span>
                    <span>Just looking around</span>
                  </Typography>
                }
              />
              <FormControlLabel
                className={classes.option}
                value="looking_home_auto_combo"
                control={<Radio />}
                label={
                  <Typography>
                    <span className={classes.emoji}>🏠 + 🚘</span>
                    <span>Looking for Home/Auto Combo</span>
                  </Typography>
                }
              />
              <FormControlLabel
                className={classes.option}
                value="too_complicated"
                control={<Radio />}
                label={
                  <Typography>
                    <span className={classes.emoji}>🤯</span>
                    <span>Too Complicated</span>
                  </Typography>
                }
              />
              <FormControlLabel
                className={classes.option}
                value="more_carriers"
                control={<Radio />}
                label={
                  <Typography>
                    <span className={classes.emoji}>📇</span>
                    <span>I want to see even more carriers</span>
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
      ) : (
        <Box className={classes.thanksWrapper}>
          <img src={TopImage} alt="thanks" />
          <Typography className={classes.title}>
            Thanks for your feedback!
          </Typography>
        </Box>
      )}
    </Dialog>
  );
}
