import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Link,
  List,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ExpandMoreIcon } from "../../../components/icons";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { adminSelectors } from "../../../state";

const useStyles = makeStyles((theme) => ({
  root: {},
  select: {
    fontSize: 14,
  },
  fieldName: {
    marginRight: 7,
    fontSize: 12,
    fontWeight: 700,
  },
  fieldValue: {
    fontSize: 12,
    fontWeight: 500,
  },
  card: {
    width: "24.3%",
    padding: 10,
    [theme.breakpoints.down('md')]: {
      width: "100%",
    },
  },
  selectedQuote: {
    outline: "5px solid green",
  },
}));

export const CoverageCard = ({ quote }) => {
  const classes = useStyles();
  const [indQuoteIdx, setIndQuoteIdx] = useState(0);
  const activeQuote = useSelector(adminSelectors.activeQuote);
  const selectedQuoteAuto = activeQuote?.applicant.selected_quote_auto;
  const selectedQuoteHome = activeQuote?.applicant.selected_quote_home;
  const quotes = quote.response_payload || [];

  const fields = [
    "Term",
    "PhoneCode",
    "TotalPremium",
    "PayPlanDownPayment",
    "PayPlanPercentDown",
    "PayPlanNumOfPayments",
    "PayPlanPaymentAmount",
  ];
  return (
    <Card
      className={clsx(
        classes.card,
        quote.req_txn_id === selectedQuoteAuto ||
          quote.req_txn_id === selectedQuoteHome
          ? classes.selectedQuote
          : "",
      )}
    >
      <Typography style={{ fontSize: 14, fontWeight: 600, marginBottom: 8 }}>
        {quote.carrier?.name}
      </Typography>
      <Select
        className={classes.select}
        value={indQuoteIdx}
        label="Age"
        fullWidth
      >
        {quote.response_payload &&
          Array.isArray(quote.response_payload) &&
          quote.response_payload.map((indQuote, idx) => (
            <MenuItem onClick={() => setIndQuoteIdx(idx)} key={idx} value={idx}>
              {indQuote.PayPlanDescription}
            </MenuItem>
          ))}
      </Select>
      <Typography>
        <span className={classes.fieldName}>Carrier URL:</span>
        {quote.response_payload &&
          Array.isArray(quote.response_payload) &&
          !!quotes[indQuoteIdx]["RTRThirdPartyQuoteURL"] && (
            <Link
              className={classes.fieldValue}
              href={quotes[indQuoteIdx]["RTRThirdPartyQuoteURL"]}
              target="_blank"
            >
              Click Here
            </Link>
          )}
      </Typography>
      <Typography>
        <span className={classes.fieldName}>Turborater Url:</span>
        {!!quote.quote_url && (
          <Link
            className={classes.fieldValue}
            href={quote.quote_url}
            target="_blank"
          >
            Click Here
          </Link>
        )}
      </Typography>
      <Typography>
        <span className={classes.fieldName}>Status</span>
        {!!quote.request_status && <span>{quote.request_status}</span>}
      </Typography>
      {quote.response_payload &&
        Array.isArray(quote.response_payload) &&
        fields &&
        fields.map((f) => (
          <Typography key={f}>
            <span className={classes.fieldName}>{f}:</span>
            <span className={classes.fieldValue}>{quotes[indQuoteIdx][f]}</span>
          </Typography>
        ))}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ color: "red" }}>
            Errors (
            {quote.response_payload &&
              !!quote.response_payload.length &&
              quote.response_payload[indQuoteIdx].Errors?.length}
            )
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!!(
            quote.response_payload &&
            !!quote.response_payload.length &&
            quote.response_payload[indQuoteIdx].Errors?.length
          ) && (
            <List>
              {quote.response_payload[indQuoteIdx].Errors?.map((w, quote) => (
                <ListItem key={quote}>{w.Text}</ListItem>
              ))}
            </List>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{ color: "orange" }}>
            Warnings (
            {quote.response_payload &&
              !!quote.response_payload.length &&
              quote.response_payload[indQuoteIdx].Warnings?.length}
            )
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!!(
            quote.response_payload &&
            !!quote.response_payload.length &&
            quote.response_payload[indQuoteIdx].Warnings?.length
          ) && (
            <List>
              {quote.response_payload[indQuoteIdx].Warnings?.map((w, quote) => (
                <ListItem key={quote}>{w.Text}</ListItem>
              ))}
            </List>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Full Response - (advanced)</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            overflow: "scroll",
            fontSize: 12,
          }}
        >
          {!!quote.response_payload && (
            <pre>
              {JSON.stringify(quote?.response_payload[indQuoteIdx], null, 2)}
            </pre>
          )}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
