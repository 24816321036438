import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  box: {
    width: 700,
    borderRadius: 3,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: 140,
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 32px 0px rgba(0,0,0,0.08)",
    padding: 35,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      width: "100%",
      maxWidth: 650,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
  },
  selectBtn: {
    textAlign: "right",
  },
  closeBtn: {
    position: "absolute",
    top: 15,
    right: 15,
    "& .MuiSvgIcon-root": {
      width: 24,
      height: 24,
    },
  },
  card: {
    margin: "40px 0",
    borderRadius: 3,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC",
    padding: 20,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
}));
