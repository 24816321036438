import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  helpText: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 12,
    textAlign: "right",
    position: "absolute",
    top: 56,
    right: 40,
    zIndex: 3,
    "& a": {
      color: "inherit",
      fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      top: 24,
    },
  },
  mobileHeader: {
    borderBottom: "1px solid #DADFF6",
    display: "flex",
    padding: 16,
    position: "sticky",
    top: 0,
    background: "#fff",
    zIndex: 2,
  },
  title: {
    fontSize: 20,
    fontWeight: 400,
    marginBottom: 8,
    margin: "0 auto",
    textAlign: "center",
  },
  drawerButton: {
    position: "relative",
    zIndex: 99,
  },
  errorPageContent: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "calc(100vw - 280px)",
    padding: "40px 40px 80px 40px",
    [theme.breakpoints.down('md')]: {
      width: "calc(100vw - 160px)",
      padding: 24,
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      padding: 16,
      minHeight: "calc(100vh - 57px)",
    },
  },
  errorPageTitle: {
    fontSize: 32,
    fontWeight: 300,
    marginBottom: 40,
    [theme.breakpoints.down('md')]: {
      fontSize: 28,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
  },
  errorPageSubtitle: {
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  chatWidgetContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
      flex: 1,
    },
  },
  image: {
    marginRight: 70,
  },
}));
