import { useEffect } from "react";
import {
  useAction,
  adminActions,
  useSelector,
  adminSelectors,
} from "../../state";

const useFAQs = () => {
  const faqs = useSelector(adminSelectors.faqs);
  const faqCategories = useSelector(adminSelectors.faqCategories);

  const getFAQs = useAction(adminActions.getFAQs);
  const getFAQCategories = useAction(adminActions.getFAQCategories);
  const updateFAQ = useAction(adminActions.updateFAQ);
  const updateFAQCategory = useAction(adminActions.updateFAQCategory);

  useEffect(() => {
    if (!faqCategories) getFAQCategories();
  }, [faqCategories, getFAQCategories]);

  useEffect(() => {
    if (!faqs) getFAQs();
  }, [faqs, getFAQs]);

  return {
    faqs,
    faqCategories,
    updateFAQ,
    updateFAQCategory,
  };
};

export default useFAQs;
