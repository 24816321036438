import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    height: 128,
    paddingLeft: 40,
    paddingRight: 40,
    width: "100%",
    [theme.breakpoints.down('md')]: {
      height: 96,
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  headerContent: {
    height: "100%",
  },
  logoWrapper: {
    "& img": {
      width: "auto",
      height: 64,
      [theme.breakpoints.down('md')]: {
        height: 48,
      },
    },
  },
  helpTextLight: {
    fontSize: 14,
  },
  phoneLink: {
    fontWeight: 600,
    textDecoration: "none",
    color: "#000",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "40px",
  },
  title: {
    fontSize: "48px",
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: "24px",
    },
  },
  subTitle: {
    paddingBottom: 40,
    color: theme.palette.secondary.main,
    fontSize: "20px",
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px",
      textAlign: "center",
      padding: "16px 16px 40px 16px",
    },
  },
}));
