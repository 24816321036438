import makeStyles from '@mui/styles/makeStyles';
import signInImg from "../../assets/img/sign_in.png";

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    secondaryPanel: {
      overflow: "hidden",
      position: "relative",
      padding: "2.8em 0 0 3.5em",
      borderRadius: "0 48px 48px 0",

      "&:before": {
        content: "''",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        backgroundImage: `url(${signInImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "cover",
        opacity: 0.6,
        [theme.breakpoints.down('sm')]: {
          backgroundImage: "none",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundSize: "cover",
          opacity: 0.6,
        },
      },
    },
    logo: {
      position: "relative",
      zIndex: 3,
      height: 40,
      width: "auto",
    },
    container: {
      display: "flex",

      [theme.breakpoints.up("md")]: {
        height: "100vh",
      },
      [theme.breakpoints.down('lg')]: {
        paddingTop: "10vh",
      },
    },
    content: {
      margin: "auto",
    },
    backdrop: {
      zIndex: theme.zIndex.modal * 3,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
  }),
  { classNamePrefix: "AuthLayout" },
);
