import { AppState } from "../types";

export const wizardSelectors = {
  addressError(state: AppState) {
    return state.wizard.addressError;
  },
  activeQuoteCard(state: AppState) {
    return state.wizard.activeQuoteCard;
  },
  activeStep(state: AppState) {
    return state.wizard.activeStep;
  },
  allQuotes(state: AppState) {
    return state.wizard.allQuotes;
  },
  carouselMode(state: AppState) {
    return state.wizard.carouselMode;
  },
  chatMode(state: AppState) {
    return state.wizard.chatMode;
  },
  contactInfo(state: AppState) {
    return state.wizard.contactInfo || {};
  },
  contactModal(state: AppState) {
    return state.wizard.contactModal || {};
  },
  addressModalOpen(state: AppState) {
    return state.wizard.addressModalOpen;
  },
  contactType(state: AppState) {
    return state.wizard.contactType;
  },
  coverageModal(state: AppState) {
    return state.wizard.coverageModal || {};
  },
  customQuotesLoading(state: AppState) {
    return state.wizard.customQuotesLoading;
  },
  getQuoteWasHydrated(state: AppState) {
    return state.wizard.quoteWasHydrated;
  },
  isQuoteSelected(state: AppState) {
    return (
      Boolean(state.auto.selectedQuote) || Boolean(state.home.selectedQuote)
    );
  },
  areQuotesSelected(state: AppState) {
    return (
      Boolean(state.auto.selectedQuote) && Boolean(state.home.selectedQuote)
    );
  },
  latestCustomQuote(state: AppState) {
    const mode = state.wizardNoPersist.mode;
    const carouselMode = state.wizard.carouselMode;
    const quoteMode =
      mode !== "bundle" && mode !== "auto-renters" ? mode : carouselMode;
    let custom;
    if (quoteMode === "auto") {
      custom = state.auto.customQuotes;
    } else {
      custom = state.home.customQuotes;
    }
    return custom && custom.length ? [custom[custom.length - 1]] : [];
  },
  latestBundleCustomQuote(state: AppState) {
    const mode = state.wizardNoPersist.mode;
    const carouselMode = state.wizard.carouselMode;
    const quoteMode =
      mode !== "bundle" && mode !== "auto-renters" ? mode : carouselMode;
    let custom;

    if (quoteMode === "auto") {
      custom = state.auto.bundleCustomQuotes;
    } else {
      custom = state.home.bundleCustomQuotes;
    }
    return custom && custom.length ? [custom[custom.length - 1]] : [];
  },
  offlineChatSubmitted(state: AppState) {
    return state.wizard.offlineChatSubmitted;
  },
  prefContactMethod(state: AppState) {
    return state.wizard.prefContactMethod;
  },
  req_uid(state: AppState) {
    return state.wizard.req_uid;
  },
  settings(state: AppState) {
    return state.wizard.settings ?? {};
  },
  shopperApprovedShown(state: AppState) {
    return state.wizard.shopperApprovedShown;
  },
  applicantFirstName(state: AppState) {
    const { selectedDrivers, applicantIndex } = state.auto.driversForm;
    return applicantIndex !== undefined
      ? `${selectedDrivers[applicantIndex].FirstName}`
      : state.home.insured.firstName
      ? state.home.insured.firstName
      : "";
  },
  showChat(state: AppState) {
    return state.wizard.showChat;
  },
  showPmtPopup(state: AppState) {
    return state.wizard.showPmtPopup;
  },
  selectedAdminQuote(state: AppState) {
    return state.wizard.selectedAdminQuote;
  },
  selectedQuote(state: AppState) {
    const mode = state.wizardNoPersist.mode;
    if (mode === "auto") {
      return state.auto.selectedQuote;
    } else if (mode === "home" || mode === "renters") {
      return state.home.selectedQuote;
    } else if (mode === "bundle" || mode === "auto-renters") {
      // TODO: figure out how selectedQuote is going to work for bundle, not really set up to use 2 quotes - CG - IMPORTANT
      return state.auto.selectedQuote;
    }
  },
  quotesLoading(state: AppState) {
    const carouselMode = state.wizard.carouselMode;
    if (carouselMode === "auto") {
      return state.auto.quotesLoading;
    } else {
      return state.home.quotesLoading;
    }
  },
  upgradedBundle(state: AppState) {
    return state.wizard.upgradedBundle;
  },
  explainerModalOpen(state: AppState) {
    return state.wizard.explainerModalOpen;
  },
};
