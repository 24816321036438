import React, { useEffect, useState } from "react";
import { adminSelectors, useSelector } from "../../../state";
import { CoverageSection_Bundle } from "./CoverageSection_Bundle";

const CoverageWrapper_Bundle = ({
  payFrequency,
  isLinked,
}: {
  payFrequency: "installments" | "full";
  isLinked: boolean;
}) => {
  const { quotes, applicant } = useSelector(adminSelectors.activeQuote);
  const [selectedQuotes, setSelectedQuotes] = useState<Array<any>>([]);
  const [hasPurchased, setHasPurchased] = useState<Array<any>>([]);

  const getPremium = (quote) => {
    if (!Array.isArray(quote.response_payload)) return;
    const quoteHasPremium = quote?.response_payload?.find(
      (quote) => !!quote.PayPlanDescription,
    );
    if (!quoteHasPremium) return 0;
    if (payFrequency === "installments") {
      const installements = quote?.response_payload?.find(
        (quote) => !quote.PayPlanDescription.includes("Full"),
      );
      if (installements) {
        return installements.TotalPremium;
      }
    } else {
      const paidInFull =
        quote?.response_payload?.find((quote) =>
          quote.PayPlanDescription.includes("Full"),
        ) ||
        (quote?.quote_type === "home" &&
          quote?.response_payload?.find((quote) =>
            quote.PayPlanDescription.includes("MetLife Payment Option"),
          ));
      if (paidInFull) {
        if (
          quote?.quote_type === "auto" &&
          quote?.response_payload?.find((quote) => quote.CompanyID === 38407765)
        ) {
          return paidInFull.PayPlanDownPayment;
        }
        return paidInFull.TotalPremium;
      }
    }
    return 0;
  };
  const selected: any = [];
  const purchasedQuotes: any = [];
  useEffect(() => {
    setHasPurchased([]);
    setSelectedQuotes([]);
    (async () => {
      [1, 2, 3, 0].map(async (level) => {
        const quotesByLevel = Object(quotes[`${level}`]);
        if (Array.isArray(quotesByLevel) && quotesByLevel.length) {
          //find and set selected quotes
          const _selectedQuotes = quotesByLevel.filter(
            (q) =>
              (q.req_txn_id === applicant.selected_quote_auto ||
                q.req_txn_id === applicant.selected_quote_home) &&
              q.is_linked === isLinked &&
              getPremium(q) > 0,
          );
          const purchased = quotesByLevel.filter(
            (q) => q.full_premium_override !== null,
          );

          if (purchased.length) {
            purchasedQuotes.push(purchased);
          }
          if (_selectedQuotes.length) {
            selected.push(_selectedQuotes);
          }
        }
      });
    })();
    if (selected.length) {
      setSelectedQuotes(selected);
    }
    if (purchasedQuotes.length) {
      setHasPurchased(purchasedQuotes);
    }
  }, [quotes, isLinked, payFrequency]);

  const coverages = [1, 2, 3, 0].map((level) => {
    const quotesByLevel = Object(quotes[`${level}`]);
    if (Array.isArray(quotesByLevel) && quotesByLevel.length) {
      const _quotes = quotesByLevel.map((q) => {
        return { ...q, displayPremium: getPremium(q) };
      });

      //filter and sort quotes - selected and then price low to high

      const purchasedQuote = _quotes.filter((q) => q.full_premium_override > 0);

      const filteredQuotes = purchasedQuote
        ? _quotes?.filter((q) => q.full_premium_override === null)
        : _quotes?.filter(
            (quote) =>
              quote.req_txn_id !== applicant.selected_quote_auto &&
              quote.req_txn_id !== applicant.selected_quote_home,
          );
      const categorized = filteredQuotes.reduce(
        (acc, curr) => {
          const succeeded = curr.displayPremium > 0 && curr.total_premium > 0;
          const notAvailable =
            curr.displayPremium === 0 && curr.total_premium > 0;
          const failed =
            curr.displayPremium === 0 && curr.total_premium === "0.00";
          const errored = curr.request_status === "failed";
          if (succeeded) {
            acc.succeeded?.push(curr);
          }
          if (notAvailable) {
            acc.notAvailable?.push(curr);
          }
          if (failed) {
            acc.failed?.push(curr);
          }
          if (errored) {
            acc.errored?.push(curr);
          }
          return acc;
        },
        { succeeded: [], notAvailable: [], failed: [], errored: [] },
      );

      const sortedQuotes = categorized.succeeded
        .sort(function (a, b) {
          return a.displayPremium - b.displayPremium;
        })
        .concat(categorized.notAvailable)
        .concat(categorized.failed)
        .concat(categorized.errored);

      const _selectedQuotes = _quotes.filter(
        (q) =>
          q.req_txn_id === applicant.selected_quote_auto ||
          q.req_txn_id === applicant.selected_quote_home,
      );

      const combinedQuotes = purchasedQuote
        ? [...purchasedQuote, ...sortedQuotes]
        : _selectedQuotes?.length
        ? [..._selectedQuotes, ...sortedQuotes]
        : sortedQuotes;

      //filter by is_linked
      const displayQuotes = combinedQuotes.filter(
        (quote) => quote.is_linked === isLinked,
      );

      //quote_type is filtered in CoverageSection_Bundle
      if (displayQuotes.length) {
        return (
          <div key={level}>
            <CoverageSection_Bundle
              quotes={displayQuotes}
              level={level}
              selectedQuotes={selectedQuotes}
              purchasedQuotes={hasPurchased}
            />
          </div>
        );
      }
    }
    return <div key={level}></div>;
  });

  return <>{coverages}</>;
};

export default React.memo(CoverageWrapper_Bundle);
