import {
  createTheme,
  useMediaQuery,
  PaletteMode,
  ThemeOptions,
} from "@mui/material";

const themeBase: ThemeOptions = {
  typography: {
    h1: {
      fontFamily: "Montserrat",
      fontSize: 24,
      fontWeight: 500,
      paddingTop: 16,
      paddingBottom: 16,
    },
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    mode: "light" as PaletteMode,
    primary: { main: "#0033ff", light: "#0066ff", contrastText: "#FFFFFF" },
    secondary: { main: "#8D98C2", light: "#cdd2e9", contrastText: "#0033ff3d" },
    info: { main: "#FF4343" },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: "0px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: "#AFB6D4",
        },
        select: {
          height: "100%",
          minHeight: 48,
          display: "flex",
          alignItems: "center",
          lineHeight: "48px",
        },

        // selectMenu: {
        //   minHeight: 48,
        //   lineHeight: "48px",
        // },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "#AFB6D4",
        },
        root: {
          borderColor: "#AFB6D4",
          minHeight: 48,
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#AFB6D4",
            },
          },
        },
        input: {
          height: 48,
          padding: "0 16px",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        // variant: "outlined",
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#AFB6D4",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: [
            "Lato",
            "Montserrat",
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
          fontWeight: 600,
          transition: "background 300ms linear",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          paddingTop: 15,
          paddingBottom: 15,
          fontSize: 14,
          letterSpacing: "1px",
          lineHeight: "18px",
          textAlign: "center",
          borderRadius: 3,
          "&.Mui-disabled": {
            color: "#fff",
            backgroundColor: "#DADFF6",
            opacity: 0.75,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: "opacity 300ms linear",
          "&:hover": {
            backgroundColor: "transparent",
            opacity: 0.5,
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: "#cdd2e9",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          height: 12,
          width: 12,
          "&.Mui-completed": {
            backgroundColor: "#0033ff",
            borderRadius: "50%",
          },
        },
        text: { display: "none" },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "& ~ .MuiFormControlLabel-label": {
            fontWeight: 500,
          },
        },
      },
    },
  },
};

export const defaultTheme = createTheme(themeBase);

export const getTheme = (themeOverride = {}) =>
  createTheme({ ...themeBase, ...themeOverride });

export function useMobile(
  size: number | "xs" | "sm" | "md" | "lg" | "xl" = "sm",
) {
  return useMediaQuery(defaultTheme.breakpoints.down(size));
}
