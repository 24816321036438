import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import {
  ExpandMoreIcon,
  ExpandLessIcon,
  StarIcon,
} from "../../../../components/icons";
import clsx from "clsx";
import {
  autoActions,
  autoSelectors,
  homeActions,
  homeSelectors,
  useAction,
  useSelector,
  wizardActions,
  wizardSelectors,
  wizardNoPersistSelectors,
} from "../../../../state";
import { formatCurrency, getInsuranceLevelTitle } from "../../../../utils";
import { Navigation } from "../../../../lib";
import QuoteProps from "./props/QuoteProps";
import { useStyles } from "./QuoteCard.styles";
import { useMobile } from "../../../../themes";

const QuoteCard = ({ quote, index = -1 }) => {
  const isMobile = useMobile();
  // refs
  const firstUpdate = useRef(true);
  const seeMoreRef = useRef<HTMLDivElement>(null);
  // actions
  const clearSelectedAutoQuote = useAction(autoActions.clearSelectedAutoQuote);
  const clearSelectedHomeQuote = useAction(homeActions.clearSelectedHomeQuote);
  const saveSelectedAutoQuote = useAction(autoActions.saveSelectedQuote);
  const saveSelectedHomeQuote = useAction(homeActions.saveSelectedQuote);
  const setCarouselMode = useAction(wizardActions.setCarouselMode);
  const setSelectedAutoQuote = useAction(autoActions.setSelectedAutoQuote);
  const setSelectedHomeQuote = useAction(homeActions.setSelectedHomeQuote);
  const toggleQuoteCardDetails = useAction(autoActions.toggleQuoteCardDetails);
  // selectors
  const activeQuoteCard = useSelector(wizardSelectors.activeQuoteCard);
  const carouselMode = useSelector(wizardSelectors.carouselMode);
  const homeMoreCoverages = useSelector(homeSelectors.moreCoverages);
  const rentersMoreCoverages = useSelector(homeSelectors.rentersMoreCoverages);
  const latestCustomQuote = useSelector(wizardSelectors.latestCustomQuote);
  const isAutoQuoteSelected = useSelector(autoSelectors.selectedQuote);
  const isHomeQuoteSelected = useSelector(homeSelectors.selectedQuote);
  const isQuoteSelected = useSelector(wizardSelectors.isQuoteSelected);
  const areQuotesSelected = useSelector(wizardSelectors.areQuotesSelected);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const monthlyPayment = useSelector(autoSelectors.monthlyPayment);
  const req_uid = useSelector(wizardSelectors.req_uid);
  const showQuoteCardDetails = useSelector(autoSelectors.showQuoteCardDetails);
  //state
  const [showQuoteProps, setShowQuoteProps] = useState(false);
  //consts
  const classes = useStyles({});
  const quoteSelected =
    mode === "bundle" || mode === "auto-renters"
      ? areQuotesSelected
      : isQuoteSelected;
  const selectedQuote = quote
    ? quote.turbo_quote_id === isAutoQuoteSelected?.turbo_quote_id ||
      quote.turbo_quote_id === isHomeQuoteSelected?.turbo_quote_id
    : null;

  useEffect(() => {
    if (activeQuoteCard !== quote?.insurance_level_id) {
      return;
    }
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (showQuoteCardDetails) {
      setTimeout(() => {
        seeMoreRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 100);
    }
  }, [showQuoteCardDetails]);

  const handleSelectQuote = async (id, quote) => {
    if (quote.quote_type === "auto") {
      if (mode === "auto") {
        await saveSelectedAutoQuote(req_uid, id);
        setSelectedAutoQuote(quote);
        Navigation.go(`/auto/wizard/quotes/${req_uid}`);
      }
    }
    if (quote.quote_type === "home" || quote.quote_type === "renters") {
      setSelectedHomeQuote(quote);
      await saveSelectedHomeQuote(req_uid, id, quote.quote_type);
      if (mode === "home") {
        Navigation.go(`/home/wizard/quotes/${req_uid}`);
      }
      if (mode === "renters") {
        Navigation.go(`/renters/wizard/quotes/${req_uid}`);
      }
    }
    if (mode === "bundle" || mode === "auto-renters") {
      if (carouselMode === "auto") {
        // moved in here bc didnt want to await this action if on bundle mode
        saveSelectedAutoQuote(req_uid, id);
        setSelectedAutoQuote(quote);
        if (isHomeQuoteSelected) {
          Navigation.go(`/${mode}/wizard/quotes/${req_uid}`);
        } else {
          setCarouselMode("home");
        }
      }
      if (carouselMode === "home") {
        if (isAutoQuoteSelected) {
          Navigation.go(`/${mode}/wizard/quotes/${req_uid}`);
        } else {
          setCarouselMode("auto");
        }
      }
    }
  };

  if (!quote?.coverage) return null;

  return (
    <Card
      className={clsx(
        classes.quoteCard,
        quote.insurance_level_id === 2 &&
          !latestCustomQuote.length &&
          !isMobile &&
          ((quote.quote_type !== "auto" && isAutoQuoteSelected) ||
            (quote.quote_type !== "home" && isHomeQuoteSelected))
          ? "quote-card-shadow"
          : "",
      )}
      variant="outlined"
    >
      {!quoteSelected ? (
        <>
          {quote.insurance_level_id === 2 && (
            <Typography className={classes.bestValueText}>
              <StarIcon />
              Best value, switch today
            </Typography>
          )}
          <Typography className={classes.insuranceLevel}>
            {getInsuranceLevelTitle(quote.insurance_level_id)}
          </Typography>
          <img
            className={classes.carrierImg}
            src={quote.logo_full_url}
            alt={quote.carrier}
          />
          {quote.quote_type === "auto" ? (
            <>
              <Typography className={classes.largePaymentAmount}>
                {formatCurrency(
                  Math.round(monthlyPayment || quote.TotalPremium / 6),
                )}
                <span>/mo</span>
              </Typography>
              <Typography className={classes.smallPaymentAmount}>
                Total Premium: &nbsp;
                {formatCurrency(Math.round(quote.TotalPremium))}
              </Typography>
            </>
          ) : (
            <>
              <Typography className={classes.largePaymentAmount}>
                {formatCurrency(Math.round(quote.TotalPremium))}
                <span>/year</span>
              </Typography>
              <Typography className={classes.smallPaymentAmount}>
                <span>
                  {formatCurrency(Math.round(quote.TotalPremium / 12))}
                </span>
                /mo
              </Typography>
            </>
          )}
          <Button
            className={clsx(
              classes.selectBtn,
              index > -1 ? `quote-btn-${index}` : "",
            )}
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              selectedQuote
                ? quote.quote_type === "auto"
                  ? clearSelectedAutoQuote()
                  : clearSelectedHomeQuote()
                : handleSelectQuote(quote.turbo_quote_id, quote)
            }
            disabled={
              quote.quote_type === "auto"
                ? isAutoQuoteSelected && !selectedQuote
                : isHomeQuoteSelected && !selectedQuote
            }
          >
            {selectedQuote ? "DESELECT" : "SELECT"}
          </Button>
        </>
      ) : (
        <Box className={classes.topContainer}>
          <img
            className={classes.selectedCarrierImg}
            src={quote.logo_full_url}
            alt={quote.carrier}
          />
          <Typography className={classes.selectedInsuranceLevel}>
            {getInsuranceLevelTitle(quote.insurance_level_id)}
          </Typography>
          {quote.quote_type === "auto" ? (
            <>
              <Grid
                container
                className={classes.selectedMonthlyPayments}
                justifyContent="space-between"
              >
                <Box>
                  <Typography className={classes.selectedMonthlyPayment}>
                    {quote.due_today_override ||
                      formatCurrency(
                        quote.PayPlanDownPayment === quote.TotalPremium
                          ? quote.TotalPremium / 6
                          : quote.PayPlanDownPayment,
                      )}
                  </Typography>
                  <Typography className={classes.selectedMonthlyPaymentLabel}>
                    First month
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.selectedMonthlyPayment}>
                    {quote.monthly_payments_override ||
                      formatCurrency(
                        quote.PayPlanPaymentAmount !== 0
                          ? quote.PayPlanPaymentAmount
                          : quote.TotalPremium / 6,
                      )}
                  </Typography>
                  <Typography className={classes.selectedMonthlyPaymentLabel}>
                    Other months
                  </Typography>
                </Box>
              </Grid>
              <Grid container alignItems="center">
                <Typography className={classes.selectedTotalPayment}>
                  ${quote.full_premium_override || quote.TotalPremium}
                </Typography>
                <Typography className={classes.selectedMonthlyPaymentLabel}>
                  6 months Total
                </Typography>
              </Grid>
            </>
          ) : (
            <Box mb={2}>
              <Typography className={classes.largePaymentAmount}>
                {formatCurrency(
                  quote.full_premium_override || quote.TotalPremium,
                )}
                <span>/year</span>
              </Typography>
              <Typography className={classes.smallPaymentAmount}>
                <span>
                  {formatCurrency(
                    quote.full_premium_override / 12 || quote.TotalPremium / 12,
                  )}
                </span>
                /mo
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {(isMobile && quoteSelected && !showQuoteProps) ||
      ((mode === "bundle" || mode === "auto-renters") &&
        isAutoQuoteSelected &&
        isHomeQuoteSelected &&
        !showQuoteProps) ? (
        <Typography
          className={classes.seeMore}
          onClick={() => setShowQuoteProps(true)}
        >
          See More
        </Typography>
      ) : (
        Boolean(quote.coverage.length) && (
          <Box className={classes.coverageContainer}>
            <QuoteProps
              coverageData={quote.coverage}
              carrier={quote.carrier}
              quoteType={quote.quote_type}
            />
            {(quote.quote_type === "auto" ||
              !!homeMoreCoverages.length ||
              !!rentersMoreCoverages.length) && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                onClick={() => toggleQuoteCardDetails()}
                className={classes.expandBtn}
              >
                {quote.insurance_level_id === 2 &&
                  !latestCustomQuote.length && (
                    <Typography className={classes.expandText}>
                      {showQuoteCardDetails ? "Less Details" : "More Details"}
                    </Typography>
                  )}
                {showQuoteCardDetails ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Grid>
            )}
            <div ref={seeMoreRef}></div>
          </Box>
        )
      )}
    </Card>
  );
};

export default QuoteCard;
