import { Box } from "@mui/material";
import React, { useEffect } from "react";
import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../../state";
import { useStyles } from "./QuoteManagement.styles";
import { ApplicantSection, CoverageSection } from "../components";
import BundleQuotesContainer from "./BundleQuotesContainer";
const QuoteManagement_old = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeQuote = useSelector(adminSelectors.activeQuote);
  const role = useSelector(authSelectors.activeRole);
  const activeAgencyId = useSelector(authSelectors.activeAgencyId);
  const quotes = activeQuote?.quotes;
  const applicant = activeQuote?.applicant;
  const custom = Object(quotes["0"]);
  const low = Object(quotes["1"]);
  const med = Object(quotes["2"]);
  const high = Object(quotes["3"]);
  const {
    match: {
      params: { masterQuoteId },
    },
  } = props;
  useEffect(() => {
    dispatch(
      adminActions.getQuoteForAgent(masterQuoteId, role, activeAgencyId),
    );
  }, [dispatch, masterQuoteId]);
  return (
    <Box className={classes.containerWrapper}>
      <Box className={classes.container}>
        <ApplicantSection app={applicant} />
        <div className={classes.coverageWrapper}>
          {activeQuote.quoteTypes.includes("Bundle") ? (
            <BundleQuotesContainer />
          ) : (
            <>
              <CoverageSection quotes={low} heading="Low Coverages" />
              <CoverageSection quotes={med} heading="Medium Coverages" />
              <CoverageSection quotes={high} heading="High Coverages" />
              {custom && (
                <CoverageSection quotes={custom} heading="Custom Coverages" />
              )}
            </>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default React.memo(QuoteManagement_old);
