import React from "react";

export const CompImage = ({ fillColor }) => {
  return (
    <svg
      width="98px"
      height="84px"
      viewBox="0 0 98 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 7 Copy 3</title>
      <g
        id="↳-auto-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Create-a-custom-quote-side-bar"
          transform="translate(-577.000000, -1084.000000)"
          fillRule="nonzero"
        >
          <g id="Group-7-Copy-3" transform="translate(577.000000, 1084.000000)">
            <path
              d="M62.6620058,14.0689174 C51.5282173,14.1202332 42.4657513,14.284728 42.4657513,14.284728 C42.4657513,14.284728 53.2379139,0.0158966443 62.6216057,5.30239589 C72.0052975,10.5888951 82.7847741,14.0989047 82.7847741,14.0989047 C82.7847741,14.0989047 73.7957968,14.0176016 62.6620058,14.0689174 Z"
              id="Path"
              fill="#F9FAFC"
            ></path>
            <path
              d="M33.7268405,81.3786755 C34.1344026,82.8852238 35.530339,83.818274 35.530339,83.818274 C35.530339,83.818274 36.2655194,82.3087207 35.8579693,80.8021724 C35.4504192,79.2956241 34.0544733,78.3625739 34.0544733,78.3625739 C34.0544733,78.3625739 33.3192928,79.8721344 33.7268405,81.3786755 Z"
              id="Path"
              fill="#3F3D56"
            ></path>
            <path
              d="M34.3257066,81.054909 C35.4440267,82.1435498 35.5806322,83.8170421 35.5806322,83.8170421 C35.5806322,83.8170421 33.9040699,83.7255019 32.7857474,82.6368611 C31.6674248,81.5482204 31.5308217,79.8747256 31.5308217,79.8747256 C31.5308217,79.8747256 33.2073841,79.9662683 34.3257066,81.054909 Z"
              id="Path"
              fill={fillColor}
            ></path>
            <path
              d="M64.7631526,81.3786755 C65.1707027,82.8852238 66.566651,83.818274 66.566651,83.818274 C66.566651,83.818274 67.3018315,82.3087207 66.8942814,80.8021724 C66.4867313,79.2956241 65.0907854,78.3625739 65.0907854,78.3625739 C65.0907854,78.3625739 64.3556049,79.8721344 64.7631526,81.3786755 Z"
              id="Path"
              fill="#3F3D56"
            ></path>
            <path
              d="M65.3620187,81.054909 C66.4803388,82.1435498 66.6169443,83.8170421 66.6169443,83.8170421 C66.6169443,83.8170421 64.940382,83.7255019 63.8220594,82.6368611 C62.7037369,81.5482204 62.5671338,79.8747256 62.5671338,79.8747256 C62.5671338,79.8747256 64.2436962,79.9662683 65.3620187,81.054909 Z"
              id="Path"
              fill={fillColor}
            ></path>
            <rect
              id="Rectangle"
              fill="#8D98C2"
              x="35.4769806"
              y="28.1698873"
              width="1.41342012"
              height="55.8301127"
            ></rect>
            <path
              d="M49.9346691,35.284791 C50.0246504,54.8071895 36.289605,70.6968598 36.289605,70.6968598 C36.289605,70.6968598 22.4086746,54.9344673 22.3187078,35.4120688 C22.228741,15.8896703 35.9637719,0 35.9637719,0 C35.9637719,0 49.8446927,15.7623926 49.9346691,35.284791 Z"
              id="Path"
              fill="#DADFF6"
            ></path>
            <rect
              id="Rectangle"
              fill="#8D98C2"
              x="17.1025118"
              y="28.1698873"
              width="1.41342012"
              height="55.8301127"
            ></rect>
            <path
              d="M31.5602004,35.284791 C31.6501816,54.8071895 17.9151363,70.6968598 17.9151363,70.6968598 C17.9151363,70.6968598 4.03420586,54.9344673 3.94423905,35.4120688 C3.85427224,15.8896703 17.5893055,0 17.5893055,0 C17.5893055,0 31.4702263,15.7623926 31.5602004,35.284791 Z"
              id="Path"
              fill="#EFF1FB"
            ></path>
            <g id="Group-Copy" transform="translate(17.221388, 39.837120)">
              <rect
                id="Rectangle"
                fill="#3F3D56"
                x="0"
                y="35.3038448"
                width="9.47176323"
                height="1.72213877"
              ></rect>
              <path
                d="M74.9130364,35.6879256 L73.0410211,36.1264097 L1.8956515,36.1649141 L1.25589027,34.6556753 C0.140104641,32.0668426 -0.242860429,29.2148857 0.149775496,26.4183925 C0.456188747,24.3339903 1.22401937,22.2628371 2.96797455,21.3632546 L6.12515763,0.071324129 L45.4862371,0 L60.7148912,12.4367716 C60.7148912,12.4367716 67.8984724,12.3060174 71.8011053,17.2254468 C73.0361805,18.7836419 73.9431553,20.848002 74.1865421,23.584973 L74.3405596,26.1440972 L74.9130364,35.6879256 Z"
                id="Path"
                fill={fillColor}
              ></path>
              <circle
                id="Oval"
                fill="#3F3D56"
                cx="58.9832528"
                cy="34.8733101"
                r="9.04122854"
              ></circle>
              <circle
                id="Oval"
                fill="#CCCCCC"
                cx="58.9832528"
                cy="34.8733101"
                r="4.73588161"
              ></circle>
              <circle
                id="Oval"
                fill="#3F3D56"
                cx="15.9297836"
                cy="34.8733101"
                r="9.04122854"
              ></circle>
              <circle
                id="Oval"
                fill="#CCCCCC"
                cx="15.9297836"
                cy="34.8733101"
                r="4.73588161"
              ></circle>
              <polygon
                id="Path"
                fill="#FFFFFF"
                points="55.1084406 11.1814159 33.7190065 11.1907805 31.108011 11.1907805 26.6993572 11.193902 26.6993572 10.8705132 26.6962541 6.9711191 26.6931509 2.59257269 29.6259437 2.59257269 32.2369392 2.58945118 36.8621933 2.58632967 39.4731888 2.58632967 44.8127894 2.58320815"
              ></polygon>
              <polygon
                id="Path"
                fill="#FFFFFF"
                points="23.2488734 11.1876567 12.0611138 11.193902 12.0580426 8.96183528 12.0580426 5.0613393 12.0549714 2.58945344 13.6931483 2.58945344 16.2772542 2.5863308 23.2458022 2.58320815"
              ></polygon>
              <rect
                id="Rectangle"
                fill="#3F3D56"
                x="26.6931509"
                y="17.2213877"
                width="1.72213877"
                height="2.58320815"
              ></rect>
              <polygon
                id="Rectangle"
                fill="#3F3D56"
                transform="translate(50.382728, 14.159454) rotate(89.969370) translate(-50.382728, -14.159454) "
                points="49.6591431 12.7912187 51.1063059 12.7912224 51.1063129 15.5276889 49.6591501 15.5276852"
              ></polygon>
              <path
                d="M49.9420243,7.74962446 L50.1142409,7.74962446 C51.9213502,7.74962446 53.3863018,9.09892309 53.3863018,10.7633673 L53.3863018,10.7633673 C53.3863018,12.4278115 51.9213502,13.7771102 50.1142409,13.7771102 L49.9420243,13.7771102 L49.9420243,7.74962446 Z"
                id="Path"
                fill="#3F3D56"
              ></path>
              <path
                d="M74.9130364,25.8052723 C72.6601713,26.0435631 70.5574574,24.6716547 69.9296893,22.5539047 C69.3019212,20.4361547 70.3309735,18.1861092 72.3685016,17.2213877 C73.6060478,18.7210952 74.5148371,20.7079686 74.7587108,23.342206 L74.9130364,25.8052723 Z"
                id="Path"
                fill="#3F3D56"
              ></path>
              <polygon
                id="Path"
                fill="#F2F2F2"
                points="31.8595672 1.72213877 25.8354573 10.3328326 25.8320815 6.27822115 29.0191716 1.72538454"
              ></polygon>
              <polygon
                id="Path"
                fill="#F2F2F2"
                points="16.3603183 2.58320815 12.0549714 8.61069384 12.0549714 4.9231137 13.7234578 2.58616034"
              ></polygon>
              <polygon
                id="Path"
                fill="#F2F2F2"
                points="39.6091917 2.58320815 33.6861255 11.193902 30.9984978 11.193902 36.9215641 2.58320815"
              ></polygon>
            </g>
            <path
              d="M97.3008404,83.7586048 C97.3008404,83.7718798 97.2804127,83.7846245 97.2435838,83.7940114 C97.2067549,83.8033982 97.1567518,83.8086511 97.1046682,83.8086048 L0.196171047,83.8086048 C0.0878287693,83.8086048 0,83.7862189 0,83.7586048 C0,83.7309908 0.0878287693,83.7086048 0.196171047,83.7086048 L97.1046682,83.7086048 C97.1567519,83.7085584 97.2067554,83.7138112 97.2435844,83.7231981 C97.2804134,83.7325851 97.3008404,83.7453298 97.3008404,83.7586048 Z"
              id="Path"
              fill="#3F3D56"
            ></path>
            <g
              id="Group-7"
              transform="translate(74.000000, 34.264703)"
              fill="#FFCC00"
            >
              <polygon
                id="Path"
                points="11 6 11.9375 3.9375 14 3 11.9375 2.0625 11 0 10.0625 2.0625 8 3 10.0625 3.9375"
              ></polygon>
              <polygon
                id="Path-Copy"
                points="19 17 19.9375 14.9375 22 14 19.9375 13.0625 19 11 18.0625 13.0625 16 14 18.0625 14.9375"
              ></polygon>
              <polygon
                id="Path"
                points="6 11 4.125 15.125 0 17 4.125 18.875 6 23 7.875 18.875 12 17 7.875 15.125"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
