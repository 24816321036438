import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  formSection: {
    display: "block",
    marginBottom: 40,
  },
  formLabel: {
    color: "#000",
    fontWeight: 600,
    fontSize: 20,
    marginBottom: 16,
    lineHeight: "1.2",
    "&.Mui-focused": {
      color: "inherit",
    },
  },
  claimsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      gap: 24,
    },
  },
  claimLabel: {
    color: "#000",
    fontWeight: 600,
    fontSize: 16,
    "&.Mui-focused": {
      color: "inherit",
    },
  },
  claimItem: {
    width: 185,
    maxWidth: "100%",
    [theme.breakpoints.down('sm')]: {
      width: 304,
    },
  },
  addClaimButton: {
    width: 112,
    height: 40,
    backgroundColor: theme.palette.primary.contrastText,
    marginTop: 16,
  },
  deleteClaimButton: {
    paddingLeft: 0,
  },
  dwellingAmount: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#000",
      cursor: "not-allowed",
    },
  },
  twoLineLabelRadioGroup: {
    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
      marginBottom: 8,
    },
    "& .MuiRadio-root": {
      paddingTop: 2,
    },
  },
  subText: {
    fontSize: ".80rem",
    fontWeight: 400,
  },
  toggle: {
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  info: {
    display: "flex",
    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
      color: "#AFB6D4",
      margin: "2px 0 0 5px",
      "&:hover": { cursor: "pointer" },
    },
  },
  nextButton: {
    width: 220,
    height: 48,
    display: "block",
    margin: "0 auto 40px",
  },
  error: {
    "& .MuiRadio-root": {
      color: "#f44336",
    },
  },
}));
