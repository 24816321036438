import { Api, arrayToEntityList, history, timeoutAsync } from "../../lib";
import { wizard } from "./state";
const { actions } = wizard;
import { autoActions, genericErrorMsg } from "../auto/actions";
import { homeActions } from "../home/actions";
import { uiActions } from "../ui/actions";
import { QuoteType } from "../admin/state";
import { authClient } from "../auth/actions";
import { lifeActions } from "../states";

export const wizardActions = {
  ...actions,
  validateAddress(state: string, quote_type: QuoteType) {
    return async (dispatch, getState) => {
      const roles = getState().auth.user?.user_agency?.map((ua) => ua.role);
      const url =
        !!roles &&
        (roles.includes("super_admin") ||
          roles.includes("admin") ||
          roles?.includes("super_agent"))
          ? `/stateLookup/admin/${state}/quote-type/${quote_type}`
          : `/stateLookup/${state}/quote-type/${quote_type}`;
      if (!state) {
        dispatch(uiActions.showError("Invalid address. Please check address"));
        return;
      }
      dispatch(uiActions.setLoading(true));
      try {
        const {
          data: { isCoveredArea },
        } = await authClient.get(url);
        dispatch(uiActions.setLoading(false));
        if (!isCoveredArea) {
          // TODO redirect to https://bitbean.atlassian.net/browse/RELI-45?focusedCommentId=33549
          dispatch(
            wizardActions.setAddressError(
              `${window.location.hostname} is not yet available in your area. Check back soon!`,
            ),
          );
        }
        return isCoveredArea;
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError(genericErrorMsg));
        throw e;
      }
    };
  },
  getAllQuotes() {
    return async (dispatch, getState) => {
      const { req_uid } = getState().wizard;
      const { data, status } = await Api.get(`/all-quotes/${req_uid}`);
      if (status === 200) {
        return dispatch(actions.setAllQuotes(data.quotes));
      } else {
        history.push("/hit-snag");
      }
    };
  },
  getCoverageDetails() {
    return async (dispatch) => {
      const { data, status } = await Api.get("/coverage-details");
      if (status === 200) {
        dispatch(actions.setCoverageDetails(data));
      } else {
        console.error("Failed to get coverage details");
      }
    };
  },
  getQuotes(_req_uid, quote_type, is_linked) {
    return async (dispatch, getState) => {
      const { req_uid } = getState().wizard;
      const { mode } = getState().wizardNoPersist;
      const id = req_uid || _req_uid;
      is_linked = is_linked ? 1 : 0;
      if (id) {
        const { data: quotes, status } = await Api.get(
          `/quote/${id}/quote-type/${quote_type}/linked/${is_linked}`,
        );
        if (status === 200) {
          // The quotes are not ready right away need to keep polling until 3 quotes come back
          if (quotes.meta.isCompleted) {
            if (Object.keys(quotes).length && !quotes?.customQuotes.length) {
              dispatch(
                wizardActions.sendQuoteNotifications(
                  req_uid,
                  quote_type,
                  is_linked,
                ),
              );
            }
            if (
              Object.keys(quotes).length > 2 ||
              (quotes?.customQuotes.length && quote_type === "auto")
            ) {
              await dispatch(wizardActions.getAllQuotes());
              if (quote_type === "home" || quote_type === "renters") {
                if (is_linked) {
                  return dispatch(
                    homeActions.setBundleHomeQuotes({ ...quotes }),
                  );
                } else
                  return dispatch(homeActions.setHomeQuotes({ ...quotes }));
              } else if (quote_type === "auto") {
                if (is_linked) {
                  return dispatch(
                    autoActions.setBundleAutoQuotes({ ...quotes }),
                  );
                } else
                  return dispatch(autoActions.setAutoQuotes({ ...quotes }));
              }
            } else {
              if (mode !== "bundle") {
                history.push("/hit-snag");
              } else {
                if (quote_type === "auto") {
                  dispatch(autoActions.setBundleAutoQuotesFailed(true));
                }
                if (quote_type === "home" || quote_type === "renters") {
                  dispatch(homeActions.setBundleHomeQuotesFailed(true));
                }
              }
            }
          } else {
            await timeoutAsync(1500);
            await dispatch(wizardActions.getQuotes(id, quote_type, is_linked));
          }
        } else {
          if (mode !== "bundle" && mode !== "auto-renters") {
            history.push("/hit-snag");
          } else {
            if (quote_type === "auto") {
              dispatch(autoActions.setBundleAutoQuotesFailed(true));
            }
            if (quote_type === "home" || quote_type === "renters") {
              dispatch(homeActions.setBundleHomeQuotesFailed(true));
            }
          }
        }
      } else {
        console.error("Cant get quote missing req_uid");
      }
    };
  },
  sendQuoteNotifications(req_uid, quote_type, is_linked) {
    return async (dispatch, getState) => {
      const { token } = getState().auth;
      if (token) return;
      const { default_agent_image_path } = getState().ui.agencyData;
      Api.post(`/quote/${req_uid}/send-notifications`, {
        quote_type,
        is_linked,
        agent_avatar: default_agent_image_path || "", //to be backwards compatible
      });
    };
  },
  getQuoteData(req_txn_id) {
    return async (dispatch) => {
      if (req_txn_id) {
        const { data, status } = await Api.get(`/quote/txn/${req_txn_id}`);
        if (status === 200) {
          dispatch(wizardActions.setReqUid(data.req_uid));
          dispatch(wizardActions.getSelectedQuote(data.req_uid));
        } else {
          dispatch(uiActions.showError("Invalid quote ID"));
        }
      } else {
        console.error("Cant get quote missing req_txn_id");
      }
    };
  },
  getSelectedQuote(req_uid) {
    return async (dispatch) => {
      const { data, status } = await Api.get(`/selected-quote/${req_uid}`);
      if (status === 200) {
        dispatch(autoActions.setSelectedAutoQuote(data.auto));
        dispatch(homeActions.setSelectedHomeQuote(data.home));
        dispatch(
          wizardActions.setSelectedAdminQuote(
            Object.keys(data.home).length !== 0 ? data.home : data.auto,
          ),
        );
      } else {
        dispatch(uiActions.showError("Can't get selected quote"));
      }
    };
  },
  getStateRequirements() {
    return async (dispatch, getState) => {
      const { mode } = getState().wizardNoPersist;
      if (!mode) return;
      // TODO: figure out how to get address into wizard state and pull from one place - CG
      const { address } =
        mode === "auto" || mode === "bundle" || mode === "auto-renters"
          ? getState().auto.startForm
          : getState().home.start;

      if (!address || !address.state) return;
      const { data, status } = await Api.get(
        `/state-requirements?State=${address?.state || ""}`,
      );
      if (status === 200) {
        dispatch(homeActions.setCoverageInfo(data.home));
        dispatch(homeActions.setRentersCoverageInfo(data.renters));
        dispatch(autoActions.setCoverageInfo(data.auto));
        return data;
      } else {
        console.error({ data, status });
      }
    };
  },
  getWizardInfo(req_uid, utm_info = "", callback) {
    return async (dispatch) => {
      const { status, data } = await Api.get(
        `/quote-data/${req_uid}${utm_info}`,
      );
      if (status === 200) {
        await dispatch(actions.setWizard(data));
        if (data.quoteType === "auto") {
          await dispatch(autoActions.resetState());
          await dispatch(autoActions.setWizard(data));
        }
        if (data.quoteType === "life") {
          await dispatch(lifeActions.resetState());
          await dispatch(lifeActions.setWizard(data));
        }
        if (data.quoteType === "home" || data.quoteType === "renters") {
          await dispatch(homeActions.refreshHomeState());
          await dispatch(homeActions.setWizard(data));
        }
        if (data.quoteType === "bundle" || data.quoteType === "auto-renters") {
          await dispatch(autoActions.resetState());
          await dispatch(homeActions.refreshHomeState());
          await dispatch(autoActions.setWizard(data));
          await dispatch(homeActions.setWizard(data));
        }
        callback(data.agentQuoted);
      } else {
        uiActions.showError("We're unable to pull up your quote at this time.");
      }
    };
  },
  getWizardSettings() {
    return async (dispatch) => {
      const { data, status } = await Api.get(`/settings/wizard`);
      if (status === 200) {
        const settingsObj = arrayToEntityList(data, "internal_name").entities;
        dispatch(actions.setSettings(settingsObj));
      } else {
        dispatch(uiActions.showError("Failed to get settings"));
        console.error({ data, status });
      }
    };
  },
  initialChat(chat_id: string, chat_message: string) {
    return async (dispatch, getState) => {
      const { req_uid } = getState().wizard;
      const { mode } = getState().wizardNoPersist;

      if (!req_uid) {
        throw new Error("req_uid missing");
      }
      dispatch(uiActions.setLoading(true));
      try {
        const { data } = await Api.post(
          `/initial-chat-message/${chat_id}/quote/${req_uid}`,
          { chat_message, quote_type: mode },
        );
        dispatch(uiActions.setLoading(false));
        return data;
      } catch (e) {
        console.error("Couldn't send initial chat message", e);
        dispatch(uiActions.setLoading(false));
        throw e;
      }
    };
  },
  upgradeToBundle(mode) {
    return async (dispatch, getState) => {
      await dispatch(wizardActions.setBundleAndSave(mode));
      if (mode === "auto") {
        const { address, apt } = getState().auto.startForm;
        dispatch(homeActions.setStart({ address: { ...address }, ...apt }));
      }
    };
  },
};
