import React, { useEffect, useRef, useState } from "react";
import { Card, Typography, Box, Button, TextField } from "@mui/material";

import {
  adminActions,
  adminSelectors,
  authClient,
  authSelectors,
  uiActions,
  useDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, yup } from "../../../../lib";

import { useStyles } from "../AgencySettingsPage.styles";
import { EditIcon } from "../../../../components";
import { REACT_APP_ASSETS_URL } from "../../../../config";
import { useParams } from "react-router";
import { DropzoneArea } from "react-mui-dropzone";

const validationSchema = yup.object({
  bio_text: yup.string().max(4000, "Bio cannot exceed 4000 characters"),
});

const AboutCard = () => {
  const [inEditMode, setInEditMode] = useState(false);
  const [fileDropped, setFileDropped] = useState(false);
  const fileUploadRef = useRef<FormData>();

  const classes = useStyles();
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const activeAgencyId = params.id
    ? Number(params.id)
    : useSelector(authSelectors.activeAgencyId);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const activeAgencyData = useSelector(adminSelectors.activeAgencyData);

  async function uploadFile() {
    if (fileDropped) {
      const { status, data } = await authClient.post(
        "/file-upload",
        fileUploadRef.current,
      );
      if (status === 200) {
        return data.path;
      } else {
        dispatch(uiActions.showError("Bio image upload failed"));
      }
    }
    return "";
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      bio_image_path: "",
      bio_text: activeAgencyData.bio_text,
    },
    validationSchema,
    async onSubmit(values) {
      const new_path = await uploadFile();

      const agencyData = {
        ...activeAgencyData,
        bio_image_path: new_path || activeAgencyData.bio_image_path,
        bio_text: values.bio_text?.replace(/(?:\r\n|\r|\n)/g, "<br>"),
      };
      dispatch(
        adminActions.updateAgency(activeAgencyId, agencyData, isSuperAdmin),
      );
      setInEditMode(false);
      form.resetForm();
    },
  });

  const handleCancel = () => {
    setInEditMode(false);
    form.resetForm();
  };

  useEffect(() => {
    dispatch(adminActions.getAgency(activeAgencyId));
  }, [activeAgencyId]);

  const showEditButton = !inEditMode;

  if (!activeAgencyId) return null;

  const SectionTitle = ({ title }) => {
    return (
      <Box className={classes.sectionTitleWrapper}>
        <Typography className={classes.sectionTitle}>{title}</Typography>
        {showEditButton && (
          <Button
            className={classes.editBtnWrapper}
            onClick={() => setInEditMode(!inEditMode)}
          >
            <EditIcon style={{ color: "white" }} />
          </Button>
        )}
      </Box>
    );
  };

  const HTMLField = ({ fieldName, fieldValue }) => {
    return (
      <Box className={classes.fieldWrapper}>
        <Typography className={classes.fieldTitle}>{fieldName}</Typography>
        <Typography dangerouslySetInnerHTML={{ __html: fieldValue }} />
      </Box>
    );
  };

  async function handleFileDrop(file) {
    fileUploadRef.current = new FormData();
    fileUploadRef.current.append("file", file[0]);
    setFileDropped(true);
  }

  async function handleFileDelete() {
    fileUploadRef.current = new FormData();
    setFileDropped(false);
  }
  return (
    <>
      {inEditMode ? (
        <Card variant="outlined" className={classes.section}>
          <form onSubmit={form.handleSubmit}>
            <SectionTitle title={"About page"} />
            {activeAgencyData.bio_image_path && (
              <Box className={classes.textFieldWrapper}>
                <Typography className={classes.fieldTitle}>
                  Current image
                </Typography>
                <img
                  alt="preview image"
                  src={`${REACT_APP_ASSETS_URL}/${activeAgencyData.bio_image_path}`}
                  className={classes.previewImg}
                ></img>
              </Box>
            )}

            <Box className={classes.textFieldWrapper}>
              <Typography className={classes.fieldTitle}>
                Upload new image
              </Typography>
              <DropzoneArea
                showPreviews={true}
                filesLimit={1}
                acceptedFiles={[".png", ".jpg", ".jpeg", ".svg"]}
                maxFileSize={3000000}
                showPreviewsInDropzone={false}
                dropzoneText="Drop an image or click to browse"
                dropzoneClass={classes.dropzone}
                dropzoneParagraphClass={classes.dropzoneText}
                previewGridClasses={{
                  item: classes.dropzone,
                  image: classes.dropzoneImage,
                }}
                onDrop={(file) => handleFileDrop(file)}
                onDelete={() => handleFileDelete()}
              />
            </Box>

            <Box className={classes.textFieldWrapper}>
              <TextField
                minRows={10}
                id={"bio_text"}
                name={"bio_text"}
                label={"Bio"}
                variant="outlined"
                multiline
                value={form.values.bio_text}
                onChange={form.handleChange}
                error={Boolean(form.errors.bio_text)}
                helperText={form.errors.bio_text}
              />
            </Box>

            <Box className={classes.submit}>
              <Button
                variant="contained"
                onClick={() => handleCancel()}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitBtn}
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      ) : (
        <Card variant="outlined" className={classes.section}>
          <SectionTitle title={"About page"} />
          {activeAgencyData.bio_image_path && (
            <Box className={classes.imageWrapper}>
              <img
                src={`${REACT_APP_ASSETS_URL}/${activeAgencyData.bio_image_path}`}
                className={classes.previewImg}
              ></img>
            </Box>
          )}
          <HTMLField fieldName={"Bio"} fieldValue={activeAgencyData.bio_text} />
        </Card>
      )}
    </>
  );
};

export default AboutCard;
