import React, { useEffect, useState, useCallback } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  MenuItem,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import {
  adminActions,
  useDispatch,
  adminSelectors,
  useSelector,
  TurboraterCompany,
} from "../../state";
import { useFormik } from "../../lib";

import { useStyles } from "./AddCarrierPage.styles";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FileUpload } from "../../components";
import { REACT_APP_ASSETS_URL } from "../../config";

const initialValues = {
  agency_group: "",
  company_id: "",
  name: "",
  auto_home: "",
  is_auto: false,
  is_home: false,
  is_active: true,
  state: "",
  supports_no_prior_coverage: true,
  logo_path: "",
  all_states: false,
  id: "",
};
// const validationSchema = yup.object({
//   state: yup.string().required("State is required"),
//   company_id: yup.number().required("Company Id is required"),
// });

const AddCarrierPage = () => {
  const [allCarrierOptions, setAllCarrierOptions] = useState<
    TurboraterCompany[]
  >([]);
  const [existingLogos, setExistingLogos] = useState<Array<any>>([]);
  const [noLogoError, setNoLogoError] = useState(false);
  const [states, setStates] = useState<Array<string>>([]);
  const [existingCarriers, setExistingCarriers] = useState<Set<string>>(
    new Set(),
  );
  const [statesLoading, setStatesLoading] = useState(true);
  const [uploadedLogoPath, setUploadedLogoPath] = useState<
    undefined | string
  >();
  const classes = useStyles();
  const dispatch = useDispatch();
  const agencyGroups = useSelector(adminSelectors.agency_groups);
  const carriers = useSelector(adminSelectors.carriers);
  const inEditMode = window.location.pathname.includes("edit");

  const form = useFormik({
    initialValues,
    // validationSchema,
    async onSubmit(values) {
      if (!inEditMode) {
        await dispatch(adminActions.createCarrier(values));
      } else {
        await dispatch(
          adminActions.updateCarriers(
            values.id,
            "",
            {
              ...values,
              // Setting these just to fit with create carrier schema
              company_id: +values.company_id,
              agency_group: 3,
            },
            true,
          ),
        );
      }
    },
  });

  useEffect(() => {
    let id;
    let currentCarrier;
    if (inEditMode) {
      id = window.location.pathname.split("/")[3];
      currentCarrier = carriers.find((c) => c.id === +id);
      form.setFieldValue("company_id", currentCarrier?.company_id);
      form.setFieldValue("name", currentCarrier?.name);
      form.setFieldValue("id", currentCarrier?.id);
      form.setFieldValue("state", currentCarrier?.state);
      form.setFieldValue("is_auto", currentCarrier?.is_auto);
      form.setFieldValue("is_home", currentCarrier?.is_home);
      form.setFieldValue("is_active", undefined);
      if (!uploadedLogoPath) {
        form.setFieldValue("logo_path", currentCarrier?.logo_path);
      }
    }
  }, [carriers]);

  const getUnusedCarriers = () => {
    return allCarrierOptions.filter(
      (c) => !existingCarriers.has(c.CompanyName),
    );
  };
  const handleSelectedCarrier = (e, company) => {
    form.setFieldValue("company_id", company.CompanyID);
    form.setFieldValue("name", company.CompanyName);
  };

  useEffect(() => {
    (async () => {
      setStatesLoading(true);
      dispatch(adminActions.getAgencyGroups());
      const supportedStates = await adminActions.getOnlyStates();
      setStates(supportedStates);
      setStatesLoading(false);
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const existingLogos = await adminActions.getLogoPerCarrier();
      setExistingLogos(existingLogos);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (inEditMode) return;
    if (form.values.company_id) {
      const match = existingLogos.filter(
        (logo) => logo.name.toLowerCase() === form.values.name.toLowerCase(),
      );
      if (match.length && match[0].logo_path !== null) {
        setNoLogoError(false);
        form.setFieldValue("logo_path", match[0].logo_path);
      } else if (form.values.logo_path) {
        setNoLogoError(false);
      } else {
        setNoLogoError(true);
      }
    }
  }, [form.values.company_id, form.values.name, existingLogos, noLogoError]);

  const handleSwitcherChange = useCallback(
    () => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) {
        if (value === "auto") {
          form.setFieldValue("auto_home", "auto");
          form.setFieldValue("is_auto", true);
          form.setFieldValue("is_home", false);
        } else {
          form.setFieldValue("auto_home", "home");
          form.setFieldValue("is_home", true);
          form.setFieldValue("is_auto", false);
        }
        form.setFieldValue("state", "");
      }
    },
    [form],
  );

  useEffect(() => {
    if (inEditMode) return;
    (async () => {
      if (form.values.state) {
        form.setFieldValue("company_id", "");
        const results: any = await dispatch(
          adminActions.getCarrierOptions(
            form.values.state,
            form.values.auto_home,
            form.values.agency_group,
          ),
        );
        const existingCarriers = await adminActions.getCarriersPerState(
          form.values.agency_group,
          form.values.state,
          form.values.auto_home,
        );

        setAllCarrierOptions(results);
        setExistingCarriers(new Set(existingCarriers));
      }
    })();
    // eslint-disable-next-line
  }, [form.values.state]);

  useEffect(() => {
    form.setFieldValue("logo_path", uploadedLogoPath);
    setNoLogoError(false);
  }, [uploadedLogoPath]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <form onSubmit={form.handleSubmit}>
          {!inEditMode && (
            <>
              <Grid container>
                <Grid item xs={11} className={classes.formControl}>
                  <TextField
                    select
                    name="agency_group"
                    label="Agency Group"
                    variant="outlined"
                    value={form.values.agency_group}
                    onChange={form.handleChange}
                    error={
                      form.touched.agency_group &&
                      Boolean(form.errors.agency_group)
                    }
                    helperText={
                      form.touched.agency_group && form.errors.agency_group
                    }
                  >
                    {agencyGroups.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={11} className={classes.formField}>
                  <ToggleButtonGroup
                    className={classes.switcherGroup}
                    exclusive
                    size="large"
                    value={form.values.auto_home}
                    onChange={handleSwitcherChange()}
                  >
                    <ToggleButton value={"auto"} className={classes.switcher}>
                      Auto
                    </ToggleButton>
                    <ToggleButton value={"home"} className={classes.switcher}>
                      Home
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={11} className={classes.formControl}>
                  <TextField
                    select
                    id="state"
                    name="state"
                    label="State"
                    variant="outlined"
                    value={form.values.state}
                    disabled={
                      statesLoading ||
                      !form.values.auto_home ||
                      !form.values.agency_group
                    }
                    InputProps={{
                      endAdornment: statesLoading ? (
                        <CircularProgress
                          color="primary"
                          size={22}
                          className={classes.selectLoader}
                        />
                      ) : null,
                    }}
                    onChange={form.handleChange}
                    error={form.touched.state && Boolean(form.errors.state)}
                    helperText={form.touched.state && form.errors.state}
                  >
                    {states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.values.all_states}
                        onChange={form.handleChange}
                        name="all_states"
                        color="primary"
                      />
                    }
                    label="Add to all states"
                  />
                </Grid>

                <Grid item xs={11} className={classes.formControl}>
                  <Autocomplete
                    fullWidth
                    className={classes.formInput}
                    options={getUnusedCarriers()}
                    onChange={(e, v) => {
                      handleSelectedCarrier(e, v);
                    }}
                    disabled={!form.values.state}
                    isOptionEqualToValue={(option, value) =>
                      option.CompanyName === value.CompanyName
                    }
                    getOptionLabel={(option) => option.CompanyName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Carriers"
                        variant="outlined"
                      />
                    )}
                  />
                  {form.values.state && !getUnusedCarriers().length && (
                    <Typography className={classes.note}>
                      No carriers for selected State
                    </Typography>
                  )}

                  {form.values.company_id && (
                    <>
                      <Typography className={classes.note}>
                        {noLogoError
                          ? "Logo is required to add selected carrier. Please upload logo below."
                          : "Logo for selected carrier is on file. To update the logo, upload new logo below."}
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          {inEditMode && (
            <Grid item xs={11} className={classes.formControl}>
              <TextField
                name="name"
                label="Company name"
                variant="outlined"
                value={form.values.name}
                onChange={form.handleChange}
              ></TextField>
            </Grid>
          )}
          {inEditMode && (
            <Grid item xs={11} className={classes.formControl}>
              <TextField
                name="company_id"
                label="Company id"
                variant="outlined"
                value={form.values.company_id}
                onChange={form.handleChange}
              ></TextField>
            </Grid>
          )}
          <Grid container>
            <Grid item xs={11} className={classes.formControl}>
              <FileUpload
                label="Carrier Logo"
                successMsg="Logo uploaded"
                path={`/file-upload`}
                showSaveBtn={true}
                fileNameCB={setUploadedLogoPath}
              />
            </Grid>
            <Grid item xs={11} className={classes.formControl}>
              {(inEditMode || uploadedLogoPath) && (
                <img
                  style={{ maxWidth: "200px" }}
                  src={`${REACT_APP_ASSETS_URL}/${
                    uploadedLogoPath || form.values.logo_path
                  }`}
                  alt="uploaded-logo"
                />
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={
                  // form.isSubmitting ||
                  (!inEditMode && !form.values.state) ||
                  !form.values.company_id ||
                  !!noLogoError
                }
              >
                {inEditMode ? "Update" : "Save"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddCarrierPage;
