//import * as CustomerSDK from "@livechat/customer-sdk";
import {
  // Avatar,
  Box,
  Button,
  Card,
  // IconButton,
  // Input,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
// import { formatDistanceToNow } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiberManualRecordIcon } from "../../components/icons";
// import {
//   REACT_APP_CHAT_GROUP_ID,
//   REACT_APP_SNAG_CHAT_GROUP_ID,
// } from "../../config";
// import { arrayToEntityList } from "../../lib";
import {
  autoSelectors,
  homeSelectors,
  wizardActions,
  wizardNoPersistSelectors,
  wizardSelectors,
} from "../../state";
import { useStyles } from "./ChatWidget.styles";
import {
  Event,
  // ChatUser,
  Chat,
} from "../../state";
import ReactGA from "react-ga";
// import {
// activeChat_agent,
// activeChat_snag,
// customerSDK_agentChat,
// customerSDK_snagChat,
// createTicket,
// } from "../../utils/liveChat";
import {
  formatCurrency,
  formatTitleCase,
  getInsuranceLevelTitle,
} from "../../utils";

// let checkIfChatActive;

export default function ChatWidget({ agentChat }) {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const contactInfo = useSelector(wizardSelectors.contactInfo);
  const applicantFirstName = useSelector(wizardSelectors.applicantFirstName);
  // const fullName = useSelector(autoSelectors.fullName);
  const homeSelectedQuote = useSelector(homeSelectors.selectedQuote);
  const autoSelectedQuote = useSelector(autoSelectors.selectedQuote);
  const mode = useSelector(wizardSelectors.chatMode);
  const isSubmitted = useSelector(wizardSelectors.offlineChatSubmitted);
  const prefContactMethod = useSelector(wizardSelectors.prefContactMethod);
  const wizardMode = useSelector(wizardNoPersistSelectors.mode);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const offlineMessagesEndRef = useRef<HTMLDivElement>(null);
  const [chat, _setChat] = useState<Chat | undefined>();
  const [events, _setEvents] = useState<Event[]>([]);
  const [offlineChats] = useState<string[]>([]);
  // const [systemMessage, setSystemMessage] = useState<string | undefined>();
  // const [users, setUsers] = useState<any>();
  // const [customer, setCustomer] = useState<ChatUser | undefined>();
  // const [chatInputText, setChatInputText] = useState("");
  const [userEmail, setUserEmail] = useState(contactInfo?.email || "");
  const [userPhone, setUserPhone] = useState(contactInfo?.phone || "");
  const [showUserInputError] = useState(false);

  // const customerSDK = agentChat ? customerSDK_agentChat : customerSDK_snagChat;
  // const activeChat = agentChat ? activeChat_agent : activeChat_snag;

  // const eventsRef = useRef(events);
  // const chatRef = useRef(chat);
  let fieldId = 0;
  const getFieldId = () => {
    const id = fieldId;
    fieldId += 1;
    return id;
  };

  const firstName = applicantFirstName.replace(
    /\w\S*/g,
    formatTitleCase(applicantFirstName),
  );

  const quoteSummary = () => `Hi ${firstName}.
    Thank you for choosing 5 Minute Insure!
    You have selected the ${
      wizardMode === "bundle"
        ? `auto ${getInsuranceLevelTitle(
            autoSelectedQuote.insurance_level_id,
          ).toLowerCase()} with ${
            autoSelectedQuote.carrier
          } at ${formatCurrency(
            autoSelectedQuote.PayPlanDownPayment,
          )}  due today & total premium at ${formatCurrency(
            autoSelectedQuote.TotalPremium,
          )} and the home ${getInsuranceLevelTitle(
            homeSelectedQuote.insurance_level_id,
          ).toLowerCase()} with ${homeSelectedQuote.carrier} at
      ${formatCurrency(
        homeSelectedQuote.TotalPremium,
      )}. An agent will be with you in a moment to review and bind your quote.`
        : wizardMode === "home"
        ? `${getInsuranceLevelTitle(
            homeSelectedQuote.insurance_level_id,
          ).toLowerCase()} with ${
            homeSelectedQuote.carrier
          } at ${formatCurrency(
            homeSelectedQuote.TotalPremium,
          )}. An agent will be with you in a moment to review and bind your quote.`
        : `${getInsuranceLevelTitle(
            autoSelectedQuote.insurance_level_id,
          ).toLowerCase()} with ${
            autoSelectedQuote.carrier
          } at ${formatCurrency(autoSelectedQuote.PayPlanDownPayment)}
      due today & total premium at ${formatCurrency(
        autoSelectedQuote.TotalPremium,
      )}.
      An agent will be with you in a moment to review the driving and claim history for all drivers and vehicles
      and bind your quote.`
    }`;

  const submitOfflineTicket = () => {
    const fields = [
      {
        type: "subject",
        id: getFieldId(),
        label: "Subject: ",
        answer: agentChat ? "Finish Quote" : "Hit a snag",
      },
      {
        type: "email",
        id: getFieldId(),
        label: "Customer email: ",
        answer: userEmail || "test@test.com", //this is required but doesn't get added to ticket
      },
      {
        type: "textarea",
        id: getFieldId(),
        label: "Coverage Summary: ",
        answer: agentChat ? quoteSummary() : "",
      },
      {
        type: "textarea",
        id: getFieldId(),
        label: "Preferred Method of contact: ",
        answer: prefContactMethod,
      },
      {
        type: "textarea",
        id: getFieldId(),
        label: "Phone: ",
        answer: userPhone,
      },
      {
        type: "textarea",
        id: getFieldId(),
        label: "Email: ",
        answer: userEmail,
      },
      {
        type: "textarea",
        id: getFieldId(),
        label: `Quote ID:`,
        answer:
          wizardMode === "home"
            ? homeSelectedQuote.turbo_quote_id
            : autoSelectedQuote.turbo_quote_id,
      },
    ];
    offlineChats.forEach((m, i) => {
      fields.push({
        type: "textarea",
        id: getFieldId(),
        label: `Customer message (${i + 1}): `,
        answer: m,
      });
    });
    // createTicket(
    //   customerSDK,
    //   agentChat ? REACT_APP_CHAT_GROUP_ID : REACT_APP_SNAG_CHAT_GROUP_ID,
    //   fields,
    // );
  };
  const handleSubmit = () => {
    dispatch(wizardActions.setOfflineChatSubmitted(true));
    submitOfflineTicket();
  };

  const handleContactMethodChange = (method: string) => {
    dispatch(wizardActions.setPrefContactMethod(method));
  };

  // const setEvents = (event) => {
  //   _setEvents(event);
  //   eventsRef.current = event;
  // };
  // const setChat = (chat) => {
  //   _setChat(chat);
  //   chatRef.current = chat;
  // };
  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
    if (offlineMessagesEndRef && offlineMessagesEndRef.current) {
      offlineMessagesEndRef.current.scrollTop =
        offlineMessagesEndRef.current.scrollHeight;
    }
    const element_offline = document.getElementById("lastMsg");
    element_offline && element_offline.scrollIntoView({ behavior: "smooth" });

    const element_online = document.getElementById("sendMsgBox");
    element_online && element_online.scrollIntoView({ behavior: "smooth" });
  };

  // const updateChat = useCallback(async () => {
  //   if (chatRef.current !== undefined) {
  //     const updatedChat = await customerSDK.getChat({
  //       chatId: chatRef.current.activeChatId,
  //     });
  //     setChat(updatedChat);
  //   }
  //   //eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (chat?.thread?.queue === null) {
      ReactGA.event({
        category: "Chat",
        action: "Agent Joined Chat",
      });
    }
  }, [chat?.thread?.queue]);

  const initialChatMsg = agentChat
    ? quoteSummary()
    : "Hey, it looks like you got stuck. Hang in there. An agent will help you shortly.";

  React.useEffect(() => {
    scrollToBottom();
  }, [events, offlineChats, isSubmitted]);

  // React.useEffect(() => {
  //   (async () => {
  //     checkIfChatActive = setInterval(async () => {
  //       try {
  //         if (activeChat && !chat && activeChat.availability === "online") {
  //           let localChat: Chat = {
  //             availability: activeChat.availability,
  //             customer: activeChat.customer,
  //             //greeting: activeChat.greeting, We are not using for now
  //           };
  //           const { chatsSummary } = await customerSDK.listChats({});
  //           if (chatsSummary.length) {
  //             //Current livechat rules 1 chat per customer
  //             const activeChat = chatsSummary[0];
  //             const chatId = activeChat.id;
  //             if (activeChat.active) {
  //               localChat = await customerSDK.getChat({
  //                 chatId,
  //               });
  //             } else {
  //               localChat = await customerSDK
  //                 .resumeChat({
  //                   chat: {
  //                     id: chatId,
  //                     thread: {
  //                       events: [],
  //                     },
  //                   },
  //                 })
  //                 .catch((e) => {
  //                   if (e.message === "Groups offline") {
  //                     console.error(
  //                       "Group is offline. Not sure why we got here.",
  //                       //TODO: handle this as offline mode
  //                       //I think it happens when agent just turned off availability and chat not fully updated (RP)
  //                     );
  //                   } else {
  //                     throw e;
  //                   }
  //                 });
  //             }
  //             localChat.activeChatId = chatId;
  //           } else {
  //             localChat = await customerSDK.startChat({
  //               chat: {
  //                 thread: {
  //                   events: [],
  //                 },
  //               },
  //             });
  //           }
  //           const { id, users, thread } = localChat;
  //           if (!thread || !users || !id) {
  //             console.error("failed to start chat");
  //             return;
  //           }
  //           //eslint-disable-next-line
  //           if (!!agentChat) {
  //             await dispatch(wizardActions.initialChat(id, quoteSummary()));
  //           }
  //           const custProperties = {
  //             name: fullName,
  //             email: userEmail,
  //             sessionFields: {
  //               turbo_quote_id: !agentChat
  //                 ? ""
  //                 : wizardMode === "home"
  //                 ? homeSelectedQuote.turbo_quote_id
  //                 : autoSelectedQuote.turbo_quote_id,
  //             },
  //           };
  //           customerSDK.updateCustomer(custProperties);
  //           localChat.thread = thread;
  //           localChat.users = users;
  //           setCustomer(activeChat.customer);
  //           setEvents(thread?.events);
  //           setUsers(arrayToEntityList(users));
  //           setChat(localChat);
  //           clearInterval(checkIfChatActive);
  //           dispatch(wizardActions.setChatMode("online"));
  //         } else if (activeChat?.availability === "offline") {
  //           clearInterval(checkIfChatActive);
  //           dispatch(wizardActions.setChatMode("offline"));
  //           ReactGA.event({
  //             category: "Chat",
  //             action: "Chat is Offline",
  //           });
  //         } else {
  //           console.error("something wrong with chat", chat);
  //         }
  //       } catch (e) {
  //         console.error(e);
  //         clearInterval(checkIfChatActive);
  //       }
  //     }, 1000);
  //   })();
  //   return () => {
  //     clearInterval(checkIfChatActive);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   customerSDK.on("user_added_to_chat", (payload) => {
  //     const { chatId } = payload;
  //     setTimeout(async () => {
  //       const updatedChat = await customerSDK.getChat({
  //         chatId: chatId,
  //       });
  //       setChat(updatedChat);
  //     }, 0);
  //   });

  //   customerSDK.on("incoming_event", (payload) => {
  //     const { event } = payload;
  //     const isInQueue = !!chatRef.current?.thread?.queue;
  //     const eventIdsSet = new Set(eventsRef.current.map((e) => e.id));

  //     switch (event.type) {
  //       case "message":
  //         if (isInQueue) {
  //           updateChat();
  //         }
  //         if (!eventIdsSet.has(event.id)) {
  //           const _events = [...eventsRef.current, event];
  //           //TODO: sanitize duplicates
  //           setEvents(_events);
  //         } else {
  //           console.error("duplicate event caught");
  //         }

  //         break;

  //       case "system_message":
  //         if (event.systemMessageType === "manual_archived_agent") {
  //           setSystemMessage("Your chat has been closed by the agent.");
  //         }
  //         console.error("unhandled system_message", event);

  //         break;
  //       default:
  //         console.warn("unhandled-event", event);

  //         break;
  //     }
  //   });
  //   customerSDK.on("queue_position_updated", (payload) => {
  //     setTimeout(async () => {
  //       const updatedChat = await customerSDK.getChat({
  //         chatId: payload.chatId,
  //       });
  //       setChat(updatedChat);
  //     }, 0);
  //   });
  //   return () => {
  //     customerSDK.off("incoming_event");
  //     customerSDK.off("queue_position_updated");
  //     customerSDK.off("user_added_to_chat");
  //   };
  //   //eslint-disable-next-line
  // }, [updateChat]);

  // const getFName = (name) => {
  //   const nameArray = name?.split(" ");
  //   if (nameArray?.length) {
  //     return nameArray[0];
  //   } else {
  //     return "";
  //   }
  // };

  const offlineMessage = agentChat
    ? `finalize your purchase?`
    : `help you resolve this issue?`;

  // const handleSendClick = async () => {
  //   if (chat && chat?.thread?.active) {
  //     const response = await customerSDK.sendEvent({
  //       chatId: chat.thread.chatId,
  //       event: {
  //         type: "message",
  //         text: chatInputText,
  //       },
  //     });
  //     setChatInputText("");
  //     const _events = [...events, response];
  //     setEvents(_events);
  //     ReactGA.event({
  //       category: "Chat",
  //       action: "User Interacted On Chat",
  //     });
  //   } else {
  //     throw new Error("cant send message before we have an active chat");
  //   }
  // };

  // const ticketMessageClick = async () => {
  //   const _offlineChats = [...offlineChats];
  //   _offlineChats.push(chatInputText);

  //   setOfflineChats(_offlineChats);
  //   setChatInputText("");
  // };

  // const onKeyUp = (e) => {
  //   if (e.keyCode === 13) {
  //     handleSendClick();
  //   }
  // };

  // const offlineOnKeyUp = (e) => {
  //   if (e.keyCode === 13) {
  //     ticketMessageClick();
  //   }
  // };

  // let currChatAuthorId = "";
  // let prevChatAuthorId = "";

  return (
    <Card className={classes.card} variant="outlined">
      {mode === "online" ? (
        <>
          <Box className={classes.cardHeader}>
            <Box className={classes.chatWithUsBox}>
              <Typography className={classes.chatHeading}>
                Chat with us
              </Typography>
              <Typography className={classes.onlineStatus}>
                <FiberManualRecordIcon
                  htmlColor="#2ED272"
                  style={{
                    fontSize: 10,
                    marginRight: 4,
                  }}
                />
                We're online!
              </Typography>
            </Box>
            {!!chat?.thread?.queue && (
              <Box className={classes.queueMsg}>
                {/* <div>
                  You are currently number {chat?.thread?.queue.position} in the
                  queue.
                </div> */}
                {chat && chat.thread && (
                  <div>
                    An agent will be here momentarily. Your estimated wait time
                    is less than 1 minute.
                    {/* {(chat?.thread?.queue.waitTime / 60).toFixed(0)} minutes. */}
                  </div>
                )}
              </Box>
            )}
          </Box>
          <Box className={classes.agentChatWrapper}>
            <Box className={classes.agentChatBlurb}>{initialChatMsg}</Box>
          </Box>
          {/* <div ref={messagesEndRef} className={classes.threadSection}>
            {events.map((e: Event) => {
              prevChatAuthorId = currChatAuthorId;
              currChatAuthorId = e.authorId;
              return (
                <Box
                  className={
                    e.authorId === customer?.id
                      ? classes.customerChatWrapper
                      : classes.agentChatWrapper
                  }
                  key={e.id}
                >
                  {prevChatAuthorId !== currChatAuthorId && (
                    <div
                      className={
                        e.authorId === customer?.id
                          ? classes.customerMessageTime
                          : classes.agentMessageTime
                      }
                    >
                      {e.authorId !== customer?.id &&
                        users &&
                        users?.entities[e.authorId] && (
                          <Avatar
                            className={classes.avatar}
                            alt={users?.entities[e.authorId].name}
                            src={users?.entities[e.authorId].avatar}
                          />
                        )}
                      {users?.entities[e.authorId] &&
                        e.authorId !== customer?.id && (
                          <>
                            <div>
                              {getFName(users?.entities[e.authorId].name)}
                            </div>
                            <div>&#183;</div>
                          </>
                        )}
                      <div>
                        {formatDistanceToNow(new Date(e.createdAt))} ago
                      </div>
                    </div>
                  )}
                  <Box
                    className={
                      e.authorId === customer?.id
                        ? classes.customerChatBlurb
                        : classes.agentChatBlurb
                    }
                  >
                    {e.text}
                  </Box>
                </Box>
              );
            })}
          </div> */}

          {chat?.thread?.active && (
            <Box className={classes.messageInputBox}>
              {/* {!systemMessage ? (
                <Input
                  classes={{ root: classes.messageInput }}
                  id={"sendMsgBox"}
                  fullWidth
                  placeholder="Send a message"
                  disableUnderline
                  value={chatInputText}
                  onChange={(e) => setChatInputText(e.target.value)}
                  // onKeyUp={onKeyUp}
                  // endAdornment={
                  //   <IconButton onClick={handleSendClick} size="large">
                  //     <SendIcon />
                  //   </IconButton>
                  // }
                />
              ) : (
                <span>{systemMessage}</span>
              )} */}
            </Box>
          )}
        </>
      ) : (
        <Box
          display={mode !== "offline" ? "flex" : "block"}
          alignItems="center"
          justifyContent="center"
          className={classes.offlineChatBoxContainer}
        >
          {mode === "offline" ? (
            <>
              <Box className={classes.offlineHeader}>
                <FiberManualRecordIcon
                  htmlColor="#8D98C2"
                  style={{
                    fontSize: 10,
                    marginRight: 4,
                  }}
                />
                <Box>We’re offline</Box>
              </Box>
              <div ref={offlineMessagesEndRef} className={classes.offlineBox}>
                <Box className={classes.agentChatWrapper}>
                  <Box className={classes.agentChatBlurb}>
                    Hey, looks like our agents are not available at the moment.
                    How should one of our licensed agents contact you to{" "}
                    {offlineMessage}
                  </Box>
                </Box>
                {!isSubmitted && (
                  <Box className={classes.agentChatWrapper}>
                    <Box className={classes.buttonWrapper}>
                      <Button
                        onClick={() => {
                          handleContactMethodChange("call");
                        }}
                        variant="outlined"
                      >
                        Call
                      </Button>
                      <Button
                        onClick={() => {
                          handleContactMethodChange("sms");
                        }}
                        variant="outlined"
                      >
                        SMS
                      </Button>
                      <Button
                        onClick={() => {
                          handleContactMethodChange("email");
                        }}
                        variant="outlined"
                      >
                        Email
                      </Button>
                    </Box>
                  </Box>
                )}
                {!!prefContactMethod && (
                  <>
                    <Box className={classes.agentChatWrapper}>
                      <Box className={classes.agentChatBlurb}>
                        {prefContactMethod === "email" ? (
                          <>
                            Please {agentChat ? "confirm " : "enter "} your
                            email address
                            <TextField
                              style={{
                                backgroundColor: "white",
                                marginBlock: 7,
                              }}
                              id="email"
                              type="email"
                              name="email"
                              variant="outlined"
                              label={"email address"}
                              value={userEmail}
                              disabled={!!isSubmitted}
                              onChange={(e) => {
                                setUserEmail(e.target.value);
                              }}
                              error={showUserInputError}
                            ></TextField>
                          </>
                        ) : (
                          <>
                            Please {agentChat ? "confirm " : "enter "} your
                            phone number
                            <TextField
                              style={{
                                backgroundColor: "white",
                                marginBlock: 7,
                              }}
                              id="userInput"
                              name="userInput"
                              variant="outlined"
                              label={"phone number"}
                              value={userPhone}
                              disabled={!!isSubmitted}
                              onChange={(e) => {
                                setUserPhone(e.target.value);
                              }}
                              error={showUserInputError}
                            ></TextField>
                          </>
                        )}
                      </Box>
                    </Box>
                    {!isSubmitted && (
                      <Box className={classes.agentChatWrapper}>
                        <Box className={classes.buttonWrapper}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={handleSubmit}
                            //TODO: validate email & phone
                          >
                            Submit
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </>
                )}
                {!!prefContactMethod && !!isSubmitted && (
                  <>
                    <Box className={classes.agentChatWrapper}>
                      <Box className={classes.agentChatBlurb}>
                        Great! We will{" "}
                        {prefContactMethod === "email"
                          ? "email"
                          : prefContactMethod === "sms"
                          ? "text"
                          : "call"}{" "}
                        you at
                        <br />
                        {prefContactMethod === "email" ? userEmail : userPhone}
                      </Box>
                    </Box>
                    {/* <Box className={classes.agentChatWrapper}>
                      <Box className={classes.agentChatBlurb}>
                        Do you have any questions before you go?
                      </Box>
                    </Box>
                    {!readyToSubmit && !hasQuestion && (
                      <Box className={classes.agentChatWrapper}>
                        <Box className={classes.buttonWrapper}>
                          <Button
                            onClick={handleHaveQuestion}
                            variant="outlined"
                          >
                            Yes, I do
                          </Button>
                          <Button
                            onClick={handleReadyToSubmit}
                            variant="outlined"
                          >
                            I'm good
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {hasQuestion && (
                      <Box className={classes.agentChatWrapper}>
                        <Box className={classes.agentChatBlurb}>
                          What are your questions?
                        </Box>
                      </Box>
                    )}
                    {offlineChats.map((s, i) => (
                      <Box key={i} className={classes.customerChatWrapper}>
                        <Box className={classes.customerChatBlurb}>{s}</Box>
                      </Box>
                    ))} */}
                    {/* {!readyToSubmit && hasQuestion && (
                      <Box className={classes.agentChatWrapper}>
                        <Box className={classes.buttonWrapper}>
                          <Button
                            onClick={handleReadyToSubmit}
                            variant="outlined"
                          >
                            I'm done
                          </Button>
                        </Box>
                      </Box>
                    )} */}
                    {isSubmitted && (
                      <Box id={"lastMsg"} className={classes.agentChatWrapper}>
                        <Box className={classes.agentChatBlurb}>
                          Hang tight, we’ll be back with you soon. You can
                          expect a reply in 1 business day so, keep an eye on
                          your inbox. Thanks for choosing 5 Minute Insure. Have
                          a great day!
                        </Box>
                      </Box>
                    )}
                    {/* {!readyToSubmit && hasQuestion && (
                      <Box className={classes.messageInputBox}>
                        <Input
                          classes={{ root: classes.messageInput }}
                          fullWidth
                          placeholder="Send a message"
                          disableUnderline
                          value={chatInputText}
                          onChange={(e) => setChatInputText(e.target.value)}
                          onKeyUp={offlineOnKeyUp}
                          endAdornment={
                            <IconButton onClick={ticketMessageClick}>
                              <SendIcon />
                            </IconButton>
                          }
                        />
                      </Box>
                    )} */}
                  </>
                )}
                <br></br>
              </div>
            </>
          ) : (
            <CircularProgress color="inherit" />
          )}
        </Box>
      )}
    </Card>
  );
}
