import React, { useRef, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { authClient, uiActions, useDispatch } from "../../../state";

// eslint-disable-next-line no-unused-vars
type CallBack = (name: string) => void;

interface Props {
  path: string;
  showSaveBtn?: boolean;
  label: string;
  successMsg: string;
  redirectUrl?: string;
  fileNameCB?: CallBack;
}

export const FileUpload = ({
  path,
  showSaveBtn = false,
  label,
  successMsg,
  redirectUrl,
  fileNameCB,
}: Props) => {
  const dispatch = useDispatch();
  const [imageUploaded, setImageUploaded] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const fdRef = useRef<FormData>();

  const uploadFile = async () => {
    const { status, data } = await authClient.post(path, fdRef.current);
    if (status === 200) {
      if (fileNameCB) {
        fileNameCB(data.path);
      }
      setImageUploaded(true);
      dispatch(uiActions.showSuccess(successMsg));
    } else {
      dispatch(uiActions.showError("Upload failed"));
    }
  };

  const onFileSelected = async (e) => {
    setFileSelected(true);
    const {
      target: {
        validity,
        files: [file],
      },
    } = e;
    fdRef.current = new FormData();
    fdRef.current.append("file", file);

    if (validity.valid && !showSaveBtn) {
      uploadFile();
    }
  };

  return (
    <div>
      <div>
        <Typography style={{ marginBottom: 10 }}>{label}</Typography>
      </div>
      <TextField
        type="file"
        onChange={onFileSelected}
        inputProps={{
          accept: ".jpg,.jpeg,.png,.gif,.svg",
        }}
      />
      {showSaveBtn && (
        <Button
          fullWidth
          style={{ marginTop: 10 }}
          color="primary"
          variant="contained"
          disabled={!fileSelected || imageUploaded}
          onClick={(e) => {
            uploadFile(); //should we check validity.valid here? - RP
            if (redirectUrl) {
              window.location.replace(redirectUrl);
            }
            e.preventDefault();
          }}
        >
          Upload
        </Button>
      )}
    </div>
  );
};
