import React, { useCallback } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography,
} from "@mui/material";

import {
  useAction,
  useSelector,
  autoActions,
  autoSelectors,
} from "../../../../../state";
import { UDDVehiclesShape } from "../../../../../state/auto/types";

import { useStyles } from "./VehiclesList.styles";

const VehiclesList = () => {
  const classes = useStyles();
  const setSelectedVehicles = useAction(autoActions.setSelectedVehicles);
  const vehicles = useSelector(autoSelectors.uddVehicles);
  const selectedVehicles = useSelector(autoSelectors.selectedVehicles);
  const setVehiclesForm = useAction(autoActions.setVehiclesForm);
  const vehicleIsChecked = (vin) => {
    const v = selectedVehicles?.find((v) => v.VIN === vin);
    return !!v;
  };
  const handleToggle = useCallback(
    (value: UDDVehiclesShape) => () => {
      //handle for unselecting other vehicles
      const userEnteredVehicle = !!selectedVehicles.find((v) => v.userEntered);
      if (userEnteredVehicle && !value.VIN) {
        setSelectedVehicles(selectedVehicles.filter((v) => !v.userEntered));
        return;
      }
      //Is in list and should be toggled off
      const isInList = !!selectedVehicles?.find((v) => v.VIN === value.VIN);
      if (isInList) {
        const newList = selectedVehicles?.filter((v) => v.VIN !== value.VIN);
        setSelectedVehicles(newList);
        return;
      }

      //not in list Add to list
      setSelectedVehicles([...selectedVehicles, value]);
    },
    [selectedVehicles],
  );
  const handleNextClick = useCallback(() => {
    setVehiclesForm({
      selectedVehicles: selectedVehicles,
      submittedVehicles: [],
    });
  }, [setVehiclesForm]);

  return (
    <>
      <List className={classes.root}>
        {vehicles?.map((vehicle, i) => (
          <ListItem
            key={`vehicle-${vehicle.VIN || i}`}
            component={Paper}
            onClick={handleToggle(vehicle)}
            className={classes.item}
          >
            <ListItemIcon className={classes.checkboxWrap}>
              <Checkbox
                edge="start"
                className={`vehicle-${i}`}
                checked={vehicleIsChecked(vehicle.VIN)}
                disableRipple
                color="primary"
              />
            </ListItemIcon>
            <Box className={classes.content}>
              <Typography style={{ fontWeight: 500 }}>
                {vehicle.ModelYear} {vehicle.Maker} {vehicle.Model}
              </Typography>
              <Typography className={classes.vin}>{vehicle.VIN}</Typography>
            </Box>
          </ListItem>
        ))}
        <ListItem
          component={Paper}
          onClick={handleToggle({})}
          className={classes.item}
        >
          <ListItemIcon className={classes.checkboxWrap}>
            <Checkbox
              edge="start"
              checked={!!selectedVehicles.find((v) => v.userEntered)}
              disableRipple
              color="primary"
              onClick={handleToggle({})}
            />
          </ListItemIcon>
          <Box className={classes.content}>
            <Typography style={{ fontWeight: 500 }}>
              Other vehicle(s) not listed here
            </Typography>
          </Box>
        </ListItem>
      </List>
      <Grid container justifyContent="center" alignItems="center">
        <Button
          id="keep-moving-btn"
          className="keep-moving-btn"
          variant="contained"
          color="primary"
          disabled={!selectedVehicles?.length}
          onClick={handleNextClick}
        >
          Let's keep moving
        </Button>
      </Grid>
    </>
  );
};

export default React.memo(VehiclesList);
