import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  OutlinedInput,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Divider,
  FormHelperText,
} from "@mui/material";
import { InfoIcon, Tooltip } from "../../../../components/icons";

import { ToggleButtonGroup, ToggleButton } from "@mui/material";
// import { useFormik } from "formik";
import {
  authSelectors,
  homeActions,
  homeSelectors,
  useAction,
  useSelector,
  wizardNoPersistSelectors,
} from "../../../../state";
import {
  formatDwellingCurrency,
  homeDiscountsSchema as validationSchema,
} from "../../../../utils";
import { Form, Navigation, useFormik } from "../../../../lib";
import { CLAIM_DESCRIPTIONS } from "../../../../state/home/types";
import { AddIcon, DeleteIcon } from "../../../../components";
import { CLAIM_MIN_DATE } from "../../../../constants";
import ScrollToOnError from "../../../../components/forms/ScrollToOnError";
import { useMobile } from "../../../../themes";
import { useStyles } from "./Discounts.styles";
import { DatePicker } from "@mui/x-date-pickers";

export default function DiscountsForm() {
  const classes = useStyles();
  const isMobile = useMobile();
  const details = useSelector(homeSelectors.details);
  const discounts = useSelector(homeSelectors.discounts);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [manualEntry, setManualEntry] = useState<number>();
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const setDiscounts = useAction(homeActions.setDiscounts);
  const setHomeDetails = useAction(homeActions.setHomeDetails);
  const isRenters = mode === "renters" || mode === "auto-renters";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      updatedDwellingAmount: details.DwellingAmt,
      constructionLevel: "",
      discounts: {
        smokeDetectors: false,
        deadbolts: false,
        burglarAlarm: false,
        sprinklers: false,
        fireAlarm: false,
        fireExtinguishers: false,
        paperless: true,
      },
      mortgageOrLoan: true,
      hasClaims: false,
      claims: [],
      policyCanceledLastFive: false,
      dangerousAnimals: false,
      mode,
      ...discounts,
      ...details,
    },
    validationSchema: validationSchema,
    validateOnChange: formSubmitted,
    async onSubmit(values) {
      // TODO: create this action
      if (
        (!details.DwellingAmt && manualEntry) ||
        (isLoggedIn && manualEntry)
      ) {
        setHomeDetails({ ...details, DwellingAmt: +manualEntry });
      }
      await setDiscounts(values);
      Navigation.go(`/${mode}/wizard/insured`);
    },
  });

  const handleToggleChange =
    (fieldName) => (event: React.MouseEvent<HTMLElement>, value: string) => {
      if (value !== null) {
        formik.setFieldValue(fieldName, value);
      }
    };

  const addClaim = () => {
    const claims = [
      ...formik.values.claims,
      {
        description: undefined,
        amountOfLoss: undefined,
        dateOfLoss: undefined,
      },
    ];
    formik.setFieldValue("claims", claims);
  };

  const handleDwellingAmtChange = (e) => {
    if (formik.values.constructionLevel !== "standard") {
      formik.setFieldValue("constructionLevel", "");
    }
    const num = e.target.value.replace(/\D/g, "");
    setManualEntry(+num);
    formik.setFieldValue("updatedDwellingAmount", num);
  };
  const removeClaim = (index) => {
    const claims: any[] = [...formik.values.claims];
    claims.splice(index, 1);
    formik.setFieldValue("claims", claims);
    if (!claims.length) {
      formik.setFieldValue("hasClaims", false);
    }
  };

  const clearClaims = () => {
    formik.setFieldValue("claims", []);
  };

  useEffect(() => {
    if (formik.values.hasClaims && !formik.values.claims.length) {
      const claims = [
        {
          description: undefined,
          amountOfLoss: undefined,
          dateOfLoss: undefined,
        },
      ];
      formik.setFieldValue("claims", claims);
    }
  }, [formik.values.hasClaims]);

  useEffect(() => {
    if (isLoggedIn && details.DwellingAmt) {
      setManualEntry(details.DwellingAmt);
      formik.setFieldValue("updatedDwellingAmount", details.DwellingAmt);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (formik.values.constructionLevel === "standard") {
      if ((manualEntry && !details.DwellingAmt) || isLoggedIn) {
        formik.setFieldValue("updatedDwellingAmount", manualEntry);
        return;
      }
      formik.setFieldValue("updatedDwellingAmount", details.DwellingAmt);
    }
    if (formik.values.constructionLevel === "medium") {
      if (
        (manualEntry && !details.DwellingAmt) ||
        (isLoggedIn && manualEntry)
      ) {
        formik.setFieldValue(
          "updatedDwellingAmount",
          Math.round(+manualEntry * 1.2),
        );
        return;
      }
      formik.setFieldValue(
        "updatedDwellingAmount",
        Math.round(details.DwellingAmt * 1.2),
      );
    }
    if (formik.values.constructionLevel === "superb") {
      if (
        (manualEntry && !details.DwellingAmt) ||
        (isLoggedIn && manualEntry)
      ) {
        formik.setFieldValue(
          "updatedDwellingAmount",
          Math.round(+manualEntry * 1.3),
        );
        return;
      }
      formik.setFieldValue(
        "updatedDwellingAmount",
        Math.round(details.DwellingAmt * 1.3),
      );
    }
  }, [formik.values.constructionLevel, manualEntry]);

  return (
    <Form form={formik} onSubmit={formik.handleSubmit} className="maxWidth">
      {!isRenters && (
        <>
          <ScrollToOnError name="updatedDwellingAmount">
            <Box className={classes.formSection}>
              <FormLabel className={classes.formLabel} component="legend">
                Amount to rebuild
              </FormLabel>
              <FormControl
                variant="outlined"
                className={classes.dwellingAmount}
              >
                <OutlinedInput
                  name="updatedDwellingAmount"
                  value={formatDwellingCurrency(
                    formik.values.updatedDwellingAmount,
                  )}
                  onChange={handleDwellingAmtChange}
                  style={{ width: 304, maxWidth: "100%" }}
                  disabled={!!details.DwellingAmt && !isLoggedIn}
                />
                {Boolean(formik.errors.updatedDwellingAmount) && (
                  <FormHelperText error variant="outlined">
                    {formik.errors.updatedDwellingAmount}
                  </FormHelperText>
                )}
              </FormControl>
            </Box>
          </ScrollToOnError>
          <ScrollToOnError name="constructionLevel">
            <FormControl
              className={`${classes.formSection} ${
                formik.errors.constructionLevel ? classes.error : ""
              }`}
              component="fieldset"
            >
              <FormLabel className={classes.formLabel} component="legend">
                Construction level of your home
              </FormLabel>
              <RadioGroup
                name="constructionLevel"
                value={formik.values.constructionLevel}
                onChange={formik.handleChange}
                className={classes.twoLineLabelRadioGroup}
              >
                <FormControlLabel
                  value="standard"
                  control={<Radio color="primary" />}
                  label={
                    <>
                      <Typography style={{ fontWeight: 500 }} display="inline">
                        Standard
                      </Typography>{" "}
                      <Typography className={classes.subText} display="inline">
                        (Lowest level of quality.)
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  value="medium"
                  control={<Radio color="primary" />}
                  label={
                    <>
                      <Typography style={{ fontWeight: 500 }} display="inline">
                        Medium
                      </Typography>{" "}
                      <Typography className={classes.subText} display="inline">
                        (Upgrade from standard.)
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  value="superb"
                  control={<Radio color="primary" />}
                  label={
                    <>
                      <Typography style={{ fontWeight: 500 }} display="inline">
                        Superb{" "}
                      </Typography>{" "}
                      <Typography className={classes.subText} display="inline">
                        (Highest level of quality.)
                      </Typography>
                    </>
                  }
                />
              </RadioGroup>
              {Boolean(formik.errors.constructionLevel) && (
                <FormHelperText error variant="outlined">
                  {formik.errors.constructionLevel}
                </FormHelperText>
              )}
            </FormControl>
          </ScrollToOnError>
        </>
      )}
      <FormControl className={classes.formSection} component="fieldset">
        <FormLabel className={classes.formLabel} component="legend">
          Do you have any of these protective devices?{" "}
          <Typography className={classes.subText} display="inline">
            (Select all that apply.)
          </Typography>
        </FormLabel>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.discounts.smokeDetectors}
                  onChange={formik.handleChange}
                  name="discounts.smokeDetectors"
                  color="primary"
                />
              }
              label="Smoke detectors"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.discounts.deadbolts}
                  onChange={formik.handleChange}
                  name="discounts.deadbolts"
                  color="primary"
                />
              }
              label="Deadbolt locks"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.discounts.burglarAlarm}
                  onChange={formik.handleChange}
                  name="discounts.burglarAlarm"
                  color="primary"
                />
              }
              label="Burglar alarm"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.discounts.sprinklers}
                  onChange={formik.handleChange}
                  name="discounts.sprinklers"
                  color="primary"
                />
              }
              label="Sprinkler system"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.discounts.fireAlarm}
                  onChange={formik.handleChange}
                  name="discounts.fireAlarm"
                  color="primary"
                />
              }
              label="Fire alarm"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.discounts.fireExtinguishers}
                  onChange={formik.handleChange}
                  name="discounts.fireExtinguishers"
                  color="primary"
                />
              }
              label="Fire extinguishers"
            />
          </Grid>
        </Grid>
      </FormControl>
      <FormControl className={classes.formSection} component="fieldset">
        <FormLabel className={classes.formLabel} component="legend">
          Some other discounts
        </FormLabel>
        <Grid container>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.discounts.paperless}
                  onChange={formik.handleChange}
                  name="discounts.paperless"
                  color="primary"
                />
              }
              label="Paperless"
            />
          </Grid>
        </Grid>
      </FormControl>
      {!isRenters && (
        <FormControl className={classes.formSection} component="fieldset">
          <FormLabel className={classes.formLabel} component="legend">
            Do you have a mortgage or loan on your home?
          </FormLabel>
          <ToggleButtonGroup
            className={classes.toggle}
            value={formik.values.mortgageOrLoan}
            onChange={handleToggleChange("mortgageOrLoan")}
            exclusive
            style={{ width: "100%" }}
            size="large"
          >
            <ToggleButton
              value={true}
              aria-label="yes"
              style={{ width: "50%", textTransform: "capitalize" }}
              color="primary"
            >
              Yes
            </ToggleButton>
            <ToggleButton
              value={false}
              aria-label="no"
              style={{ width: "50%", textTransform: "capitalize" }}
              color="primary"
            >
              No
            </ToggleButton>
          </ToggleButtonGroup>
          {Boolean(formik.errors.mortgageOrLoan) && (
            <FormHelperText error variant="outlined">
              {formik.errors.mortgageOrLoan}
            </FormHelperText>
          )}
        </FormControl>
      )}
      <FormControl className={classes.formSection} component="fieldset">
        <FormLabel className={classes.formLabel} component="legend">
          Have you made any claims in the past 5 years?
        </FormLabel>
        <ToggleButtonGroup
          className={classes.toggle}
          value={formik.values.hasClaims}
          onChange={handleToggleChange("hasClaims")}
          exclusive
          style={{ width: "100%" }}
          size="large"
        >
          <ToggleButton
            value={true}
            aria-label="yes"
            style={{ width: "50%", textTransform: "capitalize" }}
            color="primary"
          >
            Yes
          </ToggleButton>
          <ToggleButton
            value={false}
            aria-label="no"
            style={{ width: "50%", textTransform: "capitalize" }}
            color="primary"
            onClick={clearClaims}
          >
            No
          </ToggleButton>
        </ToggleButtonGroup>
        {Boolean(formik.errors.hasClaims) && (
          <FormHelperText error variant="outlined">
            {formik.errors.hasClaims}
          </FormHelperText>
        )}
      </FormControl>
      {formik.values.hasClaims && (
        <>
          {isMobile ? (
            <Divider />
          ) : (
            <FormLabel className={classes.claimLabel} component="legend">
              Claims
            </FormLabel>
          )}
          <Box className={classes.formSection}>
            {Boolean(formik.values.claims && formik.values.claims.length) &&
              formik.values.claims.map((claim, index) => (
                <Box key={index} className={classes.claimsWrapper}>
                  {isMobile && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FormLabel
                        className={classes.claimLabel}
                        component="legend"
                      >
                        Claim #{index + 1}
                      </FormLabel>
                      <IconButton
                        className={classes.deleteClaimButton}
                        onClick={() => removeClaim(index)}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                  <FormControl variant="outlined" className={classes.claimItem}>
                    <TextField
                      id={`claims[${index}].amountOfLoss`}
                      name={`claims[${index}].amountOfLoss`}
                      label={"Amount of loss"}
                      type={"number"}
                      value={claim.amountOfLoss || ""}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.errors.claims &&
                          formik.errors.claims[index] &&
                          formik.errors.claims[index].amountOfLoss,
                      )}
                      helperText={
                        formik.errors.claims &&
                        formik.errors.claims[index] &&
                        formik.errors.claims[index].amountOfLoss
                      }
                    />
                  </FormControl>
                  <FormControl variant="outlined" className={classes.claimItem}>
                    <TextField
                      select
                      id={`claims[${index}].description`}
                      name={`claims[${index}].description`}
                      label="Description"
                      value={claim.description || ""}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.errors.claims &&
                          formik.errors.claims[index] &&
                          formik.errors.claims[index].description,
                      )}
                      helperText={
                        formik.errors.claims &&
                        formik.errors.claims[index] &&
                        formik.errors.claims[index].description
                      }
                    >
                      {CLAIM_DESCRIPTIONS.map((desc) => (
                        <MenuItem key={desc.value} value={desc.value}>
                          {desc.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <DatePicker
                    // id={`claims[${index}].dateOfLoss`}
                    format="MM/dd/yyyy"
                    label={"Date of loss"}
                    maxDate={new Date()}
                    minDate={CLAIM_MIN_DATE}
                    // autoOk
                    // name="dateOfLoss"
                    value={
                      (claim.dateOfLoss && new Date(claim.dateOfLoss)) || null
                    }
                    onChange={(date) => {
                      formik.setFieldValue(`claims[${index}].dateOfLoss`, date);
                    }}
                    className={classes.claimItem}
                    // error={Boolean(
                    //   formik.errors.claims &&
                    //     formik.errors.claims[index] &&
                    //     formik.errors.claims[index].dateOfLoss,
                    // )}
                    // helperText={
                    //   formik.errors.claims &&
                    //   formik.errors.claims[index] &&
                    //   formik.errors.claims[index].dateOfLoss
                    // }
                  />
                  {isMobile ? (
                    <Divider />
                  ) : (
                    <IconButton
                      className={classes.deleteClaimButton}
                      onClick={() => removeClaim(index)}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              ))}
            <Button
              className={classes.addClaimButton}
              variant="contained"
              onClick={() => addClaim()}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 8,
                }}
              >
                <AddIcon />
                ADD
              </Box>
            </Button>
          </Box>
        </>
      )}
      <FormControl className={classes.formSection} component="fieldset">
        <FormLabel className={classes.formLabel} component="legend">
          Was your policy canceled in the last 5 years?
        </FormLabel>
        <ToggleButtonGroup
          className={classes.toggle}
          value={formik.values.policyCanceledLastFive}
          onChange={handleToggleChange("policyCanceledLastFive")}
          exclusive
          style={{ width: "100%" }}
          size="large"
        >
          <ToggleButton
            value={true}
            aria-label="yes"
            style={{ width: "50%", textTransform: "capitalize" }}
            color="primary"
          >
            Yes
          </ToggleButton>
          <ToggleButton
            value={false}
            aria-label="no"
            style={{ width: "50%", textTransform: "capitalize" }}
            color="primary"
          >
            No
          </ToggleButton>
        </ToggleButtonGroup>
        {Boolean(formik.errors.policyCanceledLastFive) && (
          <FormHelperText error variant="outlined">
            {formik.errors.policyCanceledLastFive}
          </FormHelperText>
        )}
      </FormControl>
      <FormControl className={classes.formSection} component="fieldset">
        <div className={classes.info}>
          <FormLabel className={classes.formLabel} component="legend">
            Are there any dangerous animals on premises?
          </FormLabel>
          <Tooltip
            title="Dangerous animals includes any of the following breeds or a mix of any of these breeds of dogs: Akita, Alaskan Malamute, American Bull Terrier, American Staffordshire Terrier, Mastiffs, Chow Chow,
    Doberman Pinscher, Pit Bull, Presa Canario, Rottweiler, Staffordshire Bull Terrier, Wolf Hybrid."
            placement="top"
          >
            <InfoIcon />
          </Tooltip>
        </div>
        <ToggleButtonGroup
          className={classes.toggle}
          value={formik.values.dangerousAnimals}
          onChange={handleToggleChange("dangerousAnimals")}
          exclusive
          style={{ width: "100%" }}
          size="large"
        >
          <ToggleButton
            value={true}
            aria-label="yes"
            style={{ width: "50%", textTransform: "capitalize" }}
            color="primary"
          >
            Yes
          </ToggleButton>
          <ToggleButton
            value={false}
            aria-label="no"
            style={{ width: "50%", textTransform: "capitalize" }}
            color="primary"
          >
            No
          </ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        className={classes.nextButton}
        type="submit"
        disabled={!formik.isValid}
        onClick={() => setFormSubmitted(true)}
      >
        Next
      </Button>
    </Form>
  );
}
