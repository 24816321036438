import React from "react";

export const ContactEmail = ({ fillColor }) => {
  return (
    <svg
      width="88px"
      height="104px"
      viewBox="0 0 88 104"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 4</title>
      <g
        id="↳-auto-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Confirm-phone-number-pop-up"
          transform="translate(-596.000000, -152.000000)"
          fillRule="nonzero"
        >
          <g id="Group-4" transform="translate(596.000000, 152.000000)">
            <g id="undraw_confirmed_re_sef7-copy">
              <path
                d="M63.1244397,87.2694563 L64,104 C64,104 26.9648292,68.9190684 22.3509119,58.654798 C17.7369946,48.3905275 26.4220186,23 26.4220186,23 L45.6919309,33.5343746 L63.1244397,87.2694563 Z"
                id="Path"
                fill="#9F616A"
              ></path>
              <path
                d="M6.43314243,16 L0.481177249,16.5714282 C0.481177249,16.5714282 -2.63652751,23.42857 7,24 L6.43314243,16 Z"
                id="Path"
                fill="#9F616A"
              ></path>
              <path
                d="M6.43314243,26 L0.481177249,26.5714283 C0.481177249,26.5714283 -2.63652751,33.4285717 7,34 L6.43314243,26 Z"
                id="Path"
                fill="#9F616A"
              ></path>
              <path
                d="M6.43314243,37 L0.481177249,37.5714282 C0.481177249,37.5714282 -2.63652751,44.42857 7,45 L6.43314243,37 Z"
                id="Path"
                fill="#9F616A"
              ></path>
              <path
                d="M7.43314074,47 L1.48117702,47.5714283 C1.48117702,47.5714283 -1.63652697,54.4285717 8,55 L7.43314074,47 Z"
                id="Path"
                fill="#9F616A"
              ></path>
              <path
                d="M3,18.5357137 L3.42975217,18.5357137 L3.42975217,6.7951345 C3.42974986,4.99295448 4.14763342,3.2645862 5.42547656,1.99025118 C6.7033197,0.715916168 8.4364481,0 10.243593,0 L35.18614,0 C36.9932843,0 38.7264142,0.715905773 40.0042608,1.99023646 C41.2821075,3.26456714 42,4.99293252 42,6.79511194 L42,71.2048655 C42,73.0070454 41.2821181,74.7354134 40.0042751,76.0097483 C38.7264321,77.2840832 36.9933039,78 35.1861591,78 L10.2436261,78 C6.48044158,78 3.42976892,74.9577371 3.42975739,71.2048915 L3.42975739,26.8928593 L3,26.8928593 L3,18.5357137 Z"
                id="Path"
                fill="#002679"
              ></path>
              <path
                d="M10.0539015,2 L13.2875835,2 C12.985602,2.74874398 13.0719842,3.60038219 13.5179858,4.27152916 C13.9639873,4.94267612 14.711083,5.3452625 15.5106712,5.34532465 L29.7027452,5.34532465 C30.5023353,5.34526585 31.2494339,4.94267952 31.695436,4.27153055 C32.1414381,3.60038157 32.2278177,2.74874052 31.9258312,2 L34.9460985,2 C37.7372877,2 40,4.2882806 40,7.11102487 L40,71.8889629 C40,73.2444933 39.4675391,74.5445051 38.51975,75.5030104 C37.5719609,76.4615158 36.2864825,77 34.9461054,77 L10.0539015,77 C8.71352438,77 7.42804528,76.4615195 6.4802549,75.5030154 C5.53246452,74.5445113 5,73.2445003 5,71.8889699 L5,71.8889699 L5,7.11103011 C5,5.75550004 5.53246463,4.45548937 6.48025511,3.49698585 C7.42804559,2.53848233 8.71352469,2 10.0539015,2 Z"
                id="Path"
                fill="#FFFFFF"
              ></path>
              <rect
                id="Rectangle"
                fill="#E5E5E5"
                x="9.88"
                y="53.12"
                width="26"
                height="3"
              ></rect>
              <rect
                id="Rectangle-Copy"
                fill="#E5E5E5"
                x="9.88"
                y="58.84"
                width="26"
                height="3"
              ></rect>
              <rect
                id="Rectangle"
                fill="#E5E5E5"
                x="9.88"
                y="46.88"
                width="26"
                height="3"
              ></rect>
              <path
                d="M88,96.0342659 L57.5842238,61.3846394 L56.4972613,39.1344176 L45.0841567,20.1403258 L41.8232675,12 C41.8232675,12 34.2145265,12.8140329 40.4645635,28.2806499 L43.0461016,36.5566472 L43.0461016,36.5566472 C40.7190474,41.5614468 39.5134742,47.0129337 39.5134744,52.5308504 L39.5134744,71.4952308 C39.5134744,75.9607382 60.824505,100.284472 63.3051511,104 L63.3051511,104 L88,96.0342659 Z"
                id="Path"
                fill="#9F616A"
              ></path>
              <polygon
                id="Path"
                fill="#000000"
                opacity="0.2"
                points="42.9381493 22 45 20.9553219 44.9297368 20.8078187 43.0246638 21.7731603 40.1488114 14 40 14.0586399"
              ></polygon>
              <polygon
                id="Rectangle"
                fill="#000000"
                opacity="0.2"
                transform="translate(2.097249, 17.160415) rotate(-69.777900) translate(-2.097249, -17.160415) "
                points="2.01012867 15.3807405 2.18436988 15.3807405 2.18436988 18.9400892 2.01012867 18.9400892"
              ></polygon>
              <polygon
                id="Rectangle"
                fill="#000000"
                opacity="0.2"
                transform="translate(2.097249, 27.265192) rotate(-69.777900) translate(-2.097249, -27.265192) "
                points="2.01012805 25.4855179 2.18436925 25.4855179 2.18436925 29.0448665 2.01012805 29.0448665"
              ></polygon>
              <polygon
                id="Rectangle"
                fill="#000000"
                opacity="0.2"
                transform="translate(2.097250, 38.066853) rotate(-69.777900) translate(-2.097250, -38.066853) "
                points="2.01012899 36.2871784 2.1843702 36.2871784 2.1843702 39.846527 2.01012899 39.846527"
              ></polygon>
              <polygon
                id="Rectangle"
                fill="#000000"
                opacity="0.2"
                transform="translate(2.982557, 48.643545) rotate(-64.361010) translate(-2.982557, -48.643545) "
                points="2.89546294 47.7417086 3.06965014 47.7417086 3.06965014 49.5453817 2.89546294 49.5453817"
              ></polygon>
            </g>
            <circle
              id="b1347523-5d0d-4426-8baa-7e4ff69466d7"
              fill="#E6E6E6"
              transform="translate(22.837638, 69.951375) rotate(-80.782676) translate(-22.837638, -69.951375) "
              cx="22.837638"
              cy="69.9513746"
              r="2.35137459"
            ></circle>
            <g id="Group-3" transform="translate(13.000000, 18.000000)">
              <circle
                id="Oval"
                fill={fillColor}
                cx="10"
                cy="10"
                r="10"
              ></circle>
              <g id="Group-2" transform="translate(4.000000, 6.000000)">
                <path
                  d="M12,0.562500483 L12,8.4374995 C12,8.58669638 11.9412417,8.72979425 11.8365615,8.83529238 C11.7318814,8.94079052 11.589893,9 11.4418529,9 L0.558147228,9 C0.410107106,9 0.268118717,8.94079052 0.163438539,8.83529238 C0.0587583612,8.72979425 0,8.58669638 0,8.4374995 L0,0.562500483 C-0.000344699014,0.498149123 0.0110171079,0.434278299 0.0335294588,0.374058147 C0.101532197,0.178172973 0.272031664,0.0366918105 0.475836567,0.00702987999 C0.50304979,0.00253709395 0.530568827,0.000186696586 0.558144151,0 L11.4418498,0 C11.4694251,0.000186564801 11.4969442,0.00253696303 11.5241574,0.00702987999 C11.7279718,0.0366701465 11.898481,0.17815942 11.9664645,0.374058147 C11.9889789,0.434277902 12.0003428,0.498148738 12,0.562500483 Z"
                  id="Path"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M12,0.307509652 L6.16696734,3.95286952 C6.06823267,4.01571016 5.93175959,4.01571016 5.83302492,3.95286952 L0,0.307509652 C0.0683848447,0.143390611 0.239842347,0.02485304 0.444792428,0 L6,3.47098662 L11.5552014,0 C11.7601637,0.0248320179 11.931634,0.143377261 12,0.307509652 L12,0.307509652 Z"
                  id="Path"
                  fill={fillColor}
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
