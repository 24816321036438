import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "20%",
    maxWidth: "20%",
    paddingBottom: 30,
    [theme.breakpoints.down('lg')]: {
      maxWidth: "28%",
    },
    [theme.breakpoints.down('md')]: {
      width: "100%",
      minWidth: "100%",
      padding: 16,
    },
  },
  topSection: {
    borderBottom: "1px solid rgba(141, 152, 194, .2)",
    padding: 30,
    paddingLeft: 30,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    "& .MuiButtonBase-root": {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 1,
    },
  },
  mobileApplicantTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 12,
  },
  birthDate: {
    fontSize: 14,
    color: "#8D98C2",
  },
  contactWrapper: {
    marginTop: 18,
  },
  docs: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.primary.main,
    marginBottom: 5,
    "& .MuiTypography-root": {
      maxWidth: 175,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      [theme.breakpoints.down('sm')]: {
        maxWidth: 220,
      },
    },
  },
  contactItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "start",
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      height: 18,
      marginRight: 10,
      marginTop: 2,
    },
  },
  contactTxt: {
    fontSize: 14,
    marginBottom: 12,
  },
  titleTxt: {
    fontSize: 14,
    marginBottom: 12,
    marginLeft: 10,
  },
  bottomSection: {
    borderBottom: "1px solid rgba(141, 152, 194, .2)",
    padding: 30,
    paddingLeft: 30,
    [theme.breakpoints.down('sm')]: {
      padding: "25px 0",
    },
    "& p": {
      margin: 0,
    },
    "& .hoverable": {
      margin: "0 -16px",
      padding: "8px 16px",
    },
    "&:hover": {
      "& .hoverable": {
        boxShadow: "0 2px 20px 0 rgba(141,152,194,0.15)",
        cursor: "pointer",
      },
      "& $makePrimaryTag": {
        opacity: 1,
        cursor: "pointer",
      },
    },
  },
  driversTitle: {
    fontWeight: 600,
    marginBottom: 8,
  },
  homeTitle: {
    fontWeight: 600,
    paddingTop: 20,
    paddingBottom: 8,
    marginBottom: 8,
  },
  driverCard: {
    marginTop: 24,
  },
  emptyState: {
    margin: "24px 0 8px 0",
  },
  driverIcon: {
    color: theme.palette.primary.main,
    marginRight: 16,
    fontSize: 20,
  },
  primaryContainer: {
    marginLeft: 16,
    "&:hover $makePrimaryTag": {
      opacity: 0.5,
      cursor: "pointer",
    },
  },
  primaryTag: {
    height: 22,
    width: 68,
    borderRadius: 3,
    background: "#EFF1FB",
    color: theme.palette.primary.main,
    fontSize: 12,
    textAlign: "center",
    lineHeight: "22px",
  },
  makePrimaryTag: {
    opacity: 0,
    transition: "opacity 300ms linear",
    color: theme.palette.primary.main,
    fontSize: 12,
    textDecoration: "underline",
  },
  driverAccentText: {
    color: theme.palette.secondary.main,
    fontSize: 13,
  },
  coverage: {
    fontWeight: "bold",
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  header: {
    fontWeight: 600,
    "& span": {
      fontWeight: "initial",
    },
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  addButton: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1,
    margin: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  downloadBtn: {
    cursor: "pointer",
  },
}));
