import { captureException } from "@sentry/react";
import axios from "axios";
import { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import { history } from "../../lib";
import { serializeParams } from "./apiUtils";
import { REACT_APP_API_URL } from "../../config";
import { fpPromise } from "../../utils/fingerprint";

axios.defaults.paramsSerializer = serializeParams;

const _Api = axios.create();
async function handleResponse<T = any>(
  promise: Promise<AxiosResponse<T>>,
): Promise<AxiosResponse<T>> {
  let error: AxiosError<T> | undefined;
  promise = promise.catch((err) => {
    error = err;
    return err.response;
  });
  const res = await promise;
  if (error) {
    if (res) {
      const status = res.status;
      if (status === 401) {
        window.location.replace(
          "/auth/logout?after=" +
            encodeURIComponent(
              window.location.pathname + window.location.search,
            ),
        );
      }
    }
    //throw error;
  }
  return res;
}
/** Client for making authenticated API calls. */
export const Api = {
  delete(url: string, config?: AxiosRequestConfig) {
    return handleResponse(_Api.delete(url, config));
  },
  download() {
    return Promise.reject("TODO: Implement apiDownload.");
  },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return handleResponse(_Api.get<T>(url, config));
  }, //TODO: post should only be used with body
  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return handleResponse(_Api.post<T>(url, data, config));
  },
  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return handleResponse(_Api.put<T>(url, data, config));
  },
};
_Api.defaults.paramsSerializer = serializeParams;
_Api.defaults.baseURL = REACT_APP_API_URL;

const showErrorPage = (error) => {
  //TODO: perfect this
  const errorCode = error?.response?.data?.code;
  const status = error?.response?.status;

  if (status === 500 && errorCode !== 502) return true;
  //This is assuming that code 400 are not handled for
  if (errorCode === 400) return true;

  return false;
};
_Api.interceptors.request.use(
  async (config) => {
    const fingerPrint = await fpPromise
      .then((fp) => fp.get())
      .then((result) => result.visitorId);
    config.headers = {
      fp: fingerPrint || "",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

_Api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    captureException(error, error?.response?.data);
    if (showErrorPage(error)) {
      if (window.location.pathname.includes("admin")) {
        history.push("/something-went-wrong");
        return;
      }
      console.error("error - sending to snag", error);
      if (window.location.pathname.includes("admin")) {
        history.push("/something-went-wrong");
        return;
      }
      history.push("/hit-snag");
    }
    return Promise.reject(error);
  },
);

export { axios };
