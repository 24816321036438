import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  formCard: {
    borderRadius: 0,
    marginBottom: "2em",
  },
  formWrap: {
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formControl: {
    width: "50%",
    minWidth: 330,
    padding: "0 2em 2em",
  },
  formAction: {
    paddingRight: "2em",
  },
}));
