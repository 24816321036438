import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  featureWrapper: {
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
      marginBottom: 16,
    },
  },
  featureNoSpam: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    textAlign: "right",
    "& .MuiSvgIcon-root": {
      marginRight: 10,
    },
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      gap: 15,
      width: 100,
      marginLeft: "auto",
      marginRight: 12,
    },
  },
  feature: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    marginLeft: 40,
    fontSize: 14,
    fontWeight: 500,
    "& .MuiSvgIcon-root": {
      marginRight: 8,
    },
    [theme.breakpoints.down('sm')]: {
      display: "block",
      fontSize: 12,
      width: 100,
      marginLeft: 8,
      "& .MuiSvgIcon-root": {
        display: "block",
        margin: "0 auto 8px",
      },
    },
  },
  firstFeature: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 0,
    [theme.breakpoints.down('sm')]: {
      display: "block",
      marginLeft: "auto",
      marginRight: 8,
    },
  },
  formContainer: {
    maxWidth: 640,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 40,
    marginTop: 40,
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
}));
