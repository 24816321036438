/**
 * @file This file is used to normalize environment variables and provide
 * intellisense/autocomplete for them. Import your settings from this file
 * instead of directly from `process.env`.
 */

/** Common environment names. */
// const Environments = {
//   development: "development",
//   production: "production",
// };

/**
 * True if the app is in production mode.
 * NOTE: We don't use Environments.production to test for this
 * because Create-React-App uses "production" for every other non-development
 * environment.
 */
/** Host name used to detect production mode. */
const REACT_APP_PROD_HOSTNAME = process.env.REACT_APP_PROD_HOSTNAME;

const baseOrigin =
  window.location.protocol +
  "//" +
  window.location.origin.split(".").slice(-2).join(".");
const __PROD__ =
  window.location.hostname === REACT_APP_PROD_HOSTNAME ||
  (baseOrigin === "https://reliexchange.com" &&
    window.location.hostname !== "test.reliexchange.com");
const __STAGING__ =
  window.location.hostname === process.env.REACT_APP_STAGING_HOSTNAME ||
  window.location.hostname === "test.reliexchange.com";
const __TESTING__ =
  window.location.hostname === process.env.REACT_APP_TESTING_HOSTNAME;
const __DEV__ = window.location.hostname === process.env.REACT_APP_DEV_HOSTNAME;

/** Name of the current environment. */
const NODE_ENV = __PROD__
  ? "production"
  : __STAGING__
  ? "staging"
  : __TESTING__
  ? "test"
  : process.env.NODE_ENV;

/** True if the app is in development mode. */
// const __DEV__ = NODE_ENV === Environments.development;

/** Base URL of the API. */
const REACT_APP_API_URL = "/api/V1";

const REACT_APP_BASE_API_URL = __PROD__
  ? "https://portal.reliexchange.com"
  : __STAGING__
  ? "https://staging.5mininsure.com"
  : __TESTING__
  ? "https://reliance-global-testing-ntbvc4qikq-uk.a.run.app"
  : "";

const LOGIN_IFRAME_POST_URL = __PROD__
  ? "https://portal.reliexchange.com"
  : __STAGING__
  ? "https://staging.5mininsure.com"
  : __TESTING__
  ? "https://reliance-global-testing-ntbvc4qikq-uk.a.run.app"
  : "http://reliexchange.local:3303";

/** Base URL of the SmartyStreets API. */
const REACT_APP_SMARTY_STREETS_API_URL =
  process.env.REACT_APP_SMARTY_STREETS_API_URL;
/** DSN URL for the error telemetry API. */
const REACT_APP_ERRORS_DSN = process.env.REACT_APP_ERRORS_DSN;
/** True to report errors during development. */
const REACT_APP_ERRORS_DEV = process.env.REACT_APP_ERRORS_DEV === "true";
/** Key to store redux state under localStorage. */
const REACT_APP_PERSIST_KEY = process.env.REACT_APP_PERSIST_KEY;
/** Site title string. */
const REACT_APP_SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
/** Package version string from the package.json file. */
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
/** Meant to control which group to connect to. */
const REACT_APP_CHAT_GROUP_ID = __PROD__
  ? 1
  : __STAGING__
  ? 3
  : process.env.REACT_APP_CHAT_GROUP_ID
  ? parseInt(process.env.REACT_APP_CHAT_GROUP_ID)
  : 0;

const REACT_APP_SNAG_CHAT_GROUP_ID = __PROD__ ? 5 : 4;

const REACT_APP_GOOGLE_API_TOKEN = process.env.REACT_APP_GOOGLE_API_TOKEN;
const REACT_APP_MUI_LICENSE =
  "6e266660031f041eee8047041db1dba0Tz02ODU4OSxFPTE3MTgzNDcyNTUyMzYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";
const REACT_APP_ASSETS_URL = process.env.REACT_APP_ASSETS_URL;

const IS_AGENCY_SITE =
  window.location.hostname !== process.env.REACT_APP_PROD_HOSTNAME &&
  window.location.hostname !== process.env.REACT_APP_STAGING_HOSTNAME &&
  window.location.hostname !== process.env.REACT_APP_TESTING_HOSTNAME &&
  window.location.hostname !== process.env.REACT_APP_DEV_HOSTNAME;

export {
  __DEV__,
  __TESTING__,
  __PROD__,
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_BASE_API_URL,
  REACT_APP_SMARTY_STREETS_API_URL,
  REACT_APP_ERRORS_DSN,
  REACT_APP_ERRORS_DEV,
  REACT_APP_PERSIST_KEY,
  REACT_APP_PROD_HOSTNAME,
  REACT_APP_SITE_TITLE,
  REACT_APP_VERSION,
  REACT_APP_CHAT_GROUP_ID,
  REACT_APP_SNAG_CHAT_GROUP_ID,
  REACT_APP_GOOGLE_API_TOKEN,
  REACT_APP_MUI_LICENSE,
  REACT_APP_ASSETS_URL,
  IS_AGENCY_SITE,
  LOGIN_IFRAME_POST_URL,
};
