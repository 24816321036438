import React, { Fragment, useEffect, useState } from "react";
import { Grid, IconButton, ListItem, Typography } from "@mui/material";
import {
  formatCurrency,
  getPercentage,
  kFormatter,
} from "../../../../../utils";
import {
  autoSelectors,
  useDispatch,
  useSelector,
  wizardActions,
} from "../../../../../state";
import { InfoIcon } from "../../../../../components/icons";
import { useCoverageName } from "../../../../../hooks";
import { useStyles } from "./PropListItem.styles";

const coveragesDisplayPerVehicle = ["COMP", "COLL", "RREIM", "TL", "TW"];

const Unlimited = 2147483646;
const NoCoverage = 2147483645;

const PropListItem = ({
  prop,
  fullProps,
  coverageName,
  carrier,
  quoteType,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [deductible, setDeductible] = useState<string>("");
  const [extendedCoverage, setExtendedCoverage] = useState<number>(0);
  const coverages = useSelector(autoSelectors.coveragesObj);

  const highestPipOption = () => {
    const pip = coverages.PIP;
    if (pip) {
      const limits = pip.Limits;
      if (limits?.length) {
        return Number(limits[limits.length - 1]?.Val1);
      }
    }
    return 0;
  };

  const dwellingAmount = fullProps[0]?.Coverages.find(
    (c) => c.CoverageName === "Dwelling",
  )?.Limit[0];

  const propHasDeductible = (prop) => {
    return Boolean(
      prop &&
        prop.Deductible &&
        prop.Deductible !== NoCoverage &&
        prop.Deductible !== -1,
    );
  };

  const propIsDeductible = (prop) => {
    return Boolean(prop && prop.DeductibleName && prop.Value && prop.Value > 0);
  };

  useEffect(() => {
    if (coverageName !== "Dwelling") {
      setExtendedCoverage(0);
    } else {
      const extended = fullProps[0]?.Coverages.find(
        (c) => c.CoverageName === "Extended Replacement Cost",
      )?.Limit[0];
      if (extended) {
        setExtendedCoverage(extended - 100);
      }
    }
  }, [coverageName, fullProps]);

  useEffect(() => {
    if (prop) {
      if (propHasDeductible(prop)) {
        setDeductible(formatCurrency(prop.Deductible));
      } else if (propIsDeductible(prop)) {
        if (prop.Type === "PC") {
          setDeductible(getPercentage(prop.Value, dwellingAmount));
        } else {
          setDeductible(formatCurrency(prop.Value));
        }
      }
    }
  }, [prop]);

  const formatLimit = (limitArray, hasDeductible = false) => {
    if (quoteType === "auto") {
      const isDeductible = !limitArray?.length && hasDeductible;
      const formatted = limitArray
        ?.filter((limit) => limit !== 0)
        ?.map((limit) =>
          limit === Unlimited || limit === NoCoverage
            ? coverageName === "PIP"
              ? highestPipOption()
                ? kFormatter(Number(highestPipOption()))
                : "Max"
              : "Unlimited"
            : kFormatter(limit),
        );
      if ((coverageName === "TL" && formatted?.length) || isDeductible) {
        return "Yes";
      } else if (formatted?.length) {
        return formatted.join("/");
      } else {
        return "No";
      }
    } else {
      //home
      const isDeductible = !limitArray?.length && deductible;
      const formatted =
        limitArray &&
        limitArray
          .filter((limit) => limit !== 0)
          .map((limit) =>
            limit === Unlimited || limit === NoCoverage
              ? "Unlimited"
              : formatCurrency(limit.Value || limit),
          );
      return isDeductible ? "Yes" : formatted?.length ? formatted[0] : "No";
    }
  };

  return <>
    <ListItem className={classes.prop}>
      <Typography className={classes.coverageName}>
        {useCoverageName(coverageName)}
      </Typography>
      <Grid container justifyContent="space-between" alignItems="center">
        {prop ||
        coveragesDisplayPerVehicle.find((x) => x === coverageName) ? (
          coveragesDisplayPerVehicle.find((x) => x === coverageName) ? (
            fullProps.map((p, i) => {
              return (
                <Fragment key={i}>
                  {fullProps.length > 1 && (
                    <Grid container>
                      <Typography className={classes.vehicle}>
                        {p.Year} {p.Make} {p.Model}
                      </Typography>
                    </Grid>
                  )}
                  <Typography className={classes.limit}>
                    {formatLimit(
                      p.Coverages.find((c) => c.CoverageName === coverageName)
                        ?.Limit,
                      propHasDeductible(
                        p.Coverages.find(
                          (c) => c.CoverageName === coverageName,
                        ),
                      ),
                    )}

                    {coverageName === "RREIM" &&
                      p.Coverages.find((c) => c.CoverageName === coverageName)
                        ?.Limit &&
                      `/${kFormatter(
                        Number(
                          p.Coverages.find(
                            (c) => c.CoverageName === coverageName,
                          )?.Limit,
                        ) * 30,
                      )}`}
                  </Typography>

                  {propHasDeductible(
                    p.Coverages.find((c) => c.CoverageName === coverageName),
                  ) && (
                    <Typography className={classes.deductible}>
                      $
                      {
                        p.Coverages.find(
                          (c) => c.CoverageName === coverageName,
                        )?.Deductible
                      }{" "}
                      ded.
                    </Typography>
                  )}
                  {fullProps.length > 1 && i === 0 && (
                    <div style={{ height: 18, width: 18 }}></div>
                  )}
                </Fragment>
              );
            })
          ) : coverageName === "Loss of Use" && carrier === "nationwide" ? (
            "Actual Loss Sustained"
          ) : coverageName === "Loss of Use" && carrier === "safeco" ? (
            <Typography className={classes.limit}>
              {getPercentage(20, dwellingAmount)}
            </Typography>
          ) : coverageName === "HO 490" ? (
            <span style={{ fontWeight: 500, fontSize: "1rem" }}>Yes</span>
          ) : (
            <>
              <Typography className={classes.limit}>
                {formatLimit(prop.Limit || prop?.Limits)}
                {/* eslint-disable-next-line */}
                {!!extendedCoverage &&
                extendedCoverage.toString().length <= 3 ? (
                  <span
                    className={classes.extendedCost}
                    onClick={() => {
                      dispatch(
                        wizardActions.setCoverageModal(
                          "Extended Replacement Cost",
                        ),
                      );
                    }}
                  >
                    + {extendedCoverage}% Extended coverage
                  </span>
                ) : (
                  coverageName === "Dwelling" && (
                    <span style={{ height: 16, display: "block" }}></span>
                  )
                )}
              </Typography>
              {deductible && (
                <Typography className={classes.deductible}>
                  {deductible} ded.
                </Typography>
              )}
            </>
          )
        ) : (
          <Typography className={classes.limit}>No</Typography>
        )}
        <div>
          <Typography
            className={classes.info}
            onClick={() => {
              dispatch(wizardActions.setCoverageModal(coverageName));
            }}
          >
            <IconButton style={{ padding: 0 }} size="large">
              <InfoIcon />
            </IconButton>
          </Typography>
        </div>
      </Grid>
    </ListItem>
  </>;
};

export default PropListItem;
