import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

import { AddIcon } from "../../components/icons";

import {
  useDispatch,
  adminActions,
  useSelector,
  adminSelectors,
  useAction,
  authSelectors,
} from "../../state";
import AdminPages from ".";
import { CARRIER_COLUMNS as columns } from "../../constants/tables";
import { AGENT_CARRIER_COLUMNS as agentColumns } from "../../constants/tables";

import { useStyles } from "./CarriersPage.styles";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function CarriersPage() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const carriers = useSelector(adminSelectors.carriers);
  const agencyGroups = useSelector(adminSelectors.agency_groups);
  const activeRole = useSelector(authSelectors.activeRole);
  const [pageSize, setPageSize] = React.useState(25);
  const selectedAgencyGrp = useSelector(adminSelectors.selectedAgencyGrp);
  const setSelectedAgencyGrp = useAction(adminActions.setSelectedAgencyGrp);
  const isAgencyPartner =
    activeRole === "agency_admin" || activeRole === "agent";
  useEffect(() => {
    dispatch(adminActions.setPageTitle("Carriers"));
    if (isAgencyPartner) {
      dispatch(adminActions.getCarriersByAgent());
      return;
    }
    dispatch(adminActions.getAgencyGroups());
    dispatch(adminActions.getCarriers(selectedAgencyGrp));
  }, [dispatch, selectedAgencyGrp, activeRole]);
  const handleAgencyGrpFilter = (e) => {
    setSelectedAgencyGrp(e.target.value);
  };
  return (
    <div className={classes.page}>
      {isAgencyPartner && (
        <div className={classes.topArea}>
          <Typography className={classes.header}>Carriers</Typography>
        </div>
      )}
      {!isAgencyPartner && (
        <div className={classes.topArea}>
          <Link className={classes.link} to={AdminPages.createCarriers.path}>
            <Button variant="outlined" color="primary">
              <AddIcon /> Add Carrier
            </Button>
          </Link>
          <TextField
            className={classes.agencyField}
            select
            value={selectedAgencyGrp}
            label="Agency Group"
            onChange={handleAgencyGrpFilter}
            variant="outlined"
          >
            {agencyGroups.map(({ id, name }, idx) => (
              <MenuItem key={idx} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}

      <DataGridPro
        rows={carriers}
        columns={isAgencyPartner ? agentColumns : columns}
        pagination
        density="comfortable"
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
