import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Box,
  Grid,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";

import { HexColorPicker } from "react-colorful";

import {
  adminActions,
  adminSelectors,
  authSelectors,
  useDispatch,
  useSelector,
} from "../../../../state";
import { useFormik, yup } from "../../../../lib";

import { useStyles } from "../AgencySettingsPage.styles";
import { EditIcon } from "../../../../components";
import { Agency } from "../../../../state/admin/state";
import { useParams } from "react-router";

const validationSchema = yup.object({});

const WebsiteColorsCard = () => {
  const [colorPickerOpen, setColorPickerOpen] = useState("");
  const [inEditMode, setInEditMode] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const params: { id: string } = useParams();

  const activeAgencyId = params.id
    ? Number(params.id)
    : useSelector(authSelectors.activeAgencyId);
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const activeAgencyData: Agency = useSelector(adminSelectors.activeAgencyData);
  const { theme } = activeAgencyData;

  const form = useFormik({
    enableReinitialize: true,
    initialValues: { theme },
    validationSchema,
    async onSubmit(values) {
      const agencyData = {
        ...activeAgencyData,
        theme: values.theme,
      };

      dispatch(
        adminActions.updateAgency(activeAgencyId, agencyData, isSuperAdmin),
      );
      setInEditMode(false);
      form.resetForm();
    },
  });

  const handleCancel = () => {
    setInEditMode(false);
    form.resetForm();
  };

  useEffect(() => {
    dispatch(adminActions.getAgency(activeAgencyId));
  }, []);

  const showEditButton = !inEditMode && isSuperAdmin;

  if (!activeAgencyId) return null;

  const SectionTitle = ({ title }) => {
    return (
      <Box className={classes.sectionTitleWrapper}>
        <Typography className={classes.sectionTitle}>{title}</Typography>
        {showEditButton && (
          <Button
            className={classes.editBtnWrapper}
            onClick={() => setInEditMode(!inEditMode)}
          >
            <EditIcon style={{ color: "white" }} />
          </Button>
        )}
      </Box>
    );
  };

  const handleColorChange = (palette) => {
    const theme = {
      ...form.values.theme,
      palette,
    };
    form.setFieldValue("theme", theme);
  };

  return (
    <>
      {inEditMode ? (
        <Card variant="outlined" className={classes.section}>
          <form onSubmit={form.handleSubmit}>
            <SectionTitle title={"Website colors"} />
            <Grid container spacing={2} className={classes.colorsWrapper}>
              <Grid item xs={4}>
                <Typography className={classes.smallText}>Primary</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.smallText}>Secondary</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={classes.smallText}>Accent</Typography>
              </Grid>
              <Grid item xs={4} className={classes.colorWrapper}>
                <TextField
                  id={"theme.palette.primary.main"}
                  name={"theme.palette.primary.main"}
                  label={"Main"}
                  variant="outlined"
                  required={true}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          className={classes.editColor}
                          style={{
                            backgroundColor:
                              form.values.theme.palette.primary.main,
                            cursor: "pointer",
                          }}
                          onClick={() => setColorPickerOpen("primary.main")}
                        ></div>
                      </InputAdornment>
                    ),
                  }}
                  value={form.values.theme.palette.primary.main}
                  onChange={form.handleChange}
                />
                {colorPickerOpen === "primary.main" && (
                  <HexColorPicker
                    color={form.values.theme.palette.primary.main}
                    onChange={(e) => {
                      const palette = {
                        ...form.values.theme.palette,
                        primary: {
                          ...form.values.theme.palette.primary,
                          main: e,
                        },
                      };
                      handleColorChange(palette);
                    }}
                    onMouseLeave={() => setColorPickerOpen("")}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Grid>
              <Grid item xs={4} className={classes.colorWrapper}>
                <TextField
                  id={"theme.palette.secondary.main"}
                  name={"theme.palette.secondary.main"}
                  label={"Main"}
                  variant="outlined"
                  required={true}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          className={classes.editColor}
                          style={{
                            backgroundColor:
                              form.values.theme.palette.secondary.main,
                            cursor: "pointer",
                          }}
                          onClick={() => setColorPickerOpen("secondary.main")}
                        ></div>
                      </InputAdornment>
                    ),
                  }}
                  value={form.values.theme.palette.secondary.main}
                  onChange={form.handleChange}
                />
                {colorPickerOpen === "secondary.main" && (
                  <HexColorPicker
                    color={form.values.theme.palette.secondary.main}
                    onChange={(e) => {
                      const palette = {
                        ...form.values.theme.palette,
                        secondary: {
                          ...form.values.theme.palette.secondary,
                          main: e,
                        },
                      };
                      handleColorChange(palette);
                    }}
                    onMouseLeave={() => setColorPickerOpen("")}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Grid>
              <Grid item xs={4} className={classes.colorWrapper}>
                <TextField
                  id={"theme.palette.info.main"}
                  name={"theme.palette.info.main"}
                  label={"Main"}
                  variant="outlined"
                  required={true}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          className={classes.editColor}
                          style={{
                            backgroundColor:
                              form.values.theme.palette.info.main,
                            cursor: "pointer",
                          }}
                          onClick={() => setColorPickerOpen("info.main")}
                        ></div>
                      </InputAdornment>
                    ),
                  }}
                  value={form.values.theme.palette.info.main}
                  onChange={form.handleChange}
                />
                {colorPickerOpen === "info.main" && (
                  <HexColorPicker
                    color={form.values.theme.palette.info.main}
                    onChange={(e) => {
                      const palette = {
                        ...form.values.theme.palette,
                        info: {
                          ...form.values.theme.palette.info,
                          main: e,
                        },
                      };
                      handleColorChange(palette);
                    }}
                    onMouseLeave={() => setColorPickerOpen("")}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Grid>
              <Grid item xs={4} className={classes.colorWrapper}>
                <TextField
                  id={"theme.palette.primary.light"}
                  name={"theme.palette.primary.light"}
                  label={"Light"}
                  variant="outlined"
                  required={true}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          className={classes.editColor}
                          style={{
                            backgroundColor:
                              form.values.theme.palette.primary.light,
                            cursor: "pointer",
                          }}
                          onClick={() => setColorPickerOpen("primary.light")}
                        ></div>
                      </InputAdornment>
                    ),
                  }}
                  value={form.values.theme.palette.primary.light}
                  onChange={form.handleChange}
                />
                {colorPickerOpen === "primary.light" && (
                  <HexColorPicker
                    color={form.values.theme.palette.primary.light}
                    onChange={(e) => {
                      const palette = {
                        ...form.values.theme.palette,
                        primary: {
                          ...form.values.theme.palette.primary,
                          light: e,
                        },
                      };
                      handleColorChange(palette);
                    }}
                    onMouseLeave={() => setColorPickerOpen("")}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Grid>
              <Grid item xs={4} className={classes.colorWrapper}>
                <TextField
                  id={"theme.palette.secondary.light"}
                  name={"theme.palette.secondary.light"}
                  label={"Light"}
                  variant="outlined"
                  required={true}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          className={classes.editColor}
                          style={{
                            backgroundColor:
                              form.values.theme.palette.secondary.light,
                            cursor: "pointer",
                          }}
                          onClick={() => setColorPickerOpen("secondary.light")}
                        ></div>
                      </InputAdornment>
                    ),
                  }}
                  value={form.values.theme.palette.secondary.light}
                  onChange={form.handleChange}
                />
                {colorPickerOpen === "secondary.light" && (
                  <HexColorPicker
                    color={form.values.theme.palette.secondary.light}
                    onChange={(e) => {
                      const palette = {
                        ...form.values.theme.palette,
                        secondary: {
                          ...form.values.theme.palette.secondary,
                          light: e,
                        },
                      };
                      handleColorChange(palette);
                    }}
                    onMouseLeave={() => setColorPickerOpen("")}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Grid>
              <Grid item xs={4} className={classes.colorWrapper}></Grid>
              <Grid item xs={4} className={classes.colorWrapper}>
                <TextField
                  id={"theme.palette.primary.contrastText"}
                  name={"theme.palette.primary.contrastText"}
                  label={"Contrast"}
                  variant="outlined"
                  required={true}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          className={classes.editColor}
                          style={{
                            backgroundColor:
                              form.values.theme.palette.primary.contrastText,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setColorPickerOpen("primary.contrastText")
                          }
                        ></div>
                      </InputAdornment>
                    ),
                  }}
                  value={form.values.theme.palette.primary.contrastText}
                  onChange={form.handleChange}
                />
                {colorPickerOpen === "primary.contrastText" && (
                  <HexColorPicker
                    color={form.values.theme.palette.primary.contrastText}
                    onChange={(e) => {
                      const palette = {
                        ...form.values.theme.palette,
                        primary: {
                          ...form.values.theme.palette.primary,
                          contrastText: e,
                        },
                      };
                      handleColorChange(palette);
                    }}
                    onMouseLeave={() => setColorPickerOpen("")}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Grid>
              <Grid item xs={4} className={classes.colorWrapper}>
                <TextField
                  id={"theme.palette.secondary.contrastText"}
                  name={"theme.palette.secondary.contrastText"}
                  label={"Contrast"}
                  variant="outlined"
                  required={true}
                  InputLabelProps={{
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <div
                          className={classes.editColor}
                          style={{
                            backgroundColor:
                              form.values.theme.palette.secondary.contrastText,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setColorPickerOpen("secondary.contrastText")
                          }
                        ></div>
                      </InputAdornment>
                    ),
                  }}
                  value={form.values.theme.palette.secondary.contrastText}
                  onChange={form.handleChange}
                />
                {colorPickerOpen === "secondary.contrastText" && (
                  <HexColorPicker
                    color={form.values.theme.palette.secondary.contrastText}
                    onChange={(e) => {
                      const palette = {
                        ...form.values.theme.palette,
                        secondary: {
                          ...form.values.theme.palette.secondary,
                          contrastText: e,
                        },
                      };
                      handleColorChange(palette);
                    }}
                    onMouseLeave={() => setColorPickerOpen("")}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Grid>
            </Grid>

            <Box className={classes.submit}>
              <Button
                variant="contained"
                onClick={() => handleCancel()}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.submitBtn}
                disabled={
                  // !form.isValid || TODO
                  !form.values.theme.palette.primary.main ||
                  !form.values.theme.palette.primary.light ||
                  !form.values.theme.palette.primary.contrastText ||
                  !form.values.theme.palette.secondary.main ||
                  !form.values.theme.palette.secondary.light ||
                  !form.values.theme.palette.secondary.contrastText ||
                  !form.values.theme.palette.info.main
                }
              >
                Save
              </Button>
            </Box>
          </form>
        </Card>
      ) : (
        <Card variant="outlined" className={classes.section}>
          <SectionTitle title={"Website colors"} />
          <Grid container spacing={2} className={classes.colorsWrapper}>
            <Grid item xs={4}>
              <Typography className={classes.smallText}>Primary</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.smallText}>Secondary</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.smallText}>Accent</Typography>
            </Grid>
            <Grid item xs={4} className={classes.colorWrapper}>
              <Typography className={classes.smallText}>Main</Typography>
              <div
                className={classes.color}
                style={{
                  backgroundColor: form.values.theme.palette.primary.main,
                }}
              ></div>
            </Grid>
            <Grid item xs={4} className={classes.colorWrapper}>
              <Typography className={classes.smallText}>Main</Typography>
              <div
                className={classes.color}
                style={{
                  backgroundColor: form.values.theme.palette.secondary.main,
                }}
              ></div>
            </Grid>
            <Grid item xs={4} className={classes.colorWrapper}>
              <Typography className={classes.smallText}>Main</Typography>
              <div
                className={classes.color}
                style={{
                  backgroundColor: form.values.theme.palette.info.main,
                }}
              ></div>
            </Grid>
            <Grid item xs={4} className={classes.colorWrapper}>
              <Typography className={classes.smallText}>Light</Typography>
              <div
                className={classes.color}
                style={{
                  backgroundColor: form.values.theme.palette.primary.light,
                }}
              ></div>
            </Grid>
            <Grid item xs={4} className={classes.colorWrapper}>
              <Typography className={classes.smallText}>Light</Typography>
              <div
                className={classes.color}
                style={{
                  backgroundColor: form.values.theme.palette.secondary.light,
                }}
              ></div>
            </Grid>
            <Grid item xs={4} className={classes.colorWrapper}></Grid>
            <Grid item xs={4} className={classes.colorWrapper}>
              <Typography className={classes.smallText}>Contrast</Typography>
              <div
                className={classes.color}
                style={{
                  backgroundColor:
                    form.values.theme.palette.primary.contrastText,
                }}
              ></div>
            </Grid>
            <Grid item xs={4} className={classes.colorWrapper}>
              <Typography className={classes.smallText}>Contrast</Typography>
              <div
                className={classes.color}
                style={{
                  backgroundColor:
                    form.values.theme.palette.secondary.contrastText,
                }}
              ></div>
            </Grid>
          </Grid>
        </Card>
      )}
    </>
  );
};

export default WebsiteColorsCard;
