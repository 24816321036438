import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  wrap: {
    position: "relative",
  },
  icon: {
    position: "absolute",
    top: "50%",
    right: 15,
    transform: "translate(0, -50%)",
    opacity: 0.5,
  },
  field: {
    paddingRight: 30,
    textOverflow: "ellipsis",
  },
}));
