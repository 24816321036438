import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiActions, uiSelectors } from "../state";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import { getTheme } from ".";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export const CustomThemeProvider = (props) => {
  const dispatch = useDispatch();
  const agencyData = useSelector(uiSelectors.agencyData);

  useEffect(() => {
    dispatch(uiActions.setAgency());
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTheme(agencyData?.theme)} {...props} />
    </StyledEngineProvider>
  );
};
