import React, { useEffect } from "react";
import { Box } from "@mui/material";

import DriversList from "./DriversList";
import DriverFields from "./DriverFields";

import { useSelector, autoSelectors } from "../../../../state";

const DriversWizard = () => {
  const activeDriverIndex = useSelector(autoSelectors.activeDriverIndex);
  const selectedDrivers = useSelector(autoSelectors.selectedDrivers);
  const showSelectDriverView = useSelector(autoSelectors.showSelectDriverView);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeDriverIndex]);

  return (
    <Box className="maxWidth">
      {showSelectDriverView ? (
        <DriversList />
      ) : (
        selectedDrivers?.map((driver, index) => (
          <Box
            key={`selectedDriver-${index}`}
            style={{ display: activeDriverIndex === index ? "block" : "none" }}
          >
            <DriverFields index={index} />
          </Box>
        ))
      )}
    </Box>
  );
};

export default React.memo(DriversWizard);
