import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  form: {
    justifyContent: "space-between",
    alignItems: "center",
    // width: 780,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    paddingBottom: 8,
    paddingTop: 8,
  },
  closeIconWrapper: {
    justifyContent: "center",
    alignItems: "center",
  },
  closeIcon: {
    color: "#AFB6D4",
  },
  divider: { border: 0, height: 1, background: "#ccd2e9" },
  divider_Vehicle: {
    border: 0,
    height: 1,
    background: "#ccd2e9",
    width: "94%",
    [theme.breakpoints.down('sm')]: {
      width: "90%",
    },
  },
  coverageWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBlock: 24,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  noImage: { width: 260 },
  imgWrapper: {
    marginLeft: 18,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    width: 124,
    height: 84,
    [theme.breakpoints.down('sm')]: {
      width: "auto",
      height: "auto",
    },
  },
  img: {
    width: 83,
    height: 84,
    [theme.breakpoints.down('sm')]: {
      width: 48,
      height: 47,
    },
  },
  coverageDescriptionWrapper: {
    marginLeft: 26,
    paddingRight: 18,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  label: {
    fontSize: 15,
    fontWeight: 600,
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 12,
    },
  },
  description: {
    fontSize: 16,
  },
  coverageAmountWrapper: {
    marginRight: 18,
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginRight: 0,
    },
  },
  coverageHeader: {
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    marginBottom: 0,
  },
  coverageHeader_2: {
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.secondary.main,
    marginBottom: 0,
    marginTop: 16,
  },
  dropdown: {
    minWidth: 162,
    "& .MuiSelect-selectMenu": {
      paddingTop: 2,
      paddingBottom: 2,
    },
    "& .Mui-disabled": {
      color: theme.palette.secondary.main,
    },
  },
  dropdownText: {
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  coverageDisabledInfoWrapper: {
    "&.MuiListItem-root.Mui-disabled": {
      opacity: 1,
    },
  },
  coverageDisabledInfoBox: {
    height: 24,
    width: "100%",
    backgroundColor: "#EFF1FB",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  coverageDisabledInfoText: {
    height: 13,
    fontSize: 10.5,
    paddingRight: 8,
  },
  coverageFooter: {
    fontSize: 9,
    fontWeight: 500,
    color: "#B7BFDD",
  },
  coverageErrorWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: 8,
    width: 160,
  },
  coverageErrorIcon: {
    width: 10,
    height: 10,
    marginTop: 4,
    marginRight: 6,
    color: "#FF4343",
  },
  coverageErrorText: { fontSize: 11, color: "#FF4343", fontWeight: 500 },
  coverageWrapper_Vehicle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "18px 0px 18px 18px",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  vehicleTitleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 8,
    paddingTop: 36,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  vehicleInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#EFF1FB",
    marginRight: 0,
    maxWidth: 270,
    fontSize: 11,
    height: 36,
  },
  infoIcon: {
    width: 16,
    height: 16,
    margin: "0px 8px",
    color: theme.palette.primary.main,
  },
  vehicleTitle: {
    fontSize: 22,
    fontWeight: 600,
  },
  coverageSwitch: {
    display: "flex",
    marginLeft: "auto",
    marginTop: 0,
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0",
    },
  },
  button: {
    width: 183,
    height: 40,
  },
  modalFooter: {
    marginTop: 32,
    [theme.breakpoints.down('sm')]: {
      "& > div": {
        width: "50%",
        "& > button": {
          width: "100%",
        },
      },
    },
  },
}));
