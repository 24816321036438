import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UIState = {
  loading: false,
  loadingMessage: null,
  notification: undefined,
  showExitIntent: false,
  view: {},
  loadingAgency: false,
  fbUser: false,
  adminMenuOpen: false,
  agencyData: {
    id: -1,
    logo_path: "",
    theme: {},
    phone: "",
    name: "",
    default_agent_image_path: "",
    default_agent_firstname: "",
    logo_height: 80,
  },
};

export const ui = {
  ...createSlice({
    name: "ui",
    initialState,
    reducers: {
      hideNotification(state) {
        state.notification = undefined;
      },
      setLoading(state, { payload }: PayloadAction<boolean>) {
        state.loading = payload;
      },
      setLoadingMessage(state, { payload }: PayloadAction<string | null>) {
        state.loadingMessage = payload;
      },
      setView(state, { payload }: PayloadAction<Partial<UIViewState>>) {
        state.view = {
          ...state.view,
          ...payload,
        };
      },
      setShowExitIntent(state, { payload }: PayloadAction<boolean>) {
        state.showExitIntent = payload;
      },
      setFbUser(state, { payload }: PayloadAction<boolean>) {
        state.fbUser = payload;
      },
      showNotification(state, { payload }: PayloadAction<UINotification>) {
        if (!payload.duration) {
          payload.duration = 15000;
        }
        state.notification = payload;
      },
      setLoadingAgency(state, { payload }: PayloadAction<boolean>) {
        state.loadingAgency = payload;
      },
      setAgencyData(state, { payload }: PayloadAction<Agency>) {
        state.agencyData = payload;
      },
      setAdminMenuOpen(state, { payload }: PayloadAction<boolean>) {
        state.adminMenuOpen = payload;
      },
      toggleAdminMenuOpen(state) {
        state.adminMenuOpen = !state.adminMenuOpen;
      },
    },
  }),
  persist: false,
};

export interface UINotification {
  message: string;
  variant?: "error" | "warning" | "info" | "success";
  duration?: number;
}

export interface UIState {
  loading: boolean;
  loadingMessage: string | null;
  notification?: UINotification;
  view: UIViewState;
  showExitIntent: boolean;
  loadingAgency: boolean;
  fbUser: boolean;
  adminMenuOpen: boolean;
  agencyData: Agency;
}

interface Agency {
  id: number;
  logo_path: string;
  theme: any;
  phone: string;
  name: string;
  default_agent_image_path: string;
  default_agent_firstname: string;
  logo_height: number;
}

export type UIViewType = boolean | string | Record<string, any> | undefined;

export interface UIViewState {
  // vehicleSpecsDialog?: UIViewType;
}
