import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { CloseIcon } from "../../../../components/icons";

import {
  useAction,
  useSelector,
  homeSelectors,
  homeActions,
  wizardNoPersistSelectors,
  authSelectors,
} from "../../../../state";
import {
  getLimits,
  getDeductibles,
  getPercentages,
  formatCurrency,
} from "../../../../utils";
import { Form, useFormik, yup } from "../../../../lib";

import ReactGA from "react-ga";
import { useStyles } from "./customQuoteForm.styles";
import ScrollToOnError from "../../../../components/forms/ScrollToOnError";
// import { REACT_APP_BASE_API_URL } from "../../../config";
// import { CustomQuoteFormShape } from "../../../state/home/types";
import { useTheme } from "@mui/material/styles";
import { DwellingImage } from "../../../../assets/img/coverages/dwelling";
import { OtherStructImage } from "../../../../assets/img/coverages/otherStruct";
import { GeneralDedImage } from "../../../../assets/img/coverages/generalDed";
import { PersonalPropImage } from "../../../../assets/img/coverages/personalProp";
import { LossUseImage } from "../../../../assets/img/coverages/lossUse";
import { PersonalLiabImage } from "../../../../assets/img/coverages/personalLiab";
import { MedpayImage } from "../../../../assets/img/coverages/medpay";
import { WindDedImage } from "../../../../assets/img/coverages/windDed";
import { WaterBackupImg } from "../../../../assets/img/coverages/waterBackup";

const CustomQuoteForm = ({ handleCloseModal, bundled, setFormValue }) => {
  const classes = useStyles();
  const theme = useTheme();

  const setCustom = useAction(homeActions.setCustomData);
  const submitQuote = useAction(homeActions.submitQuote);
  const submitAgentQuote = useAction(homeActions.setAgentCustomForm);

  const mode = useSelector(wizardNoPersistSelectors.mode);
  const isRenters = mode === "renters" || mode === "auto-renters";

  const validationSchema = isRenters
    ? yup.object({})
    : yup.object({
        dwelling: yup.number().required().min(1000, "Invalid amount"),
      });
  const coverages = !isRenters
    ? useSelector(homeSelectors.customCoverages)
    : useSelector(homeSelectors.rentersCustomData);

  const { DwellingAmt } = useSelector(homeSelectors.details) || {};
  const customData = useSelector(homeSelectors.customData);

  const isAgent = useSelector(authSelectors.isLoggedIn);
  const [formSubmitted, setFormSubmitted] = useState(false);

  //Dynamic limits/deductibles
  const [lossUseOptions, setLossUseOptions] = useState<any>([]);
  const [otherStructOptions, setOtherStructOptions] = useState<any>([]);
  const [personalPropOptions, setPersonalPropOptions] = useState<any>([]);
  const [extendedRepOptions, setExtendedRepOptions] = useState<any>([]);
  const [windHailDedOptions, setWindHailDedOptions] = useState<any>([]);

  //Static limits/deductibles
  const medpayOptions = getLimits(coverages.medical_payments);
  const personalLiabOptions = getLimits(coverages.personal_liability);
  const waterBackupOptions = getLimits(coverages.water_backup);
  const windDedOptions = getDeductibles(coverages.wind_hail);
  const generalDedOptions = getDeductibles(coverages.other);

  //Requirements
  const lossUseRequired = coverages.loss_of_use?.isRequired;
  const medpayRequired = coverages.medical_payments?.isRequired;
  const otherStructRequired = coverages.other_structures?.isRequired;
  const personalPropRequired = coverages.personal_property?.isRequired;
  const extendedRepRequired = coverages.extended_replacement?.isRequired;
  const personalLiabRequired = coverages.personal_liability?.isRequired;
  const windDedRequired = coverages.wind_hail?.isRequired;
  const generalDedRequired = coverages.other?.isRequired;
  const waterBackupRequired = coverages.water_backup?.isRequired;

  const CustomQuoteInitialValues = {
    dwelling: customData?.dwelling || DwellingAmt,
    lossUse: customData?.lossUse || coverages.loss_of_use?.default?.Val1,
    medPay: customData?.medPay || coverages.medical_payments?.default?.Val1,
    otherStruct:
      customData?.otherStruct || coverages.other_structures?.default?.Val1,
    personalProp:
      customData?.personalProp || coverages.personal_property?.default?.Val1,
    extendedRep:
      customData?.extendedRep || coverages.extended_replacement?.default?.Val1,
    personalLiab:
      customData?.personalLiab || coverages.personal_liability?.default?.Val1,
    windDed: customData?.windDed || coverages.wind_hail?.default?.Val1,
    generalDed: customData?.generalDed || coverages.other?.default?.Val1,
    extendedRepEnabled: customData?.extendedRepEnabled === false ? false : true,
    waterBackup:
      customData?.waterBackup || coverages.water_backup?.default?.Val1,
    waterBackupEnabled: customData?.waterBackupEnabled || false,
    p_p_replacement_cost:
      customData?.p_p_replacement_cost === false ? false : true,
  };

  const initialValues: any = {
    ...CustomQuoteInitialValues,
    lossUseRequired,
    medpayRequired,
    otherStructRequired,
    personalPropRequired,
    extendedRepRequired,
    personalLiabRequired,
    windDedRequired,
    generalDedRequired,
    waterBackupRequired,
  };

  const form = useFormik({
    enableReinitialize: true,
    validationSchema,
    validateOnChange: formSubmitted,
    initialValues,
    async onSubmit(values) {
      setCustom(values);
      document.getElementById("wizardContentContainer")?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (isAgent && (mode === "bundle" || mode === "auto-renters")) {
        setFormValue();
        return;
      }

      handleCloseModal();
      if (isAgent) {
        submitAgentQuote();
        return;
      }
      submitQuote(bundled, true);
      ReactGA.event({
        category: "User",
        action: "Submitted Custom Quote",
      });
    },
  });

  useEffect(() => {
    ReactGA.event({
      category: "User",
      action: "Custom Quote Form Popup",
    });
  }, []);

  useEffect(() => {
    if (mode !== "renters") {
      setLossUseOptions(
        getPercentages(coverages.loss_of_use, form.values.dwelling),
      );
      setOtherStructOptions(
        getPercentages(coverages.other_structures, form.values.dwelling),
      );
      setPersonalPropOptions(
        getPercentages(coverages.personal_property, form.values.dwelling),
      );
      setExtendedRepOptions(
        getPercentages(coverages.extended_replacement, form.values.dwelling),
      );
      setWindHailDedOptions(
        getPercentages(coverages.wind_hail, form.values.dwelling),
      );
    }
  }, [form.values.dwelling, mode]);
  useEffect(() => {
    if (isRenters) {
      setLossUseOptions(getLimits(coverages.loss_of_use));
      setPersonalPropOptions(getLimits(coverages.personal_property));
    }
  }, [mode]);

  return <>
    {!isAgent ? (
      <Box className={classes.titleWrapper}>
        <Box>
          <Typography variant="h5" className={classes.title}>
            Create a custom quote
          </Typography>
        </Box>

        <Box className={classes.closeIconWrapper}>
          <IconButton edge="start" color="inherit" onClick={handleCloseModal} size="large">
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Box>
      </Box>
    ) : (
      <Box className={classes.titleWrapper}>
        <Box>
          <Typography variant="h5" className={classes.title}>
            Set {isRenters ? "renters" : "home"} coverages
          </Typography>
        </Box>
        {(mode === "home" || mode === "renters") && (
          <Box className={classes.closeIconWrapper}>
            <IconButton edge="start" color="inherit" onClick={handleCloseModal} size="large">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>
        )}
      </Box>
    )}

    <Form form={form}>
      <hr className={classes.divider} />
      {isRenters === false && (
        <>
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <DwellingImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Rebuilding coverage (Coverage A)
              </Typography>
              <Typography className={classes.description}>
                Pays to fix or rebuild your home in case of fire, hail,
                vandalism and other events.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <ScrollToOnError name="dwelling">
                  <OutlinedInput
                    // variant="outlined"
                    className={classes.dropdown}
                    id="dwelling"
                    name="dwelling"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    value={form.values.dwelling}
                    onChange={form.handleChange}
                    error={Boolean(
                      Number(form.values.dwelling) < Number(DwellingAmt),
                    )}
                  ></OutlinedInput>
                </ScrollToOnError>
                <Typography
                  style={{
                    // eslint-disable-next-line
                    color: Boolean(
                      Number(form.values.dwelling) < Number(DwellingAmt),
                    )
                      ? "#F44336"
                      : "#B7BFDD",
                    fontSize: 9,
                    fontWeight: 500,
                    marginTop: 4,
                  }}
                >
                  {form.errors.dwelling ||
                    (!isAgent && `Minimum ${formatCurrency(DwellingAmt)}`)}
                </Typography>
              </Box>
            </Box>
          </Box>
          {Boolean(extendedRepOptions?.length) && (
            <>
              <Box className={classes.coverageWrapper}>
                <Box className={classes.imgWrapper}></Box>
                <Box className={classes.coverageDescriptionWrapper}>
                  <Typography className={classes.label}>Extended</Typography>
                  <Typography className={classes.description}>
                    Extended coverage for repair or rebuilding in case of
                    price increase.
                  </Typography>
                </Box>
                <Box className={classes.coverageAmountWrapper}>
                  <Switch
                    className={classes.coverageSwitch}
                    checked={form.values.extendedRepEnabled}
                    color="primary"
                    onChange={() => {
                      form.setFieldValue(
                        "extendedRepEnabled",
                        !form.values.extendedRepEnabled,
                      );
                    }}
                    value={form.values.extendedRepEnabled}
                  />
                  <Box>
                    <Typography className={classes.coverageHeader}>
                      Extended Coverage
                    </Typography>
                    <ScrollToOnError name="extendedRep">
                      <TextField
                        select
                        variant="standard"
                        className={classes.dropdown}
                        id="extendedRep"
                        name="extendedRep"
                        disabled={!form.values.extendedRepEnabled}
                        value={form.values.extendedRep}
                        onChange={form.handleChange}
                        error={Boolean(form.errors.extendedRep)}
                        size="small"
                        InputProps={{
                          disableUnderline: true,
                          className: classes.dropdownText,
                        }}
                      >
                        {extendedRepOptions.map((coverage, i) => (
                          <MenuItem
                            key={`extendedRep-${i}`}
                            value={coverage.Val1}
                          >
                            {coverage.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ScrollToOnError>
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {Boolean(otherStructOptions?.length) && (
            <>
              <hr className={classes.divider} />
              <Box className={classes.coverageWrapper}>
                <Box className={classes.imgWrapper}>
                  <OtherStructImage fillColor={theme.palette.primary.main} />
                </Box>
                <Box className={classes.coverageDescriptionWrapper}>
                  <Typography className={classes.label}>
                    Other structures (Coverage B)
                  </Typography>
                  <Typography className={classes.description}>
                    Coverage for detached structures on your property, such as
                    a garage, fence or shed.
                  </Typography>
                </Box>
                <Box className={classes.coverageAmountWrapper}>
                  <Box>
                    <Typography className={classes.coverageHeader}>
                      Coverage
                    </Typography>
                    <ScrollToOnError name="otherStruct">
                      <TextField
                        select
                        variant="standard"
                        className={classes.dropdown}
                        id="otherStruct"
                        name="otherStruct"
                        value={form.values.otherStruct}
                        onChange={form.handleChange}
                        error={Boolean(form.errors.otherStruct)}
                        size="small"
                        InputProps={{
                          disableUnderline: true,
                          className: classes.dropdownText,
                        }}
                      >
                        {otherStructOptions.map((coverage, i) => (
                          <MenuItem
                            key={`otherStruct-${i}`}
                            value={coverage.Val1}
                          >
                            {coverage.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ScrollToOnError>
                    <Typography className={classes.coverageFooter}>
                      per incident
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <hr className={classes.divider} />
            </>
          )}
        </>
      )}

      {Boolean(generalDedOptions?.length) && (
        <>
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <GeneralDedImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                General deductible
              </Typography>
              <Typography className={classes.description}>
                Amount paid out of pocket before insurance starts paying when
                you make a claim.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Deductible
                </Typography>
                <ScrollToOnError name="generalDed">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="generalDed"
                    name="generalDed"
                    value={form.values.generalDed}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.generalDed)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {generalDedOptions.map((coverage, i) => (
                      <MenuItem key={`generalDed-${i}`} value={coverage.Val1}>
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {Boolean(personalPropOptions?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <PersonalPropImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Personal property (Coverage C)
              </Typography>
              <Typography className={classes.description}>
                Coverage for furniture, clothing, jewelry (limits apply),
                household goods, sporting goods, electronics, etc.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="personalProp">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="personalProp"
                    name="personalProp"
                    value={form.values.personalProp}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.personalProp)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {personalPropOptions.map((coverage, i) => (
                      <MenuItem
                        key={`personalProp-${i}`}
                        value={coverage.Val1}
                      >
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
              </Box>
            </Box>
          </Box>
          <>
            <Box className={classes.coverageWrapper}>
              <Box className={classes.noImage}></Box>
              <Box className={classes.coverageDescriptionWrapper}>
                <Typography className={classes.label}>
                  Replacement cost
                </Typography>
                <Typography className={classes.description}>
                  Pays to replace your personal belongings with new items of
                  the same type, kind, and quality if damaged or destroyed.
                </Typography>
              </Box>
              <Box className={classes.coverageAmountWrapper}>
                <Switch
                  className={classes.coverageSwitch}
                  checked={form.values.p_p_replacement_cost}
                  color="primary"
                  onChange={() => {
                    form.setFieldValue(
                      "p_p_replacement_cost",
                      !form.values.p_p_replacement_cost,
                    );
                  }}
                  value={form.values.p_p_replacement_cost}
                />
                <Box>
                  <TextField
                    variant="standard"
                    size="small"
                    className={classes.dropdown}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  ></TextField>
                </Box>
              </Box>
            </Box>
          </>
        </>
      )}
      {Boolean(lossUseOptions?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <LossUseImage />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Loss of use (Coverage D)
              </Typography>
              <Typography className={classes.description}>
                Pays for housing and additional living expenses if your house
                isn't livable.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="lossUse">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="lossUse"
                    name="lossUse"
                    value={form.values.lossUse}
                    defaultValue={null}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.lossUse)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {lossUseOptions.map((coverage, i) => (
                      <MenuItem key={`lossUse-${i}`} value={coverage.Val1}>
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {Boolean(personalLiabOptions?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <PersonalLiabImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Personal liability (Coverage E)
              </Typography>
              <Typography className={classes.description}>
                Coverage if you or a family member gets sued for an injury or
                for damaged property.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="personalLiab">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="personalLiab"
                    name="personalLiab"
                    value={form.values.personalLiab}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.personalLiab)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {personalLiabOptions.map((coverage, i) => (
                      <MenuItem
                        key={`personalLiab-${i}`}
                        value={coverage.Val1}
                      >
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {Boolean(medpayOptions?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <MedpayImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Medical payments (Coverage F)
              </Typography>
              <Typography className={classes.description}>
                Pays for injuries to another person due to an accident that's
                your fault.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="medPay">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="medPay"
                    name="medPay"
                    value={form.values.medPay}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.medPay)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {medpayOptions.map((coverage, i) => (
                      <MenuItem key={`medPay-${i}`} value={coverage.Val1}>
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {Boolean(windDedOptions?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <WindDedImage fillColor={theme.palette.primary.main} />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>
                Deductible for wind or hail
              </Typography>
              <Typography className={classes.description}>
                Amount paid out of pocket before insurance starts paying when
                you make a claim for wind or hail damages.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Typography className={classes.coverageHeader}>
                  Deductible
                </Typography>
                <ScrollToOnError name="windDed">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="windDed"
                    name="windDed"
                    value={form.values.windDed}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.windDed)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {windDedOptions.map((coverage, i) => (
                      <MenuItem key={`windDed-${i}`} value={coverage.Val1}>
                        {coverage.label}
                      </MenuItem>
                    ))}
                    {windHailDedOptions.map((coverage, i) => (
                      <MenuItem key={`windDed--${i}`} value={coverage.Val1}>
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {Boolean(waterBackupOptions?.length) && (
        <>
          <hr className={classes.divider} />
          <Box className={classes.coverageWrapper}>
            <Box className={classes.imgWrapper}>
              <WaterBackupImg />
            </Box>
            <Box className={classes.coverageDescriptionWrapper}>
              <Typography className={classes.label}>Water backup</Typography>
              <Typography className={classes.description}>
                Pays for damage to your home caused by water backing up into a
                home from a blocked sewer line, clogged drain, or sump pump
                failure.
              </Typography>
            </Box>
            <Box className={classes.coverageAmountWrapper}>
              <Box>
                <Switch
                  className={classes.coverageSwitch}
                  checked={form.values.waterBackupEnabled}
                  color="primary"
                  onChange={() => {
                    form.setFieldValue(
                      "waterBackupEnabled",
                      !form.values.waterBackupEnabled,
                    );
                  }}
                  value={form.values.waterBackupEnabled}
                />
                <Typography className={classes.coverageHeader}>
                  Coverage
                </Typography>
                <ScrollToOnError name="waterBackup">
                  <TextField
                    select
                    variant="standard"
                    className={classes.dropdown}
                    id="waterBackup"
                    name="waterBackup"
                    value={form.values.waterBackup}
                    disabled={!form.values.waterBackupEnabled}
                    onChange={form.handleChange}
                    error={Boolean(form.errors.waterBackup)}
                    size="small"
                    InputProps={{
                      disableUnderline: true,
                      className: classes.dropdownText,
                    }}
                  >
                    {waterBackupOptions.map((coverage, i) => (
                      <MenuItem
                        key={`waterBackup-${i}`}
                        value={coverage.Val1}
                      >
                        {coverage.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </ScrollToOnError>
                <Typography className={classes.coverageFooter}>
                  per incident
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      <hr className={classes.divider} />

      <Grid
        component="footer"
        container
        justifyContent="flex-end"
        alignItems="center"
        classes={{ container: classes.modalFooter }}
        spacing={2}
      >
        <Grid item>
          <Button
            size="large"
            type="reset"
            className={classes.button}
            onClick={handleCloseModal}
            style={{
              backgroundColor: "#eff1fb",
              lineHeight: "18px",
              padding: "8px 16px",
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            className={classes.button}
            onClick={() => setFormSubmitted(true)}
            disabled={
              !isAgent && Number(form.values.dwelling) < Number(DwellingAmt)
            }
          >
            {isAgent && (mode === "bundle" || mode === "auto-renters")
              ? "Next"
              : "Quote"}
          </Button>
        </Grid>
      </Grid>
    </Form>
  </>;
};

export default CustomQuoteForm;
