import makeStyles from '@mui/styles/makeStyles';
import insuranceLogosBanner from "../../../assets/img/insurance-logos-banner.jpg";

export const useStyles = makeStyles((theme) => ({
  containerWrapper: {
    width: "100%",
    borderLeft: ".5px solid #DADFF6",
    marginLeft: "-13px",
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      borderLeft: "none",
    },
  },
  container: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      marginLeft: 0,
    },
  },
  popper: {
    zIndex: 1200,
    "& .MuiListItem-button:hover": {
      backgroundColor: "#F7F9FF",
    },
    "& li": {
      height: 42,
    },
    "& .statuses": {
      width: 100,
      fontWeight: "500",
      textAlign: "center",
      fontSize: 15,
      marginRight: 15,
    },
    "& .New": {
      color: "#2eb662",
      backgroundColor: "#2ED27221",
    },
    "& .progress": {
      color: "blue",
      backgroundColor: "#d4ddff",
    },
    "& .Pending": {
      color: "#ffb20d",
      backgroundColor: "#FFCC002B",
    },
    "& .Closed": {
      color: "#757fa4",
      backgroundColor: "#eeeff5",
    },
  },
  topWrapper: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: 100,
    borderBottom: ".5px solid #DADFF6",
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      flexDirection: "column",
      paddingBottom: 20,
    },
  },
  mobileTopWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  drawerWrapper: { padding: "20px 0" },
  date: {
    marginLeft: 36,
  },
  lifeIcon: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
    },
  },
  divider: {
    margin: 0,
    border: ".5px solid #DADFF6",
    borderRadius: 3,
    // marginLeft: 24,
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  coverageWrapper: {
    height: "100%",
    padding: 40,
    width: "80%",
    maxWidth: 1280,
    [theme.breakpoints.down('md')]: {
      width: "100%",
      padding: 0,
      marginTop: 24,
    },
  },
  coverageHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0",
    [theme.breakpoints.down('md')]: {
      height: 82,
      backgroundColor: "#F7F9FF",
      marginBottom: 12,
    },
    [theme.breakpoints.down('sm')]: {
      height: 82,
      backgroundColor: "unset",
      margin: 0,
    },
  },
  switcherGroup: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 24,
      width: "95%",
    },
    "& .MuiButtonBase-root": {
      border: "1px solid #DADFF6",
      width: 150,
      height: 46,
      fontWeight: 600,
      fontSize: 14,
      textTransform: "initial",
      [theme.breakpoints.down('md')]: {
        height: 32,
        fontSize: 12,
      },
    },
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      border: "none",
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: "100%",
      minWidth: "100%",
      "& .MuiButtonBase-root": {
        width: "50%",
      },
    },
  },
  flex: { display: "flex", alignItems: "center" },
  multiSwitchWrapper: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      justifyContent: "flex-end",
      marginBottom: 12,
    },
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      display: "block",
    },
  },
  switchTxt: {
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      fontSize: 13,
    },
  },
  button: {
    color: theme.palette.primary.main,
    backgroundColor: "#E5EBFF",
    width: 172,
    marginLeft: 18,
    "&:hover": {
      cursor: "pointer",
      opacity: 0.75,
    },
  },
  buttonMobile: {
    color: "#babed1",
    background: "none",
    paddingRight: 24,
    "&:hover": {
      background: "none",
    },
  },
  mobileMoreOptions: {
    height: 116,
    width: 196,
    backgroundColor: "#FFFFFF",
    position: "absolute",
    right: 36,
    zIndex: 2,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    padding: 10,
    boxShadow: "0 2px 30px 0 rgba(141,152,194,0.34)",
    "& .MuiButtonBase-root": {
      marginLeft: 12,
      color: theme.palette.primary.main,
    },
    "& .MuiButton-root.Mui-disabled": {
      color: "#babed1",
    },
  },
  mobileMoreDivider: {
    width: "100%",
    border: ".5px solid #DADFF6",
    borderRadius: 3,
  },
  alert: {
    width: "100%",
    maxWidth: "100%",
    margin: "0 auto",
    backgroundColor: "#CDD2E9",
    opacity: 0.8,
    color: "#000000",
    "& .MuiAlert-icon": {
      color: "#000000",
    },
  },
  bundleAlert: {
    marginBottom: 20,
    // opacity: 0.25,
    borderRadius: 3,
    backgroundColor: "#ffd0d0",
    color: "#EA0D26",
    "& .MuiAlert-icon": {
      color: "#EA0D26",
    },
  },
  alertWrapper: {
    "& p": {
      marginBottom: 30,
      borderRadius: 3,
      color: "#0033FF",
      fontSize: 17,
      padding: "2px 8px",
      backgroundColor: "#d4ddff",
      width: "fit-content",
    },
  },
  insuranceLogosBanner: {
    height: 56,
    marginTop: 88,
    width: "100%",
    right: 40,
    background: `transparent url(${insuranceLogosBanner})`,
    backgroundSize: "cover",
    animation: "$infiniteShiftLeft 500s linear",
    "&:before": {
      content: "''",
      background:
        "linear-gradient(270deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)",
      height: 80,
      width: 172,
      display: "block",
    },
    "&:after": {
      content: "''",
      background:
        "linear-gradient(270deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)",
      height: 80,
      width: 172,
      transform: "scaleX(-1)",
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
    },
    [theme.breakpoints.down('md')]: {
      width: "calc(100vw - 248px)",
    },
    [theme.breakpoints.down('sm')]: {
      width: "calc(100vw - 80px)",
    },
  },
  "@keyframes infiniteShiftLeft": {
    "0%": {
      backgroundPosition: 50000,
    },
    "100%": {
      backgroundPosition: 0,
    },
  },
  quotesLoadingIcon: {
    position: "relative",
    width: 184,
    margin: "-120px auto 0",
    "& > div": {
      position: "relative",
      zIndex: 1,
    },
    "&:before": {
      content: "''",
      height: 80,
      width: 172,
      display: "block",
      position: "absolute",
      transform: "scaleX(-1)",
      top: "50%",
      marginTop: -40,
      left: -22,
    },
    "&:after": {
      content: "''",
      // TODO: need to fix for reli
      // background:
      //   "linear-gradient(270deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)",
      height: 80,
      width: 172,
      display: "block",
      position: "absolute",
      top: "50%",
      marginTop: -40,
      right: -22,
    },
  },
  quotesLoadingText: {
    fontSize: 32,
    fontWeight: 300,
    marginBottom: 16,
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  quotesLoadingSubtext: {
    fontSize: 15,
    textAlign: "center",
  },
  applicantTitleWrapper: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      verticalAlign: "middle",
    },
  },
  mobileDates: {
    display: "flex",
    borderTop: ".5px solid #DADFF6",
    padding: "20px 0",
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      marginRight: 20,
    },
  },
  text: {
    width: 90,
    fontSize: 14,
    fontWeight: 600,
    display: " inline-block",
  },
  applicantName: {
    fontSize: 26,
    marginRight: 12,
    fontWeight: 500,
  },
  carIcon: {
    marginRight: 8,
  },
  createdDate: {
    fontSize: 14,
    color: "#8D98C2",
  },
  backNavigationIcon: {
    height: 25,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      marginRight: 12,
    },
  },
  backNavigationWrapper: {
    "&:hover": {
      cursor: "pointer",
      opacity: 0.75,
    },
    [theme.breakpoints.down('sm')]: {
      float: "left",
    },
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 30,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      justifyContent: "space-between",
      flexDirection: "row",
      padding: "20px 0",
      width: "90%",
    },
  },
  nameWrapper: {
    display: "flex",
    minWidth: 150,
    margin: "0 10px",
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  historyIcon: {
    height: 18,
    color: theme.palette.primary.main,
  },
  ownersWrapper: {
    display: "inherit",
    marginLeft: 25,
    [theme.breakpoints.down('sm')]: {
      margin: "0 10px",
    },
  },
  agentWrapper: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "50%",
    height: 45,
    width: 45,
    border: "white 3px solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 99,
    cursor: "pointer",
  },
  agentCircle: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "50%",
    height: 45,
    width: 45,
    border: "white 3px solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 99,
  },
  directorWrapper: {
    color: "white",
    borderRadius: "50%",
    height: 40,
    width: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#0033FF",
    opacity: 0.5,
    marginLeft: "-7px",
    marginTop: "3px",
    cursor: "pointer",
  },
  topRight: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
      textAlign: "center",
      margin: "auto",
    },
    [theme.breakpoints.down('sm')]: {
      display: "initial",
      margin: 0,
    },
  },
  dropDown: {
    // put in when adding transfer ownership
    // margin: "0 40px",

    marginLeft: "40px",
    zIndex: 99,
    width: 350,
    "& .MuiButtonGroup-groupedContainedPrimary:not(:last-child)": {
      width: 300,
      [theme.breakpoints.down('sm')]: {
        width: "100%",
      },
      cursor: "text",
      "&:active": {
        boxShadow: "none",
      },
    },
    "& .MuiButtonGroup-contained ": {
      boxShadow: "none",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        margin: 0,
      },
    },
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: "100%",
      "& .MuiGrid-container": {
        display: "initial",
      },
      "& .MuiButton-contained:hover": {
        boxShadow: "none",
      },
    },
  },

  contactWrapper: {
    padding: 15,
    boxShadow: "0 2px 50px 0 rgba(141,152,194,0.23)",
  },
  contactItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "start",
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      height: 18,
      marginRight: 10,
      marginTop: 2,
    },
    "&:last-of-type": {
      alignItems: "center",

      "& .MuiSvgIcon-root": {
        transform: "rotate(90deg)",
        [theme.breakpoints.down('sm')]: {
          display: "none",
        },
      },
      "& .MuiButton-text": { paddingLeft: 0 },
    },
  },
  contactTxt: {
    fontSize: 14,
    marginBottom: 12,
  },
  agentViewTop: {
    display: "flex",
    paddingBottom: 15,
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
      height: 18,
      marginTop: 2,
      cursor: "pointer",
    },
  },
  agentMiddle: {
    padding: "0 10px",
  },
  agentName: {
    fontSize: 16,
    fontWeight: 500,
  },
  buttons: {
    backgroundColor: ({ status }: any) =>
      status === "New"
        ? "#2ED27221"
        : status === "In progress"
        ? "#d4ddff"
        : status === "Pending"
        ? "#FFCC002B"
        : status === "Closed"
        ? "#eeeff5"
        : "",
    color: ({ status }: any) =>
      status === "New"
        ? "#2eb662"
        : status === "In progress"
        ? "blue"
        : status === "Pending"
        ? "#ffb20d"
        : status === "Closed"
        ? "#757fa4"
        : "",
    "&:hover": {
      color: ({ status }: any) =>
        status === "New"
          ? "#2eb662"
          : status === "In progress"
          ? "blue"
          : status === "Pending"
          ? "#ffb20d"
          : status === "Closed"
          ? "#757fa4"
          : "",
      backgroundColor: ({ status }: any) =>
        status === "New"
          ? "#2ED27221"
          : status === "In progress"
          ? "#d4ddff"
          : status === "Pending"
          ? "#FFCC002B"
          : status === "Closed"
          ? "#eeeff5"
          : "",
    },
  },

  editBtn: {
    padding: 0,
    height: 48,
    minWidth: 48,
    marginLeft: 25,
  },
}));
