import React from "react";
import { Box, Button, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

import { defaultTheme } from "../../../../../themes";
import { Navigation, useLocation } from "../../../../../lib";
import { useAction, wizardActions } from "../../../../../state";

import { WizardSide1 } from "../../../../../assets/img/auto/wizard-side-1";
import { WizardSide2 } from "../../../../../assets/img/auto/wizard-side-2";
import { WizardSide3 } from "../../../../../assets/img/auto/wizard-side-3";
import { WizardSide4 } from "../../../../../assets/img/auto/wizard-side-4";
import { WizardSide5 } from "../../../../../assets/img/auto/wizard-side-5";

import { Home2Image } from "../../../../../assets/img/home/two";
import { Home3Image } from "../../../../../assets/img/home/three";
import { Home4Image } from "../../../../../assets/img/home/four";
import { Home5Image } from "../../../../../assets/img/home/five";

import beachImage from "../../../../../assets/img/life/beach.svg";
import healthImage from "../../../../../assets/img/life/health.svg";
import familyImage from "../../../../../assets/img/life/family.svg";

const useStyles = makeStyles((theme) => ({
  sidebarImg: {
    bottom: 56,
    left: 24,
    width: 400,
    padding: 40,
    [theme.breakpoints.down("md")]: {
      left: 16,
      bottom: 40,
      width: 200,
      maxWidth: "100%",
      padding: 0,
    },
    "& svg": {
      maxWidth: "100%",
      height: "auto",
    },
    // eslint-disable-next-line no-useless-computed-key
    ["@media (max-height: 600px)"]: { display: "none" },
  },
  bundleBoxContainer: {
    position: "relative",
    "& svg": {
      width: 184,
      position: "absolute",
      bottom: 4,
      left: -84,
      zIndex: 2,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
  bundleBox: {
    height: 208,
    width: 200,
    maxWidth: "100%",
    border: "1px solid #FFFFFF",
    borderRadius: 3,
    padding: 24,
    fontSize: 16,
    color: "#ffffff",
    fontWeight: 500,
    lineHeight: "24px",
    position: "relative",
    bottom: 64,
    "& p": {
      margin: 0,
    },
    "& button": {
      marginTop: 24,
      width: "100%",
      backgroundColor: "#d5d5d5",
      color: "#000",
      [theme.breakpoints.down("md")]: {
        marginTop: 24,
        padding: "0 8px",
        fontSize: 10,
        height: 32,
        margin: "0 -16px",
        width: "calc(100% + 32px)",
        borderRadius: 0,
        marginBottom: -16,
      },
    },
    [theme.breakpoints.down("md")]: {
      height: "auto",
      bottom: 0,
      padding: 16,
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      position: "fixed",
      bottom: 0,
      background: "#fff",
      boxShadow: "0 2px 24px 0 #dadff6, 0 2px 40px 0 #eff1fb",
      padding: 16,
    },
  },
}));
const WizardStepImg = () => {
  const classes = useStyles();
  const location = useLocation();

  const upgradeToBundle = useAction(wizardActions.upgradeToBundle);

  const getImgUrl = () => {
    switch (location.pathname) {
      // auto
      case "/auto/wizard/start":
      case "/life/wizard/start":
      case "/bundle/wizard/start":
      case "/auto-renters/wizard/start":
      case "/auto/wizard/verify":
      case "/bundle/wizard/verify":
      case "/auto-renters/wizard/verify":
      case "/auto/wizard/code-entry":
      case "/bundle/wizard/code-entry":
      case "/auto-renters/wizard/code-entry":
        return (
          <WizardSide1
            fillColor={defaultTheme.palette.primary.main}
            fillContrast={defaultTheme.palette.info.main}
          />
        );
      case "/auto/wizard/vehicles":
      case "/bundle/wizard/vehicles":
      case "/auto-renters/wizard/vehicles":
        return (
          <WizardSide2
            fillColor={defaultTheme.palette.primary.main}
            fillContrast={defaultTheme.palette.info.main}
          />
        );
      case "/life/wizard/coverage":
        return <img src={beachImage} alt="beachImage" />;
      case "/life/wizard/insured":
        return <img src={healthImage} alt="healthImage" />;
      case "/life/wizard/quotes":
        return <img src={familyImage} alt="familyImage" />;
      case "/auto/wizard/drivers":
      case "/auto-renters/wizard/drivers":
        return (
          <WizardSide3
            fillColor={defaultTheme.palette.primary.main}
            fillContrast={defaultTheme.palette.info.main}
          />
        );
      case "/auto/wizard/discounts":
      case "/bundle/wizard/auto-discounts":
      case "/auto-renters/wizard/auto-discounts":
        return (
          <WizardSide4
            fillColor={defaultTheme.palette.primary.main}
            fillContrast={defaultTheme.palette.info.main}
          />
        );
      case "/life/wizard/contact":
      case "/auto/wizard/contact":
        return (
          <WizardSide5
            fillColor={defaultTheme.palette.primary.main}
            fillContrast={defaultTheme.palette.info.main}
          />
        );
      case "/auto/wizard/quotes":
        return (
          <div className={classes.bundleBoxContainer}>
            <Home2Image
              fillColor={defaultTheme.palette.primary.main}
              fillContrast={defaultTheme.palette.info.main}
            />
            <div className={classes.bundleBox}>
              <Typography>
                See what you can save by bundling your Auto & Home insurance.
              </Typography>
              <Button
                onClick={() => {
                  upgradeToBundle("auto");
                  Navigation.go("/bundle/wizard/details/form");
                }}
              >
                Bundle + Save
              </Button>
            </div>
          </div>
        );
      //home - renters
      case "/home/wizard/start":
      case "/renters/wizard/start":
      case "/bundle/wizard/continue":
      case "/auto-renters/wizard/continue":
      case "/home/wizard/details/form":
      case "/renters/wizard/details/form":
      case "/bundle/wizard/details/form":
      case "/auto-renters/wizard/details/form":
      case "/home/wizard/details/confirm":
      case "/bundle/wizard/details/confirm":
        return (
          <Home2Image
            fillColor={defaultTheme.palette.primary.main}
            fillContrast={defaultTheme.palette.info.main}
          />
        );
      case "/home/wizard/discounts":
      case "/renters/wizard/discounts":
      case "/bundle/wizard/discounts":
      case "/bundle/wizard/home-discounts":
      case "/auto-renters/wizard/discounts":
      case "/auto-renters/wizard/home-discounts":
        return (
          <Home3Image
            fillColor={defaultTheme.palette.primary.main}
            fillContrast={defaultTheme.palette.info.main}
          />
        );
      case "/home/wizard/insured":
      case "/renters/wizard/insured":
      case "/bundle/wizard/insured":
      case "/auto-renters/wizard/insured":
        return (
          <Home4Image
            fillColor={defaultTheme.palette.primary.main}
            fillContrast={defaultTheme.palette.info.main}
          />
        );
      case "/home/wizard/contact":
      case "/renters/wizard/contact":
      case "/bundle/wizard/contact":
      case "/auto-renters/wizard/contact":
        return (
          <Home5Image
            fillColor={defaultTheme.palette.primary.main}
            fillContrast={defaultTheme.palette.info.main}
          />
        );
      case "/home/wizard/quotes":
        return (
          <div className={classes.bundleBoxContainer}>
            <WizardSide2
              fillColor={defaultTheme.palette.primary.main}
              fillContrast={defaultTheme.palette.info.main}
            />
            <div className={classes.bundleBox}>
              <Typography>
                See what you can save by bundling your Auto & Home insurance.
              </Typography>
              <Button
                onClick={() => {
                  upgradeToBundle("home");
                  Navigation.go("/bundle/wizard/start");
                }}
              >
                Bundle + Save
              </Button>
            </div>
          </div>
        );
      default:
        return "";
    }
  };

  return (
    <Box
      className={classes.sidebarImg}
      style={getImgUrl() === "" ? { display: "none" } : {}}
    >
      {getImgUrl()}
    </Box>
  );
};

export default React.memo(WizardStepImg);
