import React, { useEffect, useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { usePlacesWidget } from "react-google-autocomplete";
import { RoomIcon } from "../../../icons";

import { REACT_APP_GOOGLE_API_TOKEN } from "../../../../config";
import { googlePlaceToAddress } from "../../../../lib/googlePlaceToAddress";

import { useStyles } from "./AddressAsyncAutocomplete.styles";
const pfAddress = window.prefill_token_data?.Contact?.Address || "";

const AddressAsyncAutocomplete = ({ form, name = "" }) => {
  const classes = useStyles();
  const addressSelect = useRef<Boolean>(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");

  const { ref } = usePlacesWidget({
    options: { types: ["address"], componentRestrictions: { country: "us" } },
    apiKey: REACT_APP_GOOGLE_API_TOKEN,
    inputAutocompleteValue: pfAddress,
    onPlaceSelected: (place) => {
      if (!place || !place.formatted_address) return;
      addressSelect.current = true;
      setAutoCompleteValue(place.formatted_address);
      const {
        streetNumber,
        streetName,
        zip,
        state,
        full_state,
        city,
        formatted_address,
      } = googlePlaceToAddress(place);
      const address = {
        zipcode: zip,
        state,
        full_state,
        city,
        street_line: `${streetNumber} ${streetName}`,
        label: formatted_address,
        location: {
          type: "Point",
          coordinates: [
            place.geometry.location.lng(),
            place.geometry.location.lat(),
          ],
        },
      };
      if (name) {
        form.setFieldValue(name, address);
      } else {
        form.setFieldValue("address", address);
      }
    },
  });
  const autoCompleteRef: RefObject<HTMLInputElement> = ref;
  useEffect(() => {
    if (pfAddress && autoCompleteRef) {
      setTimeout(() => {
        if (autoCompleteRef.current) {
          autoCompleteRef.current.value = pfAddress;
        }
        autoCompleteRef.current?.focus();
      }, 500);
    }
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (name) {
      if (form.isValid && form?.values?.[name]?.state) {
        setAutoCompleteValue(form?.values?.[name]?.label);
      }
    } else {
      if (form.isValid && form?.values?.address?.state) {
        setAutoCompleteValue(form?.values?.address?.label);
      }
    }
  }, []);
  useEffect(() => {
    const autoComplete = window.document.querySelector("#autocomplete");
    let notAutoCompleted = true;
    if (autoComplete) {
      notAutoCompleted =
        window.getComputedStyle(autoComplete, ":-webkit-autofill")
          .backgroundColor === "rgba(0, 0, 0, 0)" ||
        window.getComputedStyle(autoComplete, ":-webkit-autofill")
          .backgroundColor === "rgb(0, 0, 0)" ||
        window.getComputedStyle(autoComplete, ":-webkit-autofill")
          .backgroundColor === "";
    }

    if (!notAutoCompleted) {
      setTimeout(() => {
        autoCompleteRef.current && autoCompleteRef.current.focus();
      }, 1000);
    }
  }, [form.values, autoCompleteValue]);
  const handleOnBlur = (e) => {
    if (!name) {
      form.handleBlur(e);
    }
    if (!addressSelect.current) {
      setAutoCompleteValue("");
    }
  };
  return (
    <Box className={classes.wrap}>
      <TextField
        id="autocomplete"
        // label="Address"
        fullWidth
        inputRef={ref}
        placeholder="Address"
        onBlur={handleOnBlur}
        inputProps={{
          className: classes.field,
        }}
        value={autoCompleteValue}
        onChange={(e) => setAutoCompleteValue(e.target.value)}
        // do not use endAdornment for the icon since calculated width of dropdown will be wrong
        // InputProps={{ endAdornment: <RoomIcon style={{ opacity: 0.5 }} /> }}
      />
      <RoomIcon className={classes.icon} />
    </Box>
  );
};

export default React.memo(AddressAsyncAutocomplete);
