import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  formCard: {
    borderRadius: 0,
    marginBottom: "2em",
  },
  formWrap: {
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formControl: {
    width: "50%",
    minWidth: 330,
    padding: "0 2em 2em",
  },
  formAction: {
    paddingRight: "2em",
  },
  note: {
    fontSize: 14,
    lineHeight: 1.5,
    color: theme.palette.secondary.main,
  },
  selectLoader: {
    position: "absolute",
    right: 35,
  },
  jsonInput: {
    paddingBlock: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
}));
