import React from "react";
import { Typography } from "@mui/material";
import { useStyles } from "./WizardCompleteCard.styles";
import { useSelector, wizardSelectors } from "../../../../state";
import { ContactConfirm } from "../../../../assets/img/contact_confirm";
import { useTheme } from "@mui/material/styles";

const WizardCompleteCard = () => {
  const classes = useStyles({});
  const theme = useTheme();

  const contactType = useSelector(wizardSelectors.contactType);

  return (
    <div className={classes.wrapper}>
      <ContactConfirm fillColor={theme.palette.primary.main} />
      <Typography className={classes.paragraph}>
        Great! An insurance expert will {contactType} you with a final quote.
      </Typography>
      <Typography className={classes.subparagraph}>
        A licensed agent will review your quote and {contactType} you with the
        final accurate price.
      </Typography>
    </div>
  );
};

export default WizardCompleteCard;
