import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors, useAction } from "../../../state";
import { InfoIcon, SendIcon } from "../../../components/icons";
import { useStyles } from "./CoverageCard.styles";
import { formatCurrency } from "../../../utils";
import { LaunchIcon, ShoppingCartIcon } from "../../../components";
import { useMobile } from "../../../themes";

interface Props {
  quote: Quote | any;
  isLast: boolean;
  hasPurchased: any;
}

interface Quote {
  insurance_level_id: any;
  request_status: string;
  displayPremium: number;
  carrier_bind_url: string;
  req_txn_id: string;
  carrier: {
    name: string;
    logo_full_url: string;
    carrier_notes: string;
    notes_title: string;
  };
  response_payload: any;
}

export const CoverageCard = ({ quote, isLast, hasPurchased }: Props) => {
  const dispatch = useDispatch();
  const activeQuote = useSelector(adminSelectors.activeQuote);
  const bundleBindData = useSelector(adminSelectors.bundleBindData);
  const quoteStatus = useSelector(adminSelectors.quoteStatus);

  const setBundleBindData = useAction(adminActions.setBundleBindData);
  const selectedQuoteAuto = activeQuote?.applicant.selected_quote_auto;
  const selectedQuoteHome = activeQuote?.applicant.selected_quote_home;
  const selectedLifeQuoteId = activeQuote.applicant.selected_quote_life;

  const isLife = activeQuote.quoteType === "Life";
  const isBundle =
    activeQuote.quoteType === "Bundle" ||
    activeQuote.quoteType === "AutoRenters";
  const manualQuote = !!quote.manual_quote_type;

  const [showPurchase, setShowPurchase] = useState(false);
  const [pending, setPending] = useState(false);
  const isSelectedQuote = hasPurchased?.length
    ? null
    : quote.req_txn_id === selectedQuoteAuto ||
      quote.req_txn_id === selectedQuoteHome ||
      (!!isLife && quote.companyCode === selectedLifeQuoteId?.split("+")[1]);
  const purchased =
    quote.full_premium_override ||
    (isLife && !!activeQuote.applicant.bound && isSelectedQuote);
  const classes = useStyles({ isLast, isSelectedQuote, purchased, pending });

  useEffect(() => {
    if (!isBundle) return;
    if (
      bundleBindData[quote.quote_type] &&
      bundleBindData[quote.quote_type]?.turbo_quote_id === quote.req_txn_id
    ) {
      setPending(true);
    } else {
      setPending(false);
    }
  }, [bundleBindData, quote]);

  const carrierLink = quote.carrier_bind_url || quote.quote_url;
  const handleBind = () => {
    if (isBundle) {
      setPending(true);
    }
    if (!isBundle) {
      dispatch(adminActions.setBindModalOpen(true));
      setBundleBindData({
        singleQuoteType: isLife
          ? "life"
          : quote.quote_type || quote.manual_quote_type,
        isBundle: false,
        req_uid: activeQuote.applicant.req_uid,
        carrierName: quote.manual_carrier_name,
        effectiveDate:
          activeQuote.applicant.effective_date?.replace("Z", "") || new Date(),
        [isLife ? "life" : quote.quote_type || quote.manual_quote_type]: {
          isLinked: quote.is_linked,
          premium: isLife
            ? quote.premium.replace(",", "")
            : quote.displayPremium,
          logo: quote.carrier?.logo_full_url,
          insurance_level_id: quote.insurance_level_id,
          turbo_quote_id: isLife
            ? `${activeQuote.applicant.req_uid}+${quote.companyCode}`
            : quote.req_txn_id,
          quoteType: quote.quote_type || quote.manual_quote_type,
        },
      });
    } else {
      setBundleBindData({
        ...bundleBindData,
        isLinked: quote.is_linked,
        forPurchase: true,
        singleQuoteType: quote.quote_type,
        req_uid: activeQuote.applicant.req_uid,
        masterQuoteType: activeQuote.quoteType,
        effectiveDate:
          activeQuote.applicant.effective_date?.replace("Z", "") || new Date(),
        [quote.quote_type]: {
          isLinked: quote.is_linked,
          premium: quote.displayPremium,
          logo: quote.carrier.logo_full_url,
          insurance_level_id: quote.insurance_level_id,
          turbo_quote_id: quote.req_txn_id,
          quoteType: quote.quote_type,
        },
      });
    }
  };

  const handleSendEmail = () => {
    dispatch(adminActions.setEmailModalOpen(true));
    dispatch(
      adminActions.setEmailModalData({
        premium: quote.displayPremium,
        logo: quote.carrier.logo_full_url,
        emailAddress: activeQuote.applicant.email,
        insurance_level_id: quote.insurance_level_id
          ? quote.insurance_level_id
          : null,
        turbo_quote_id: isLife
          ? `${activeQuote.applicant.req_uid}+${quote.companyCode}`
          : quote.req_txn_id,
      }),
    );
  };

  const PurchaseButton = () => {
    return (
      <Button className={classes.emailLink} onClick={() => handleBind()}>
        <ShoppingCartIcon />
      </Button>
    );
  };

  const isMobile = useMobile();
  const errors =
    quote.response_payload &&
    !!quote.response_payload.length &&
    quote.response_payload[0].Errors;
  const fatalErrors =
    quote.response_payload &&
    !!quote.response_payload.length &&
    quote.response_payload[0].FatalErrors;
  const carrierNotes = quote.carrier?.carrier_notes;

  return (
    <Box className={classes.root}>
      {!manualQuote &&
        isSelectedQuote &&
        !activeQuote.applicant.bound &&
        !purchased &&
        !pending && (
          <Box className={classes.selectedQuoteTag}>
            <Typography>Selected Quote</Typography>
          </Box>
        )}
      {purchased && (isLife ? isSelectedQuote : true) && (
        <Box className={classes.selectedQuoteTag}>
          <Typography>Purchased</Typography>
        </Box>
      )}
      {pending && !activeQuote.applicant.bound && (
        <Box className={classes.selectedQuoteTag}>
          <Typography>Pending</Typography>
        </Box>
      )}
      {!(isMobile && manualQuote) ? (
        <Box
          className={classes.card}
          onMouseEnter={() => setShowPurchase(true)}
          onMouseLeave={() => setShowPurchase(false)}
        >
          <Box className={classes.cardLeft}>
            {quote.carrier?.logo_full_url && !quote.manual_carrier_name ? (
              <div className={classes.imgWrapper}>
                <img
                  className={classes.carrierImg}
                  src={quote.carrier?.logo_full_url}
                  alt="carrier_logo"
                />
                {quote.carrier?.notes_title && (
                  <div className={classes.infoContainer}>
                    {quote.carrier.notes_title}
                    <span
                      onClick={() => {
                        dispatch(adminActions.setCarrierNotes(carrierNotes));
                        dispatch(adminActions.setCarrierModalOpen(true));
                      }}
                    >
                      <InfoIcon className={classes.carrierInfoIcon} />
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <Typography className={classes.carrierName}>
                {quote.manual_carrier_name}
              </Typography>
            )}
            {manualQuote && (
              <Typography className={classes.manualQuoteText}>
                {quote.manual_quote_type} Insurance
              </Typography>
            )}
            {quote.displayPremium > 0 &&
              showPurchase &&
              !isMobile &&
              !(
                quoteStatus.status === "Closed" &&
                quoteStatus.sub_status === "Won"
              ) && (
                <div
                  className={classes.purchaseBtn}
                  onClick={() => handleBind()}
                >
                  Mark as purchased
                </div>
              )}
            {/* {isLife && (
            <Box className={classes.lifeProductInfo}>{quote.productName}</Box>
          )} */}
          </Box>
          {(errors?.length === 0 && fatalErrors?.length === 0) ||
          isLife ||
          manualQuote ? (
            <Box className={classes.quoteDataWrapper}>
              <Typography className={classes.quotePremiumTxt}>
                {quote.displayPremium > 0
                  ? formatCurrency(Math.round(quote.displayPremium))
                  : "Option N/A"}
              </Typography>
              {isMobile && !manualQuote && quote.displayPremium > 0 && (
                <Button
                  className={classes.emailLink}
                  onClick={() => handleSendEmail()}
                >
                  <SendIcon />
                </Button>
              )}
              {isMobile &&
                quote.displayPremium > 0 &&
                !(
                  quoteStatus.status === "Closed" &&
                  quoteStatus.sub_status === "Won"
                ) && <PurchaseButton />}
              <Box className={classes.linksWrapper}>
                {!isLife && (
                  <Link target="_blank" href={carrierLink}>
                    <Box className={classes.bindLink}>
                      {manualQuote ? "View in carrier" : "Bind"}
                      <LaunchIcon />
                    </Box>
                  </Link>
                )}
                {!isMobile && !manualQuote && quote.displayPremium > 0 && (
                  <span onClick={() => handleSendEmail()}>
                    <SendIcon className={classes.emailLink} />
                  </span>
                )}
              </Box>
            </Box>
          ) : (
            <Grid
              container
              style={{ width: "auto", paddingRight: 5 }}
              alignItems="center"
            >
              <Typography className={classes.quoteFailedText}>
                Quote Failed
              </Typography>
              <Tooltip
                title={
                  <List>
                    {errors?.length ? (
                      errors?.map((w, quote) => (
                        <ListItem key={quote}>{w.Text}</ListItem>
                      ))
                    ) : fatalErrors?.length ? (
                      fatalErrors?.map((error, quote) => (
                        <ListItem key={quote}>{error}</ListItem>
                      ))
                    ) : quote.response_payload?.Message ? (
                      <ListItem>
                        Message: {quote.response_payload?.Message}
                      </ListItem>
                    ) : null}
                  </List>
                }
              >
                <InfoIcon className={classes.quoteFailedIcon} />
              </Tooltip>
              {!isMobile && <span style={{ width: 49 }}></span>}
            </Grid>
          )}
        </Box>
      ) : (
        // mobile manual quote
        <Box className={classes.mobileQuoteWrapper}>
          <Box className={clsx(classes.flex, classes.topDiv)}>
            <Box>
              {!quote.manual_carrier_name ? (
                <img
                  className={classes.carrierImg}
                  src={quote.carrier?.logo_full_url}
                  alt="carrier_logo"
                />
              ) : (
                <Typography>{quote.manual_carrier_name}</Typography>
              )}
            </Box>
            <Box className={classes.flex}>
              <Link target="_blank" href={carrierLink}>
                <Box className={classes.bindLink}>
                  View in carrier
                  <LaunchIcon />
                </Box>
              </Link>
              <span style={{ width: 20 }}></span>
              <PurchaseButton />
            </Box>
          </Box>
          <Box className={classes.flex}>
            <Box>
              <Typography className={classes.manualQuoteText}>
                {quote.manual_quote_type} Insurance
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.quotePremiumTxt}>
                {formatCurrency(Math.round(quote.displayPremium))}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
