import React from "react";
import { Button, Grid, Hidden, Typography } from "@mui/material";
import img from "../../../assets/img/tekton-wrench-unsplash.png";
import logo from "../../../assets/img/5minute_logo.svg";
import { useMobile } from "../../../themes";
import { uiSelectors, useSelector } from "../../../state";
import { useStyles } from "./SomethingWentWrong.styles";
import { formatPhone } from "../../../lib";

const SomethingWentWrong = () => {
  const classes = useStyles();
  const isMobile = useMobile();
  const { phone } = useSelector(uiSelectors.agencyData);

  return <>
    <Grid item component="header" className={classes.header} id="header">
      <Grid
        container
        alignItems="center"
        justifyContent={isMobile ? "center" : "space-between"}
        className={classes.headerContent}
      >
        <Grid item className={classes.logoWrapper}>
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </Grid>
        <Hidden smDown>
          <Grid item>
            <Typography className={classes.helpTextLight}>
              Need help? Call us at{" "}
              <a href={`tel:${phone}`} className={classes.phoneLink}>
                {formatPhone(phone)}
              </a>
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>

    <div className={classes.root}>
      <img alt="wrench" src={img} style={{ maxWidth: "100%" }} />
      <Typography className={classes.title}>Something went wrong</Typography>
      <Typography className={classes.subTitle}>
        We’re sorry. Don’t worry though. Our best man is on the case
      </Typography>
      <Button color="primary" variant="contained" href="/">
        Return Home
      </Button>
    </div>
  </>;
};

export default SomethingWentWrong;
