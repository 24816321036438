import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import {
  EXTERIOR_WALLS_OPTIONS,
  EXTERIOR_WALLS_OPTIONS_INTERNAL,
  FOUNDATION_TYPE,
  ROOF_TYPE,
  STORY_TYPE,
  NUMBERS,
  FAMILY_UNITS,
  ROOF_TYPE_INTERNAL,
  STORY_TYPE_INTERNAL,
} from "../../../../state/home/types";
import {
  getLastHundredYears,
  replaceWhiteSpaceWithPlus,
  moreDetailsValidation as validationSchema,
} from "../../../../utils";
import {
  authSelectors,
  homeActions,
  homeSelectors,
  useAction,
  useSelector,
  wizardNoPersistSelectors,
} from "../../../../state";
import { getRecaptchaToken, Navigation } from "../../../../lib";
import { useMobile } from "../../../../themes";
import { useStyles } from "./Details.styles";
import { Link } from "react-router-dom";

export default function ConfirmDetails() {
  const classes = useStyles();
  const isMobile = useMobile();

  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const details = useSelector(homeSelectors.details);
  const { address, apt } = useSelector(homeSelectors.start);

  const defaultRoofBuilt =
    details.YearOfConstruction > new Date().getFullYear() - 5
      ? details.YearOfConstruction
      : new Date().getFullYear() - 5;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const setHomeDetails = useAction(homeActions.setHomeDetails);
  const getPropertyLookup = useAction(homeActions.getPropertyLookup);
  const years = getLastHundredYears();

  const [storyTypes, setStoryTypes] = useState(STORY_TYPE);
  const [roofTypes, setRoofTypes] = useState(ROOF_TYPE);
  const [exteriorWallsTypes, setExteriorWallsTypes] = useState(
    EXTERIOR_WALLS_OPTIONS,
  );

  const { zipcode = "", state = "", city = "", street_line = "" } = address;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      YearOfConstruction: details.YearOfConstruction,
      SquareFootage: details.SquareFootage,
      StoryType:
        STORY_TYPE.filter((x) => x.label === details.StoryType)[0]?.value ||
        STORY_TYPE_INTERNAL.filter((x) => x.label === details.StoryType)[0]
          ?.value ||
        details.StoryType,
      exteriorWalls:
        details.exteriorWalls ||
        EXTERIOR_WALLS_OPTIONS.filter(
          (x) => x.label === details.Construction,
        )[0]?.value ||
        EXTERIOR_WALLS_OPTIONS_INTERNAL.filter(
          (x) => x.label === details.Construction,
        )[0]?.value,
      foundation:
        details.foundation ||
        FOUNDATION_TYPE.filter((x) => x.label === details?.FoundationType)[0]
          ?.value,
      roofType:
        details.roofType ||
        ROOF_TYPE.filter((x) => x.label === details?.RoofComposition)[0]
          ?.value ||
        ROOF_TYPE_INTERNAL.filter(
          (x) => x.label === details?.RoofComposition,
        )[0]?.value,
      roofBuilt: details.roofBuilt || defaultRoofBuilt,
      NumberOfFamilies: details.NumberOfFamilies,
      NumberOfFullBaths: details.NumberOfFullBaths,
      numberOfHalfBaths: details.numberOfHalfBaths || 1,
      SwimmingPool: details.SwimmingPool,
      fencedInPool: details.fencedInPool || true,
      poolType: details.poolType || "InGround",
      trampoline: details.trampoline || "false",
      yearPurchased: details.yearPurchased,
    },
    validationSchema: validationSchema,
    validateOnChange: formSubmitted,
    async onSubmit(values) {
      await setHomeDetails(values);
      if (!details.DwellingAmt) {
        const grecaptchaToken = await getRecaptchaToken();
        const { propertyType } = details;
        const lookupAddress = {
          address1: `${address.street_line} ${apt}`,
          city: address.city,
          state: address.state,
          zipCode: address.zipcode,

          yearOfConstruction: +values.YearOfConstruction,
          squareFootage: +values.SquareFootage,
          numberOfFamilies: +values.NumberOfFamilies,
          storyType:
            values.StoryType === "SplitLevel" ||
            values.StoryType === "OneAndOneHalfStories"
              ? "TwoStories"
              : values.StoryType,
        };
        await getPropertyLookup(
          lookupAddress,
          grecaptchaToken,
          false,
          propertyType,
        );
      }

      if (mode === "home") {
        Navigation.go("/home/wizard/discounts");
      }
      if (mode === "bundle") {
        Navigation.go("/bundle/wizard/home-discounts");
      }
    },
  });

  useEffect(() => {
    if (
      formik.values.roofType &&
      !ROOF_TYPE.find((x) => x.value === formik.values.roofType)
    ) {
      ROOF_TYPE.splice(0, 0, {
        label: details.RoofComposition,
        value: formik.values.roofType,
      });
    }
    setRoofTypes([...ROOF_TYPE]);
  }, [formik.values.roofType, details.RoofComposition, setRoofTypes]);

  useEffect(() => {
    if (
      formik.values.StoryType &&
      !STORY_TYPE.find((x) => x.value === formik.values.StoryType)
    ) {
      STORY_TYPE.unshift({
        label: STORY_TYPE_INTERNAL.filter(
          (x) => x.label === details.StoryType,
        )[0]?.label,
        value: formik.values.StoryType,
        number: 2,
      });
      setStoryTypes([...STORY_TYPE]);
    }
  }, [formik.values.StoryType, details.StoryType, setStoryTypes]);

  useEffect(() => {
    if (
      formik.values.exteriorWalls &&
      !EXTERIOR_WALLS_OPTIONS.find(
        (x) => x.value === formik.values.exteriorWalls,
      )
    ) {
      EXTERIOR_WALLS_OPTIONS.splice(0, 0, {
        label: details.Construction,
        value: formik.values.exteriorWalls,
      });
    }
    setExteriorWallsTypes([...EXTERIOR_WALLS_OPTIONS]);
  }, [
    formik.values.exteriorWalls,
    details.Construction,
    setExteriorWallsTypes,
  ]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid
        container
        spacing={isMobile ? 0 : 3}
        className={classes.confirmDetailsContainer}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <label className={classes.confirmDetailsLabel}>Year built</label>
          <TextField
            name="YearOfConstruction"
            value={formik.values.YearOfConstruction}
            className={classes.confirmDetailsInput}
            onChange={formik.handleChange}
            size="small"
            error={!!formik.errors.YearOfConstruction}
            helperText={formik.errors.YearOfConstruction}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <label className={classes.confirmDetailsLabel}>Sq. feet</label>
          <TextField
            name="SquareFootage"
            value={formik.values.SquareFootage}
            className={classes.confirmDetailsInput}
            onChange={formik.handleChange}
            size="small"
            error={!!formik.errors.SquareFootage}
            helperText={formik.errors.SquareFootage}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl className={classes.fullWidth} size="small">
            <label className={classes.confirmDetailsLabel}>Stories</label>
            <Select
              name="StoryType"
              value={formik.values.StoryType}
              onChange={formik.handleChange}
              className={classes.confirmDetailsSelect}
              variant="standard"
              error={!!formik.errors.StoryType}
            >
              {storyTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.errors.StoryType) && (
              <FormHelperText error variant="outlined">
                {formik.errors.StoryType}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl className={classes.fullWidth} size="small">
            <label className={classes.confirmDetailsLabel}>
              Exterior walls
            </label>
            <Select
              name="exteriorWalls"
              value={formik.values.exteriorWalls}
              onChange={formik.handleChange}
              className={classes.confirmDetailsSelect}
              variant="standard"
            >
              {exteriorWallsTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.errors.exteriorWalls) && (
              <FormHelperText error variant="outlined">
                {formik.errors.exteriorWalls}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl className={classes.fullWidth} size="small">
            <label className={classes.confirmDetailsLabel}>Foundation</label>
            <Select
              name="foundation"
              value={formik.values.foundation}
              onChange={formik.handleChange}
              className={classes.confirmDetailsSelect}
              variant="standard"
            >
              {FOUNDATION_TYPE.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.errors.foundation) && (
              <FormHelperText error variant="outlined">
                {formik.errors.foundation}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl className={classes.fullWidth} size="small">
            <label className={classes.confirmDetailsLabel}>Roof type</label>
            <Select
              name="roofType"
              value={formik.values.roofType}
              onChange={formik.handleChange}
              className={classes.confirmDetailsSelect}
              variant="standard"
            >
              {roofTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.errors.roofType) && (
              <FormHelperText error variant="outlined">
                {formik.errors.roofType}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl className={classes.fullWidth} size="small">
            <label className={classes.confirmDetailsLabel}>
              Roof built or last replaced
            </label>
            <Select
              name="roofBuilt"
              value={formik.values.roofBuilt}
              onChange={formik.handleChange}
              className={classes.confirmDetailsSelect}
              variant="standard"
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.errors.roofBuilt) && (
              <FormHelperText error variant="outlined">
                {formik.errors.roofBuilt}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl className={classes.fullWidth} size="small">
            <label className={classes.confirmDetailsLabel}>Family Units</label>
            <Select
              name="NumberOfFamilies"
              value={formik.values.NumberOfFamilies}
              onChange={formik.handleChange}
              className={classes.confirmDetailsSelect}
              variant="standard"
            >
              {FAMILY_UNITS.map((number) => (
                <MenuItem key={number} value={number}>
                  {number}
                </MenuItem>
              ))}
            </Select>
            {Boolean(formik.errors.NumberOfFamilies) && (
              <FormHelperText error variant="outlined">
                {formik.errors.NumberOfFamilies}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl className={classes.fullWidth} size="small">
            <label className={classes.confirmDetailsLabel}>Full Baths</label>
            <Select
              name="NumberOfFullBaths"
              value={formik.values.NumberOfFullBaths}
              onChange={formik.handleChange}
              className={classes.confirmDetailsSelect}
              variant="standard"
            >
              {NUMBERS.map((number) => (
                <MenuItem key={number} value={number}>
                  {number}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl className={classes.fullWidth} size="small">
            <label className={classes.confirmDetailsLabel}>Half Baths</label>
            <Select
              name="numberOfHalfBaths"
              value={formik.values.numberOfHalfBaths}
              onChange={formik.handleChange}
              className={classes.confirmDetailsSelect}
              variant="standard"
            >
              {NUMBERS.map((number) => (
                <MenuItem key={number} value={number}>
                  {number}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {details.propertyType !== "C" && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl className={classes.fullWidth} size="small">
              <label className={classes.confirmDetailsLabel}>
                Swimming Pool
              </label>
              <Select
                id="SwimmingPool"
                name="SwimmingPool"
                value={formik.values.SwimmingPool}
                onChange={formik.handleChange}
                className={classes.confirmDetailsSelect}
                variant="standard"
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {details.propertyType !== "C" &&
          formik.values.SwimmingPool === "true" && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl className={classes.fullWidth} size="small">
                <label className={classes.confirmDetailsLabel}>
                  Fence around pool
                </label>
                <Select
                  name="fencedInPool"
                  value={formik.values.fencedInPool}
                  onChange={formik.handleChange}
                  className={classes.confirmDetailsSelect}
                  variant="standard"
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        {details.propertyType !== "C" &&
          formik.values.SwimmingPool === "true" && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <FormControl className={classes.fullWidth} size="small">
                <label className={classes.confirmDetailsLabel}>Pool type</label>
                <Select
                  name="poolType"
                  value={formik.values.poolType}
                  onChange={formik.handleChange}
                  className={classes.confirmDetailsSelect}
                  variant="standard"
                >
                  <MenuItem value="AboveGround">Above ground</MenuItem>
                  <MenuItem value="InGround">In ground</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
        {details.propertyType !== "C" && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl className={classes.fullWidth} size="small">
              <label className={classes.confirmDetailsLabel}>Trampoline</label>
              <Select
                name="trampoline"
                value={formik.values.trampoline}
                onChange={formik.handleChange}
                className={classes.confirmDetailsSelect}
                variant="standard"
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        className={classes.nextButton}
        type="submit"
        disabled={!formik.isValid}
        onClick={() => setFormSubmitted(true)}
      >
        Yes, &nbsp; Continue
      </Button>
      {isLoggedIn ? (
        <Box sx={{ textAlign: "center" }}>
          <Link
            style={{ fontSize: 14, margin: "0 10px" }}
            target="_blank"
            to={{
              pathname: replaceWhiteSpaceWithPlus(
                `https://www.google.com/maps/place/${street_line}+${city}+${state}+${zipcode}/`,
              ),
            }}
          >
            Map
          </Link>
          <Link
            style={{ fontSize: 14, margin: "0 10px" }}
            target="_blank"
            to={{
              pathname: replaceWhiteSpaceWithPlus(
                `https://www.zillow.com/homes/${street_line}+${city}+${state}+${zipcode}/`,
              ),
            }}
          >
            Zillow
          </Link>
        </Box>
      ) : null}
    </form>
  );
}
