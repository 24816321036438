import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
  root: {},
  searchCard: {
    padding: 32,
    minHeight: 220,
  },
  searchField: {
    marginTop: 16,
    // width: 340,
  },
  editPmtLink: {
    marginRight: 16,
  },
  formCard: {
    borderRadius: 0,
    marginBottom: "2em",
  },
  formWrap: {
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  formControl: {
    width: "50%",
    minWidth: 330,
    padding: "0 2em 2em",
  },
  formAction: {
    paddingRight: "2em",
  },
  selectLoader: {
    position: "absolute",
    right: 35,
  },
  jsonInput: {
    paddingBlock: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
}));
