import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";

import {
  useAction,
  autoActions,
  useSelector,
  wizardSelectors,
  wizardActions,
} from "../../../../state";
import { Api, getRecaptchaToken } from "../../../../lib";

import { useStyles } from "./CodeEntry.styles";

const CodeEntry = () => {
  const classes = useStyles();
  const verifyPhone = useAction(autoActions.verifyPhone);
  const setAddressError = useAction(wizardActions.setAddressError);
  const phone = useSelector(wizardSelectors.contactInfo).phone;
  const addressError = useSelector(wizardSelectors.addressError);

  const first = useRef<HTMLDivElement>(null);
  const second = useRef<HTMLDivElement>();
  const third = useRef<HTMLDivElement>();
  const fourth = useRef<HTMLDivElement>();
  const [code, setCode] = useState("");
  useEffect(() => {
    setAddressError("");
  }, []);
  useEffect(() => {
    if (addressError) {
      setCode("");
      first?.current?.focus();
    }
  }, [addressError]);

  const handleCode = (e) => {
    if (addressError) {
      setAddressError("");
    }
    let newCode;
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      newCode = code.slice(0, -1);
      setCode(newCode);
    } else {
      if (code.length === 0) {
        second?.current?.focus();
      } else if (code.length === 1) {
        third?.current?.focus();
      } else if (code.length === 2) {
        fourth?.current?.focus();
      }
      newCode = code + e.target.value;
      setCode(newCode);
    }
  };

  const submitCode = async (e) => {
    e.preventDefault();
    const grecaptchaToken = await getRecaptchaToken();
    verifyPhone(code, grecaptchaToken);
  };
  const resendCode = () => {
    Api.post("/get-code", { phone: phone.replace(/\D/g, "") });
  };
  return (
    <>
      <Box className={classes.container}>
        <Typography>
          We sent a text to {`${phone}`} with a one time code
        </Typography>

        <Grid container justifyContent="center" className={classes.formWrapper}>
          <form
            className={classes.form}
            onSubmit={submitCode}
            noValidate
            autoComplete="off"
          >
            <TextField
              autoFocus
              inputRef={first}
              className={classes.inputField}
              onChange={(e) => handleCode(e)}
              value={code.substring(0, 1)}
              inputProps={{ maxLength: 1 }}
            />
            <TextField
              className={classes.inputField}
              inputRef={second}
              onChange={handleCode}
              value={code.substring(1, 2)}
              inputProps={{ maxLength: 1 }}
            />
            <TextField
              className={classes.inputField}
              inputRef={third}
              onChange={handleCode}
              value={code.substring(2, 3)}
              inputProps={{ maxLength: 1 }}
            />
            <TextField
              className={classes.inputField}
              inputRef={fourth}
              onChange={handleCode}
              value={code.substring(3)}
              inputProps={{ maxLength: 1 }}
            />
            {addressError && (
              <div className={classes.errorText}>{addressError}</div>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={code.length < 4}
                type="submit"
              >
                Look up my vehicles
              </Button>
            </Grid>
          </form>
        </Grid>
        <Box className={classes.linkWrapper}>
          <Button
            className={classes.link}
            type="submit"
            onClick={() => resendCode()}
          >
            Resend code
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default React.memo(CodeEntry);
