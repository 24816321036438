import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import {
  AccessAlarmsIcon,
  AddIcon,
  CheckCircleOutlineIcon,
  EditIcon,
  NotificationsIcon,
} from "../../../components";
import { useAction, adminActions } from "../../../state";
import { useStyles } from "../DashboardPage.styles";
import { format, isPast } from "date-fns";
import { useParams } from "react-router-dom";
import emptyMailImg from "../../../assets/img/emptyMail.svg";
import { useMobile } from "../../../themes";
export const AlertsDrawer = ({ alerts }) => {
  const openTaskModal = useAction(adminActions.setTaskModalOpen);
  const setTaskModalData = useAction(adminActions.setTaskModalData);
  const classes = useStyles();
  const isMobile = useMobile("sm");

  const params: Record<string, any> = useParams();
  const req_uid = params.masterQuoteId;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const orderedAlerts = alerts?.toReversed();
  return (
    <React.Fragment>
      <IconButton
        aria-label="alerts"
        onClick={() => {
          setTaskModalData({
            req_uid,
          });
          setDrawerOpen(true);
        }}
      >
        <Badge
          badgeContent={alerts?.length}
          color={alerts?.length ? "error" : "primary"}
        >
          <NotificationsIcon color="primary" />
        </Badge>
      </IconButton>
      <Drawer
        anchor={isMobile ? "bottom" : "right"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box p={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minWidth: "300px",
            }}
          >
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                Application tasks
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setTaskModalData({
                    req_uid,
                  });
                  openTaskModal(true);
                }}
                sx={{ height: "21px", width: "21px", minWidth: "21px" }}
              >
                <AddIcon />
              </Button>
            </Box>
          </Box>

          {orderedAlerts?.length ? (
            orderedAlerts.map((t) => {
              return (
                <Box
                  className={classes.alertItem}
                  key={t.id}
                  sx={{ width: "auto !important" }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <NotificationsIcon
                      sx={{
                        color: isPast(new Date(t.start))
                          ? "red !important"
                          : "grey",
                      }}
                    />
                    <Typography
                      sx={{
                        color: isPast(new Date(t.start)) ? "red" : "grey",
                      }}
                    >
                      {" "}
                      {format(new Date(t.start), "PPP")}
                    </Typography>
                  </Box>
                  <Typography> {t.summary}</Typography>
                  <Box className={classes.description}>{t.description}</Box>

                  <Box>
                    <Divider />
                    <Box sx={{ paddingTop: "6px" }}>
                      <span
                        onClick={() => {
                          setTaskModalData({
                            snooze: true,
                            id: t.id,
                            user_id: t.user_id,
                            req_uid,
                          });
                          openTaskModal(true);
                        }}
                      >
                        {" "}
                        <AccessAlarmsIcon />
                      </span>
                      <span
                        onClick={() => {
                          setTaskModalData({
                            complete: true,
                            id: t.id,
                            user_id: t.user_id,
                            req_uid,
                          });
                          openTaskModal(true);
                        }}
                      >
                        {" "}
                        <CheckCircleOutlineIcon />
                      </span>
                      <span
                        onClick={() => {
                          setTaskModalData(t);
                          openTaskModal(true);
                        }}
                      >
                        {" "}
                        <EditIcon />
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 5,
              }}
            >
              <img src={emptyMailImg}></img>
              <Typography>There are no tasks right now</Typography>
            </Box>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
};
