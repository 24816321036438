import React from "react";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { Skeleton } from '@mui/material';
import { useStyles } from "./LoadingCard.styles";

export default function LoadingCard({ mode, title, carouselMode }) {
  const classes = useStyles({ mode, carouselMode });

  const Section = () => {
    const classes = useStyles({ mode, carouselMode });
    return <>
      <Skeleton
        variant="rectangular"
        width={152}
        height={16}
        style={{ marginBottom: 8 }}
      />
      <Skeleton variant="rectangular" width={116} height={20} />
      <Box className={classes.divider} />
    </>;
  };

  return (
    <Card className={classes.loadingQuoteCard} variant="outlined">
      <Typography className={classes.insuranceLevel}>{title}</Typography>
      <CircularProgress className={classes.circularProgress} />
      <Typography className={classes.description}>
        Creating your <br /> personalized quote
      </Typography>
      <Box className={classes.selectBtn}>Select</Box>
      <Box className={classes.fullWidthDivider} />
      {(mode === "home" || carouselMode === "home") && (
        <>
          <Section />
          <Section />
        </>
      )}
      <Section />
      <Section />
      <Section />
      <Section />
      <Section />
      <Skeleton
        variant="rectangular"
        width={152}
        height={16}
        style={{ marginBottom: 8 }}
      />
      <Skeleton variant="rectangular" width={116} height={20} />
      <Box
        style={{
          marginBottom: mode === "auto" || carouselMode === "auto" ? 36 : 8,
        }}
      />
    </Card>
  );
}
