import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./EditQuote.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  adminSelectors,
  authSelectors,
  autoSelectors,
  homeSelectors,
  wizardActions,
} from "../../state";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { NODE_ENV } from "../../config";

interface EditQuoteForm {
  payment_type: "ach" | "cc";
  due_today_override: number | undefined;
  monthly_payments_override: number | undefined;
  payment_frequency_auto: "installments" | "full";
  payment_frequency_home: "installments" | "full";
  full_premium_override_auto: number | undefined;
  full_premium_override_home: number | undefined;
}

const initialValues: EditQuoteForm = {
  payment_type: "ach",
  due_today_override: undefined,
  monthly_payments_override: undefined,
  payment_frequency_auto: "installments",
  payment_frequency_home: "full",
  full_premium_override_auto: undefined,
  full_premium_override_home: undefined,
};

const EditQuote = () => {
  const dispatch = useDispatch();
  const params: Record<string, any> = useParams();
  const classes = useStyles();
  const req_uid = params.masterQuoteId;
  const pmtInfo = useSelector(adminSelectors.pmtInfo);
  const showOfflinePaymentLink = useSelector(
    adminSelectors.showOfflinePaymentLink,
  );
  const agencyId = useSelector(authSelectors.activeAgencyId);
  const activeRole = useSelector(authSelectors.activeRole);
  const { agency } = useSelector(adminSelectors.activeQuote);

  const selectedAutoQuote = useSelector(autoSelectors.selectedQuote);
  const selectedHomeQuote = useSelector(homeSelectors.selectedQuote);

  if (!activeRole || !agencyId) {
    console.warn("No active role or agency Id");
    return;
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    //validationSchema,
    async onSubmit() {
      // dispatch(adminActions.setBound(req_uid, activeRole, agencyId));
    },
  });
  const handleUpdateQuote = (quote_type: "auto" | "home") => {
    const values =
      quote_type === "auto"
        ? {
            payment_type: form.values.payment_type,
            due_today_override: form.values.due_today_override,
            monthly_payments_override: form.values.monthly_payments_override,
            full_premium_override: form.values.full_premium_override_auto,
            payment_frequency: form.values.payment_frequency_auto,
          }
        : {
            payment_type: form.values.payment_type,
            full_premium_override: form.values.full_premium_override_home,
            payment_frequency: form.values.payment_frequency_home,
          };

    const txn_id =
      quote_type === "auto"
        ? selectedAutoQuote.turbo_quote_id
        : selectedHomeQuote.turbo_quote_id;

    dispatch(
      adminActions.updateQuote(values, req_uid, txn_id, activeRole, agencyId),
    );
  };
  const handleRequestPmt = (quote_type: "auto" | "home") => {
    const txn_id =
      quote_type === "auto"
        ? selectedAutoQuote.turbo_quote_id
        : selectedHomeQuote.turbo_quote_id;

    dispatch(adminActions.showPmtPopup(req_uid, txn_id, activeRole, agencyId));
  };
  const handleRequestPmtClose = (quote_type: "auto" | "home") => {
    const txn_id =
      quote_type === "auto"
        ? selectedAutoQuote.turbo_quote_id
        : selectedHomeQuote.turbo_quote_id;

    dispatch(adminActions.hidePmtPopup(req_uid, txn_id, activeRole, agencyId));
  };

  useEffect(() => {
    dispatch(adminActions.setPageTitle("Edit customer quote"));
    dispatch(wizardActions.getSelectedQuote(req_uid));
  }, [dispatch, req_uid]);

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(adminActions.getPmtInfo(req_uid, activeRole, agencyId));
    }, 2000);
    return () => clearInterval(timer);
  }, [dispatch, activeRole, agencyId]);

  useEffect(() => {
    if (form.values.payment_frequency_auto === "full") {
      form.setFieldValue("due_today_override", undefined);
      form.setFieldValue("monthly_payments_override", undefined);
    }
    // eslint-disable-next-line
  }, [form.values.payment_frequency_auto]);

  return (
    <Box className={classes.root}>
      <Box className={classes.headerWrapper}>
        <Typography variant="h4">
          {/* TODO: update to applicant when implemented */}
          {selectedAutoQuote?.first_name || selectedHomeQuote?.first_name}{" "}
          {selectedAutoQuote?.last_name || selectedHomeQuote?.last_name}
        </Typography>
        <Box className={classes.bindWrapper}>
          <Button
            className={classes.bindBtn}
            variant="outlined"
            color="primary"
            type="submit"
          >
            Set Policy Bound
          </Button>
          {showOfflinePaymentLink && (
            <Box>
              <Typography>For Offline users send them below link</Typography>
              <Typography className={classes.offlineLink}>{`${
                NODE_ENV !== "development"
                  ? `https://${agency?.domain}`
                  : `${window.location.host}`
              }/payment/${req_uid}`}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classes.wrapper}>
        <Box className={classes.leftPanel}>
          {selectedAutoQuote?.turbo_quote_id && (
            <>
              {selectedHomeQuote?.turbo_quote_id ? (
                <Typography variant="h6" className={classes.quoteType}>
                  Auto
                </Typography>
              ) : (
                <div className={classes.quoteTypeFiller}></div>
              )}
              <form onSubmit={form.handleSubmit} className={classes.form}>
                <Card className={classes.editQuoteWrapper}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Payment Frequency</FormLabel>
                    <RadioGroup
                      aria-label="payment_frequency_auto"
                      name="payment_frequency_auto"
                      value={form.values.payment_frequency_auto}
                      onChange={form.handleChange}
                    >
                      <FormControlLabel
                        value="full"
                        control={<Radio />}
                        label="Pay in Full"
                      />
                      <FormControlLabel
                        value="installments"
                        control={<Radio />}
                        label="Installments"
                      />
                    </RadioGroup>
                  </FormControl>
                  {form.values.payment_frequency_auto === "installments" && (
                    <>
                      <TextField
                        onChange={form.handleChange}
                        className={classes.field}
                        name="due_today_override"
                        label="Due today"
                        type="number"
                        value={form.values.due_today_override || ""}
                      />
                      <TextField
                        onChange={form.handleChange}
                        className={classes.field}
                        label="Monthly payments"
                        type="number"
                        value={form.values.monthly_payments_override || ""}
                        name="monthly_payments_override"
                      />
                    </>
                  )}
                  <TextField
                    onChange={form.handleChange}
                    className={classes.field}
                    label="Full premium"
                    name="full_premium_override_auto"
                    type="number"
                    value={form.values.full_premium_override_auto || ""}
                  />
                  <Box className={classes.buttonWrapper}>
                    <Button
                      onClick={() => handleUpdateQuote("auto")}
                      variant="contained"
                    >
                      Update Quote
                    </Button>
                    <Button
                      onClick={() => handleRequestPmt("auto")}
                      variant="contained"
                    >
                      Show Payment
                    </Button>
                    <Button
                      onClick={() => handleRequestPmtClose("auto")}
                      variant="contained"
                    >
                      Close Payment
                    </Button>
                  </Box>
                </Card>
              </form>
            </>
          )}
          {selectedHomeQuote?.turbo_quote_id && (
            <>
              {selectedAutoQuote?.turbo_quote_id ? (
                <Typography variant="h6" className={classes.quoteType}>
                  Home
                </Typography>
              ) : (
                <div className={classes.quoteTypeFiller}></div>
              )}
              <form onSubmit={form.handleSubmit} className={classes.form}>
                <Card className={classes.editQuoteWrapper}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Payment Frequency</FormLabel>
                    <RadioGroup
                      aria-label="payment_frequency_home"
                      name="payment_frequency_home"
                      value={form.values.payment_frequency_home}
                      onChange={form.handleChange}
                    >
                      <FormControlLabel
                        value="full"
                        control={<Radio />}
                        label="Pay in Full"
                      />
                      <FormControlLabel
                        value="installments"
                        control={<Radio />}
                        label="Installments"
                        disabled={true}
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    onChange={form.handleChange}
                    className={classes.field}
                    label="Full premium"
                    name="full_premium_override_home"
                    type="number"
                    value={form.values.full_premium_override_home || undefined}
                  />
                  <Box className={classes.buttonWrapper}>
                    <Button
                      onClick={() => handleUpdateQuote("home")}
                      variant="contained"
                    >
                      Update Quote
                    </Button>
                    <Button
                      onClick={() => handleRequestPmt("home")}
                      variant="contained"
                    >
                      Show Payment
                    </Button>
                    <Button
                      onClick={() => handleRequestPmtClose("home")}
                      variant="contained"
                    >
                      Close Payment
                    </Button>
                  </Box>
                </Card>
              </form>
            </>
          )}
        </Box>
        <Box className={classes.cardsWrapper}>
          <Card className={classes.card}>
            <Typography variant="h5" className={classes.title}>
              Payment Method
            </Typography>

            <FormControl component="fieldset">
              <RadioGroup
                aria-label="payment_type"
                name="payment_type"
                value={form.values.payment_type}
                onChange={form.handleChange}
              >
                <FormControlLabel
                  value="cc"
                  control={<Radio />}
                  label="Credit Card"
                />
                <FormControlLabel value="ach" control={<Radio />} label="ACH" />
              </RadioGroup>
            </FormControl>
          </Card>
          <Card className={classes.card}>
            <Typography variant="h5" className={classes.title}>
              Payment Info
            </Typography>
            <Typography variant="h6">
              {form.values.payment_type === "cc" ? "Credit card" : "EFT"}
            </Typography>
            <Box className={classes.cardInfoSection}>
              <Typography className={classes.pmtInfoTitle}>
                Account Name
              </Typography>
              <Typography className={classes.pmtInfoValue}>
                {pmtInfo.name_on_account}
              </Typography>
              {form.values.payment_type === "cc" ? (
                <>
                  <Typography className={classes.pmtInfoTitle}>
                    Card number
                  </Typography>
                  <Typography className={classes.pmtInfoValue}>
                    {pmtInfo.creditcard_number}
                  </Typography>
                  <Typography className={classes.pmtInfoTitle}>
                    Exp date
                  </Typography>
                  <Typography className={classes.pmtInfoValue}>
                    {pmtInfo.expiration}
                  </Typography>
                  <Typography className={classes.pmtInfoTitle}>CVV</Typography>
                  <Typography className={classes.pmtInfoValue}>
                    {pmtInfo.cvc}
                  </Typography>
                </>
              ) : form.values.payment_type === "ach" ? (
                <>
                  <Typography className={classes.pmtInfoTitle}>
                    Routing number
                  </Typography>
                  <Typography className={classes.pmtInfoValue}>
                    {pmtInfo.routing_number}
                  </Typography>
                  <Typography className={classes.pmtInfoTitle}>
                    Account number
                  </Typography>
                  <Typography className={classes.pmtInfoValue}>
                    {pmtInfo.account_number}
                  </Typography>
                </>
              ) : (
                <Typography className={classes.pmtInfoTitle}>
                  No payment selected
                </Typography>
              )}
            </Box>
          </Card>
          <Card className={classes.card}>
            <Typography className={classes.title}>Billing Info</Typography>
            <Box className={classes.cardInfoSection}>
              <Typography className={classes.pmtInfoTitle}>
                First name
              </Typography>
              <Typography className={classes.pmtInfoValue}>
                {pmtInfo.bill_first_name}
              </Typography>
              <Typography className={classes.pmtInfoTitle}>
                Last name
              </Typography>
              <Typography className={classes.pmtInfoValue}>
                {pmtInfo.bill_last_name}
              </Typography>
              <Typography className={classes.pmtInfoTitle}>Address</Typography>
              <Typography className={classes.pmtInfoValue}>
                {pmtInfo.bill_street}
              </Typography>
              <Typography className={classes.pmtInfoTitle}>City</Typography>
              <Typography className={classes.pmtInfoValue}>
                {pmtInfo.bill_city}
              </Typography>
              <Typography className={classes.pmtInfoTitle}>State</Typography>
              <Typography className={classes.pmtInfoValue}>
                {pmtInfo.bill_state}
              </Typography>
              <Typography className={classes.pmtInfoTitle}>Zip</Typography>
              <Typography className={classes.pmtInfoValue}>
                {pmtInfo.bill_zip}
              </Typography>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default EditQuote;
