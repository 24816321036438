import React, { FC, useRef } from "react";

import { useScrollToOnError } from "../../utils";

interface ScrollToOnErrorProps {
  name: string;
}

const ScrollToOnError: FC<ScrollToOnErrorProps> = ({ name, children }) => {
  const targetRef = useRef<HTMLDivElement>(null);
  useScrollToOnError(targetRef, name);

  return <div ref={targetRef}>{children}</div>;
};

export default ScrollToOnError;
