import {
  Box,
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { yup } from "../../../../../lib";
import {
  useAction,
  useSelector,
  autoActions,
  autoSelectors,
} from "../../../../../state";
import { DriverShape, UDDDriversShape } from "../../../../../state/auto/types";
import { formatTitleCase, NewDriverShape } from "../../../../../utils";
import { useStyles } from "./DriversList.styles";

const validationSchema = yup.object({});

const DriversList = () => {
  const classes = useStyles();
  const drivers = useSelector(autoSelectors.uddDrivers);
  const selectedDrivers_submitted = useSelector(autoSelectors.selectedDrivers);
  const applicantIndex_submitted = useSelector(autoSelectors.applicantIndex);
  const driverState = useSelector(autoSelectors.state);
  const startForm = useSelector(autoSelectors.startForm);
  const setDriversForm = useAction(autoActions.setDriversForm);
  const setShowSelectDriversView = useAction(
    autoActions.setShowSelectDriverView,
  );
  const uddDrivers = drivers
    ? drivers.filter((d) => d.State === driverState)
    : [];
  const [chosenApplicant, setChosenApplicant] = useState<number | undefined>(
    applicantIndex_submitted,
  );
  const [startFormApplicant, setStartFormApplicant] = useState<
    number | undefined
  >(undefined);

  //returning user
  const userEnteredDrivers = selectedDrivers_submitted.filter(
    (d) => !!d.isUserEntered,
  );
  const uddSelectedDrivers = selectedDrivers_submitted.filter(
    (d) => !d.isUserEntered,
  );

  const getNewEnteredDriver = (): DriverShape => {
    const newDriver = NewDriverShape();
    newDriver.isUserEntered = true;
    return newDriver;
  };

  const selectedDrivers: Array<DriverShape> = uddSelectedDrivers.length
    ? uddSelectedDrivers
    : [];

  const additionalDrivers: Array<DriverShape> = userEnteredDrivers.length
    ? userEnteredDrivers
    : uddDrivers.length
    ? [getNewEnteredDriver()]
    : [
        {
          ...getNewEnteredDriver(),
          FirstName: startForm.first_name,
          MiddleName: startForm.middle_name,
          LastName: startForm.last_name,
          isChecked: true,
        },
      ];

  const form = useFormik({
    enableReinitialize: false, //don't change - RP
    initialValues: {
      additionalDrivers,
      selectedDrivers,
    },
    validationSchema,
    async onSubmit(values) {
      const allDrivers = [
        ...values.selectedDrivers,
        ...values.additionalDrivers.filter((d) => d.isChecked),
      ];
      const applicantIndex =
        startFormApplicant !== undefined
          ? values.selectedDrivers.findIndex(
              (d) => d.udd_id === startFormApplicant,
            )
          : chosenApplicant;

      await setDriversForm({ selectedDrivers: allDrivers, applicantIndex });
      setShowSelectDriversView(false);
    },
  });

  const additionalDriverIsChecked = form.values.additionalDrivers.find(
    (d) => d.isChecked,
  );
  const additionalDriverIsEmpty = form.values.additionalDrivers.find(
    (d) => d.isChecked && (d.FirstName === "" || d.LastName === ""),
  );
  const additionalDrivers_NonEmpty = form.values.additionalDrivers.filter(
    (d) => d.isChecked && d.FirstName !== "" && d.LastName !== "",
  );
  const isCheckedUddDriver = (index: number) => {
    return Boolean(
      form.values.selectedDrivers.filter((d) => d.udd_id === index).length,
    );
  };

  const toggleSelectedDriver = (
    driver: UDDDriversShape,
    index: number,
    isApplicant = false,
  ) => {
    //if driver is checked already, remove from selected drivers
    const _selectedDrivers = form.values.selectedDrivers;
    if (isCheckedUddDriver(index)) {
      const _driver = _selectedDrivers.findIndex((d) => d.udd_id === index);
      //if is applicant, leave as checked
      if (isApplicant) {
        return;
      } else {
        _selectedDrivers.splice(_driver, 1);
      }
      form.setFieldValue("selectedDrivers", _selectedDrivers);

      //otherwise, add new selected driver
    } else {
      const _newDriver: DriverShape = { ...NewDriverShape(), ...driver };
      _newDriver.isChecked = true;
      _newDriver.udd_id = index;
      form.setFieldValue("selectedDrivers", [..._selectedDrivers, _newDriver]);
    }
  };

  const toggleAdditionalDriver = (index: number) => {
    const _additionalDrivers = form.values.additionalDrivers;
    _additionalDrivers[index] = {
      ..._additionalDrivers[index],
      isChecked: !form.values.additionalDrivers[index].isChecked,
    };

    //if trying to remove driver
    if (!_additionalDrivers[index].isChecked) {
      //if it's the last driver, reset as new driver
      if (_additionalDrivers.length - 1 === index) {
        _additionalDrivers[index] = getNewEnteredDriver();
      } else {
        _additionalDrivers.splice(index, 1);
      }
    }
    form.setFieldValue("additionalDrivers", _additionalDrivers);
  };

  useEffect(() => {
    //see if any drivers match start form
    const _startFormApplicant = uddDrivers.findIndex(
      (d) =>
        d.FirstName?.toLowerCase() === startForm.first_name?.toLowerCase() &&
        d.MiddleName?.toLowerCase() === startForm.middle_name?.toLowerCase() &&
        d.LastName?.toLowerCase() === startForm.last_name?.toLowerCase(),
    );

    //add this driver to selectedDrivers (checked)
    if (_startFormApplicant !== -1) {
      setStartFormApplicant(_startFormApplicant);
      toggleSelectedDriver(
        uddDrivers[_startFormApplicant],
        _startFormApplicant,
        true,
      );
    }
  }, []);

  useEffect(() => {
    //if last additional driver has all required info, give user new additional driver
    const lastAddDriver =
      form.values.additionalDrivers[form.values.additionalDrivers.length - 1];
    if (lastAddDriver.FirstName !== "" && lastAddDriver.LastName !== "") {
      form.setFieldValue("additionalDrivers", [
        ...form.values.additionalDrivers,
        getNewEnteredDriver(),
      ]);
    }
  }, [form.values.additionalDrivers]);

  return (
    <form onSubmit={form.handleSubmit}>
      <List className={classes.root}>
        {uddDrivers?.map((driver, i) => {
          return (
            <ListItem
              key={`driver-${i}`}
              component={Paper}
              onClick={() => {
                if (startFormApplicant !== i) {
                  toggleSelectedDriver(driver, i);
                }
              }}
              className={classes.item}
              disabled={startFormApplicant === i}
            >
              <ListItemIcon className={classes.checkboxWrap}>
                <Checkbox
                  edge="start"
                  checked={startFormApplicant === i || isCheckedUddDriver(i)}
                  disableRipple
                  color="primary"
                  className={`driver-${i}`}
                />
              </ListItemIcon>
              <Box className={classes.content}>
                <Typography>
                  {driver.FirstName} {driver.MiddleName} {driver.LastName}
                </Typography>
              </Box>
            </ListItem>
          );
        })}

        {form.values.additionalDrivers.map((driver, i) => (
          <ListItem key={i} component={Paper} className={classes.item}>
            <Box className={classes.additionalDriverWrapper}>
              <Box
                className={classes.additionalDriverTitle}
                onClick={() => {
                  toggleAdditionalDriver(i);
                }}
              >
                <ListItemIcon className={classes.checkboxWrap}>
                  <Checkbox
                    edge="start"
                    checked={driver.isChecked}
                    disableRipple
                    color="primary"
                  />
                </ListItemIcon>
                <Typography className={classes.content}>
                  Add a driver
                </Typography>
              </Box>
              {driver.isChecked && (
                <Box className={classes.additionalDriverFieldsWrapper}>
                  <TextField
                    variant="outlined"
                    className={classes.textFieldLrg}
                    id={`additionalDrivers[${i}].FirstName`}
                    name={`additionalDrivers[${i}].FirstName`}
                    label="First Name"
                    value={form.values.additionalDrivers[i].FirstName}
                    onChange={form.handleChange}
                  />
                  <TextField
                    variant="outlined"
                    className={classes.textFieldSmll}
                    id={`additionalDrivers[${i}].MiddleName`}
                    name={`additionalDrivers[${i}].MiddleName`}
                    label="Middle"
                    value={form.values.additionalDrivers[i].MiddleName}
                    onChange={form.handleChange}
                  />
                  <TextField
                    variant="outlined"
                    className={classes.textFieldLrg}
                    id={`additionalDrivers[${i}].LastName`}
                    name={`additionalDrivers[${i}].LastName`}
                    label="Last Name"
                    value={form.values.additionalDrivers[i].LastName}
                    onChange={form.handleChange}
                  />
                </Box>
              )}
            </Box>
          </ListItem>
        ))}
      </List>

      {startFormApplicant === undefined && (
        <Box className={classes.field}>
          <Typography variant="h6" className={classes.fieldTitle}>
            Who is the primary applicant?
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                id="applicant"
                name="applicant"
                label="Select a driver"
                value={chosenApplicant !== undefined ? chosenApplicant : ""}
                onChange={(e) => {
                  setChosenApplicant(Number(e.target.value));
                }}
                disabled={
                  !form.values.selectedDrivers.length &&
                  !form.values.additionalDrivers.length
                }
              >
                {[
                  ...form.values.selectedDrivers,
                  ...additionalDrivers_NonEmpty,
                ].map((driver, index) => (
                  <MenuItem key={`driver-${index}`} value={index}>
                    {formatTitleCase(driver.FirstName)}{" "}
                    {formatTitleCase(driver.MiddleName)}{" "}
                    {formatTitleCase(driver.LastName)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Box>
      )}

      <Grid container justifyContent="center" alignItems="center">
        <Button
          id="submit"
          variant="contained"
          color="primary"
          type="submit"
          disabled={
            (!form.values.selectedDrivers.length &&
              !additionalDriverIsChecked) ||
            !!additionalDriverIsEmpty ||
            (startFormApplicant === undefined && chosenApplicant === undefined)
          }
        >
          Let's keep moving
        </Button>
      </Grid>
    </form>
  );
};

export default React.memo(DriversList);
