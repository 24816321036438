import React from "react";

export const MedpayImage = ({ fillColor }) => {
  return (
    <svg
      width="83px"
      height="84px"
      viewBox="0 0 83 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>undraw_injured_9757</title>
      <g
        id="↳-auto-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Create-a-custom-quote-side-bar"
          transform="translate(-561.000000, -110.000000)"
          fillRule="nonzero"
        >
          <g
            id="undraw_injured_9757"
            transform="translate(561.000000, 110.000000)"
          >
            <path
              d="M82.1833555,75.3265822 C81.1438665,77.4522765 79.2282022,79.0335461 77.2200454,80.3190386 C75.0252,81.7227841 72.6604626,82.8502051 70.1810625,83.6749703 C69.8411459,83.7903558 69.497926,83.8976156 69.1530924,84 L55.2086323,84 C55.1113051,83.8959905 55.0188934,83.7871055 54.931478,83.6749703 C53.9975334,82.4967376 53.4926136,80.9869746 53.6757458,79.5080894 C53.9117284,77.6082908 55.3769389,75.8628813 57.2744759,75.4598444 C59.1737042,75.0551824 61.3384854,76.1716595 61.9159967,77.9999516 C62.2328705,74.4766295 62.5991315,70.8070441 64.4999719,67.8070199 C66.2226055,65.0913967 69.2042566,63.1477191 72.434952,62.7999373 C74.3352182,62.6050149 76.2523889,62.9507444 77.9592598,63.7961533 C78.1160442,63.8741605 78.271139,63.9554179 78.4229288,64.0399256 C79.4472858,64.6119658 80.3512211,65.3715306 81.0861022,66.2777552 C83.1007816,68.7902299 83.5941808,72.4419436 82.1833555,75.3265822 Z"
              id="Path"
              fill="#F2F2F2"
            ></path>
            <path
              d="M78.8337978,64.253088 C72.2069265,69.4571271 66.9704064,76.1171268 63.5631877,83.6748141 C63.5142867,83.7821255 63.4653722,83.8910627 63.4181119,84 L62.746988,84 C62.7942482,83.8910627 62.8414276,83.7821255 62.8903353,83.6748141 C63.5395533,82.2114777 64.2562058,80.7741562 65.0436653,79.374231 C66.7193632,76.3972865 68.6908426,73.5842409 70.9303602,70.9746799 C73.1684136,68.3618939 75.6682689,65.9683215 78.3936248,63.8287204 C78.415252,63.8117622 78.4390498,63.7975544 78.4644335,63.7864462 C78.5603981,63.7462107 78.6701686,63.7504549 78.762484,63.7979701 C78.8547995,63.8454852 78.9195433,63.9310642 78.9382773,64.0303356 C78.9481551,64.1173975 78.9081233,64.2027459 78.8337978,64.253088 Z"
              id="Path"
              fill="#FFFFFF"
            ></path>
            <path
              d="M27.8371537,49.1265907 C28.1917384,48.7995971 28.3727861,48.3429432 28.3315803,47.8795057 C28.2903746,47.4160683 28.0311481,46.993453 27.6236539,46.7253776 L26.77266,41.4939759 L23.2771084,42.5198679 L25.1920956,47.1871319 C24.744691,47.8703264 24.915983,48.7575871 25.5908093,49.2523945 C26.2656357,49.747202 27.2306943,49.6931552 27.8371608,49.1265907 L27.8371537,49.1265907 Z"
              id="Path"
              fill="#FFB8B8"
            ></path>
            <path
              d="M24.4054905,45.5421687 L22.3886858,36.4407162 C21.9891372,34.6678129 22.5725676,32.8217129 23.9289534,31.5669767 C26.51048,29.163104 29.8283519,26.1331726 30.9744107,25.3494341 C32.050064,24.6085363 33.3516171,24.2372951 34.6712172,24.2949962 L34.6988813,24.2979356 L36.4337349,25.8822006 L32.6545287,31.8938659 L26.9173193,35.3674378 L27.5557716,44.2880402 L24.4054905,45.5421687 Z"
              id="Path"
              fill={fillColor}
            ></path>
            <rect
              id="Rectangle"
              fill="#CCCCCC"
              x="21.253012"
              y="33.3975904"
              width="10.1204819"
              height="1.01204819"
              rx="0.506024096"
            ></rect>
            <rect
              id="Rectangle"
              fill="#CCCCCC"
              x="21.253012"
              y="47.5662651"
              width="10.1204819"
              height="1.01204819"
              rx="0.506024096"
            ></rect>
            <path
              d="M26.8800651,82.9879518 C26.7061735,82.9879518 26.5610736,82.8584502 26.5456949,82.6895505 L22.2663944,33.7538706 C22.2500099,33.5737978 22.386431,33.4148681 22.5710995,33.3988913 C22.755768,33.3829146 22.9187537,33.5159407 22.9351382,33.6960136 L26.8752313,78.9085655 L30.7033422,33.6968123 C30.7136485,33.5802972 30.7869184,33.4779943 30.8955517,33.4284402 C31.0041851,33.3788861 31.131678,33.3896091 31.230005,33.45657 C31.3283321,33.523531 31.3825551,33.6365569 31.3722488,33.7530719 L27.2145276,82.6887519 C27.1995463,82.8578393 27.0545333,82.9879518 26.8804847,82.9879518 L26.8800651,82.9879518 Z"
              id="Path"
              fill="#CCCCCC"
            ></path>
            <circle
              id="Oval"
              fill="#FFB8B8"
              cx="36.4337349"
              cy="18.2168675"
              r="4.04819277"
            ></circle>
            <polygon
              id="Path"
              fill="#FFB8B8"
              points="23.0687327 81.9759036 21.253012 81.3051092 22.6211324 72.8674699 25.3012048 73.8575537"
            ></polygon>
            <path
              d="M23.4864532,82.9879518 L17.2048193,80.9025217 L17.2567003,80.7325719 C17.6727239,79.3788877 19.0621432,78.6312723 20.362834,79.0612309 L24.2891566,80.3647593 L23.4864532,82.9879518 Z"
              id="Path"
              fill="#2F2E41"
            ></path>
            <polygon
              id="Path"
              fill="#FFB8B8"
              points="38.4578313 80.9638554 36.4006981 80.9638554 35.4216867 72.8674699 38.4578313 72.8674699"
            ></polygon>
            <path
              d="M32.3855422,82.9879518 L38.4578313,82.9879518 L38.4578313,80.9638554 L34.6245524,80.9638554 C33.3885658,80.965121 32.3869519,81.8648387 32.3855422,82.9750865 L32.3855422,82.9879518 Z"
              id="Path"
              fill="#2F2E41"
            ></path>
            <path
              d="M38.1338043,78.9317518 L37.4223353,78.8247784 C35.2644665,78.5008451 35.135268,78.4814453 34.7926969,78.3298912 C34.6177578,78.2524512 34.6054579,77.0823014 34.7104928,70.5045039 C34.7875497,65.6761516 34.8835151,59.6671169 34.6972112,56.4411672 C34.6814015,56.1791556 34.4893015,55.9622632 34.2327202,55.9167293 C33.9761389,55.8711953 33.7220482,56.0089049 33.618338,56.2497061 C32.0127577,59.9855075 29.3189917,68.3456096 27.5364685,73.877652 C26.9164373,75.8020094 26.426712,77.3219059 26.1168703,78.232972 C25.9928719,78.5900267 25.6197356,78.7935381 25.2552164,78.7029247 C23.7883098,78.3334621 23.8863869,78.1763653 21.7970913,77.6319109 C21.4214803,77.5354051 21.1883943,77.1574402 21.2688923,76.7754002 C21.6467889,74.9798727 28.5622939,45.86691 29.0916324,43.0994236 C29.12781,42.9080914 29.2383751,42.7392591 29.3987338,42.6304819 C29.5590924,42.5217046 29.7559459,42.4820034 29.9455059,42.5202093 C31.502516,42.8363036 32.4497539,43.1716387 33.988414,43.4958883 C35.6486917,43.8457922 37.3655148,44.2076542 39.0400963,44.545365 L39.0400963,44.545365 C39.2067661,44.5791497 39.3561396,44.6714024 39.4616492,44.8057152 C39.8760109,45.3329866 40.4904216,45.6289693 41.1409449,45.9422922 C41.5795136,46.1535487 40.585711,46.1345106 40.9676555,46.4391642 C42.5143728,47.6728128 40.2901375,72.724017 38.9461814,78.3819282 C38.8568164,78.7489841 38.504857,78.9871995 38.1338043,78.9317664 L38.1338043,78.9317518 Z"
              id="Path"
              fill="#2F2E41"
            ></path>
            <path
              d="M38.2106153,22.1994584 C37.9531621,21.1078689 39.2546475,18.7590681 38.9971944,17.6674786 C38.8228431,16.928232 37.0832056,17.4307584 36.6772273,16.7886786 C36.271249,16.1465987 35.5912332,15.626419 34.8312096,15.6178963 C33.9576212,15.6081007 33.0980089,16.2556233 32.2704696,15.9757642 C31.433739,15.692795 31.1662996,14.5478377 31.5362268,13.7462841 C31.906154,12.9447305 32.7030437,12.4339651 33.4903674,12.0337325 C34.8310903,11.352178 36.3921817,10.8544712 37.8271439,11.3054016 C38.6945829,11.5779874 39.4198713,12.1686357 40.1217487,12.7463229 C40.7615472,13.2729143 41.4121083,13.8114443 41.8593627,14.508793 C42.7355477,15.874908 42.6826837,17.715585 41.9339186,19.1553778 C41.1851535,20.5951706 39.8208077,21.6517475 38.3174492,22.2650602"
              id="Path"
              fill="#2F2E41"
            ></path>
            <path
              d="M27.4137702,46.4089954 C27.8858004,45.6337531 28.6024462,42.0014602 29.4862094,35.904713 C30.0878929,31.7427335 30.6121364,27.5138745 30.8722447,25.3537047 C30.9132885,24.9983107 31.1374373,24.6886797 31.4655443,24.534142 C31.6343329,24.4540505 31.8021214,24.3782529 31.9689098,24.306749 C34.1228959,23.3856511 36.3058155,23.0804865 38.4566698,23.3995746 C40.604329,23.7238664 42.6187702,24.6262736 44.2768653,26.0068418 L44.2964382,26.0250045 L44.3014852,26.0509279 C44.3108441,26.0989401 45.2141819,30.8845988 43.3639253,34.1178868 C41.5391019,37.3081211 41.5178189,45.7565776 41.5178189,45.8415511 L41.5178189,45.950126 L41.4115704,45.9203617 C38.5727222,45.122883 27.6065209,46.5180504 27.4960488,46.5322935 L27.3253012,46.5542169 L27.4137702,46.4089954 Z"
              id="Path"
              fill={fillColor}
            ></path>
            <rect
              id="Rectangle"
              fill="#CCCCCC"
              x="34.4096386"
              y="35.4216867"
              width="10.1204819"
              height="1.01204819"
              rx="0.506024096"
            ></rect>
            <rect
              id="Rectangle"
              fill="#CCCCCC"
              x="34.4096386"
              y="49.5903614"
              width="10.1204819"
              height="1.01204819"
              rx="0.506024096"
            ></rect>
            <path
              d="M40.0366748,84 C39.8627832,84 39.7176833,83.8706619 39.7023046,83.7019755 L35.4230037,36.7895739 C35.4124048,36.6732343 35.4663419,36.5602252 35.5644975,36.4931162 C35.6626531,36.4260072 35.7901149,36.4149936 35.8988694,36.4642242 C36.0076239,36.5134548 36.0811487,36.6154503 36.0917476,36.7317899 L40.031841,79.9257642 L43.8599523,36.7325876 C43.8758844,36.5526983 44.0385399,36.4194475 44.2232533,36.4349635 C44.4079668,36.4504796 44.5447911,36.6088869 44.5288589,36.7887762 L40.3711374,83.7011778 C40.356156,83.8700518 40.211143,84 40.0370944,84 L40.0366748,84 Z"
              id="Path"
              fill="#CCCCCC"
            ></path>
            <path
              d="M36.4698577,50.3699449 C36.3715189,49.9179646 36.4762721,49.4451659 36.7559303,49.0787701 C37.0355884,48.7123742 37.4614241,48.4900184 37.918853,48.4715345 L41.3730516,44.5301205 L43.5180723,47.1497991 L39.5456941,50.0833963 C39.5250244,50.8797649 38.9184049,51.5346453 38.1336759,51.6077503 C37.348947,51.6808552 36.6341498,51.1490774 36.4698545,50.3699399 L36.4698577,50.3699449 Z"
              id="Path"
              fill="#FFB8B8"
            ></path>
            <path
              d="M39.4698795,46.8789964 L44.9023626,39.7137464 L42.1010478,33.7755913 L42.3044481,26.7330035 L44.6010861,26.313253 L44.6254256,26.3253302 C45.7410759,26.9708205 46.6059003,27.9675049 47.081789,29.1562157 C47.5919378,30.4179777 48.6501313,34.7106977 49.447958,38.0897222 C49.871723,39.8581451 49.3352285,41.7173086 48.0316024,42.9979492 L41.3674491,49.5903614 L39.4698795,46.8789964 Z"
              id="Path"
              fill={fillColor}
            ></path>
            <path
              d="M16.2119143,81.1781728 L23.9305168,82.9666814 C24.2395267,83.045394 24.5628106,82.9001668 24.6985652,82.6216548 L31.3289892,65.6556283 C31.3881279,65.5238243 31.3883364,65.374413 31.3295659,65.2424565 C31.2620026,65.0943471 31.1343484,64.9793383 30.9763357,64.9242174 L25.1730778,62.7913248 C24.987565,62.7230243 24.7806016,62.7340344 24.6040408,62.8215966 C24.4505699,62.8945212 24.3374004,63.0277973 24.2937096,63.1870635 L24.2935409,63.1878555 L20.197566,78.1409584 L20.1367183,78.1446034 C20.1131711,78.1460283 17.7650577,78.2997599 16.7091562,79.5196221 C16.310762,79.9817629 16.1305643,80.5828125 16.2119143,81.1781728 L16.2119143,81.1781728 Z"
              id="Path"
              fill="#CCCCCC"
            ></path>
            <path
              d="M80.9638554,82.9879518 C80.9638554,83.0013867 80.9468575,83.014285 80.9162123,83.0237849 C80.885567,83.0332849 80.8439595,83.038601 80.8006208,83.0385542 L0.163233577,83.0385542 C0.073082162,83.0385542 0,83.0158986 0,82.9879518 C0,82.960005 0.073082162,82.9373494 0.163233577,82.9373494 L80.8006208,82.9373494 C80.8439596,82.9373024 80.8855674,82.9426185 80.9162127,82.9521185 C80.9468581,82.9616185 80.9638554,82.9745169 80.9638554,82.9879518 Z"
              id="Path"
              fill="#3F3D56"
            ></path>
            <polygon
              id="Rectangle"
              fill="#B3B3B3"
              transform="translate(24.854858, 72.492185) rotate(-83.954840) translate(-24.854858, -72.492185) "
              points="24.6913318 69.0616197 25.0183833 69.0616197 25.0183833 75.9227511 24.6913318 75.9227511"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#B3B3B3"
              transform="translate(23.543970, 76.046553) rotate(-65.775630) translate(-23.543970, -76.046553) "
              points="23.3804436 73.0361444 23.7074968 73.0361444 23.7074968 79.0569619 23.3804436 79.0569619"
            ></polygon>
            <polygon
              id="Path"
              fill="#B3B3B3"
              points="29.2054455 69.8313253 22.2650602 68.0544468 22.4090123 67.8072289 29.3493976 69.5841074"
            ></polygon>
            <polygon
              id="Path"
              fill="#B3B3B3"
              points="30.2174937 68.8192771 23.2771084 66.0476748 23.4210605 65.7831325 30.3614458 68.5547348"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#B3B3B3"
              transform="translate(22.521838, 79.339783) rotate(-49.494750) translate(-22.521838, -79.339783) "
              points="22.3583642 76.2713975 22.6853111 76.2713975 22.6853111 82.4081682 22.3583642 82.4081682"
            ></polygon>
            <polygon
              id="Rectangle"
              fill="#B3B3B3"
              transform="translate(19.758082, 80.677183) rotate(-24.393340) translate(-19.758082, -80.677183) "
              points="19.5945931 78.4089652 19.921571 78.4089652 19.921571 82.9454012 19.5945931 82.9454012"
            ></polygon>
            <path
              d="M62.7383569,78.4640396 L62.7383569,3.64147916 C62.7383569,2.63110429 62.7577768,1.61857077 62.7383569,0.608319011 C62.737508,0.564149788 62.7383569,0.519891921 62.7383569,0.475711207 C62.7383569,-0.158045818 61.7435708,-0.15909477 61.7435708,0.475711207 L61.7435708,75.2982799 C61.7435708,76.3086547 61.7241509,77.3211883 61.7435708,78.33144 C61.7444197,78.3756092 61.7435708,78.4198671 61.7435708,78.4640478 C61.7435708,79.0978049 62.7383569,79.0988538 62.7383569,78.4640478 L62.7383569,78.4640396 Z"
              id="Path"
              fill="#F2F2F2"
            ></path>
            <path
              d="M68.7478384,22.2650602 L56.7461375,22.2650602 C56.1480309,22.2643917 55.6633423,21.7958937 55.6626506,21.2177666 L55.6626506,7.11958281 C55.6633423,6.54145566 56.1480309,6.07295773 56.7461375,6.07228916 L68.7478384,6.07228916 C69.345945,6.07295773 69.8306336,6.54145566 69.8313253,7.11958281 L69.8313253,21.2177666 C69.8306336,21.7958937 69.345945,22.2643917 68.7478384,22.2650602 L68.7478384,22.2650602 Z"
              id="Path"
              fill="#F2F2F2"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
