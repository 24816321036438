import { RefObject, useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import { kFormatter } from "./helperMethods";
import { formatCurrency } from ".";
import { DriverShape } from "../state/auto/types";

export const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const NewDriverShape = (): DriverShape => {
  return {
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Age: undefined,
    Address: undefined,
    City: undefined,
    State: undefined,
    Zip: undefined,
    DLNumber: undefined,
    isChecked: false,
    isUserEntered: false,
    udd_id: undefined,
    spouse: false,
    tickets: [],
    continuous_insurance: "",
    no_insurance_reason: "",
    accidents: false,
    collision: false,
    relationship_to_applicant: "",
    gender: "",
    marital_status: "",
    dob: "",
    education_level: "",
    industry: "",
    occupation_class: "",
    isSubmitted: false,
    moreDrivers: false,
  };
};

export const getInsuranceLevelTitle = (level) => {
  switch (level) {
    case 0:
      return "Custom coverage";
    case 1:
      return "Basic coverage";
    case 2:
      return "Robust coverage";
    case 3:
      return "Super coverage";
    default:
      return "";
  }
};

export const formatOptionLabel = (option) =>
  Object.values(option)
    .map((limit) => kFormatter(Number(limit)))
    .join(" / ");

const formatPercentLabel = (option) =>
  Object.values(option).map((percent) => percent + "%");

export const getLimits = (obj) => {
  return (obj ? obj.Limits : [])
    .filter(
      (option) =>
        option?.Val1?.toLowerCase() !== "none" && option?.Val1 !== "0",
    )
    .map((option) => ({
      ...option,
      label: formatOptionLabel(option),
    }));
};

export const getPercentage = (percent, percentageOf) => {
  return `${formatCurrency(
    Math.round((Number(percent) * Number(percentageOf)) / 100),
  )}`;
};

export const getPercentLabel = (option, percentageOf) => {
  return `${formatCurrency(
    Math.round((Number(option.Val1) * Number(percentageOf)) / 100),
  )} - ${formatPercentLabel(option)}`;
};

export const getPercentages = (obj, percentageOf) => {
  return (obj && obj?.percentage ? obj.percentage : []).map((option) => ({
    ...option,
    label: getPercentLabel(option, percentageOf),
  }));
};

export const getLimits_Rental = (obj) =>
  (obj && obj?.Limits ? obj.Limits : [])?.map((option) => ({
    ...option,
    label: `${formatOptionLabel(option)} / ${Object.values(option).map(
      (limit) => formatCurrency(Number(limit) * 30),
    )}`,
  }));

export const getDefaultValue = (values) => {
  return values && values.length
    ? values[Math.floor((values.length - 1) / 2)]
    : "";
};

export const formatDefaultValue = (value) => {
  return {
    ...value,
    label: formatOptionLabel(value),
  };
};

export const formatDefaultPercent = (value) => {
  return {
    ...value,
    label: formatPercentLabel(value),
  };
};

export const getDeductibles = (obj) => {
  return (obj && obj?.Deductibles ? obj.Deductibles : [])
    .filter(
      (option) =>
        option?.Val1?.toLowerCase() !== "none" && option?.Val1 !== "0",
    )
    .map((option) => ({
      ...option,
      label: formatOptionLabel(option),
    }));
};

export const getOptions = (obj) =>
  (obj ? obj?.Options : [])?.map((option) => ({
    Val1: option.Val1[0],
    label: option.Val1,
  }));

export const useScrollToOnError = (
  scrollable: RefObject<HTMLDivElement>,
  name: string,
) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];

  useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (scrollable.current && firstErrorKey === name) {
        scrollable.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      prevSubmitCountRef.current = formik.submitCount;
    }
    // eslint-disable-next-line
  }, [formik.submitCount, formik.isValid]);
};

export const hideDLNumber = (DLNumber) =>
  DLNumber.replace(/.{1,4}/, (m) => "*".repeat(m.length));

export const getLastHundredYears = () => {
  const max = new Date().getFullYear();
  const min = max - 99;
  const years: number[] = [];

  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};

export const getLastTwoHundredYears = () => {
  const max = new Date().getFullYear();
  const min = max - 199;
  const years: number[] = [];

  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};
