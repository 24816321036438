import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { add, format } from "date-fns";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  InsertInvitationIcon,
} from "../icons";
import { useDispatch, useSelector } from "react-redux";
import {
  adminActions,
  adminSelectors,
  authSelectors,
  uiSelectors,
} from "../../state";
import { useStyles } from "./ModalAgent.styles";
import { useFormik, yup } from "../../lib";
import { PersonAdd } from "@mui/icons-material";
import clsx from "clsx";

import { useMobile } from "../../themes";
import { DatePicker } from "@mui/x-date-pickers";

export default function ModalTask() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isOpen = useSelector(adminSelectors.taskModalOpen);
  const editData = useSelector(adminSelectors.taskModalData);
  const schedule = useSelector(adminSelectors.schedule);
  const userId = useSelector(authSelectors.userId);
  const loading = useSelector(uiSelectors.loadingMessage);
  const role = useSelector(authSelectors.activeRole);
  const activeAgencyId = useSelector(authSelectors.activeAgencyId);
  const isMobile = useMobile();

  const completeMode = editData.complete;
  const snoozeMode = editData.snooze;
  const editMode = editData.start;
  const hasCalEvent = !!editData.event_id;
  const editCalendar = !!editData.calendarId;

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [reminder, setReminder] = useState<boolean>(hasCalEvent);
  const [viewSchedule, setViewSchedule] = useState<boolean>(false);
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [scheduleDate, setScheduleDate] = useState(new Date());
  const [showInvite, setShowInvite] = useState(false);

  const [number, setNumber] = useState<any>(1);
  const [interval, setInterval] = useState<any>();
  const [initialDate, setInitialDate] = useState<any>();
  const [displayDate, setDisplayDate] = useState<any>();

  useEffect(() => {
    const d = format(new Date(scheduleDate), "PPP").split(",")[0];
    const f =
      format(new Date(scheduleDate), "eeee") +
      " - " +
      d.substring(0, d.length - 2);
    setDisplayDate(f);
    if (viewSchedule) {
      dispatch(adminActions.getEvents(scheduleDate, scheduleDate, true));
    }
  }, [viewSchedule, scheduleDate]);

  const validationSchema =
    !editCalendar && !snoozeMode && !completeMode
      ? yup.object({
          summary: yup.string().required("Field is required"),
          description: yup.string().required("Field is required"),
        })
      : yup.object({});

  const form = useFormik({
    validationSchema,
    validateOnChange: formSubmitted,
    enableReinitialize: true,
    initialValues: {
      summary: editData.summary || "",
      description: editData.description || "",
      event_id: editData.event_id || "",
      calendarId: editData.calendarId || "",
      hasTime: editData.hasTime || "",
      req_uid: editData.req_uid || null,
      start: "",
      end: "",
      conference: false,
      guestEmail: "",
      attendees: [],
    },
    async onSubmit(values) {
      if (completeMode || (snoozeMode && !reminder)) {
        dispatch(
          adminActions.completeTask(
            editData.id,
            role,
            activeAgencyId,
            editData.req_uid,
          ),
        );
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleCloseModal();
        return;
      }
      let updateValues;

      if (snoozeMode || editMode) {
        if (snoozeMode) {
          updateValues = {
            start: add(new Date(), { [interval]: number }),
            req_uid: editData.req_uid,
          };
        }
        if (editMode) {
          updateValues = {
            ...values,
            reminder,
            start: new Date(values.start),
            end: new Date(values.end),
          };
        }
        const id = editCalendar ? 0 : editData.id;
        dispatch(
          adminActions.updateTask(id, updateValues, role, activeAgencyId),
        );
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleCloseModal();
        return;
      }

      const start = values.start ? new Date(values.start) : new Date();
      const end = values.end ? new Date(values.end) : new Date();

      dispatch(
        adminActions.createEvent(
          {
            ...values,
            start,
            end,
            reminder,
            user_id: userId,
          },
          role,
          activeAgencyId,
        ),
      );
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleCloseModal();
    },
  });

  const handleAddEmail = () => {
    const emails = [...form.values.attendees, form.values.guestEmail];
    form.setFieldValue("guestEmail", "");
    form.setFieldValue("attendees", emails);
  };
  const handleRemoveGuest = (index) => {
    const emails = form.values.attendees;
    emails.splice(index, 1);
    form.setFieldValue("attendees", emails);
  };

  const handleScheduleDate = (date) => {
    setScheduleDate(date);
    setShowPicker(false);
  };
  useEffect(() => {
    form.setFieldValue(
      "description",
      form.values.description.replace(/(<([^>]+)>)/gi, ""),
    );
  }, [form.values.description]);

  const handleCloseModal = () => {
    dispatch(adminActions.setTaskModalOpen(false));
    dispatch(adminActions.setTaskModalData({}));
    setReminder(false);
    setNumber(1);
    setInterval("days");
    setFormSubmitted(false);
    form.resetForm();
    form.setFieldValue("description", "");
    setInitialDate("");
    setViewSchedule(false);
    setShowInvite(false);
    setScheduleDate(new Date());
  };

  useEffect(() => {
    if (editData.attendees?.length) {
      form.setFieldValue(
        "attendees",
        editData.attendees.map((a: any) => a.email),
      );
    }
    if (editData.hangoutLink) {
      form.setFieldValue("conference", true);
    }
    if (!editData.start) return;
    setInitialDate(format(new Date(editData.start), "yyyy-MM-dd'T'HH:mm"));
  }, [editData]);
  useEffect(() => {
    if (hasCalEvent) {
      setReminder(true);
    }
    if (editMode) {
      form.setFieldValue(
        "start",
        format(new Date(editData.start), "yyyy-MM-dd'T'HH:mm"),
      );
      form.setFieldValue(
        "end",
        format(new Date(editData.end), "yyyy-MM-dd'T'HH:mm"),
      );
    }
    if (snoozeMode) {
      setReminder(true);
    }
  }, [editData]);

  useEffect(() => {
    if (
      !form.values.start ||
      (editMode && !initialDate) ||
      form.values.start === initialDate
    ) {
      return;
    }
    const endDate = add(new Date(form.values.start), { hours: 1 });
    form.setFieldValue("end", format(endDate, "yyyy-MM-dd'T'HH:mm:ss"));
  }, [form.values.start]);
  return (
    <Fade in={!!isOpen}>
      <div>
        <Modal open={!!isOpen} onClose={() => handleCloseModal()}>
          <Box className={classes.box}>
            <Box className={classes.top}>
              <Box className={classes.commentsTitle}>
                {completeMode
                  ? "Are you sure you want to complete this task?"
                  : snoozeMode
                  ? "Snooze notification"
                  : editCalendar
                  ? "Edit event"
                  : editMode
                  ? "Edit task"
                  : "Create a task"}
              </Box>
              <IconButton
                className={classes.closeBtn}
                onClick={handleCloseModal}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <>
              <Box className={classes.commentsWrapper}>
                <form onSubmit={form.handleSubmit}>
                  {!completeMode && !snoozeMode && (
                    <>
                      <Box>
                        <TextField
                          fullWidth
                          label="Title"
                          id="summary"
                          name="summary"
                          value={form.values.summary}
                          onChange={form.handleChange}
                          error={
                            form.touched.summary && Boolean(form.errors.summary)
                          }
                          helperText={
                            form.touched.summary && form.errors.summary
                          }
                        />
                      </Box>
                      <Box className={classes.commentsInput}>
                        <TextField
                          fullWidth
                          label="Description"
                          id="description"
                          name="description"
                          value={form.values.description}
                          onChange={form.handleChange}
                          multiline
                          minRows={5}
                          maxRows={10}
                          error={
                            form.touched.description &&
                            Boolean(form.errors.description)
                          }
                          helperText={
                            form.touched.description && form.errors.description
                          }
                        />
                      </Box>

                      <Box className={classes.notification}>
                        {!hasCalEvent && (
                          <Box className={classes.checkBox}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={reminder}
                                  onChange={() => setReminder(!reminder)}
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label="Add to calendar"
                            />
                          </Box>
                        )}
                        {reminder && (
                          <>
                            <Box className={classes.dateInputWrapper}>
                              <Box className={classes.notificationInputs}>
                                <TextField
                                  id="date"
                                  type="datetime-local"
                                  name="start"
                                  value={form.values.start}
                                  className={classes.calendarInputs}
                                  InputLabelProps={{
                                    shrink: true,
                                    placeholder: "",
                                  }}
                                  label="Start"
                                  style={{ color: "transparent" }}
                                  onChange={form.handleChange}
                                />
                              </Box>

                              <Box className={classes.notificationInputs}>
                                <TextField
                                  type="datetime-local"
                                  id="endDate"
                                  name="end"
                                  value={form.values.end}
                                  className={classes.calendarInputs}
                                  InputLabelProps={{
                                    shrink: true,
                                    placeholder: "",
                                  }}
                                  label="End"
                                  style={{ color: "transparent" }}
                                  onChange={form.handleChange}
                                />
                              </Box>
                            </Box>
                            <Box className={classes.calendarActions}>
                              <InsertInvitationIcon />
                              <Typography onClick={() => setViewSchedule(true)}>
                                View my schedule
                              </Typography>
                            </Box>
                            {viewSchedule && (
                              <Box className={classes.schedule}>
                                <Box className={classes.scheduleTop}>
                                  <Box className={classes.controls}>
                                    <div
                                      className={classes.controls}
                                      onClick={() =>
                                        setScheduleDate(
                                          add(new Date(scheduleDate), {
                                            days: -1,
                                          }),
                                        )
                                      }
                                    >
                                      {" "}
                                      <ChevronLeftIcon />
                                    </div>
                                    <div
                                      className={classes.controls}
                                      onClick={() =>
                                        setScheduleDate(
                                          add(new Date(scheduleDate), {
                                            days: 1,
                                          }),
                                        )
                                      }
                                    >
                                      {" "}
                                      <ChevronRightIcon />
                                    </div>
                                    <Typography
                                      onClick={() => setShowPicker(true)}
                                    >
                                      {displayDate}
                                    </Typography>
                                    <DatePicker
                                      open={showPicker}
                                      format="MM/dd/yyyy"
                                      value={scheduleDate}
                                      onChange={(date) =>
                                        handleScheduleDate(date)
                                      }
                                    />
                                  </Box>
                                  {/* </Box> */}
                                  <Box>
                                    <IconButton
                                      className={classes.closeBtn}
                                      onClick={() => {
                                        setScheduleDate(new Date());
                                        setViewSchedule(false);
                                      }}
                                      size="large"
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                                {!loading ? (
                                  schedule.length ? (
                                    schedule.map((e) => (
                                      <Box
                                        className={classes.event}
                                        key={e.eventId + e.link}
                                      >
                                        <Typography>
                                          {" "}
                                          {format(new Date(e.startTime), "p")
                                            .toLowerCase()
                                            .replace(" ", "")}{" "}
                                          -{" "}
                                          {format(new Date(e.endTime), "p")
                                            .toLowerCase()
                                            .replace(" ", "")}
                                        </Typography>
                                        <Typography>{e.summary}</Typography>
                                      </Box>
                                    ))
                                  ) : (
                                    <Typography className={classes.noEvents}>
                                      No events scheduled
                                    </Typography>
                                  )
                                ) : (
                                  <></>
                                )}
                              </Box>
                            )}
                            <Box
                              className={clsx(
                                classes.calendarActions,
                                classes.invite,
                              )}
                            >
                              <div>
                                <PersonAdd />
                                {!showInvite && (
                                  <Typography
                                    onClick={() => setShowInvite(true)}
                                  >
                                    Invite Guests
                                  </Typography>
                                )}
                              </div>
                              {showInvite && (
                                <TextField
                                  name="guestEmail"
                                  onChange={form.handleChange}
                                  label={
                                    isMobile
                                      ? "Enter guest email."
                                      : "Enter guest email address."
                                  }
                                  fullWidth
                                  value={form.values.guestEmail}
                                ></TextField>
                              )}
                              {showInvite && (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => handleAddEmail()}
                                >
                                  Add
                                </Button>
                              )}
                            </Box>
                            {!!form.values.attendees.length && (
                              <Box className={classes.attendees}>
                                {form.values.attendees.map((a, i) => (
                                  <div key={i}>
                                    {a}{" "}
                                    <span onClick={() => handleRemoveGuest(i)}>
                                      <CloseIcon />
                                    </span>
                                  </div>
                                ))}
                              </Box>
                            )}
                            <Box className={classes.checkBox}>
                              {" "}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={form.values.conference}
                                    onChange={form.handleChange}
                                    name="conference"
                                    color="primary"
                                  />
                                }
                                label="Add Google Meet video conferencing"
                              />
                              {!!editData.hangoutLink && (
                                <div>
                                  {" "}
                                  <a
                                    href={editData.hangoutLink}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {editData.hangoutLink.split("//")[1]}
                                  </a>
                                </div>
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </>
                  )}
                  {snoozeMode && (
                    <>
                      <Box className={classes.notificationInputs}>
                        <Box>
                          <TextField
                            className={classes.notificationNum}
                            id="standard-number"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={number}
                            onChange={(e) => setNumber(+e.target.value)}
                          />
                        </Box>
                        <Box>
                          <FormControl
                            variant="outlined"
                            className={classes.notificationInterval}
                          >
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={interval}
                              onChange={(e) => setInterval(e.target.value)}
                              defaultValue={"days"}
                            >
                              <MenuItem value={"months"}>Months</MenuItem>
                              <MenuItem value={"weeks"}>Weeks</MenuItem>
                              <MenuItem value={"days"}>Days</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box className={classes.checkBox}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!reminder}
                              onChange={() => setReminder(!reminder)}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label=" Don’t remind me about this application"
                        />
                      </Box>
                    </>
                  )}
                  <Box className={classes.commentsButtons}>
                    <Button
                      className={classes.cancelBtn}
                      size="small"
                      onClick={() => handleCloseModal()}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => setFormSubmitted(true)}
                      className={classes.selectBtn}
                      variant="contained"
                      color="primary"
                      size="small"
                      type="submit"
                      disabled={!form.isValid}
                    >
                      {completeMode
                        ? "Complete"
                        : snoozeMode
                        ? "Snooze"
                        : "Save"}
                    </Button>
                  </Box>
                </form>
              </Box>
            </>
          </Box>
        </Modal>
      </div>
    </Fade>
  );
}
