import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useStyles } from "./SelectedQuote.styles";
import { useSelector } from "react-redux";

import { wizardSelectors, lifeSelectors } from "../../../../../state";
import { useMobile } from "../../../../../themes";
import WizardCompleteCard from "../../../quotes/cards/WizardCompleteCard";
import ContactCards from "./ContactCards";
import { useOnMount } from "../../../../../lib";
import { formatCurrency } from "../../../../../utils";

export default function Quotes() {
  const classes = useStyles({});
  const isMobile = useMobile();
  // const params: { id: string } = useParams();
  const contactType = useSelector(wizardSelectors.contactType);
  const selectedLifeQuote = useSelector(lifeSelectors.selectedQuote);
  const baseInfo = useSelector(lifeSelectors.baseInfo);

  const startForm = useSelector(lifeSelectors.startForm);
  const listItemProps = [
    { field: "Insurance type", value: selectedLifeQuote?.productName },
    {
      field: "Insurance amount",
      value: baseInfo?.FaceAmount && formatCurrency(baseInfo?.FaceAmount),
    },
    { field: "Age", value: baseInfo?.Birthdate.ActualAge },
    { field: "State", value: startForm.address?.full_state },
    { field: "Gender", value: baseInfo?.sex === "M" ? "Male" : "Female" },
    { field: "Health class", value: baseInfo?.healthtxt },
  ];
  const ListItem = (props) => {
    return (
      <Box className={classes.listItem}>
        <Typography className={classes.field}>{props.field}</Typography>
        <Typography className={classes.product}>{props.value}</Typography>
      </Box>
    );
  };

  useOnMount(() => {
    window.scrollTo(0, 0);
    // getSelectedQuote(params.id);
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      spacing={5}
      className={classes.selectedQuotePageWrapper}
    >
      {isMobile ? (
        <>
          {contactType ? (
            <WizardCompleteCard />
          ) : (
            <>
              <ContactCards title="How would you like to check out?" />
            </>
          )}
          <Grid>
            <Box className={classes.selectedQuoteContainer}>
              <Box className={classes.cardContainer}>
                <Box className={classes.topContainer}>
                  <div className={classes.logoContainer}>
                    <img
                      className={classes.logo}
                      src={`https://www.compulifeapi.com/images/logosapi/${selectedLifeQuote?.compImgUrl}-small.png`}
                    />
                  </div>
                  <Box className={classes.premium}>
                    <Box className={classes.price}>
                      {!!selectedLifeQuote?.monthlyPremium && (
                        <Box>
                          {formatCurrency(
                            Math.round(
                              +selectedLifeQuote?.monthlyPremium.replace(
                                ",",
                                "",
                              ),
                            ),
                          )}
                        </Box>
                      )}
                      <Box className={classes.priceInterval}>Monthly</Box>
                    </Box>
                    <Box className={classes.price}>
                      {selectedLifeQuote?.premium && (
                        <Box className={classes.price}>
                          {formatCurrency(
                            Math.round(
                              +selectedLifeQuote?.premium.replace(",", ""),
                            ),
                          )}
                        </Box>
                      )}
                      <Box className={classes.priceInterval}>Annual</Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.bottom}>
                  {listItemProps.map((li) => (
                    <ListItem
                      key={li.field}
                      field={li.field}
                      value={li.value}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid>
            <Box className={classes.selectedQuoteContainer}>
              <Box className={classes.cardContainer}>
                <Box className={classes.topContainer}>
                  <div className={classes.logoContainer}>
                    <img
                      className={classes.logo}
                      src={`https://www.compulifeapi.com/images/logosapi/${selectedLifeQuote?.compImgUrl}-small.png`}
                    />
                  </div>
                  <Box className={classes.premium}>
                    <Box className={classes.price}>
                      {!!selectedLifeQuote?.monthlyPremium && (
                        <Box>
                          {formatCurrency(
                            Math.round(
                              +selectedLifeQuote?.monthlyPremium.replace(
                                ",",
                                "",
                              ),
                            ),
                          )}
                        </Box>
                      )}
                      <Box className={classes.priceInterval}>Monthly</Box>
                    </Box>
                    <Box className={classes.price}>
                      {selectedLifeQuote?.premium && (
                        <Box className={classes.price}>
                          {formatCurrency(
                            Math.round(
                              +selectedLifeQuote?.premium.replace(",", ""),
                            ),
                          )}
                        </Box>
                      )}
                      <Box className={classes.priceInterval}>Annual</Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.bottom}>
                  {listItemProps.map((li) => (
                    <ListItem
                      key={li.field}
                      field={li.field}
                      value={li.value}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
          {
            <Grid item className={classes.contactCardsContainer}>
              {contactType ? (
                <WizardCompleteCard />
              ) : (
                <div>
                  <ContactCards title="How would you like to check out?" />
                </div>
              )}
            </Grid>
          }
        </>
      )}
    </Grid>
  );
}
