import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  coverageWrapper: {
    border: "1px solid #DADFF6",
    borderRadius: 3,
    marginBottom: 32,
    paddingBottom: 0,
    "& .MuiAccordionDetails-root": {
      display: "block",
      padding: "0px 24px",
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
    },
    "& .MuiAccordion-root": {
      boxShadow: "none",
      border: "none",
    },
    "& .MuiAccordionSummary-root": {
      padding: "8px 24px",
      borderBottom: "1px solid #DADFF6",
      [theme.breakpoints.down('md')]: {
        borderBottom: "none",
      },
    },
    "& .MuiAccordionSummary-content": { margin: 0 },
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      border: "none",
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      border: "none",
      "& .MuiAccordion-root.Mui-expanded": {
        margin: 0,
      },
      "& .MuiAccordionSummary-root": {
        padding: "8px 12px",
      },
    },
  },
  headingTxtWrapper: {
    width: 180,
    height: 28,
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ({ level }: any) =>
      level === 1
        ? "#FFCC002B"
        : level === 2
        ? "#F376201F"
        : level === 3
        ? "#2ED27221"
        : "#D2C8E0",
    "& .MuiTypography-root": {
      fontSize: 16,
      color: ({ level }: any) =>
        level === 1
          ? "#FFCC00"
          : level === 2
          ? "#F37620"
          : level === 3
          ? "#2ED272"
          : "#AB73FA",
      [theme.breakpoints.down('sm')]: {
        fontWeight: 600,
      },
    },
    [theme.breakpoints.down('sm')]: {
      background: "none !important",
      justifyContent: "flex-start !important",
    },
  },
  cardsWrapper: {
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  cardWrapper: {
    width: "50%",
    padding: 24,
    [theme.breakpoints.down('md')]: {
      width: "100%",
      padding: "12px 0px",
    },
  },
  cardWrapperTxt: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 12,
  },
  divider: {
    margin: "0px 24px",
    border: ".5px solid #DADFF6",
    borderRadius: 3,
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  switcherGroup: {
    marginTop: 24,
    "& .MuiButtonBase-root": {
      border: "1px solid #DADFF6",
      borderBottom: "none",
      width: 76,
      height: 32,
      fontWeight: 600,
      fontSize: 14,
      textTransform: "initial",
    },
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      border: "none",
    },
  },
  tabDivider: {
    margin: 0,
    border: ".5px solid #DADFF6",
    borderRadius: 3,
  },
}));
