import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon, EditIcon } from "../icons";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelectors,
  autoActions,
  autoSelectors,
  homeActions,
  homeSelectors,
  useAction,
  wizardActions,
  wizardNoPersistSelectors,
  wizardSelectors,
} from "../../state";
import { useStyles } from "./ModalAddress.styles";
import { Form, Navigation, useFormik, yup } from "../../lib";
import { states } from "../../assets/data/us_states";

const validationSchema = yup.object({
  zipcode: yup.string().required("Field is required"),
  state: yup.string().required("Field is required"),
  city: yup.string().required("Field is required"),
  street_line: yup.string().required("Field is required"),
});

export default function ModalAddress() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isOpen = useSelector(wizardSelectors.addressModalOpen);

  const mode = useSelector(wizardNoPersistSelectors.mode);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const { address, apt, skipLookup } =
    mode === "home" || mode === "renters"
      ? useSelector(homeSelectors.start)
      : useSelector(autoSelectors.startForm);

  const [showForm, setShowForm] = useState(false);
  const handleCloseAddressModal = () => {
    setShowForm(false);
    dispatch(wizardActions.setAddressModalOpen(false));
  };

  const initialValues: any = {
    street_line: address?.street_line || "",
    city: address?.city || "",
    zipcode: address?.zipcode || "",
    state: address?.state || "",
    full_state: address?.full_state || "",
    apt: apt || "",
    label: address?.label || "",
    skipLookup: skipLookup || false,
  };
  const setStartForm = useAction(autoActions.setStartForm);
  const setHomeStart = useAction(homeActions.setStart);
  const form = useFormik({
    initialValues, // startForm,
    enableReinitialize: true,
    validationSchema,
    async onSubmit(values) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { skipLookup, apt, ...address } = values;
      if (mode !== "home") {
        await setStartForm(
          { address: { ...address }, apt },
          isLoggedIn,
          skipLookup,
        );
      }

      if (mode !== "auto") {
        await setHomeStart({ address: { ...address }, apt });
        if (mode === "home" || mode === "renters") {
          Navigation.go(`/${mode}/wizard/details/form`);
        }
      }
      handleCloseAddressModal();
    },
  });

  useEffect(() => {
    const state = states.find((s) => s.abbr === form.values.state);
    form.setFieldValue("full_state", state?.name);
  }, [form.values.state]);

  return (
    <Fade in={!!isOpen}>
      <div>
        <Modal open={isOpen} onClose={handleCloseAddressModal}>
          <Box className={classes.box}>
            <IconButton
              className={classes.closeBtn}
              onClick={handleCloseAddressModal}
              size="large">
              <CloseIcon />
            </IconButton>
            <Typography className={classes.title}>
              Can you verify your address for me?
            </Typography>
            <Form form={form} onChange={() => form.setFieldValue("label", "")}>
              <Grid container>
                {!showForm ? (
                  <Grid container spacing={3} className={classes.card}>
                    <Box>
                      <Typography>{form.values.street_line}</Typography>
                      <Typography>
                        {form.values.city}, {form.values.state}{" "}
                        {form.values.zipcode}
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => setShowForm(true)}
                      >
                        Edit
                      </Button>
                    </Box>
                  </Grid>
                ) : (
                  <Grid container spacing={3} className={classes.card}>
                    <Grid item md={9} xs={12}>
                      <TextField
                        id="street_line"
                        name="street_line"
                        label="Street address"
                        value={form.values.street_line}
                        onChange={form.handleChange}
                        error={
                          form.touched.street_line &&
                          Boolean(form.errors.street_line)
                        }
                        helperText={
                          form.touched.street_line && form.errors.street_line
                        }
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        id="apt"
                        name="apt"
                        label="Apt"
                        value={form.values.apt}
                        onChange={form.handleChange}
                      />
                    </Grid>
                    <Grid item md={7} xs={12}>
                      <TextField
                        id="city"
                        name="city"
                        label="City"
                        value={form.values.city}
                        onChange={form.handleChange}
                        error={form.touched.city && Boolean(form.errors.city)}
                        helperText={form.touched.city && form.errors.city}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <TextField
                        select
                        id="state"
                        name="state"
                        label="State"
                        variant="outlined"
                        value={form.values.state}
                        onChange={form.handleChange}
                        error={form.touched.state && Boolean(form.errors.state)}
                        helperText={form.touched.state && form.errors.state}
                      >
                        {states.map((state) => (
                          <MenuItem key={state.abbr} value={state.abbr}>
                            {state.abbr}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        id="zipcode"
                        name="zipcode"
                        label="Zip"
                        value={form.values.zipcode}
                        onChange={form.handleChange}
                        error={
                          form.touched.zipcode && Boolean(form.errors.zipcode)
                        }
                        helperText={form.touched.zipcode && form.errors.zipcode}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} className={classes.selectBtn}>
                  <Button
                    className={classes.selectBtn}
                    variant="contained"
                    color="primary"
                    type="submit"
                    // onClick={() => handleSubmit()}
                  >
                    Use this address
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Box>
        </Modal>
      </div>
    </Fade>
  );
}
