import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useMobile } from "../../../themes";
import {
  useSelector,
  wizardSelectors,
  wizardNoPersistSelectors,
} from "../../../state";
import { useStyles } from "./Stepper.styles";

function BundledWizardStep(props) {
  const classes = useStyles();
  const location = useLocation();
  const { active, completed } = props;

  const getProgress = () => {
    switch (location.pathname) {
      case "/bundle/wizard/start":
      case "/auto-renters/wizard/start":
        return 0;
      case "/bundle/wizard/details/form":
      case "/bundle/wizard/details/confirm":
      case "/bundle/wizard/vehicles":
      case "/auto-renters/wizard/details/form":
      case "/auto-renters/wizard/vehicles":
        return 25;
      case "/bundle/wizard/home-discounts":
      case "/bundle/wizard/drivers":
      case "/auto-renters/wizard/home-discounts":
      case "/auto-renters/wizard/drivers":
        return 50;
      case "/bundle/wizard/insured":
      case "/bundle/wizard/auto-discounts":
      case "/auto-renters/wizard/insured":
      case "/auto-renters/wizard/auto-discounts":
        return 75;
      case "/bundle/wizard/continue":
      case "/bundle/wizard/contact":
      case "/auto-renters/wizard/continue":
      case "/auto-renters/wizard/contact":
        return 100;
      default:
        return 0;
    }
  };

  const [progress, setProgress] = useState(getProgress());

  useEffect(() => {
    setProgress(getProgress());
  }, [location]);

  return active ? (
    progress !== 100 ? (
      <Box className={classes.bundledStepperProgress}>
        <CircularProgress
          variant="determinate"
          value={progress}
          size={20}
          thickness={5}
          style={{ zIndex: 1 }}
        />
        <CircularProgress
          variant="determinate"
          value={100}
          className={classes.fullProgressBundled}
          size={20}
          thickness={5.4}
        />
      </Box>
    ) : (
      <Box className={classes.completedBundledStepIcon}></Box>
    )
  ) : completed ? (
    <Box className={classes.completedBundledStepIcon}></Box>
  ) : (
    <Box className={classes.bundledStepIcon}></Box>
  );
}

export default function WizardStepper() {
  const classes = useStyles();
  const isMobile = useMobile();
  const location = useLocation();
  const activeStep = useSelector(wizardSelectors.activeStep);
  const mode = useSelector(wizardNoPersistSelectors.mode);

  const steps =
    mode === "home" || mode === "renters"
      ? ["Start", "Home", "Discounts", "Insured", "Quotes"]
      : mode === "auto"
      ? ["Start", "Vehicles", "Drivers", "Discounts", "Quotes"]
      : mode === "life"
      ? ["Start", "Health & Lifestyle", "Coverage", "Final Details", "Quotes"]
      : mode === "auto-renters"
      ? ["Auto", "Renters", "Quotes"]
      : ["Auto", "Home", "Quotes"];

  const getProgress = () => {
    switch (location.pathname) {
      case "/home/wizard/start":
      case "/renters/wizard/start":
      case "/auto/wizard/start":
      case "/life/wizard/start":
        return 0;
      case "/home/wizard/details/form":
      case "/renters/wizard/details/form":
      case "/home/wizard/details/confirm":
      case "/auto/wizard/vehicles":
      case "/life/wizard/insured":
        return 25;
      case "/home/wizard/discounts":
      case "/renters/wizard/discounts":
      case "/auto/wizard/drivers":
      case "/life/wizard/coverage":
        return 50;
      case "/home/wizard/insured":
      case "/renters/wizard/insured":
      case "/auto/wizard/discounts":
        return 75;
      case "/home/wizard/contact":
      case "/renters/wizard/contact":
      case "/auto/wizard/contact":
      case "/life/wizard/contact":
        return 100;
      default:
        return 0;
    }
  };
  const getStep = () => {
    switch (location.pathname) {
      case "/home/wizard/start":
      case "/renters/wizard/start":
      case "/auto/wizard/start":
      case "/life/wizard/start":
        return "Start";
      case "/home/wizard/details/form":
      case "/renters/wizard/details/form":
        return "Home";
      case "/home/wizard/details/confirm":
        return "Home";
      case "/home/wizard/discounts":
      case "/renters/wizard/discounts":
        return "Discounts";
      case "/home/wizard/insured":
      case "/renters/wizard/insured":
        return "Insured";
      case "/life/wizard/insured":
        return "Health & Lifestyle";
      case "/life/wizard/coverage":
        return "Coverage";
      case "/life/wizard/contact":
        return "Final Details";
      case "/home/wizard/contact":
      case "/renters/wizard/contact":
      case "/auto/wizard/contact":
      case "/bundle/wizard/contact":
        return "Quotes";
      case "/auto/wizard/vehicles":
        return "Vehicles";
      case "/auto/wizard/drivers":
        return "Drivers";
      case "/auto/wizard/discounts":
        return "Discounts";
      case "/bundle/wizard/start":
      case "/bundle/wizard/vehicles":
      case "/bundle/wizard/drivers":
      case "/bundle/wizard/auto-discounts":
      case "/bundle/wizard/continue":
      case "/auto-renters/wizard/start":
      case "/auto-renters/wizard/vehicles":
      case "/auto-renters/wizard/drivers":
      case "/auto-renters/wizard/auto-discounts":
      case "/auto-renters/wizard/continue":
        return "Auto";
      case "/bundle/wizard/details/form":
      case "/bundle/wizard/details/confirm":
      case "/bundle/wizard/home-discounts":
      case "/bundle/wizard/insured":
        return "Home";
      case "/auto-renters/wizard/details/form":
      case "/auto-renters/wizard/home-discounts":
      case "/auto-renters/wizard/insured":
        return "Renters";
      default:
        return "";
    }
  };
  // needed for mobile stepper
  const getNextStep = () => {
    switch (location.pathname) {
      case "/home/wizard/start":
      case "/renters/wizard/start":
        return "Details";
      case "/life/wizard/start":
        return "Health & Lifestyle";
      case "/home/wizard/details/form":
      case "/renters/wizard/details/form":
        return "Discounts";
      case "/home/wizard/details/confirm":
        return "Discounts";
      case "/home/wizard/discounts":
      case "/renters/wizard/discounts":
        return "Insured";
      case "/home/wizard/insured":
      case "/renters/wizard/insured":
      case "/auto/wizard/discounts":
        return "Quotes";
      case "/life/wizard/insured":
        return "Coverage";
      case "/life/wizard/coverage":
        return "Final Details";
      case "/auto/wizard/start":
        return "Vehicles";
      case "/auto/wizard/vehicles":
        return "Drivers";
      case "/auto/wizard/drivers":
        return "Discounts";
      default:
        return "";
    }
  };

  const [progress, setProgress] = useState(getProgress());
  const [step, setStep] = useState(getStep());
  const [nextStep, setNextStep] = useState(getNextStep());

  useEffect(() => {
    setProgress(getProgress());
    setStep(getStep());
    setNextStep(getNextStep());
  }, [location]);

  return activeStep < 5 ? (
    !isMobile ? (
      mode === "bundle" || mode === "auto-renters" ? (
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          className={classes.bundledStepper}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={BundledWizardStep}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          className={classes.stepper}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )
    ) : (
      <Box className={classes.mobileStepper}>
        <Box className={classes.mobileStepperProgress}>
          <CircularProgress variant="determinate" value={progress} size={56} />
          <CircularProgress
            variant="determinate"
            value={100}
            className={classes.fullProgress}
            size={56}
          />
          <Typography className={classes.stepCounter}>
            {activeStep + 1} of 5
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            {step}
          </Typography>
          {activeStep !== 4 && (
            <Typography
              color="secondary"
              style={{ fontSize: 12, fontWeight: 500 }}
            >
              Next: {nextStep}
            </Typography>
          )}
        </Box>
      </Box>
    )
  ) : null;
}
