import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  stepper: {
    width: 520,
    maxWidth: "100%",
    margin: "0 auto",
    padding: "24px 0 0 0",
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: 8,
    },
    "& .MuiStepLabel-root.Mui-disabled .MuiStepIcon-root": {
      color: "#CDD2E9",
    },
  },
  mobileStepper: {
    display: "flex",
  },
  bundledStepper: {
    width: 520,
    maxWidth: "100%",
    margin: "0 auto",
    padding: "24px 0 0 0",
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      marginTop: 8,
      height: 20,
    },
    "& .MuiStepLabel-root.Mui-disabled .MuiStepIcon-root": {
      color: "#CDD2E9",
    },
  },
  mobileStepperProgress: {
    position: "relative",
    height: 56,
    width: 56,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 24,
    "& .MuiCircularProgress-root": {
      position: "absolute",
      left: 0,
      top: 0,
    },
  },
  fullProgress: {
    color: "#DADFF6",
    zIndex: -1,
  },
  bundledStepperProgress: {
    position: "relative",
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiCircularProgress-root": {
      position: "absolute",
      left: 0,
      top: 0,
    },
  },
  fullProgressBundled: {
    color: "#DADFF6",
  },
  bundledStepIcon: {
    backgroundColor: "#DADFF6",
    height: 16,
    width: 16,
    borderRadius: 100,
  },
  completedBundledStepIcon: {
    backgroundColor: theme.palette.primary.main,
    height: 16,
    width: 16,
    borderRadius: 100,
  },
  stepCounter: {
    fontSize: 12,
    fontWeight: "bold",
  },
}));
