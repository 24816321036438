import qs from "query-string";

/**
 * Serializes URL params correctly for `express-openapi-validator`. See:
 * - https://github.com/axios/axios/issues/678#issuecomment-634632500
 * - https://github.com/axios/axios/blob/8a8c534a609cefb10824dec2f6a4b3ca1aa99171/lib/helpers/buildURL.js
 * - https://github.com/axios/axios/blob/59ab559386273a185be18857a12ab0305b753e50/lib/utils.js#L177
 *
 * @param params The query params.
 */
export function serializeParams(params: Record<string, any>) {
  if (params instanceof URLSearchParams) {
    return params.toString();
  }
  const formattedParams = {};
  const keys = Object.keys(params);
  const { length } = keys;
  for (let i = 0; i < length; i++) {
    const key = keys[i];
    let value = params[key];
    if (value === null || value === undefined) {
      continue;
    }
    if (Object.prototype.toString.call(value) === "[object Date]") {
      // Format Dates...
      value = value.toISOString();
    } else if (value !== null && typeof value === "object") {
      // Format objects and arrays...
      value = JSON.stringify(value);
    }
    formattedParams[key] = value;
  }
  // URLSearchParams does not handle arrays...
  // return new URLSearchParams(formattedParams).toString();
  return qs.stringify(formattedParams);
}
