import React from "react";

export const RentalImage = ({ fillColor }) => {
  return (
    <svg
      width="84px"
      height="84px"
      viewBox="0 0 84 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 12</title>
      <g
        id="↳-auto-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Create-a-custom-quote-side-bar"
          transform="translate(-584.000000, -1540.000000)"
        >
          <g id="Group-12" transform="translate(584.000000, 1540.000000)">
            <rect
              id="Rectangle"
              fill="#FFFFFF"
              x="0"
              y="0"
              width="84"
              height="84"
            ></rect>
            <g id="Group-10" transform="translate(5.000000, 2.000000)">
              <path
                d="M45.6620058,9.94132187 C34.5282173,9.99263765 25.4657513,10.1571325 25.4657513,10.1571325 C25.4657513,10.1571325 36.2379139,-4.1116989 45.6216057,1.17480035 C55.0052975,6.46129959 65.7847741,9.9713092 65.7847741,9.9713092 C65.7847741,9.9713092 56.7957968,9.89000609 45.6620058,9.94132187 Z"
                id="Path"
                fill="#F9FAFC"
                fillRule="nonzero"
              ></path>
              <path
                d="M16.7268405,77.2510799 C17.1344026,78.7576282 18.530339,79.6906784 18.530339,79.6906784 C18.530339,79.6906784 19.2655194,78.1811251 18.8579693,76.6745768 C18.4504192,75.1680285 17.0544733,74.2349784 17.0544733,74.2349784 C17.0544733,74.2349784 16.3192928,75.7445389 16.7268405,77.2510799 Z"
                id="Path"
                fill="#3F3D56"
                fillRule="nonzero"
              ></path>
              <path
                d="M17.3257066,76.9273135 C18.4440267,78.0159542 18.5806322,79.6894466 18.5806322,79.6894466 C18.5806322,79.6894466 16.9040699,79.5979063 15.7857474,78.5092656 C14.6674248,77.4206248 14.5308217,75.74713 14.5308217,75.74713 C14.5308217,75.74713 16.2073841,75.8386727 17.3257066,76.9273135 Z"
                id="Path"
                fill={fillColor}
                fillRule="nonzero"
              ></path>
              <path
                d="M47.7631526,77.2510799 C48.1707027,78.7576282 49.566651,79.6906784 49.566651,79.6906784 C49.566651,79.6906784 50.3018315,78.1811251 49.8942814,76.6745768 C49.4867313,75.1680285 48.0907854,74.2349784 48.0907854,74.2349784 C48.0907854,74.2349784 47.3556049,75.7445389 47.7631526,77.2510799 Z"
                id="Path"
                fill="#3F3D56"
                fillRule="nonzero"
              ></path>
              <path
                d="M48.3620187,76.9273135 C49.4803388,78.0159542 49.6169443,79.6894466 49.6169443,79.6894466 C49.6169443,79.6894466 47.940382,79.5979063 46.8220594,78.5092656 C45.7037369,77.4206248 45.5671338,75.74713 45.5671338,75.74713 C45.5671338,75.74713 47.2436962,75.8386727 48.3620187,76.9273135 Z"
                id="Path"
                fill={fillColor}
                fillRule="nonzero"
              ></path>
              <g
                id="Group-Copy"
                transform="translate(0.000000, 37.137108)"
                fillRule="nonzero"
              >
                <rect
                  id="Rectangle"
                  fill="#3F3D56"
                  x="0"
                  y="34"
                  width="9"
                  height="2"
                ></rect>
                <path
                  d="M74,34.5383759 L72.1508007,34.9627358 L1.87254739,35 L1.24058354,33.5393755 C0.138397053,31.0339321 -0.239900458,28.2738401 0.147950039,25.5674252 C0.450628741,23.550164 1.20910109,21.5457251 2.93180102,20.6751192 L6.05050451,0.0690266955 L44.931853,0 L59.9749011,12.0361686 C59.9749011,12.0361686 67.0709291,11.9096262 70.9259969,16.670595 C72.1460191,18.1785989 73.0419398,20.1764635 73.2823601,22.8252734 L73.4345005,25.3019653 L74,34.5383759 Z"
                  id="Path"
                  fill="#CDD2E9"
                ></path>
                <circle id="Oval" fill="#3F3D56" cx="57" cy="33" r="9"></circle>
                <circle
                  id="Oval"
                  fill="#CCCCCC"
                  cx="57.5"
                  cy="33.5"
                  r="4.5"
                ></circle>
                <circle id="Oval" fill="#3F3D56" cx="15" cy="33" r="9"></circle>
                <circle
                  id="Oval"
                  fill="#CCCCCC"
                  cx="15.5"
                  cy="33.5"
                  r="4.5"
                ></circle>
                <polygon
                  id="Path"
                  fill="#FFFFFF"
                  points="54 9.98839949 32.9231727 9.99709987 30.3503369 9.99709987 26.0061156 10 26.0061156 9.69954686 26.0030578 6.07670836 26 2.00870038 28.88993 2.00870038 31.4627658 2.00580025 36.0204218 2.00290013 38.5932575 2.00290013 43.8548199 2"
                ></polygon>
                <polygon
                  id="Path"
                  fill="#FFFFFF"
                  points="22 9.99419764 12.0054873 10 12.0027436 7.92623753 12.0027436 4.30237534 12 2.00580236 13.4634548 2.00580236 15.7719491 2.00290118 21.9972564 2"
                ></polygon>
                <rect
                  id="Rectangle"
                  fill="#3F3D56"
                  x="26"
                  y="17"
                  width="2"
                  height="3"
                ></rect>
                <polygon
                  id="Rectangle"
                  fill="#3F3D56"
                  transform="translate(49.667991, 13.375359) rotate(89.969370) translate(-49.667991, -13.375359) "
                  points="48.9615343 12.0395123 50.37444 12.0395159 50.3744469 14.7112052 48.9615411 14.7112016"
                ></polygon>
                <path
                  d="M48,6 L48.2000031,6 C50.2986834,6 52,7.56700338 52,9.5 L52,9.5 C52,11.4329966 50.2986834,13 48.2000031,13 L48,13 L48,6 Z"
                  id="Path"
                  fill="#3F3D56"
                ></path>
                <path
                  d="M74,24.9750921 C71.3836427,25.1964827 68.941663,23.9218738 68.2126067,21.9543203 C67.4835504,19.9867669 68.6786368,17.8963008 71.0449131,17 C72.4821331,18.3933442 73.5375526,20.2393031 73.8207744,22.6867132 L74,24.9750921 Z"
                  id="Path"
                  fill="#3F3D56"
                ></path>
                <polygon
                  id="Path"
                  fill="#F2F2F2"
                  points="32 1 25.0039205 9 25 5.23295262 28.7013162 1.00301557"
                ></polygon>
                <polygon
                  id="Path"
                  fill="#F2F2F2"
                  points="17 2 12 9 12 4.71744135 13.9376911 2.00342852"
                ></polygon>
                <polygon
                  id="Path"
                  fill="#F2F2F2"
                  points="39 2 33.4970138 10 31 10 36.5029862 2"
                ></polygon>
              </g>
              <path
                d="M74,79.6310093 C74,79.6442843 73.9844641,79.657029 73.9564547,79.6664158 C73.9284453,79.6758027 73.8904166,79.6810556 73.8508055,79.6810093 L0.149193547,79.6810093 C0.066796226,79.6810093 3.63797881e-12,79.6586234 3.63797881e-12,79.6310093 C3.63797881e-12,79.6033952 0.066796226,79.5810093 0.149193547,79.5810093 L73.8508055,79.5810093 C73.8904167,79.5809629 73.9284457,79.5862157 73.9564552,79.5956026 C73.9844646,79.6049895 74,79.6177343 74,79.6310093 Z"
                id="Path"
                fill="#3F3D56"
                fillRule="nonzero"
              ></path>
              <g
                id="key-(1)"
                transform="translate(30.000000, 18.637108) rotate(45.000000) translate(-30.000000, -18.637108) translate(19.000000, 8.137108)"
                stroke={fillColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
              >
                <path
                  d="M20.8962426,1.8189894e-12 L18.6887278,2.20759984 M10.2891339,10.6075172 C11.8398745,12.1376741 12.4518341,14.381487 11.89269,16.4871212 C11.333546,18.5927554 9.68903949,20.2373252 7.58348637,20.7964908 C5.47793324,21.3556564 3.23420677,20.7436733 1.70410884,19.192873 C-0.59650476,16.8107802 -0.56360275,13.0243244 1.7780582,10.6825732 C4.11971915,8.34082207 7.90602912,8.30791879 10.2880302,10.608621 L10.2891339,10.6075172 Z M10.2891339,10.6075172 L14.8255769,6.07089955 M14.8255769,6.07089955 L18.1368491,9.3822993 L22,5.51899959 L18.6887278,2.20759984 M14.8255769,6.07089955 L18.6887278,2.20759984"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
