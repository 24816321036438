import React from "react";

export const OtherStructImage = ({ fillColor }) => {
  return (
    <svg
      width="84px"
      height="84px"
      viewBox="0 0 84 84"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Group 2 Copy</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="84" height="84"></rect>
      </defs>
      <g
        id="↳-home-wizard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Home---Create-a-custom-quote-side-bar"
          transform="translate(-564.000000, -362.000000)"
        >
          <g id="Group-2-Copy" transform="translate(564.000000, 362.000000)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use id="Rectangle" fill="#FFFFFF" xlinkHref="#path-1"></use>
            <path
              d="M8.24037915,76.9458776 C8.92640622,79.5175977 11.2761097,81.1103472 11.2761097,81.1103472 C11.2761097,81.1103472 12.5135988,78.5334933 11.827592,75.9617732 C11.1415852,73.3900531 8.79186554,71.7973037 8.79186554,71.7973037 C8.79186554,71.7973037 7.55437639,74.3741534 8.24037915,76.9458776 Z"
              id="Path"
              fill="#002679"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M9.35194051,76.2631529 C11.2672569,78.173578 11.5012174,81.1103472 11.5012174,81.1103472 C11.5012174,81.1103472 8.62981479,80.9497015 6.71449428,79.0392763 C4.79917376,77.1288512 4.56521739,74.1920863 4.56521739,74.1920863 C4.56521739,74.1920863 7.43661999,74.3527278 9.35194051,76.2631529 Z"
              id="Path"
              fill="#FF4343"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
            <g id="Group-20" mask="url(#mask-2)" fillRule="nonzero">
              <g transform="translate(10.956522, 36.339130)">
                <polygon
                  id="Path"
                  fill="#E7EBFD"
                  points="39.3071829 0 14.8413526 0.223502735 0 14.6733537 0.299878373 14.8659777 0.192808975 14.8659777 0.192808975 44.9217391 51.4627643 44.9217391 51.4627643 14.8659777 51.4956522 14.4940684"
                ></polygon>
                <polygon
                  id="Path"
                  fill="#DADFF6"
                  points="39.3431676 0 24.1043478 17.8566782 24.1043478 44.9217391 51.4956522 44.9217391 51.4956522 14.4597904"
                ></polygon>
                <rect
                  id="Rectangle"
                  fill={fillColor}
                  x="31.9565217"
                  y="21.3652174"
                  width="11.5043478"
                  height="23.7391304"
                ></rect>
                <rect
                  id="Rectangle"
                  fill={fillColor}
                  x="10.226087"
                  y="28.6695652"
                  width="5.66086957"
                  height="4.93043478"
                ></rect>
                <rect
                  id="Rectangle"
                  fill={fillColor}
                  x="10.226087"
                  y="20.0869565"
                  width="5.66086957"
                  height="4.93043478"
                ></rect>
              </g>
            </g>
            <rect
              id="Rectangle"
              fill="#000000"
              mask="url(#mask-2)"
              x="-12.9652174"
              y="82.173913"
              width="104.086957"
              height="1"
            ></rect>
            <path
              d="M64.0126482,35.9573123 C64.1911067,34.7081028 64.637253,33.726581 65.351087,33.012747 C66.0649209,32.298913 67.0464427,31.8527668 68.2956522,31.6743083 C67.0464427,31.4958498 66.0649209,31.0497036 65.351087,30.3358696 C64.637253,29.6220356 64.1911067,28.6405138 64.0126482,27.3913043 C63.8341897,28.6405138 63.3880435,29.6220356 62.6742095,30.3358696 C61.9603755,31.0497036 60.9788538,31.4958498 59.7296443,31.6743083 C60.9788538,31.8527668 61.9603755,32.298913 62.6742095,33.012747 C63.3880435,33.726581 63.8341897,34.7081028 64.0126482,35.9573123 Z"
              id="Path"
              fill="#FFCC00"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M62.0584239,45.7554101 C60.85625,44.5532362 60.1048913,42.900247 59.8043478,40.7964427 C59.5038043,42.900247 58.7524457,44.5532362 57.5502717,45.7554101 C56.3480978,46.957584 54.6951087,47.7089427 52.5913043,48.0094862 C54.6951087,48.3100296 56.3480978,49.0613883 57.5502717,50.2635623 C58.7524457,51.4657362 59.5038043,53.1187253 59.8043478,55.2225296 C60.1048913,53.1187253 60.85625,51.4657362 62.0584239,50.2635623 C63.2605978,49.0613883 64.913587,48.3100296 67.0173913,48.0094862 C64.913587,47.7089427 63.2605978,46.957584 62.0584239,45.7554101 Z"
              id="Path"
              fill="#FFCC00"
              mask="url(#mask-2)"
            ></path>
            <path
              d="M73.8735178,45.486166 C73.6950593,46.7353755 73.248913,47.7168972 72.5350791,48.4307312 C71.8212451,49.1445652 70.8397233,49.5907115 69.5905138,49.76917 C70.8397233,49.9476285 71.8212451,50.3937747 72.5350791,51.1076087 C73.248913,51.8214427 73.6950593,52.8029644 73.8735178,54.0521739 C74.0519763,52.8029644 74.4981225,51.8214427 75.2119565,51.1076087 C75.9257905,50.3937747 76.9073123,49.9476285 78.1565217,49.76917 C76.9073123,49.5907115 75.9257905,49.1445652 75.2119565,48.4307312 C74.4981225,47.7168972 74.0519763,46.7353755 73.8735178,45.486166 Z"
              id="Path"
              fill="#FFCC00"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
