import React from "react";
import { Route, Router, Switch } from "react-router-dom";

import { Pages } from "./pages";
import { history, renderPageRoutes, RouteSecurityProvider } from "./lib";
import { AuthLayout, MainLayout, AdminLayout, WizardLayout } from "./layouts";

import HandleTokenRefresh from "./refresh-token";

const AutoWizardPages = React.memo(function AutoWizardPages() {
  return (
    <WizardLayout>
      <Switch>{renderPageRoutes(Pages.autoWizard)}</Switch>
    </WizardLayout>
  );
});

const HomeWizardPages = React.memo(function HomeWizardPages() {
  return (
    <WizardLayout>
      <Switch>{renderPageRoutes(Pages.homeWizard)}</Switch>
    </WizardLayout>
  );
});
const LifeWizardPages = React.memo(function LifeWizardPages() {
  return (
    <WizardLayout>
      <Switch>{renderPageRoutes(Pages.lifeWizard)}</Switch>
    </WizardLayout>
  );
});

const BundleWizardPages = React.memo(function BundleWizardPages() {
  return (
    <WizardLayout>
      <Switch>{renderPageRoutes(Pages.bundleWizard)}</Switch>
    </WizardLayout>
  );
});

const AutoRentersWizardPages = React.memo(function BundleWizardPages() {
  return (
    <WizardLayout>
      <Switch>{renderPageRoutes(Pages.autoRentersWizard)}</Switch>
    </WizardLayout>
  );
});

const MainPages = React.memo(function MainPages() {
  return (
    <MainLayout>
      <Switch>{renderPageRoutes(Pages.main)}</Switch>
    </MainLayout>
  );
});

const AuthPages = React.memo(function AuthPages() {
  return (
    <AuthLayout>
      <Switch>{renderPageRoutes(Pages.auth)}</Switch>
    </AuthLayout>
  );
});

const AdminPages = React.memo(function AdminPages() {
  return (
    <AdminLayout>
      <Switch>{renderPageRoutes(Pages.admin)}</Switch>
      <HandleTokenRefresh />
    </AdminLayout>
  );
});

export const App = React.memo(function App() {
  return (
    <RouteSecurityProvider>
      <Router history={history}>
        <Switch>
          <Route path="/auth">
            <AuthPages />
          </Route>
          <Route path="/admin">
            <AdminPages />
          </Route>
          <Route path="/auto/wizard/:step">
            <AutoWizardPages />
          </Route>
          <Route path="/home/wizard/:step">
            <HomeWizardPages />
          </Route>
          <Route path="/life/wizard/:step">
            <LifeWizardPages />
          </Route>
          <Route path="/renters/wizard/:step">
            <HomeWizardPages />
          </Route>
          <Route path="/bundle/wizard/:step">
            <BundleWizardPages />
          </Route>
          <Route path="/auto-renters/wizard/:step">
            <AutoRentersWizardPages />
          </Route>
          <Route path="/">
            <MainPages />
          </Route>
        </Switch>
      </Router>
    </RouteSecurityProvider>
  );
});
