import React, { useEffect, useState } from "react";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { useDispatch, adminActions } from "../../state";
import { useParams } from "react-router";
import { Button } from "@mui/material";
import { ModalJSONReportView } from "../../components";

function JSONPopup(props) {
  const [showDialog, setShowDialog] = useState(false);
  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setShowDialog(true)}
      >
        Show Data
      </Button>
      <ModalJSONReportView
        {...props}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function ReportView() {
  const params: { id: string } = useParams();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = React.useState(25);
  const [reportData, setReportData] = useState<any>([]);
  const [columns, setColumns] = useState<any>([
    { field: "id", headerName: "ID", width: 90 },
  ]);

  useEffect(() => {
    (async () => {
      const res: any = await dispatch(adminActions.getReportById(params.id));
      const { data, name } = res;
      dispatch(adminActions.setPageTitle("Report Data | " + name));
      if (!data.length) return;
      const columns = Object.keys(data[0]).map((c) => {
        return {
          field: c,
          headerName: c,
          width: 120,
          renderCell: (props) => {
            return typeof props.value === "object" && !!props.value ? (
              <JSONPopup {...props} />
            ) : (
              <span>{props.value}</span>
            );
          },
        };
      });
      setColumns(columns);
      setReportData(data);
    })();
  }, [dispatch, params.id]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGridPro
        autoHeight
        rows={reportData}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
