import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 40,
  },
  field: {
    paddingTop: 28,
    paddingBottom: 28,

    "& .MuiOutlinedInput-input": {
      padding: "0 14px",
    },

    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
      transform: "translate(14px, 16px) scale(1)",
    },
  },
  fieldTitle: {
    marginBottom: 24,
    fontWeight: 600,
  },
  ticketFields: { marginTop: 20 },
  selectLoader: {
    position: "absolute",
    right: 35,
  },
  name: {
    fontWeight: 300,
  },
  switcherGroup: {
    width: "100%",
  },
  switcher: {
    width: "50%",

    "&.MuiToggleButton-root": {
      borderRadius: 0,
      borderColor: "#ccd2e9",
      textTransform: "initial",
      color: "#AFB6D4",
    },

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  removeDriverBtn: {
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: "1px",
  },
  removeIcon: {
    height: 20,
    marginRight: 4,
  },
  error: {
    "& .MuiRadio-root": {
      color: "#f44336",
    },
    "& .MuiToggleButton-root": {
      borderColor: "#f44336",
    },
  },
}));
