import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../state";
import { useStyles } from "./ModalNewAgency.styles";
import BrandingCard from "../../pages/admin/Agency/cards/BrandingCard";
import { IconButton, Box, Modal, Paper, Typography } from "@mui/material";
import { CloseIcon } from "../icons";

export default function ModalNewAgency() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpen = useSelector(adminSelectors.agencyModalOpen);

  const handleCloseNewAgencyModal = () =>
    dispatch(adminActions.setAgencyModalOpen(false));

  return (
    <Modal open={isOpen} onClose={handleCloseNewAgencyModal}>
      <Paper className={classes.paper}>
        <Box className={classes.titleWrapper}>
          <Box>
            <Typography className={classes.title}>
              Create a new agency
            </Typography>
          </Box>

          <Box className={classes.closeIconWrapper}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseNewAgencyModal}
              size="large">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>
        </Box>
        <BrandingCard isNewAgency={true} />
      </Paper>
    </Modal>
  );
}
