import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../icons";
import { useDispatch, useSelector } from "react-redux";
import { uiSelectors, wizardActions, wizardSelectors } from "../../state";
import { useStyles } from "./ModalContact.styles";
// import { IS_AGENCY_SITE, REACT_APP_CHAT_GROUP_ID } from "../../config";
// import { createTicket, customerSDK_agentChat } from "../../utils/liveChat";
import { ContactMethods } from "../../utils/contact";
import { ContactPhone } from "../../assets/img/contact_phone";
import { useTheme } from "@mui/material/styles";
import { ContactEmail } from "../../assets/img/contact_email";
import { Api } from "../../lib/api";

export default function ModalContact({ snag = false }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const { isOpen, type } = useSelector(wizardSelectors.contactModal);
  const contactInfo = useSelector(wizardSelectors.contactInfo);
  const { id, default_agent_image_path } = useSelector(uiSelectors.agencyData);
  const req_uid = useSelector(wizardSelectors.req_uid);
  const email = contactInfo.email;
  const phone = contactInfo.phone;

  const { turbo_quote_id } = useSelector(wizardSelectors.selectedQuote) || {};
  const [userInput, setUserInput] = useState("");

  useEffect(() => {
    if (type === "email") {
      setUserInput(email);
    } else {
      setUserInput(phone);
    }
  }, [type]);

  // const quoteSummary = `You have selected the ${getInsuranceLevelTitle(
  //   insurance_level_id,
  // ).toLowerCase()} with ${carrier} at $${PayPlanDownPayment}. Total premium: ${TotalPremium}`;

  // const getTicketFields = () => {
  //   return [
  //     {
  //       type: "subject",
  //       id: 0,
  //       label: "Subject: ",
  //       answer: "Finish Quote",
  //     },
  //     {
  //       type: "email",
  //       id: 1,
  //       label: "Customer email: ",
  //       answer: email,
  //     },
  //     {
  //       type: "textarea",
  //       id: 2,
  //       label: "Coverage Summary: ",
  //       answer: quoteSummary,
  //     },
  //     {
  //       type: "textarea",
  //       id: 3,
  //       label: "Preferred Method of contact: ",
  //       answer: type,
  //     },
  //     {
  //       type: "textarea",
  //       id: 4,
  //       label: type === "email" ? "Email: " : "Phone: ",
  //       answer: contactInfo || userInput,
  //     },
  //     {
  //       type: "textarea",
  //       id: 5,
  //       label: `Quote ID:`,
  //       answer: turbo_quote_id,
  //     },
  //   ];
  // };

  const handleCloseContactModal = () => {
    dispatch(wizardActions.closeContactModal());
    setUserInput("");
  };

  const handleSubmit = () => {
    (async () => {
      await dispatch(wizardActions.setContactInfo(userInput));
      await dispatch(wizardActions.setContactType(type));
      const contactData = {
        method: type,
        contact_info: userInput,
        agent_avatar: default_agent_image_path || "", //to be backwards compatible
      };
      !snag
        ? await Api.put(`/contact-me/${turbo_quote_id || req_uid}`, contactData)
        : await Api.put(`/contact-me/snag/agency/${id}`, {
            ...contactData,
            req_uid,
          });
      // if (!IS_AGENCY_SITE) {
      //   createTicket(
      //     customerSDK_agentChat,
      //     REACT_APP_CHAT_GROUP_ID,
      //     getTicketFields(),
      //   );
      // }
      handleCloseContactModal();
    })();
  };
  const { method, action, label, button } = ContactMethods[type || "email"];
  return (
    <Fade in={!!isOpen}>
      <div>
        <Modal open={!!isOpen} onClose={handleCloseContactModal}>
          <Box className={classes.box}>
            <IconButton
              className={classes.closeBtn}
              onClick={handleCloseContactModal}
              size="large">
              <CloseIcon />
            </IconButton>
            <div className={classes.titleWrapper}>
              <div className={classes.img}>
                {type === "email" ? (
                  <ContactEmail fillColor={theme.palette.primary.main} />
                ) : (
                  <ContactPhone fillColor={theme.palette.primary.main} />
                )}
              </div>
              <Typography className={classes.title}>
                What {method} should we {action} you at?
              </Typography>
              <TextField
                id="contact_info"
                name="contact_info"
                label={label}
                value={userInput}
                onChange={(e) => {
                  setUserInput(e.target.value);
                }}
                className={classes.input}
              />
              <Button
                className={classes.selectBtn}
                variant="contained"
                color="primary"
                size="small"
                disabled={!userInput}
                onClick={() => handleSubmit()}
              >
                {button}
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </Fade>
  );
}
