import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { EditIcon } from "../../components/icons";
import WizardStepImg from "../wizard/stepper/WizardStepper/WizardStepImg";

import {
  useAction,
  autoActions,
  useSelector,
  autoSelectors,
  wizardSelectors,
  wizardNoPersistSelectors,
  homeSelectors,
  uiSelectors,
} from "../../state";
import { Navigation, useLocation } from "../../lib";
import { useMobile } from "../../themes";

import { useStyles } from "./Sidebar.styles";
import { formatTitleCase } from "../../utils";

const SidebarContent = ({ setDrawerOpen }) => {
  const isMobile = useMobile();
  const location = useLocation();
  const setDriverStep = useAction(autoActions.setDriverStep);
  const setVehicleStep = useAction(autoActions.setVehicleStep);

  const wizardActiveStep = useSelector(wizardSelectors.activeStep);
  const vehiclesForm = useSelector(autoSelectors.vehiclesForm);
  const selectedDrivers = useSelector(autoSelectors.selectedDrivers);
  const selectedVehicles = useSelector(autoSelectors.selectedVehicles);
  const autoDiscounts = useSelector(autoSelectors.discounts);
  const { address } = useSelector(homeSelectors.start);
  const details = useSelector(homeSelectors.details);
  const homeDiscounts = useSelector(homeSelectors.discounts);
  const insured = useSelector(homeSelectors.insured);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const loading = useSelector(uiSelectors.loading);
  const [editDisabled, setEditDisabled] = useState(false);
  const classes = useStyles({ editDisabled });
  const [url, setUrl] = useState(location.pathname);

  useEffect(() => {
    if (wizardActiveStep > 4 || loading) {
      setEditDisabled(true);
    } else {
      setEditDisabled(false);
    }
  }, [wizardActiveStep, setEditDisabled, location.pathname, loading]);

  useEffect(() => {
    setUrl(location.pathname);
  }, [location.pathname]);

  const handleVehicleEdit = useCallback(
    (index) => () => {
      if (editDisabled) return;
      setVehicleStep(index);
      Navigation.go(
        `${
          url.includes("bundle")
            ? "/bundle/wizard/vehicles"
            : url.includes("auto-renters")
            ? "/auto-renters/wizard/vehicles"
            : "/auto/wizard/vehicles"
        }`,
      );
      setDrawerOpen(false);
    },
    [setVehicleStep, setDrawerOpen, wizardActiveStep, editDisabled],
  );

  const handleDriverEdit = useCallback(
    (index) => () => {
      if (editDisabled) return;
      setDriverStep(index);
      Navigation.go(
        `${
          url.includes("bundle")
            ? "/bundle/wizard/drivers"
            : url.includes("auto-renters")
            ? "/auto-renters/wizard/drivers"
            : "/auto/wizard/drivers"
        }`,
      );
      setDrawerOpen(false);
    },
    [setDriverStep, setDrawerOpen, wizardActiveStep, editDisabled],
  );

  const handleEdit = useCallback(
    (next) => () => {
      if (editDisabled) return;
      Navigation.go(next);
      setDrawerOpen(false);
    },
    [setDrawerOpen, wizardActiveStep, editDisabled],
  );

  const autoDiscountsPage =
    mode === "bundle"
      ? "/bundle/wizard/auto-discounts"
      : mode === "auto-renters"
      ? "/auto-renters/wizard/auto-discounts"
      : "/auto/wizard/discounts";

  const homeDiscountsPage =
    mode === "bundle"
      ? "/bundle/wizard/home-discounts"
      : mode === "auto-renters"
      ? "/auto-renters/wizard/home-discounts"
      : "/home/wizard/discounts";

  const EnabledAutoDiscounts = () => {
    const discounts: string[] = [];
    if (autoDiscounts.residence_owned_rented === "O") {
      discounts.push("Homeowner");
    }
    if (autoDiscounts.employment_military) {
      discounts.push("Military");
    }
    if (autoDiscounts.paperless_discount) {
      discounts.push("Paperless");
    }
    if (autoDiscounts.autopay) {
      discounts.push("Autopay");
    }
    return discounts;
  };

  const EnabledHomeDiscounts = () => {
    const discounts: string[] = [];
    if (!homeDiscounts?.discounts) return discounts;
    const enabledDiscounts = Object.keys(homeDiscounts.discounts).filter(
      (d) => d !== "paperless" && homeDiscounts.discounts[d] === true,
    );
    if (enabledDiscounts.length) {
      discounts.push("Protective Devices");
    }
    if (homeDiscounts.discounts.paperless) {
      discounts.push("Paperless");
    }
    return discounts;
  };

  return (
    <Grid item className={classes.sidebarContent}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        style={{ height: "100%" }}
      >
        <Grid item className={classes.sidebarHeader}>
          {url.includes("start") ||
          url.includes("verify") ||
          url.includes("code") ? (
            <Box className={classes.ovalWrapper}>
              <Typography className={classes.oval}>
                Save up <br /> to $1,200
              </Typography>
              <Typography className={classes.oval}>
                No <br /> spam
              </Typography>
            </Box>
          ) : (
            <>
              {(mode === "auto" ||
                mode === "bundle" ||
                mode === "auto-renters") && (
                <>
                  {Boolean(vehiclesForm?.submittedVehicles?.length) && (
                    <Box>
                      {(mode === "bundle" || mode === "auto-renters") && (
                        <Box
                          className={classes.sidebarLabelWrapper}
                          style={{
                            borderRadius: "0px 10px 0px 0px",
                          }}
                        >
                          <Typography>AUTO</Typography>
                        </Box>
                      )}
                      <Box className={classes.sidebarItemWrap}>
                        <Typography className={classes.sidebarTitle}>
                          Vehicles
                        </Typography>
                        {selectedVehicles?.map((vehicle, i) => {
                          return (
                            // only show when submitted
                            vehicle.usage && (
                              <Grid
                                key={`vehicle-${vehicle.VIN || i}`}
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                className={classes.sidebarItem}
                                onClick={handleVehicleEdit(i)}
                              >
                                <Typography className={classes.sidebarItemText}>
                                  {vehicle.ModelYear} {vehicle.Maker}{" "}
                                  {vehicle.Model}
                                </Typography>
                                {!editDisabled && (
                                  <EditIcon
                                    className={classes.sidebarItemIcon}
                                  />
                                )}
                              </Grid>
                            )
                          );
                        })}
                      </Box>
                    </Box>
                  )}
                  {Boolean(
                    selectedDrivers.filter((d) => d.isSubmitted).length,
                  ) && (
                    <Box className={classes.sidebarItemWrap}>
                      <Typography className={classes.sidebarTitle}>
                        Drivers
                      </Typography>
                      {selectedDrivers.map((driver, i) => {
                        return (
                          driver.isSubmitted && (
                            <Grid
                              key={`driver-${i}`}
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              className={classes.sidebarItem}
                              onClick={handleDriverEdit(i)}
                            >
                              <Typography
                                noWrap
                                className={classes.sidebarItemText}
                              >
                                {formatTitleCase(driver.FirstName)}{" "}
                                {formatTitleCase(driver.MiddleName)}{" "}
                                {formatTitleCase(driver.LastName)}
                              </Typography>
                              {!editDisabled && (
                                <EditIcon className={classes.sidebarItemIcon} />
                              )}
                            </Grid>
                          )
                        );
                      })}
                    </Box>
                  )}
                  {Boolean(EnabledAutoDiscounts().length) && (
                    <Box className={classes.sidebarItemWrap}>
                      <Typography className={classes.sidebarTitle}>
                        Discounts
                      </Typography>
                      {EnabledAutoDiscounts().map((discount, i) => {
                        return (
                          <Grid
                            key={`discounts-${i}`}
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.sidebarDiscountItem}
                            onClick={handleEdit(autoDiscountsPage)}
                          >
                            <Typography
                              noWrap
                              className={classes.sidebarItemText}
                            >
                              {discount}
                            </Typography>
                            {!editDisabled && (
                              <EditIcon className={classes.sidebarItemIcon} />
                            )}
                          </Grid>
                        );
                      })}
                    </Box>
                  )}
                </>
              )}
              {(mode === "home" ||
                mode === "bundle" ||
                mode === "renters" ||
                mode === "auto-renters") &&
                Boolean(details?.SquareFootage) && (
                  <Box>
                    {(mode === "bundle" || mode === "auto-renters") && (
                      <Box className={classes.sidebarLabelWrapper}>
                        <Typography>
                          {mode === "bundle" ? "HOMEOWNERS" : "RENTERS"}{" "}
                        </Typography>
                      </Box>
                    )}
                    <Box className={classes.sidebarItemWrap}>
                      <Typography className={classes.sidebarTitle}>
                        Property
                      </Typography>
                      <Grid
                        key={`address`}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.sidebarItem}
                        onClick={handleEdit(
                          mode === "bundle"
                            ? "/bundle/wizard/details/form"
                            : mode === "auto-renters"
                            ? "/auto-renters/wizard/details/form"
                            : mode === "home"
                            ? "/home/wizard/details/form"
                            : "/renters/wizard/details/form",
                        )}
                      >
                        <Typography className={classes.sidebarItemText}>
                          {address.street_line} <br />
                          {address.city}
                          {", "} {address.state} {address.zipcode}
                        </Typography>
                        {!editDisabled && (
                          <EditIcon className={classes.sidebarItemIcon} />
                        )}
                      </Grid>
                    </Box>

                    {Boolean(EnabledHomeDiscounts().length) && (
                      <Box className={classes.sidebarItemWrap}>
                        <Typography className={classes.sidebarTitle}>
                          Discounts
                        </Typography>
                        {EnabledHomeDiscounts().map((discount, i) => {
                          return (
                            <Grid
                              key={`discounts-${i}`}
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              className={classes.sidebarDiscountItem}
                              onClick={handleEdit(homeDiscountsPage)}
                            >
                              <Typography
                                noWrap
                                className={classes.sidebarItemText}
                              >
                                {discount}
                              </Typography>
                              {!editDisabled && (
                                <EditIcon className={classes.sidebarItemIcon} />
                              )}
                            </Grid>
                          );
                        })}
                      </Box>
                    )}
                    {insured.dob && (
                      <Box className={classes.sidebarItemWrap}>
                        <Typography className={classes.sidebarTitle}>
                          Applicants
                        </Typography>
                        <Grid
                          key={`insured`}
                          container
                          justifyContent="space-between"
                          alignItems="center"
                          className={classes.sidebarItem}
                          onClick={handleEdit(
                            mode === "bundle"
                              ? "/bundle/wizard/insured"
                              : mode === "auto-renters"
                              ? "/auto-renters/wizard/insured"
                              : mode === "home"
                              ? "/home/wizard/insured"
                              : "/renters/wizard/insured",
                          )}
                        >
                          <Typography
                            noWrap
                            className={classes.sidebarItemText}
                          >
                            {formatTitleCase(insured.firstName)}{" "}
                            {formatTitleCase(insured.middleName)}{" "}
                            {formatTitleCase(insured.lastName)}
                          </Typography>
                          {!editDisabled && (
                            <EditIcon className={classes.sidebarItemIcon} />
                          )}
                        </Grid>
                        {insured.coapplicant?.firstName && (
                          <Grid
                            key={`insured-coapplicant`}
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            className={classes.sidebarItem}
                            onClick={handleEdit(
                              mode === "bundle"
                                ? "/bundle/wizard/insured"
                                : mode === "auto-renters"
                                ? "/auto-renters/wizard/insured"
                                : mode === "home"
                                ? "/home/wizard/insured"
                                : "/renters/wizard/insured",
                            )}
                          >
                            <Typography
                              noWrap
                              className={classes.sidebarItemText}
                            >
                              {formatTitleCase(insured.coapplicant?.firstName)}{" "}
                              {formatTitleCase(insured.coapplicant?.middleName)}{" "}
                              {formatTitleCase(insured.coapplicant?.lastName)}
                            </Typography>
                            {!editDisabled && (
                              <EditIcon className={classes.sidebarItemIcon} />
                            )}
                          </Grid>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
            </>
          )}
        </Grid>
        {!isMobile && <WizardStepImg />}
      </Grid>
    </Grid>
  );
};

export default SidebarContent;
