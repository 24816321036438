import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  AddIcon,
  ArrowForwardIosIcon,
  DirectionsCar,
  EditIcon,
  HistoryIcon,
  MoreHorizIcon,
  PersonIcon,
  NotesIcon,
  ContactPhoneIcon,
  PolicyIcon,
  ArrowBackIcon,
  InsertDriveFileIcon,
} from "../../../components";
import { useStyles } from "./MobileDrawer.styles";
import { Navigation, history } from "../../../lib";

import { useDispatch, useSelector } from "react-redux";
import { adminActions, adminSelectors } from "../../../state";
import { NotesSection } from "../QuoteManagement/NotesSection";
import { useParams } from "react-router-dom";
import { NODE_ENV } from "../../../config";
import { Typography } from "@mui/material";
import { StatusActivity } from "../../../components/modals/ModalActivity";
import {
  ApplicantInfo,
  HomeApplicants,
  DriversList,
  LifeCoverage,
  VehiclesList,
  SupportingDocs,
} from "../QuoteManagement/Components";

type Anchor = "top" | "left" | "bottom" | "right";

export default function MobileDrawer() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    applicant,
    baseInfo,
    quoteTypes,
    quoteType,
    agency,
    quote_documents: quoteDocuments,
    aff_agent,
    quote_comments,
    quote_activity,
  } = useSelector(adminSelectors.activeQuote);

  const params: Record<string, any> = useParams();

  const quoteId = params.masterQuoteId;
  const drivers = applicant.drivers;
  const vehicles = applicant.vehicles;

  const hasCoApplicant = !!applicant.coApplicant?.first_name;
  let homeApplicants;
  if (hasCoApplicant) {
    homeApplicants = [applicant, applicant.coApplicant];
  }

  const setAsPrimary = (driver) => {
    dispatch(
      adminActions.setActiveQuoteNew({
        applicant: {
          first_name: driver.first_name,
          middle_name: driver.middle_name,
          last_name: driver.last_name,
          gender: driver.gender,
          dob: driver.dob,
          created_at: new Date(),
          req_uid: params.masterQuoteId,
        },
        domain: "",
        quotes: [],
      }),
    );
    return Navigation.go("/admin/quote-management/new");
  };

  const urlMode = quoteTypes.includes("AutoRenters")
    ? "auto-renters"
    : quoteType.toLowerCase();
  const hydrateLink = `${
    NODE_ENV !== "development"
      ? `https://${agency?.domain}`
      : `http://${window.location.host}`
  }/${urlMode}/wizard/hydrate-quote/${applicant.req_uid}`;

  const [state, setState] = useState({
    bottom: false,
    right: false,
  });

  const [componentIndex, setComponentIndex] = useState<number>(0);
  const [showNoteInput, setShowNoteInput] = useState(false);
  const items = [
    {
      text: "Edit in wizard",
      showArrow: false,
      icon: <EditIcon />,
      showItem: quoteType !== "manual",
      func: () => window.open(`${hydrateLink}`, "_blank"),
    },
    {
      text: "Edit application",
      showArrow: false,
      icon: <EditIcon />,
      showItem: quoteType === "manual",
      func: () => history.push(`/admin/edit-application/${applicant.req_uid}`),
    },
    {
      text: "Applicant information",
      showArrow: true,
      icon: <ContactPhoneIcon />,
      showItem: true,
      component: (
        <ApplicantInfo
          applicant={applicant}
          baseInfo={baseInfo}
          quoteType={quoteType}
          aff_agent={aff_agent}
          agency={agency}
        />
      ),
    },
    {
      text: "Home applicants",
      showArrow: true,
      icon: <PersonIcon />,
      showItem: !!homeApplicants,
      component: (
        <HomeApplicants
          homeApplicants={homeApplicants}
          setAsPrimary={setAsPrimary}
        />
      ),
    },
    {
      text: "Drivers",
      showArrow: true,
      icon: <PersonIcon />,
      showItem: !!drivers,
      component: <DriversList drivers={drivers} setAsPrimary={setAsPrimary} />,
    },
    {
      text: "Coverage",
      showArrow: true,
      icon: <PolicyIcon />,
      showItem: !!baseInfo,
      component: <LifeCoverage baseInfo={baseInfo} />,
    },
    {
      text: "Vehicles",
      showArrow: true,
      icon: <DirectionsCar />,
      showItem: !!vehicles,
      component: <VehiclesList vehicles={vehicles} />,
    },
    {
      text: "Supporting documents",
      showArrow: true,
      icon: <InsertDriveFileIcon />,
      showItem: true,
      component: <SupportingDocs quoteDocuments={quoteDocuments} />,
    },
    {
      text: "Notes",
      showArrow: true,
      icon: <NotesIcon />,
      showItem: true,
      component: (
        <NotesSection
          quoteId={quoteId}
          comments={quote_comments}
          showNoteInput={showNoteInput}
          setShowNoteInput={setShowNoteInput}
        />
      ),
    },
    {
      text: "Status activity",
      showArrow: true,
      icon: <HistoryIcon />,
      showItem: true,
      component: <StatusActivity statusHistory={quote_activity} />,
    },
    {
      text: "Add manual application",
      showArrow: false,
      icon: <AddIcon />,
      showItem: true,
      func: () => history.push("/admin/create-application"),
    },
  ];

  // filter out which components to show based on quote type
  const filteredItems = items.filter((i) => !!i.showItem);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const setDrawerOpen = (reason) => {
    if (reason === "backdropClick") {
      setState({ bottom: false, right: false });
    }
  };
  const list = (anchor: Anchor) => {
    return (
      <div className={classes.list} role="presentation">
        <List>
          {filteredItems.map((item, index) => (
            <ListItem
              button
              key={item.text}
              className={classes.listItem}
              onClick={() => {
                toggleDrawer(anchor, false);
                if (item.func) {
                  item.func();
                  return;
                }
                setComponentIndex(index);
                setState({ bottom: false, right: true });
              }}
            >
              {<ListItemIcon>{item.icon}</ListItemIcon>}
              <ListItemText primary={item.text} />
              {item.showArrow && <ArrowForwardIosIcon />}
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  return (
    <div>
      {(["bottom"] as Anchor[]).map((anchor) => (
        <React.Fragment key={"bottom"}>
          <Button
            color="primary"
            onClick={toggleDrawer("bottom", true)}
            className={classes.button}
          >
            <MoreHorizIcon />
          </Button>
          <Drawer
            anchor={"bottom"}
            open={state["bottom"]}
            onClose={(e, r) => {
              setDrawerOpen(r);
            }}
            transitionDuration={{ enter: 500, exit: 250 }}
          >
            {list(anchor)}
          </Drawer>
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={(e, r) => {
              setDrawerOpen(r);
            }}
            transitionDuration={{ enter: 500, exit: 250 }}
            className={classes.rightDrawer}
          >
            {
              <div className={classes.wrapper}>
                <div className={classes.drawerHeader}>
                  <div className={classes.drawerHeader}>
                    <div
                      onClick={() => setState({ bottom: true, right: false })}
                    >
                      <ArrowBackIcon />
                    </div>
                    <Typography>
                      {filteredItems[componentIndex].text}
                    </Typography>
                  </div>
                  {filteredItems[componentIndex].text === "Notes" && (
                    <Button
                      color="primary"
                      className={classes.addButton}
                      onClick={() => setShowNoteInput(true)}
                    >
                      <AddIcon />
                    </Button>
                  )}
                </div>
                {filteredItems[componentIndex].component}
              </div>
            }
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
