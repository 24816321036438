import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, Menu, MenuItem, Link as LinkTab } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  AccountCircleIcon,
  KeyboardArrowDown,
  LaunchIcon,
} from "../../../components/icons";

import {
  authSelectors,
  uiActions,
  useDispatch,
  useSelector,
} from "../../../state";

import { useStyles } from "./TopRightMenu.styles";
import LogoutIcon from "../../../components/icons/LogoutIcon";
import { NODE_ENV } from "../../../config";
import PersonalLink from "../../../components/icons/PersonalLinkIcon";
import { useMobile } from "../../../themes";

const TopRightMenu = () => {
  const classes = useStyles();
  const isMobile = useMobile();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const userFirstName = useSelector(authSelectors.userFirstName);
  const agencyDomain = useSelector(authSelectors.domain);
  const referral_token = useSelector(authSelectors.referralToken);
  const affiliateToken = useSelector(authSelectors.affiliateToken);
  const domain =
    NODE_ENV === "development" ? window.location.host : agencyDomain;
  const activeRole = useSelector(authSelectors.activeRole);
  const notProductOwner = activeRole !== "referral_product_owner";
  const isAffiliateAgent = activeRole === "affiliate_agent";
  const salesDirectorRole =
    activeRole === "sales_director" || activeRole === "sales_admin";
  const dispatch = useDispatch();
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const urlParam = affiliateToken
    ? `affiliate_token=${affiliateToken}`
    : `referral_token=${referral_token}`;
  const SiteLink = () => {
    return (
      <LinkTab
        href={`https://${agencyDomain}?${urlParam}`}
        target="_blank"
        className={classes.link}
      >
        <span className={classes.iconWrapper}>
          <LaunchIcon />
        </span>{" "}
        View site
      </LinkTab>
    );
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div className={classes.topRight}>
      <Button
        className={classes.menuBtn}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          <Avatar className={classes.avatar}>{userFirstName.charAt(0)}</Avatar>
          <KeyboardArrowDown />
        </div>
      </Button>
      <Menu
        id="admin-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        className={classes.menu}
        onClose={handleClose}
      >
        {!isAffiliateAgent ? (
          <MenuItem
            onClick={handleClose}
            className={classes.link}
            component={Link}
            to="/admin/profile"
          >
            <span className={classes.iconWrapper}>
              <AccountCircleIcon />
            </span>{" "}
            Profile
          </MenuItem>
        ) : null}
        {notProductOwner && !salesDirectorRole && (
          <MenuItem className={classes.link}>
            <CopyToClipboard
              onCopy={() => {
                dispatch(uiActions.showSuccess("Link copied to clipboard"));
              }}
              text={`${domain}?${urlParam}`}
            >
              <div>
                <span className={classes.linkWrapper}>
                  <PersonalLink />
                </span>
                My personal link
              </div>
            </CopyToClipboard>
          </MenuItem>
        )}
        {isMobile ? (
          <MenuItem className={classes.link}>
            <SiteLink />
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={handleClose}
          className={classes.link}
          component={Link}
          to="/auth/logout"
        >
          <span className={classes.iconWrapper}>
            <LogoutIcon />
          </span>{" "}
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TopRightMenu;
