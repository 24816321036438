import React from "react";
import { Box, Drawer, Hidden, Link } from "@mui/material";
import SidebarContent from "./SidebarContent";
import { REACT_APP_ASSETS_URL } from "../../config";
import {
  useSelector,
  uiSelectors,
  wizardNoPersistSelectors,
  authSelectors,
} from "../../state";
import { useStyles } from "./Sidebar.styles";
import WizardStepImg from "../wizard/stepper/WizardStepper/WizardStepImg";

export default function Sidebar({ mobileOpen, setMobileOpen }) {
  const { logo_height, logo_path } = useSelector(uiSelectors.agencyData);
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const user = useSelector(authSelectors.user);
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const classes = useStyles({ logo_height });

  return <>
    <Hidden smUp implementation="css">
      <Drawer
        variant="temporary"
        anchor={"left"}
        open={mobileOpen}
        onClose={() => setMobileOpen(false)}
        classes={{
          paper: classes.sidebar,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Box className={classes.logoContainer}>
          {logo_path && (
            <Link href="/">
              <img src={`${REACT_APP_ASSETS_URL}/${logo_path}`} alt="logo" />
            </Link>
          )}
        </Box>
        {mode !== "" ? (
          <SidebarContent setDrawerOpen={setMobileOpen} />
        ) : (
          <Box className={classes.sidebarContent} style={{ height: "100vh" }}>
            <WizardStepImg />
          </Box>
        )}
      </Drawer>
    </Hidden>
    <div className={classes.sidebarContainer}>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            root: classes.sidebarWrapper,
            paper: classes.sidebar,
          }}
          variant="permanent"
          open
        >
          <Box className={classes.logoContainer}>
            {logo_path && (
              <Link href="/">
                <img
                  src={`${REACT_APP_ASSETS_URL}/${logo_path}`}
                  alt="logo"
                />
              </Link>
            )}
          </Box>
          {mode !== "" ? (
            <SidebarContent setDrawerOpen={setMobileOpen} />
          ) : (
            <Box
              className={classes.sidebarContent}
              style={{ height: "100vh" }}
            >
              <WizardStepImg />
            </Box>
          )}
        </Drawer>
      </Hidden>
      {!!isLoggedIn && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 8,
            zIndex: 9999,
            padding: 8,
            background: "#e8e8e8",
          }}
        >
          Logged In as {user.first_name} {user.last_name}
        </div>
      )}
    </div>
  </>;
}
