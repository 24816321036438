import React from "react";
import { Button, Grid, Link, Typography } from "@mui/material";
import { Navigation } from "../../../lib";
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from "react-redux";
import { wizardNoPersistSelectors } from "../../../state";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: -40,
  },
  secondLine: {
    fontSize: 32,
    fontWeight: 300,
    textAlign: "center",
    marginTop: 8,
    marginBottom: 32,
    [theme.breakpoints.down('md')]: {
      fontSize: 28,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  link: {
    marginTop: 24,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    fontSize: 14,
    fontWeight: 500,
    transition: "all 300ms linear",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5,
    },
  },
}));

export default function Continue() {
  const classes = useStyles();
  const mode = useSelector(wizardNoPersistSelectors.mode);
  const url =
    mode === "bundle"
      ? "/bundle/wizard/details/form"
      : "/auto-renters/wizard/details/form";
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.container}
    >
      <Typography variant="h1" style={{ textAlign: "center" }}>
        We got your auto details!
      </Typography>
      <Typography className={classes.secondLine}>
        Let’s move on to {mode === "bundle" ? "homeowners" : "renters"}{" "}
        insurance
      </Typography>
      <Button
        color="primary"
        variant="contained"
        onClick={() => Navigation.go(url)}
      >
        Continue to {mode === "bundle" ? "Homeowners" : "Renters"}
      </Button>
      <Link href="/auto/wizard/contact" className={classes.link}>
        I just want to see my auto quotes
      </Link>
    </Grid>
  );
}
