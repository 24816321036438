import React from "react";

export const Analytics = () => {
  return (
    <iframe
      src="https://datastudio.google.com/embed/reporting/45f2172e-3c0c-4f15-af72-d70f82fb6ade/page/1M"
      style={{ border: "none", width: "100%", height: "calc(100vh - 166px)" }}
      allowFullScreen
    />
  );
};
