import { arrayToEntityList } from "../../lib";
import { AppState } from "../states";
import { createSelector } from "reselect";

const _state = (state: AppState) => state.auto;

const _selectedVehicles = createSelector(
  _state,
  (auto) =>
    auto.vehiclesForm?.selectedVehicles?.map((sv) => ({
      ...sv,
      userEntered: !auto.uddVehicles?.find((v) => sv.VIN === v.VIN),
    })) ?? [],
);
export const autoSelectors = {
  activeVehicleIndex(state: AppState) {
    return state.auto.activeVehicleIndex;
  },
  activeDriverIndex(state: AppState) {
    return state.auto.activeDriverIndex;
  },
  showSelectVehicleView(state: AppState) {
    return state.auto.showSelectVehicleView;
  },
  showSelectDriverView(state: AppState) {
    return state.auto.showSelectDriverView;
  },
  //START
  startForm(state: AppState) {
    return state.auto.startForm ?? {};
  },
  state(state: AppState) {
    return state.auto.startForm?.address?.state || "";
  },
  //VEHICLES
  uddVehicles(state: AppState) {
    return state.auto.uddVehicles;
  },
  vehiclesForm(state: AppState) {
    return state.auto.vehiclesForm ?? {};
  },
  getVinError(state: AppState) {
    return state.auto.vehiclesForm.vinError;
  },
  selectedVehicles: _selectedVehicles,
  makesLoading(state: AppState) {
    return state.auto.makesLoading;
  },
  modelsLoading(state: AppState) {
    return state.auto.modelsLoading;
  },
  //APPLICANT
  fullName(state: AppState) {
    const { selectedDrivers, applicantIndex } = state.auto.driversForm;
    return applicantIndex !== undefined
      ? `${selectedDrivers[applicantIndex].FirstName} ${selectedDrivers[applicantIndex].MiddleName} ${selectedDrivers[applicantIndex]?.LastName}`
      : "";
  },
  applicantIndex(state: AppState) {
    return state.auto.driversForm.applicantIndex;
  },
  applicant(state: AppState) {
    const { selectedDrivers, applicantIndex } = state.auto.driversForm;
    return applicantIndex !== undefined
      ? selectedDrivers[applicantIndex]
      : undefined;
  },
  //DRIVERS
  uddDrivers(state: AppState) {
    return state.auto.uddDrivers;
  },
  selectedDrivers(state: AppState) {
    return state.auto.driversForm.selectedDrivers ?? [];
  },
  industriesOccupations(state: AppState) {
    return state.auto.industriesOccupations;
  },
  industries(state: AppState) {
    return state.auto.industries;
  },
  industriesOccupationsLoading(state: AppState) {
    return state.auto.industriesOccupationsLoading;
  },
  //DISCOUNTS
  discounts(state: AppState) {
    return state.auto.discountsForm;
  },
  //QUOTES | COVERAGE | PAYMENT
  coverages(state: AppState) {
    return state.auto.coverages;
  },
  coveragesObj(state: AppState) {
    const coverages = state.auto.coverageInfo[0]?.Coverages;
    return coverages ? arrayToEntityList(coverages, "CoverageCd").entities : {};
  },
  defaultCoverages(state: AppState) {
    return state.auto.coverageInfo?.defaults ?? {};
  },
  displayCoverages(state: AppState) {
    return state.auto.coverageInfo[0]?.displayCoverages;
  },
  moreCoverages(state: AppState) {
    return state.auto.coverageInfo[0]?.moreCoverages ?? [];
  },
  showQuoteCardDetails(state: AppState) {
    return state.auto.showQuoteCardDetails;
  },
  quotes(state: AppState) {
    return state.auto.quotes || [];
  },
  bundleQuotes(state: AppState) {
    return state.auto.bundleQuotes || [];
  },
  bundleQuotesFailed(state: AppState) {
    return state.auto.bundleQuotesFailed;
  },
  selectedQuote(state: AppState) {
    return state.auto.selectedQuote;
  },
  customData(state: AppState) {
    return state.auto.customData;
  },
  monthlyPayment(state: AppState) {
    const monthlyPayments =
      state.auto.selectedQuote?.monthly_payments_override ||
      state.auto.selectedQuote?.PayPlanPaymentAmount;
    return parseFloat(monthlyPayments);
  },
};
