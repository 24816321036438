import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  applicationsPage: {
    width: "96%",
    height: "100%",
    [theme.breakpoints.down('md')]: {
      width: "100%",
      paddingBottom: 48,
    },
    "& .child-row-styles": {
      backgroundColor: "#f7f9ff",
    },
    "& .parent-row:hover": {
      cursor: "pointer",
    },
  },
  pageHeader: {
    // display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    "& h1": {
      fontSize: 20,
      fontWeight: 600,
      margin: "8px 0",
    },
    [theme.breakpoints.down('md')]: {
      display: "block",
    },
  },
  headerTop: {
    display: "flex",
    justifyContent: "space-between",
    "& .MuiButton-root": {
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    "& .MuiButton-textPrimary:hover ": {
      backgroundColor: "white",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    "&.MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row !important",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  applicationsTable: {
    "& .MuiDataGrid-main": {
      fontFamily: "Montserrat",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#E5EBFF",
    },
    "& a": {
      color: "inherit",
      width: "100%",
      textAlign: "center",
    },
  },
  statusSelector: {
    boxSizing: "border-box",
    height: 87,
    width: 140,
    border: "1px solid #DADFF6",
    borderRadius: 3,
    backgroundColor: "#FFFFFF",
    padding: 20,
    cursor: "pointer",
    marginRight: 15,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  amount: { fontWeight: 600, fontSize: 32, lineHeight: "80%" },
  statusAmount: {
    fontSize: 16,
  },
  amountsWrapper: {
    display: "flex",
    paddingBottom: 20,
    borderBottom: "1px solid #DADFF6",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "flex-start",
      flexWrap: "wrap",
      gap: 8,
    },
  },
  active: {
    borderWidth: 2,
    borderColor: ({ activeStatus }: any) =>
      activeStatus === "New"
        ? "#2eb662"
        : activeStatus === "In progress"
        ? "blue"
        : activeStatus === "Pending"
        ? "#ffb20d"
        : activeStatus === "Closed"
        ? "#757fa4"
        : "",
  },
  selectField: {
    width: 220,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  selectWrapper: {
    // display: "flex",
    margin: "20px 0",
    "& :nth-of-type(2)": {
      marginLeft: "20px",
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: 10,
        width: "100%",
      },
    },
    "& > *:last-child": {
      width: 300,
      marginLeft: "20px",
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: 10,
        width: "100%",
      },
    },
  },
  toggle: {
    display: "flex",
    paddingLeft: 10,
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      verticalAlign: "bottom",
      marginLeft: 10,
      cursor: "pointer",
    },
  },
}));
