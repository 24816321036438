import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  sidebarContainer: {
    position: "sticky",
    top: 0,
    minHeight: "100vh",
    display: "flex",
  },
  sidebarWrapper: {
    width: 280,
    height: "100%",
    [theme.breakpoints.down('md')]: {
      width: 160,
    },
  },
  sidebar: {
    "&.MuiDrawer-paper": {
      borderRight: "none",
      width: 280,
      overflow: "visible",
      position: "sticky",
      [theme.breakpoints.down('md')]: {
        width: 160,
      },
      [theme.breakpoints.down('sm')]: {
        width: 280,
      },
    },
  },
  logoContainer: {
    padding: "32px 30px",
    textAlign: "center",
    "& img": {
      maxWidth: 255,
      height: ({ logo_height }: any) => (logo_height ? logo_height : 80),
      [theme.breakpoints.down('md')]: {
        height: ({ logo_height }: any) =>
          logo_height ? logo_height * 0.6 : 48,
      },
    },
    [theme.breakpoints.down('md')]: {
      padding: 24,
    },
  },
  container: {
    height: "100%",
  },
  sidebarContent: {
    position: "relative",
    width: "280px",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 12px 0 0",
    [theme.breakpoints.down('md')]: {
      width: 160,
      padding: "40px 24px",
    },
    [theme.breakpoints.down('sm')]: {
      width: 280,
      height: "calc(100vh - 96px)",
      padding: 24,
    },
  },
  sidebarHeader: {
    width: "100%",
  },
  sidebarLabelWrapper: {
    backgroundColor: theme.palette.primary.light,
    width: "100%",
    height: 48,
    marginBottom: 24,
    "& .MuiTypography-root": {
      padding: "16px 40px",
      fontSize: 14,
      color: "#FFFFFF",
      fontWeight: 600,
      letterSpacing: 2,
    },
  },
  sectionWrapper: {
    marginTop: 24,
  },
  sidebarTitle: {
    marginBottom: 16,
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.primary.contrastText,
  },
  sidebarItemWrap: {
    marginTop: 24,
    marginBottom: 30,
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  sidebarItem: {
    fontSize: 16,
    fontWeight: 300,
    marginBottom: 8,
    color: theme.palette.primary.contrastText,
    cursor: ({ editDisabled }: any) => (!editDisabled ? "pointer" : ""),
    opacity: 0.75,
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    "&:hover": {
      opacity: ({ editDisabled }: any) => (!editDisabled ? 1 : ""),
    },
    "&:hover .MuiSvgIcon-root": {
      opacity: 1,
    },
  },
  sidebarDiscountItem: {
    fontSize: 16,
    fontWeight: 300,
    color: theme.palette.primary.contrastText,
    cursor: ({ editDisabled }: any) => (!editDisabled ? "pointer" : ""),
    opacity: 0.75,
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    marginBottom: 8,
    "&:hover": {
      opacity: ({ editDisabled }: any) => (!editDisabled ? 1 : ""),
    },
    "&:hover .MuiSvgIcon-root": {
      opacity: 1,
    },
  },
  sidebarItemText: {
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  sidebarItemIcon: {
    fontSize: 24,
    marginLeft: 16,
    opacity: 0,
    transition: "opacity 300ms linear",
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      marginLeft: 8,
    },
  },
  ovalWrapper: {
    padding: 40,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  oval: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 81,
    height: 81,
    border: "1px solid #DADFF6",
    color: theme.palette.primary.contrastText,
    fontSize: 14,
    fontWeight: 500,
    textAlign: "center",
    borderRadius: "50%",
    marginBottom: 24,
  },
}));
