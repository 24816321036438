import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  featureWrapper: {
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 16,
      marginBottom: 16,
    },
  },
  featureNoSpam: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    textAlign: "right",
    "& .MuiSvgIcon-root": {
      marginRight: 10,
    },
    justifyContent: "flex-end",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      gap: 15,
      width: 100,
      marginLeft: "auto",
      marginRight: 12,
    },
  },
  feature: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,

    "& .MuiSvgIcon-root": {
      marginRight: 10,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
      gap: 15,
      width: 100,
      marginLeft: 12,
    },
  },
  fields: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
    },
  },
  customBtn: {
    paddingTop: 22,
  },
  backdrop_custom: {
    zIndex: theme.zIndex.modal,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    zIndex: theme.zIndex.modal + 100,
    overflow: "auto",
    maxWidth: 780,
    maxHeight: "90vh",
    padding: "30px 40px 40px",
    borderRadius: 3,
    backgroundColor: "#FFF",
    boxShadow: "0 2px 32px 0 rgba(0,0,0,0.08), 0 2px 24px 0 rgba(0,0,0,0.12)",
  },
  appBar: {
    boxShadow: "none",
  },
}));
