import React from "react";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useStyles } from "./AdminSidebar.styles";
import { AssignmentIcon, DescriptionIcon } from "../icons";
import AdminPages from "../../pages/admin";
import { history } from "../../lib";
import {
  adminSelectors,
  authSelectors,
  Page,
  uiActions,
  uiSelectors,
  useDispatch,
  useSelector,
} from "../../state";
import { useLocation } from "react-router-dom";

const Icon = (props) => {
  const { icon } = props;
  return React.createElement(icon);
};

const adminPage = (key: string): Page => {
  return AdminPages[key];
};
const DocLink = () => {
  const classes = useStyles();
  const { google_doc_url } = useSelector(adminSelectors.activeAgencyData);

  return google_doc_url ? (
    <ListItem button key={"doc"} className={classes.listItem}>
      <ListItemIcon>
        <Icon icon={AssignmentIcon} />
      </ListItemIcon>
      <ListItemText
        primary={
          <a href={google_doc_url} target="_blank" rel="noreferrer">
            Lead management
          </a>
        }
      />
    </ListItem>
  ) : null;
};
export const AdminSidebar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const activeRole = useSelector(authSelectors.activeRole);
  const adminMenuOpen = useSelector(uiSelectors.adminMenuOpen);
  const hide =
    activeRole === "agent" ||
    activeRole === "affiliate_agent" ||
    activeRole === "referral_product_owner";
  const checkRoles = (key: string): boolean => {
    if (activeRole === "super_admin") {
      return true;
    }

    if (adminPage(key).allowedRoles === undefined) {
      //No Roles on page allow everyone
      return true;
    }
    if (!activeRole) {
      return false;
    }
    return !!adminPage(key).allowedRoles?.includes(activeRole);
  };
  const handleDrawerOpen = () => {
    dispatch(uiActions.setAdminMenuOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(uiActions.setAdminMenuOpen(false));
  };

  return (
    <div className={classes.root}>
      <Drawer
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: adminMenuOpen,
          [classes.drawerClose]: !adminMenuOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: adminMenuOpen,
            [classes.drawerClose]: !adminMenuOpen,
          }),
        }}
      >
        <List>
          {Object.keys(AdminPages)
            .filter((key) => adminPage(key).menuItemOrder)
            .filter(checkRoles)
            .sort(
              (a, b) =>
                (adminPage(a).menuItemOrder ?? 0) -
                (adminPage(b)?.menuItemOrder ?? 0),
            )
            .map((p, index) => {
              const page: Page = AdminPages[p];
              return (
                <ListItem
                  onClick={() => {
                    page.type.includes("Link") ? null : history.push(page.path);
                  }}
                  button
                  key={index}
                  className={classes.listItem}
                >
                  <ListItemIcon
                    className={location.pathname === page.path ? "active" : ""}
                  >
                    <Icon icon={page.icon ?? DescriptionIcon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      page.type.includes("Link") ? (
                        <a href={page.path} target="_blank" rel="noreferrer">
                          {page.title}
                        </a>
                      ) : (
                        page.title
                      )
                    }
                  />
                </ListItem>
              );
            })}
          {!hide && <DocLink />}
        </List>
      </Drawer>
    </div>
  );
};
