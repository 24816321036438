import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 640,
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  fields: {
    maxWidth: 300,
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
  phoneField: {
    width: 286,
    borderWidth: "1px",
    borderColor: theme.palette.primary.main,
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      width: "100%",
    },
  },
  boldText: { fontWeight: 600, fontSize: "1.5rem", margin: 30 },
  submitButton: {
    width: 286,
    height: 52,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  smallText: {
    margin: "auto",
    marginTop: theme.spacing(1.2),
    fontSize: "0.7rem",
    marginBottom: theme.spacing(3),
    maxWidth: 400,
  },
  linkWrapper: {
    textAlign: "center",
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    textTransform: "none",
    fontSize: 14,
    fontWeight: 500,
    transition: "all 300ms linear",
    cursor: "pointer",

    "&:hover": {
      opacity: 0.9,
      backgroundColor: "transparent",
    },
  },
}));
