import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Montserrat",
  },
  info: {
    marginLeft: "auto",
    color: "#AFB6D4",

    "& .MuiSvgIcon-root": {
      width: 18,
      height: 18,
    },
  },
  box: {
    width: 550,
    height: 500,
    borderRadius: 3,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 32px 0px rgba(0,0,0,0.08)",
    padding: 30,
    paddingBottom: 12,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 450,
    },
    "& .MuiDropzonePreviewList-root": {
      "&.MuiGrid-spacing-xs-1 > .MuiGrid-item": {
        width: "100%",
        padding: 0,
        marginBottom: 20,
      },
    },
  },
  containerCustom: {
    "&.MuiGrid-spacing-xs-1": {
      width: "100%",
      padding: 0,
      margin: 0,
    },
    "& .MuiChip-root": {
      width: "100%",
      justifyContent: "space-between",
      borderRadius: "3px",
      fontSize: 14,
      height: 40,
    },
  },
  titleWrapper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    fontFamily: "Montserrat",
  },
  subTitle: {
    marginBottom: 25,
    fontFamily: "Montserrat",
  },
  fieldName: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 6,
  },
  dropzone: {
    maxHeight: 150,
    width: "100%",
    backgroundColor: "#E4E7F2",
    minHeight: "auto",
    marginBottom: 16,
    padding: 20,
    "&.MuiDropzoneArea-root": {
      backgroundColor: "#E4E7F2",
    },
    "& .MuiDropzoneArea-textContainer": {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  item: {
    width: "100%",
  },
  dropzoneText: {
    margin: 0,
    fontSize: 14,
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
  dropzoneImage: {
    height: 20,
  },
  closeBtn: {
    marginRight: 0,
    paddingRight: 0,
    color: "#a3a8bf",
    width: 24,
    height: 24,
    "& .MuiSvgIcon-root": {
      width: 24,
      height: 24,
    },
  },

  buttons: {
    position: "absolute",
    bottom: 30,
    right: 30,
  },
  selectBtn: {
    fontWeight: 600,
    letterSpacing: "1px",
    transition: "all 300ms linear",
    height: 48,
    width: 130,
    color: "#fff",
  },
  cancelBtn: {
    fontWeight: 600,
    letterSpacing: "1px",
    transition: "all 300ms linear",
    height: 48,
    width: 130,
    color: "#000",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
}));
